import { useCallback, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Modal } from "reactstrap";

import PRButton from "~components/Generic/PRButton";

import "./style.scss";

export const modalZIndex = 1255;
function PRModal({
  title,
  visible: visibleProp,
  submitText: submitTextProp,
  submitLink = "",
  submitLinkProps = {},
  closeText: closeTextProp,
  submitDisabled,
  loading,
  onClose,
  onClick,
  onDelete,
  children,
  dark,
  noFooter,
  noPadding,
  disableOverflowResize,
  ...rest
}) {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(visibleProp ?? true);
  const submitText = useMemo(() => {
    if (submitTextProp != null) {
      return submitTextProp;
    }
    return t("common.submit");
  }, [submitTextProp, t]);
  const closeText = useMemo(() => {
    if (closeTextProp) {
      return closeTextProp;
    }
    return t("common.close");
  }, [closeTextProp, t]);
  const handleClickClose = useCallback(
    (e) => {
      onClose?.(e);
      setToggle(false);
    },
    [onClose]
  );

  const handleClickSubmit = useCallback(
    (e) => {
      onClick?.(e);
    },
    [onClick]
  );

  const handleClickDelete = useCallback(
    (e) => {
      onDelete?.(e);
    },
    [onDelete]
  );

  useEffect(() => {
    if (visibleProp !== undefined && visibleProp !== null) {
      setToggle(visibleProp);
    }
  }, [visibleProp]);

  useEffect(() => {
    const bodyResizeObserver = new ResizeObserver(() => {
      if (!disableOverflowResize) return;
      const body = document.querySelector("body");
      if (body) {
        const bodyPaddingRight = parseInt(window.getComputedStyle(body).getPropertyValue("padding-right"));

        if (bodyPaddingRight > 0) {
          document.body.style.paddingRight = `0px`;
        }
      }
    });

    bodyResizeObserver.observe(document.body);

    return () => {
      bodyResizeObserver.disconnect();
    };
  }, [disableOverflowResize]);

  //TODO use $zindex-modal
  return (
    <Modal centered isOpen={toggle} {...rest} wrapClassName="pr-modal" zIndex={modalZIndex}>
      {!!title && (
        <div className="modal-header">
          <h5 className="modal-title mt-0">{title}</h5>
          <button
            aria-label="Close"
            className={classNames("close", {
              "btn-close-white": dark,
            })}
            data-dismiss="modal"
            type="button"
            onClick={handleClickClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
      <div
        className={classNames("modal-body", {
          "p-0": noPadding,
        })}
      >
        {children}
      </div>
      {!noFooter && (
        <div className="modal-footer">
          <PRButton outline color="primary" data-dismiss="modal" onClick={handleClickClose}>
            {closeText}
          </PRButton>
          {onDelete && (
            <PRButton color="danger" loading={loading} type="button" onClick={handleClickDelete}>
              {t("common.delete")}
            </PRButton>
          )}
          {(submitText || submitLink) && (
            <PRButton
              color="primary"
              disabled={submitDisabled}
              link={submitLink}
              linkProps={submitLinkProps}
              loading={loading}
              type="button"
              onClick={handleClickSubmit}
            >
              {submitText}
            </PRButton>
          )}
        </div>
      )}
    </Modal>
  );
}
export default PRModal;
