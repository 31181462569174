import PRSwitch from "~components/Generic/PRSwitch";

import MessageTemplateDefinition from "../MessageTemplate/MessageTemplateDefinition";
import MessageTemplateEdit from "../MessageTemplate/MessageTemplateEdit";

function SystemMessageTemplate() {
  const routes = [
    {
      path: "/settings/system-message-template/form/:id?",
      component: MessageTemplateEdit,
    },
    {
      path: "/settings/system-message-template/",
      component: MessageTemplateDefinition,
      componentProps: { defaultMode: true },
    },
    {
      path: "/settings/system-message-template",
      to: "/settings/system-message-template/",
      redirect: true,
    },
  ];
  return <PRSwitch routes={routes} />;
}

export default SystemMessageTemplate;
