import React, { forwardRef } from "react";

import { useSelector } from "react-redux";

import styled from "@emotion/styled";
import Link from "@mui/material/Link";

import HistoryHelper from "~helpers/HistoryHelper";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

const StyledLink = styled(Link)``;

const isCustomProtocol = (url = "") => {
  const protocols = ["ftp", "ftps", "mailto", "tel", "sms", "callto", "webcal"];
  const urlProtocol = url.split(":")[0];
  return protocols.includes(urlProtocol);
};
/** @typedef */

/**
 * @class
 * @param {PalLinkProps} props
 * @param {React.ForwardedRef<HTMLAnchorElement>} ref
 */
const PalLink = forwardRef(function PalLink(
  { to, noPrefix, scope: scopeProp = "dashboard", newTab, children, onClick, ...rest },
  ref
) {
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const isCustomProtocolHandler = isCustomProtocol(to);
  const urlPrefix = `${process.env.PUBLIC_URL}/dashboard/project/${currentProject?.id || 0}/bot/${currentBot?.id || 0}`;
  const navigateUrl = noPrefix || isCustomProtocolHandler ? to : `${urlPrefix}${to}`;
  const scope = noPrefix ? "" : scopeProp;
  return (
    <StyledLink
      ref={ref}
      {...rest}
      {...(to ? { href: navigateUrl } : { href: "/#" })}
      {...(newTab ? { target: "_blank" } : {})}
      rel="noopener noreferrer"
      onClick={(e) => {
        onClick?.(e);
        if (e.defaultPrevented) return;
        if (newTab) return;
        if (isCustomProtocolHandler) {
          return;
        }
        e.preventDefault();
        if (!to) return;

        if (to === "/") {
          HistoryHelper.push("/home", { scope });
        } else if (to.startsWith("#")) {
          HistoryHelper.replace(to, { scope });
        } else {
          HistoryHelper.push(to, { scope });
        }
      }}
    >
      {children}
    </StyledLink>
  );
});
export default PalLink;
