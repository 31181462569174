import { createContext, useContext } from "react";

export const ChatbotResponsePropContext = createContext({});
export function PRContextProvider({ children, valueProp }) {
  return (
    <ChatbotResponsePropContext.Provider value={valueProp}>
      {/*  */}
      {children}
    </ChatbotResponsePropContext.Provider>
  );
}

export const useChatbotResponsePropContext = () => useContext(ChatbotResponsePropContext);
