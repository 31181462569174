import { combineReducers } from "redux";

import guide from "./guide/reducer";
//combine reducers

const customReducer = combineReducers({
  guide,
});

export default customReducer;
