import styled from "@emotion/styled";
import Badge from "@mui/material/Badge";

const Styled = styled(Badge)`
  //z-index: 1410;
`;
/** @param {import("@mui/material/Badge").BadgeProps} props */
export default function PalBadge({ children, ...rest }) {
  return <Styled {...rest}>{children}</Styled>;
}
