import { useEffect, useState, useMemo } from "react";

const defaultOptions = {
  shadowHeight: 10,
  gradientColors: ["rgba(0, 0, 0, 0.08)", "rgba(0, 0, 0, 0.04)", "rgba(0, 0, 0, 0)"],
};
export default function useScrollShadow(optionsProp = {}) {
  const { shadowHeight, listenEventKey, isHorizontal, gradientColors, disabled } = {
    ...defaultOptions,
    ...optionsProp,
  };
  const [scrollRef, setScrollRef] = useState(null);
  const [topRef, setTopRef] = useState(null);
  const [bottomRef, setBottomRef] = useState(null);

  const gradientColorsStr = useMemo(() => {
    return gradientColors.join(", ");
  }, [gradientColors]);

  useEffect(() => {
    if (!scrollRef || disabled) return;
    const handleScrollY = () => {
      const shadowSize = 10;

      const scrollValue = scrollRef[!isHorizontal ? "scrollTop" : "scrollLeft"];
      const scrollSize = scrollRef[!isHorizontal ? "scrollHeight" : "scrollWidth"];
      const clientSize = scrollRef[!isHorizontal ? "clientHeight" : "clientWidth"];
      const maxScrollDiff = scrollSize - clientSize;
      const shadowOpacityBottomDiff = maxScrollDiff - scrollValue;

      //   const topDom = document.querySelector(".scroll-shadow.top");
      const topH = scrollValue <= shadowSize ? scrollValue : shadowSize;
      const diffOffset = scrollValue;
      if (topRef) {
        if (!isHorizontal) {
          topRef.style.height = `${Math.max(topH)}px`;
          topRef.style.top = `${diffOffset}px`;
        } else {
          topRef.style.width = `${Math.max(topH)}px`;
          topRef.style.left = `${diffOffset}px`;
        }
      }

      const bottomH = shadowOpacityBottomDiff <= shadowSize ? shadowOpacityBottomDiff : shadowSize;
      const bottomOffset =
        scrollValue + scrollRef[!isHorizontal ? "clientHeight" : "clientWidth"] - shadowSize + (shadowSize - bottomH);
      if (bottomRef) {
        if (!isHorizontal) {
          bottomRef.style.top = `${bottomOffset}px`;
          bottomRef.style.height = `${Math.max(bottomH)}px`;
        } else {
          bottomRef.style.left = `${bottomOffset}px`;
          bottomRef.style.width = `${Math.max(bottomH)}px`;
        }
      }
    };
    const handleResizeObserver = () => {
      handleScrollY();
    };
    const resizeObserver = new ResizeObserver(handleResizeObserver);
    resizeObserver.observe(scrollRef);

    scrollRef?.addEventListener("scroll", handleScrollY);
    const customListenCallback = (e) => {
      // console.log("customListenCallback", e);
      handleScrollY();
    };
    if (listenEventKey) {
      window.addEventListener(listenEventKey, customListenCallback);
    }
    return () => {
      resizeObserver.disconnect();
      scrollRef?.removeEventListener("scroll", handleScrollY);
      if (listenEventKey) {
        window.removeEventListener(listenEventKey, customListenCallback);
      }
    };
  }, [scrollRef, topRef, bottomRef, listenEventKey, shadowHeight, isHorizontal, disabled]);

  useEffect(() => {
    if (!scrollRef || disabled) return;
    scrollRef.style.position = "relative";
  }, [scrollRef, disabled]);

  const topProps = useMemo(() => {
    if (disabled) return {};
    return {
      ref: setTopRef,
      style: {
        ...(!isHorizontal
          ? {
              left: 0,
              right: 0,
              width: "100%",
              overflow: "hidden",
              height: `${shadowHeight}px`,
              display: "flex",
              position: "absolute",
              zIndex: 100,

              top: 0,
              alignItems: "flex-end",
            }
          : {
              top: 0,
              bottom: 0,
              height: "100%",
              overflow: "hidden",
              width: `${shadowHeight}px`,
              display: "flex",
              position: "absolute",
              zIndex: 100,

              left: 0,
              justifyContent: "flex-end",
            }),
      },
    };
  }, [shadowHeight, isHorizontal, disabled]);

  const bottomProps = useMemo(() => {
    if (disabled) return {};
    return {
      ref: setBottomRef,
      style: {
        ...(!isHorizontal
          ? {
              left: 0,
              right: 0,
              width: "100%",
              overflow: "hidden",
              height: `${shadowHeight}px`,
              display: "flex",
              position: "absolute",
              zIndex: 100,

              bottom: 0,
            }
          : {
              top: 0,
              bottom: 0,
              height: "100%",
              overflow: "hidden",
              width: `${shadowHeight}px`,
              display: "flex",
              position: "absolute",
              zIndex: 100,

              right: 0,
              justifyContent: "flex-start",
            }),
      },
    };
  }, [shadowHeight, isHorizontal, disabled]);

  useEffect(() => {
    if (!topRef) return;
    if (disabled) {
      if (topRef.style) {
        topRef.style.display = "none";
      }
      return;
    }

    //clear all if any children exist
    while (topRef.firstChild) {
      topRef.removeChild(topRef.firstChild);
    }
    const shadowDom = document.createElement("div");
    topRef.style.flex = "1 1";
    topRef.style[!isHorizontal ? "height" : "width"] = shadowHeight;
    topRef.style.background = `linear-gradient(${!isHorizontal ? "to bottom" : "to right"}, ${gradientColorsStr})`;

    topRef.appendChild(shadowDom);
  }, [topRef, shadowHeight, isHorizontal, gradientColorsStr, disabled]);

  useEffect(() => {
    if (!bottomRef) return;
    if (disabled) {
      if (bottomRef.style) {
        bottomRef.style.display = "none";
      }
      return;
    }

    //clear all if any children exist
    while (bottomRef.firstChild) {
      bottomRef.removeChild(bottomRef.firstChild);
    }
    const shadowDom = document.createElement("div");
    bottomRef.style.flex = "1 1";
    bottomRef.style[!isHorizontal ? "height" : "width"] = shadowHeight;
    bottomRef.style.background = `linear-gradient(${!isHorizontal ? "to top" : "to left"}, ${gradientColorsStr})`;

    bottomRef.appendChild(shadowDom);
  }, [bottomRef, shadowHeight, isHorizontal, gradientColorsStr, disabled]);

  return { ref: setScrollRef, topProps, bottomProps };
}
