import * as at from "./actionTypes";

/**
 * @param {boolean} payload
 * @returns
 */
export const setGuideOpen = (payload) => ({
  type: at.OPEN_GUIDE,
  payload,
});
