import { Fragment, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react";

import classNames from "classnames";
import _ from "lodash";
import moment from "moment-timezone";
import { Calendar, momentLocalizer } from "react-big-calendar";
import MonthView from "react-big-calendar/lib/Month";
import { useTranslation } from "react-i18next";
import { FaFileCsv, FaFileExcel } from "react-icons/fa";
import { MdCalendarToday, MdChevronLeft, MdChevronRight, MdExpandLess, MdExpandMore, MdPerson } from "react-icons/md";
import { ButtonDropdown, ButtonGroup, Col, DropdownItem, DropdownMenu, DropdownToggle, Label, Row } from "reactstrap";

import AlertHelper from "~helpers/AlertHelper";
import DateHelper from "~helpers/DateHelper";
import FileHelper from "~helpers/FileHelper";

import { PRContextProvider, usePRCalendarPropContext } from "./context";
import PRButton from "../PRButton";
import PRFormGroup from "../PRFormGroup";
import PRInput from "../PRInput";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./style.scss";
const localizer = momentLocalizer(moment);

function CustomToolbar(props) {
  const { t } = useTranslation();
  const { view, label, onNavigate, onView } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const calendarProp = usePRCalendarPropContext();
  const weekOptions = useMemo(
    () => [
      { label: t("component.calendar.week"), value: "week" },
      { label: t("component.calendar.workWeek"), value: "work_week" },
    ],
    [t]
  );
  const [weekType, setWeekType] = useState(weekOptions[0]);
  const dropdownToggle = () => setIsDropdownOpen((p) => !p);
  const handleClickNavigate = (target) => () => {
    onView(target);
  };

  const handleClickWeek = (target) => {
    return () => {
      setWeekType(target);
      onView(target.value);
    };
  };

  const goToBack = () => {
    onNavigate("PREV");
  };

  const goToNext = () => {
    onNavigate("NEXT");
  };

  const goToCurrent = () => {
    onNavigate("TODAY");
  };
  return (
    <div>
      <Row className="g-2 justify-content-lg-between mb-2 align-items-stretch justify-content-center">
        <Col className="d-flex align-items-center justify-content-center" lg="auto" xs={12}>
          <ButtonGroup className="w-md-auto w-100" size="lg">
            <PRButton outline className="fw-bold" color="secondary" onClick={goToCurrent}>
              <span className="d-flex align-items-center">
                <span>{t("common.today")}</span>
                <MdCalendarToday className="ms-1 fs-5" />
              </span>
            </PRButton>
            <PRButton outline className="fw-bold" color="secondary" onClick={goToBack}>
              <span className="d-flex align-items-center">
                <MdChevronLeft className="me-1 fs-5" />
                {t("component.calendar.back")}
              </span>
            </PRButton>
            <PRButton outline className="fw-bold" color="secondary" onClick={goToNext}>
              <span className="d-flex align-items-center fs-5">
                {t("component.calendar.next")}
                <MdChevronRight className="ms-1 fs-5" />
              </span>
            </PRButton>
          </ButtonGroup>
        </Col>
        <Col lg className="text-center align-self-center " xs={12}>
          <h4 className="text-secondary fw-bold">
            {calendarProp?.showAll ? t("component.calendar.allEvents") : label}
          </h4>
        </Col>
        <Col className="d-flex align-items-center justify-content-center" lg="auto" xs={12}>
          <ButtonGroup className="w-md-auto w-100" size="lg">
            <PRButton
              outline
              active={view === "month"}
              className="fw-bold"
              color="secondary"
              onClick={handleClickNavigate("month")}
            >
              {t("common.month")}
            </PRButton>
            <ButtonDropdown isOpen={isDropdownOpen} toggle={dropdownToggle}>
              <PRButton
                outline
                active={view === weekType.value}
                className="fw-bold"
                color="secondary"
                size="lg"
                onClick={handleClickNavigate(weekType.value)}
              >
                {weekType.label}
              </PRButton>
              <DropdownToggle caret outline className="p-0" color="secondary">
                {/* <i className="??? mdi-18px " /> */}

                <MdExpandMore />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>{t("component.calendar.weekView")}</DropdownItem>
                {weekOptions.map((obj) => (
                  <DropdownItem key={obj.value} onClick={handleClickWeek(obj)}>
                    <span className="text-capitalize text-secondary">{obj.label}</span>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>
            <PRButton
              outline
              active={view === "day"}
              className="fw-bold"
              color="secondary"
              onClick={handleClickNavigate("day")}
            >
              {t("common.day")}
            </PRButton>
            <PRButton
              outline
              active={view === "agenda"}
              className="fw-bold"
              color="secondary"
              onClick={handleClickNavigate("agenda")}
            >
              {t("component.calendar.agenda")}
            </PRButton>
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  );
}

const getCustomMonthView = ({ rowLimit }) => {
  function CustomMonthView(props) {
    const monthRef = useRef();

    useEffect(() => {
      if (monthRef.current?.state?.rowLimit !== rowLimit) {
        monthRef.current.setState({ rowLimit });
      }
    });

    return <MonthView {...props} ref={monthRef} />;
  }
  CustomMonthView.range = MonthView.range;
  CustomMonthView.navigate = MonthView.navigate;
  CustomMonthView.title = MonthView.title;
  return CustomMonthView;
};

function RenderLeafEvents({ collapsed, events = [], onDoubleClickEvent }) {
  const { t } = useTranslation();
  const availableEvents = events.filter((event) => event.available);
  const unavailableEvents = events.filter((event) => !event.available);

  if (collapsed && unavailableEvents.length > 0) {
    return (
      <div className="d-flex align-items-center">
        <div className="me-4">
          <span className="text-nowrap d-flex justify-content-end align-items-center">
            {unavailableEvents.length} <MdPerson className="ms-1 text-primary" />
          </span>
        </div>
        <div>
          <span
            className={classNames("text-nowrap", {
              "text-success-600": availableEvents?.length > 0,
              "text-muted": !availableEvents?.length,
            })}
          >
            {availableEvents.length} {availableEvents[0]?.title || t("common.available")}
          </span>
        </div>
      </div>
    );
  }
  const handleDoubleClickAvailable = (e) => {
    e.stopPropagation();
    const firstAvailableEvent = availableEvents?.[0];
    onDoubleClickEvent?.(firstAvailableEvent);
  };
  return (
    <>
      {unavailableEvents.map((item, index) => {
        const handleDoubleClick = (e) => {
          e.stopPropagation();
          onDoubleClickEvent?.(item);
        };
        return (
          <div key={index} className="event-leaf-text text-primary" onDoubleClick={handleDoubleClick}>
            {item.title}
          </div>
        );
      })}
      {!!availableEvents.length && (
        <div className="event-leaf-text text-success-600" onDoubleClick={handleDoubleClickAvailable}>
          {availableEvents.length > 1 && "+"}
          {availableEvents.length > 1 && availableEvents.length} {availableEvents[0]?.title || "Available"}
        </div>
      )}
    </>
  );
}

function RenderTimeEvents({ collapse, events = [], onDoubleClickEvent, columnRatio }) {
  const { t } = useTranslation();
  const [collapseList, setCollapseList] = useState([...Array(events.length)].map(() => false));

  const toggleCollapse = (index) => () => {
    const newCollapseList = [...collapseList];
    newCollapseList[index] = !newCollapseList[index];
    setCollapseList(newCollapseList);
  };

  useEffect(() => {
    setCollapseList([...Array(events.length)].map(() => !!collapse));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapse]);

  return events.map((timeEvent, index) => {
    const firstEvent = timeEvent.events[0];
    let timeText = `${DateHelper.getDateTimeLocal(firstEvent.start).format("LT")}-${DateHelper.getDateTimeLocal(
      firstEvent.end
    ).format("LT")}`;

    if (firstEvent.allDay) {
      timeText = t("component.renderTimeEvents.allDay");
    }
    const unavailableEvents = timeEvent.events.filter((event) => !event.available);
    return (
      <Row
        key={index}
        className={classNames("g-2 ag-border-left ", {
          "ag-border-bottom": index !== events.length - 1,
        })}
      >
        <Col className="d-flex align-items-start" xs={columnRatio[0]}>
          <div className="d-flex justify-content-between align-items-center w-100">
            <span>{timeText}</span>
            {unavailableEvents?.length > 0 && (
              <PRButton
                border
                noBorder
                onlyIcon
                outline
                color="secondary"
                icon={!collapseList[index] ? <MdExpandMore /> : <MdExpandLess />}
                size="sm"
                onClick={toggleCollapse(index)}
                // className="ms-1"
              />
            )}
          </div>
        </Col>
        <Col xs={columnRatio[1]}>
          <RenderLeafEvents
            collapsed={collapseList[index]}
            events={timeEvent.events}
            onDoubleClickEvent={onDoubleClickEvent}
          />
        </Col>
      </Row>
    );
  });
}

function RenderDayEvents({ onDoubleClickEvent, events = [], columnRatio, disableCollapse }) {
  const { t } = useTranslation();
  const [collapsedTime, setCollapsedTime] = useState(false);
  const handleClickCollapseTime = () => {
    setCollapsedTime(!collapsedTime);
  };
  return (
    <Row className="gx-2 ag-border  ag-border-bottom">
      <Col xs={columnRatio[0]}>{t("common.date")}</Col>
      <Col xs={columnRatio[1]}>
        <Row className="gx-2  ag-border-left">
          <Col className="d-flex align-items-center justify-content-between" xs={columnRatio[0]}>
            {t("common.time")}
            {!disableCollapse && (
              <PRButton
                noBorder
                onlyIcon
                outline
                color="secondary"
                icon={!collapsedTime ? MdExpandMore : MdExpandLess}
                size="sm"
                onClick={handleClickCollapseTime}
                // className="ms-1"
              />
            )}
          </Col>
          <Col xs={columnRatio[1]}>{t("component.renderDayEvents.event")}</Col>
        </Row>
      </Col>
      {events.map((dayEvent, index) => {
        const dayText = DateHelper.getDateTimeLocal(dayEvent.startDate).format("L");
        return (
          <Fragment key={index}>
            <Col className="" xs={columnRatio[0]}>
              {dayText}
            </Col>
            <Col className="" xs={columnRatio[1]}>
              <RenderTimeEvents
                collapse={collapsedTime}
                columnRatio={columnRatio}
                events={dayEvent.items}
                onDoubleClickEvent={onDoubleClickEvent}
              />
            </Col>
          </Fragment>
        );
      })}
    </Row>
  );
}

export function RenderGroupedEvents({
  onDoubleClickEvent,
  eventList,
  events = [],
  columnRatio,
  disableCollapse,
  onClickDownload,
  onRangeChange,
  date,
  hideShowAll,
}) {
  const { t } = useTranslation();
  const calendarProp = usePRCalendarPropContext();

  const getMappedDate = () => {
    const mappedData = eventList.map((event) => {
      const { title, start, end, reservation } = event;
      const startDate = DateHelper.getDateTimeLocal(start).toDate();
      const startTime = DateHelper.getDateTimeLocal(start).format("LT");
      const endTime = DateHelper.getDateTimeLocal(end).format("LT");
      let timeText = `${startTime} - ${endTime}`;
      if (event.allDay) timeText = t("component.renderGroupedEvents.allDay");
      return {
        Date: startDate,
        Time: timeText,
        Event: title,
        ...reservation?.reserver?.safe_information,
      };
    });
    return mappedData;
  };
  const handleDownloadXlsx = () => {
    const timestamp = Date.now();
    const result = onClickDownload?.(eventList, "xlsx");
    if (!result) return;
    const mappedData = getMappedDate();
    if (!mappedData?.length) {
      AlertHelper.show(t("component.renderGroupedEvents.dataNotAvailableError"), "warning");
      return;
    }
    FileHelper.exportAsExcel(mappedData, `agenda_${timestamp}`);
  };
  const handleDownloadCsv = () => {
    const timestamp = Date.now();
    const result = onClickDownload?.(eventList, "csv");
    if (!result) return;

    const mappedData = getMappedDate();
    if (!mappedData?.length) {
      AlertHelper.show(t("component.renderGroupedEvents.dataNotAvailableError"), "warning");
      return;
    }
    FileHelper.exportAsCSV(mappedData, `agenda_${timestamp}`);
  };

  const handleChangeShowAllEvents = (e) => {
    const showAll = e.target.checked;
    const range = MonthView.range(date, {
      localizer: localizer,
    });
    onRangeChange?.(range, "month", showAll);
  };
  return (
    <div className="agenda-view">
      <Row className="g-0 mb-2">
        <Col xs className="d-flex align-items-center">
          {!hideShowAll && (
            <>
              <PRFormGroup switch>
                <PRInput checked={calendarProp.showAll || false} type="switch" onChange={handleChangeShowAllEvents} />
                <Label className="me-2" size="md">
                  {t("component.renderGroupedEvents.showAllEvents")}
                </Label>
              </PRFormGroup>
            </>
          )}
        </Col>
        <Col className="d-flex align-items-center" xs="auto">
          <PRButton
            noBorder
            outline
            className="me-1"
            color="teal"
            icon={FaFileCsv}
            size="lg"
            tooltipText={t("component.renderGroupedEvents.downloadAsCSV")}
            onClick={handleDownloadCsv}
          />
          <PRButton
            noBorder
            outline
            className=""
            color="teal"
            icon={FaFileExcel}
            size="lg"
            tooltipText={t("component.renderGroupedEvents.downloadAsExcel")}
            onClick={handleDownloadXlsx}
          />
        </Col>
      </Row>
      {events?.map((month, index, arr) => {
        return (
          <Fragment key={index}>
            {calendarProp.showAll && (
              <Label className="my-2 font-size-16 fw-bold" tag="h6">
                {DateHelper.getDateTimeLocal(month.startDate).format("MMMM")}{" "}
                {DateHelper.getDateTimeLocal(month.startDate).format("YYYY")}
              </Label>
            )}
            <RenderDayEvents
              columnRatio={columnRatio}
              disableCollapse={disableCollapse}
              events={month.items}
              onDoubleClickEvent={onDoubleClickEvent}
            />
          </Fragment>
        );
      })}
    </div>
  );
}
export function PRAgenda(props) {
  const {
    events: eventsRaw,
    onDoubleClickEvent,
    columnRatio = [2, 10],
    disableCollapse,
    onClickDownload,
    onRangeChange,
    date,
    view,
    hideShowAll,
    ...rest
  } = props;
  const events = useMemo(() => {
    const sortedEvents = [...eventsRaw];
    //sort by start date
    return sortedEvents.sort((a, b) => {
      const aStart = DateHelper.getDateTimeLocal(a.start);
      const bStart = DateHelper.getDateTimeLocal(b.start);
      return aStart.diff(bStart);
    });
  }, [eventsRaw]);

  const groupedEventsByMonth = useMemo(() => {
    const groupedEvents = _.groupBy(events, (event) => {
      return DateHelper.getDateTimeLocal(event.start).format("MMMM yyyy");
    });
    const groupedMonthList = Object.values(groupedEvents).map((items) => {
      const startDate = DateHelper.getDateTimeLocal(items[0].start);
      const endDate = DateHelper.getDateTimeLocal(items[items.length - 1].start);
      return {
        type: "month",
        startDate,
        endDate,
        items,
      };
    });
    const groupedEventsByDay = groupedMonthList.map((group) => {
      const groupedMonthDayEvents = _.groupBy(group.items, (event) => {
        return DateHelper.getDateTimeLocal(event.start).format("DD MMMM yyyy");
      });
      const groupedList = Object.values(groupedMonthDayEvents).map((items) => {
        const startDate = DateHelper.getDateTimeLocal(items[0].start);
        const endDate = DateHelper.getDateTimeLocal(items[items.length - 1].start);
        return {
          type: "day",
          startDate,
          endDate,
          items,
        };
      });
      return {
        ...group,
        items: groupedList,
      };
    });

    const groupedEventsByTimeRange = groupedEventsByDay.map((monthItem) => {
      const groupedList = monthItem.items.map((dayItem) => {
        const groupedEvents = _.groupBy(dayItem.items, (event) => {
          return (
            DateHelper.getDateTimeLocal(event.start).format("HH:mm") +
            " - " +
            DateHelper.getDateTimeLocal(event.end).format("HH:mm")
          );
        });
        const groupedList = Object.values(groupedEvents).map((items) => {
          const startDate = DateHelper.getDateTimeLocal(items[0].start);
          const endDate = DateHelper.getDateTimeLocal(items[items.length - 1].start);

          return {
            type: "time",
            startDate,
            endDate,
            events: items,
          };
        });
        return {
          ...dayItem,
          items: groupedList,
        };
      });
      return {
        ...monthItem,
        items: groupedList,
      };
    });
    return groupedEventsByTimeRange;
  }, [events]);

  return (
    <RenderGroupedEvents
      columnRatio={columnRatio}
      date={date}
      disableCollapse={disableCollapse}
      eventList={events}
      events={groupedEventsByMonth}
      hideShowAll={hideShowAll}
      onClickDownload={onClickDownload}
      onDoubleClickEvent={onDoubleClickEvent}
      onRangeChange={onRangeChange}
    />
  );
}

PRAgenda.range = MonthView.range;
PRAgenda.navigate = MonthView.navigate;
PRAgenda.title = MonthView.title;

const PRCalendar = forwardRef(
  /**
   * @typedef {import("react-big-calendar").CalendarProps & {
   *   rowLimit?: number;
   *   onClickDownload?: (events: any[], type: "xlsx" | "csv") => void;
   * }} PRCalendarProps
   * @param {PRCalendarProps} param0
   * @param {React.Ref<import("react-big-calendar").Calendar>} ref
   */ function PRCalendar({ rowLimit = 4, className, events, showAll, ...rest }, ref) {
    const { t } = useTranslation();
    const { defaultDate, views } = useMemo(
      () => ({
        defaultDate: DateHelper.getDateTimeLocal(),
        views: {
          month: getCustomMonthView({ rowLimit }),
          week: true,
          day: true,
          agenda: PRAgenda,
          work_week: true,
        },
      }),
      [rowLimit]
    );

    const timeGutter = useCallback(() => {
      return (
        <div className="d-flex justify-content-center align-items-center h-100">{t("component.calendar.allDay")}</div>
      );
    }, []);

    const components = useMemo(
      () => ({
        ...rest.components,
        toolbar: CustomToolbar,
        timeGutterHeader: timeGutter,
      }),
      [rest.components, timeGutter]
    );

    return (
      <div className={classNames("pr-calendar", className)}>
        <PRContextProvider valueProp={{ showAll }}>
          <Calendar
            {...rest}
            ref={ref}
            components={components}
            defaultDate={defaultDate}
            endAccessor="end"
            events={events}
            localizer={localizer}
            startAccessor="start"
            views={views}
          />
        </PRContextProvider>
      </div>
    );
  }
);

export default PRCalendar;
