import {
  Children,
  cloneElement,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import classNames from "classnames";
import { createPortal } from "react-dom";

import Utils from "~helpers/Utils";

const PRPopover = forwardRef(function PRPopover(
  {
    placement = "bottom-start",
    children: childrenProp,
    content,
    childRef,
    popoverClassName,
    disabled,
    onOpenChange,
    portal,
    zIndex = 10,
    ...props
  },
  ref
) {
  const targetRef = useRef(null);
  const [open, setOpen] = useState(false);
  const uniqStringRef = useRef(Utils.getId());

  useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
    close: () => setOpen(false),
    isOpen: open,
  }));

  const handleOpen = useCallback(
    (status) => {
      setOpen(status);
      //check is focused
      if (targetRef.current && targetRef.current.contains(document.activeElement)) {
        setOpen(true);
      }
    },
    [targetRef]
  );

  const { x, y, refs, strategy, context } = useFloating({
    open,
    onOpenChange: handleOpen,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
    placement: placement,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    onOpenChange?.(open);
  }, [onOpenChange, open]);

  let children = Children.toArray(childrenProp).filter(Boolean);

  if (!children?.length) return null;
  if (Array.isArray(children) && children.length === 1) {
    children = children[0];
  }

  if ((children.length === 1 && typeof children[0] === "string") || children.length > 1) {
    children = <span>{children}</span>;
  }

  let singleChild;
  try {
    singleChild = Children.only(children);
  } catch (error) {}

  const id = singleChild?.props?.id || uniqStringRef.current;
  const childrenRef = singleChild?.ref || singleChild?.props?.ref;
  const handleRef = (ref) => {
    if (typeof refs.setReference === "function") {
      refs.setReference?.(ref);
    } else if (childrenRef) {
      refs.setReference.current = ref;
    }
    if (typeof childrenRef === "function") {
      childrenRef?.(ref);
    } else if (childrenRef) {
      childrenRef.current = ref;
    }
    targetRef.current = ref;
  };

  const cloneProps = {
    ref: handleRef,
    ...getReferenceProps(),
    id,
  };
  const popoverComponent = (
    <>
      {!!id && !disabled && open && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              width: "max-content",
              zIndex: zIndex,
            }}
            {...getFloatingProps()}
            className={classNames("pr-popover", popoverClassName, getFloatingProps().className)}
          >
            {typeof content === "function" ? content({ close: handleClose }) : content}
          </div>
        </FloatingFocusManager>
      )}
    </>
  );
  return (
    <>
      {cloneElement(singleChild, { ...singleChild?.props, ...cloneProps })}
      {portal
        ? createPortal(
            popoverComponent,
            document.getElementById(typeof portal !== "string" ? "root" : portal) || document.body
          )
        : popoverComponent}
    </>
  );
});

export default PRPopover;
