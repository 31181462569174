import { createSelector } from "reselect";

/** @type {() => typeof import("./user.reducer.js").initState} */
const selectRoot = (state) => state.user;

export const selectProjects = createSelector(selectRoot, (state) => state.projects);
export const selectCurrentProject = createSelector(selectRoot, (state) => state?.selectedProject);
export const selectCurrentProjectId = createSelector(selectCurrentProject, (project) => project?.id);

export const selectBots = createSelector(selectRoot, (state) => state.bots);
export const selectCurrentBot = createSelector(selectRoot, (state) => state?.selectedBot);
export const selectUserInfo = createSelector(selectRoot, (state) => state.userInfo);
