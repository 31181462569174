import { forwardRef, memo } from "react";

import styled from "@emotion/styled";
import { Avatar } from "@mui/material";

import { ReactComponent as BotSvg } from "~assets/images/webchat/chat-bot.svg";
import PRTooltip from "~components/Generic/PRTooltip";

const StyledDiv = styled.div`
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;

const StyledAvatar = styled(Avatar)`
  &.MuiAvatar-root.MuiAvatar-circular {
    /* border: 1px solid var(--bs-gray-300); */
  }
`;
const getNameAbbreviation = (name) => {
  if (!name) return "";
  const nameParts = name.split(" ");
  return (
    (nameParts.length > 1 ? nameParts[0].charAt(0) + nameParts[1].charAt(0) : nameParts[0].charAt(0)) || ""
  ).toUpperCase();
};
export default memo(
  forwardRef(function ChatProfileImage({ visible, message, agentLogo, botJoinedLogo, botLogo, animate }, ref) {
    const isAgent = message.sender === "AGENT";
    return (
      <StyledDiv ref={ref} visible={visible}>
        <PRTooltip title={message?.senderData?.name}>
          {!isAgent ? (
            !botJoinedLogo ? (
              <StyledAvatar>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#ffffff",
                    color: "white",
                    width: "5rem",
                    height: "5rem",
                    borderRadius: "50%",
                  }}
                >
                  <BotSvg className="icon-circle__bot" />
                </div>
              </StyledAvatar>
            ) : (
              <StyledAvatar src={botJoinedLogo} />
            )
          ) : (
            //   <PRImg alt="Bot picture" className="avatar-xs" src={botLogo || PalamarLogo} />
            <StyledAvatar {...(agentLogo && { src: agentLogo })}>
              {!agentLogo && getNameAbbreviation(message?.senderData?.name)}
            </StyledAvatar>
          )}
        </PRTooltip>
      </StyledDiv>
    );
  })
);
