import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { PalPage, PalTypography } from "@palamar/fe-library";
import { withCardon } from "cardon";
import chroma from "chroma-js";
import { useTranslation } from "react-i18next";
import { MdAdd, MdKeyboardArrowDown, MdKeyboardArrowUp, MdSend, MdWarning } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "reactstrap";

import styled from "@emotion/styled";
import { Alert, CircularProgress, Collapse, Grid, Tab, Tabs } from "@mui/material";

import useLoading from "~common/hooks/useLoading";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRDate from "~components/Generic/PRDate";
import PRLink from "~components/Generic/PRLink";
import PRModal from "~components/Generic/PRModal";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import {
  apiUrlChatbot,
  implementationStatusMap,
  intentRecommendTypeMap,
  recommendationGeneratorStatus,
  sessionTypeMap,
  tableFilterStorageKey,
} from "~constants";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import { getRecommendationGenerator, updateRecommendations } from "~store/dialogComponents/intentRecommend/actions";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

const StyledTab = styled(Tab)`
  font-weight: 600;
`;
const StyledPage = styled(PalPage)`
  & > .MuiBox-root {
    background-color: ${({ theme, order }) =>
      order
        ? chroma(theme.palette.grey[100])
            .darken(order * 0.1)
            .hex()
        : "white"};
    /* border-right: 1px solid #f4f4f4;
    border-left: 1px solid #f4f4f4; */
    border-radius: 0;
    padding-right: 0;
  }
  background-color: white;
`;

const StyledTable = styled(PRTable)`
  .pr-table & > .table-wrapper > table > thead {
    th {
      background-color: ${({ theme, order }) =>
        chroma(theme.palette.grey[100])
          .darken(order * 0.1)
          .hex()};
    }

    th:first-child {
      border-top-left-radius: 0;
    }
    th:last-child {
      border-top-right-radius: 0;
    }
  }
  /* .pr-table & > .table-wrapper > table > thead > tr:nth-child(even) {
    th {
      background-color: ${({ theme, order }) =>
    chroma(theme.palette.grey[100])
      .darken(order * 0.1)
      .hex()};
    }
  } */
  .pr-table & > .table-wrapper > table > tbody > tr:nth-child(odd) {
    td {
      background-color: ${({ theme, order }) =>
        chroma(theme.palette.grey[100])
          .darken((order + 1) * 0.1)
          .hex()};
    }
  }
  .pr-table & > .table-wrapper > table > tbody > tr:nth-child(even) {
    td {
      background-color: ${({ theme, order }) =>
        chroma(theme.palette.grey[100])
          .darken((order + 1) * 0.1)
          .hex()};
    }
  }
`;

const RecommendationRowRenderer = ({ row, index, children }) => {
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();
  const handleTabChange = useCallback((event, newValue) => {
    setTab(newValue);
  }, []);
  const handleClick = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);
  const clonedChildren = useMemo(
    () =>
      React.isValidElement(children)
        ? React.cloneElement(children, {
            onClick: handleClick,
            children: React.Children.map(children.props.children, (child, idx) => {
              if (idx === 1 && React.isValidElement(child)) {
                return <td>{open ? <MdKeyboardArrowDown /> : <MdKeyboardArrowUp />}</td>;
              }
              return child;
            }),
          })
        : children,
    [children, open]
  );

  return (
    <>
      {clonedChildren}
      <tr>
        <td colSpan={100} style={{ padding: 0 }}>
          <Collapse in={open}>
            <StyledPage order={5} title={""}>
              <Grid container spacing={2}>
                <Grid item sx={(theme) => ({ marginTop: theme.spacing(-1) })} xs={12}>
                  <PalTypography variant="p">{row?.data.request_summary}</PalTypography>
                </Grid>
                <Grid item xs={12}>
                  <Tabs value={tab} variant="scrollable" onChange={handleTabChange}>
                    <StyledTab label={t("dashboard.intentRecommendation.similarIntents")} />
                    <StyledTab label={t("dashboard.intentRecommendation.questions")} />
                  </Tabs>
                </Grid>
                {tab === 0 && (
                  <>
                    {(row.similar_intents || []).map((intent, index) => (
                      <Grid key={index} item xs={12}>
                        <PRLink newTab to={`/chatbot/intent/form/${intent.id}`}>
                          {intent.display_text || intent.text}
                        </PRLink>
                      </Grid>
                    ))}
                  </>
                )}
                {tab === 1 && (
                  <>
                    {(row.data.questions || []).map((question, index) => (
                      <Grid key={index} item xs={12}>
                        <PalTypography variant="p">{question}</PalTypography>
                      </Grid>
                    ))}
                  </>
                )}
              </Grid>
            </StyledPage>
          </Collapse>
        </td>
      </tr>
    </>
  );
};

const activeRecommendationStatusDelayMap = {
  [recommendationGeneratorStatus.IN_PROGRESS]: 15_000,
  [recommendationGeneratorStatus.INITIATED]: 60_000,
};
const SubClusterRowRenderer = ({ row, index, children }) => {
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();
  const handleClick = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);
  const handleTabChange = useCallback((event, newValue) => {
    setTab(newValue);
  }, []);
  const clonedChildren = useMemo(
    () =>
      React.isValidElement(children)
        ? React.cloneElement(children, {
            onClick: handleClick,
            children: React.Children.map(children.props.children, (child, idx) => {
              if (idx === 1 && React.isValidElement(child)) {
                return <td>{open ? <MdKeyboardArrowDown /> : <MdKeyboardArrowUp />}</td>;
              }
              return child;
            }),
          })
        : children,
    [children, open]
  );

  const onAddRecommendationClick = useCallback((recommendation) => {
    if (recommendation?.recommendation_type === "SAMPLE") {
      return {
        linkProps: {
          newTab: true,
        },
        link: `/chatbot/intent/form/${recommendation?.intent}/?samples=${encodeURI(
          (recommendation?.data.questions || []).join("\n")
        )}`,
        disabled: !recommendation?.data.questions,
      };
    }
    if (recommendation?.recommendation_type === "ADD_ANSWER") {
      return {
        linkProps: {
          newTab: true,
        },
        link: `/chatbot/intent/form/?samples=${encodeURI((recommendation?.data.questions || []).join("\n"))}`,
        disabled: !recommendation?.data.questions,
      };
    }
    if (recommendation?.recommendation_type === "INTEGRATION") {
      return {
        onClick: () => {
          IntegrationModal.show({
            summary: recommendation?.data.request_summary,
            similarIntents: encodeURI((recommendation?.data.questions || []).join("\n")),
            encoded: true,
          });
        },
      };
    }
    if (recommendation?.recommendation_type === "STATIC") {
      return {
        linkProps: {
          newTab: true,
        },
        link: `/chatbot/intent/form/?response=${encodeURI(recommendation?.data.response)}&samples=${encodeURI(
          (recommendation?.data.questions || []).join("\n")
        )}`,
        disabled: !recommendation?.data.response,
      };
    }
    if (recommendation?.recommendation_type === "AGENT_FAILS") {
      return {
        onClick: () => {
          AgentFailModal.show({ reason: recommendation?.data.reason });
        },
      };
    }
    return {};
  }, []);

  const recommendationIconRender = useCallback((recommendation) => {
    switch (recommendation?.recommendation_type) {
      case "SAMPLE":
        return MdAdd;
      case "STATIC":
        return MdAdd;
      case "INTEGRATION":
        return MdAdd;
      case "ADD_ANSWER":
        return MdAdd;
      case "AGENT_FAILS":
        return MdWarning;
      default:
        return MdAdd;
    }
  }, []);
  const recommendationColumns = useMemo(
    () => [
      {
        render: () => <span />,
        style: {
          width: "10px",
        },
      },
      // {
      //   key: "id",
      //   label: "ID",
      //   style: {
      //     width: "10px",
      //   },
      // },
      {
        key: "recommendation_type",
        label: t("dashboard.intentRecommendation.recommendationType"),
        render: (item) => intentRecommendTypeMap[item.recommendation_type] || item.recommendation_type,
      },
      {
        key: "implementation_status",
        label: t("dashboard.intentRecommendation.implementationStatus"),
        render: (item) => implementationStatusMap[item.implementation_status] || item.implementation_status,
      },
      {
        key: "actions",
        label: t("common.actions"),
        actions: true,
        fixed: "right",
        render: (row) => (
          <div className="d-flex gap-1 justify-content-center">
            {row.recommendation_type && (
              <PRButton
                outline
                color="secondary"
                icon={recommendationIconRender(row)}
                size="sm"
                tooltipText="Add Recommendation"
                {...onAddRecommendationClick(row)}
              />
            )}
          </div>
        ),
      },
    ],
    [t, onAddRecommendationClick, recommendationIconRender]
  );

  const sessionColumns = useMemo(
    () => [
      {
        key: "quotes",
        label: t("dashboard.intentRecommendation.quotes"),
      },
      {
        key: "created",
        label: t("common.created"),
        render: (item) => (
          <div>
            <PRTooltip title={DateHelper.getDateTimeLocal(item.created).format("LLT")}>
              <span>{DateHelper.getDateTimeLocal(item.created).fromNow()}</span>
            </PRTooltip>
          </div>
        ),
      },
      {
        key: "actions",
        label: t("common.actions"),
        actions: true,
        fixed: "right",
        render: (row) => (
          <div className="d-flex gap-1 justify-content-center">
            <PRButton
              outline
              color="primary"
              icon={MdSend}
              link={`/contactcenter/history/${row.session}`}
              linkProps={{ newTab: true }}
              size="sm"
              tooltipText={t("dashboard.intentRecommendation.goToSession")}
            />
          </div>
        ),
      },
    ],
    [t]
  );

  return (
    <>
      {clonedChildren}
      <tr>
        <td colSpan={100} style={{ padding: 0 }}>
          <Collapse in={open}>
            <StyledPage order={3} title={row.name}>
              <Grid container spacing={2}>
                <Grid item sx={(theme) => ({ marginTop: theme.spacing(-1) })} xs={12}>
                  <Tabs value={tab} variant="scrollable" onChange={handleTabChange}>
                    <StyledTab label={t("common.summary")} />
                    <StyledTab label={t("common.sessions")} />
                    <StyledTab label={t("dashboard.intentRecommendation.recommendations")} />
                  </Tabs>
                </Grid>
                {tab === 0 && (
                  <Grid item xs={12}>
                    <PalTypography variant="p">{row.summary}</PalTypography>
                  </Grid>
                )}
                {tab === 1 && (
                  <Grid item xs={12}>
                    <StyledTable noCount noPagination columns={sessionColumns} data={row.sessions || []} order={3} />
                  </Grid>
                )}
                {tab === 2 && (
                  <Grid item xs={12}>
                    <StyledTable
                      noCount
                      noPagination
                      columns={recommendationColumns}
                      data={row.recommendations || []}
                      order={3}
                      rowRenderer={RecommendationRowRenderer}
                    />
                  </Grid>
                )}
              </Grid>
            </StyledPage>
          </Collapse>
        </td>
      </tr>
    </>
  );
};

const ClusterRowRenderer = ({ row, index, children }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const handleClick = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);
  const clonedChildren = useMemo(
    () =>
      React.isValidElement(children)
        ? React.cloneElement(children, {
            onClick: handleClick,
            children: React.Children.map(children.props.children, (child, idx) => {
              if (idx === 1 && React.isValidElement(child)) {
                return <td>{open ? <MdKeyboardArrowDown /> : <MdKeyboardArrowUp />}</td>;
              }
              return child;
            }),
          })
        : children,
    [children, open]
  );
  const columns = useMemo(
    () => [
      {
        render: () => <span />,
        style: {
          width: "10px",
        },
      },
      // {
      //   key: "id",
      //   label: "ID",
      //   style: {
      //     width: "10px",
      //   },
      // },
      {
        key: "name",
        label: t("common.name"),
      },
      // {
      //   key: "cluster_type",
      //   label: "Cluster Type",
      // },
      {
        key: "session_type",
        label: t("dashboard.intentRecommendation.sessionType"),
        render: (item) => sessionTypeMap[item.session_type] || item.session_type,
      },
      {
        key: "cluster_size",
        label: t("dashboard.intentRecommendation.clusterSize"),
      },
      {
        key: "created",
        label: t("common.created"),
        render: (item) => (
          <div>
            <PRTooltip title={DateHelper.getDateTimeLocal(item.created).format("LLT")}>
              <span>{DateHelper.getDateTimeLocal(item.created).fromNow()}</span>
            </PRTooltip>
          </div>
        ),
      },
    ],
    [t]
  );

  return (
    <>
      {clonedChildren}
      <tr>
        <td colSpan={100} style={{ padding: 0 }}>
          <Collapse in={open}>
            <StyledPage order={1} title={row.name}>
              <Grid container spacing={2}>
                <Grid item sx={(theme) => ({ marginTop: theme.spacing(-1) })} xs={12}>
                  <PalTypography variant="p">{row.summary}</PalTypography>
                </Grid>
                <Grid item xs={12}>
                  <StyledTable
                    noCount
                    noPagination
                    columns={columns}
                    data={row.sub_clusters || []}
                    order={1}
                    rowRenderer={SubClusterRowRenderer}
                  />
                </Grid>
              </Grid>
            </StyledPage>
          </Collapse>
        </td>
      </tr>
    </>
  );
};

const IntentRecommend = () => {
  const { t } = useTranslation();
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const tableRef = useRef(null);
  const [generator, setGenerator] = useState(null);
  const [triggerProcessingStatus, setTriggerProcessingStatus] = useState(1);

  const dispatch = useDispatch();

  const columns = [
    {
      render: () => <span />,
      style: {
        width: "10px",
      },
    },
    {
      key: "id",
      label: t("common.id"),
      style: {
        width: "10px",
      },
    },
    {
      key: "name",
      label: t("common.name"),
    },
    // {
    //   key: "cluster_type",
    //   label: "Cluster Type",
    // },
    {
      key: "session_type",
      label: t("dashboard.intentRecommendation.sessionType"),
      render: (item) => sessionTypeMap[item.session_type] || item.session_type,
    },
    {
      key: "recommended",
      label: t("dashboard.intentRecommendation.recommended"),
    },
    {
      key: "created",
      label: t("common.created"),
      render: (item) => (
        <div>
          <PRTooltip title={DateHelper.getDateTimeLocal(item.created).format("LLT")}>
            <span>{DateHelper.getDateTimeLocal(item.created).fromNow()}</span>
          </PRTooltip>
        </div>
      ),
    },
  ];

  const actions = [
    {
      label: t("dashboard.intentRecommendation.startRecommendations"),
      onClick: async () => {
        const result = await UpdateRecommendationsModal.show({
          projectId: currentProject.id,
          chatbotId: currentBot.id,
          chatbotName: currentBot.name,
        });
        if (result) {
          tableRef.current?.refresh();
          setTriggerProcessingStatus(triggerProcessingStatus + 1);
        }
      },
    },
  ];

  useEffect(() => {
    let checkTimeout;
    const checkStatus = async () => {
      let result;
      let activeDelay = activeRecommendationStatusDelayMap[recommendationGeneratorStatus.IN_PROGRESS];
      try {
        result = await dispatch(getRecommendationGenerator(currentProject.id, currentBot.id));
        setGenerator(result);

        activeDelay = activeRecommendationStatusDelayMap[result.status];
        if (activeDelay) {
          checkTimeout = setTimeout(() => {
            checkStatus();
          }, activeDelay);
        }
      } catch (e) {
        checkTimeout = setTimeout(() => {
          checkStatus();
        }, activeDelay);
      }
    };

    checkStatus();

    return () => {
      clearTimeout(checkTimeout);
    };
  }, [currentProject.id, currentBot.id, dispatch, triggerProcessingStatus]);

  return (
    <PRContainer
      actions={actions}
      description={t("dashboard.intentRecommendation.description")}
      name={t("common.chatbot")}
      parentName={t("dashboard.intentRecommendation")}
    >
      <Grid container spacing={2}>
        {generator && (
          <Grid item xs={12}>
            {generator && generator.status === recommendationGeneratorStatus.IN_PROGRESS && (
              <Alert icon={<CircularProgress size={20} />} severity="info">
                {t("dashboard.intentRecommendation.inProgressAlert")}
              </Alert>
            )}
            {generator && generator.status === recommendationGeneratorStatus.FAILED && (
              <Alert severity="error">{t("dashboard.intentRecommendation.failedAlert")}</Alert>
            )}
            {generator && generator.status === recommendationGeneratorStatus.SUCCESS && (
              <Alert severity="success">{t("dashboard.intentRecommendation.successAlert")}</Alert>
            )}
            {generator && generator.status === recommendationGeneratorStatus.INITIATED && (
              <Alert severity="info">{t("dashboard.intentRecommendation.initiatedAlert")}</Alert>
            )}
          </Grid>
        )}
        <Grid item xs={12}>
          <StyledTable
            columns={columns}
            order={0}
            rowRenderer={ClusterRowRenderer}
            storageKey={tableFilterStorageKey.intentRecommend}
            tableRef={tableRef}
            url={apiUrlChatbot.getClusterModelViewSet.format(currentProject.id, currentBot.id)}
          />
        </Grid>
      </Grid>
    </PRContainer>
  );
};

const UpdateRecommendationsModal = withCardon(
  function UpdateRecommendations({ get, projectId, chatbotId, chatbotName }) {
    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useState(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000));
    const [toDate, setToDate] = useState(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000));
    const { t } = useTranslation();
    const [loading, q] = useLoading();
    const onClick = async () => {
      if (
        !(await DialogHelper.showQuestionYesNo(
          "",
          <>
            {t("dashboard.intentRecommendation.startRecommendationsConfirm1")}
            <b>{chatbotName}</b>
            {t("dashboard.intentRecommendation.startRecommendationsConfirm2")}
          </>
        ))
      ) {
        return;
      }
      await q(dispatch(updateRecommendations(projectId, chatbotId, fromDate, toDate)));
      get(true)();
    };
    return (
      <PRModal
        loading={loading}
        title={t("dashboard.intentRecommendation.startRecommendations")}
        onClick={onClick}
        onClose={get(false)}
      >
        <Label>{t("dashboard.intentRecommendation.fromDate")}:</Label>
        <PRDate name="From Date" value={fromDate} onChange={setFromDate} />
        <Label className="mt-2">{t("dashboard.intentRecommendation.toDate")}:</Label>
        <PRDate name="To Date" value={toDate} onChange={setToDate} />
      </PRModal>
    );
  },
  {
    destroyOnHide: true,
  }
);

const ClusterInfoModal = withCardon(
  function ClusterInfo({ get, projectId, clusterId, summary }) {
    const { t } = useTranslation();
    // const renderedMd = RenderHelper.renderMd(summary, { html: true });
    // const sanitizedHtml = SanitizeHelper.html(renderedMd);

    // const htmlDom = <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} style={{ width: "100%" }} />;
    return (
      <PRModal size="xl" submitText="" title="Cluster Info" onClose={get(false)}>
        {/* {htmlDom} */}
        <PRTable
          columns={[
            {
              key: "id",
              label: t("common.id"),
            },
            // {
            //   key: "is_handled",
            //   label: t("dashboard.intentRecommendation.isHandled"),
            // render: (item) => (item.is_handled ? t("common.yes") : t("common.no")),
            // },
            {
              key: "quotes",
              label: t("dashboard.intentRecommendation.quotes"),
            },
            {
              key: "created",
              label: t("common.created"),
              render: (item) => (
                <div>
                  <PRTooltip title={DateHelper.getDateTimeLocal(item.created).format("LLT")}>
                    <span>{DateHelper.getDateTimeLocal(item.created).fromNow()}</span>
                  </PRTooltip>
                </div>
              ),
            },
          ]}
          url={apiUrlChatbot.getClusteredSessions.format(projectId, clusterId)}
        />
      </PRModal>
    );
  },
  {
    destroyOnHide: true,
  }
);

// const UpdateClustersModal = withCardon(
//   function UpdateClusters({ get, projectId, chatbotId }) {
//     const dispatch = useDispatch();
//     const [date, setDate] = useState(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000));
//     const [loading, q] = useLoading();
//     const onClick = async () => {
//       // console.log("date", date);
//       await q(dispatch(updateClusters(projectId, chatbotId, date)));
//       // AlertHelper.showSuccess("Clusters are updated successfully");
//       get(true)();
//     };
//     return (
//       <PRModal loading={loading} title="Update Clusters" onClick={onClick} onClose={get(false)}>
//         <Label>Start From:</Label>
//         <PRDate name="start_date" value={date} onChange={setDate} />
//       </PRModal>
//     );
//   },
//   {
//     destroyOnHide: true,
//   }
// );

const IntegrationModal = withCardon(
  function Integration({ get, summary, similarIntents, encoded = false }) {
    const { t } = useTranslation();
    const link = `/chatbot/intent/form/?samples=${encoded ? similarIntents : encodeURI(similarIntents)}`;
    return (
      <PRModal
        submitLink={link}
        submitLinkProps={{ newTab: true }}
        title={t("common.integration")}
        onClick={get(false)}
        onClose={get(false)}
      >
        <Label>{summary}</Label>
      </PRModal>
    );
  },
  {
    destroyOnHide: true,
  }
);

const AgentFailModal = withCardon(
  function AgentFail({ get, reason }) {
    const { t } = useTranslation();
    return (
      <PRModal submitText="" title={t("dashboard.intentRecommendation.agentFails")} onClose={get(false)}>
        <Label>{reason}</Label>
      </PRModal>
    );
  },
  {
    destroyOnHide: true,
  }
);

export default IntentRecommend;
