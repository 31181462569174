import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import CodeEditor from "~components/CodeEditor";
import { dialogComponentsAuthTypeOptions } from "~constants";
import store from "~store";
import { setScenarioFormState } from "~store/dialogComponents/scenarioManager/actions";
import {
  selectScenarioManager,
  selectScenarioPreProcessLowCodeScript,
} from "~store/dialogComponents/scenarioManager/selectors";

import useMonacoCodeCompletion from "../../useMonacoCodeCompletion";
import useMonacoScenarioEditorLowCode from "../../useMonacoScenarioEditorLowCode";
import useMonacoScenarioEditorVariables from "../../useMonacoScenarioEditorVariables";

export default function PreprocessLowCode() {
  const dispatch = useDispatch();

  const preProcessLowCodeScript = useSelector(selectScenarioPreProcessLowCodeScript);
  const [codeEditor, setCodeEditor] = useState(null);

  const handleChangeAuthType = (value) => {
    dispatch(setScenarioFormState("preprocess_low_code_script", value));
  };
  const handleGetAiContext = () => {
    const intentState = selectScenarioManager(store.getState());
    const context = `"You are currently on the Intent page used to create messages that the user will see in the Chatbot software. With low-code, you can send various messages to the user and receive input. The code editor window you are in is for the pre-process low-code section. Additional information about the Intent is provided in the JSON below:

\`\`\`json
${JSON.stringify(intentState, null, 2)}
\`\`\``;

    return context;
  };

  useMonacoScenarioEditorVariables(codeEditor?.editor);
  useMonacoScenarioEditorLowCode(codeEditor?.editor);
  useMonacoCodeCompletion(codeEditor?.editor, {
    getRelevantContext: handleGetAiContext,
  });
  return (
    <div className="w-100">
      <CodeEditor
        defaultLanguage="python"
        language={"python"}
        options={dialogComponentsAuthTypeOptions}
        value={preProcessLowCodeScript}
        onChange={handleChangeAuthType}
        onMount={setCodeEditor}
      />
    </div>
  );
}
