import {
  apiUrlIntegrationModule,
  apiUrlIntegrationModuleRegistry,
  apiUrlIntegrationModuleVersion,
  apiUrlIntegrationTriggerList,
} from "~constants";
import Network from "~helpers/Network";

import * as at from "./actionTypes";

export const setModule = (payload) => ({
  type: at.SET_MODULE,
  payload,
});

export const setModuleList = (payload) => ({
  type: at.SET_MODULE_LIST,
  payload,
});
export const setModuleIntegration = (payload) => ({
  type: at.SET_MODULE_INTEGRATION,
  payload,
});
export const setModuleIntegrationList = (payload) => ({
  type: at.SET_MODULE_INTEGRATION_LIST,
  payload,
});

export const setModuleVersion = (payload) => ({
  type: at.SET_MODULE_VERSION,
  payload,
});
export const setTriggerList = (payload) => ({
  type: at.SET_TRIGGER_LIST,
  payload,
});

// ----------------- API -----------------

export const getModule = (projectId, id) => () => {
  const url = `${apiUrlIntegrationModule.get.format(projectId)}${id}/`;

  return Network.request(url, {
    onSuccess: setModule,
    loading: true,
  });
};
export const getModuleList = (projectId, id) => () => {
  const url = `${apiUrlIntegrationModule.get.format(projectId)}`;

  return Network.request(url, {
    onSuccess: ({ results = [] }) => setModuleList(results),
    loading: true,
  });
};

export const getModuleVersion = (projectId, id) => () => {
  const url = `${apiUrlIntegrationModuleVersion.get.format(projectId)}${id}/`;

  return Network.request(url, {
    onSuccess: setModuleVersion,
    loading: true,
  });
};

export const deleteModuleVersion = (projectId, id) => () => {
  const url = `${apiUrlIntegrationModuleVersion.get.format(projectId)}${id}/`;

  return Network.request(url, {
    method: "DELETE",
    loading: true,
  });
};

export const getTriggerList = () => () => {
  const url = apiUrlIntegrationTriggerList.get;
  return Network.request(url, {
    onSuccess: setTriggerList,
    loading: true,
  });
};

export const createOrUpdateModuleVersion =
  (projectId, moduleId, data, options = {}) =>
  () => {
    let url = apiUrlIntegrationModule.newVersion.format(projectId, moduleId);
    if (data?.id) {
      url = `${apiUrlIntegrationModuleVersion.get.format(projectId)}${data.id}/`;
    }
    return Network.request(url, {
      method: data?.id ? "PUT" : "POST",
      data,
      loading: true,
      successMsg: true,
      ...options,
    });
  };

export const createOrUpdateModule = (projectId, data) => () => {
  let url = apiUrlIntegrationModule.get.format(projectId);
  if (data.id) {
    url += `${data.id}/`;
  }
  return Network.request(url, {
    method: data.id ? "PUT" : "POST",
    data,
    loading: true,
    successMsg: true,
  });
};

export const deleteModule = (projectId, id) => () => {
  let url = `${apiUrlIntegrationModule.get.format(projectId)}${id}/`;
  return Network.request(url, {
    method: "DELETE",
    loading: true,
  });
};

export const getModuleIntegration = (projectId, id) => () => {
  const url = `${apiUrlIntegrationModuleRegistry.get.format(projectId)}${id}/`;

  return Network.request(url, {
    onSuccess: setModuleIntegration,
    loading: true,
  });
};

export const getModuleIntegrationList = (projectId) => () => {
  const url = apiUrlIntegrationModuleRegistry.get.format(projectId);

  return Network.request(url, {
    onSuccess: setModuleIntegrationList,
    loading: true,
  });
};

export const deleteModuleIntegration = (projectId, id) => () => {
  const url = `${apiUrlIntegrationModuleRegistry.get.format(projectId)}${id}/`;

  return Network.request(url, {
    method: "DELETE",
    loading: true,
  });
};

export const createOrUpdateModuleIntegration = (projectId, moduleRegistryId, data) => () => {
  let url = apiUrlIntegrationModule.integrate.format(projectId, moduleRegistryId, data?.version);
  if (data?.id) {
    url = `${apiUrlIntegrationModuleRegistry.get.format(projectId)}${data.id}/`;
  }
  return Network.request(url, {
    method: data?.id ? "PUT" : "POST",
    data,
    loading: true,
    successMsg: true,
  });
};
