import { apiUrlWhatsappTemplate } from "~constants";
import Network from "~helpers/Network";

import * as actionTypes from "./actionTypes";

export const setWhatsappTemplateList = (payload) => ({
  type: actionTypes.SET_WHATSAPP_TEMPLATE_LIST,
  payload,
});

export const setWhatsappTemplate = (payload) => ({
  type: actionTypes.SET_WHATSAPP_TEMPLATE,
  payload,
});

export const getWhatsappTemplateList = (projectId) => () => {
  const url = `${apiUrlWhatsappTemplate.getList.format(projectId)}`;
  return Network.request(url, {
    loading: true,
    params: {
      limit: 9999,
    },
    onSuccess: ({ results = [] }) => setWhatsappTemplateList(results),
  });
};

export const sendWhatsappTemplate = (projectId, id, data) => () => {
  const url = `${apiUrlWhatsappTemplate.send.format(projectId, id)}`;
  return Network.request(url, {
    method: "POST",
    data,
    loading: true,
    successMsg: "Whatsapp template sent successfully",
  });
};

export const getWhatsappTemplate = (projectId, id) => () => {
  const url = `${apiUrlWhatsappTemplate.get.format(projectId, id)}`;
  return Network.request(url, {
    loading: true,
    onSuccess: setWhatsappTemplate,
  });
};

export const deleteWhatsappTemplate = (projectId, id) => () => {
  const url = `${apiUrlWhatsappTemplate.get.format(projectId, id)}`;
  return Network.request(url, {
    method: "DELETE",
    loading: true,
    successMsg: "Whatsapp template deleted successfully",
  });
};

export const syncWhatsappTemplate = (projectId) => () => {
  const url = `${apiUrlWhatsappTemplate.sync.format(projectId)}`;
  return Network.request(url, {
    method: "POST",
    loading: true,
    successMsg: "Whatsapp template synced successfully",
  });
};

export const createOrUpdateWhatsappTemplate = (projectId, data) => () => {
  let url = `${apiUrlWhatsappTemplate.getList.format(projectId)}`;
  if (data.id) {
    url += `${data.id}/`;
  }
  const form = new FormData();
  for (const key in data) {
    const dataItem = data[key];
    if (dataItem === undefined) continue;
    if (dataItem instanceof File) {
      form.append(key, dataItem, dataItem.name);
      continue;
    }
    const value = typeof dataItem === "object" ? JSON.stringify(dataItem) : dataItem;
    form.append(key, value);
  }
  return Network.request(url, {
    method: data.id ? "PUT" : "POST",
    data: form,
    loading: true,
    successMsg: `Whatsapp template ${data.id ? "updated" : "created"} successfully`,
  });
};
