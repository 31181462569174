import PRSwitch from "~components/Generic/PRSwitch";

import IntentTagsEdit from "./IntentTagsEdit";
import IntentTagsList from "./IntentTagsList";

function IntentTags() {
  const routes = [
    { path: "/chatbot/intent-tags/form/:id?", component: IntentTagsEdit },
    { path: "/chatbot/intent-tags/", component: IntentTagsList },
    { path: "/chatbot/", to: "chatbot/intent-tags/", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}

export default IntentTags;
