import { apiUrlChatbot } from "~constants";
import Network from "~helpers/Network";

// export const setIntentRecord = (payload) => ({
//   type: at.SET_INTENT_TAG,
//   payload,
// });

// -----------  API ------------

export const updateIntentRecord =
  (projectId, data, options = {}) =>
  (_dispatch, _getState) => {
    const url = `${apiUrlChatbot.getIntentRecords.format(projectId)}${data.id}/`;

    return Network.request(url, {
      loading: true,
      // onSuccess: setIntentRecord,
      method: "PUT",
      data,
      ...options,
    });
  };

export const createIntentRecordSamples =
  (projectId, chatbotId, intentId, data, options = {}) =>
  (_dispatch, _getState) => {
    const url = `${apiUrlChatbot.getIntentRecordSampleIntent.format(projectId, chatbotId, intentId)}`;

    return Network.request(url, {
      loading: true,
      // onSuccess: setIntentRecord,
      method: "POST",
      data,
      ...options,
    });
  };

export const getIntentRecordSamples =
  (projectId, chatbotId, intentId, options = {}) =>
  (_dispatch, _getState) => {
    const url = `${apiUrlChatbot.getIntentRecordSampleIntent.format(projectId, chatbotId, intentId)}`;

    return Network.request(url, {
      loading: true,
      method: "GET",
      ...options,
    });
  };

export const getIntentRecord =
  (projectId, intentRecordId, options = {}) =>
  (_dispatch, _getState) => {
    const url = `${apiUrlChatbot.getIntentRecords.format(projectId)}${intentRecordId}/`;

    return Network.request(url, {
      loading: true,
      ...options,
    });
  };
