import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

export const initState = {
  open: false,
};

const guide = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.OPEN_GUIDE:
        draft.open = action.payload;
    }
  });

export default guide;
