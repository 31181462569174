import { useCallback, useEffect, useState } from "react";

import { isEqual } from "lodash";

import StorageHelper from "~helpers/StorageHelper";

export const useStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(initialValue);

  useEffect(() => {
    (async () => {
      let value = initialValue;
      try {
        value = await StorageHelper.get(key);
        if (value == null && initialValue !== null) {
          await StorageHelper.set(key, initialValue);
        }
      } catch (error) {
        console.error(error);
      }
      if (value != null && !isEqual(value, initialValue)) {
        setStoredValue(value);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  const setValue = useCallback(
    async (value) => {
      try {
        let valueToStore = value;
        const isValueFunction = value instanceof Function;
        if (isValueFunction) {
          const item = await StorageHelper.get(key);
          const storedValue = item || initialValue;
          valueToStore = value(storedValue);
        }

        await StorageHelper.set(key, valueToStore);
        setStoredValue(valueToStore);
      } catch (error) {
        console.error(error);
      }
    },
    // initialValue is not a dependency because it is only used on the first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key]
  );

  return [storedValue, setValue];
};
