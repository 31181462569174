import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import Network from "~helpers/Network";

import {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  userForgetPasswordResetSuccess,
  userForgetPasswordResetError,
} from "./actions";
import { FORGET_PASSWORD, FORGET_PASSWORD_RESET } from "./actionTypes";

//Include Both Helper File with needed methods

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    const response = yield call(Network.request, "/reset-password/", {
      method: "POST",
      data: { email: user.email, answer: user.answer },
      noBearer: true,
    });
    if (response) {
      yield put(
        userForgetPasswordSuccess(
          "Reset link has been sent to your email address if it exists in our system. Please check your email."
        )
      );
    }
  } catch (error) {
    let message = "";
    if (error.response.status === 404) {
      message = "Endpoint not found";
    }
    yield put(userForgetPasswordError(message));
  }
}
function* forgetUserReset({ payload: { token, data } }) {
  try {
    const response = yield call(Network.request, `/new-password/${token}`, {
      method: "PUT",
      data,
      noBearer: true,
    });
    if (response) {
      yield put(userForgetPasswordResetSuccess("Password has been reset successfully. Redirecting to login page..."));
    }
  } catch (error) {
    let message = "";
    if (error.response.status === 404) {
      message = "Endpoint not found";
    }
    yield put(userForgetPasswordResetError(message));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
  yield takeEvery(FORGET_PASSWORD_RESET, forgetUserReset);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
