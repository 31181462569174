import { useEffect, useMemo, useState } from "react";

import { withCardon } from "cardon";
import { useFormik } from "formik";
import { cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";
import { MdHelp } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Col, Label, Modal, Row } from "reactstrap";
import * as Yup from "yup";

import useLoading from "~common/hooks/useLoading";
import PRButton from "~components/Generic/PRButton";
import PRInput from "~components/Generic/PRInput";
import { modalZIndex } from "~components/Generic/PRModal";
import PRSelect from "~components/Generic/PRSelect";
import PRTooltip from "~components/Generic/PRTooltip";
import { organizationResourceType, organizationResourceTypeOptions } from "~constants";
import DateHelper from "~helpers/DateHelper";
import debounceAsync from "~helpers/debounceAsync";
import Utils from "~helpers/Utils";
import {
  createOrUpdateFilter,
  createOrUpdateResource,
  getFilter,
  getFilterList,
  getReservationAnnouncementList,
  getResource,
  getResourceManagerUserList,
} from "~store/organization/actions";
import { selectCurrentProject } from "~store/user/selectors";

function AddEditOrganizationResource({ get, resourceId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, enqueue] = useLoading();
  const currentProject = useSelector(selectCurrentProject);
  const [filters, setFilters] = useState([]);
  const [announcementList, setAnnouncementList] = useState([]);

  useEffect(() => {
    if (!currentProject?.id) return;
    dispatch(
      getReservationAnnouncementList(currentProject?.id, {
        params: { limit: 9999 },
      })
    ).then((response) => setAnnouncementList(response.results));
  }, [currentProject?.id, dispatch]);

  const formik = useFormik({
    initialValues: {
      name: "",
      member_filter: undefined,
      resource_type: organizationResourceType.PUBLIC,
      allow_multiple_reservation: false,
      allow_dynamic_reservation: false,
      remind_reservation_time: 0,
      send_survey: false,
      remind_reservation: false,
      resource_managers: [],
      announcement: {
        id: 87,
      },
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("component.formik.required").format(t("common.name"))),
      // member_filter: Yup.mixed().required("Member filter is required"),
      resource_type: Yup.mixed().required(
        t("component.formik.required").format(t("component.addEditOrganizationResource.resourceType"))
      ),
      allow_multiple_reservation: Yup.boolean().required("Allow multiple reservation is required"),
      allow_dynamic_reservation: Yup.boolean().required("Allow dynamic reservation is required"),
      // send_survey: Yup.boolean().required("Send survey is required"),
      announcement: Yup.mixed()
        .optional()
        .nullable()
        .when("send_survey", {
          is: true,
          then: Yup.object()
            .nullable()
            .required("Announcement is required")
            .shape({
              id: Yup.number().required("Announcement is required"),
            }),

          otherwise: Yup.object().nullable().optional(),
        }),
    }),
    onSubmit: async (values) => {
      let response;
      let payload = {
        ...values,
      };
      try {
        let filterObj;
        if (formik.values.member_filter) {
          filterObj = filters.find((item) => item.id === formik.values.member_filter);
          filterObj = Utils.getWithNonAllowedKeys(cloneDeep(filterObj), ["id"], ["member_data_field"]);
          filterObj.is_template = false;
          const filterInstanceResult = await enqueue(dispatch(createOrUpdateFilter(filterObj, currentProject.id)));
          payload.member_filter = filterInstanceResult?.id;
        }

        //map resource_managers's object to only allow id
        payload = {
          ...payload,
          resource_managers: payload.resource_managers.map((item) => ({ id: item.id })),
        };
        response = await enqueue(dispatch(createOrUpdateResource(payload, currentProject.id)));
      } catch {}
      get(response)();
    },
  });

  const filterOptions = useMemo(() => {
    return filters?.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }, [filters]);
  useEffect(() => {
    dispatch(getFilterList(currentProject.id)).then((response) => setFilters(response?.results || []));
  }, [dispatch, currentProject.id]);

  useEffect(() => {
    if (!formik.values.member_filter || !filters.length) return;

    const ifIsNotExists = !filters.find((item) => item.id === formik.values.member_filter);

    if (ifIsNotExists) {
      dispatch(getFilter(formik.values.member_filter, currentProject.id)).then((response) => {
        if (!filters.find((item) => item.id === response.id)) {
          setFilters((prev) => [...prev, response]);
        }
      });
    }
  }, [filters, formik.values.member_filter, dispatch, currentProject.id]);

  const handleChange = (key) => (value) => {
    //convert moment's day to minutes
    // if (key === "remind_reservation_time") {
    //   value = value.diff(DateHelper.getDateTimeLocal().startOf("day"), "minutes");
    // }

    formik.setFieldValue(key, value || null);
  };
  const handleChangeName = (key) => (e) => {
    formik.setFieldValue(key, e.target.value);
  };
  const handleCheckboxChange = (key) => (e) => {
    formik.setFieldValue(key, e.target.checked);
  };

  useEffect(() => {
    if (resourceId) {
      enqueue(
        dispatch(getResource(resourceId)).then((response) => {
          // let durationDate = DateHelper.getDateTimeLocal().startOf("day");
          // durationDate.add(response.allow_dynamic_reservation_duration, "minutes");
          formik.setValues({
            id: response.id,
            name: response.name,
            member_filter: response.member_filter,
            resource_type: response.resource_type,
            allow_multiple_reservation: response.allow_multiple_reservation,
            allow_dynamic_reservation: response.allow_dynamic_reservation,
            remind_reservation_time: response.remind_reservation_time,
            send_survey: response.send_survey,
            remind_reservation: response.remind_reservation,
            announcement: response.announcement,
            resource_managers: response.resource_managers,
          });
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceId, dispatch]);
  const handleLoadOptions = debounceAsync(async (searchText, callback, signal) => {
    const list = await dispatch(
      getResourceManagerUserList(currentProject.id, {
        signal,
        loading: false,
        params: {
          email__icontains: searchText,
        },
      })
    );
    const options = list?.results || [];

    // if (formik.values.member && !options.find((item) => item.value === formik.values.member)) {
    //   const member = await dispatch(
    //     getMember(currentProject.id, formik.values.member, {
    //       loading: false,
    //       onSuccess: () => {},
    //     })
    //   );
    //   if (member) {
    //     options.push({
    //       ...member,
    //     });
    //   }
    // }
    return options;
  });
  return (
    <Modal centered isOpen={true} size="lg" zIndex={modalZIndex}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">{resourceId ? t("common.edit") : t("common.create")}</h5>
        <button aria-label="Close" className="close" data-dismiss="modal" onClick={get(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body mb-3">
        <Row className="g-2">
          <Col xs={12}>
            <Label htmlFor="slot-validation-text">{t("common.name")}</Label>
            <PRInput
              disabled={loading}
              invalid={formik.touched.name && formik.errors.name}
              placeholder={t("common.namePlaceholder")}
              type="text"
              value={formik.values.name}
              onChange={handleChangeName("name")}
            />
          </Col>
          <Col xs={12}>
            <Label htmlFor="slot-validation-text">{t("common.filter")}</Label>
            <PRSelect
              isPrimitiveValue
              invalid={formik.touched.member_filter && formik.errors.member_filter}
              isDisabled={loading}
              isLoading={loading}
              options={filterOptions}
              placeholder={t("component.addEditOrganizationResource.selectFilterPlaceholder")}
              value={formik.values.member_filter}
              onChange={handleChange("member_filter")}
            />
          </Col>
          <Col xs={12}>
            <Label htmlFor="slot-validation-text">{t("common.type")}</Label>
            <PRSelect
              isPrimitiveValue
              isClearable={false}
              options={organizationResourceTypeOptions}
              placeholder={t("component.addEditOrganizationResource.selectTypePlaceholder")}
              value={formik.values.resource_type}
              onChange={handleChange("resource_type")}
            />
          </Col>
          <Col xs={12}>
            <Label htmlFor="slot-validation-text">Manager</Label>
            {/* <PRSelect
              isMulti
              isPrimitiveValue
              isClearable={false}
              options={organizationResourceTypeOptions}
              placeholder="Select type"
              value={formik.values.resource_type}
              onChange={handleChange("resource_type")}
            /> */}
            <PRSelect
              // isPrimitiveValue
              isMulti
              lazy
              invalid={formik.touched.resource_managers && formik.errors.resource_managers}
              labelSelector="email"
              loadOptions={handleLoadOptions}
              placeholder="Select manager"
              value={formik.values.resource_managers}
              valueSelector="id"
              onChange={handleChange("resource_managers")}
            />
          </Col>
          <Col xs={12}>
            <Label className="d-flex align-items-center" htmlFor="slot-validation-text">
              Dynamic Reservation
              <span className="ms-1 fs-6 d-flex align-items-center lh-1">
                <PRTooltip title="This option allows users to create reservations on the fly without having to create a reservation slot. Can not be changed after the resource is created.">
                  <span>
                    <MdHelp className="fs-6 ms-1" />
                  </span>
                </PRTooltip>
              </span>
            </Label>
            <PRInput
              checked={formik.values.allow_dynamic_reservation}
              disabled={loading}
              invalid={formik.touched.allow_dynamic_reservation && formik.errors.allow_dynamic_reservation}
              type="checkbox"
              onChange={handleCheckboxChange("allow_dynamic_reservation")}
            />
          </Col>
          <Col xs={12}>
            <Label htmlFor="slot-validation-text">Enable Announcement</Label>
            <PRInput
              checked={formik.values.send_survey}
              disabled={loading}
              invalid={formik.touched.send_survey && formik.errors.send_survey}
              type="checkbox"
              onChange={handleCheckboxChange("send_survey")}
            />
          </Col>
          {formik.values.send_survey && (
            <Col xs={12}>
              <Label htmlFor="slot-validation-text">Announcement</Label>
              <PRSelect
                isPrimitiveValue
                invalid={
                  (formik.touched.announcement?.id || formik.touched.announcement) &&
                  (formik.errors.announcement?.id || formik.errors.announcement)
                }
                isClearable={false}
                labelSelector="name"
                options={announcementList}
                placeholder="Select type"
                value={formik.values.announcement?.id}
                valueSelector="id"
                onChange={handleChange("announcement.id")}
              />
            </Col>
          )}

          <Col xs={12}>
            <Label htmlFor="slot-validation-text">Enable Reminder</Label>
            <PRInput
              checked={formik.values.remind_reservation}
              disabled={loading}
              invalid={formik.touched.remind_reservation && formik.errors.remind_reservation}
              type="checkbox"
              onChange={handleCheckboxChange("remind_reservation")}
            />
          </Col>
          {formik.values.remind_reservation && (
            <Col xs={12}>
              <Label htmlFor="slot-validation-text">
                Send a reminder{" "}
                {DateHelper.formatMoment(formik.values.remind_reservation_time, "minutes", "d [days]  h [hrs] m [min]")}{" "}
                before the reservation.
              </Label>
              <PRInput
                name="remind_reservation_time"
                type="number"
                value={formik.values.remind_reservation_time}
                onChange={formik.handleChange}
              />
            </Col>
          )}
          <Col xs={12}>
            <Label htmlFor="slot-validation-text">Allow Multiple Reservation</Label>
            <PRInput
              checked={formik.values.allow_multiple_reservation}
              disabled={loading}
              invalid={formik.touched.allow_multiple_reservation && formik.errors.allow_multiple_reservation}
              type="checkbox"
              onChange={handleCheckboxChange("allow_multiple_reservation")}
            />
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <PRButton outline color="primary" data-dismiss="modal" onClick={get(false)}>
          {t("common.close")}
        </PRButton>
        <PRButton color="primary" disabled={loading} loading={loading} type="button" onClick={formik.handleSubmit}>
          {resourceId ? t("common.update") : t("common.create")}
        </PRButton>
      </div>
    </Modal>
  );
}
const AddEditOrganizationResourceModal = withCardon(AddEditOrganizationResource, { destroyOnHide: true });
export default AddEditOrganizationResourceModal;
