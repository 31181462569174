import { useEffect, useMemo, useState } from "react";

import { useMonaco } from "@monaco-editor/react";
import { PalTextField } from "@palamar/fe-library";
import { withCardon } from "cardon";
import classNames from "classnames";
import { useFormik } from "formik";
import { cloneDeep, groupBy } from "lodash";
import { useTranslation } from "react-i18next";
import {
  MdAdd,
  MdClose,
  MdContentCopy,
  MdDataObject,
  MdDelete,
  MdDescription,
  MdEmail,
  MdNotifications,
  MdOpenInNew,
  MdPhoneAndroid,
  MdSettings,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Alert, Col, Label, Row } from "reactstrap";

import { Grid } from "@mui/material";

import useLoading from "~common/hooks/useLoading";
import useMonacoDecorator from "~common/hooks/useMonacoDecorator";
import useProjectChange from "~common/hooks/useProjectChange";
import PRButton, { PRButtonGroup } from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import { PRDividerLabel } from "~components/Generic/PRDivider";
import PRInput, { PRTextArea } from "~components/Generic/PRInput";
import PRModal from "~components/Generic/PRModal";
import PRPage from "~components/Generic/PRPage";
import PRSelect from "~components/Generic/PRSelect";
import PRTooltip from "~components/Generic/PRTooltip";
import {
  messageTemplateCurlyFormat,
  settingsLanguageMap,
  settingsLanguageOptions,
  settingsTemplateFormat,
  settingsTemplatePlatformType,
  settingsTemplatePlatformTypeMap,
} from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import DialogHelper from "~helpers/DialogHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import LoadingHelper from "~helpers/LoadingHelper";
import Utils from "~helpers/Utils";
import { getResource, getResources } from "~store/organization/actions";
import { selectResource } from "~store/organization/selectors";
import {
  createOrUpdateMessageTemplateDefinition,
  deleteMessageTemplateDefinition,
  deleteMessageTemplateDefinitionFile,
  getMessageTemplateDefinitionList,
  getTemplateTypeList,
  sendTestMessageTemplate,
  uploadMessageTemplateDefinitionFile,
} from "~store/settings/messageTemplate/actions";
import { selectMessageTemplateDefinitionList, selectTemplateTypeList } from "~store/settings/messageTemplate/selectors";
import { selectCurrentProject, selectUserInfo } from "~store/user/selectors";

import TemplateUploadModal from "./TemplateUploadModal";
import { messageTemplateTextDescriptionMap } from "../MessageTemplateDefinition";

import "./style.scss";

const notificationTypeOptions = [
  {
    value: settingsTemplatePlatformType.EMAIL,
    label: "constants.settingsTemplatePlatformType.email",
    icon: MdEmail,
  },
  {
    value: settingsTemplatePlatformType.SMS,
    label: "constants.settingsTemplatePlatformType.sms",
    icon: MdPhoneAndroid,
  },
  {
    value: settingsTemplatePlatformType.PUSH_NOTIFICATION,
    label: "constants.settingsTemplatePlatformType.pushNotification",
    icon: MdNotifications,
  },
];
const MessageTemplateEdit = () => {
  const { t } = useTranslation();
  const { id: keyStr } = useParams();

  const keyChunks = keyStr.split("::");
  const key = keyChunks?.[0];
  const isDefaultTemplate = keyChunks?.[1] === "default";
  const resourceId = keyChunks?.[2] ? parseInt(keyChunks?.[2]) : null;
  const { currentPageUrl } = useMemo(() => {
    return {
      currentPageUrl: isDefaultTemplate ? "/settings/system-message-template/" : "/settings/message-template/",
    };
  }, [isDefaultTemplate]);

  const [codeEditor, setCodeEditor] = useState(null);
  // const [selectedTemplateGroup, setSelectedTemplateGroup] = useState(null);

  const [selectedNewLanguage, setSelectedNewLanguage] = useState(null); //for new language
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedNotificationType, setSelectedNotificationType] = useState(settingsTemplatePlatformType.EMAIL);
  const [selectedTemplateLanguage, setSelectedTemplateLanguage] = useState(null);
  const [selectedGroupTemplate, setSelectedGroupTemplate] = useState(null);
  const [templateFiles, setTemplateFiles] = useState([]);

  // const messageTemplate = useSelector(selectMessageTemplateDefinition);
  const currentProject = useSelector(selectCurrentProject);
  const templateTypeList = useSelector(selectTemplateTypeList);
  const resource = useSelector(selectResource);
  const userInfo = useSelector(selectUserInfo);
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const monaco = useMonaco();

  const messageTemplateDefinitionListRaw = useSelector(selectMessageTemplateDefinitionList);
  const messageTemplateDefinitionList = useMemo(() => {
    const nonDuplicateList = [];
    for (const item of messageTemplateDefinitionListRaw) {
      const isExist = nonDuplicateList.find(
        (i) =>
          i.language === item.language &&
          i.platform === item.platform &&
          i.template_type === item.template_type &&
          i.default_for_projects === item.default_for_projects &&
          i.default_for_resources === item.default_for_resources &&
          i.resource === item.resource
      );
      if (!isExist) {
        nonDuplicateList.push(item);
      }
    }

    return nonDuplicateList;
  }, [messageTemplateDefinitionListRaw]);

  const selectedTemplateType = useMemo(() => {
    return templateTypeList.find((item) => item.key === key);
  }, [key, templateTypeList]);

  const relatedMessageTemplateDefinitionList = useMemo(() => {
    const clonedMessageTemplateDefinitionList = cloneDeep(messageTemplateDefinitionList);
    let filtered = clonedMessageTemplateDefinitionList.filter(
      (item) =>
        item.template_type === key &&
        !!item.default_for_projects === isDefaultTemplate &&
        (selectedTemplateType?.type === "resource"
          ? resourceId
            ? item.resource === resourceId || !item.resource
            : !item.resource
          : true)
    );

    if (!isDefaultTemplate) {
      let filteredDefault = clonedMessageTemplateDefinitionList.filter(
        (item) => item.template_type === key && !!item.default_for_projects === true
      );

      for (const messageTemplate of filteredDefault) {
        const matchedMessageTemplate = filtered.find(
          (item) =>
            item.language === messageTemplate.language &&
            item.platform === messageTemplate.platform &&
            item.template_type === messageTemplate.template_type &&
            // item.default_for_projects === messageTemplate.default_for_projects &&
            item.default_for_resources === messageTemplate.default_for_resources &&
            item.default_for_surveys === messageTemplate.default_for_surveys &&
            item.resource === messageTemplate.resource &&
            item.survey === messageTemplate.survey
        );
        if (!matchedMessageTemplate) {
          // messageTemplate.fromDefault = true;
          // if (
          //   messageTemplate?.default_for_projects &&
          //   (resourceId ? messageTemplate.default_for_resources && messageTemplate.resource === resourceId : true)
          // ) {
          //   messageTemplate.originalContent = messageTemplate.content;
          //   messageTemplate.originalTitle = messageTemplate.title;
          // }

          filtered.push({
            ...messageTemplate,
            default_for_projects: false,
            default_for_resources: false,
            originalContent: messageTemplate.content,
            originalTitle: messageTemplate.title,
            id: null,
          });
        } else {
          // matchedMessageTemplate.fromDefault = true;

          matchedMessageTemplate.originalContent = messageTemplate.content;
          matchedMessageTemplate.originalTitle = messageTemplate.title;
        }
      }
    }

    if (resourceId) {
      filtered = filtered.filter((item) => item.resource === resourceId);
    }

    return filtered;
  }, [messageTemplateDefinitionList, key, isDefaultTemplate, resourceId]);

  useEffect(() => {
    q(dispatch(getMessageTemplateDefinitionList(currentProject.id, { params: { limit: 9999 } })));
    q(dispatch(getTemplateTypeList(currentProject.id, { params: { limit: 9999 } })));
    q(dispatch(getResources(currentProject.id)));

    if (resourceId) {
      q(dispatch(getResource(resourceId, currentProject.id)));
    }
  }, [dispatch, currentProject.id, q, resourceId]);

  useEffect(() => {
    if (relatedMessageTemplateDefinitionList?.length) {
      const files = relatedMessageTemplateDefinitionList.reduce((acc, item) => {
        const files = [...(item.files || []).map((file) => ({ ...file, templateId: item.id }))];
        acc.push(...files);
        return acc;
      }, []);

      setTemplateFiles(files);
    }
  }, [relatedMessageTemplateDefinitionList, dispatch, currentProject.id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      templates: [...relatedMessageTemplateDefinitionList],
    },
  });

  const handleChangeFormik = (key) => (e) => {
    const value = e?.target ? e.target.value : e;
    let isChanged = false;
    const newTemplates = formik.values.templates.map((item) => {
      if (item.language === selectedLanguage && item.platform === selectedNotificationType) {
        isChanged = true;
        return {
          ...item,
          ...(!key
            ? value
            : {
                [key]: value,
              }),
        };
      }
      return item;
    });
    if (!isChanged && (formikTemplate.values?.title || formikTemplate.values?.content)) {
      newTemplates.push({
        ...formikTemplate.values,
      });
    }
    formik.setFieldValue("templates", newTemplates);
  };

  const { templateGroups } = useMemo(() => {
    const groupList = [];
    const filtered = messageTemplateDefinitionList.filter((item) => !!item.default_for_projects === isDefaultTemplate);
    const grouped = groupBy(filtered, (item) => item.template_type);
    let index = 0;
    for (const key in grouped) {
      let elementArray = grouped[key] || [];
      elementArray = elementArray.filter((item) => !item.resource);

      const firstItem = elementArray?.[0];
      let suffix = "";
      if (firstItem?.default_for_projects) {
        suffix = t("dashboard.templateEdit.suffixForProjects");
      }
      if (firstItem?.default_for_resources) {
        suffix = t("dashboard.templateEdit.suffixForResources");
      }
      const name =
        (messageTemplateTextDescriptionMap[key]?.title ? t(messageTemplateTextDescriptionMap[key]?.title) : key) +
        suffix;
      groupList.push({
        index,
        name,
        templates: elementArray,
      });
      index++;
    }
    // let defaultSelectedTemplateGroup =
    //   !isDefaultTemplate &&
    //   groupList.find((item) =>
    //     item.templates?.find(
    //       (t) =>
    //         key === t.template_type &&
    //         (resourceId ? t.resource === resourceId && t.default_for_resources : true) &&
    //         t.default_for_projects
    //     )
    //   );

    // if (defaultSelectedTemplateGroup) {
    //   setSelectedGroupTemplate(defaultSelectedTemplateGroup);
    // }

    return {
      templateGroups: groupList,
      // templateGroupOptions: groupList,
    };
  }, [messageTemplateDefinitionList, isDefaultTemplate, t]);

  const { templatesByLanguage } = useMemo(() => {
    const grouped = groupBy(formik.values?.templates, (item) => item.language);
    if (!selectedLanguage) {
      setSelectedLanguage(Object.keys(grouped)?.[0] || null);
    }
    return { templatesByLanguage: grouped };
  }, [formik.values?.templates, selectedLanguage]);

  const handleClickCancel = () => {
    HistoryHelper.goBack(currentPageUrl, { scope: "dashboard" });
  };
  const handleClickSystemDefault = async () => {
    if (!(await DialogHelper.showQuestionYesNo("", t("dashboard.templateEdit.applySystemDefaultQuestion")))) return;
    // resource specific template's will be ignored. Only system default templates will be applied.
    let systemDefaultTemplateDefinitions = messageTemplateDefinitionList.filter(
      (item) => !!item.default_for_projects === true && item.template_type === key && !item.resource
    );

    const mapped = systemDefaultTemplateDefinitions.map((item) => ({
      ...item,
      default_for_projects: false,
      resource: resourceId,
      project: currentProject.id,
    }));

    applyFromTemplateGroup({
      templates: mapped,
    });
  };

  const visibleTemplate = useMemo(() => {
    if (!formik.values?.templates?.length) return null;
    const template = formik.values?.templates?.find(
      (item) => item.language === selectedLanguage && item.platform === selectedNotificationType
    );
    return template;
  }, [formik.values?.templates, selectedLanguage, selectedNotificationType]);

  const formikTemplate = useFormik({
    enableReinitialize: true,
    initialValues: {
      // ...messageTemplate,
      ...visibleTemplate,
      id: visibleTemplate?.id,
      title: visibleTemplate?.title,
      content: visibleTemplate?.content,
      language: visibleTemplate?.language || selectedLanguage,
      template_format:
        visibleTemplate?.template_format ||
        (selectedNotificationType === settingsTemplatePlatformType.EMAIL
          ? settingsTemplateFormat.HTML
          : settingsTemplateFormat.PLAIN),

      platform: selectedNotificationType,
      default_for_projects: isDefaultTemplate,
      default_for_resources: resourceId && isDefaultTemplate,
      resource: resourceId,
      project: visibleTemplate?.project,
    },
  });

  useProjectChange(() => {
    HistoryHelper.push(currentPageUrl, { scope: "dashboard" });
  }, []);

  const handleChangeFormikTemplate = (key) => (e) => {
    if (!selectedLanguage) return;
    const value = e?.target ? e.target.value : e;
    formikTemplate.setFieldValue(key, value);

    handleChangeFormik(key)(value);
  };

  const variableStatus = useMemo(() => {
    const templateVariables = selectedTemplateType?.variables || [];
    const variables = {};
    templateVariables.forEach((variable) => {
      variables[variable.value] = formikTemplate.values.content?.includes(
        messageTemplateCurlyFormat.format(variable.value)
      );
    });
    return variables;
  }, [formikTemplate.values.content, selectedTemplateType]);

  const handleClickUploadFile = async () => {
    const result = await TemplateUploadModal.show();
    if (!result) return;

    for (const fileObj of result) {
      await dispatch(uploadMessageTemplateDefinitionFile(currentProject.id, visibleTemplate.id, fileObj.file));
    }

    dispatch(getMessageTemplateDefinitionList(currentProject.id, { params: { limit: 9999 } }));
  };
  const handleClickVariable =
    (variable, raw = false) =>
    () => {
      //  && formik.values.template_format !== settingsTemplateFormat.PLAIN
      //insert text at cursor position in monaco editor
      const position = codeEditor.getPosition();
      codeEditor.executeEdits("", [
        {
          range: new monaco.Range(position.lineNumber, position.column, position.lineNumber, position.column),
          text: raw ? variable : messageTemplateCurlyFormat.format(variable),
        },
      ]);
    };

  useMonacoDecorator(codeEditor, {
    variableResolver: (variableName) => {
      const hoverLabel = selectedTemplateType?.variables?.find((item) => item.value === variableName)?.label;

      return hoverLabel;
    },
    wrapperType: "curlyBrackets",
  });

  const handleClickAddLanguage = () => {
    if (!formik.values.templates) return;
    const newTemplates = [...formik.values.templates];

    newTemplates.push({
      id: null,
      resource: resourceId,
      default_for_projects: isDefaultTemplate,
      default_for_resources: resourceId && isDefaultTemplate,
      template_format:
        selectedNotificationType === settingsTemplatePlatformType.EMAIL
          ? settingsTemplateFormat.HTML
          : settingsTemplateFormat.PLAIN,
      platform: settingsTemplatePlatformType.EMAIL,
      title: "",
      content: "",
      language: selectedNewLanguage.value,
      template_type: key,
      files: [],
      project: currentProject.id,
    });
    setSelectedLanguage(selectedNewLanguage.value);
    formik.setFieldValue("templates", newTemplates);
  };

  const handleClickSelectLanguage = (language) => () => {
    setSelectedLanguage(language);
  };

  const handleClickRemoveLanguage = (language) => () => {
    if (!formik.values.templates) return;
    const newTemplates = [...formik.values.templates.filter((item) => item.language !== language)];

    formik.setFieldValue("templates", newTemplates);
    if (selectedLanguage === language) {
      setSelectedLanguage(newTemplates[0]?.language);
    }
  };

  const { languageOptions, languageOptionsExceptSelected } = useMemo(() => {
    const options = [...settingsLanguageOptions];
    for (const option of options) {
      const isExist = formik.values.templates?.find((item) => item.language === option.value);

      option.disabled = isExist;
    }
    const onlyDisabledOptions = options.filter((item) => item.disabled);
    const languageOptionsExceptSelected = onlyDisabledOptions
      .filter((item) => item.value !== selectedLanguage)
      .map((item) => ({ ...item, disabled: false }));
    return { languageOptions: options, languageOptionsExceptSelected };
  }, [formik.values.templates, selectedLanguage]);

  const handleChangeApplyFromLanguage = () => {
    const template = formik.values.templates?.find(
      (item) => item.language === selectedTemplateLanguage.value && item.platform === selectedNotificationType
    );
    if (!template) return;
    formikTemplate.setValues({
      title: template.title,
      content: template.content,
      template_format: template.template_format,
    });

    handleChangeFormik(null)({
      title: template.title,
      content: template.content,
      template_format: template.template_format,
    });
  };

  const applyFromTemplateGroup = (groupTemplate) => {
    const activeGroupTemplate = groupTemplate || selectedGroupTemplate;
    if (!activeGroupTemplate.templates?.length) return;
    const templates = activeGroupTemplate.templates.map((item) => ({
      id: null,
      resource: null,
      default_for_projects: isDefaultTemplate,
      default_for_resources: resourceId && isDefaultTemplate,
      template_format: item.template_format,
      platform: item.platform || settingsTemplatePlatformType.EMAIL,
      title: item.title,
      content: item.content,
      language: item.language,
      template_type: key,
      files: [],
      project: currentProject.id,
    }));

    const newTemplateGroup = {
      templates,
    };
    formik.setValues(newTemplateGroup);
    setSelectedLanguage(templates?.[0]?.language);
    setSelectedNotificationType(templates?.[0]?.platform);

    // formikTemplate.setValues(templates?.[0]);
  };

  const sendTemplate = async (email) => {
    const data = {
      template_type: key,
      language: selectedLanguage,
      resource: resourceId,
      email: [email],
    };
    await dispatch(sendTestMessageTemplate(currentProject.id, data));
  };

  const openSendTemplateModal = async () => {
    if (!userInfo.is_superuser) {
      if (await DialogHelper.showQuestionYesNo("", t("dashboard.templateEdit.sendTemplateWarning"))) {
        sendTemplate(userInfo?.email);
      } else {
        return;
      }
    } else {
      await SendTemplateModal.show({ onClick: sendTemplate });
    }
  };
  const handleChangeApplyFromTemplateGroup = () => {
    applyFromTemplateGroup();
  };

  const handleChangeTemplateFormat = (value) => {
    handleChangeFormikTemplate("template_format")(value?.toUpperCase());
  };

  const isTemplateDeletable = useMemo(() => {
    const relatedTemplates = relatedMessageTemplateDefinitionList.filter(
      (i) =>
        i.id &&
        !!i.default_for_projects === isDefaultTemplate &&
        (selectedTemplateType?.type === "resource"
          ? resourceId
            ? i.resource === resourceId || !i.resource
            : !i.resource
          : true)
    );
    return relatedTemplates?.length > 0;
  }, [relatedMessageTemplateDefinitionList, isDefaultTemplate, resourceId, selectedTemplateType]);

  const handleDelete = async () => {
    if (!(await DialogHelper.showQuestionYesNo())) return;

    let deleteTemplateDefinitionList = [...relatedMessageTemplateDefinitionList];

    try {
      LoadingHelper.open();
      for (const data of deleteTemplateDefinitionList) {
        dispatch(
          deleteMessageTemplateDefinition(currentProject.id, data.id, {
            loading: false,
            successMsg: false,
          })
        );
      }
      AlertHelper.showSuccess();
      handleClickCancel();
    } finally {
      LoadingHelper.close();
    }
  };

  const handleSubmit = async () => {
    const errors = [];

    const blankFilteredTemplates = formik.values.templates?.filter((item) => item.content?.trim() !== "");
    if (!blankFilteredTemplates?.length) {
      errors.push(t("dashboard.templateEdit.errorEmptyTemplate"));
    }
    const groupedByLang = groupBy(blankFilteredTemplates, "language");

    for (const key in groupedByLang) {
      const tempList = groupedByLang[key];
      const filteredErrorItems = tempList.filter((t) => t.platform !== settingsTemplatePlatformType.SMS && !t.title);
      if (filteredErrorItems?.length) {
        const innerErrors = filteredErrorItems.map((item) => {
          const notificationOption =
            t(notificationTypeOptions.find((option) => option.value === item.platform)?.label) || item.platform;
          return t("dashboard.templateEdit.errorEmptyPlatformTitle").format(notificationOption);
        });
        errors.push([
          t("dashboard.templateEdit.errorEmptyLanguageTitle").format(t(settingsLanguageMap[key])),
          ...innerErrors,
        ]);
      }
    }
    if (errors?.length) {
      DialogHelper.showValidate(errors);
      return;
    }
    // let newTemplateData = cloneDeep(messageTemplateDefinitionList);

    const deleteTemplateDefinitionList = [];
    const createOrUpdateTemplateDefinitionList = [];
    for (const template of formik.values.templates) {
      if (!template.id) {
        createOrUpdateTemplateDefinitionList.push(template);
      }
    }

    for (const data of relatedMessageTemplateDefinitionList.filter((i) => i.id)) {
      const templateData = formik.values.templates.find((item) => item.id && item.id === data.id);
      if (!templateData) {
        deleteTemplateDefinitionList.push(data);
      } else {
        createOrUpdateTemplateDefinitionList.push(templateData);
      }
    }

    try {
      LoadingHelper.open();
      for (const t of deleteTemplateDefinitionList) {
        await dispatch(
          deleteMessageTemplateDefinition(currentProject.id, t.id, {
            loading: false,
            successMsg: false,
          })
        );
      }

      for (const t of createOrUpdateTemplateDefinitionList) {
        await dispatch(
          createOrUpdateMessageTemplateDefinition(currentProject.id, {
            ...t,
            title: t.title || t.content, //TODO fix on backend. disable title for SMS types
            template_type: selectedTemplateType.key,
          })
        );
      }
      AlertHelper.showSuccess();
    } finally {
      LoadingHelper.close();
    }
    handleClickCancel();
  };

  const languageEntries = Object.entries(templatesByLanguage || {});

  const parentName = useMemo(
    () => [
      {
        label: t("common.templates"),
        url: currentPageUrl,
      },
      {
        label: t("dashboard.templateEdit.manageMessageTemplate"),
      },
    ],
    [currentPageUrl, t]
  );

  const relatedDefaultTemplateDefinition = useMemo(() => {
    if (isDefaultTemplate) return null;
    return messageTemplateDefinitionList.find(
      (item) =>
        !!item.default_for_projects === true &&
        item.template_type === key &&
        item.resource === resourceId &&
        item.language === selectedLanguage &&
        item.platform === selectedNotificationType
    );
  }, [messageTemplateDefinitionList, key, resourceId, selectedLanguage, selectedNotificationType, isDefaultTemplate]);

  const setVisibleTemplateToDefault = async () => {
    if (!(await DialogHelper.showQuestionYesNo("", t("dashboard.templateEdit.applySystemDefaultQuestion")))) return;
    // resource specific template's will be ignored. Only system default templates will be applied.

    if (relatedDefaultTemplateDefinition) {
      formikTemplate.setFieldValue("title", relatedDefaultTemplateDefinition.title);
      formikTemplate.setFieldValue("content", relatedDefaultTemplateDefinition.content);

      let isChanged = false;
      const newTemplates = formik.values.templates.map((item) => {
        if (item.language === selectedLanguage && item.platform === selectedNotificationType) {
          isChanged = true;
          return {
            ...item,
            title: relatedDefaultTemplateDefinition.title,
            content: relatedDefaultTemplateDefinition.content,
            originalContent: relatedDefaultTemplateDefinition.content,
            originalTitle: relatedDefaultTemplateDefinition.title,
          };
        }
        return item;
      });
      if (!isChanged) {
        newTemplates.push({
          ...formikTemplate.values,
        });
      }
      formik.setFieldValue("templates", newTemplates);
      // formikTemplate.setValues(relatedDefaultTemplateDefinition);
    }
  };
  return (
    <PRContainer className="pr-message-template-edit" name={t("common.settings")} parentName={parentName}>
      {isDefaultTemplate && (
        <Alert className="d-flex align-items-center lh-1" color="warning">
          <MdSettings className="fs-5 me-1 text-secondary" /> {t("dashboard.templates.defaultModeWarning")}
        </Alert>
      )}

      <PRPage>
        <Alert color="info">
          {t("dashboard.templateEdit.editingWarning1")}{" "}
          <span className="  fw-semibold">{t(messageTemplateTextDescriptionMap[key]?.title) || key}</span>{" "}
          {t("dashboard.templateEdit.editingWarning2")}
          {resource?.name && (
            <>
              ({t("dashboard.templateEdit.editingWarning3")}{" "}
              <span className=" text-danger-600  fw-semibold"> {resource?.name}</span>{" "}
              {t("dashboard.templateEdit.editingWarning4")})
            </>
          )}
          . {!isTemplateDeletable && t("dashboard.templateEdit.inheritedWarning")}
        </Alert>
        {/* <PRTable onRowClick={() => {}} data={templateGroup} columns={columns} /> */}
        <Row className="g-2 justify-content-between">
          <Col lg="7" md="6" xs="12"></Col>
          <Col lg="5" md="6" xs="12">
            <Row>
              <Col className="d-flex  justify-content-end align-items-center" lg="12" md="12" xs="12">
                <div className="w-100">
                  <Label>{t("dashboard.templateEdit.applyTemplate")}:</Label>
                  <div className="font-size-12 gap-2 mb-2 d-flex align-items-center">
                    <PRSelect
                      fullWidth
                      className=""
                      isClearable={false}
                      labelSelector="name"
                      options={templateGroups}
                      value={selectedGroupTemplate}
                      valueSelector="index"
                      onChange={setSelectedGroupTemplate}
                    />
                    <PRButton
                      icon={MdContentCopy}
                      tooltipText={t("dashboard.templateEdit.copyTemplateTooltip")}
                      onClick={handleChangeApplyFromTemplateGroup}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <PRDividerLabel pageSeparator className="my-2">
          {t("dashboard.templateEdit.templateVariants")}
        </PRDividerLabel>
        <Row className="g-2">
          <Col lg="12">
            <Row className="g-2 d-flex align-items-center justify-content-between">
              <Col xs="auto">
                <Label className="" size="md">
                  {t("common.languages")}
                </Label>
              </Col>
              <Col xs lg="5" md="6">
                <div className="d-flex align-items-center gap-2">
                  <PRSelect
                    fullWidth
                    labelRenderer={(item) => {
                      let languageCode = item.value?.toLowerCase();
                      return (
                        <div className="d-flex align-items-center">
                          <img
                            alt={item.value}
                            loading="lazy"
                            src={Utils.getFlagUrlByIsoCode(languageCode)}
                            width={20}
                          />
                          <span className="ms-2">{item.label}</span>
                        </div>
                      );
                    }}
                    options={languageOptions}
                    value={selectedNewLanguage}
                    onChange={setSelectedNewLanguage}
                  />
                  <PRButton
                    className=""
                    color="success"
                    disabled={selectedNewLanguage?.value === selectedLanguage}
                    icon={MdAdd}
                    tooltipText={t("dashboard.templateEdit.addLanguage")}
                    onClick={handleClickAddLanguage}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col md="6" sm="12">
            <Row className="g-0 gap-2 language-box">
              {!languageEntries?.length && (
                <div className="mt-1 text-center text-muted w-100">{t("dashboard.templateEdit.noLanguage")}</div>
              )}
              {languageEntries.map(([language, templates = []]) => {
                const hasEmailTemplate = templates?.find(
                  (item) => item?.platform === settingsTemplatePlatformType.EMAIL
                );
                const isEmailOriginal =
                  !isDefaultTemplate &&
                  hasEmailTemplate?.content === hasEmailTemplate?.originalContent &&
                  hasEmailTemplate?.title === hasEmailTemplate?.originalTitle;

                const hasSmsTemplate = templates?.find((item) => item?.platform === settingsTemplatePlatformType.SMS);
                const isSmsOriginal =
                  !isDefaultTemplate &&
                  hasSmsTemplate?.content === hasSmsTemplate?.originalContent &&
                  hasSmsTemplate?.title === hasSmsTemplate?.originalTitle;

                const hasPushNotificationTemplate = templates?.find(
                  (item) => item?.platform === settingsTemplatePlatformType.PUSH_NOTIFICATION
                );
                const isPushNotificationOriginal =
                  !isDefaultTemplate &&
                  hasPushNotificationTemplate?.content === hasPushNotificationTemplate?.originalContent &&
                  hasPushNotificationTemplate?.title === hasPushNotificationTemplate?.originalTitle;

                let languageCode = language?.toLowerCase();

                return (
                  <Col
                    key={language}
                    className={classNames("language-badge", {
                      selected: selectedLanguage === language,
                    })}
                    xs="auto"
                  >
                    <div className="badge-label" onClick={handleClickSelectLanguage(language)}>
                      <span className="">
                        <img alt={language} loading="lazy" src={Utils.getFlagUrlByIsoCode(languageCode)} width={20} />
                        <span className="ms-2"> {t(settingsLanguageMap[language])}</span>
                      </span>
                      <Row className="gx-2 align-items-center">
                        <Col className="d-flex align-items-center" xs="auto">
                          <PRTooltip title={t("common.email")}>
                            <div className="d-flex align-items-center position-relative">
                              <MdEmail
                                className={classNames("font-size-16", {
                                  "text-primary": hasEmailTemplate?.content,
                                })}
                              />
                              {!!hasEmailTemplate?.content && (
                                <span
                                  className={classNames(
                                    "bg-secondary ms-1 position-absolute top-0 start-100 translate-middle badge border border-secondary rounded-circle  p-1",
                                    {
                                      "bg-success": !isEmailOriginal && hasEmailTemplate?.id,
                                      "bg-danger": !isEmailOriginal && !hasEmailTemplate?.id && !!hasEmailTemplate,
                                      "bg-light": isEmailOriginal,
                                    }
                                  )}
                                >
                                  <span className="visually-hidden"></span>
                                </span>
                              )}
                            </div>
                          </PRTooltip>
                        </Col>
                        <Col className="d-flex align-items-center" xs="auto">
                          <PRTooltip title={t("dashboard.templates.sms")}>
                            <div className="d-flex align-items-center position-relative">
                              <MdPhoneAndroid
                                className={classNames("font-size-16", {
                                  "text-primary": hasSmsTemplate?.content,
                                })}
                              />
                              {!!hasSmsTemplate?.content && (
                                <span
                                  className={classNames(
                                    "bg-secondary ms-1 position-absolute top-0 start-100 translate-middle badge border border-secondary rounded-circle  p-1",
                                    {
                                      "bg-success": !isSmsOriginal && hasSmsTemplate?.id,
                                      "bg-danger": !isSmsOriginal && !hasSmsTemplate?.id && !!hasSmsTemplate,
                                      "bg-light": isSmsOriginal,
                                    }
                                  )}
                                >
                                  <span className="visually-hidden"></span>
                                </span>
                              )}
                            </div>
                          </PRTooltip>
                        </Col>
                        <Col className="d-flex align-items-center" xs="auto">
                          <PRTooltip title={t("dashboard.templates.pushNotification")}>
                            <div className="d-flex align-items-center position-relative">
                              <MdNotifications
                                className={classNames("font-size-16", {
                                  "text-primary": hasPushNotificationTemplate?.content,
                                })}
                              />
                              {!!hasPushNotificationTemplate?.content && (
                                <span
                                  className={classNames(
                                    "bg-secondary ms-1 position-absolute top-0 start-100 translate-middle badge border border-secondary rounded-circle  p-1",
                                    {
                                      "bg-success": !isPushNotificationOriginal && hasPushNotificationTemplate?.id,
                                      "bg-danger":
                                        !isPushNotificationOriginal &&
                                        !hasPushNotificationTemplate?.id &&
                                        !!hasPushNotificationTemplate,
                                      "bg-light": isPushNotificationOriginal,
                                    }
                                  )}
                                >
                                  <span className="visually-hidden"></span>
                                </span>
                              )}
                            </div>
                          </PRTooltip>
                        </Col>
                      </Row>
                    </div>
                    <PRButton
                      onlyIcon
                      color="danger"
                      icon={MdClose}
                      tooltipText={t("dashboard.templateEdit.removeLanguage")}
                      onClick={handleClickRemoveLanguage(language)}
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>

          <Col className="gap-1 d-flex align-items-end justify-content-between" lg="12">
            <div>
              <Label className="mt-2">{t("dashboard.templateEdit.notificationType")}</Label>
              <div>
                <PRButtonGroup
                  isTranslate
                  disabled={!languageEntries?.length}
                  options={notificationTypeOptions}
                  value={selectedNotificationType}
                  onChange={setSelectedNotificationType}
                />
              </div>
            </div>
            {!!relatedDefaultTemplateDefinition && (
              <PRButton className="w-xs-100 w-md-auto" onClick={setVisibleTemplateToDefault}>
                {t("dashboard.templateEdit.resetSystemDefault")}
              </PRButton>
            )}
          </Col>

          <PRDividerLabel pageSeparator className="my-2">
            {t("dashboard.templateEdit.templateContent")}{" "}
            {selectedNotificationType && ` - ${t(settingsTemplatePlatformTypeMap[selectedNotificationType])}`}
            {selectedLanguage && (
              <>
                {" - "}
                <img
                  className="me-1"
                  loading="lazy"
                  src={Utils.getFlagUrlByIsoCode(selectedLanguage)}
                  style={{ marginBottom: 2 }}
                  width={16}
                />
                {t(settingsLanguageMap[selectedLanguage])}
              </>
            )}
          </PRDividerLabel>
          {[settingsTemplatePlatformType.EMAIL].includes(selectedNotificationType) && (
            <Col lg="12">
              <Alert color="info">{t("dashboard.templateEdit.emailTemplateWarning")}</Alert>
            </Col>
          )}
          {languageEntries?.length > 1 && (
            <Col lg="12">
              <Row className="g-2 justify-content-between">
                <Col lg="7" md="6" xs="12"></Col>
                <Col lg="5" md="6" xs="12">
                  <Row className="gx-2 justify-content-end align-items-center">
                    <Col xs="12">
                      <Label>
                        {t("dashboard.templateEdit.applyFrom").format(
                          t(settingsTemplatePlatformTypeMap[selectedNotificationType])
                        )}
                      </Label>
                      <div className="font-size-12 gap-2 mb-2 d-flex align-items-center">
                        <PRSelect
                          fullWidth
                          isDisabled={!languageEntries?.length}
                          options={languageOptionsExceptSelected}
                          value={selectedTemplateLanguage}
                          onChange={setSelectedTemplateLanguage}
                        />
                        <PRButton
                          disabled={!languageEntries?.length}
                          icon={MdContentCopy}
                          tooltipText={t("dashboard.templateEdit.applyFromTooltip").format(
                            selectedNotificationType
                              ? ` ${t(settingsTemplatePlatformTypeMap[selectedNotificationType])}`
                              : "",
                            t(settingsLanguageMap[selectedTemplateLanguage?.value]),
                            t(settingsLanguageMap[selectedLanguage])
                          )}
                          onClick={handleChangeApplyFromLanguage}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          )}
          {[settingsTemplatePlatformType.EMAIL, settingsTemplatePlatformType.PUSH_NOTIFICATION].includes(
            selectedNotificationType
          ) && (
            <Col lg="12">
              <Label className="">{t("common.title")}</Label>
              <PRInput
                disabled={!languageEntries?.length || !selectedLanguage}
                invalid={formikTemplate.touched.title && formikTemplate.errors.title}
                name="title"
                placeholder={t("dashboard.templateEdit.titlePlaceholder")}
                value={formikTemplate.values.title}
                onBlur={formikTemplate.handleBlur}
                onChange={handleChangeFormikTemplate("title")}
              />
            </Col>
          )}

          <Col lg="12">
            <div className="gap-2 d-flex align-items-start justify-content-between">
              <Label className="mt-2">
                {/* {formik.values.template_format === settingsTemplateFormat.PLAIN && "Content"} */}
                {t("common.content")}
              </Label>
              <div className="ms-2 d-flex align-items-center">
                {selectedTemplateType?.variables?.length > 0 && (
                  <div>
                    <Label className="mt-2">{t("common.variables")}:</Label>
                    {selectedTemplateType?.variables?.map((variable) => {
                      return (
                        <PRButton
                          key={variable.value}
                          className="my-1 ms-1"
                          color={variableStatus[variable.value] ? "success" : "warning"}
                          disabled={!languageEntries?.length || !selectedLanguage}
                          size="sm"
                          tooltipText={variable.label}
                          onClick={handleClickVariable(variable.value)}
                        >
                          {variable.label}
                        </PRButton>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <PRTextArea
              editorMode
              disabled={!languageEntries?.length || !selectedLanguage}
              editorProps={{
                onMount: setCodeEditor,
                defaultHeight: 500,
              }}
              format={formikTemplate.values.template_format?.toLowerCase()}
              invalid={formikTemplate.touched.content && formikTemplate.errors.content}
              name="content"
              placeholder={t("dashboard.templateEdit.contentPlaceholder")}
              value={formikTemplate.values.content}
              onBlur={formikTemplate.handleBlur}
              onChange={handleChangeFormikTemplate("content")}
              onFormatChange={handleChangeTemplateFormat}
            />
          </Col>
          <Col lg="12">
            <div className="d-flex align-items-center justify-content-between">
              <Label className="mt-2">{t("dashboard.templateEdit.files")}</Label>
              <PRButton
                color="success"
                disabled={!visibleTemplate?.id}
                icon={MdAdd}
                size="sm"
                onClick={handleClickUploadFile}
              >
                {t("dashboard.templateEdit.addFile")}
              </PRButton>
            </div>
            {/* <div className="border border-secondary-450 rounded p-2"> */}
            <Row className="m-0 gy-1 gx-2">
              {templateFiles.map((file) => {
                return (
                  <Col key={file.id} lg="6" md="6" xs="12">
                    <FileItem file={file} onInsert={handleClickVariable(file.url, true)} />
                  </Col>
                );
              })}
            </Row>
            {/* </div> */}
          </Col>
        </Row>

        <Row className="justify-content-end mt-2">
          <Col xs="auto">
            <PRButton outline className="me-2" onClick={openSendTemplateModal}>
              {t("dashboard.templateEdit.sendTestMessage")}
            </PRButton>
            <PRButton outline className="me-2" onClick={handleClickCancel}>
              {t("common.cancel")}
            </PRButton>
            {!isDefaultTemplate && (
              <PRButton outline className="me-2" onClick={handleClickSystemDefault}>
                {t("dashboard.templateEdit.resetAllSystemDefault")}
              </PRButton>
            )}
            <PRButton
              className="me-2"
              color={"danger"}
              disabled={!isTemplateDeletable || (isDefaultTemplate && !userInfo.is_superuser)}
              onClick={handleDelete}
            >
              {t("dashboard.templateEdit.deleteTemplate")}
            </PRButton>
            <PRButton onClick={handleSubmit}>
              {relatedMessageTemplateDefinitionList?.length ? t("common.update") : t("common.create")}
            </PRButton>
          </Col>
        </Row>
      </PRPage>
    </PRContainer>
  );
};

export default MessageTemplateEdit;

function FileItem({ file, onInsert }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);

  const handleClickDelete = async () => {
    if (!(await DialogHelper.showQuestionYesNo())) return;
    try {
      // LoadingHelper.open();
      await dispatch(deleteMessageTemplateDefinitionFile(currentProject.id, file.templateId, file.id));
      // AlertHelper.showSuccess();

      dispatch(getMessageTemplateDefinitionList(currentProject.id, { params: { limit: 9999 } }));
    } finally {
      // LoadingHelper.close();
    }
  };

  const fileName = useMemo(() => {
    if (!file.media_file) return null;
    const chunks = file.media_file.split("/");
    return chunks?.[chunks?.length - 1];
  }, [file.media_file]);

  return (
    <Row className="gx-1 align-items-center border border-secondary-400 rounded p-1">
      <Col xs="auto">
        <MdDescription className="me-1" />
      </Col>
      <Col> {fileName}</Col>
      <Col xs="auto">
        <PRButton
          icon={MdDataObject}
          size="sm"
          tooltipText={t("dashboard.templateEdit.insertURL")}
          onClick={onInsert}
        />
      </Col>
      <Col xs="auto">
        <PRButton
          icon={MdOpenInNew}
          link={file.url}
          linkProps={{
            newTab: true,
            noPrefix: true,
          }}
          size="sm"
          tooltipText={t("common.openInNewTab")}
        />
      </Col>
      <Col xs="auto">
        <PRButton
          color="danger"
          icon={MdDelete}
          size="sm"
          tooltipText={t("dashboard.templateEdit.deleteFile")}
          onClick={handleClickDelete}
        />
      </Col>
    </Row>
  );
}

const SendTemplateModal = withCardon(
  function SendTemplate({ get, onClick }) {
    const [email, setEmail] = useState("");
    const { t } = useTranslation();
    const handleClick = () => {
      if (!Utils.validateEmail(email)) {
        DialogHelper.showValidate([t("dashboard.templateEdit.errorInvalidEmail")]);
        return;
      }
      onClick(email);
      get(true)();
    };

    const handleChange = (e) => {
      setEmail(e.target.value);
    };

    return (
      <PRModal submitText={t("common.send")} title={t("dashboard.templateEdit.sendTestMessage")} onClick={handleClick}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PalTextField fullWidth label={t("common.email")} type="email" value={email} onChange={handleChange} />
          </Grid>
        </Grid>
      </PRModal>
    );
  },
  {
    destroyOnHide: true,
  }
);
