import { apiUrlChatLabels } from "~constants";
import Network from "~helpers/Network";

import * as at from "./actionTypes";

export const setChatLabel = (payload) => ({
  type: at.SET_CHAT_LABEL,
  payload,
});

export const setChatLabelList = (payload) => ({
  type: at.SET_CHAT_LABEL_LIST,
  payload,
});

export const getChatLabel =
  (projectId, id, options = {}) =>
  (_dispatch, _getState) => {
    const url = `${apiUrlChatLabels.get.format(projectId)}/${id}/`;
    return Network.request(url, {
      onSuccess: setChatLabel,
      loading: true,
      ...options,
    });
  };

export const getChatLabelList =
  (projectId, params, options = {}) =>
  (_dispatch, _getState) => {
    return Network.request(apiUrlChatLabels.get.format(projectId), {
      params,
      loading: true,
      ...options,
    });
  };

export const createOrUpdateChatLabel = (projectId, data) => (_dispatch, _getState) => {
  let url = apiUrlChatLabels.get.format(projectId);
  if (data.id) {
    url = `${apiUrlChatLabels.get.format(projectId)}/${data.id}/`;
  }
  return Network.request(url, {
    method: data.id ? "PUT" : "POST",
    data,
    loading: true,
    successMsg: true,
  });
};

export const deleteChatLabel = (projectId, id) => (_dispatch, _getState) => {
  const url = `${apiUrlChatLabels.get.format(projectId)}/${id}`;
  return Network.request(url, {
    method: "DELETE",
    loading: true,
    successMsg: true,
  });
};
