import { useEffect, useMemo } from "react";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRInput from "~components/Generic/PRInput";
import PRPage from "~components/Generic/PRPage";
import PRSelect from "~components/Generic/PRSelect";
import { userRole } from "~constants";
import HistoryHelper from "~helpers/HistoryHelper";
import { createOrUpdateTicketCategory, getTicketCategory, setTicketCategory } from "~store/helpdesk/actions";
import { selectTicketCategory } from "~store/helpdesk/selectors";
import { getPermissionUserList } from "~store/settings/permission/actions";
import { selectPermissionUserList } from "~store/settings/permission/selectors";
import { selectCurrentProject } from "~store/user/selectors";

import "./style.scss";

export default function ActionsEdit() {
  const { t } = useTranslation();
  const { id } = useParams();
  const ticketCategory = useSelector(selectTicketCategory);
  // const [data, setData] = useState();
  const dispatch = useDispatch();

  const permissionUserList = useSelector(selectPermissionUserList);
  const currentProject = useSelector(selectCurrentProject);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: ticketCategory.id,
      name: ticketCategory.name,
      agent_list: ticketCategory.agent_list,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t("component.formik.required").format(t("common.name")))
        .max(64, t("component.formik.maxLength").format(t("common.name"), 64)),
    }),
    onSubmit: async (values) => {
      await dispatch(createOrUpdateTicketCategory(currentProject.id, values));
      HistoryHelper.goBack("/helpdesk/ticket-category", { scope: "dashboard" });
    },
  });

  useEffect(() => {
    dispatch(getPermissionUserList(currentProject.id));
    return () => {
      dispatch(setTicketCategory({}));
    };
  }, [dispatch, currentProject.id]);

  const permissionUserOptions = useMemo(() => {
    const ticketValidRoles = [userRole.ticketAgent, userRole.admin];

    return permissionUserList
      .filter((item) => item?.roles?.some((role) => ticketValidRoles.includes(role)))
      .map((item) => ({
        label: `${item.firstname} ${item.lastname} (${item.email})`,
        value: item.id,
      }));
  }, [permissionUserList]);

  useEffect(() => {
    if (!id) return;
    dispatch(getTicketCategory(currentProject.id, id));
  }, [dispatch, currentProject.id, id]);

  const onChangeSelect = (key) => (obj) => {
    formik.setFieldValue(key, obj);
  };
  const handleClickCancel = () => {
    HistoryHelper.goBack("/helpdesk/ticket-category", { scope: "dashboard" });
  };
  const parentName = [
    {
      label: t("common.ticketCategory"),
      url: "/helpdesk/ticket-category",
    },
    {
      label: id ? t("dashboard.ticketCategoryEdit.edit") : t("dashboard.ticketCategoryEdit.create"),
    },
  ];
  return (
    <PRContainer
      className="pr-data-format"
      description={t("dashboard.ticketCategoryEdit.description")}
      name={t("common.helpDesk")}
      parentName={parentName}
    >
      <PRPage className="">
        <Row className="mt-2 g-2">
          <Col md="4">
            <Label className="mb-2">{t("common.name")}</Label>
          </Col>
          <Col md="8">
            <PRInput
              invalid={formik.touched.name && formik.errors.name}
              name="name"
              type="text"
              value={formik.values.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
          <Col md="4">
            <Label className="mb-2">{t("dashboard.ticketCategoryEdit.agentList")}</Label>
          </Col>
          <Col md="8">
            <PRSelect
              isMulti
              isPrimitiveValue
              invalid={formik.touched.agent_list && formik.errors.agent_list}
              name="agent_list"
              options={permissionUserOptions}
              value={formik.values.agent_list}
              onBlur={formik.handleBlur}
              onChange={onChangeSelect("agent_list")}
            />
          </Col>
        </Row>
        <Row className="justify-content-end mt-2">
          <Col md="auto">
            <PRButton outline className="" onClick={handleClickCancel}>
              {t("common.cancel")}
            </PRButton>
            <PRButton className="ms-2" onClick={formik.handleSubmit}>
              {t("common.save")}
            </PRButton>
          </Col>
        </Row>
      </PRPage>
    </PRContainer>
  );
}
