import _ from "lodash";
import Lottie from "lottie-react";

import styled from "@emotion/styled";

import LoadingLottie from "~assets/lottie/loading.json";
import { LoadingOverlayBase } from "~components/LoadingOverlay";

import DialogHelper from "./DialogHelper";
import IFrameHelper from "./IFrameHelper";

const StyledLottie = styled(Lottie)`
  svg {
    width: max(18vw, 18vh) !important;
    height: max(18vw, 18vh) !important;
  }
`;

const queryParams = new URLSearchParams(window.location.search);
const isBackgroundTransparent = !!queryParams.get("disable_loading_backdrop");

const _loadingKey = "generic-loading-dialog";
function LoadingLottieContent() {
  //TODO: Fix object is not extensible error and remove this cloneDeep
  let cloneLoadingLottie = _.cloneDeep(LoadingLottie);
  return <StyledLottie animationData={cloneLoadingLottie} aria-label="Loading" />;
}
export default class LoadingHelper {
  openStatus = false;
  static {}
  static open = (reason) => {
    // const viewMode = selectViewMode(store.getState());
    const isWebchatMode = IFrameHelper.isWebChatMode();
    if (isWebchatMode && IFrameHelper.isInIFrame()) return; // || viewMode === "chat"

    if (LoadingHelper.openStatus) return;
    LoadingHelper.openStatus = true;

    // console.log("LoadingHelper.open ", reason);
    DialogHelper.show(
      "",
      isWebchatMode ? <LoadingLottieContent /> : <LoadingOverlayBase />,

      [],
      {
        bare: true,
        transparent: true,
        disableBackdropClick: true,
        key: _loadingKey,

        dialogOptions: {
          sx: {
            "& .MuiDialog-paper": {
              border: "none",
            },
            //disable mui backdrop gray color
            ...(isBackgroundTransparent && isWebchatMode
              ? {
                  "& .MuiBackdrop-root": {
                    backgroundColor: "transparent",
                  },
                }
              : {}),
          },
        },
      }
    );
  };

  static close = _.debounce((reason) => {
    if (!LoadingHelper.openStatus) return;
    LoadingHelper.openStatus = false;
    // console.log("LoadingHelper.close ", reason);
    DialogHelper.close(_loadingKey);
  }, 100);
}
