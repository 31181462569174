import { apiUrlDefaultMessage } from "~constants";
import Network from "~helpers/Network";

import * as at from "./actionTypes";

export const setDefaultMessage = (data) => ({
  type: at.SET_DEFAULT_MESSAGE,
  payload: data,
});

// -------------- API --------------
export const getDefaultMessage = (projectId, id) => (_dispatch, _getState) => {
  const url = `${apiUrlDefaultMessage.get.format(projectId)}${id}/`;

  return Network.request(url, {
    loading: true,
    onSuccess: setDefaultMessage,
  });
};
export const getDefaultMessageList =
  (projectId, options = {}) =>
  (_dispatch, _getState) => {
    return Network.request(apiUrlDefaultMessage.get.format(projectId), {
      loading: true,
      ...options,
    });
  };

export const createOrUpdateDefaultMessage = (projectId, data) => (_dispatch, _getState) => {
  let url = apiUrlDefaultMessage.get.format(projectId);
  if (data.id) {
    url += data.id + "/";
  }

  return Network.request(url, {
    method: data.id ? "PATCH" : "POST",
    data,
    successMsg: true,
    loading: true,
  });
};

export const deleteDefaultMessage = (projectId, id) => (_dispatch, _getState) => {
  const url = `${apiUrlDefaultMessage.get.format(projectId)}${id}/`;

  return Network.request(url, {
    method: "DELETE",
    successMsg: true,
    loading: true,
  });
};
