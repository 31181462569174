import { useCallback, useEffect, useState } from "react";

import { withCardon } from "cardon";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Input, Label } from "reactstrap";

import PRModal from "~components/Generic/PRModal";
import AlertHelper from "~helpers/AlertHelper";
import { updateAgentInfo, getAgentInfo } from "~store/socket/livechat/actions";
import { selectAgentInfo } from "~store/socket/livechat/selectors";

function UpdateAgentName({ get, id }) {
  const dispatch = useDispatch();

  const agentInfo = useSelector(selectAgentInfo);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAgentInfo());
  }, [dispatch]);

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const handleCreate = async () => {
    setLoading(true);
    try {
      await dispatch(
        updateAgentInfo(
          {
            ...agentInfo,
            name: value,
          },
          { loading: false }
        )
      );
      AlertHelper.show(t("network.success"), "success");

      window.location.reload();
    } catch (e) {
      AlertHelper.show(t("network.serverError"), "error");
      console.error(e);
    }
    setLoading(false);
    get(true)();
  };
  return (
    <PRModal
      loading={loading}
      submitText={t("common.update")}
      title={t("component.updateAgentName.title")}
      onClick={handleCreate}
      onClose={get(false)}
    >
      <Label htmlFor="slot-validation-text">{t("component.updateAgentName.agentName")}:</Label>
      <Input
        className="form-control"
        name="slotValidationText"
        placeholder={t("common.namePlaceholder")}
        type="text"
        value={value}
        onChange={handleChange}
      />
    </PRModal>
  );
}
const UpdateAgentNameModal = withCardon(UpdateAgentName, { destroyOnHide: true });
export default UpdateAgentNameModal;
