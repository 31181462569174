import { useMemo, useRef } from "react";

import { MdPrint } from "react-icons/md";
import { useParams } from "react-router-dom";

import styled from "@emotion/styled";

import PRContainer from "~components/Generic/PRContainer";

import { DynamicReportContent } from "./StatisticGeneratorReportList/DynamicReportChartModal";

const StyledContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 15px;
`;

export default function StatisticGeneratorPreview() {
  const { id, previewId } = useParams();
  const printRef = useRef();

  const handlePrint = () => {
    const printContent = printRef.current;
    const WinPrint = window.open("", "", "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0");

    WinPrint.document.write(printContent.innerHTML);
    // Copy <link>
    document.querySelectorAll("link[rel='stylesheet']").forEach((link) => {
      const newLink = WinPrint.document.createElement("link");
      newLink.rel = "stylesheet";
      newLink.href = link.href;
      WinPrint.document.head.appendChild(newLink);
    });

    // Copy <style>
    document.querySelectorAll("style").forEach((style) => {
      const newStyle = WinPrint.document.createElement("style");
      newStyle.textContent = style.textContent;
      WinPrint.document.head.appendChild(newStyle);
    });

    const customStyle = WinPrint.document.createElement("style");
    const currentPrintWidth = WinPrint.innerWidth;

    customStyle.textContent = `
    body, html {
      overflow: auto !important;
      height: auto !important;
      display: block !important;
      position: static !important; 
     
    }
    .echarts-for-react{
      // transform: scale(0.65) !important;
      // transform-origin: 0 0 !important;
      // margin-left:${currentPrintWidth > 800 ? "10%" : "0"};
    }
    .markdown-body{
      padding-right: 10px;
    }
  `;
    WinPrint.document.head.appendChild(customStyle);

    const viewportMeta = WinPrint.document.createElement("meta");
    viewportMeta.name = "viewport";
    viewportMeta.content = "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0";
    WinPrint.document.head.appendChild(viewportMeta);

    WinPrint.document.body.classList.add("markdown-wrapper");

    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
  };

  const parentName = useMemo(
    () => [
      {
        label: "Statistics Generator",
        url: "/stats/statistic-generator/",
      },
      {
        label: "Statistic Reports",
        url: `/stats/statistic-generator/reports/${id}`,
      },
      {
        label: "Report Preview - " + previewId,
      },
    ],
    [id, previewId]
  );

  return (
    <PRContainer
      fluid
      actions={[
        {
          label: "Print",
          icon: MdPrint,
          color: "primary",
          onClick: handlePrint,
        },
      ]}
      name="Statistics"
      parentName={parentName}
    >
      <StyledContainer>
        <DynamicReportContent ref={printRef} reportId={previewId} />
      </StyledContainer>
    </PRContainer>
  );
}
