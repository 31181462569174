import PRSwitch from "~components/Generic/PRSwitch";

import CustomerEdit from "./CustomerEdit";
import CustomerList from "./CustomerList";

export default function Customer() {
  const routes = [
    { path: "/organization/customers/form/:id?", component: CustomerEdit },
    { path: "/organization/customers", component: CustomerList },
  ];
  return <PRSwitch routes={routes} />;
}
