import styled from "@emotion/styled";

const HeaderDivider = styled.div`
  width: 1px;
  height: 40px;
  background-color: #ddd;
  margin: 0px 20px;
  @media (max-width: 767.98px) {
    margin: 0px 10px;
  }
  @media (max-width: 576px) {
    height: 26px;
    margin: 0px 0px !important;
  }
`;

export default HeaderDivider;
