import { useEffect, useMemo, useState } from "react";

import { withCardon } from "cardon";
import classNames from "classnames";
import { useFormik } from "formik";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { MdCloudUpload, MdDescription, MdEdit } from "react-icons/md";
import { Card, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { chatbotMaxFileUploadSize } from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import Utils from "~helpers/Utils";

import PRInput from "../PRInput";
import PRModal from "../PRModal";

import "./style.scss";

const focusedStyle = {
  borderColor: "#50c8f4",
};

// const acceptStyle = {
//   borderColor: "#34c38f",
// };

// const rejectStyle = {
//   borderColor: "#f46a6a",
// };

const EditFileNameModal = withCardon(
  function EditFileName({ get, name }) {
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        name: name,
      },
      validationSchema: Yup.object({
        name: Yup.string().required("Name is required"),
      }),

      onSubmit: async (values) => {
        get(values)();
      },
    });

    return (
      <PRModal
        submitText={"Update"}
        title={"Change File Name"}
        zIndex={9999}
        onClick={formik.handleSubmit}
        onClose={get(false)}
      >
        <Row className="g-2">
          <Col xs={12}>
            <Label>Name</Label>
            <PRInput
              invalid={formik.touched.name && formik.errors.name}
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Col>
        </Row>
      </PRModal>
    );
  },
  {
    destroyOnHide: true,
  }
);

export default function PRDropZone({
  onFileChange,
  accept,
  minSize = 0,
  maxSize = chatbotMaxFileUploadSize,
  maxFiles = 1,
  className,
  content: contentProp,
  files,
  hideDropzone = false,
  hideSelectedFiles = false,
  enableFileEdit,
  inputEdit,
  invalid,
  maxDisplayNameLength = 30,
  ...rest
}) {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState([]);

  const content = useMemo(() => {
    if (!contentProp) {
      return t("component.prDropZone.dropFilesHere");
    }
    return contentProp;
  }, [contentProp, t]);

  function handleDrop(files) {
    files.map((file) => {
      const displayName = file.name
        .replace(/\.[^/.]+$/, "")
        .replace(/_/g, " ")
        .replace(/-/g, " ")
        .replace(/ {2}/g, " ")
        .trim();
      if (displayName.length > maxDisplayNameLength) {
        file.displayName = displayName.substring(0, maxDisplayNameLength);
      }
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: Utils.formatBytes(file.size),
        displayName,
      });
    });
    const isValid = files.every((f) => f.displayName?.length > 2);
    setSelectedFiles(files);
    onFileChange?.(files, isValid);
  }
  useEffect(() => {
    if (!files) return;
    const mappedFiles = files?.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: Utils.formatBytes(file.size),
        displayName: file.name.replace(/\.[^/.]+$/, ""),
      })
    );
    setSelectedFiles(mappedFiles);
  }, [files]);

  function handleDropRejected(fileRejections, _e) {
    let tooManyFiles = false;
    for (const fileRejection of fileRejections) {
      for (const rejection of fileRejection.errors) {
        if (rejection.code === "file-invalid-type") {
          AlertHelper.show(t("component.prDropZone.invalidFileType").format((accept || []).join(", ")), "error");
        } else if (rejection.code === "file-too-large") {
          AlertHelper.show(t("component.prDropZone.fileTooLarge").format(Utils.formatBytes(maxSize)), "error");
        } else if (rejection.code === "file-too-small") {
          AlertHelper.show(t("component.prDropZone.fileTooSmall").format(Utils.formatBytes(minSize)), "error");
        } else if (rejection.code === "too-many-files") {
          if (!tooManyFiles) {
            AlertHelper.show(t("component.prDropZone.tooManyFiles").format(maxFiles), "error");
          }
          tooManyFiles = true;
        }
      }
    }
  }
  const { getRootProps, getInputProps, isFocused, isDragActive } = useDropzone({
    onDrop: handleDrop,
    onDropRejected: handleDropRejected,
    minSize: minSize,
    maxSize: maxSize,
    maxFiles: maxFiles,
    accept,
    ...rest,
  });

  const handleFileRename = (file) => async () => {
    const result = await EditFileNameModal.show({ name: file.name });
    if (!result) return;
    let displayName = result.name;
    if (displayName.length > maxDisplayNameLength) {
      displayName = displayName.substring(0, maxDisplayNameLength);
    }
    setSelectedFiles((prev) => {
      const newFiles = prev.map((f) => {
        if (f === file) {
          const createdFile = new File([f], f.name, {
            type: f.type,
          });
          Object.assign(createdFile, {
            preview: f.preview,
            formattedSize: f.formattedSize,
            displayName: displayName,
          });
          return createdFile;
        }
        return f;
      });
      const isValid = newFiles.every((f) => f.displayName?.length > 2);
      onFileChange?.(newFiles, isValid);
      return newFiles;
    });
  };

  const handleChangeFileRename = (file) => (e) => {
    let value = e.target.value;
    if (value.length > maxDisplayNameLength) {
      value = value.substring(0, maxDisplayNameLength);
    }
    setSelectedFiles((prev) => {
      const newFiles = prev.map((f) => {
        if (f === file) {
          const createdFile = new File([f], f.name, { type: f.type });

          Object.assign(createdFile, {
            preview: f.preview,
            formattedSize: f.formattedSize,
            displayName: value,
          });
          return createdFile;
        }
        return f;
      });

      const isValid = newFiles.every((f) => f.displayName?.length > 2);
      onFileChange?.(newFiles, isValid);
      return newFiles;
    });
  };

  return (
    <Form className={classNames("pr-dropzone", className)}>
      {!hideDropzone && (
        <div
          className="dropzone"
          style={{
            transition: "border .1s ease-in-out",
            ...(isFocused || isDragActive ? focusedStyle : {}),
          }}
        >
          <div className="dz-message needsclick mt-2" {...getRootProps({})}>
            <input {...getInputProps()} />
            <div className="mb-3">
              <MdCloudUpload className="display-3 text-muted" />
            </div>
            {typeof content === "string" ? <h5>{content}</h5> : typeof content === "function" ? content() : content}
            {Array.isArray(accept) && accept?.length > 0 && (
              <div>
                {accept.map((a, i) => {
                  return (
                    <span key={i} className="fs-6 badge badge-soft-secondary ms-1">
                      {a}
                    </span>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
      {!!selectedFiles?.length && !hideSelectedFiles && (
        <div className="dropzone-previews mt-3" id="file-previews">
          {selectedFiles.map((f, i) => {
            return (
              <Card
                key={i + "-file"}
                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
              >
                <div className="p-2">
                  <Row className="align-items-center flex-nowrap">
                    <Col className="col-auto">
                      {f.type.startsWith("image/") ? (
                        <img alt={f.displayName} className="avatar-sm rounded bg-light" height="80" src={f.preview} />
                      ) : (
                        <MdDescription className="text-muted ms-2 fs-1" />
                      )}
                    </Col>
                    <Col className="">
                      <Row className="justify-content-between align-items-center">
                        <Col
                          xs
                          className="text-muted font-weight-bold font-size-12 text-truncate overflow-hidden"
                          style={{
                            maxWidth: inputEdit ? "100%" : 250,
                          }}
                        >
                          {inputEdit ? (
                            <PRInput
                              invalid={!(f.displayName?.length > 2) && "File name must be at least 3 characters"}
                              value={f.displayName}
                              onChange={handleChangeFileRename(f)}
                            />
                          ) : (
                            <div className="d-flex justify-content-between">{f.name}</div>
                          )}
                        </Col>
                        {enableFileEdit && (
                          <Col xs="auto">
                            <MdEdit
                              className="text-secondary ms-2"
                              style={{ cursor: "pointer" }}
                              onClick={handleFileRename(f)}
                            />
                          </Col>
                        )}
                      </Row>
                      <p className="mb-0 font-size-12">
                        <strong>{f.formattedSize}</strong>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Card>
            );
          })}
        </div>
      )}
      <Input className="d-none" invalid={!!invalid} />
      {invalid && <FormFeedback type="invalid">{invalid}</FormFeedback>}
    </Form>
  );
}
