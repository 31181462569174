export const tableFilterStorageKey = {
  chatHistory: "chatHistory_filter",
  scenarioManager: "scenarioManager_filter",
  variablesList: "variables_filter",
  ticketList: "ticketList_filter",
  ticketCategoryList: "ticketCategoryList_filter",
  agentLeaderboardList: "agentLeaderboardList_filter",
  intentLeaderboardList: "intentLeaderboardList_filter",
  guideDefinitionList: "guideDefinitionList_filter",
  announcementHistory: "announcementHistory_filter",
  actionList: "actionList_filter",
  filterList: "filterList_filter",
  whatsappTemplateList: "whatsappTemplateList_filter",
  surveyList: "surveyList_filter",
  dictionaryList: "dictionaryList_filter",
  databaseList: "databaseList_filter",
  entityTypesList: "entityTypesList_filter",
  mediaList: "mediaList_filter",
  assetsList: "assetsList_filter",
  botMessagesList: "botMessagesList_filter",
  messageTemplateList: "messageTemplateList_filter",
  moduleList: "moduleList_filter",
  announcementVersionMembers: "announcementVersionMembers_filter",
  intentRecommend: "intentRecommend_filter",
};
