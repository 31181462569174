import PRSwitch from "~components/Generic/PRSwitch";

import StatisticGeneratorEdit from "./StatisticGeneratorEdit";
import StatisticGeneratorList from "./StatisticGeneratorList";
import StatisticGeneratorPreview from "./StatisticGeneratorPreview";
import StatisticGeneratorReportList from "./StatisticGeneratorReportList";

function StatisticGenerator() {
  const routes = [
    { path: "/stats/statistic-generator/form/:id?", component: StatisticGeneratorEdit },
    { path: "/stats/statistic-generator/reports/:id/preview/:previewId?", component: StatisticGeneratorPreview },
    { path: "/stats/statistic-generator/reports/:id?", component: StatisticGeneratorReportList },
    { path: "/stats/statistic-generator/", component: StatisticGeneratorList },
    { path: "/stats/statistic-generator/", to: "/stats/statistic-generator", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}

export default StatisticGenerator; //
