import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

/**
 * @typedef {{
 *   id: 83;
 *   name: key2;
 *   dictionary: string[];
 *   company_value: string;
 *   project: 3;
 *   bot: 3;
 * }} Entity
 */

/** @typedef {keyof typeof import("~constants").dialogComponentsEntityTypeFamilyMap} FamilyType */

/**
 * @typedef {{
 *   id: number;
 *   name: string;
 *   family: FamilyType;
 *   lookup: any;
 *   date_format: string;
 *   entities: Entity[];
 *   lookup_function: any;
 *   project: number;
 *   bot: number;
 * }} EntityType
 */
export const initState = Object.freeze({
  /** @type {EntityType} */
  entityType: {},
});

const entityTypes = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_ENTITY_TYPE:
        draft.entityType = action.payload;
        break;
    }
  });
export default entityTypes;
