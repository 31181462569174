import { apiUrlTicket, apiUrlTicketCategory, apiUrlStatistics } from "~constants";
import Network from "~helpers/Network";

import * as at from "./actionTypes";

export const setTicket = (payload) => ({
  type: at.SET_TICKET,
  payload,
});

export const setTicketList = (payload) => ({
  type: at.SET_TICKET_LIST,
  payload,
});

export const setTicketStats = (payload) => ({
  type: at.SET_TICKET_STATS,
  payload,
});

export const setTicketCategory = (payload) => ({
  type: at.SET_TICKET_CATEGORY,
  payload,
});

export const setTicketCategoryList = (payload) => ({
  type: at.SET_TICKET_CATEGORY_LIST,
  payload,
});

// ----------------- API -----------------

//Ticket

export const getTicket = (guid, projectId) => () => {
  const url = `${apiUrlTicket.get.format(projectId, guid)}`;

  return Network.request(url, {
    loading: true,
    onSuccess: setTicket,
  });
};

export const getTicketList =
  (projectId, params = {}) =>
  () => {
    const url = `${apiUrlTicket.getList.format(projectId)}`;

    return Network.request(url, {
      loading: true,
      onSuccess: (response) => setTicketList(response.results),
      params,
    });
  };

export const deleteTicket = (guid, projectId) => () => {
  const url = `${apiUrlTicket.get.format(projectId, guid)}`;

  return Network.request(url, {
    method: "DELETE",
    loading: true,
    successMsg: true,
  });
};

export const createOrUpdateTicket = (projectId, payload) => () => {
  let url = `${apiUrlTicket.post.format(projectId)}`;
  let method = "POST";
  if (payload.uuid) {
    method = "PUT";
    url = `${url}${payload.uuid}/`;
  }
  return Network.request(url, {
    method,
    data: payload,
    loading: true,
    successMsg: true,
  });
};

export const appendTicketComment = (projectId, uuid, data) => () => {
  const url = `${apiUrlTicket.postComment.format(projectId, uuid)}`;

  return Network.request(url, {
    method: "POST",
    loading: true,
    successMsg: false,
    data,
  });
};

export const appendTicketNote = (projectId, uuid, text) => () => {
  const url = `${apiUrlTicket.postNote.format(projectId, uuid)}`;

  return Network.request(url, {
    method: "POST",
    loading: true,
    successMsg: true,
    data: {
      note: text,
    },
  });
};

export const createPublicTicketToken = (projectId, uuid, payload) => () => {
  let url = `${apiUrlTicket.getShareTicket.format(projectId, uuid)}`;

  return Network.request(url, {
    method: "POST",
    loading: true,
    successMsg: true,
    data: payload,
  });
};

export const getTicketStats =
  (projectId, data = {}) =>
  () => {
    const url = `${apiUrlStatistics.getTicketStatistics.format(projectId)}`;

    return Network.request(url, {
      method: "POST",
      loading: true,
      onSuccess: setTicketStats,
      data,
    });
  };

//Ticket public
export const getPublicTicket = (alias, share_token) => () => {
  const url = alias
    ? `${apiUrlTicket.getPublicAlias.format(alias, share_token)}`
    : `${apiUrlTicket.getPublic.format(share_token)}`;

  return Network.request(url, {
    loading: true,
    noBearer: true,
  });
};
export const appendPublicTicketComment = (alias, share_token, data) => () => {
  const url = alias
    ? `${apiUrlTicket.postPublicCommentAlias.format(alias, share_token)}`
    : `${apiUrlTicket.postPublicComment.format(share_token)}`;

  return Network.request(url, {
    method: "POST",
    loading: true,
    successMsg: true,
    noBearer: true,
    data,
  });
};

// export const appendPublicTicketNote = (share_token, text) => () => {
//   // const url = `${apiUrlTicket.postPublicNote.format(share_token)}`;

//   return Network.request(url, {
//     method: "POST",
//     loading: true,
//     successMsg: true,
//     noBearer: true,
//     data: {
//       note: text,
//     },
//   });
// };
export const approvePublicTicket = (alias, share_token) => () => {
  // const url = `${apiUrlTicket.postPublicApprove.format(share_token)}`;
  const url = alias
    ? `${apiUrlTicket.postPublicApproveAlias.format(alias, share_token)}`
    : `${apiUrlTicket.postPublicApprove.format(share_token)}`;

  return Network.request(url, {
    method: "POST",
    loading: true,
    successMsg: true,
    noBearer: true,
  });
};
//Ticket Category

export const getTicketCategoryList =
  (projectId, options = {}) =>
  () => {
    const url = `${apiUrlTicketCategory.get.format(projectId)}`;

    return Network.request(url, {
      loading: true,
      onSuccess: (response) => setTicketCategoryList(response.results),
      ...options,
    });
  };

export const getTicketCategory = (projectId, categoryId) => () => {
  const url = `${apiUrlTicketCategory.get.format(projectId)}${categoryId}/`;

  return Network.request(url, {
    loading: true,
    onSuccess: setTicketCategory,
  });
};

export const deleteTicketCategory = (projectId, categoryId) => () => {
  const url = `${apiUrlTicketCategory.get.format(projectId)}${categoryId}/`;

  return Network.request(url, {
    method: "DELETE",
    loading: true,
    successMsg: true,
  });
};

export const createOrUpdateTicketCategory = (projectId, payload) => () => {
  let url = `${apiUrlTicketCategory.get.format(projectId)}`;
  let method = "POST";
  if (payload.id) {
    method = "PUT";
    url = `${url}${payload.id}/`;
  }
  return Network.request(url, {
    method,
    data: payload,
    loading: true,
    successMsg: true,
  });
};
