import Network from "~helpers/Network";

export async function getBotSettings(project, botId) {
  return await Network.request(`/project/${project}/chatbot/${botId}/`);
}

export async function updateBotSettings(project, botId, body) {
  return await Network.request(`/project/${project}/chatbot/${botId}/`, {
    data: body,
    method: "PUT",
  });
}

export async function trainBot(project, botId, trainType, data) {
  return await Network.request(`/project/${project}/chatbot/${botId}/train/${trainType}`, {
    method: "POST",
    data: data || {},
  });
}

export async function listBotSessions(project, chatbot, page = 1) {
  return await Network.request(`/project/${project}/chatbot/${chatbot}/session/?page=${page}&order_by=-start_time`);
}
