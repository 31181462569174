import PRSwitch from "~components/Generic/PRSwitch";

import MessageTemplateDefinition from "./MessageTemplateDefinition";
import MessageTemplateEdit from "./MessageTemplateEdit";

function MessageTemplate() {
  const routes = [
    { path: "/settings/message-template/form/:id?", component: MessageTemplateEdit },
    { path: "/settings/message-template/", component: MessageTemplateDefinition },
    { path: "/settings/message-template/", to: "/settings/message-template", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}

export default MessageTemplate;
