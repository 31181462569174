import React, { useEffect, useState } from "react";

import classNames from "classnames";
import Drawer from "rc-drawer";
import { MdClose, MdEdit, MdHelp, MdMenuBook } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

// @svgr/webpack import syntax
import { ReactComponent as GuideImage } from "~assets/images/guide.svg";
import useBootstrapBreakpoint from "~common/hooks/useBootstrapBreakpoint";
import useScrollShadow from "~common/hooks/useScrollShadow";
import { useStorage } from "~common/hooks/useStorage";
import PRButton from "~components/Generic/PRButton";
import PRTooltip from "~components/Generic/PRTooltip";
import { guideType } from "~constants";
import RenderHelper from "~helpers/RenderHelper";
import store from "~store";
import { selectVisibleAppAnnouncementList } from "~store/announcement/selectors";
import { setGuideOpen } from "~store/custom/guide/actions";
import { selectGuideOpen } from "~store/custom/guide/selectors";
import { getGuideList } from "~store/settings/guideDefinition/actions";
import { selectGuideList } from "~store/settings/guideDefinition/selectors";
import { selectUserInfo } from "~store/user/selectors";

import motionProps from "./motion";

import "rc-drawer/assets/index.css";
import "./style.scss";

export function GuideDrawer() {
  const dispatch = useDispatch();

  const [selectedDoc, setSelectedDoc] = useState(null);
  const isSm = useBootstrapBreakpoint("sm");
  const userInfo = useSelector(selectUserInfo);

  const open = useSelector(selectGuideOpen);
  const guideList = useSelector(selectGuideList);
  const visibleAppAnnouncementList = useSelector(selectVisibleAppAnnouncementList);

  const [docs, setDocs] = useStorage("PALMATE_GUIDE", []);

  const handleClose = () => {
    dispatch(setGuideOpen(false));
  };
  const handleOpen = () => {
    dispatch(setGuideOpen(true));
  };

  useEffect(() => {
    dispatch(getGuideList());
  }, [dispatch]);

  useEffect(() => {
    if (!guideList) return;
    const guideTypeOnly = guideList.filter((guide) => guide.type === guideType.Guide);

    setDocs(guideTypeOnly);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guideList]);

  useEffect(() => {
    //track page changes
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList") {
          const { pathname } = window.location;
          if (!docs?.length) return;
          const doc = docs.find((doc) => new RegExp(doc.test_regex).test(pathname));

          setSelectedDoc(doc);
          if (!doc) {
            const openState = selectGuideOpen(store.getState());
            if (openState) {
              dispatch(setGuideOpen(false));
            }
          }
        }
      });
    });
    observer.observe(document.getElementById("root"), {
      attributes: true,
      childList: true,
      subtree: true,
    });
    return () => {
      observer.disconnect();
    };
  }, [dispatch, docs]);

  const renderedMd = RenderHelper.renderMd(selectedDoc?.body || "", {
    html: true,
  });

  const { ref, topProps, bottomProps } = useScrollShadow();

  const isVisible = !open && selectedDoc?.body;

  // const handleCtrlS = (e) => {
  //   if (e.ctrlKey && e.keyCode === 83) {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     dispatch(createOrUpdateGuide(selectedDoc));
  //   }
  // };

  return (
    <div
      className={classNames("pr-guide-drawer", {
        "app-announcement-mode": visibleAppAnnouncementList?.length,
      })}
    >
      {isVisible && (
        <PRTooltip delay={1000} placement="left" title="Open Guide">
          <div className="open-icon" onClick={handleOpen}>
            <div className="d-flex">
              <MdHelp className="fs-3" />
            </div>
          </div>
        </PRTooltip>
      )}
      <Drawer
        open={open}
        onClose={handleClose}
        {...motionProps}
        destroyOnClose
        rootClassName={visibleAppAnnouncementList?.length ? "app-announcement-mode" : ""}
        width={isSm ? "100%" : 420}
      >
        <div className="guide">
          <Row className="g-0 guide-title">
            <Col xs="auto">
              <h3 className="mb-0 d-flex align-items-center">
                <MdMenuBook className="fs-2 me-2 text-secondary" />
                {selectedDoc?.title}
              </h3>
            </Col>
            <Col xs="auto">
              <PRButton
                noBorder
                outline
                className="d-flex align-items-center"
                color="dark"
                icon={MdClose}
                onClick={handleClose}
              />
            </Col>
          </Row>

          <div
            ref={ref}
            className={classNames("guide-body markdown-wrapper", {
              "app-announcement-mode": visibleAppAnnouncementList?.length,
            })}
          >
            <div {...topProps} />
            <div {...bottomProps} />
            <div className="markdown-body">
              <div dangerouslySetInnerHTML={{ __html: renderedMd }} />
            </div>
            <GuideImage alt="Guide" className="guide-image" />
            {userInfo?.is_superuser && (
              <Row className="g-0 toolbox justify-content-end">
                <Col xs="auto">
                  <PRButton
                    className=" "
                    color="primary"
                    icon={MdEdit}
                    link={"/settings/guide-definition/form/" + selectedDoc?.id}
                    linkProps={{
                      newTab: true,
                    }}
                    size="sm"
                    tooltipText="Edit Guide"
                  />
                </Col>
              </Row>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
}
