import { forwardRef, Fragment, useEffect, useRef, useState } from "react";

import { PalTooltip } from "@palamar/fe-library";
import Carousel from "react-multi-carousel";

import styled from "@emotion/styled";
import { Flag, Phone } from "@mui/icons-material";
import { Box, Button, Card, CardActions, CardContent, CardMedia, Divider, Typography } from "@mui/material";

import ImagePreviewModal from "~common/modals/ImagePreviewModal";
import PRPhoneNumber from "~components/Generic/PRPhoneNumber";
import {
  WEBCHAT_REDIRECT_KEY,
  WEBCHAT_REDIRECT_MEDIUM_KEY,
  WEBCHAT_REDIRECT_MEDIUM_VALUE,
  WEBCHAT_REDIRECT_VALUE,
} from "~constants";
import StyledHelper from "~helpers/StyledHelper";
import Utils from "~helpers/Utils";

import { usePRRefContext } from "../../context";
import "react-multi-carousel/lib/styles.css";

const imageHeight = 180;
const getStaticMapUrl = (lat, lng, name) => {
  const baseUrl = "https://maps.googleapis.com/maps/api/staticmap";
  const params = {
    center: `${lat},${lng}`,
    zoom: 16,
    size: `560x${imageHeight}`,
    markers: `color:red|size:mid|${lat},${lng}`,
    key: window.pr_config.google,
  };
  return `${baseUrl}${Utils.qs(params)}`;
};

// const getDirectionsUrl = (lat, lng) => {
//   const baseUrl = "https://www.google.com/maps/dir/";
//   const params = {
//     api: 1,
//     destination: `${lat},${lng}`,
//   };
//   return `${baseUrl}${Utils.qs(params)}`;
// };

const getMapQueryUrl = (lat, lng) => {
  const baseUrl = "https://www.google.com/maps/search/";
  const params = {
    api: 1,
    query: `${lat},${lng}`,
  };
  return `${baseUrl}${Utils.qs(params)}`;
};

const CarouselWrapper = styled(Box, StyledHelper.sfw("maxWidth"))`
  width: auto;

  .react-multi-carousel-track {
    & > li {
      margin: ${({ theme }) => theme.spacing(2)} 0;
    }
  }

  /* ${({ showCarousel }) => !showCarousel && "display: none;"} */
`;

const StyledCard = styled.div`
  .MuiPaper-root {
    border-radius: 14px;
  }
  .MuiCardMedia-root {
    background-size: contain;
  }
  height: 100%;
  margin: ${({ theme }) => theme.spacing(1)};
`;
const responsiveImage = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function ToleratedClickWrapper({ children, onClick, clickThresholdPx = 10, ...rest }) {
  const isDraggingRef = useRef(false);
  const startXRef = useRef(0);
  const startYRef = useRef(0);

  const handleMouseDown = (event) => {
    startXRef.current = event.clientX;
    startYRef.current = event.clientY;
    isDraggingRef.current = false;

    const handleMouseMove = (moveEvent) => {
      const deltaX = Math.abs(moveEvent.clientX - startXRef.current);
      const deltaY = Math.abs(moveEvent.clientY - startYRef.current);
      if (deltaX > clickThresholdPx || deltaY > clickThresholdPx) {
        isDraggingRef.current = true;
      }
    };

    const handleMouseUp = (e) => {
      if (!isDraggingRef.current) {
        onClick?.(e);
      }
      // Clean up event listeners
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  return (
    <Box sx={{ cursor: "pointer" }} onMouseDown={handleMouseDown} {...rest}>
      {children}
    </Box>
  );
}
function CardItemBase({ card, noButton, loading }, ref) {
  const distributedRef = usePRRefContext();

  const handleDragStart = (event) => {
    event.stopPropagation(); // Prevents the carousel from moving when dragging the image
  };

  const handleClickOpenMapQuery = () => {
    window.open(getMapQueryUrl(card.lat, card.lng), "_blank");
  };

  const handleClickImagePreview = (link) => async () => {
    distributedRef.current.disableScrollOnMessage = true;

    // Show image asynchronously until close button is clicked
    await ImagePreviewModal.show({ image: link });

    setTimeout(() => {
      distributedRef.current.disableScrollOnMessage = false;
    }, 100);
  };

  return (
    <StyledCard ref={ref}>
      <Card
        elevation={2}
        sx={{
          height: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {loading ? (
          <Box alignItems="center" display="flex" height={270} justifyContent="center">
            {/* <Skeleton height={270} variant="rectangular" width={300} /> */}
          </Box>
        ) : (
          <>
            {(card.images?.length > 0 || card?.type === "location") && (
              <Box height={imageHeight} mt={-2} width={1} onDragStart={handleDragStart} onMouseDown={handleDragStart}>
                <Carousel
                  draggable={true}
                  keyBoardControl={false}
                  partialVisible={false}
                  removeArrowOnDeviceType={["tablet", "mobile", "desktop", "superLargeDesktop"]}
                  responsive={responsiveImage}
                  showDots={card.images?.length > 1}
                >
                  {card?.type === "location" && (
                    <ToleratedClickWrapper onClick={handleClickOpenMapQuery}>
                      <CardMedia
                        key={"location"}
                        image={getStaticMapUrl(card.lat, card.lng, card.title)}
                        sx={{ height: imageHeight, width: "100%" }}
                        title={`Location - ${card.title}`}
                        // onClick={handleClickOpenMapQuery}
                      />
                    </ToleratedClickWrapper>
                  )}
                  {card.images.map((image, index) => (
                    <ToleratedClickWrapper key={index} onClick={handleClickImagePreview(image)}>
                      <CardMedia key={index} image={image} sx={{ height: imageHeight }} title={`Image ${index + 1}`} />
                    </ToleratedClickWrapper>
                  ))}
                </Carousel>
              </Box>
            )}

            <CardContent
              sx={{
                display: "-webkit-box",
                WebkitLineClamp: 9,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingBottom: 0,
                height: "auto",
                marginTop: card.images?.length > 0 ? 3 : 0,
                marginBottom: -2,
              }}
            >
              <PalTooltip title={card.title}>
                <Typography
                  gutterBottom
                  component="div"
                  sx={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    width: "100%",
                    textWrap: "wrap",
                  }}
                  variant="body1"
                >
                  {card.title}
                </Typography>
              </PalTooltip>
              <Typography sx={{ color: "text.secondary" }} variant="body2" whiteSpace={"pre-line"}>
                {card.description}
              </Typography>
            </CardContent>
            {(card?.distance || card?.phone) && (
              <Box display={"flex"} justifyContent={"space-between"} marginTop="auto" mb={0.5} ml={2} mr={2} pt={1}>
                <span>
                  {card?.phone && (
                    <Typography
                      alignItems="center"
                      display="flex"
                      gap={0.5}
                      sx={{ color: "text.secondary" }}
                      variant="body2"
                    >
                      <Phone color="primary" fontSize="small" />
                      <a href={`tel:${card.phone}`}>
                        <PRPhoneNumber render showPlainOnFail value={card.phone} />
                      </a>
                    </Typography>
                  )}
                </span>
                <span>
                  {card?.distance && (
                    <Typography
                      alignItems="center"
                      display="flex"
                      gap={0.5}
                      sx={{ color: "text.secondary" }}
                      variant="body2"
                    >
                      {card.distance}
                      <Flag color="secondary" fontSize="small" />
                    </Typography>
                  )}
                </span>
              </Box>
            )}
            {!noButton && (
              <Box marginTop={card?.distance || card?.phone ? 1 : "auto"} paddingTop={0}>
                <Divider />
                <CardActions
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 0,
                    "& > *": {
                      margin: 0,
                    },
                  }}
                >
                  {card.buttons?.map((button, index, arr) => {
                    let urlStr = button?.url || "";

                    let enableRedirectQuery = true;
                    if (urlStr.includes("disable_redirect_query=")) {
                      enableRedirectQuery = false;
                    }

                    if (enableRedirectQuery) {
                      const parsedUrl = new URL(urlStr);
                      parsedUrl.searchParams.append(WEBCHAT_REDIRECT_KEY, WEBCHAT_REDIRECT_VALUE);
                      parsedUrl.searchParams.append(WEBCHAT_REDIRECT_MEDIUM_KEY, WEBCHAT_REDIRECT_MEDIUM_VALUE);
                      urlStr = parsedUrl.toString();
                    }

                    urlStr = urlStr.replace(/[&?]?disable_redirect_query=.*?[&?]?/g, "");

                    return (
                      <Fragment key={index}>
                        <Button fullWidth href={urlStr} size="small" target="_blank" variant="text">
                          {button.text}
                        </Button>
                        {index !== arr.length - 1 && <Divider sx={{ width: 1 }} />}
                      </Fragment>
                    );
                  })}
                </CardActions>
              </Box>
            )}
          </>
        )}
      </Card>
    </StyledCard>
  );
}

const getChatAreaParent = () => {
  const id = "chat-area";
  const dom = document.getElementById(id);

  return dom;
};

const CardItem = forwardRef(CardItemBase);
function getWidth(width) {
  const dom = getChatAreaParent();
  let targetWidth = width ?? dom?.clientWidth ?? window.innerWidth;
  const baseWith = targetWidth > 900 ? 900 : targetWidth > 300 ? 300 : targetWidth;

  return baseWith;
}

export default function CarouselMessage({ carouselData }) {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const targetDom = getChatAreaParent();

    const observer = new ResizeObserver((entries) => {
      if (entries[0]) {
        setWidth(entries[0].contentRect.width);
      }
    });

    if (targetDom) {
      observer.observe(targetDom);
    }
    return () => {
      if (targetDom) {
        observer.unobserve(targetDom);
      }
    };
  }, []);

  const hasAnyButton = carouselData.cards.some((card) => card.buttons.length > 0);
  const cardCount = carouselData.cards.length;
  let itemCount = cardCount > 4 ? 3 : Math.max(cardCount - 1, 1);
  //calculate  count by with
  if (width < 600) {
    itemCount = itemCount < 1 ? itemCount : 1;
  } else if (width < 900) {
    itemCount = itemCount < 2 ? itemCount : 2;
  }

  const responsiveCard = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: itemCount,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1500 },
      items: itemCount,
    },
    tablet: {
      breakpoint: { max: 1500, min: 1000 },
      items: itemCount,
    },
    mobile: {
      breakpoint: { max: 1000, min: 0 },
      items: 1,
    },
  };
  if (cardCount === 1 || width === 0) {
    return (
      <CarouselWrapper display="flex" justifyContent="center">
        <Box width={300}>
          {/* {width === 0 ? <CardItem loading /> : <CardItem card={carouselData.cards[0]} noButton={!hasAnyButton} />} */}
          {width === 0 ? <div /> : <CardItem card={carouselData.cards[0]} noButton={!hasAnyButton} />}
        </Box>
      </CarouselWrapper>
    );
  }
  let calculatedMaxWidth = cardCount > 4 ? 1200 : cardCount * 300;
  if (calculatedMaxWidth > width) {
    calculatedMaxWidth = width;
  }
  return (
    <CarouselWrapper showCarousel={width > 0}>
      <Box maxWidth={calculatedMaxWidth} minWidth={300} width={width}>
        <Carousel
          // centerMode={true}
          deviceType="mobile"
          draggable={true}
          keyBoardControl={true}
          responsive={responsiveCard}
        >
          {carouselData.cards.map((card, index) => (
            <CardItem key={index} card={card} index={index} noButton={!hasAnyButton} />
          ))}
        </Carousel>
      </Box>
    </CarouselWrapper>
  );
}
