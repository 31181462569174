import React, { useEffect, useState } from "react";

import { useTranslation, withTranslation } from "react-i18next";
import { MdArrowUpward, MdConfirmationNumber, MdPeople, MdQuestionMark, MdReply, MdThumbsUpDown } from "react-icons/md";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

import { getProjectStats } from "~apiRequests/project";
import useLoading from "~common/hooks/useLoading";
import PRChart from "~components/Generic/PRChart";
import PRContainer from "~components/Generic/PRContainer";
import PRLink from "~components/Generic/PRLink";
import PRPage from "~components/Generic/PRPage";
import PRTooltip from "~components/Generic/PRTooltip";
import DataCard from "~components/Statistics/DataCard";
import DateHelper from "~helpers/DateHelper";
import { selectPopupSettingsState } from "~store/settings/popupSettings/selectors";
import { selectCurrentProject } from "~store/user/selectors";
import "./style.scss";

const Dashboard = () => {
  const currentProject = useSelector(selectCurrentProject);
  const popupSettings = useSelector(selectPopupSettingsState);
  const { t } = useTranslation();

  const [statsData, setStatsData] = useState(null);

  const [loading, q] = useLoading();

  useEffect(() => {
    if (!currentProject?.id) return;
    q(
      getProjectStats(currentProject.id).then((response) => {
        setStatsData(response);
      })
    );
  }, [currentProject.id, q]);

  const progressTickets = statsData?.total_progress_count;
  const doneTickets = statsData?.ticket_resolved_count + statsData?.ticket_approved_count;
  const waitingTickets = statsData?.ticket_waiting_count;
  const totalTickets = statsData?.total_ticket_count;
  const getOptionsHelpDesk = () => {
    return {
      tooltip: {
        trigger: "item",
      },
      legend: {
        type: "scroll",
        orient: "vertical",

        right: 10,
        top: "center",
        formatter: function (name) {
          if (name === "Progress") {
            const percent = ((progressTickets / totalTickets) * 100).toFixed(2);
            return t("dashboard.homepage.progressPercentage").format(percent.toString());
          } else if (name === "Done") {
            const percent = ((doneTickets / totalTickets) * 100).toFixed(2);
            return t("dashboard.homepage.donePercentage").format(percent.toString());
          } else if (name === "Waiting") {
            const percent = ((waitingTickets / totalTickets) * 100).toFixed(2);
            return t("dashboard.homepage.waitingPercentage").format(percent.toString());
          }
          return name;
        },
      },
      series: [
        {
          name: t("dashboard.homepage.ticketCount"),
          type: "pie",

          top: "15%",
          // left: "-30%",
          radius: ["35%", "75%"],
          center: ["35%", "38%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2,
          },
          label: {
            show: true,
          },
          labelLine: {
            show: true,
            align: "center",
          },
          data: [
            { value: doneTickets, name: t("dashboard.homepage.done") },
            { value: waitingTickets, name: t("dashboard.homepage.waiting") },
            { value: progressTickets, name: t("dashboard.homepage.progress") },
          ],
        },
      ],
    };
  };
  const getOptionsFeedback = () => {
    const agentFeedbackAvg = statsData?.agent_feedback_avarage;
    const botFeedbackAvg = statsData?.bot_feedback_avarage;
    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        data: [t("dashboard.homepage.agentFeedback"), t("dashboard.homepage.botFeedback")],
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
      },
      yAxis: {
        type: "category",
        data: [""],
      },
      series: [
        {
          name: t("dashboard.homepage.agentFeedback"),
          type: "bar",
          data: [agentFeedbackAvg],
          label: {
            show: true,
            position: "inside",
            fontSize: 18,
            formatter: (obj) => {
              return `${obj.data.toFixed(2)}/5`;
            },

            textBorderWidth: 1,
            textBorderColor: "#666",
          },
        },
        {
          name: t("dashboard.homepage.botFeedback"),
          type: "bar",
          data: [botFeedbackAvg],
          label: {
            show: true,
            position: "inside",
            fontSize: 18,
            formatter: (obj) => {
              return `${obj.data.toFixed(2)}/5`;
            },
            textBorderWidth: 1,
            textBorderColor: "#666",
          },
        },
      ],
    };
  };

  const getOptionsMessageStatistics = () => {
    const dates = statsData?.general_messaging_stats?.map((item) => Object.keys(item)?.[0]);

    const visitors = [];
    const questions = [];
    const responses = [];

    for (let i = 0; i < dates?.length; i++) {
      const date = dates?.[i];
      const data = statsData?.general_messaging_stats[i][date];
      const dateObj = DateHelper.getDateTimeLocal(date, "DD/MM/YYYY").startOf("day").toDate();
      visitors.push([dateObj, data.total_visitors]);
      questions.push([dateObj, data.total_questions]);
      responses.push([dateObj, data.total_responses]);
    }

    return {
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: [t("dashboard.homepage.visitors"), t("dashboard.homepage.questions")], //, t("dashboard.homepage.responses")],
      },
      xAxis: {
        type: "time",
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: t("dashboard.homepage.visitors"),
          type: "line",
          data: visitors,
        },
        {
          name: t("dashboard.homepage.questions"),
          type: "line",
          data: questions,
        },
        // {
        //   name: t("dashboard.homepage.responses"),
        //   type: "line",
        //   data: responses,
        // },
      ],
      grid: {
        bottom: "15%",
      },
      dataZoom: [
        {
          left: "10%",
          right: "10%",
          type: "slider",
          xAxisIndex: [0],
          filterMode: "filter",
          minValueSpan: 604800000, // 7 days
          labelFormatter: function (value) {
            //only date
            return DateHelper.moment(new Date(value)).format("DD/MM/YYYY");
          },
        },
      ],
    };
  };

  const getOptionHotTopic = () => {
    let data = statsData?.hot_topics || [];
    data = data.sort((b, a) => b.count - a.count);
    let tags = data.map((item) => item.tag);
    let chatbotNames = [...new Set(data.map((item) => item.chatbot_name))];

    let series = chatbotNames.map((chatbot_name) => {
      let counts = tags.map((tag) => {
        let item = data.find((item) => item.chatbot_name === chatbot_name && item.tag === tag);
        return item ? item.count : 0;
      });

      return {
        name: chatbot_name,
        type: "bar",
        data: counts,
        // barWidth: "40%",
        itemStyle: {
          label: {
            show: true,
            position: "top",
            formatter: "{c}",
          },
        },
      };
    });

    return {
      tooltip: {
        trigger: "axis",
      },
      xAxis: {
        type: "value",
      },
      yAxis: {
        type: "category",
        data: tags,
      },
      legend: {
        data: chatbotNames,
      },
      series,
    };
  };

  return (
    <PRContainer
      className="pr-dashboard"
      description={t("dashboard.homepage.description")}
      name={t("layout.sidebar.home")}
    >
      <Row className="mt-4">
        <Col lg={4} md={6} sm={12}>
          <DataCard
            color="success"
            icon={MdPeople}
            label={t("dashboard.homepage.visitors")}
            loading={loading}
            value={statsData?.total_visitors}
          />
        </Col>
        <Col lg={4} md={6} sm={12}>
          <DataCard
            color="orange"
            icon={MdQuestionMark}
            label={t("dashboard.homepage.questions")}
            loading={loading}
            value={statsData?.total_question}
          />
        </Col>
        <Col lg={4} md={6} sm={12}>
          <DataCard
            color="teal"
            icon={MdReply}
            label={t("dashboard.homepage.responses")}
            loading={loading}
            value={statsData?.total_response}
          />
        </Col>
        <Col lg={6} md={6} sm={12}>
          <DataCard
            color="primary"
            icon={MdConfirmationNumber}
            infoText={
              <div className=" fw-light fs-6">
                {t("dashboard.homepage.ticketsInfo.preLink")}
                <PRLink className="mx-1" color="link" to="/helpdesk">
                  {t("dashboard.homepage.ticketsInfo.link")}
                  {/* <i className="??? mdi-arrow-right ms-1"></i> */}
                </PRLink>
                {t("dashboard.homepage.ticketsInfo.postLink")}
              </div>
            }
            label={t("dashboard.homepage.tickets")}
            loading={loading}
            value={
              totalTickets ? (
                <>
                  {totalTickets}
                  {!!progressTickets && (
                    <PRTooltip title={t("dashboard.homepage.progressTicketsTooltip")}>
                      <span className="text-success ms-2 font-size-16">
                        {progressTickets} <MdArrowUpward />
                      </span>
                    </PRTooltip>
                  )}
                  {!!waitingTickets && (
                    <PRTooltip title={t("dashboard.homepage.waitingTicketsTooltip")}>
                      <span className="text-danger ms-2 font-size-16">
                        {waitingTickets} <MdArrowUpward />
                      </span>
                    </PRTooltip>
                  )}
                </>
              ) : (
                0
              )
            }
          />
        </Col>
        {popupSettings.show_thumps_up && (
          <Col lg={6} md={6} sm={12}>
            <DataCard
              color="pink"
              icon={(props) => (
                <MdThumbsUpDown
                  {...props}
                  style={{
                    // fontSize: 18,
                    ...props.style,
                  }}
                />
              )}
              label={t("dashboard.homepage.likesDislikes")}
              loading={loading}
              value={`${statsData?.like_count || 0}/${statsData?.dislike_count || 0}`}
            />
          </Col>
        )}
        <Col lg="12" md="12">
          <PRPage title={t("dashboard.homepage.messageStatistics")}>
            <PRChart loading={loading} option={getOptionsMessageStatistics()} />
          </PRPage>
        </Col>
        <Col lg="6" md="12">
          <PRPage title={t("dashboard.homepage.hotTopics")}>
            <PRChart height="20vh" loading={loading} option={getOptionHotTopic()} />
          </PRPage>
        </Col>
        <Col lg="6" md="12">
          <PRPage title={t("dashboard.homepage.agentBotFeedback")}>
            <PRChart height="20vh" loading={loading} option={getOptionsFeedback()} />
          </PRPage>
        </Col>
        <Col lg="6" md="12">
          <PRPage title={t("dashboard.homepage.helpDesk")}>
            <PRChart height="20vh" loading={loading} option={getOptionsHelpDesk()} />
          </PRPage>
        </Col>
        {/*  <Col md="12" lg="6">
            <PRPage title="Project Users">
              <PerfectScrollbar options={scrollbarOptions} height="20vh" className="project-users p-2">
                {statsData?.project_users.map((user) => (
                  <Row className="justify-content-between" key={user.id}>
                    <Col xs className="d-flex align-items-center">
                      <i className="??? fa-user-circle me-2 font-size-24 text-secondary"></i>
                      <Label size="md">
                        {user.first_name} {user.last_name}
                      </Label>
                    </Col>
                    <Col xs="auto" className="d-flex align-items-center">
                      {user.roles.map((role) => (
                        <Badge color={role === userRole.admin ? "danger" : "secondary"} className="me-1" key={role}>
                          {userRoleMap[role]}
                        </Badge>
                      ))}
                    </Col>
                  </Row>
                ))}
              </PerfectScrollbar>
             <PRChart option={getOptionRoleUser()} height="20vh" /> 
            </PRPage>
          </Col>*/}
      </Row>
    </PRContainer>
  );
};

export default withTranslation()(Dashboard);
