import { createRef, useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { MdAdd, MdDelete, MdDownload, MdPreview } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import useProjectChange from "~common/hooks/useProjectChange";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import {
  popupSettingsMapper,
  apiUrlStatisticGenerator,
  statisticGeneratorReportTypeMap,
  statisticGeneratorReportType,
} from "~constants";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import FileHelper from "~helpers/FileHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import Utils from "~helpers/Utils";
import {
  deleteStatisticGeneratorReport,
  downloadStatisticGeneratorReport,
  generateAsyncStatisticGenerator,
} from "~store/statisticGenerator/actions";
import { selectCurrentProject } from "~store/user/selectors";

import DynamicReportChartModal from "./DynamicReportChartModal";

export default function StatisticGeneratorReportList() {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const tableRef = createRef();

  const currentProject = useSelector(selectCurrentProject);

  useProjectChange(() => {
    HistoryHelper.push("/stats/statistic-generator/", { scope: "dashboard" });
  }, []);
  const columns = useMemo(() => {
    const handleDownloadExcel = (item) => async () => {
      const excelData = await dispatch(
        downloadStatisticGeneratorReport(currentProject.id, item.id, {
          responseType: "blob",
        })
      );
      const fileName = item.file_name.endsWith(".xlsx") ? item.file_name.slice(0, -5) : item.file_name;
      FileHelper.saveAs(excelData, `${Utils.slugify(fileName) + ".xlsx"}` || `report_${item.id}`);
    };

    const handleDownloadJson = (item) => async () => {
      const jsonData = await dispatch(downloadStatisticGeneratorReport(currentProject.id, item.id));
      const fileName = item.file_name.endsWith(".json") ? item.file_name.slice(0, -5) : item.file_name;
      FileHelper.saveAs(
        JSON.stringify(jsonData, null, 2),
        `${Utils.slugify(fileName) + ".json"}` || `report_${item.id}`,
        "application/json"
      );
    };

    const handleShowReportPreview = (item) => async (e) => {
      //if clicked with shift. cancel default action and open in modal
      if (e.shiftKey) {
        e.preventDefault();
        DynamicReportChartModal.show({ reportId: item.id });
      }
    };

    const handleDeleteReportPreview = (item) => async () => {
      if (!(await DialogHelper.showQuestionYesNo("Delete Report", "Are you sure you want to delete the report?")))
        return;
      await dispatch(deleteStatisticGeneratorReport(currentProject.id, item.generator, item.id));
      tableRef.current?.refresh();
    };
    return [
      {
        label: t("dashboard.statisticGeneratorReportList.fileName"),
        key: "name",
      },
      {
        label: t("dashboard.statisticGeneratorReportList.reportType"),
        key: "report_type",
        render: (row) => {
          return statisticGeneratorReportTypeMap[row.report_type]
            ? t(statisticGeneratorReportTypeMap[row.report_type])
            : row.report_type;
        },
      },
      {
        label: t("common.created"),
        key: "created",
        render: (item) => (
          <div>
            <PRTooltip title={DateHelper.getDateTimeLocal(item.created).format("LLT")}>
              <span>{DateHelper.getDateTimeLocal(item.created).fromNow()}</span>
            </PRTooltip>
          </div>
        ),
      },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        render: (row) => (
          <div className="d-flex justify-content-center gap-1">
            {row.report_type === statisticGeneratorReportType.WEB && (
              <PRButton
                outline
                color="primary"
                icon={MdPreview}
                link={`/stats/statistic-generator/reports/${id}/preview/${row.id}`}
                size="sm"
                tooltipText={t("dashboard.statisticGeneratorReportList.previewReport")}
                onClick={handleShowReportPreview(row)}
              />
            )}
            {row.report_type === statisticGeneratorReportType.JSON && (
              <PRButton
                outline
                color="primary"
                icon={MdDownload}
                size="sm"
                tooltipText={t("dashboard.statisticGeneratorReportList.downloadJson")}
                onClick={handleDownloadJson(row)}
              />
            )}
            {row.report_type === statisticGeneratorReportType.EXCEL && (
              <PRButton
                outline
                color="primary"
                icon={MdDownload}
                size="sm"
                tooltipText={t("dashboard.statisticGeneratorReportList.downloadExcel")}
                onClick={handleDownloadExcel(row)}
              />
            )}
            <PRButton
              outline
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText="Delete"
              onClick={handleDeleteReportPreview(row)}
            />
          </div>
        ),
      },
    ];
  }, [dispatch, currentProject?.id, t]);

  const actions = useMemo(() => {
    const handleClickGenerateReport = async () => {
      if (
        !(await DialogHelper.showQuestionYesNo(
          t("dashboard.statisticGeneratorReportList.generateReport"),
          t("dashboard.statisticGeneratorReportList.generateReportMessage")
        ))
      )
        return;
      await dispatch(generateAsyncStatisticGenerator(currentProject.id, id));
      tableRef.current?.refresh();
    };

    return [
      {
        label: t("dashboard.statisticGeneratorReportList.generateReport"),
        icon: MdAdd,
        color: "success",
        onClick: handleClickGenerateReport,
      },
    ];
  }, [dispatch, id, currentProject?.id, tableRef, t]);

  const parentName = useMemo(
    () => [
      {
        label: t("dashboard.statisticGenerator"),
        url: "/stats/statistic-generator/",
      },
      {
        label: t("dashboard.statisticGeneratorReportList.statisticReports"),
      },
    ],
    [t]
  );
  const handleOnLoad = useCallback((results) => {
    return results.map((item) => {
      return {
        ...item,
        settings: {
          ...item.settings,
          ...popupSettingsMapper(item.settings),
        },
      };
    });
  }, []);
  return (
    <PRContainer
      actions={actions}
      description={t("dashboard.statisticGeneratorReportList.description")}
      name={t("common.statistics")}
      parentName={parentName}
    >
      <div className="mt-2">
        <PRTable
          ref={tableRef}
          columns={columns}
          url={apiUrlStatisticGenerator.getReports.format(currentProject?.id, id)}
          onLoad={handleOnLoad}
        />
      </div>
    </PRContainer>
  );
}
