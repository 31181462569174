import ConstantHelper from "~helpers/ConstantHelper";

export const [appAnnouncementType, appAnnouncementTypeOptions, appAnnouncementTypeMap] = ConstantHelper.typify({
  Standart: "Standard",
  Info: "Info",
  Warning: "Warning",
  Error: "Error",
});

export const [appAnnouncementAlertType, appAnnouncementAlertTypeOptions, appAnnouncementAlertTypeMap] =
  ConstantHelper.typify({
    Standart: "success",
    Info: "info",
    Warning: "warning",
    Error: "error",
  });
