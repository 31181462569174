import axios from "axios";
import { t } from "i18next";

import Network from "./Network";
import * as url from "./palmate_url_helper";

const post = (url, data) => Network.request(url, { method: "POST", data });

// Login Method
const postJwtLogin = (data) => post(url.POST_LOGIN, data);

// Register Method
const postJwtRegister = (data) => {
  return axios
    .post(url.POST_REGISTER, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch((err) => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = t("utils.postJwtRegister.404");
            break;
          case 500:
            message = t("utils.postJwtRegister.500");
            break;
          case 401:
            message = t("utils.postJwtRegister.401");
            break;
          case 400:
            message = t("utils.postJwtRegister.400");
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Reset Password Method
const postJwtForgetPwd = (data) => post(url.POST_RESET_PASSWORD, data);

export { postJwtLogin, postJwtRegister, postJwtForgetPwd };
