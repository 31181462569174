import { safeProduce as produce } from "~helpers/immer";
import { getSessionWithSortedMessages } from "~store/socket/livechat/reducer";

import * as at from "./actionTypes";

export const initState = {
  selectedSession: null,
  sessionResponse: {
    results: [],
    count: 0,
  },
  sessionGetterSetter: [],
  isGetterSetterDrawerOpen: false,
};
export function sessionHistoryToMessages({ history, ...rest }) {
  return {
    ...rest,
    ...(history && {
      messages: history.map((m) => {
        return {
          type: m.history_type?.toLowerCase(),
          time: m.created,
          payload: m.history_object,
          historyId: m.history_id,
        };
      }),
    }),
  };
}

const chatbot = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SELECT_SESSION:
        draft.selectedSession = action.payload && sessionHistoryToMessages(action.payload);
        break;
      case at.SET_SESSION_RESPONSE:
        const { results = [], ...rest } = action.payload;
        const newSessionList = [];
        if (results.length) {
          for (const s of results) {
            newSessionList.push(getSessionWithSortedMessages(sessionHistoryToMessages(s)));
          }
        }
        draft.sessionResponse = {
          ...rest,
          results: newSessionList,
        };
        break;
      case at.UPDATE_SESSION_HISTORY_LABEL: {
        const { id, label } = action.payload;
        const targetSession = draft.sessionResponse?.results?.find((session) => session?.id === id);
        if (targetSession) {
          targetSession.label = label;
          if (draft.selectedSession?.id === id) {
            draft.selectedSession.label = label;
          }
        }
        break;
      }
      case at.SET_SESSION_GETTER_SETTER: {
        draft.sessionGetterSetter = action.payload;
        break;
      }
      case at.SET_IS_GETTER_SETTER_DRAWER_OPEN: {
        draft.isGetterSetterDrawerOpen = action.payload;
        break;
      }
      default:
        break;
    }
  });

export default chatbot;
