import { useCallback, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { selectCurrentProject } from "~store/user/selectors";

export default function useProjectChange(callback, deps = []) {
  const currentProject = useSelector(selectCurrentProject);
  const [project, setProject] = useState(currentProject);
  const [projectTemp, setProjectTemp] = useState(currentProject);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callbackFunction = useCallback(callback, deps);

  useEffect(() => {
    let callbackFunc;
    if (currentProject !== projectTemp) {
      setProjectTemp(project);
      setProject(currentProject);
      callbackFunc = callbackFunction();
    }

    return () => {
      callbackFunc?.();
    };
  }, [currentProject, projectTemp, project, callbackFunction]);
}
