import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

export const initState = {
  chatLabel: null,
};

const chatLabels = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_CHAT_LABEL:
        draft.chatLabel = action.payload;
        break;
    }
  });

export default chatLabels;
