import { useMemo } from "react";

import styled from "@emotion/styled";
import { Tooltip } from "@mui/material";

const defaultDelayOption = {
  show: 400,
  // hide: 0,
};

const StyledPopper = styled("div", {
  label: "PalTooltip-custom-popper",
})`
  .MuiTooltip-tooltip {
    max-width: 960px;
    width: auto;
  }
  z-index: 1500;
`;

/** @param {import("@mui/material").TooltipProps} props */
export default function PalTooltip({ disabled, children, title, delay, delayHide, light, ...props }) {
  const { delayOptions } = useMemo(() => {
    const delayOptions = { ...defaultDelayOption };
    if (delay) {
      delayOptions.show = delay;
    }
    if (delayHide) {
      delayOptions.hide = delayHide;
    }
    return { delayOptions };
  }, [delay, delayHide]);

  return (
    <>
      <Tooltip
        // fade={false}
        {...props}
        className="pr-tooltip"
        // popperClassName={classNames("pr-tooltip pr-tooltip-popper", {
        //   light,
        // })}
        disableFocusListener={disabled}
        disableHoverListener={disabled}
        disableTouchListener={disabled}
        title={title}
        enterDelay={delayOptions.show}
        // leaveDelay={delayOptions.hide}
        {...(delayOptions.hide != null && { leaveDelay: delayOptions.hide })}
        slots={{ popper: StyledPopper }}
      >
        {children}
      </Tooltip>
    </>
  );
}
