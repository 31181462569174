import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { useLocation } from "react-router-dom";

import styled from "@emotion/styled";
import { Box, Menu, MenuItem, Typography } from "@mui/material";

import { ReactComponent as LanguageIcon } from "~assets/images/icons/language.svg";
import languages from "~common/languages";
import PalButton from "~components/mui/PalButton";
import PalLink from "~components/mui/PalLink";
import DialogHelper from "~helpers/DialogHelper";

const StyledFooter = styled("footer")`
  height: 80px;
  background: #f8f8f8;
  padding: 0px 50px;

  .MuiLink-root {
    white-space: nowrap;
  }
  @media (max-width: 600px) {
    padding: 0px 20px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  .MuiTypography-root {
    font-weight: 300;
    text-align: center;
    font-size: 13px;
    line-height: 20px;
    font-family: "Museo Sans";
    letter-spacing: 0px;
    text-transform: none;
  }

  .MuiButton-root {
    padding: 0;
  }

  svg {
    min-width: 22px;
    min-height: 22px;
    width: 22px;
    height: 22px;
  }
`;
export const TermsOfUse = ({ ...props }) => (
  <p {...props}>
    <p>
      Welcome to our platform Palmate (the "Service"). By accessing or using the Service, you agree to be bound by the
      following terms and conditions (the "Terms of Use"). If you do not agree to these terms, you may not access or use
      the Service.
    </p>
    <ol>
      <li>
        <b>Use of the Service</b>: The Service allows you to communicate with our chatbot and live agents through a live
        chat interface. You may use the Service only for lawful purposes and in accordance with these Terms of Use. You
        agree not to use the Service in any way that violates any applicable federal, state, local, or international law
        or regulation.
      </li>
      <li>
        <b>Prohibited Conduct</b>: You agree not to use the Service to:
      </li>

      <ul>
        <li>
          • Send unsolicited or unauthorized advertising, promotional materials, or any other forms of solicitation.
        </li>
        <li>
          • Transmit any content that is harmful, threatening, abusive, harassing, tortious, defamatory, vulgar,
          obscene, libelous, or otherwise offensive or objectionable.
        </li>
        <li> • Harm minors in any way.</li>
        <li>
          • Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person
          or entity.
        </li>
        <li>
          • Transmit any content that you do not have a right to transmit under any law or under contractual or
          fiduciary relationships.
        </li>
        <li>
          • Transmit any content that infringes any patent, trademark, trade secret, copyright, or other proprietary
          rights of any party.
        </li>
      </ul>

      <li>
        <b> Privacy Policy</b>: Our Privacy Policy governs the use of any personal information you provide to us through
        the Service. By using the Service, you consent to our collection, use, and disclosure of your personal
        information in accordance with our Privacy Policy.
      </li>
      <li>
        <b>Disclaimer of Warranties</b>: The Service is provided on an "as is" and "as available" basis. We make no
        representations or warranties of any kind, express or implied, as to the operation of the Service or the
        information, content, materials, or products included on the Service.
      </li>
      <li>
        <b>Limitation of Liability</b>: In no event shall we be liable for any damages whatsoever, including but not
        limited to any direct, indirect, special, incidental, or consequential damages, arising out of or in connection
        with the use or inability to use the Service.
      </li>
      <li>
        <b>Changes to the Terms of Use</b>: We reserve the right to make changes to these Terms of Use at any time. Your
        continued use of the Service following any changes to these terms will be deemed acceptance of those changes.
      </li>
      <li>
        <b> Governing Law</b>: These Terms of Use and your use of the Service will be governed by and construed in
        accordance with the laws of the country where the company is headquartered, without giving effect to any
        principles of conflicts of law.
      </li>
      <li>
        <b> Contact Us</b>: If you have any questions about these Terms of Use, please contact us at
        <a href="mailto:info@palmate.ai"> info@palmate.ai</a>
      </li>
    </ol>
    By using our Service you agree to these terms and conditions.
  </p>
);

export const PrivacyPolicy = ({ ...props }) => (
  <p {...props}>
    <p>
      Our Privacy Policy explains how we collect, use, and protect the personal information of users of Palmate (the
      "Service"). By using the Service, you consent to the collection, use, and disclosure of your personal information
      in accordance with this Privacy Policy.
    </p>{" "}
    <ol>
      <li>
        <b>Information We Collect</b>: We may collect personal information from you when you use the Service, including
        but not limited to your name, email address, and any other information you choose to provide. We may also
        collect information about your device and how you interact with the Service, such as your IP address and browser
        type.
      </li>
      <li>
        <b>Use of Your Information</b>: We may use your personal information to provide you with the Service, to respond
        to your inquiries, to improve the Service, and for other business purposes. We may also use your information to
        contact you about our products and services, or to send you promotional materials.
      </li>
      <li>
        <b>Sharing Your Information</b>: We may share your personal information with our service providers, agents, or
        partners for the purpose of providing the Service or for other business purposes. We may also share your
        information if required by law or in the good faith belief that such action is necessary to comply with legal
        requirements or to protect the rights, property, or safety of our company, employees, customers, or the public.
      </li>
      <li>
        <b>Data Retention</b>: We will retain your personal information for as long as needed to provide you with the
        Service or as required by law.
      </li>
      <li>
        <b>Data Security</b>: We take reasonable steps to protect your personal information from unauthorized access,
        use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure
        and we cannot guarantee the absolute security of your personal information.
      </li>
      <li>
        <b>Children's Privacy</b>: The Service is not intended for children under the age of 13 and we do not knowingly
        collect personal information from children under the age of 13.
      </li>
      <li>
        <b>Changes to the Privacy Policy</b>: We reserve the right to make changes to this Privacy Policy at any time.
        Your continued use of the Service following any changes to this policy will be deemed acceptance of those
        changes.
      </li>
      <li>
        <b>Contact Us</b>: If you have any questions about this Privacy Policy, please contact us at info@palmate.ai.
      </li>
    </ol>
    By using our Service you agree to our Privacy Policy. Thank you for using our Service!
  </p>
);
export default function Footer() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { i18n, t } = useTranslation();
  const location = useLocation();

  const changeLanguageAction = (lang) => () => {
    i18n.changeLanguage(lang);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenOk = (title, body) => () => {
    DialogHelper.showOk(
      title,
      <div className="text-start">{body}</div>,

      null,
      {
        dialogOptions: {
          maxWidth: "md",
        },
      }
    );
  };

  useEffect(() => {
    const hash = location.hash;

    if (hash === "#term-of-use") {
      handleOpenOk("Terms of Use", <TermsOfUse />)();
    } else if (hash === "#privacy-policy") {
      handleOpenOk("Privacy Policy", <PrivacyPolicy />)();
    }
  }, [location]);

  return (
    <StyledFooter>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: { xs: "10px", md: "30px" },
        }}
      >
        <div>
          <PalButton
            aria-controls="menu-language"
            aria-haspopup="true"
            aria-label="language"
            color="inherit"
            size="large"
            sx={{
              ml: "-5px",
              mr: "-15px",
            }}
            onClick={handleMenu}
          >
            <Box
              sx={(theme) => ({
                display: "flex",
                alignItems: "center",
                mx: "10px",
                color: theme.palette.secondary.main,
              })}
            >
              <LanguageIcon />
              <Typography
                sx={{
                  margin: "0 5px 0 10px",
                }}
              >
                {languages[i18n.language]?.label}
              </Typography>
              {anchorEl ? <MdExpandLess /> : <MdExpandMore />}
            </Box>
          </PalButton>
          <Menu
            keepMounted
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            id="menu-appbar"
            open={Boolean(anchorEl)}
            style={{ top: "-50px" }}
            sx={(theme) => ({
              "& .MuiList-root": {
                padding: "10px",
                gap: "1px",
                display: "flex",
                flexDirection: "column",
              },
              "& .MuiMenuItem-root": {
                textAlign: "left",
                font: "normal normal normal 13px/31px Museo Sans",
                letterSpacing: 0,
                color: theme.palette.secondary.main,
                padding: "7px 10px",
                minWidth: "100px",
                height: 29,
                "&:hover": {
                  background: "#F8F8F8",
                  borderRadius: "4px",
                },
                "&.Mui-selected": {
                  background: "#F8F8F8",
                  borderRadius: "4px",
                  color: theme.palette.secondary.dark,
                },
              },
            })}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={handleClose}
          >
            {Object.keys(languages).map((key) => (
              <MenuItem key={key} selected={i18n.language === key} onClick={changeLanguageAction(key)}>
                {languages[key].label}
              </MenuItem>
            ))}
            {/* 
            <MenuItem onClick={handleClose}>Türkçe</MenuItem>
            <MenuItem onClick={handleClose} selected>
              English
            </MenuItem>
            <MenuItem onClick={handleClose}>Deutsch</MenuItem> */}
          </Menu>
        </div>

        <PalLink noPrefix color="secondary" to="#term-of-use">
          {t("component.footer.termsOfUse.title")}
        </PalLink>
        <PalLink noPrefix color="secondary" to="#privacy-policy">
          {t("component.footer.privacyPolicy.title")}
        </PalLink>
      </Box>
      <Typography color="secondary">
        © {new Date().getFullYear()} Palmate.{" "}
        <span className="d-none d-md-inline">{t("component.footer.allRightsReserved")}</span>
      </Typography>
    </StyledFooter>
  );
}
