export const SET_FORM_STATE = "@ORGANIZATION/SET_FORM_STATE";
export const CREATE_OR_UPDATE = "@ORGANIZATION/CREATE_OR_UPDATE";
export const RESET_FORM = "@ORGANIZATION/RESET_FORM";

export const SET_FORM_SAVING = "@ORGANIZATION/SET_FORM_SAVING";
export const SET_FORM_LOADING = "@ORGANIZATION/SET_FORM_LOADING";

export const FETCH_SCENARIO_FORM = "@ORGANIZATION/FETCH_SCENARIO_FORM";

export const SET_MEMBER_FIELD_FORMAT = "@ORGANIZATION/SET_MEMBER_FIELD_FORMAT";
export const SET_FILTERS = "@ORGANIZATION/SET_FILTERS";
export const SET_FILTER = "@ORGANIZATION/SET_FILTER";

export const SET_ACTION = "@ORGANIZATION/SET_ACTION";
export const SET_ACTION_LIST = "@ORGANIZATION/SET_ACTION_LIST";

export const SET_RESOURCES = "@ORGANIZATION/SET_RESOURCES";
export const SET_DYNAMIC_RESERVATIONS = "@ORGANIZATION/SET_DYNAMIC_RESERVATIONS";
export const SET_RESOURCE = "@ORGANIZATION/SET_RESOURCE";
export const SET_MEMBERS = "@ORGANIZATION/SET_MEMBERS";
export const SET_MEMBER = "@ORGANIZATION/SET_MEMBER";
export const SET_SURVEY = "@ORGANIZATION/SET_SURVEY";
export const SET_SURVEY_RESULT = "@ORGANIZATION/SET_SURVEY_RESULT";
export const SET_SURVEY_LIST = "@ORGANIZATION/SET_SURVEY_LIST";
export const SET_SLOT_LIST = "@ORGANIZATION/SET_SLOT_LIST";
export const SET_SLOT = "@ORGANIZATION/SET_SLOT";
export const SET_RESERVATION_LIST = "@ORGANIZATION/SET_RESERVATION_LIST";

export const SET_ANNOUNCEMENT = "@ORGANIZATION/SET_ANNOUNCEMENT";
export const SET_ANNOUNCEMENT_VERSION = "@ORGANIZATION/SET_ANNOUNCEMENT_VERSION";

export const SET_SECRET_KEY = "@ORGANIZATION/SET_SECRET_KEY";
export const SET_SECRET_KEY_LIST = "@ORGANIZATION/SET_SECRET_KEY_LIST";
