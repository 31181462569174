import { forwardRef, Fragment, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";

import classNames from "classnames";
import _ from "lodash";
import { MdAdd, MdSearch } from "react-icons/md";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormFeedback, Input, Spinner } from "reactstrap";

import { Tooltip } from "@mui/material";

import { useDebouncedEffect } from "~common/hooks/useDebounceEffect";
import PRButton from "~components/Generic/PRButton";
import "./style.scss";

function PRDropdown(
  {
    label,
    labelSelector,
    labelRender,
    valueSelector = "",
    icon: IconComp,
    search = false,
    searchIcon = false,
    searchDebounce = 500,
    options,
    onFetch,
    onChange,
    buttonProps = {},
    secondaryButtonProps = {},
    invalid = "",
    className,
    fullWidth,
    tag,
    color = "primary",
    ...rest
  },
  ref
) {
  const { position, ...secondaryButtonPropsRest } = secondaryButtonProps;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [initDataList, setInitDataList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [fetchToggle, setFetchToggle] = useState(false);
  const [filteredDataList, setFilteredDataList] = useState([]);
  const [staticOptions, setStaticOptions] = useState(null);
  const emptyArrayRef = useRef([]);

  const lazySearch = search == "lazy";
  const dropdownOptions = staticOptions || dataList || emptyArrayRef.current;

  const getFilteredList = useCallback(() => {
    if (Array.isArray(dropdownOptions) && dropdownOptions?.length) {
      return dropdownOptions.filter((item) => {
        const label = (labelSelector ? item?.[labelSelector] : item?.toString()) ?? "";
        return !searchText || label.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
      });
    }
    return [];
  }, [dropdownOptions, labelSelector, searchText]);

  useImperativeHandle(ref, () => ({
    refresh: () => {
      setFetchToggle((prev) => !prev);
    },
  }));
  useEffect(() => {
    if (options && !_.isEqual(options, staticOptions)) {
      setStaticOptions(options);
    }
  }, [staticOptions, options]);

  useDebouncedEffect(
    () => {
      (async () => {
        if (lazySearch && searchText?.length > 2) {
          if (onFetch) {
            setLoading(true);
            try {
              const callbackData = await onFetch?.(searchText);
              setDataList(Array.isArray(callbackData) ? callbackData : []);
            } catch (error) {
              console.log(error);
            } finally {
              setLoading(false);
            }
          }
        } else if (!lazySearch) {
          setFilteredDataList(getFilteredList());
        }
      })();
    },
    [searchText, initDataList, getFilteredList],
    lazySearch ? searchDebounce : 0
  );
  useEffect(() => {
    if (lazySearch) {
      if (!searchText) {
        setDataList(initDataList);
      } else if (searchText?.length < 3) {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazySearch, searchText]);

  useEffect(() => {
    setFilteredDataList(getFilteredList());
  }, [dropdownOptions, labelSelector, searchText, getFilteredList]);

  const toggleDropdown = () => setIsDropdownOpen((prevState) => !prevState);
  const handleChangeSearchText = (e) => {
    const value = e.target.value;
    setSearchText(value);
    if (lazySearch && value?.length > 2) {
      setLoading(true);
    }
  };

  const handleClickSelectIntent = (intent) => () => {
    // setIsDropdownOpen(false);
    setSearchText("");
    setDataList([]);
    if (!valueSelector) {
      onChange(intent, intent);
    } else {
      onChange(intent?.[valueSelector], intent);
    }
  };

  //auto focus on search input
  const searchInputRef = useRef(null);
  useEffect(() => {
    if (isDropdownOpen) {
      if (search && searchInputRef.current) {
        searchInputRef.current.focus();
      }
      setDataList(initDataList);
    } else {
      setSearchText("");
    }
  }, [search, initDataList, isDropdownOpen]);

  useEffect(() => {
    if (staticOptions) return;

    (async () => {
      if (onFetch) {
        setLoading(true);
        try {
          const callbackData = await onFetch?.("");
          setInitDataList(Array.isArray(callbackData) ? callbackData : []);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staticOptions, fetchToggle]);

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      toggle={toggleDropdown}
      {...rest}
      className={classNames("pr-dropdown", className)}
    >
      {Object.keys(secondaryButtonProps || {})?.length ? (
        <div className="input-group">
          {position !== "right" && <PRButton color={color} icon={MdAdd} {...secondaryButtonPropsRest} />}
          <DropdownToggle color={color} {...buttonProps} className={classNames(buttonProps?.className)} tag={tag}>
            {(!loading || isDropdownOpen) && IconComp && <IconComp className={`  me-2`} />}
            {(!loading || isDropdownOpen) && searchIcon && !IconComp && <MdSearch className={`me-2`} />}
            {loading && !isDropdownOpen && <Spinner className="me-2" color="light" size="sm" />}
            <span className="text-capitalize pr-button-text"> {label}</span>
          </DropdownToggle>

          {position === "right" && <PRButton color={color} icon={MdAdd} {...secondaryButtonPropsRest} />}
        </div>
      ) : (
        <DropdownToggle
          color={color}
          {...buttonProps}
          className={classNames(
            "d-flex align-items-center justify-content-center",
            {
              "w-100": fullWidth,
            },
            buttonProps.className
          )}
          tag={tag}
        >
          {!loading && IconComp && <IconComp className={`me-2`} />}
          {!loading && searchIcon && !IconComp && <MdSearch className={`me-2`} />}
          {loading && !isDropdownOpen && <Spinner className="me-2" color="light" size="sm" />}
          <span className="text-capitalize pr-button-text"> {label}</span>
        </DropdownToggle>
      )}

      {invalid && (
        <>
          <Input invalid={true} type="hidden" value={valueSelector} />
          <FormFeedback valid={false}>{`${invalid}`}</FormFeedback>
        </>
      )}
      <DropdownMenu className="dropdown-menu">
        {search && (
          <>
            <div className="search">
              <Input
                className="pe-2"
                defaultValue={searchText}
                innerRef={searchInputRef}
                placeholder={"Search..."}
                type="text"
                onChange={handleChangeSearchText}
              />
              {loading && (
                <div className="loading">
                  <Spinner className="ms-2" color="primary" size="sm" />
                </div>
              )}
            </div>
          </>
        )}
        <div className="p-2 options">
          {filteredDataList.map((option, index, arr) => {
            return (
              <Fragment key={index}>
                <DropdownItem onClick={handleClickSelectIntent(option)}>
                  <Tooltip enterDelay={500} enterNextDelay={250} placement="left" title={option[labelSelector]}>
                    <span>
                      {typeof labelRender === "function"
                        ? labelRender(option[labelSelector], option)
                        : option[labelSelector]}
                    </span>
                  </Tooltip>
                </DropdownItem>
                {index !== arr.length - 1 && <DropdownItem divider />}
              </Fragment>
            );
          })}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
}
export default forwardRef(PRDropdown);
