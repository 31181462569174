import React, { useMemo } from "react";

import chroma from "chroma-js";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  MdCheckCircle,
  MdDoNotDisturb,
  MdMarkChatRead,
  MdOutlineChatBubble,
  MdOutlineFeedback,
  MdSync,
} from "react-icons/md";
import { useSelector } from "react-redux";
import { Badge } from "reactstrap";

import PRLink from "~components/Generic/PRLink";
import PRTooltip from "~components/Generic/PRTooltip";
import { chatbotMainSessionStatusList, chatbotSessionStatus } from "~constants";
import {
  selectAgentInfo,
  selectOnlineStatus,
  selectSelectedSession,
  selectSessions,
  selectViewStatus,
} from "~store/socket/livechat/selectors";
import "./style.scss";

export default function LiveChatHeader({ className }) {
  const onlineStatus = useSelector(selectOnlineStatus);
  const sessions = useSelector(selectSessions);
  const agentInfo = useSelector(selectAgentInfo);
  const selectedSession = useSelector(selectSelectedSession);
  const livechatViewStatus = useSelector(selectViewStatus);
  const { t } = useTranslation();

  const mainSessions = useMemo(() => {
    return sessions.filter((session) => chatbotMainSessionStatusList.includes(session.session_status));
  }, [sessions]);

  const agentWaitingSessions = mainSessions.filter(
    (session) => session.session_status === chatbotSessionStatus.AGENT_WAIT
  );
  let IconComp = MdOutlineChatBubble;
  let color = "text-secondary";
  let statusText = t("layout.liveChat.disconnected");
  //warning color
  let statusTextColorHex = "#ffc107";
  const isOnline = ["connected", "reconnected"].includes(onlineStatus);
  const isAgentAvailable = agentInfo?.status === "AV";
  const sessionCount = mainSessions?.length || 0;
  if (sessionCount && selectedSession?.session_status === chatbotSessionStatus.AGENT_CHAT) {
    IconComp = MdOutlineChatBubble;
    color = "text-danger";
    if (livechatViewStatus === "hidden") {
      statusText = t("layout.liveChat.chattingWithLink");
      statusTextColorHex = "#dc3545";
    } else {
      statusText = t("layout.liveChat.chatting");
      statusTextColorHex = "#007bff";
      color = "text-primary";
    }
  } else if (agentWaitingSessions?.length) {
    IconComp = MdOutlineFeedback;
    color = "text-danger";
    statusText = t("layout.liveChat.waitingForAgent");
    statusTextColorHex = "#dc3545";
  } else if (sessionCount && isOnline) {
    IconComp = MdMarkChatRead;
    color = "text-primary";
    statusText = t("layout.liveChat.activeSessionsExist");
    statusTextColorHex = "#007bff";
  } else if (isOnline) {
    IconComp = MdMarkChatRead;
    color = "text-primary";
    statusText = "";
    statusTextColorHex = "#007bff";
  }

  const lightenColor = chroma(statusTextColorHex).brighten(2).hex();

  return (
    <div className={classNames("pr-livechat-header", "")}>
      <PRTooltip
        placement="bottom"
        title={
          <div className="text-center">
            <div>
              <span className="font-weight-bold">
                <div className="fw-light fs-5">{t("layout.liveChat.name")}</div>
                <div className="d-flex align-items-center">
                  <span className="fw-light me-1">{t("layout.liveChat.agentStatus")}</span>
                  <span className="me-1">
                    {isAgentAvailable ? t("layout.liveChat.available") : t("layout.liveChat.unavailable")}
                  </span>
                  {isAgentAvailable ? (
                    <MdCheckCircle className="text-success fs-5" />
                  ) : (
                    <MdDoNotDisturb className="text-danger fs-5" />
                  )}
                </div>
              </span>
            </div>
            <div>
              <span className="fw-light">{t("layout.liveChat.activeSessions")}</span> <span>{sessionCount}</span>
            </div>
            <div>
              <span
                className={"fw-bold fs-6"}
                style={{
                  color: lightenColor,
                }}
              >
                {statusText}
              </span>
            </div>
          </div>
        }
      >
        <PRLink
          className={classNames("position-relative d-flex align-items-center", className, {
            bounce: agentWaitingSessions?.length,
            "opacity-50": agentInfo?.status !== "AV",
          })}
          to="/contactcenter/livechat"
        >
          <div className="d-flex align-items-center position-relative justify-content-center " style={{ height: 36 }}>
            <IconComp className={classNames("fs-2", color)} />
            {!isOnline && (
              <div className="offline-icon">
                <MdSync className="fs-4 spin " />
              </div>
            )}
          </div>

          {!!sessionCount && isOnline && (
            <Badge pill className="position-absolute top-0 start-100 translate-middle" color="danger">
              {sessionCount}
            </Badge>
          )}
        </PRLink>
      </PRTooltip>
    </div>
  );
}
