import React from "react";

import classNames from "classnames";
import { Spinner } from "reactstrap";

import "./style.scss";
import { Fade } from "@mui/material";

export default function PRAccordion({
  children,
  separated,
  noSpacing,
  noSpacingHeader,
  flush,
  underline,
  loading,
  depth = 0,
  className,
  noHeaderBackground,
}) {
  return (
    <div
      className={classNames(
        "accordion",
        "pr-accordion",
        `depth-${depth}`,
        {
          "accordion-flush": flush,
          "no-spacing": noSpacing,
          "separated-items": separated,
          "accordion-loading": loading,
          "no-spacing-header": noSpacingHeader,
          "header-underline": underline,
          "disable-button-color": noHeaderBackground,
        },
        className
      )}
    >
      {children}
      {loading !== undefined && (
        <Fade mountOnEnter unmountOnExit className={classNames("pr-spinner")} in={loading}>
          <span>
            <Spinner className="ms-2" color="primary" />
          </span>
        </Fade>
      )}
    </div>
  );
}
