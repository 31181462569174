import { useEffect } from "react";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import useProjectChange from "~common/hooks/useProjectChange";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRInput from "~components/Generic/PRInput";
import PRPage from "~components/Generic/PRPage";
import HistoryHelper from "~helpers/HistoryHelper";
import { createOrUpdateIntentTag, getIntentTag, setIntentTag } from "~store/dialogComponents/intentTags/actions";
import { selectIntentTag } from "~store/dialogComponents/intentTags/selectors";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

const goBackUrl = "/chatbot/intent-tags/";
export default function IntentTagsEdit() {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const intentTag = useSelector(selectIntentTag);

  const handleGoBack = () => {
    HistoryHelper.goBack(goBackUrl, { scope: "dashboard" });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: intentTag.id,
      key: intentTag.key,
    },
    validationSchema: Yup.object({
      key: Yup.string()
        .required(t("component.formik.required").format(t("common.name")))
        .min(3, t("component.formik.minLength").format(t("common.name"), "3")),
    }),
    onSubmit: async (values) => {
      await dispatch(createOrUpdateIntentTag(currentProject.id, currentBot.id, values));
      handleGoBack();
    },
  });

  useEffect(() => {
    if (!id) return;
    dispatch(getIntentTag(currentProject.id, currentBot.id, id));
    return () => {
      dispatch(setIntentTag({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useProjectChange(() => {
    HistoryHelper.push(goBackUrl, { scope: "dashboard" });
  }, []);

  const parentName = [
    {
      label: t("common.intentTags"),
      url: goBackUrl,
    },
    {
      label: !id ? t("component.intentTagsEdit.createIntentTag") : t("component.intentTagsEdit.editIntentTag"),
    },
  ];
  return (
    <PRContainer
      smalltalkSelector
      description={t("component.intentTagsEdit.description")}
      name={t("common.chatbot")}
      parentName={parentName}
    >
      <PRPage title={!id ? t("component.intentTagsEdit.createIntentTag") : t("component.intentTagsEdit.editIntentTag")}>
        <Row className="mt-2 g-2">
          <Col md="4">
            <Label size="md">{t("common.name")}</Label>
          </Col>
          <Col md="8">
            <PRInput
              invalid={formik.touched.key && formik.errors.key}
              name="key"
              type="text"
              value={formik.values.key}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
        </Row>
        <Row className="justify-content-end mt-2">
          <Col md="auto">
            <PRButton outline link={goBackUrl}>
              {t("common.cancel")}
            </PRButton>
            <PRButton className="ms-2" onClick={formik.handleSubmit}>
              {t("common.save")}
            </PRButton>
          </Col>
        </Row>
      </PRPage>
    </PRContainer>
  );
}
