import { useCallback, useEffect, useState } from "react";

export default function useVisibility() {
  const [isVisible, setIsVisible] = useState(true);
  const handleVisibilityChange = useCallback(() => {
    if (typeof document === "undefined") {
      return;
    }
    if (document.hidden) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange, false);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleVisibilityChange]);
  return isVisible;
}
