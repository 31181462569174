import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import classNames from "classnames";
import moment from "moment-timezone";
import DateTime from "react-datetime";
import { FormFeedback, Input } from "reactstrap";

import DateHelper from "~helpers/DateHelper";

import PRPopover from "../PRPopover";

import "react-datetime/css/react-datetime.css";
import "./style.scss";

const SmartInput = memo(function SmartInput({ value, onChange, ...props }) {
  const [currentValue, setCurrentValue] = useState(value);
  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleChange = useCallback(
    (e) => {
      setCurrentValue(e.target.value);
      onChange?.(e);
    },
    [onChange]
  );
  return <input value={currentValue} onChange={handleChange} {...props} />;
});
/**
 * @param {DateTime.DatetimepickerProps} param0
 * @returns {JSX.Element} PRDate
 */
function PRDate({
  timeFormat,
  isClearable = true,
  dateFormat,
  utc,
  value: valueProp,
  invalid,
  placeholder,
  onChange,
  popover = false,
  onOpen,
  onClose,
  isValidDate,
  disabled,
  inlineExpander,
  size = "md",
  className,
  onKeyDown,
  ...props
}) {
  const [dateContainerRef, setDateContainerRef] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentNavigate, setCurrentNavigate] = useState(null);
  const [isFocus, setIsFocus] = useState(false);
  const [lastValidDate, setLastValidDate] = useState(null);
  const popoverRef = useRef(null);
  const handleFocus = useCallback(
    (isFocus) => () => {
      setIsFocus(isFocus);
    },
    []
  );
  const isFocusOrOpen = isFocus || open;

  const value = useMemo(() => {
    if (typeof valueProp === "string" && DateHelper.IsServerFormat(valueProp)) {
      return utc ? DateHelper.getDateTime(valueProp) : DateHelper.getDateTimeLocal(valueProp);
    }
    return valueProp;
  }, [valueProp, utc]);

  const dateFormatStr = useMemo(() => {
    let formatStr;
    if (dateFormat === false) {
      formatStr = timeFormat ?? DateHelper.getTimeFormat();
    } else if (timeFormat === false) {
      formatStr = dateFormat ?? DateHelper.getDateFormat();
    } else {
      formatStr = (dateFormat ?? DateHelper.getDateFormat()) + " " + (timeFormat ?? DateHelper.getTimeFormat());
    }
    return formatStr;
  }, [dateFormat, timeFormat]);

  const inputProps = useMemo(() => {
    return {
      placeholder: placeholder,
      onKeyDown,
      onFocus: handleFocus(true),
      onBlur: handleFocus(false),
      ...(isFocusOrOpen && {}),
    };
  }, [placeholder, onKeyDown, handleFocus, isFocusOrOpen]);

  const renderInput = useCallback(
    (renderProps, openCalendar, closeCalendar) => {
      const handleClear = () => {
        renderProps.onChange({ target: { value: "" } });
        closeCalendar();
      };
      const { value: valueRenderText, ...inputProps } = renderProps;
      const value = valueProp ? valueRenderText : "";
      return (
        <div className="d-flex align-items-center justify-content-between position-relative">
          <SmartInput
            //
            {...inputProps}
            value={value}
          />
          {!!renderProps.value && isClearable && (
            <div className="btn-close position-absolute end-0 me-2 font-size-10" onClick={handleClear} />
          )}
        </div>
      );
    },
    [isClearable, !!valueProp]
  );

  const handleChange = useCallback(
    (value) => {
      //TODO: Locale info comes wrong from DateTime component's event even configured in DateHelper. That causes due updateLocale wont apply in DateTime component's moment object and date format shows wrong.

      try {
        if (value?._locale) {
          const date = utc ? DateHelper.getDateTime() : DateHelper.getDateTimeLocal();
          value._locale = date._locale;
        }
        onChange?.(value);
        if (popover) {
          setOpen(false);
          onClose?.();
          popoverRef.current?.close();
        }
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, utc]
  );

  useEffect(() => {
    if (!isFocusOrOpen) {
      let formattedText;

      let appliedDate;
      if (moment.isMoment(value)) {
        formattedText = value.format(dateFormatStr);
        appliedDate = value;
      } else {
        formattedText = value;
      }
      if (value !== "" && (formattedText?.length !== dateFormatStr?.length || typeof value === "string")) {
        const newDate = utc
          ? DateHelper.getDateTime(formattedText, dateFormatStr, true)
          : DateHelper.getDateTimeLocal(formattedText, dateFormatStr, true);
        appliedDate = newDate;
        onChange?.(newDate);
      }

      if (appliedDate?.isValid()) {
        setLastValidDate(appliedDate);
      } else if (value !== "" && lastValidDate && !appliedDate?.isValid()) {
        onChange?.(lastValidDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value?.toString(), isFocusOrOpen, utc, dateFormatStr]);

  const handleRef = useCallback((ref) => {
    setDateContainerRef(ref);
  }, []);

  useEffect(() => {
    //Change time by mouse wheel
    if (dateContainerRef && (currentNavigate === "time" || dateFormat === false) && open) {
      const dispatchList = [];
      // find rdtCounters classes dom
      const rdtCounters = document.getElementsByClassName("rdtCounters");
      let mouseUpDownCallback = (upDown) => (e) => {
        const isScrollUp = e.deltaY < 0;
        const isShiftPressed = e.shiftKey;
        upDown(isScrollUp, isShiftPressed);
      };

      //check this dom is include in dateRef
      // add mouseup and mousedown event to rdtCounters classes dom
      for (let i = 0; i < rdtCounters.length; i++) {
        if (!dateContainerRef?.contains(rdtCounters[i])) continue;
        const timeSectionDoms = [...rdtCounters[i].children].filter((dom) => dom.classList.contains("rdtCounter"));
        //add mouseup and mousedown event to rdtCounters classes dom if has "rdtCounter"

        for (let i = 0; i < timeSectionDoms.length; i++) {
          const timeDom = timeSectionDoms[i];
          const isHour = i === 0;
          const isMinute = i === 1;

          const handleUpDown = (isUp, isShiftPressed) => {
            let newDate = value;
            const incrementCount = isShiftPressed ? 10 : 1;
            const direction = isUp ? incrementCount : -incrementCount;
            if (isHour) {
              newDate = newDate.clone().add(direction, "hour");
            } else if (isMinute) {
              newDate = newDate.clone().add(direction, "minute");
            } else {
              return;
            }
            //check is same day or hour
            if (!newDate.isSame(value, isHour ? "day" : "hour")) return;
            handleChange(newDate);
          };
          const eventFunc = mouseUpDownCallback(handleUpDown);
          //check timeDom is visible
          if (timeDom.offsetParent === null) continue;

          timeDom.addEventListener("wheel", eventFunc);
          dispatchList.push(() => {
            timeDom.removeEventListener("wheel", eventFunc);
          });
        }
      }
      return () => {
        dispatchList.forEach((dispatch) => dispatch());
      };
    }
  }, [dateContainerRef, currentNavigate, value, handleChange, open, dateFormat]);

  const handleOpenClose = useCallback(
    (state, expander) => () => {
      setOpen(state);
      if (state) {
        onOpen?.();
        popover && popoverRef.current?.open();
      } else {
        onClose?.();
        popover && popoverRef.current?.close();
      }
    },
    [popover, onOpen, onClose]
  );
  const prDate = (
    <div
      ref={handleRef}
      className={classNames("pr-date", {
        invalid: invalid,
        "inline-expander": inlineExpander,
        small: size === "sm",
        "inline-expander-sm": size === "sm" && inlineExpander,
      })}
      disabled={disabled}
    >
      <DateTime
        dateFormat={dateFormat ?? DateHelper.getDateFormat()}
        timeFormat={timeFormat ?? DateHelper.getTimeFormat()}
        utc={utc}
        value={value}
        {...props}
        className={classNames(
          {
            "hide-expander-sm": size === "sm" && inlineExpander,
          },
          className
        )}
        closeOnSelect={true}
        inputProps={inputProps}
        isValidDate={isValidDate}
        locale={DateHelper.getLocale()}
        renderInput={renderInput}
        onChange={handleChange}
        onClose={handleOpenClose(false, false)}
        onNavigate={setCurrentNavigate}
        onOpen={handleOpenClose(true, false)}
      />
      <Input className="d-none" invalid={!!invalid} />
      {invalid && <FormFeedback type="invalid">{invalid}</FormFeedback>}
    </div>
  );

  if (popover) {
    const popoverContent = () => {
      return (
        <div
          className={classNames("rdtPicker hide-input d-block", {
            "inline-expander": inlineExpander,
            "inline-expander-sm": size === "sm" && inlineExpander,
          })}
          disabled={disabled}
        >
          <DateTime
            dateFormat={dateFormat ?? DateHelper.getDateFormat()}
            timeFormat={timeFormat ?? DateHelper.getTimeFormat()}
            utc={utc}
            value={value}
            {...props}
            closeOnSelect={true}
            inputProps={inputProps}
            isValidDate={isValidDate}
            locale={DateHelper.getLocale()}
            renderInput={renderInput}
            onChange={handleChange}
            onClose={handleOpenClose(false)}
            onNavigate={setCurrentNavigate}
            onOpen={handleOpenClose(true)}
          />
          {/* {dateRef._renderCalendar && dateRef._renderCalendar.call(dateRef)} */}
        </div>
      );
    };
    return (
      <PRPopover ref={popoverRef} content={popoverContent} portal="root">
        <div className="hide-expander">{prDate}</div>
      </PRPopover>
    );
  }
  return prDate;
}

export default memo(PRDate);
