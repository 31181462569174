import React, { forwardRef, useState } from "react";

import classNames from "classnames";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import styled from "@emotion/styled";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";

import PalIconButton from "~components/mui/PalIconButton";

import PRButton from "../PRButton";

import "./style.scss";

function PRTitle({ title = "" }) {
  return <h4 className="card-title fs-5">{title}</h4>;
}

const StyledCard = styled(Card)`
  &.card {
    background-color: var(--bs-body-bg);
    border-radius: 14px;
  }
`;
export const PRCard = forwardRef(function PRCard({ children, style, ...rest }, ref) {
  return (
    <StyledCard ref={ref} style={style} {...rest}>
      <CardBody>{children}</CardBody>
    </StyledCard>
  );
});

const PRPage = ({
  collapse,
  collapseOptions,
  defaultOpen = true,
  title,
  titleClassName,
  actions = [],
  children,
  headerColor,
  noCard,
  className = "",
  style,
  styleCard,
  onClick,
}) => {
  const Wrapper = noCard ? React.Fragment : PRCard;
  const [open, setOpen] = useState(defaultOpen);

  const handleChangeToggle = () => {
    setOpen(!open);
  };
  return (
    <div className={`pr-page position-relative ${className}`} style={style} onClick={onClick}>
      {headerColor && (
        <div
          className="position-absolute top-0 start-0 end-0"
          style={{
            borderRadius: "2px",
            zIndex: 1,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              borderTop: `2px solid var(--bs-${headerColor})`,
              opacity: 0.67,
            }}
          />
        </div>
      )}

      <Wrapper {...(!noCard && { style: styleCard })}>
        <Container fluid className="container ms-0">
          {!!(actions?.length || title) && (
            <Row
              className="align-items-center justify-content-center page-footer"
              style={{
                cursor: collapse ? "pointer" : "default",
              }}
              onClick={collapse && handleChangeToggle}
            >
              <Col xs className={classNames("ps-0", titleClassName)}>
                <PRTitle title={title} />
              </Col>

              {actions.map((action, index) => {
                const {
                  component: Component,
                  render,
                  label,
                  color = "primary",
                  loading,
                  className: actionClassName,
                  icon,
                  ...rest
                } = action;
                return (
                  <Col
                    key={index}
                    className={classNames("gx-2", {
                      "pe-0": index === actions.length - 1,
                    })}
                    xs="auto"
                  >
                    {Component ? (
                      typeof Component === "function" ? (
                        <Component key={index} action={action} index={index} />
                      ) : (
                        Component
                      )
                    ) : (
                      <PRButton
                        icon={icon}
                        loading={loading}
                        {...rest}
                        className={classNames("fw-medium", actionClassName)}
                        color={color}
                      >
                        {typeof render === "function" ? render() : label}
                      </PRButton>
                    )}
                  </Col>
                );
              })}
              {collapse && (
                <Col className="pe-0" xs="auto">
                  <PalIconButton size="small">{!open ? <ExpandMore /> : <ExpandLess />}</PalIconButton>
                </Col>
              )}
            </Row>
          )}
          <Row>
            <Col className={`px-0`} xs="12">
              {collapse ? (
                <Collapse in={open} {...collapseOptions} timeout="auto">
                  {children}
                </Collapse>
              ) : (
                children
              )}
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </div>
  );
};

export default PRPage;
