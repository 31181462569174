import { withCardon } from "cardon";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import PRInput from "~components/Generic/PRInput";
import PRModal from "~components/Generic/PRModal";
import Utils from "~helpers/Utils";

const RenameFileModal = withCardon(
  function RenameFileModal({ get, title, name }) {
    const { t } = useTranslation();
    const formik = useFormik({
      initialValues: {
        name: name,
      },
      validationSchema: Yup.object({
        name: Yup.string()
          .required(t("component.formik.required").format(t("common.name")))
          .test("test-variable", t("component.formik.notValid").format(t("common.name")), function (value) {
            const { valid, reason } = Utils.isValidPythonVariableName(value);
            if (valid) return true;
            return this.createError({ message: reason });
          }),
      }),
      onSubmit: async (values) => {
        get(values.name)();
      },
    });

    return (
      <PRModal title={title || t("common.uploadFile")} onClick={formik.handleSubmit} onClose={get(false)}>
        <Row className="g-2">
          <Col xs={12}>
            <Label>{t("common.name")}</Label>
            <PRInput
              invalid={formik.touched.name && formik.errors.name}
              name="name"
              placeholder={t("common.namePlaceholder")}
              value={formik.values.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
        </Row>
      </PRModal>
    );
  },
  { destroyOnHide: true }
);
export default RenameFileModal;
