import { createSelector } from "reselect";

/** @type {() => typeof import("./reducer").initState} */
export const selectChatbot = (state) => state.chathistory;

export const selectSelectedSession = (state) => selectChatbot(state).selectedSession;
export const selectSessionId = (state) => selectSelectedSession(state)?.id;
export const selectSessionResponse = (state) => selectChatbot(state).sessionResponse;
export const selectSessionGetterSetter = (state) => selectChatbot(state).sessionGetterSetter;
export const selectIsGetterSetter = createSelector(
  selectSessionGetterSetter,
  (sessionGetterSetter) => sessionGetterSetter && sessionGetterSetter.length > 0
);
export const selectIsGetterSetterDrawerOpen = (state) => selectChatbot(state).isGetterSetterDrawerOpen;
