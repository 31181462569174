import * as actionTypes from "./actionTypes";

export const alertShow = (payload) => ({
  type: actionTypes.ALERT_ADD,
  payload,
});

export const alertUpdate = (key, newAlert) => ({
  type: actionTypes.ALERT_ADD,
  payload: { key, newAlert },
});
export const alertSetList = (alertList) => ({
  type: actionTypes.ALERT_SET_LIST,
  payload: alertList,
});

export const alertRemove = (key) => ({
  type: actionTypes.ALERT_REMOVE,
  payload: key,
});

export const alertSetDom = (dom) => ({
  type: actionTypes.ALERT_SET_DOM,
  payload: dom,
});
