import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Badge } from "reactstrap";

import PRAccordion, { PRAccordionItem } from "~components/Generic/PRAccordion";
import Utils from "~helpers/Utils";
import { initState as scenarioManagerInitState } from "~store/dialogComponents/scenarioManager/reducer";
import {
  selectScenarioManagerForms,
  selectScenarioManagerSlotValidationText,
  selectScenarioManagerSlots,
  selectScenarioPreProcessLowCodeScript,
} from "~store/dialogComponents/scenarioManager/selectors";

import Forms from "./Forms";
import PreprocessLowCode from "./PreProcess";
import Slots from "./Slots";

// function RenderTitleIntentMetaTag() {
//   const authenticationRequired = useSelector(selectScenarioManagerAuthenticationRequired);
//   const authType = useSelector(selectScenarioManagerAuthType);

//   const initState = scenarioManagerInitState.intent;
//   const advancedState = {
//     authenticationRequired,
//   };
//   const advancedInitState = {
//     authenticationRequired: initState.authentication_required,
//   };

//   const hasChanges =
//     JSON.stringify(Utils.removeNulls(advancedState, true)) !==
//       JSON.stringify(Utils.removeNulls(advancedInitState, true)) || authType !== dialogComponentsAuthType.ANONYMOUS;

//   return (
//     <div className="position-relative">
//       Metadata
//       {hasChanges && (
//         <span className="ms-2 position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-1">
//           <span className="visually-hidden"></span>
//         </span>
//       )}
//     </div>
//   );
// }

function RenderTitleSlots() {
  const { t } = useTranslation();
  const slots = useSelector(selectScenarioManagerSlots);
  const slotValidationText = useSelector(selectScenarioManagerSlotValidationText);

  const initState = scenarioManagerInitState.intent;
  const advancedState = { slots, slotValidationText };
  const advancedInitState = { slotValidationText: initState.slot_validation_text, slots: initState.slots };

  const hasChanges =
    JSON.stringify(Utils.removeNulls(advancedState, true)) !==
    JSON.stringify(Utils.removeNulls(advancedInitState, true));

  return (
    <div className="position-relative">
      {t("common.slots")}
      {hasChanges && !slots?.length && (
        <span className="ms-2 position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-1">
          <span className="visually-hidden"></span>
        </span>
      )}
      <Badge className="ms-1" color={"primary"}>
        {!!slots?.length && slots?.length}
      </Badge>
    </div>
  );
}

function RenderTitleForms() {
  const forms = useSelector(selectScenarioManagerForms);
  const { t } = useTranslation();
  return (
    <>
      {t("common.forms")}
      <Badge className="ms-1" color={"primary"}>
        {!!forms.length && forms.length}
      </Badge>
    </>
  );
}
function RenderTitlePreprocessLowCode() {
  const preProcessLowCodeScript = useSelector(selectScenarioPreProcessLowCodeScript);
  const { t } = useTranslation();
  return (
    <div className="position-relative">
      {t("common.lowCode")}
      {!!preProcessLowCodeScript && (
        <span className="ms-2 position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-1">
          <span className="visually-hidden"></span>
        </span>
      )}
    </div>
  );
}

export default function Preprocess() {
  const [accordionState, setAccordionState] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
  });
  const handleClickAccordion = (id) => () => {
    setAccordionState({
      ...accordionState,
      [id]: !accordionState[id],
    });
  };

  return (
    <PRAccordion flush>
      {/* <PRAccordionItem
          secondary
          title={RenderTitleIntentMetaTag}
          collapsed={accordionState[1]}
          onClick={handleClickAccordion(1)}
        >
          <IntentMetaTag />
        </PRAccordionItem> */}
      <PRAccordionItem
        secondary
        collapsed={accordionState[4]}
        title={RenderTitlePreprocessLowCode}
        onClick={handleClickAccordion(4)}
      >
        <PreprocessLowCode />
      </PRAccordionItem>
      <PRAccordionItem
        secondary
        collapsed={accordionState[2]}
        title={RenderTitleSlots}
        onClick={handleClickAccordion(2)}
      >
        <Slots />
      </PRAccordionItem>
      <PRAccordionItem
        secondary
        collapsed={accordionState[3]}
        title={RenderTitleForms}
        onClick={handleClickAccordion(3)}
      >
        <Forms />
      </PRAccordionItem>
    </PRAccordion>
  );
}
