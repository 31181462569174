import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import { useFormik } from "formik";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import {
  MdAdd,
  MdAutoAwesome,
  MdChevronRight,
  MdDelete,
  MdExpandMore,
  MdOpenInNew,
  MdOutlineOpenInNew,
  MdUpload,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import {
  Alert,
  ButtonDropdown,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";

import { getIntentDetails } from "~apiRequests/intent";
import ImagePreview from "~assets/images/image-preview-64.png";
import ImagePreviewModal from "~common/modals/ImagePreviewModal";
import CodeEditor from "~components/CodeEditor";
import { PRAccordionItem } from "~components/Generic/PRAccordion";
import PRAccordion from "~components/Generic/PRAccordion/PRAccordionBody";
import PRButton from "~components/Generic/PRButton";
import { renderText } from "~components/Generic/PRChatMessage/ChatUtils";
import PRDragHandle from "~components/Generic/PRDragHandle";
import PRImg from "~components/Generic/PRImg";
import PRInput, { PRTextArea, PRTextAreaFormat } from "~components/Generic/PRInput";
import PRLink from "~components/Generic/PRLink";
import PRSelect from "~components/Generic/PRSelect";
import HtmlContent from "~components/HtmlContent";
import {
  dialogComponentsMessageChoiceTypes,
  dialogComponentsResponseMessageTypeOptions,
  dialogComponentsResponseMessageTypes,
  userRole,
} from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import Utils from "~helpers/Utils";
import useMonacoCodeCompletion from "~pages/ChatBot/DialogComponents/ScenarioManager/ScenarioManagerEdit/useMonacoCodeCompletion";
import useMonacoScenarioEditorLowCode from "~pages/ChatBot/DialogComponents/ScenarioManager/ScenarioManagerEdit/useMonacoScenarioEditorLowCode";
import useMonacoScenarioEditorVariables from "~pages/ChatBot/DialogComponents/ScenarioManager/ScenarioManagerEdit/useMonacoScenarioEditorVariables";
import UploadModal from "~pages/ChatBot/DialogComponents/UploadModal";
import {
  getDatabaseList,
  getIntent,
  getIntentList,
  getIntentResponsePrediction,
  getMediaList,
  getVariableList,
  uploadDatabase,
  uploadMedia,
} from "~store/dialogComponents/scenarioManager/actions";
import {
  selectScenarioManagerDatabaseList,
  selectScenarioManagerMediaList,
  selectScenarioManagerVariableList,
} from "~store/dialogComponents/scenarioManager/selectors";
import { getResources } from "~store/organization/actions";
import { selectResources } from "~store/organization/selectors";
import { selectCurrentBot, selectCurrentProject, selectUserInfo } from "~store/user/selectors";

import { PRContextProvider, useChatbotResponsePropContext } from "./context";

import "./style.scss";

function ChoiceMessageItem({ message, onChangeDynamic, depth }) {
  const { t } = useTranslation();
  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,

    initialValues: {
      text: message.text,
      buttonText: "",
    },
    validationSchema: Yup.object({
      text: Yup.string()
        .required(t("component.formik.required").format(t("common.message")))
        .test("len", t("component.formik.minLength").format(t("common.message"), "3"), (val) => {
          if (val) {
            return val.length > 3;
          }
          return true;
        }),
      buttonText: Yup.string().test(
        "len",
        t("component.formik.minLength").format(t("common.buttonText"), "3"),
        (val) => {
          if (val) {
            return val.length > 3;
          }
          return true;
        }
      ),
    }),
  });

  const sortedMessageButtons = useMemo(() => {
    return [...(message?.message_buttons || [])].sort((a, b) => a?.order - b?.order);
  }, [message?.message_buttons]);

  const handleClickAddChoiceButton = () => {
    if (!formik.values.text || formik.errors.text) return;
    const highestMessageButtonOrder = sortedMessageButtons.reduce((highest, item) => {
      return item.order > highest ? item.order : highest;
    }, 0);

    onChangeDynamic("message_buttons", [
      ...sortedMessageButtons,
      {
        text: "",
        button_type: dialogComponentsMessageChoiceTypes.goMessage,
        go_message_item: {
          message_type: dialogComponentsResponseMessageTypes.inline,
          children: [
            {
              message_type: dialogComponentsResponseMessageTypes.text,
              text: "",
              message_buttons: [],
              data_format: PRTextAreaFormat.plain,
              children: [],
            },
          ],
        },

        order: highestMessageButtonOrder + 1,
      },
    ]);
  };

  // const handleClickSelectType = (type) => () => {
  //   setSelectedChoiceType(type);
  // };
  const handleChange = (key) => (e) => {
    const value = e.target.value;
    formik.handleChange(e);
    if (key === "text") {
      onChangeDynamic?.(key, value);
    }
  };
  const handleChangeMessageButtonItem = (buttonIndex, key) => (goMessageItem) => {
    onChangeDynamic("message_buttons", [
      ...sortedMessageButtons.map((button, index) =>
        index === buttonIndex
          ? {
              ...button,
              [key]: goMessageItem,
            }
          : button
      ),
    ]);
  };

  const handleChangeMessageButtonText = (buttonIndex) => (text) => {
    onChangeDynamic("message_buttons", [
      ...sortedMessageButtons.map((button, index) => (index === buttonIndex ? { ...button, text } : button)),
    ]);
  };
  const handleDeleteMessageButtonItem = (buttonIndex) => () => {
    onChangeDynamic("message_buttons", [...sortedMessageButtons.filter((button, index) => index !== buttonIndex)]);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleClickAddChoiceButton();
    }
  };

  const handleOnSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = Utils.arrayMove(sortedMessageButtons, oldIndex, newIndex);

    onChangeDynamic(
      "message_buttons",
      newItems.map((item, index) => ({ ...item, order: index + 1 }))
    );
  };

  return (
    <div>
      {message.message_type === dialogComponentsResponseMessageTypes.choice && (
        <div className="d-flex align-items-center">
          <label className="fw-bold me-1">{t("component.choiceMessageItem.choices")}</label>
          <label className="text-capitalize"> ({sortedMessageButtons?.length})</label>
        </div>
      )}
      <div className="choice-message-item">
        <Row className="mb-2 g-0">
          <Col xs className="d-flex align-items-start">
            <Row className="gx-2 w-100">
              <Col xs="auto">
                <Label className=" " htmlFor={`choice-question-${depth}`} size="md">
                  {t("common.question")}:
                </Label>
              </Col>
              <Col xs>
                <PRInput
                  id={`choice-question-${depth}`}
                  invalid={!!formik.values.text && formik.errors.text}
                  name="text"
                  placeholder={t("component.choiceMessageItem.questionPlaceholder")}
                  rows={1}
                  type="textarea"
                  value={formik.values.text}
                  onBlur={formik.handleBlur}
                  onChange={handleChange("text")}
                  onKeyDown={handleKeyDown}
                />
              </Col>
            </Row>
          </Col>
          <Col xs="auto">
            <PRButton color="primary" disabled={formik.errors.text} icon={MdAdd} onClick={handleClickAddChoiceButton}>
              {t("component.choiceMessageItem.addChoice")}
            </PRButton>
          </Col>
        </Row>
        {!sortedMessageButtons?.length && (
          <div className="text-muted text-center">{t("component.choiceMessageItem.noChoices")}</div>
        )}

        <SortableChoiceItemList
          useDragHandle
          depth={depth}
          handleChangeMessageButtonItem={handleChangeMessageButtonItem}
          handleChangeMessageButtonText={handleChangeMessageButtonText}
          handleDeleteMessageButtonItem={handleDeleteMessageButtonItem}
          helperClass="pr-scenario-manager"
          items={sortedMessageButtons}
          onSortEnd={handleOnSortEnd}
        />
      </div>
    </div>
  );
}
function GoMessageItem({ message, onChange, onDelete, onChangeButtonText, buttonText, order, depth }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedType, setSelectedType] = useState(dialogComponentsResponseMessageTypes.text);
  const { t } = useTranslation();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,

    initialValues: {
      buttonText: buttonText,
      children: message?.children || [],
    },
    validationSchema: Yup.object({
      buttonText: Yup.string()
        .required(t("component.formik.required").format(t("common.buttonText")))
        .min(3, t("component.formik.minLength").format(t("common.buttonText"), "3")),
    }),
  });

  const sortedMessageItemChildren = useMemo(() => {
    return [...(message?.children || [])].sort((a, b) => a?.order - b?.order);
  }, [message?.children]);

  const handleClickSelectType = (type) => () => {
    setSelectedType(type);
  };
  const handleChangeMessageItem = (itemIndex) => (messageItem) => {
    const children = [
      ...(sortedMessageItemChildren || []).map((item, index) => (index === itemIndex ? { ...messageItem } : item)),
    ];

    const newItem = {
      ...message,
      children,
    };
    onChange(newItem);
  };
  const handleDeleteMessageItem = (itemIndex) => () => {
    const children = [...(sortedMessageItemChildren || [])];
    children.splice(itemIndex, 1);

    const newItem = {
      ...message,
      children,
    };
    onChange(newItem);
  };
  const handleOnSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = Utils.arrayMove([...(sortedMessageItemChildren || [])], oldIndex, newIndex);

    const newItem = {
      ...message,
      children: newItems.map((item, index) => ({ ...item, order: index + 1 })),
    };
    onChange(newItem);
  };

  const toggleDropdown = () => setIsDropdownOpen((prevState) => !prevState);

  const handleClickAddMessage = (e) => {
    const highestMessageItemOrder = (sortedMessageItemChildren || []).reduce((highest, item) => {
      return item.order > highest ? item.order : highest;
    }, 0);

    const newItem = {
      ...message,
      children: [
        ...(sortedMessageItemChildren || []),
        {
          text: "",
          message_type: selectedType,
          message_buttons: [],
          order: highestMessageItemOrder + 1,
          data_format: PRTextAreaFormat.plain,
        },
      ],
    };
    onChange(newItem);
  };

  const handleChangeButtonText = (e) => {
    const value = e.target.value;
    onChangeButtonText(value);
    formik.handleChange(e);
  };

  return (
    <div className="button-inline-message-items">
      <PRDragHandle />
      <Row className="gx-2 justify-content-end mb-2">
        <Col xs>
          <Row className="align-items-start g-0 w-100">
            <Col xs="auto">
              <label className="fw-bold m-0 me-2 mt-2">{t("common.buttonText")}:</label>
            </Col>
            <Col xs>
              <div className="w-100">
                <PRInput
                  id={`choice-question-button-${depth}`}
                  invalid={!!formik.touched.buttonText && formik.errors.buttonText}
                  name="buttonText"
                  placeholder={t("component.goMessageItem.buttonTextPlaceholder")}
                  type="text"
                  value={buttonText}
                  onBlur={formik.handleBlur}
                  onChange={handleChangeButtonText}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs="auto">
          <div className="btn-group">
            <ButtonDropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
              <PRButton color="primary" icon={MdAdd} id="caret" onClick={handleClickAddMessage}>
                {t("component.goMessageItem.addMessage1")}
                <span className="ms-1 text-capitalize fw-bold" style={{ whiteSpace: "pre" }}>
                  {t(
                    dialogComponentsResponseMessageTypeOptions.find((option) => option.value === selectedType)?.label ||
                      selectedType
                  ) + " "}
                </span>
                {t("component.goMessageItem.addMessage2")}
              </PRButton>
              <DropdownToggle caret color="primary">
                <MdExpandMore />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>{t("component.responseMessageItem.messageType")}</DropdownItem>
                {dialogComponentsResponseMessageTypeOptions.map((type) => (
                  <DropdownItem key={type.value} onClick={handleClickSelectType(type.value)}>
                    <span className="text-capitalize text-secondary"> {t(type.label)}</span>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>
          </div>
        </Col>
        <Col xs="auto">
          <PRButton outline color="danger" icon={MdDelete} onClick={onDelete} />
        </Col>
      </Row>
      <Label>
        <span className="fw-bold">
          {t("component.goMessageItem.buttonMessageItems")}({sortedMessageItemChildren?.length || 0})
        </span>
      </Label>
      <SortableMessageItemList
        useDragHandle
        handleChangeMessageItem={handleChangeMessageItem}
        handleDeleteMessageItem={handleDeleteMessageItem}
        helperClass="pr-scenario-manager"
        items={sortedMessageItemChildren || []}
        onSortEnd={handleOnSortEnd}
      />
      {!sortedMessageItemChildren?.length && (
        <div className="text-center mt-2">{t("component.goMessageItem.noButtonMessageItems")}</div>
      )}
    </div>
  );
}
function GoIntentItem({ goIntent, onChange, onDelete, onChangeButtonText, buttonText, order }) {
  const [selectedIntent, setSelectedIntent] = useState(goIntent);
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,

    initialValues: {
      buttonText: buttonText,
      intent: goIntent,
    },
    validationSchema: Yup.object({
      buttonText: Yup.string()
        .required(t("component.formik.required").format(t("common.buttonText")))
        .test(
          "min3",
          t("component.formik.minLength").format(t("common.buttonText"), "3"),
          (value) => value?.length >= 3
        ),
      intent: Yup.object({
        id: Yup.string().required(t("component.formik.required").format(t("common.intent"))),
      }),
    }),
  });

  useEffect(() => {
    if (goIntent && !goIntent?.text) {
      getIntentDetails(currentProject.id, currentBot.id, goIntent).then((res) => {
        setSelectedIntent(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goIntent, goIntent?.text]);

  const handleChangeIntent = (intent) => {
    onChange(intent.id);
  };

  const handleSearch = async (searchText, callback, signal, valueProp) => {
    const response = await dispatch(
      getIntentList(currentProject.id, currentBot.id, {
        params: {
          display_text__icontains: searchText,
          limit: 20,
        },
        signal,
      })
    );

    const responseExceptThis = (response?.results || [])?.filter(
      (item) => !selectedIntent?.id || item.id !== Number(selectedIntent?.id)
    );
    if (valueProp && !responseExceptThis.find((item) => item.id === valueProp)) {
      const responseIntent = await dispatch(getIntent(currentProject.id, currentBot.id, valueProp));
      responseExceptThis.push(responseIntent);
    }
    return responseExceptThis;
  };

  const handleChangeButtonText = (e) => {
    const value = e.target.value;
    onChangeButtonText(value);
    formik.handleChange(e);
  };

  return (
    <Col className="go-intent-item" xs={12}>
      <PRDragHandle />
      <Row className="gy-2 gx-0">
        <Col xs className="d-flex align-items-start g-0  mt-2">
          <Row className="align-items-start g-2 w-100">
            <Col xs="auto">
              <label className="fw-bold me-2 mt-2">{t("common.buttonText")}:</label>
            </Col>
            <Col xs>
              <div className="w-100">
                <PRInput
                  id={`intent-question-button-${order}`}
                  invalid={!!formik.touched.buttonText && formik.errors.buttonText}
                  name="buttonText"
                  placeholder={t("component.goIntentItem.buttonTextPlaceholder")}
                  type="text"
                  value={buttonText}
                  onBlur={formik.handleBlur}
                  onChange={handleChangeButtonText}
                />
              </div>
            </Col>
          </Row>
        </Col>

        <Col xs={"auto"}>
          <PRButton outline color="danger" icon={MdDelete} onClick={onDelete} />
        </Col>
      </Row>
      {/* <Label className="fws-bold ">Go Intent</Label> */}
      <Row className="mt-2 gx-2 align-items-center">
        <Col xs={"12"}>
          <PRSelect
            lazy
            menuPortal
            className="w-100"
            invalid={formik.errors.intent?.id}
            isClearable={false}
            labelSelector="display_text"
            loadOptions={handleSearch}
            name="entityType"
            placeholder={t("component.goIntentItem.intentSearchPlaceholder")}
            value={formik.values.go_intent}
            valueSelector="id"
            onChange={handleChangeIntent}
          />
        </Col>
        <Col xs className="gap-2 d-flex align-items-start">
          <Label className="fw-bold" size="md">
            {t("component.goIntentItem.intentQuestion")}:
          </Label>
          <Label className="fws-bold" size="md">
            <PRLink newTab to={`/chatbot/intent/form/${selectedIntent?.id}`}>
              {selectedIntent?.text}
            </PRLink>
          </Label>
        </Col>
      </Row>
    </Col>
  );
}

const SortableMessageItem = SortableElement(MessageItem);
const SortableGoIntentItem = SortableElement(GoIntentItem);
const SortableGoMessageItem = SortableElement(GoMessageItem);
const SortableMessageItemList = SortableContainer(({ items, handleChangeMessageItem, handleDeleteMessageItem }) => {
  return (
    <Row className="g-0 gap-2">
      {items.map((item, index) => (
        <SortableMessageItem
          key={index}
          depth={0}
          index={index}
          isRootItem={true}
          message={item}
          order={index}
          onChange={handleChangeMessageItem(index)}
          onDelete={handleDeleteMessageItem(index)}
        />
      ))}
    </Row>
  );
});

const SortableChoiceItemList = SortableContainer(
  ({ items, handleChangeMessageButtonItem, handleDeleteMessageButtonItem, handleChangeMessageButtonText, depth }) => {
    return (
      <Row className="g-0 gap-2 ">
        {items.map((choice, index) => {
          let buttonContent;
          if (choice.button_type === dialogComponentsMessageChoiceTypes.goIntent) {
            buttonContent = (
              <SortableGoIntentItem
                key={index}
                buttonText={choice.text}
                goIntent={choice.go_intent}
                index={index}
                order={index}
                onChange={handleChangeMessageButtonItem(index, "go_intent")}
                onChangeButtonText={handleChangeMessageButtonText(index)}
                onDelete={handleDeleteMessageButtonItem(index)}
              />
            );
          } else if (choice.button_type === dialogComponentsMessageChoiceTypes.goMessage) {
            let messageItem = choice.go_message_item;
            if (choice.go_message_item?.message_type !== dialogComponentsResponseMessageTypes.inline) {
              messageItem = {
                message_type: dialogComponentsResponseMessageTypes.inline,
                children: [choice.go_message_item],
              };
            }
            buttonContent = (
              <SortableGoMessageItem
                key={index}
                buttonText={choice.text}
                depth={depth + 1}
                index={index}
                message={messageItem}
                order={index}
                onChange={handleChangeMessageButtonItem(index, "go_message_item")}
                onChangeButtonText={handleChangeMessageButtonText(index)}
                onDelete={handleDeleteMessageButtonItem(index)}
              />
            );
          }
          return buttonContent;
        })}
      </Row>
    );
  }
);

/** @param {{ message: import("~store/dialogComponents/scenarioManager/reducer").MessageItem }} props */
function MessageItem({ message, onChange, onChangeButtonText, onDelete, isRootItem, buttonText, order, depth }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const resources = useSelector(selectResources);
  const userInfo = useSelector(selectUserInfo);
  const [intentResponsePrediction, setIntentResponsePrediction] = useState(["deneme"]);
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const dispatch = useDispatch();
  const [selectedIntent, setSelectedIntent] = useState({});
  const [codeEditor, setCodeEditor] = useState(null);
  const { onPrediction, getRelevantContext } = useChatbotResponsePropContext();

  const { id } = useParams();
  const isLowCodeEditable = useMemo(() => {
    return currentProject.permissions?.includes(userRole.admin) || userInfo?.is_superuser;
  }, [currentProject?.permissions, userInfo?.is_superuser]);

  const mediaList = useSelector(selectScenarioManagerMediaList);
  const databaseList = useSelector(selectScenarioManagerDatabaseList);
  const variableList = useSelector(selectScenarioManagerVariableList);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,

    initialValues: {
      text: message.text,
      data_format: message.data_format,
      fileUrl: message.file_url,
      imageUrl: message.image_url,
      buttonText: buttonText,
      resource: message.resource,
      low_code_script: message.low_code_script,
      go_intent: message.go_intent,
    },
    validationSchema: Yup.object({
      ...(!isRootItem
        ? {
            buttonText: Yup.string()
              .required(t("component.formik.required").format(t("common.buttonText")))
              .test(
                "min3",
                t("component.formik.minLength").format(t("common.buttonText"), "3"),
                (value) => value?.length >= 3
              ),
          }
        : {}),
      ...(message.message_type === dialogComponentsResponseMessageTypes.text ||
      message.message_type === dialogComponentsResponseMessageTypes.wikipediaAnswer
        ? {
            text: Yup.string()
              .required(t("component.formik.required").format(t("common.message")))
              .test("len", t("component.formik.minLength").format(t("common.message"), "3"), (val) => {
                if (val) {
                  return val.length > 3;
                }
                return true;
              }),
          }
        : {}),
      ...(message.message_type === dialogComponentsResponseMessageTypes.file
        ? {
            fileUrl: Yup.string()
              .required(t("component.formik.required").format(t("component.messageItem.fileUrl")))
              .test("validUrl", t("component.messageItem.validUrl"), (val) => {
                if (Utils.getVariablesFromStr(val).length) return true;
                return Utils.validateUrl(val);
              }),
          }
        : {}),
      ...(message.message_type === dialogComponentsResponseMessageTypes.image
        ? {
            imageUrl: Yup.string()
              .required(t("component.formik.required").format(t("component.messageItem.imageUrl")))
              .test("validUrl", t("component.messageItem.validUrl"), (val) => {
                if (Utils.getVariablesFromStr(val).length) return true;
                return Utils.validateUrl(val);
              }),
          }
        : {}),
      ...(message.message_type === dialogComponentsResponseMessageTypes.handleReservation
        ? {
            resource: Yup.number().required(t("component.formik.required").format(t("common.calendar"))),
          }
        : {}),
    }),
  });

  const handleChange = (key) => (e) => {
    const value = e.target.value;
    formik.handleChange(e);
    onChange?.({ ...message, [key]: value });
  };

  const handleChangeFormat = (value) => {
    // formik.setFieldValue("data_format", value);

    onChange?.({ ...message, data_format: value });
  };

  const handleChangeSelect = (key) => (value) => {
    formik.setFieldValue(key, value);
    onChange?.({ ...message, [key]: value });
  };
  const handleChangeDynamic = (key, value) => {
    onChange?.({ ...message, [key]: value });
  };

  const handleChangeCode = (value) => {
    onChange?.({ ...message, low_code_script: value });
  };
  const handleChangeButtonText = (e) => {
    const value = e.target.value;
    onChangeButtonText(value);
    formik.handleChange(e);
  };
  const toggleDropdown = () => setIsDropdownOpen((prevState) => !prevState);
  const handleClickChangeType = (type) => () => {
    onChange?.({
      ...message,
      message_type: type,
      ...(type === dialogComponentsResponseMessageTypes.choice && !message.message_buttons?.length
        ? { message_buttons: [] }
        : {}),
    });
  };

  const resourceOptions = useMemo(() => {
    const mappedData = resources.map((item) => ({
      value: item.id,
      label: `${item.name} (${item.resource_type})`,
    }));
    mappedData.reverse();
    return mappedData;
  }, [resources]);

  useEffect(() => {
    setIntentResponsePrediction([]);
  }, []);

  const handlePredictResponseSamples = (e) => {
    // const text = selectScenarioManagerText(store.getState());
    // const samples = selectScenarioManagerSamples(store.getState());

    // Expecting onPrediction to return an object with the following shape:
    // {
    //   text: string,
    //   samples: string[]
    // }
    const onPredictionResultParams = onPrediction?.(message);

    // const simplifiedResponse = getSimplifiedResponse(response);
    setIntentResponsePrediction(null);
    dispatch(
      getIntentResponsePrediction(currentProject.id, currentBot.id, {
        // text,
        // samples: samples.map((item) => item.intent_text),
        ...onPredictionResultParams,
        response: formik.values.text,
        count: e.shiftKey ? 25 : 5,
      })
    )
      .then(({ new_response = [] } = {}) => {
        const singleItem = new_response?.[0];
        if (typeof singleItem !== "string") {
          AlertHelper.show(t("component.messageItem.invalidPredictionAlert"), "error");
          setIntentResponsePrediction([]);
        } else {
          setIntentResponsePrediction(new_response);
        }
      })
      .catch((err) => {
        setIntentResponsePrediction([]);
      });
  };

  const isPredictionLoading = useMemo(() => {
    return intentResponsePrediction === null;
  }, [intentResponsePrediction]);

  const mediaListOptions = useMemo(() => {
    return [
      {
        value: -1,
        label: (
          <span className="">
            <MdAdd className="me-1" />
            {t("component.messageItem.customUrl")}
          </span>
        ),
      },
      ...mediaList.map((item) => ({
        value: item.name,
        label: item.name,
      })),
    ];
  }, [mediaList]);

  const fileListOptions = useMemo(() => {
    return [
      {
        value: -1,
        label: (
          <span className="">
            <MdAdd className="me-1" />
            {t("component.messageItem.customUrl")}
          </span>
        ),
      },
      {
        label: t("common.media"),
        options: mediaList.map((item) => ({
          value: "media|" + item.name,
          label: item.name,
        })),
      },
      {
        label: t("common.variable"),
        options: variableList.map((item) => ({
          value: "variable|" + item.name,
          label: item.name,
        })),
      },
      {
        label: t("common.database"),
        options: databaseList.map((item) => ({
          value: "database|" + item.name,
          label: item.name,
        })),
      },
    ];
  }, [mediaList, variableList, databaseList]);

  const handleChangeMediaListSelect = (value) => {
    let imageVariable = "";
    if (value !== -1) {
      imageVariable = `\${media["${value}"]}`;
    }
    formik.setFieldValue("image_url", imageVariable);
    onChange?.({ ...message, image_url: imageVariable });
  };

  const handleChangeFileListSelect = (valueRaw) => {
    let formattedVar = "";
    if (valueRaw !== -1) {
      const [prefix, value] = valueRaw.split("|");
      if (prefix === "variable") {
        formattedVar = `\${${value}}`;
      } else {
        formattedVar = `\${${prefix}["${value}"]}`;
      }
    }
    formik.setFieldValue("file_url", formattedVar);
    onChange?.({ ...message, file_url: formattedVar });
  };

  const selectedMedia = useMemo(() => {
    const regex = /^\${media\["(?<key>.+?)"\]}$/;
    const match = regex.exec(message.image_url);
    const key = match?.groups?.key;

    const selected = mediaList.find((item) => item.name === key);
    return selected;
  }, [mediaList, message.image_url]);

  const { selectedFile, selectedFileUrl, selectedFileValue } = useMemo(() => {
    const objectBasedRegex = /^\${(?<prefix>.+?)\["(?<key>.+?)"\]}$/;
    const variableRegex = /^\${(?<key>.+?)}$/;
    let objectBasedMatch = objectBasedRegex.exec(message.file_url);
    if (!objectBasedMatch) {
      objectBasedMatch = variableRegex.exec(message.file_url);
    }
    const key = objectBasedMatch?.groups?.key;
    const prefix = objectBasedMatch?.groups?.prefix;

    let selected;
    if (prefix === "media") {
      selected = mediaList.find((item) => item.name === key);
    } else if (prefix === "database") {
      selected = databaseList.find((item) => item.name === key);
    } else {
      selected = variableList.find((item) => item.name === key);
    }
    return {
      selectedFile: selected,
      selectedFileValue: selected ? `${prefix}|${selected.name}` : -1,
      selectedFileUrl: selected?.media_url || selected?.data_url || selected?.value || "",
    };
  }, [mediaList, variableList, databaseList, message.file_url]);

  const handleRemovePrediction = (item) => () => {
    setIntentResponsePrediction([...intentResponsePrediction.filter((i) => i !== item)]);
  };
  const handleClickImagePreview = (e, data) => {
    if (data.broken) return;
    ImagePreviewModal.show({
      // authenticated: true,
      image: data.image,
      // image: selectedMedia?.media_url || message.image_url,
      title: selectedMedia?.name,
    });
  };

  const handleAddPredictionAsTextMessage = (item) => () => {
    handleChange("text")({ target: { value: formik.values.text + "\n" + item } });
    handleRemovePrediction(item)();
  };
  useEffect(() => {
    if (message?.go_intent && message?.go_intent !== selectedIntent?.id) {
      getIntentDetails(currentProject.id, currentBot.id, message?.go_intent).then((res) => {
        setSelectedIntent(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message?.go_intent]);

  useMonacoScenarioEditorVariables(codeEditor?.editor);
  useMonacoScenarioEditorLowCode(codeEditor?.editor);
  useMonacoCodeCompletion(codeEditor?.editor, {
    getRelevantContext: getRelevantContext,
  });

  let content;
  if (
    message.message_type === dialogComponentsResponseMessageTypes.text ||
    message.message_type === dialogComponentsResponseMessageTypes.wikipediaAnswer ||
    message.message_type === dialogComponentsResponseMessageTypes.gptSmalltalk
  ) {
    content = (
      <>
        <label className="fw-bold">
          {message.message_type !== dialogComponentsResponseMessageTypes.gptSmalltalk
            ? t("common.content")
            : t("common.prompt")}
        </label>
        <PRTextArea
          editorMode
          editorProps={{
            onMount: setCodeEditor,
          }}
          format={
            message.message_type !== dialogComponentsResponseMessageTypes.gptSmalltalk
              ? formik.values.data_format
              : undefined
          }
          formatSelector={message.message_type !== dialogComponentsResponseMessageTypes.gptSmalltalk}
          invalid={!!formik.touched.text && formik.errors.text}
          name="text"
          previewRender={renderText}
          previewWrapper={formik.values.data_format === PRTextAreaFormat.html ? HtmlContent : "div"}
          rows={3}
          onChange={handleChange("text")}
          onFormatChange={
            message.message_type !== dialogComponentsResponseMessageTypes.gptSmalltalk ? handleChangeFormat : undefined
          }
          onBlur={formik.handleBlur}
          // defaultValue={formik.values.text}
          value={formik.values.text}
        />
        {!!intentResponsePrediction?.length && (
          <div className="mt-2">
            <PRAccordion noHeaderBackground noSpacing underline>
              <PRAccordionItem
                noHeaderBackground
                defaultCollapsed={true}
                title={t("component.messageItem.AISuggestionsTitle")}
              >
                <>
                  <Row className="g-2">
                    {intentResponsePrediction.map((sample, index) => {
                      return (
                        <Fragment key={`${sample || index}`}>
                          <Col xs={12}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div className="gap-2 ms-4 d-flex align-items-center w-100">
                                <MdChevronRight />
                                <div
                                  className="w-100 disabled-50"
                                  disabled={true}
                                  style={{
                                    paddingLeft: "0.75rem",
                                    paddingRight: "0.75rem",
                                  }}
                                >
                                  <Label size="md">{sample}</Label>
                                </div>
                                <PRButton
                                  outline
                                  color="success"
                                  icon={MdAdd}
                                  tooltipText={t("component.messageItem.addPrediction")}
                                  onClick={handleAddPredictionAsTextMessage(sample)}
                                />
                                <PRButton
                                  outline
                                  color="secondary"
                                  icon={MdDelete}
                                  tooltipText={t("component.messageItem.removePrediction")}
                                  onClick={handleRemovePrediction(sample)}
                                />
                              </div>
                            </div>
                          </Col>
                        </Fragment>
                      );
                    })}
                  </Row>
                </>
              </PRAccordionItem>
            </PRAccordion>
          </div>
        )}
        <Row className="mt-2 justify-content-end">
          <Col xs="auto">
            <PRButton
              color="primary"
              disabled={isPredictionLoading || !formik.values.text}
              icon={MdAutoAwesome}
              loading={isPredictionLoading}
              size="sm"
              tooltipDelay={1500}
              tooltipText={
                <div>
                  <div className="text-center fw-bold">{t("component.messageItem.generateAIVariationTooltip1")}</div>
                  <span className="text-end fw-bold me-1">
                    {t("component.messageItem.generateAIVariationTooltip2")}
                  </span>
                  <span className="text-start">{t("component.messageItem.generateAIVariationTooltip3")}</span>
                </div>
              }
              onClick={handlePredictResponseSamples}
            >
              {t("component.messageItem.generateAIVariation")}
            </PRButton>
          </Col>
        </Row>
      </>
    );
  } else if (message.message_type === dialogComponentsResponseMessageTypes.goIntent) {
    // const handleChangeIntent = (intent) => {
    //   formik.setFieldValue("go_intent", intent);
    //   onChange?.({ ...message, go_intent: intent.id });
    // };
    // const handleSearch = async (searchText) => {
    //   const response = await getIntents(currentProject.id, currentBot.id, searchText);
    //   return response?.results || [];
    // };
    const handleChangeSearchIntent = (intent) => {
      // if (!suggestedIntentIdList.includes(intent.id)) {
      //   dispatch(setScenarioFormState("response.suggested_intents", [...suggestedIntentIdList, intent.id]));
      //   setSuggestedIntentList([...suggestedIntentList, intent]);
      // }

      formik.setFieldValue("go_intent", intent);
      onChange?.({ ...message, go_intent: intent });
    };

    const handleSearch = async (searchText, callback, signal, valueProp) => {
      const response = await dispatch(
        getIntentList(currentProject.id, currentBot.id, {
          params: {
            display_text__icontains: searchText,
            limit: 20,
          },
          signal,
        })
      );

      const responseExceptThis = (response?.results || [])?.filter((item) => !id || item.id !== Number(id));
      if (valueProp && !responseExceptThis.find((item) => item.id === valueProp)) {
        const responseIntent = await dispatch(getIntent(currentProject.id, currentBot.id, valueProp));
        responseExceptThis.push(responseIntent);
      }
      return responseExceptThis;
    };
    const handleSelectDisabled = (option) => {
      return id && option?.id === Number(id);
    };
    content = (
      <>
        <label className="fw-bold">{t("component.messageItem.goIntent")}</label>

        <Row className="gx-2 d-flex align-items-center">
          <Col>
            {/* <PRDropdown
              labelSelector="text"
              label="Select Intent"
              onFetch={handleSearch}
              onChange={handleChangeIntent}
              invalid={formik.errors.intent?.id}
              searchIcon
              search="lazy"
            /> */}
            <PRSelect
              isPrimitiveValue
              lazy
              menuPortal
              className="w-100"
              id="entityType"
              isClearable={false}
              isOptionDisabled={handleSelectDisabled}
              labelSelector="display_text"
              loadOptions={handleSearch}
              name="entityType"
              placeholder={t("component.messageItem.goIntent.placeHolder")}
              value={formik.values.go_intent}
              valueSelector="id"
              onChange={handleChangeSearchIntent}
            />
          </Col>
          <Col xs={"auto"}>
            <PRButton
              icon={MdOutlineOpenInNew}
              link={`/chatbot/intent/form/${selectedIntent?.id}`}
              linkProps={{ newTab: true }}
              tooltipText={t("component.messageItem.newTabTooltip")}
            />
          </Col>
        </Row>
      </>
    );
  } else if (message.message_type === dialogComponentsResponseMessageTypes.file) {
    const handleClickUploadFile = async () => {
      const result = await UploadModal?.show();
      if (result?.length) {
        const uploadResult = await dispatch(
          uploadDatabase(currentProject.id, currentBot.id, result[0].file, result[0].name)
        );
        dispatch(getDatabaseList(currentProject.id, currentBot.id));
        const imageUrl = `\${database["${uploadResult?.name}"]}`;

        onChange?.({ ...message, file_url: imageUrl });
        formik.setFieldValue("fileUrl", imageUrl);
        UploadModal?.hide();
      }
    };
    content = (
      <>
        <label className="fw-bold">{t("component.messageItem.fileUrl")}</label>
        <Row className="gx-2">
          <Col>
            <Row className="gx-2">
              <Col xs="3">
                <PRSelect
                  isPrimitiveValue
                  isClearable={false}
                  options={fileListOptions}
                  value={selectedFileValue ?? -1}
                  onChange={handleChangeFileListSelect}
                />
              </Col>
              <Col className="gap-2 d-flex align-items-center" xs="9">
                <PRInput
                  className="w-100"
                  disabled={!!selectedFile}
                  invalid={!selectedFile && !!formik.touched.fileUrl && formik.errors.fileUrl}
                  name="fileUrl"
                  type="text"
                  value={formik.values.fileUrl}
                  onBlur={formik.handleBlur}
                  onChange={handleChange("file_url")}
                />

                <PRButton
                  outline
                  color="primary"
                  icon={MdUpload}
                  tooltipText={t("component.messageItem.uploadFileTooltip")}
                  onClick={handleClickUploadFile}
                />
              </Col>
            </Row>
          </Col>
          {!!selectedFileUrl && (
            <Col xs="auto">
              <PRButton
                outline
                color="primary"
                icon={MdOpenInNew}
                link={selectedFileUrl}
                linkProps={{
                  newTab: true,
                  noPrefix: true,
                }}
                tooltipText={t("component.messageItem.newTabTooltip")}
              />
            </Col>
          )}
        </Row>
      </>
    );
  } else if (message.message_type === dialogComponentsResponseMessageTypes.image) {
    const handleClickUploadImage = async () => {
      const result = await UploadModal?.show({ title: t("component.messageItem.uploadImage") });
      if (result?.length) {
        const uploadResult = await dispatch(
          uploadMedia(currentProject.id, currentBot.id, result[0].file, result[0].name)
        );
        dispatch(getMediaList(currentProject.id, currentBot.id));
        const imageUrl = `\${media["${uploadResult?.name}"]}`;

        onChange?.({ ...message, image_url: imageUrl });
        formik.setFieldValue("imageUrl", imageUrl);
        UploadModal?.hide();
      }
    };
    content = (
      <>
        <Row className="gx-2">
          <Col>
            <label className="fw-bold">{t("component.messageItem.imageUrl")}</label>
            <Row className="gx-2">
              <Col xs="3">
                <PRSelect
                  isPrimitiveValue
                  isClearable={false}
                  options={mediaListOptions}
                  value={selectedMedia?.name ?? -1}
                  onChange={handleChangeMediaListSelect}
                />
              </Col>
              <Col className="gap-2 d-flex align-items-center" xs="9">
                <PRInput
                  className="w-100"
                  disabled={!!selectedMedia}
                  invalid={!selectedMedia && !!formik.touched.imageUrl && formik.errors.imageUrl}
                  name="imageUrl"
                  type="text"
                  value={formik.values.imageUrl}
                  onBlur={formik.handleBlur}
                  onChange={handleChange("image_url")}
                />
                <PRButton
                  outline
                  color="primary"
                  icon={MdUpload}
                  tooltipText={t("component.messageItem.uploadImageTooltip")}
                  onClick={handleClickUploadImage}
                />
              </Col>
            </Row>
          </Col>
          {message.image_url && (
            <Col className="pe-0" xs={"auto"}>
              <div className="image-preview">
                <PRImg
                  // authenticated
                  blankPlaceholder={ImagePreview}
                  src={selectedMedia?.media_url || message.image_url}
                  onClick={handleClickImagePreview}
                />
              </div>
            </Col>
          )}
        </Row>
      </>
    );
  } else if (message.message_type === dialogComponentsResponseMessageTypes.choice) {
    content = <ChoiceMessageItem depth={depth + 1} message={message} onChangeDynamic={handleChangeDynamic} />;
  } else if (message.message_type === dialogComponentsResponseMessageTypes.handleReservation) {
    content = (
      <>
        <label className="fw-bold">{t("common.calendar")}</label>
        <PRSelect
          isPrimitiveValue
          invalid={!!formik.touched.resource && formik.errors.resource}
          name="resource"
          options={resourceOptions}
          value={formik.values.resource}
          onBlur={formik.handleBlur}
          onChange={handleChangeSelect("resource")}
        />
      </>
    );
  } else if (message.message_type === dialogComponentsResponseMessageTypes.lowCode) {
    content = (
      <>
        <label className="fw-bold">{t("common.lowCodeEditor")}</label>
        <Alert className="mb-3" color="warning" isOpen={!isLowCodeEditable}>
          {t("component.messageItem.lowCodeEditorAlert")}
        </Alert>
        <CodeEditor
          readOnly={!isLowCodeEditable}
          value={formik.values.low_code_script}
          onChange={handleChangeCode}
          onMount={setCodeEditor}
        />
      </>
    );
  }
  return (
    <Col className="message-item" xs={12}>
      <PRDragHandle />
      <Row className="align-items-start g-0 gap-2">
        <Col className="message-type" xs={"auto"}>
          <div className="d-flex align-items-center">
            <span className="fw-bold me-2">{t("common.type")}:</span>
            <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
              <DropdownToggle caret color="primary">
                <span className="text-capitalize">
                  {t(
                    dialogComponentsResponseMessageTypeOptions.find((option) => option.value === message.message_type)
                      ?.label || message.message_type
                  )}
                </span>
                <MdExpandMore />
              </DropdownToggle>
              <DropdownMenu>
                {dialogComponentsResponseMessageTypeOptions.map((type) => (
                  <DropdownItem key={type.value} onClick={handleClickChangeType(type.value)}>
                    {t(type.label)}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </Col>

        <Col xs className="d-flex align-items-center px-0 ">
          {!isRootItem && (
            <Row className="align-items-start g-0 w-100">
              <Col xs="auto">
                <label className="fw-bold m-0 me-2 mt-2">{t("common.buttonText")}:</label>
              </Col>
              <Col xs>
                <div className="w-100">
                  <PRInput
                    id={`choice-question-button-${depth}`}
                    invalid={!!formik.touched.buttonText && formik.errors.buttonText}
                    name="buttonText"
                    placeholder={t("component.messageItem.buttonTextPlaceholder")}
                    type="text"
                    value={buttonText}
                    onBlur={formik.handleBlur}
                    onChange={handleChangeButtonText}
                  />
                </div>
              </Col>
            </Row>
          )}
        </Col>
        <Col className="d-flex align-items-center" xs={"auto"}>
          <PRButton
            outline
            color="danger"
            icon={MdDelete}
            onClick={onDelete}
            // className="rounded"
          />
        </Col>

        {content && (
          <Col className="pt-2" xs={12}>
            {content}
          </Col>
        )}
      </Row>
    </Col>
  );
}

function ResponseMessageItems({ onChange, label }) {
  const { t } = useTranslation();
  const { messageItems } = useChatbotResponsePropContext();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedType, setSelectedType] = useState(dialogComponentsResponseMessageTypes.text);

  const handleClickAddMessage = (e, customInitialData = {}) => {
    const highestMessageItemOrder = messageItems.reduce((highest, item) => {
      return item.order > highest ? item.order : highest;
    }, 0);

    onChange([
      ...messageItems,
      {
        text: "",
        message_type: selectedType,
        message_buttons: [],
        order: highestMessageItemOrder + 1,
        data_format: PRTextAreaFormat.plain,
        ...customInitialData,
      },
    ]);
  };
  const handleChangeMessageItem = (itemIndex) => (messageItem) => {
    // DialogHelper.show("Add Message");

    onChange([...messageItems.map((item, index) => (index === itemIndex ? { ...messageItem } : item))]);
  };
  const handleDeleteMessageItem = (itemIndex) => () => {
    // DialogHelper.show("Add Message");

    onChange([...messageItems.filter((item, index) => index !== itemIndex)]);
  };
  const handleClickSelectType = (type) => () => {
    setSelectedType(type);
  };
  const toggleDropdown = () => setIsDropdownOpen((prevState) => !prevState);
  const handleOnSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = Utils.arrayMove([...messageItems], oldIndex, newIndex);

    onChange(newItems.map((item, index) => ({ ...item, order: index + 1 })));
  };

  return (
    <div className="pr-chatbot-response">
      <span className="d-flex justify-content-between align-items-center mb-3">
        <Label className="my-0" size="md">
          {label ? label : t("common.messages")}
        </Label>
        {/* <PRButton icon={MdAdd} onClick={handleClickAddMessage} /> */}
        <div className="btn-group">
          <ButtonDropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
            <PRButton color="primary" id="caret" onClick={handleClickAddMessage}>
              <MdAdd className="me-2" />
              {t("component.responseMessageItem.addItem1")}
              <span className="ms-1 text-capitalize fw-bold" style={{ whiteSpace: "pre" }}>
                {t(
                  dialogComponentsResponseMessageTypeOptions.find((option) => option.value === selectedType)?.label ||
                    selectedType
                ) + " "}
              </span>
              {t("component.responseMessageItem.addItem2")}
            </PRButton>
            <DropdownToggle caret color="primary">
              <MdExpandMore />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>{t("component.responseMessageItem.messageType")}</DropdownItem>
              {/* <DropdownItem divider /> */}
              {dialogComponentsResponseMessageTypeOptions.map((type) => (
                <DropdownItem key={type.value} onClick={handleClickSelectType(type.value)}>
                  <span className="text-capitalize text-secondary"> {t(type.label)}</span>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </ButtonDropdown>
        </div>
      </span>
      {!messageItems?.length && (
        <div>
          <p className="text-muted text-center"> - {t("component.responseMessageItems.noMessages")} - </p>
        </div>
      )}

      <SortableMessageItemList
        useDragHandle
        handleChangeMessageItem={handleChangeMessageItem}
        handleDeleteMessageItem={handleDeleteMessageItem}
        helperClass="pr-scenario-manager pr-chatbot-response"
        items={messageItems}
        onSortEnd={handleOnSortEnd}
      />
    </div>
  );
}

export default function ChatbotResponse({ getRelevantContext, onChange, messageItems, onPrediction, label }) {
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);

  const sortedResponseMessageItems = useMemo(() => {
    return [...(messageItems || [])].sort((a, b) => a?.order - b?.order);
  }, [messageItems]);

  useEffect(() => {
    dispatch(getResources(currentProject.id));

    dispatch(getMediaList(currentProject.id, currentBot.id));
    dispatch(getVariableList(currentProject.id, currentBot.id));
    dispatch(getDatabaseList(currentProject.id, currentBot.id));
  }, [dispatch, currentProject.id, currentBot.id]);

  const handleChangeMessageItems = useCallback(
    (messageItems) => {
      onChange?.(messageItems);
    },
    [onChange]
  );

  const valueProp = useMemo(() => {
    return {
      onPrediction,
      messageItems: sortedResponseMessageItems,
      getRelevantContext,
    };
  }, [onPrediction, sortedResponseMessageItems, getRelevantContext]);

  return (
    <PRContextProvider valueProp={valueProp}>
      <ResponseMessageItems label={label} onChange={handleChangeMessageItems} />
    </PRContextProvider>
  );
}
