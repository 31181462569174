import Network from "~helpers/Network";

async function getProjectStats(project) {
  return await Network.request(`project/${project}/statistics/`, {
    method: "GET",
    loading: false,
  });
}

export { getProjectStats };
