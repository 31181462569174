import { apiUrlChatbot } from "~constants";
import Network from "~helpers/Network";

import * as at from "./actionTypes";

export const setDictionary = (payload) => ({
  type: at.SET_DICTIONARY,
  payload,
});

// -----------  API ------------

export const getDictionary = (projectId, chatbotId, id) => (_dispatch, _getState) => {
  const url = `${apiUrlChatbot.getDictionary.format(projectId, chatbotId)}${id}/`;

  return Network.request(url, {
    loading: true,
    onSuccess: setDictionary,
  });
};

export const createOrUpdateDictionary = (projectId, chatbotId, data) => (_dispatch, _getState) => {
  const url = data?.id
    ? `${apiUrlChatbot.getDictionary.format(projectId, chatbotId)}${data?.id}/`
    : apiUrlChatbot.getDictionary.format(projectId, chatbotId);

  return Network.request(url, {
    method: data?.id ? "PUT" : "POST",
    data,
    loading: true,
  });
};

export const deleteDictionary = (projectId, chatbotId, id) => (_dispatch, _getState) => {
  const url = `${apiUrlChatbot.getDictionary.format(projectId, chatbotId)}${id}/`;

  return Network.request(url, {
    method: "DELETE",
    successMsg: true,
    loading: true,
  });
};
