import { createContext, useContext } from "react";

export const PRCalendarPropContext = createContext({});
export function PRContextProvider({ children, valueProp }) {
  return (
    <PRCalendarPropContext.Provider value={valueProp}>
      {/*  */}
      {children}
    </PRCalendarPropContext.Provider>
  );
}

export const usePRCalendarPropContext = () => useContext(PRCalendarPropContext);
