import React, { useEffect } from "react";

const bootstrapBreakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};
export default function useBootstrapBreakpoint(size = "md") {
  const [isBreakpoint, setIsBreakpoint] = React.useState(false);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < bootstrapBreakpoints[size]) {
        setIsBreakpoint(true);
      } else {
        setIsBreakpoint(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [size]);
  return isBreakpoint;
}
