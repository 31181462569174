import { useEffect, useMemo, useRef, useState } from "react";

import chroma from "chroma-js";
import classNames from "classnames";
import { groupBy } from "lodash";
import ReactDOMServer from "react-dom/server";
import { MdOpenInNew } from "react-icons/md";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Col, FormGroup, Label, Row } from "reactstrap";

import styled from "@emotion/styled";

import PRButton from "~components/Generic/PRButton";
import PRChart from "~components/Generic/PRChart";
import PRContainer from "~components/Generic/PRContainer";
import PRInput from "~components/Generic/PRInput";
import PRPage from "~components/Generic/PRPage";
import PRSelect from "~components/Generic/PRSelect";
import PRTab from "~components/Generic/PRTab";
import AlertHelper from "~helpers/AlertHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import store from "~store";
import { getInsight, setInsight, setInsightData } from "~store/dialogComponents/scenarioManager/actions";
import {
  selectScenarioManagerInsight,
  selectScenarioManagerInsightData,
} from "~store/dialogComponents/scenarioManager/selectors";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

function rectangleToLassoCoords(x1, y1, x2, y2) {
  // Bu fonksiyon sol alt (x1, y1) ve sağ üst (x2, y2) koordinatları alır ve
  // bu koordinatlara uygun bir lasso select koordinat dizisi döndürür.
  return [
    [x1, y1],
    [x1, y2],
    [x2, y2],
    [x2, y1],
    [x1, y1], // İlk noktaya dönerek kapalı bir döngü oluşturuyoruz
  ];
}
function isPointInPolygon(point, polygon) {
  let x = point[0],
    y = point[1];

  let isInside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    let xi = polygon[i][0],
      yi = polygon[i][1];
    let xj = polygon[j][0],
      yj = polygon[j][1];

    let intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) isInside = !isInside;
  }

  return isInside;
}

function cross(o, a, b) {
  return (a[0] - o[0]) * (b[1] - o[1]) - (a[1] - o[1]) * (b[0] - o[0]);
}

function convexHull(points = []) {
  points.sort(function (a, b) {
    return a[0] == b[0] ? a[1] - b[1] : a[0] - b[0];
  });

  let lower = [];
  for (let i = 0; i < points.length; i++) {
    while (lower.length >= 2 && cross(lower[lower.length - 2], lower[lower.length - 1], points[i]) <= 0) {
      lower.pop();
    }
    lower.push(points[i]);
  }

  let upper = [];
  for (let i = points.length - 1; i >= 0; i--) {
    while (upper.length >= 2 && cross(upper[upper.length - 2], upper[upper.length - 1], points[i]) <= 0) {
      upper.pop();
    }
    upper.push(points[i]);
  }

  upper.pop();
  lower.pop();
  return lower.concat(upper);
}

function calculateTrianglePoints(x, y, radius) {
  const x1 = x;
  const y1 = y - radius;

  const x2 = x - (Math.sqrt(3) / 2) * radius;
  const y2 = y + radius / 2;

  const x3 = x + (Math.sqrt(3) / 2) * radius;
  const y3 = y + radius / 2;

  return [
    [x1, y1],
    [x2, y2],
    [x3, y3],
  ];
}
let highlightedSeriesIndex = null;

const StyledContainer = styled(PRContainer)`
  .pr-sel__control {
    max-height: 55vh;
    overflow: auto;
  }
`;

const SelectedSeriesItem = () => {
  const insightData = useSelector(selectScenarioManagerInsightData);

  if (!insightData) return null;
  return (
    <div className="fs-5">
      <div className="fw-bold mb-1">Tag</div>
      <div className="mb-2">{insightData?.intent_tag_label || "-"}</div>
      <div className="fw-bold mb-1">Intent</div>
      <div className="d-flex align-items-center mb-2">
        {insightData?.intent_text}
        <PRButton
          outline
          className="ms-1"
          color="primary"
          icon={MdOpenInNew}
          link={`/chatbot/intent/form/${insightData?.intent_id}/`}
          linkProps={{ newTab: true }}
          size="sm"
        />
      </div>
    </div>
  );
};

export default function IntentDistributionMap() {
  const dispatch = useDispatch();

  const isShiftPressed = useRef(false);

  const insight = useSelector(selectScenarioManagerInsight);
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);

  const [initialInsight, setInitialInsight] = useState({
    intents: [],
    samples: [],
  });
  const [selectedIntentList, setSelectedIntentList] = useState([]);
  const [chartCustomOptions, setChartCustomOptions] = useState({
    // markAreaVisible: false,
    showSamples: true,
    showIntents: true,
    showConvexHull: true,
  });
  const [chartRef, setChartRef] = useState(null);
  const [selectedLegends, setSelectedLegends] = useState({});
  const [selectedTab, setSelectedTab] = useState("intent");
  const brushSeriesRef = useRef(null);

  const { intentList, intentTagList } = useMemo(() => {
    if (!insight.intents?.length) return [];

    let seriesList = insight.intents.map((intent) => {
      const dataList = [];

      const sampleList = [
        ...(insight.samples
          .filter((sample) => sample.intent_id === intent.id)
          ?.map((sample) => ({
            name: sample.id,
            value: [sample.vector[0], sample.vector[1]],
            symbolSize: 5,
            id: sample.id,
            type: "sample",
            objItem: sample,
            text: sample.intent_text,
          })) || []),
      ];

      if (chartCustomOptions.showSamples) {
        dataList.push(...sampleList);
      }

      const intentList = [
        {
          name: intent.intent_id,
          value: [intent.vector[0], intent.vector[1]],
          symbolSize: 10,
          id: intent.id,
          type: "intent",
          objItem: intent,
          text: intent.intent_text,
        },
      ];
      if (chartCustomOptions.showIntents) {
        dataList.push(...intentList);
      }

      if (chartCustomOptions.showConvexHull) {
        const dataArr =
          !chartCustomOptions.showSamples && !chartCustomOptions.showIntents
            ? [...sampleList, ...intentList]
            : [...dataList];
        const rawDataList = dataArr.map((item) => item.value);
        const hullPoints = convexHull(rawDataList);

        dataList.push({
          name: dataArr.map((item) => item.id).join("_"),
          value: hullPoints,
          id: dataArr.map((item) => item.id).join("_"),
          type: "convexHull",
          objItem: intent,
        });
      }
      return {
        name: intent.id,
        id: intent.id,
        text: intent.intent_text,
        tag: intent.intent_tag_label || "No Tag",
        tagId: intent.intent_tag_id || 0,
        data: dataList,
      };
    });

    const groupedSeriesList = groupBy(seriesList, (series) => series.tagId);
    const seriesTagList = Object.entries(groupedSeriesList).map(([tag, seriesList], index) => {
      const seriesData = seriesList.reduce((acc, series) => {
        acc.push(...series.data);
        return acc;
      }, []);
      return {
        name: seriesList?.[0]?.tagId || index,
        id: seriesList?.[0]?.tagId,
        // tag: tag || "No Tag" + (index + 1),
        data: seriesData,
        objItem: seriesList,
        text: seriesList?.[0]?.tag || "No Tag" + (index + 1),
      };
    });

    return {
      intentList: seriesList,
      intentTagList: seriesTagList,
    };
  }, [insight.intents, insight.samples, chartCustomOptions]);

  useEffect(() => {
    if (!intentList?.length || initialInsight?.intents?.length) return;

    setInitialInsight(insight);
    setSelectedIntentList(intentList);
  }, [insight, initialInsight, intentList]);

  const { initialIntentList, initialIntentTagList } = useMemo(() => {
    if (!initialInsight.intents?.length) return [];

    let seriesList = initialInsight.intents.map((intent) => {
      const dataList = [];

      const sampleList = [
        ...(initialInsight.samples
          .filter((sample) => sample.intent_id === intent.id)
          ?.map((sample) => ({
            name: sample.id,
            value: [sample.vector[0], sample.vector[1]],
            symbolSize: 5,
            id: sample.id,
            type: "sample",
            objItem: sample,
            text: sample.intent_text,
          })) || []),
      ];

      if (chartCustomOptions.showSamples) {
        dataList.push(...sampleList);
      }

      const intentList = [
        {
          name: intent.intent_id,
          value: [intent.vector[0], intent.vector[1]],
          symbolSize: 10,
          id: intent.id,
          type: "intent",
          objItem: intent,
          text: intent.intent_text,
        },
      ];
      if (chartCustomOptions.showIntents) {
        dataList.push(...intentList);
      }

      if (chartCustomOptions.showConvexHull) {
        const dataArr =
          !chartCustomOptions.showSamples && !chartCustomOptions.showIntents
            ? [...sampleList, ...intentList]
            : [...dataList];
        const rawDataList = dataArr.map((item) => item.value);
        const hullPoints = convexHull(rawDataList);

        dataList.push({
          name: dataArr.map((item) => item.id).join("_"),
          value: hullPoints,
          id: dataArr.map((item) => item.id).join("_"),
          type: "convexHull",
          objItem: intent,
        });
      }
      return {
        name: intent.id,
        id: intent.id,
        text: intent.intent_text,
        tag: intent.intent_tag_label || "No Tag",
        tagId: intent.intent_tag_id || 0,
        data: dataList,
      };
    });

    const groupedSeriesList = groupBy(seriesList, (series) => series.tagId);
    const seriesTagList = Object.entries(groupedSeriesList).map(([tag, seriesList], index) => {
      const seriesData = seriesList.reduce((acc, series) => {
        acc.push(...series.data);
        return acc;
      }, []);
      return {
        name: seriesList?.[0]?.tagId || index,
        id: seriesList?.[0]?.tagId,
        // tag: tag || "No Tag" + (index + 1),
        data: seriesData,
        objItem: seriesList,
        text: seriesList?.[0]?.tag || "No Tag" + (index + 1),
      };
    });

    return {
      initialIntentList: seriesList,
      initialIntentTagList: seriesTagList,
    };
  }, [initialInsight?.intents, initialInsight?.samples, chartCustomOptions]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Shift") {
        isShiftPressed.current = true;
      }
    };
    const handleKeyUp = (e) => {
      if (e.key === "Shift") {
        isShiftPressed.current = false;
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    const instance = chartRef?.getEchartsInstance();
    const activeSeriesList = selectedTab === "intent" ? intentList : intentTagList;
    if (!instance || !activeSeriesList?.length) return;
    const selectedLegends = activeSeriesList.reduce((acc, item) => {
      acc[item.id] = !!selectedIntentList?.find((intent) => intent.id === item.id);
      return acc;
    }, {});
    setSelectedLegends(selectedLegends);
  }, [selectedIntentList, chartRef, selectedTab, intentList, intentTagList]);

  useEffect(() => {
    // if (insight.intents?.length) return;
    dispatch(
      getInsight(currentProject.id, currentBot.id, {
        include_samples: true,
        include_no_prediction_intents: false,
      })
    );
    setInitialInsight({
      intents: [],
      samples: [],
    });
  }, [currentProject.id, currentBot.id, dispatch]);

  const chartOptions = useMemo(() => {
    const activeSeriesList = selectedTab === "intent" ? intentList : intentTagList;
    if (!activeSeriesList?.length) return {};

    const series = activeSeriesList.map((series) => {
      return {
        animation: false,
        type: "custom",
        name: series.name?.toString(),
        id: series.id,
        data: series.data,
        emphasis: {
          focus: "series",
          blurScope: "coordinateSystem",
        },
        renderItem: (params, api) => {
          const dataIndex = params.dataIndex;
          const originalData = series.data[dataIndex];
          const isHighlighted = params.seriesIndex === highlightedSeriesIndex;
          const isNonHighlighted = !!highlightedSeriesIndex && params.seriesIndex !== highlightedSeriesIndex;

          let polygonColor = chroma(api.style().fill).alpha(0.1).css();
          let circleColor = api.style().fill;

          if (isHighlighted) {
            polygonColor = chroma(polygonColor).alpha(0.3).css();
            circleColor = chroma(circleColor).alpha(0.7).css();
          } else if (isNonHighlighted) {
            polygonColor = chroma(polygonColor).alpha(0.2).css();
            circleColor = chroma(circleColor).alpha(0.2).css();
          }

          if (originalData.type === "convexHull") {
            const points = originalData.value.map((pt) => api.coord(pt));
            return {
              // name: originalData.name,
              type: "polygon",
              shape: {
                points,
              },
              style: api.style({
                fill: polygonColor,
              }),
              silent: true,
            };
          } else {
            const p = api.coord([api.value(0), api.value(1)]);
            const x = p[0];
            const y = p[1];
            const size = api.size([1, 1]);
            const radius = Math.max(2.5, size[0] * 0.175);
            const strokeWidth = Math.max(1.5, size[0] * 0.1);
            return {
              name: originalData.name,
              type: originalData.type === "intent" ? "polygon" : "circle",
              shape:
                originalData.type === "intent"
                  ? {
                      points: calculateTrianglePoints(x, y, radius * 1.5),
                    }
                  : {
                      cx: x,
                      cy: y,
                      r: radius,
                    },
              style: api.style({
                stroke: isHighlighted ? api.style().fill : circleColor,
                lineWidth: strokeWidth,
                fill: isHighlighted ? circleColor : "transparent",
              }),
            };
          }
        },
      };
    });

    return {
      lazyUpdate: true,
      animationDuration: 0,
      animationDurationUpdate: 0,
      animation: false,
      title: {
        text: "Intent Distribution Map",
      },
      grid: {
        left: "3%",
        right: "3%",
        containLabel: true,
        //remove background
        backgroundColor: "transparent",
        borderWidth: 0,
      },
      tooltip: {
        trigger: "item",
        showDelay: 0,
        formatter: function (params) {
          const objItem = params?.data?.objItem;
          const isIntent = objItem.id === objItem.intent_id;
          // console.log(params);
          const intent = insight.intents.find((intent) => intent.id === objItem.intent_id);
          const sample = !isIntent && insight.samples.find((sample) => sample.id === objItem.id);

          const htmlString = ReactDOMServer.renderToStaticMarkup(
            <Provider store={store}>
              <Row
                className="text-center"
                style={{
                  maxWidth: 360,
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                  // wordSpacing: "normal",
                }}
              >
                <Col className="d-flex align-items-center justify-content-center" xs="12">
                  <div dangerouslySetInnerHTML={{ __html: params?.marker }}></div>
                  <span
                    className={classNames({
                      "text-danger": isIntent,
                      "text-primary": !isIntent,
                    })}
                  >
                    {isIntent ? " Intent" : " Sample"}
                  </span>
                </Col>
                <Col xs="12">Tag</Col>
                <Col className="fw-bold mb-2" xs="12">
                  {intent?.intent_tag_label || "-"}
                </Col>

                <Col xs="12">Question</Col>
                <Col className="fw-bold  mb-2" xs="12">
                  {intent?.intent_text}
                </Col>
                {!isIntent && (
                  <>
                    <Col xs="12">Sample:</Col>
                    <Col className="fw-bold" xs="12">
                      {sample?.intent_text}
                    </Col>
                  </>
                )}

                {/* <Col xs="12">
                <PRLink newTab to={`/chatbot/intent/form/${first?.seriesId}`}>
                  Go to Intent
                </PRLink>
              </Col> */}
              </Row>
            </Provider>
          );

          return htmlString;
        },
      },
      dataZoom: [
        {
          type: "inside",
        },
        {
          type: "inside",
          orient: "vertical",
        },
      ],
      toolbox: {
        feature: {
          dataZoom: {},
          brush: {
            type: ["rect", "polygon", "clear"],
          },
        },
      },
      brush: {
        xAxisIndex: "all",
        brushLink: "all",
        outOfBrush: {
          colorAlpha: 0.1,
        },
      },
      legend: {
        show: false,
        selected: selectedLegends,
      },
      xAxis: [{}],
      yAxis: [{}],
      series: series, //...seriesList
    };
  }, [selectedLegends, intentList, intentTagList, selectedTab, insight.intents, insight.samples]);

  const handleChangeChartOption = (toggleKey) => (e) => {
    setChartCustomOptions((prev) => ({
      ...prev,
      [toggleKey]: e.target.checked,
    }));
  };

  const handleSelectDeselectAll = () => {
    if (selectedIntentList?.length) {
      setSelectedIntentList([]);
    } else {
      if (selectedTab === "intentTag") {
        setSelectedIntentList(intentTagList);
      } else {
        setSelectedIntentList(intentList);
      }
    }
  };

  const handleRecalculate = () => {
    const params = {};

    if (brushSeriesRef.current !== null) {
      const areaIntents = [...(brushSeriesRef.current || [])];
      let intentIdList = [];
      for (const series of areaIntents) {
        intentIdList.push(...series.data.map((item) => item.objItem.intent_id));
      }
      intentIdList = [...new Set(intentIdList)];
      if (intentIdList.length === 0) {
        AlertHelper.showWarning("Selected area does not contain any intent. Please select another area.");
        return;
      }
      params.intent_list = intentIdList;
      brushSeriesRef.current = null;
      //clear brush selection
      const instance = chartRef?.getEchartsInstance();
      instance.dispatchAction({
        type: "brush",
        areas: [],
      });
    } else if (selectedTab === "intent") {
      params.intent_list = selectedIntentList.map((intent) => intent.id);
    } else {
      params.intent_tag_list = selectedIntentList.map((intent) => intent.id);
    }
    dispatch(
      getInsight(currentProject.id, currentBot.id, {
        include_samples: true,
        include_no_prediction_intents: false,
        ...params,
      })
    );
  };
  const handleRecalculateReset = () => {
    dispatch(
      setInsight({
        intents: initialInsight.intents,
        samples: initialInsight.samples,
      })
    );
    if (selectedTab === "intentTag") {
      setSelectedIntentList(initialIntentTagList);
    } else {
      setSelectedIntentList(initialIntentList);
    }
  };

  const handleClickChart = (params) => {
    highlightedSeriesIndex = highlightedSeriesIndex === params.seriesIndex ? null : params.seriesIndex;
    const instance = chartRef?.getEchartsInstance();
    instance.setOption(instance.getOption());

    dispatch(
      setInsightData(highlightedSeriesIndex != null && highlightedSeriesIndex > -1 ? params?.data?.objItem : null)
    );
  };

  const handleChangeSearchIntent = (intent) => {
    setSelectedIntentList(intent);
  };

  const handleClickCancel = () => {
    HistoryHelper.goBack(`/chatbot/intent/`, { scope: "dashboard" });
  };
  useEffect(() => {
    if (selectedTab === "intentTag") {
      setSelectedIntentList(intentTagList);
    } else {
      setSelectedIntentList(intentList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const parentName = [
    {
      label: "Intent",
      url: "/chatbot/intent/",
    },
    {
      label: `Intent Distribution Map`,
    },
  ];
  return (
    <StyledContainer
      description={"Here you can view your intent distribution map to detect the similarity between intents."}
      name="Chatbot"
      parentName={parentName}
    >
      <PRPage size="xl">
        <Row className="g-2">
          {/* <Col xs={4}>
          <PRButton onClick={handleRecalculate}>Recalculate zoomed area</PRButton>
        </Col> */}

          {/* <Col xs={4}>
            <FormGroup switch className="mt-1 d-flex align-items-center">
              <PRInput
                onChange={handleChangeChartOption("markAreaVisible")}
                type="switch"
                checked={chartCustomOptions.markAreaVisible}
              />
              <Label size="md">Mark Area</Label>
            </FormGroup>
          </Col> */}
          <Col xs={4}>
            <FormGroup switch className="mt-1 d-flex align-items-center">
              <PRInput
                checked={chartCustomOptions.showIntents}
                type="switch"
                onChange={handleChangeChartOption("showIntents")}
              />
              <Label size="md">Show Intents</Label>
            </FormGroup>
          </Col>
          <Col xs={4}>
            <FormGroup switch className="mt-1 d-flex align-items-center">
              <PRInput
                checked={chartCustomOptions.showSamples}
                type="switch"
                onChange={handleChangeChartOption("showSamples")}
              />
              <Label size="md">Show Samples</Label>
            </FormGroup>
          </Col>
          <Col xs={4}>
            <FormGroup switch className="mt-1 d-flex align-items-center">
              <PRInput
                checked={chartCustomOptions.showConvexHull}
                type="switch"
                onChange={handleChangeChartOption("showConvexHull")}
              />
              <Label size="md">Show Convex Hull</Label>
            </FormGroup>
          </Col>
        </Row>
        <Row className="chart-container mt-4">
          <Col xs={8}>
            <PRChart
              ref={setChartRef}
              notMerge
              height="70vh"
              option={chartOptions}
              onEvents={{
                click: handleClickChart,
                mouseover: function (params) {
                  const instance = chartRef?.getEchartsInstance();
                  if (isShiftPressed.current) {
                    highlightedSeriesIndex = params.seriesIndex;
                    instance.setOption(instance.getOption());
                  }
                },
                mouseout: function (params) {
                  const instance = chartRef?.getEchartsInstance();
                  if (isShiftPressed.current) {
                    instance.setOption(instance.getOption());
                    highlightedSeriesIndex = null;
                  }
                },
                brushselected: function (params) {
                  // params.areas içinde seçim alanları hakkında bilgi var
                  // params.batch[0].selected içinde hangi serilerin ve hangi veri noktalarının seçildiği hakkında bilgi var

                  const instance = chartRef?.getEchartsInstance();
                  const seriesList = instance.getOption().series;
                  const area = params.batch[0].areas[0];
                  const range = area
                    ? area.brushType === "rect"
                      ? rectangleToLassoCoords(
                          area.coordRange[0][0],
                          area.coordRange[1][0],
                          area.coordRange[0][1],
                          area.coordRange[1][1]
                        )
                      : area.coordRange
                    : [];
                  const selected = [];

                  for (const series of seriesList) {
                    for (const data of series.data) {
                      if (isPointInPolygon(data.value, range)) {
                        selected.push(series);
                      }
                    }
                  }
                  brushSeriesRef.current = !area ? null : selected;
                },
              }}
            />
          </Col>
          <Col xs={4}>
            <PRButton className="w-100 mb-4 " onClick={handleSelectDeselectAll}>
              Select/Deselect All
            </PRButton>
            <div className="d-flex align-items-center gap-2 mb-2">
              <PRButton className="w-100 " onClick={handleRecalculate}>
                Recalculate by Selected
              </PRButton>
              <PRButton className=" " onClick={handleRecalculateReset}>
                Reset
              </PRButton>
            </div>
            <SelectedSeriesItem />
            <PRTab
              className="mb-2"
              tab={selectedTab}
              tabList={[
                { id: "intent", label: "Intent" },
                { id: "intentTag", label: "Intent Tag" },
              ]}
              onChange={setSelectedTab}
            />
            {selectedTab === "intent" && (
              <PRSelect
                isMulti
                id="entityType"
                isClearable={false}
                labelSelector="text"
                name="entityType"
                options={initialIntentList}
                placeholder="Search intent..."
                value={selectedIntentList}
                valueSelector="id"
                onChange={handleChangeSearchIntent}
              />
            )}
            {selectedTab === "intentTag" && (
              <PRSelect
                isMulti
                id="entityType"
                isClearable={false}
                labelSelector="text"
                name="entityType"
                options={initialIntentTagList}
                placeholder="Search intent tag..."
                value={selectedIntentList}
                valueSelector="id"
                onChange={handleChangeSearchIntent}
              />
            )}
          </Col>
        </Row>
        <div className="d-flex justify-content-end mt-2">
          <PRButton outline color="primary" onClick={handleClickCancel}>
            Cancel
          </PRButton>
        </div>
      </PRPage>
    </StyledContainer>
  );
}
