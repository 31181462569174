import PRSwitch from "~components/Generic/PRSwitch";

import IntentDistributionMap from "./IntentDistributionMap";
import ScenarioManagerEdit from "./ScenarioManagerEdit";
import ScenarioManagerList from "./ScenarioManagerList";

function ScenarioManager() {
  const routes = [
    { path: "/chatbot/intent/distribution-map/", component: IntentDistributionMap },
    { path: "/chatbot/intent/form/:id?", component: ScenarioManagerEdit },
    { path: "/chatbot/intent/", component: ScenarioManagerList },
    { path: "/chatbot/", to: "chatbot/intent/", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}

export default ScenarioManager;
