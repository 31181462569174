import { useEffect, useMemo, useRef, useState } from "react";

import classNames from "classnames";
import { parsePhoneNumberWithError } from "libphonenumber-js";
import { useTranslation } from "react-i18next";
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap";

import { countryList } from "~constants";
import Utils from "~helpers/Utils";

import PRFormFeedback from "../PRFormFeedback";
import PRInput from "../PRInput";
import PRSelect from "../PRSelect";
import PRTooltip from "../PRTooltip";

import "./style.scss";

const ImageLoader = ({ src, ...rest }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    });

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  const handleImageLoad = (e) => {
    setLoaded(true);
  };

  return <img ref={imageRef} src={isVisible || loaded ? src : ""} onLoad={handleImageLoad} {...rest} />;
};

const { options, defaultCountry } = (() => {
  const list = [];
  let defaultCountry = null;
  for (const country of countryList) {
    const c = {
      label: (
        <div className="d-flex align-items-center">
          <ImageLoader
            alt={country.name}
            className="me-2"
            loading="lazy"
            src={Utils.getFlagUrlByIsoCode(country?.code.toLowerCase())}
            width={24}
          />
          <span>{country.name}</span>
        </div>
      ),
      value: country.code, //used name instead of code for search by name. country.code,
    };
    list.push(c);
    const tr = countryList.find((x) => x.code === "TR");
    if (c.value === tr.code) {
      defaultCountry = c.value;
    }
  }
  return { options: list, defaultCountry };
})();

export default function PRPhoneNumber({
  onChange,
  size,
  disabled,
  render,
  showPlainOnFail,
  value: valueProp,
  invalid,
  inlineExpander,
  fullWidth,
  className,
  ...props
}) {
  const [country, setCountry] = useState(!valueProp ? defaultCountry : null);
  const [isOpen, setIsOpen] = useState(false);
  const [portalRef, setPortalRef] = useState(null);
  const [portalRef2, setPortalRef2] = useState(null);
  const { t } = useTranslation();
  /** @type {[import("libphonenumber-js").PhoneNumber]} */
  const [parsedData, setParsedData] = useState(null);

  useEffect(() => {
    if (portalRef && !isOpen) {
      setPortalRef(null);
    }
  }, [isOpen, portalRef]);

  useEffect(() => {
    let parsed;
    try {
      parsed = parsePhoneNumberWithError(valueProp, country);
    } catch (error) {}
    let parsedDataObj = {
      ...parsed,
      formattedNational: parsed?.formatNational(),
      formattedInternational: parsed?.formatInternational(),
      isValidNumber: parsed?.isValid(),
    };
    if (parsed?.country) {
      setCountry(parsed?.country);
    } else {
      parsedDataObj = {};
    }
    setParsedData(parsedDataObj);
  }, [valueProp, country]);

  const selectedCountry = useMemo(() => {
    return countryList.find((x) => x.code === country);
  }, [country]);

  const handleChange = (e, countryCode) => {
    let parsed;
    const isCountryChangedFromSelect = e === null;
    const value = e?.target?.value ?? valueProp;
    const countryCodeStr = countryCode ?? country;
    if (countryCode) {
      setCountry(countryCode);
    }
    try {
      parsed = parsePhoneNumberWithError(value, countryCodeStr);
    } catch (error) {}

    const matchedCountry = countryList.find((x) => x.code === countryCodeStr);
    const callingCode =
      isCountryChangedFromSelect && matchedCountry
        ? matchedCountry?.dial_code.replace("+", "")
        : parsed?.countryCallingCode;

    onChange({
      country: countryCode,
      dialCode: parsed?.countryCallingCode,
      nationalNumber: parsed?.nationalNumber,
      value: parsed?.nationalNumber ? `+${callingCode}${parsed?.nationalNumber}` : value,
      isValidNumber: parsed?.isValid(),
    });
  };

  const handleChangeSelect = (newCountry) => {
    handleChange(null, newCountry);
    setIsOpen(false);
  };

  const handleFilterOptions = (option, rawInput) => {
    const country = countryList.find((x) => x.code === option?.value);
    if (!country) return false;
    return country.name.toLowerCase().includes(rawInput.toLowerCase());
  };

  if (render) {
    if (!parsedData?.formattedInternational) {
      if (showPlainOnFail) {
        return valueProp;
      }
      return "";
    }
    return (
      <PRTooltip
        title={
          <Row className="gx-2 align-items-center w-100 m-0 text-nowrap">
            <Col className="d-flex align-items-center" xs="auto">
              <img
                alt={selectedCountry?.name}
                className="me-2"
                loading="lazy"
                src={Utils.getFlagUrlByIsoCode(selectedCountry?.code)}
                width={20}
              />
              {selectedCountry?.name}
            </Col>
            <Col xs>{`${parsedData?.formattedInternational}`}</Col>
          </Row>
        }
      >
        <span>{`${parsedData?.formattedInternational}`}</span>
      </PRTooltip>
    );
  }
  return (
    <div className={classNames("pr-phone-number", className, { "w-100": fullWidth })}>
      <div className=" d-flex align-items-start w-100">
        {portalRef2 && (
          <Dropdown
            className={classNames({
              danger: invalid,
              sm: size === "sm",
            })}
            isOpen={isOpen}
            toggle={() => setIsOpen((prev) => !prev)}
          >
            <DropdownToggle color="transparent" disabled={disabled} size={size}>
              <div className="d-flex align-items-center">
                <span>{selectedCountry?.dial_code}</span>
              </div>
            </DropdownToggle>
            <DropdownMenu
              className="phone-dropdown-menu"
              container={inlineExpander && portalRef2}
              flip={!inlineExpander}
            >
              <div className="select-wrapper">
                {portalRef && (
                  <PRSelect
                    autoFocus
                    isPrimitiveValue
                    menuIsOpen
                    backspaceRemovesValue={false}
                    components={{ IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    filterOption={handleFilterOptions}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuPortal={portalRef}
                    options={options}
                    placeholder={t("component.placeholder.search")}
                    tabSelectsValue={false}
                    value={country}
                    onChange={handleChangeSelect}
                  />
                )}
                <div ref={setPortalRef} data-portal="true"></div>
              </div>
            </DropdownMenu>
          </Dropdown>
        )}
        <PRInput
          className="w-100"
          disabled={disabled}
          size={size}
          type="tel"
          value={parsedData?.formattedNational || valueProp || ""}
          onChange={handleChange}
          {...props}
          invalid={!!invalid}
        />
      </div>
      <div>
        <PRFormFeedback invalid={invalid} />
      </div>

      <div ref={setPortalRef2} className="position-relative" data-menu-portal="true"></div>
    </div>
  );
}
