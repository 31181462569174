import { useState } from "react";

import { withCardon } from "cardon";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Alert, Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import PRDropZone from "~components/Generic/PRDropZone";
import PRInput from "~components/Generic/PRInput";
import PRModal from "~components/Generic/PRModal";
import PRSelect from "~components/Generic/PRSelect";
import { fileTypeOptions } from "~constants";
import Utils from "~helpers/Utils";

const UploadModal = withCardon(
  function UploadModalContent({ get, title, noRename, enableTypeSelect, labelLimit = 30 }) {
    const { t } = useTranslation();
    const [isUploadVisible, setIsUploadVisible] = useState(false);

    const formik = useFormik({
      initialValues: {
        files: [],
        names: [],
        type: "File",
      },
      validationSchema: Yup.object({
        names: Yup.array().of(
          Yup.string()
            .required(t("component.formik.required").format(t("common.name")))
            .max(labelLimit, t("component.formik.maxLength").format(t("common.name"), labelLimit.toString()))
            .test("test-variable", t("component.formik.notValid").format(t("common.variable")), function (value) {
              const { valid, reason } = Utils.isValidPythonVariableName(value);
              if (valid) return true;
              return this.createError({ message: reason });
            })
        ),
      }),
      onSubmit: async (values) => {
        const newFiles = [];
        for (const file of values.files) {
          const displayName = values.names[values.files.indexOf(file)];

          newFiles.push({
            file,
            name: displayName,
            type: values.type,
          });
        }
        get(newFiles)();
      },
    });
    const handleUpload = async () => {
      if (!formik.values.files.length) {
        setIsUploadVisible(true);
        return;
      }
      setIsUploadVisible(false);
      formik.handleSubmit();
    };

    const handleFileChange = (files) => {
      setIsUploadVisible(false);
      formik.setFieldValue("files", files);
      formik.setFieldValue(
        "names",
        files.map((file) => Utils.convertTextToPythonVariableName(file.name).substring(0, labelLimit))
      );
    };

    const toggle = () => setIsUploadVisible(!isUploadVisible);

    const handleChangeFileName = (index) => (e) => {
      const value = e.target.value;
      const newNames = [...formik.values.names];
      newNames[index] = value;
      formik.setFieldValue("names", newNames);
    };

    return (
      <PRModal title={title || "Upload File"} onClick={handleUpload} onClose={get(false)}>
        <Alert color="warning" isOpen={isUploadVisible} toggle={toggle}>
          {t("component.uploadModal.fileSelectAlert")}
        </Alert>

        <PRDropZone
          onFileChange={handleFileChange}
          // accept={["image/*"]}
          className="mt-4"
          // inputEdit
        />
        {!noRename && (
          <Row className="mt-3 g-2">
            {formik.values.names.map((text, index) => (
              <Col key={index} xs={12}>
                <Label>
                  {t("common.file")}: {formik.values.files[index].name}
                </Label>
                <PRInput
                  invalid={formik.touched.names?.[index] && formik.errors.names?.[index]}
                  name={`names[${index}]`}
                  placeholder={t("component.uploadFile.fileNamePlaceholder")}
                  value={text}
                  onBlur={formik.handleBlur}
                  onChange={handleChangeFileName(index)}
                />
              </Col>
            ))}
          </Row>
        )}

        {enableTypeSelect && (
          <Row className="mt-3 g-2">
            <Col xs={12}>
              <Label>{t("common.type")}:</Label>
              <PRSelect
                isPrimitiveValue
                invalid={formik.touched.type && formik.errors.type}
                isClearable={false}
                options={fileTypeOptions}
                value={formik.values.type}
                onChange={formik.handleChange}
              />
            </Col>
          </Row>
        )}
      </PRModal>
    );
  },
  { destroyOnHide: true }
);
export default UploadModal;
