import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { PalIconButton } from "@palamar/fe-library";
import { withCardon } from "cardon";
import classNames from "classnames";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import {
  MdAdd,
  MdBlock,
  MdCode,
  MdDelete,
  MdEdit,
  MdHelp,
  MdInsertInvitation,
  MdMoreTime,
  MdPerson,
  MdRemoveCircleOutline,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Col, Label, Row } from "reactstrap";

import { Add, Schedule } from "@mui/icons-material";
import { Box, Switch } from "@mui/material";

import useLoading from "~common/hooks/useLoading";
import { RenderBreadcrumbActions } from "~components/Breadcrumb";
import PRButton from "~components/Generic/PRButton";
import PRCalendar from "~components/Generic/PRCalendar";
import PRContainer from "~components/Generic/PRContainer";
import PRModal from "~components/Generic/PRModal";
import PRPage from "~components/Generic/PRPage";
import PRSelect from "~components/Generic/PRSelect";
import PRTab from "~components/Generic/PRTab";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import LowCodeEditorModal from "~components/LowCodeEditor";
import PalTooltip from "~components/mui/PalTooltip";
import {
  apiUrlOrganization,
  lowCodeModuleType,
  organizationResourceAttendanceStatus,
  organizationResourceAttendanceStatusMap,
  organizationVisibilityType,
  userRole,
} from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import FileHelper from "~helpers/FileHelper";
import LoadingHelper from "~helpers/LoadingHelper";
import { CustomerListCellRenderer } from "~pages/Organization/Customer/CustomerList";
import AddBulkReservationSlotsModal from "~pages/Organization/Resources/AddBulkReservationSlotsModal";
import AddEditOrganizationResourceModal from "~pages/Organization/Resources/AddEditOrganizationResourceModal";
import AddEditReservationSlotModal, {
  AttendanceButton,
  AttendanceStatusDot,
  EditReservationSlotDateModal,
} from "~pages/Organization/Resources/AddEditReservationSlotModal";
import {
  addBlacklistResourceUser,
  deleteResource,
  getDynamicReservationList,
  getMemberFieldFormatList,
  getReservationActions,
  getReservations,
  getResources,
  getSlots,
  removeBlacklistResourceUser,
  setResource,
} from "~store/organization/actions";
import {
  selectDynamicReservationList,
  selectMemberFieldFormat,
  selectReservations,
  selectResources,
  selectSlots,
} from "~store/organization/selectors";
import { selectCurrentProject, selectUserInfo } from "~store/user/selectors";

import AddOrEditDynamicReservationModal, {
  DynamicSlotAddEditModal,
  ListDynamicReservationsModal,
} from "../AddOrEditDynamicReservation";

import "./style.scss";

function resourceToSlotMapper(resourceList) {
  const mappedReservedView = (resourceList || []).map((reservation) => {
    let lowestStartDate;
    let highestEndDate;
    let lowestAvailableCount;
    for (const s of reservation.slot) {
      const startDate = DateHelper.getDateTimeLocal(s.start_date);
      const endDate = DateHelper.getDateTimeLocal(s.end_date);
      if (!lowestStartDate || startDate.isBefore(lowestStartDate)) {
        lowestStartDate = startDate;
      }
      if (!highestEndDate || endDate.isAfter(highestEndDate)) {
        highestEndDate = endDate;
      }
      if (!lowestAvailableCount || s.available_slots < lowestAvailableCount) {
        lowestAvailableCount = s.available_slots;
      }
    }
    return {
      ...reservation.slot[0],
      available_slots: lowestAvailableCount,
      start_date: lowestStartDate,
      end_date: highestEndDate,
      reservation,
    };
  });

  return mappedReservedView;
}

function slotToEventMapper(slotRaw, { dynamic, calendarView, reservedView, resource, onRefreshCalendar }) {
  const slot = { ...slotRaw };
  const isAvailable = slot.available_slots > 0;
  const total = slot.total || slot.available_slots;
  const reserved = total - slot.available_slots;
  const ratio = `${reserved}/${total}`;
  const eventIdentifier = isAvailable
    ? ratio + " " + i18next.t("common.available")
    : ratio + " " + i18next.t("common.unavailable");

  const startDate = DateHelper.getDateTimeLocal(slot.start_date);
  const endDate = DateHelper.getDateTimeLocal(slot.end_date);

  const allDay = startDate.clone().add(1, "day").isSame(slot.end_date, "milliseconds");
  const customer = slot.reservation?.customer;
  let eventTitle = eventIdentifier;
  let eventTitleTooltip = eventIdentifier;

  // const insideUserIdentifier = "✓";
  // if (identifierValue) {
  //   eventTitle += ` ${insideUserIdentifier}`;
  // }
  if (reservedView) {
    if (slot.reservation?.reserver) {
      let fullName = slot.reservation?.reserver?.safe_information?.full_name;
      if (slot.reservation?.reserver?.safe_information?.ad && slot.reservation?.reserver?.safe_information?.soyad) {
        fullName = `${slot.reservation?.reserver?.safe_information?.ad} ${slot.reservation?.reserver?.safe_information?.soyad}`;
      }
      const email = slot.reservation?.reserver?.safe_information?.email;

      const customerData = `${fullName} (${email})`;
      eventTitle = customerData;
    } else if (slot.reservation?.customer) {
      let fullName = slot.reservation?.customer?.full_name;
      if (slot.reservation?.customer?.ad && slot.reservation?.customer?.soyad) {
        fullName = `${slot.reservation?.customer?.ad} ${slot.reservation?.customer?.soyad}`;
      }
      const email = slot.reservation?.customer?.email;
      const customerData = `*${fullName} (${email})`;
      eventTitle = customerData;
    }

    eventTitleTooltip = eventTitle;

    eventTitle = (
      <Box alignItems="center" display="flex">
        <Box mr={0.25}>
          {/* <AttendanceStatusDot mini isAttended={slot.reservation?.is_attended} /> */}
          <AttendanceButton
            renderButton={(props) => {
              return <AttendanceStatusDot mini isAttended={slot.reservation?.is_attended} {...props} />;
            }}
            reservation={slot.reservation}
            resource={resource}
            onUpdated={onRefreshCalendar}
          />
        </Box>
        {eventTitle}
      </Box>
    );
  }
  const isBlacklisted = resource?.blacklist?.some(
    (cus) => cus?.id === (slot.reservation?.reserver?.id || slot.reserver?.id)
  );

  if (dynamic) {
    //reservation item instead of slot

    if (slot.is_blocking) {
      const blockedMinutes = endDate.diff(startDate, "minutes");
      const blockedFormattedStr = DateHelper.formatMoment(blockedMinutes, "minutes", "auto");
      eventTitle =
        i18next.t("dashboard.reservations.blocked").format(blockedFormattedStr) + (slot.note ? ` (${slot.note})` : "");
      eventTitleTooltip = eventTitle;

      slot.reservation = { ...slot };
    } else if (slot.dynamic) {
      if (slot.reserver) {
        let fullName = slot?.reserver?.safe_information?.full_name;
        if (slot?.reserver?.safe_information?.ad && slot?.reserver?.safe_information?.soyad) {
          fullName = `${slot?.reserver?.safe_information?.ad} ${slot?.reserver?.safe_information?.soyad}`;
        }
        const email = slot?.reserver?.safe_information?.email;

        const customerData = `${fullName} (${email})`;
        eventTitle = customerData;
      }
      eventTitleTooltip = eventTitle;

      eventTitle = (
        <Box alignItems="center" display="flex">
          <Box mr={0.25}>
            {/* <AttendanceStatusDot mini isAttended={slot.reservation?.is_attended} /> */}
            <AttendanceButton
              renderButton={(props) => {
                return <AttendanceStatusDot mini isAttended={slot.reservation?.is_attended} {...props} />;
              }}
              reservation={slot}
              resource={resource}
              onUpdated={onRefreshCalendar}
            />
          </Box>
          {eventTitle}
        </Box>
      );

      slot.blacklist = isBlacklisted;
      slot.reservation = { ...slot };
    } else {
      eventTitle = "";
      eventTitleTooltip = "";
      if (calendarView === "month") {
        eventTitle = `${i18next.t("common.available")}: ${startDate.format("HH:mm")} - ${endDate.format("HH:mm")}`;
      }
    }
  }
  return {
    id: slot.id,
    reservation: slot.reservation,
    title: eventTitle,
    tooltip: eventTitleTooltip,
    available: isAvailable,
    availableSlots: slot.available_slots,
    totalSlots: total,
    customer: customer,
    blacklist: isBlacklisted,
    isBlocking: slot.is_blocking,
    start: startDate.toDate(),
    end: endDate.toDate(),
    allDay,
    start_date: startDate,
    end_date: endDate,
    dynamic: slot.dynamic,
  };
}

function BlacklistViewContent({ get, blacklist, resource }) {
  const tableRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [blacklistList, setBlacklistList] = useState(blacklist || []);
  const memberFieldFormat = useSelector(selectMemberFieldFormat);
  const memberDataFields = memberFieldFormat?.member_data_fields;
  const currentProject = useSelector(selectCurrentProject);
  const [tab, setTab] = useState("members");
  const [isActionPerformed, setIsActionPerformed] = useState(false);
  const [filterActions, setFilterActions] = useState(null);

  const columns = useMemo(() => {
    if (!memberDataFields) return [];

    const handleBlacklist = (row, isExistingInBlacklist) => async () => {
      if (!(await DialogHelper.showQuestionYesNo())) return;
      if (!isExistingInBlacklist) {
        await dispatch(addBlacklistResourceUser(currentProject.id, resource.id, row.id));
        setBlacklistList((prev) => [...prev, row]);
      } else {
        await dispatch(removeBlacklistResourceUser(currentProject.id, resource.id, row.id));
        setBlacklistList((prev) => prev.filter((cus) => cus.id !== row.id));
      }
      setIsActionPerformed(true);
      tableRef.current.refresh();
    };

    const fields = [];
    fields.unshift({
      label: "#",
      key: "id",
      order: -1,
    });
    if (memberDataFields.some((item) => item.visibility_type === organizationVisibilityType.PUBLIC)) {
      const memberOnlyFields = memberDataFields.map((item) => {
        const label = (
          <span
            className={classNames({
              "fw-semibold": item.identifier,
            })}
          >
            {item.display_name || item.name}
          </span>
        );

        return {
          label: label,
          key: `safe_information.${item.name}`,
          order: item.order,
          render: (obj, index, value) => (
            <CustomerListCellRenderer cellKey={item.name} memberDataFields={memberDataFields} value={value} />
          ),
        };
      });
      fields.push(...memberOnlyFields);
    }

    fields.push({
      label: t("common.actions"),
      key: "actions",
      actions: true,
      fixed: "right",
      render: (row) => {
        const isExistingInBlacklist = blacklistList.some((cus) => cus.id === row.id);
        return (
          <div className="d-flex justify-content-center">
            <PRButton
              outline
              className="ms-1"
              color={!isExistingInBlacklist ? "danger" : "warning-600"}
              icon={!isExistingInBlacklist ? MdBlock : MdRemoveCircleOutline}
              size="sm"
              tooltipText={!isExistingInBlacklist ? "Add to Blacklist" : "Remove from Blacklist"}
              onClick={handleBlacklist(row, isExistingInBlacklist)}
            />
          </div>
        );
      },
    });

    return fields;
  }, [memberDataFields, dispatch, currentProject.id, resource.id, blacklistList, t]);

  const handleFilterSetActions = useCallback((actions) => {
    setFilterActions(actions);
  }, []);

  return (
    <PRModal
      size="xl"
      submitText={""}
      title={t("dashboard.reservations.blacklist")}
      onClose={get(isActionPerformed)}
      // onDelete={reservation?.id ? handleClickDeleteReservation : undefined}
    >
      <PRTab
        className="mb-2 mt-4"
        tab={tab}
        tabList={[
          {
            id: "members",
            label: t("dashboard.reservations.members"),
          },
          {
            id: "blacklist",
            label: t("dashboard.reservations.blacklistedMembers"),
          },
        ]}
        onChange={setTab}
      />
      {tab === "members" && (
        <>
          <div className="my-2 d-flex align-items-center justify-content-between">
            <div></div>
            {filterActions?.length && <RenderBreadcrumbActions actions={filterActions} />}
          </div>
          <PRTable
            ref={tableRef}
            inline
            columns={columns}
            customSetActions={handleFilterSetActions}
            url={apiUrlOrganization.getReservationMembers.format(currentProject.id)}
          />
        </>
      )}
      {tab === "blacklist" && <PRTable ref={tableRef} inline columns={columns} data={blacklistList} />}
    </PRModal>
  );
}

const BlacklistView = withCardon(BlacklistViewContent, { destroyOnHide: true });

export default function CreateResource() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentProject = useSelector(selectCurrentProject);
  const resources = useSelector(selectResources);
  const slots = useSelector(selectSlots);
  const reservations = useSelector(selectReservations);
  const userInfo = useSelector(selectUserInfo);
  const dynamicReservationList = useSelector(selectDynamicReservationList);

  const memberFieldFormat = useSelector(selectMemberFieldFormat);
  const memberDataFields = memberFieldFormat?.member_data_fields;
  const sortedMemberDataFields = useMemo(() => {
    return [...(memberDataFields || [])]?.sort((a, b) => a.order - b.order);
  }, [memberDataFields]);
  const publicMemberDataFields = useMemo(() => {
    return sortedMemberDataFields?.filter((item) => item.visibility_type === organizationVisibilityType.PUBLIC);
  }, [sortedMemberDataFields]);

  const tableDetailRef = useRef(null);

  const [selectedResourceId, setSelectedResourceId] = useState("");
  const [loadingResources, enqueue] = useLoading(true);
  const [calendarView, setCalendarView] = useState("month");
  const [dateRange, setDateRange] = useState();
  const [showAll, setShowAll] = useState(false);
  const [calendarRef, setCalendarRef] = useState();
  const [tab, setTab] = useState("calendar");
  const [reservedView, setReservedView] = useState(false);
  const [slotActionsInfo, setSlotActionsInfo] = useState({});
  const [accordionState, setAccordionState] = useState({
    1: true,
    2: false,
  });

  const hasProjectFullRight = [userRole.admin, userRole.organizationManager].some((role) =>
    currentProject?.permissions?.includes(role)
  );
  const hasFullAccess = userInfo.is_superuser || hasProjectFullRight;

  // Fetch initial once for blacklist and AddEditReservationSlot.
  useEffect(() => {
    dispatch(getMemberFieldFormatList(currentProject.id));
  }, [dispatch, currentProject?.id]);

  const handleToggleSlotView = (e) => {
    setReservedView(e.target.checked);
  };
  const handleClickAccordion = (id) => () => {
    setAccordionState({
      ...(id === 1 && {
        1: !accordionState[1],
        2: false,
      }),
      ...(id === 2 && {
        1: false,
        2: !accordionState[2],
      }),
    });
  };

  useEffect(() => {
    if (selectedResourceId) {
      dispatch(getReservationActions(currentProject.id, selectedResourceId))
        .then((response) => {
          setSlotActionsInfo(response);
        })
        .catch(() => {
          setSlotActionsInfo({});
        });
    }
  }, [dispatch, selectedResourceId, currentProject.id]);

  const selectedResource = useMemo(() => {
    if (!selectedResourceId) return null;
    return resources.find((resource) => resource.id === selectedResourceId);
  }, [resources, selectedResourceId]);

  const handleCalendarRef = useCallback((ref) => {
    setCalendarRef(ref);
  }, []);

  const getSlotsByDateRange = useCallback(
    async (dateObj, fetchOptions) => {
      let rangeByRef;
      let response = {
        results: [],
      };
      if (calendarRef && !dateObj) {
        rangeByRef = calendarRef.getView().range(calendarRef.props.date, { localizer: calendarRef.props.localizer });
      }
      const { start, end } = dateObj || rangeByRef || {};
      // console.log("start", start, "end", end);
      if (selectedResource?.allow_dynamic_reservation) {
        try {
          const responseDynamic = await dispatch(
            getDynamicReservationList(
              currentProject.id,
              selectedResourceId,
              {
                start_date__gte: start,
                end_date__lte: end,
                limit: 99999,
              },
              fetchOptions
            )
          );
          response.results = Array.isArray(responseDynamic?.results)
            ? responseDynamic.results
            : Array.isArray(responseDynamic)
              ? responseDynamic
              : [];
        } catch (error) {}
      }
      const slotResponse = await dispatch(
        getSlots(
          selectedResourceId,
          currentProject.id,
          { start_date__gte: start, end_date__lte: end, limit: 99999 },
          fetchOptions
        )
      );
      if (slotResponse.results) {
        response.results.push(...slotResponse.results);
      }
      return response;
    },
    [calendarRef, dispatch, selectedResourceId, currentProject, selectedResource?.allow_dynamic_reservation]
  );

  const getReservationsByDateRange = useCallback(
    (dateObj, fetchOptions) => {
      let rangeByRef;
      if (calendarRef && !dateObj) {
        rangeByRef = calendarRef.getView().range(calendarRef.props.date, { localizer: calendarRef.props.localizer });
      }
      const { start, end } = dateObj || rangeByRef || {};
      // console.log("start", start, "end", end);
      return dispatch(
        getReservations(
          selectedResourceId,
          currentProject.id,
          {
            start_date__gte: start.toISOString(),
            end_date__lte: end.toISOString(),
            limit: 99999,
          },
          fetchOptions
        )
      );
    },
    [calendarRef, dispatch, selectedResourceId, currentProject]
  );

  const { calendarEvents, backgroundEvents } = useMemo(() => {
    let activeSlots = slots?.length ? [...slots] : [];
    if (selectedResource?.allow_dynamic_reservation) {
      let dynamicList = [...(dynamicReservationList || [])];
      if (calendarView === "month") {
        activeSlots = [];
        dynamicList = dynamicList.filter((slot) => !slot.is_blocking);
      }
      if (dynamicList?.length > 0) {
        activeSlots.push(...dynamicList);
      }
    }
    if (reservedView) {
      const resourceSlots = resourceToSlotMapper(reservations);
      activeSlots = resourceSlots || [];
    }

    if (!activeSlots) return [];
    const mappedCalendarEvents = activeSlots.map((slot) =>
      slotToEventMapper(slot, {
        dynamic: selectedResource?.allow_dynamic_reservation,
        reservedView,
        resource: selectedResource,
        calendarView: calendarView,
        onRefreshCalendar: () => {
          if (reservedView) {
            getReservationsByDateRange(dateRange);
          } else {
            getSlotsByDateRange(dateRange);
          }
        },
      })
    );
    let calendarEventList = mappedCalendarEvents;
    let backgroundEventList = [];

    if (selectedResource?.allow_dynamic_reservation) {
      calendarEventList = mappedCalendarEvents.filter((event) => event.dynamic);
      backgroundEventList = mappedCalendarEvents.filter((event) => !event.dynamic);
    }
    return {
      calendarEvents: calendarEventList,
      backgroundEvents: backgroundEventList,
    };
  }, [
    dynamicReservationList,
    reservedView,
    selectedResource,
    calendarView,
    getSlotsByDateRange,
    getReservationsByDateRange,
    dateRange,
    slots,
    reservations,
    t,
  ]);

  const fetchEvents = useCallback(
    async (modalDateRange) => {
      let reservationsResult = [];
      let slotsResult = [];
      if (reservedView) {
        reservationsResult =
          (
            await getReservationsByDateRange(modalDateRange, {
              onSuccess: () => {},
            })
          )?.results || [];
      } else {
        slotsResult =
          (
            await getSlotsByDateRange(modalDateRange, {
              onSuccess: () => {},
            })
          )?.results || [];
      }

      let activeSlots = slotsResult || [];
      if (reservedView) {
        const resourceSlots = resourceToSlotMapper(reservationsResult);

        activeSlots = resourceSlots.map((event) => event?.reservation?.slot || []).flat() || [];
        //filter by modalDateRange's start and end
        activeSlots = activeSlots.filter((slot) => {
          const startDate = DateHelper.getDateTime(slot.start_date);
          return (
            startDate.isBetween(modalDateRange.start, modalDateRange.end) || startDate.isSame(modalDateRange.start)
          );
        });

        //remove duplicate by id
        activeSlots = activeSlots.filter((slot, index, self) => self.findIndex((s) => s.id === slot.id) === index);
      }

      if (!activeSlots) return [];
      const mappedCalendarEvents = activeSlots.map((slot) =>
        slotToEventMapper(slot, {
          dynamic: selectedResource?.allow_dynamic_reservation,
          reservedView,
          resource: selectedResource,
          onRefreshCalendar: () => {
            if (reservedView) {
              getReservationsByDateRange(dateRange);
            } else {
              getSlotsByDateRange(dateRange);
            }
          },
        })
      );

      return mappedCalendarEvents;
    },
    [getReservationsByDateRange, getSlotsByDateRange, reservedView, dateRange, selectedResource, t]
  );
  // useEffect(() => {
  //   enqueue(dispatch(getResource(selectedResourceId, currentProject.id)));
  // }, [dispatch, currentProject.id, enqueue, selectedResourceId]);

  useEffect(() => {
    enqueue(dispatch(getResources(currentProject.id))).then(({ results = [] }) => {
      setSelectedResourceId(results?.[0]?.id);
      if (!results?.[0]?.id) {
        dispatch(setResource({}));
      }
    });
    // dispatch(getMembers(currentProject.id)); //for 'Add/Edit modal'
  }, [dispatch, currentProject.id, enqueue]);

  const resourceOptions = useMemo(() => {
    const mappedData = resources.map((item) => ({
      value: item.id,
      label: `${item.name} (${item.resource_type} - ${item.id})`,
    }));
    mappedData.reverse();
    return mappedData;
  }, [resources]);

  const handleClickAddResource = async () => {
    const result = await AddEditOrganizationResourceModal.show();
    if (result) {
      await dispatch(getResources(currentProject.id));
      setSelectedResourceId(result.id);
    }
  };
  const handleClickEditResource = async () => {
    const result = await AddEditOrganizationResourceModal.show({ resourceId: selectedResourceId });
    if (result) {
      dispatch(getResources(currentProject.id));
      tableDetailRef.current.refresh();
    }
  };

  const handleClickOpenBlacklist = async () => {
    const result = await BlacklistView.show({ blacklist: selectedResource?.blacklist, resource: selectedResource });
    if (result) {
      dispatch(getResources(currentProject.id));
      // tableDetailRef.current.refresh();
    }
  };
  const handleClickAddResourceSlots = async () => {
    const result = await AddBulkReservationSlotsModal.show({
      resourceId: selectedResourceId,
      dynamic: selectedResource?.allow_dynamic_reservation,
    });
    if (result) {
      dispatch(getResources(currentProject.id));
      if (reservedView) {
        getReservationsByDateRange(dateRange);
      } else {
        getSlotsByDateRange(dateRange);
      }
    }
  };
  const handleClickDeleteResource = async () => {
    if (!(await DialogHelper.showQuestionDelete())) {
      return;
    }
    await dispatch(deleteResource(selectedResourceId, currentProject.id));
    dispatch(getResources(currentProject.id));
    if (resources.length > 0) {
      setSelectedResourceId(resources[0].id);
    }
  };

  const handleClickShowGenerator = () => {};

  const handleClickAddSingleSlot = useCallback(
    (date) => async () => {
      const result = await AddEditReservationSlotModal.show({
        resource: selectedResource,
        date,
        fetchEvents: fetchEvents,
        reservedMode: reservedView,
        slotActionsInfo: slotActionsInfo,
        dynamic: selectedResource?.allow_dynamic_reservation,
      });
      if (result) {
        dispatch(getResources(currentProject.id));
        if (reservedView) {
          getReservationsByDateRange(dateRange);
        } else {
          getSlotsByDateRange(dateRange);
        }
      }
    },
    [
      selectedResource,
      currentProject.id,
      getSlotsByDateRange,
      getReservationsByDateRange,
      dateRange,
      dispatch,
      reservedView,
      fetchEvents,
      slotActionsInfo,
    ]
  );

  const handleClickShowDynamicReservations = (date) => async () => {
    const result = await ListDynamicReservationsModal.show({
      slotActionsInfo: slotActionsInfo,
      resource: selectedResource,
      fetchEvents: fetchEvents,
      date: date,
    });
    if (result) {
      dispatch(getResources(currentProject.id));
      if (reservedView) {
        getReservationsByDateRange(dateRange);
      } else {
        getSlotsByDateRange(dateRange);
      }
    }
  };

  const handleClickAddReservation = (dateParam) => async () => {
    //start,end fields are original slot start and end date
    const date = DateHelper.getDateTimeLocal(dateParam);
    date.utcOffset(0, true);
    // const endOfDay = DateHelper.getDateTimeLocal(item.end);
    const beginOfDay = date.clone().startOf("day");
    const endOfDay = date.clone().endOf("day").subtract(1, "second");

    const result = await AddOrEditDynamicReservationModal.show({
      fetchEvents,
      resource: selectedResource,
      slot: {
        start_date: DateHelper.getDateTimeLocal(date),
      },
      // defaultDate: slotStartDate,
      reservationDateRange: {
        start: beginOfDay,
        end: endOfDay,
      },
      reservation: null,
      slotActionsInfo: slotActionsInfo,
    });
    if (result) {
      dispatch(getResources(currentProject.id));
      if (reservedView) {
        getReservationsByDateRange(dateRange);
      } else {
        getSlotsByDateRange(dateRange);
      }
    }
  };

  const handleClickOpenAddSlot = (date) => async () => {
    const result = await DynamicSlotAddEditModal.show({
      resource: selectedResource,
      fetchEvents: fetchEvents,
      slotActionsInfo: slotActionsInfo,
      date: date,
    });
    if (result) {
      dispatch(getResources(currentProject.id));
      if (reservedView) {
        getReservationsByDateRange(dateRange);
      } else {
        getSlotsByDateRange(dateRange);
      }
    }
  };
  const handleClickEditSingleSlot = useCallback(
    (date) => async () => {
      const beginOfTheDay = DateHelper.getDateTimeLocal(date).startOf("day");
      const endOfTheDay = beginOfTheDay.clone().add(1, "day");
      const relatedEvents = calendarEvents.filter((slot) => {
        const startDate = DateHelper.getDateTime(slot.start);
        return startDate.isBetween(beginOfTheDay, endOfTheDay) || startDate.isSame(beginOfTheDay);
      });

      let expandedRelatedEvents = relatedEvents.map((event) => event?.reservation?.slot || []).flat();
      //remove duplicate by id
      expandedRelatedEvents = expandedRelatedEvents.filter(
        (slot, index, self) => self.findIndex((s) => s.id === slot.id) === index
      );
      const mappedRelatedEvents = expandedRelatedEvents.map((slot) =>
        slotToEventMapper(slot, { reservedView, resource: selectedResource })
      );

      const result = await AddEditReservationSlotModal.show({
        resource: selectedResource,
        events: reservedView ? mappedRelatedEvents : relatedEvents,
        fetchEvents: fetchEvents,
        date,
        reservedMode: reservedView,
        slotActionsInfo: slotActionsInfo,
        dynamic: selectedResource?.allow_dynamic_reservation,
      });
      if (result) {
        dispatch(getResources(currentProject.id));
        if (reservedView) {
          getReservationsByDateRange(dateRange);
        } else {
          getSlotsByDateRange(dateRange);
        }
      }
    },
    [
      selectedResource,
      currentProject.id,
      calendarEvents,
      getSlotsByDateRange,
      dateRange,
      dispatch,
      reservedView,
      getReservationsByDateRange,
      fetchEvents,
      slotActionsInfo,
      t,
    ]
  );

  // const presentCalendarEvents = useMemo(() => {
  //   return calendarEvents.filter((event) => {
  //     const end = DateHelper.getDateTimeLocal(event.end);
  //     const now = DateHelper.getDateTimeLocal();
  //     return end.isAfter(now);
  //   });
  // }, [calendarEvents]);

  const eventWrapperComponent = useCallback((props) => {
    const selected = props.selected;
    return (
      <div
        //add danger background bootstrap class
        className={classNames({
          "calendar-day-available": !props.event.customer && props.event.available,
          "calendar-day-scheduled-customer": props.event.customer && props.event.available,
          "calendar-day-selected": selected,
          "calendar-day-blacklist": props.event.blacklist,
          "calendar-day-blocked": props.event.isBlocking,
        })}
      >
        {props.children}
      </div>
    );
  }, []);
  const dateCellWrapperComponent = useCallback(
    function (props) {
      const childrenClassName = props.children.props.className;
      const cellDate = props.value;

      const relevantEvents = calendarEvents.filter((event) => {
        const eventDate = event.start;
        return DateHelper.getDateTimeLocal(eventDate).isSame(DateHelper.getDateTimeLocal(cellDate), "day");
      });

      const isToday = DateHelper.getDateTimeLocal(cellDate).isSame(DateHelper.getDateTimeLocal(new Date()), "day");
      const unavailableSlots = relevantEvents.filter((event) => !event.available);

      let totalAvailableTimeMinutes = 0;
      let reservedAvailableTimer = 0;
      if (selectedResource?.allow_dynamic_reservation) {
        const relatedSlots = slots.filter((slot) => {
          const startDate = DateHelper.getDateTimeLocal(slot.start_date);
          return startDate.isSame(DateHelper.getDateTimeLocal(cellDate), "day");
        });

        totalAvailableTimeMinutes = relatedSlots
          .map((slot) => {
            const startDate = DateHelper.getDateTimeLocal(slot.start_date);
            const endDate = DateHelper.getDateTimeLocal(slot.end_date);
            return endDate.diff(startDate, "minutes");
          })
          .reduce((acc, curr) => acc + curr, 0);

        const reservationList = relevantEvents.filter((event) => event.dynamic);
        reservedAvailableTimer = reservationList
          .map((slot) => {
            const startDate = DateHelper.getDateTimeLocal(slot.start_date);
            const endDate = DateHelper.getDateTimeLocal(slot.end_date);
            return endDate.diff(startDate, "minutes");
          })
          .reduce((acc, curr) => acc + curr, 0);
      }
      return (
        <div
          className={classNames(childrenClassName, {
            border: isToday,
            "border-secondary": isToday,
            "calendar-cell-wrapper": true,
          })}
        >
          <Row className="h-100 align-items-end justify-content-between">
            <Col xs="auto"></Col>
            <Col xs="auto">
              <div className="d-flex align-items-center">
                {!selectedResource?.allow_dynamic_reservation && calendarView === "month" && (
                  <>
                    {!!unavailableSlots?.length && (
                      <PRTooltip title={"Total scheduled meeting"}>
                        <div className="d-flex align-items-center font-size-13 fw-bold ms-1 mb-1 me-2 text-primary">
                          {unavailableSlots.length}
                          <MdPerson className="ms-1 " />
                        </div>
                      </PRTooltip>
                    )}
                    {!relevantEvents?.length && !reservedView && (
                      <PRButton
                        outline
                        className="me-1 mb-1"
                        color="success-600"
                        disabled={!selectedResourceId}
                        icon={MdAdd}
                        size="sm"
                        onClick={handleClickAddSingleSlot(cellDate)}
                      />
                    )}
                    {!!relevantEvents?.length && (
                      <PRButton
                        outline
                        className="me-1 mb-1"
                        color="primary"
                        disabled={!selectedResourceId}
                        icon={MdEdit}
                        size="sm"
                        onClick={handleClickEditSingleSlot(cellDate)}
                      />
                    )}
                  </>
                )}
              </div>
            </Col>

            {selectedResource?.allow_dynamic_reservation && !!totalAvailableTimeMinutes && calendarView === "month" && (
              <Col className="d-flex align-items-center justify-content-end w-100" xs="auto">
                {totalAvailableTimeMinutes - reservedAvailableTimer > 0 && (
                  <PalTooltip title="Add reservation">
                    <PalIconButton
                      outline
                      color="success"
                      disabled={!selectedResourceId}
                      size="small"
                      sx={{
                        mr: "auto",
                        ml: 0.25,
                      }}
                      onClick={handleClickAddReservation(cellDate)}
                    >
                      <Add fontSize="small" />
                    </PalIconButton>
                  </PalTooltip>
                )}
                <PalTooltip
                  title={t("dashboard.reservations.totalAvailableTime").format(
                    DateHelper.formatMoment(totalAvailableTimeMinutes - reservedAvailableTimer, "minutes", "auto"),
                    DateHelper.formatMoment(totalAvailableTimeMinutes, "minutes", "auto")
                  )}
                >
                  <Box
                    fontSize={10}
                    fontWeight="bold"
                    mr={1}
                    sx={{
                      color:
                        totalAvailableTimeMinutes === reservedAvailableTimer
                          ? (props) => props.palette.error.main
                          : (props) => props.palette.success.main,
                    }}
                  >
                    <Schedule fontSize="small" />
                    {totalAvailableTimeMinutes === reservedAvailableTimer
                      ? t("common.unavailable")
                      : `${DateHelper.formatMoment(
                          totalAvailableTimeMinutes - reservedAvailableTimer,
                          "minutes",
                          "auto"
                        )}`}
                  </Box>
                </PalTooltip>

                <PRButton
                  outline
                  className="me-1 mb-1"
                  color={"primary"}
                  disabled={!selectedResourceId}
                  icon={MdInsertInvitation}
                  size="sm"
                  tooltipText={t("dashboard.reservations.manageReservations")}
                  onClick={handleClickShowDynamicReservations(cellDate)}
                />
              </Col>
            )}
            {selectedResource?.allow_dynamic_reservation && !totalAvailableTimeMinutes && calendarView === "month" && (
              <Col className="d-flex align-items-center justify-content-end w-100" xs="auto">
                <PRButton
                  outline
                  className="me-1 mb-1"
                  color={"secondary"}
                  disabled={!selectedResourceId}
                  icon={MdMoreTime}
                  size="sm"
                  tooltipText={t("dashboard.reservations.addSlotTooltip")}
                  onClick={handleClickOpenAddSlot(cellDate)}
                />
              </Col>
            )}
          </Row>
        </div>
      );
    },
    [
      calendarEvents,
      calendarView,
      handleClickAddSingleSlot,
      handleClickEditSingleSlot,
      selectedResourceId,
      selectedResource?.allow_dynamic_reservation,
      reservedView,
      slots,
      t,
    ]
  );

  const handleDoubleClickCalendar = useCallback(
    async (e) => {
      if (selectedResource.allow_dynamic_reservation) {
        let result;
        if (e?.dynamic) {
          const firstEventStartDate = DateHelper.getDateTimeLocal(e.start_date).startOf("day");
          const lastEventEndDate = DateHelper.getDateTimeLocal(e.end_date).endOf("day").subtract(1, "second");
          const beginOfDay = firstEventStartDate;
          const endOfDay = lastEventEndDate;

          endOfDay.utcOffset(0, true);
          beginOfDay.utcOffset(0, true);

          result = await AddOrEditDynamicReservationModal.show({
            editMode: true,
            fetchEvents,
            resource: selectedResource,
            slot: e,
            reservationDateRange: {
              start: beginOfDay,
              end: endOfDay,
            },
            reservation: e.reservation,
            slotActionsInfo: slotActionsInfo,
            isBlocking: e.isBlocking,
          });
        } else {
          result = await ListDynamicReservationsModal.show({
            slotActionsInfo: slotActionsInfo,
            resource: selectedResource,
            fetchEvents: fetchEvents,
            date: e.start,
            highlightReservationId: e.reservation?.id,
          });
        }
        if (result && selectedResource?.id) {
          dispatch(getResources(currentProject.id));
          if (reservedView) {
            getReservationsByDateRange(dateRange);
          } else {
            getSlotsByDateRange(dateRange);
          }
        }
      } else {
        const result = await AddEditReservationSlotModal.show({
          resource: selectedResource,
          events: [e],
          fetchEvents: fetchEvents,
          date: e.start,
          doubleClick: true,
          reservedMode: reservedView,

          slotActionsInfo: slotActionsInfo,
          dynamic: selectedResource?.allow_dynamic_reservation,
        });
        if (result && selectedResource?.id) {
          dispatch(getResources(currentProject.id));
          if (reservedView) {
            getReservationsByDateRange(dateRange);
          } else {
            getSlotsByDateRange(dateRange);
          }
        }
      }
    },
    [
      selectedResource,
      currentProject.id,
      getSlotsByDateRange,
      dateRange,
      dispatch,
      getReservationsByDateRange,
      reservedView,
      fetchEvents,
      slotActionsInfo,
    ]
  );

  const calendarComponents = useMemo(() => {
    return {
      eventWrapper: eventWrapperComponent,
      dateCellWrapper: dateCellWrapperComponent,
    };
  }, [eventWrapperComponent, dateCellWrapperComponent]);

  const handleRangeChanged = useCallback((range, view, showAll) => {
    setShowAll(showAll);
    if (showAll) {
      const ago = DateHelper.getDateTimeLocal().subtract(5, "year");
      const later = DateHelper.getDateTimeLocal().add(5, "year");
      setDateRange({ start: ago, end: later });
      return;
    }
    let startDate, endDate;
    if (range.start && range.end) {
      startDate = DateHelper.getDateTimeLocal(range.start).startOf("day");
      endDate = DateHelper.getDateTimeLocal(range.end).startOf("day").add(1, "day");
      // endDate = DateHelper.getDateTimeLocal(range.end).endOf("month");
    } else {
      const days = range || [];
      startDate = DateHelper.getDateTimeLocal(days[0]).startOf("day");
      endDate = DateHelper.getDateTimeLocal(days[days.length - 1])
        .startOf("day")
        .add(1, "day");
      // endDate = DateHelper.getDateTimeLocal(days[days.length - 1]).endOf("day");
    }
    setDateRange({ start: startDate, end: endDate });
  }, []);

  useEffect(() => {
    if (!selectedResourceId || (!dateRange && !calendarRef) || !currentProject.id) return;
    if (reservedView) {
      getReservationsByDateRange(dateRange);
    } else {
      getSlotsByDateRange(dateRange);
    }
  }, [
    getSlotsByDateRange,
    dispatch,
    selectedResourceId,
    currentProject.id,
    dateRange,
    calendarRef,
    reservedView,
    getReservationsByDateRange,
  ]);

  // const totalSlots = calendarEvents.length;
  // const totalSlotsPresent = presentCalendarEvents.length;
  // const totalSlotsAvailable = calendarEvents.filter((slot) => slot.available).length;
  // const totalSlotAvailablePresent = presentCalendarEvents.filter((slot) => slot.available).length;
  // const totalSlotsReserved = totalSlots - totalSlotsAvailable;
  // const totalSlotsReservedPresent = totalSlotsPresent - totalSlotAvailablePresent;

  const handleClickDownload = useCallback(
    (events, type) => {
      const timestamp = Date.now();
      const { start, end } = dateRange;
      DialogHelper.showQuestionYesNo(
        t("dashboard.reservations.download.title").format(type.toUpperCase()),
        t("dashboard.reservations.download.description")
      ).then(async (result) => {
        if (result) {
          LoadingHelper.open();
          try {
            // const response = await dispatch(
            //   getSlots(selectedResourceId, currentProject.id, {
            //     start_date__gte: start,
            //     end_date__lte: end,
            //     limit: 99999,
            //   })
            // );
            const filterStart = start.clone();
            const filterEnd = end.clone();
            filterStart.utcOffset(0, true);
            filterEnd.utcOffset(0, true);

            let reservationResults = [];

            if (selectedResource?.allow_dynamic_reservation) {
              const responseDynamic = await dispatch(
                getDynamicReservationList(
                  currentProject.id,
                  selectedResourceId,
                  {
                    start_date__gte: filterStart.toISOString(),
                    end_date__lte: filterEnd.toISOString(),
                    limit: 99999,
                  },
                  { onSuccess: () => {} }
                )
              );
              reservationResults = Array.isArray(responseDynamic?.results)
                ? responseDynamic.results
                : Array.isArray(responseDynamic)
                  ? responseDynamic
                  : [];
            } else {
              const responseReservation = await dispatch(
                getReservations(selectedResourceId, currentProject.id, {
                  start_date__gte: filterStart.toISOString(),
                  end_date__lte: filterEnd.toISOString(),
                  limit: 99999,
                })
              );
              reservationResults = responseReservation.results || [];
            }

            const mappedDataRaw = [];
            const allSlots = reservationResults.map((event) => event.slot).flat();
            const allUniqueSlots = allSlots.filter(
              (slot, index, self) => self.findIndex((s) => s.id === slot.id) === index
            );
            const sortedAllSlotsByStartDate = allUniqueSlots.sort((a, b) => {
              const startOfDayA = DateHelper.getDateTimeLocal(a.start_date).startOf("day");
              const minutesSinceStartA = DateHelper.getDateTimeLocal(a.start_date).diff(startOfDayA, "minutes");

              const startOfDayB = DateHelper.getDateTimeLocal(b.start_date).startOf("day");
              const minutesSinceStartB = DateHelper.getDateTimeLocal(b.start_date).diff(startOfDayB, "minutes");

              return minutesSinceStartA - minutesSinceStartB;
            });

            const mappedAllSlots = sortedAllSlotsByStartDate.map((slot) => {
              const startTime = DateHelper.getDateTimeLocal(slot.start_date).format("LT");
              const endTime = DateHelper.getDateTimeLocal(slot.end_date).format("LT");
              let timeTextKey = `${startTime} - ${endTime}`;
              if (slot.allDay) timeTextKey = t("dashboard.reservations.allDay");

              return {
                [`${timeTextKey}`]: "",
              };
            });

            const aggregatedAllSlotsFormat = mappedAllSlots.reduce((acc, slot) => {
              return {
                ...acc,
                ...slot,
              };
            }, {});

            for (const event of reservationResults) {
              const customerFields = event.reserver?.safe_information || event.customer || {};

              const lowcodePublicFields = slotActionsInfo?.report_visible_member_keys;
              const lowcodeIgnoreFields = slotActionsInfo?.report_ignore_keys;
              const lowcodeIgnoreSlotView = slotActionsInfo?.report_ignore_slot_view;
              const lowcodeReservationTypeEnabled = slotActionsInfo?.reservation_type_enabled;

              const visibleCustomerFields = {};
              if (Array.isArray(lowcodePublicFields) && lowcodePublicFields.length > 0) {
                for (const field of lowcodePublicFields) {
                  visibleCustomerFields[field] = customerFields[field] || "";
                }
              } else {
                for (const field of publicMemberDataFields) {
                  visibleCustomerFields[field.name] = customerFields[field.name] || "";
                }
              }

              const slots = [...(Array.isArray(event.slot) ? event.slot : [event.slot])];
              const sortedSlotsByStartDate = slots.sort((a, b) => {
                return DateHelper.getDateTimeLocal(a.start_date).diff(DateHelper.getDateTimeLocal(b.start_date));
              });
              const startDateMoment = DateHelper.getDateTimeLocal(sortedSlotsByStartDate[0].start_date);

              let sortDate = sortedSlotsByStartDate?.[0]?.start_date;
              let startTime = DateHelper.getDateTimeLocal(sortedSlotsByStartDate[0].start_date).format("LT");
              let endTime = DateHelper.getDateTimeLocal(
                sortedSlotsByStartDate[sortedSlotsByStartDate.length - 1].end_date
              ).format("LT");

              if (selectedResource?.allow_dynamic_reservation) {
                sortDate = event.start_date;
                startTime = DateHelper.getDateTimeLocal(event.start_date).format("LT");
                endTime = DateHelper.getDateTimeLocal(event.end_date).format("LT");
              }

              // const endDate = DateHelper.getDateTimeLocal(sortedSlotsByStartDate[sortedSlotsByStartDate.length - 1].end_date).toDate();

              const mappedSlots = sortedSlotsByStartDate.map((slot) => {
                const startTime = DateHelper.getDateTimeLocal(slot.start_date).format("LT");
                const endTime = DateHelper.getDateTimeLocal(slot.end_date).format("LT");
                let timeTextKey = `${startTime} - ${endTime}`;
                if (slot.allDay) timeTextKey = t("dashboard.reservations.allDay");

                return {
                  [`${timeTextKey}`]: "X",
                };
              });

              const aggregatedSlots = mappedSlots.reduce((acc, slot) => {
                return {
                  ...acc,
                  ...slot,
                };
              }, {});
              const slotPickFormat = {
                ...aggregatedAllSlotsFormat,
              };
              for (const key in aggregatedSlots) {
                slotPickFormat[key] = aggregatedSlots[key];
              }
              //search in blacklist list
              const blacklist = selectedResource?.blacklist || [];
              const isBlacklisted = !!event.reserver?.id && blacklist.some((item) => item.id === event.reserver?.id);

              const reportObj = {
                _date: sortDate,
                Calendar: selectedResource?.name,
                Date: startDateMoment.format("DD.MM.YYYY"),
                TimeRange: `${startTime} - ${endTime}`,

                AttendedStatus:
                  organizationResourceAttendanceStatus.no_info === event?.is_attended
                    ? "-"
                    : organizationResourceAttendanceStatusMap[event?.is_attended] || event?.is_attended,
                ...visibleCustomerFields,
                ...(!!lowcodeReservationTypeEnabled && {
                  "İşlem Adı": event?.secret_note?.islem_adi,
                  Fiyat: event?.secret_note?.fiyat,
                }),
                ...(!!selectedResource?.allow_dynamic_reservation && {
                  Blocked: event.is_blocking ? "X" : "",
                  Blacklist: isBlacklisted ? "X" : "",
                }),
                note: event?.note,
                ...(!lowcodeIgnoreSlotView ? slotPickFormat : {}),
              };

              if (Array.isArray(lowcodeIgnoreFields) && lowcodeIgnoreFields.length > 0) {
                for (const field of lowcodeIgnoreFields) {
                  delete reportObj[field];
                }
              }
              mappedDataRaw.push(reportObj);
            }
            // const mappedData = flatReservations.map((event) => {
            //   const { title, start, end, customer, reserver } = event;
            //   const startDate = DateHelper.getDateTimeLocal(start).toDate();
            //   const startTime = DateHelper.getDateTimeLocal(start).format("LT");
            //   const endTime = DateHelper.getDateTimeLocal(end).format("LT");
            //   let timeText = `${startTime} - ${endTime}`;
            //   if (event.allDay) timeText = "All Day";
            //   return {
            //     Date: startDate,
            //     Time: timeText,
            //     // Event: title,
            //     ...(reserver?.safe_information || customer || {}),
            //   };
            // });

            const mappedData = mappedDataRaw
              .sort((a, b) => {
                return DateHelper.getDateTimeLocal(a._date).diff(DateHelper.getDateTimeLocal(b._date));
              })
              .map((item) => {
                delete item._date;
                return item;
              });

            if (!mappedData?.length) {
              AlertHelper.show(t("dashboard.reservations.noDataError"), "warning");
              return;
            }
            if (type === "csv") {
              FileHelper.exportAsCSV(mappedData, `agenda_${timestamp}`);
            } else if (type === "xlsx") {
              FileHelper.exportAsExcel(mappedData, `agenda_${timestamp}`);
            }
          } finally {
            LoadingHelper.close();
          }
        }
      });
      return false;
    },
    [selectedResourceId, dateRange, dispatch, currentProject.id, t]
  );

  const columns = useMemo(() => {
    return [
      {
        label: t("common.startDate"),
        key: "start_date",
        render: (row) => {
          return DateHelper.getDateTimeLocal(row.start_date).format("LLT");
        },
      },
      {
        label: t("common.endDate"),
        key: "end_date",
        render: (row) => {
          return DateHelper.getDateTimeLocal(row.end_date).format("LLT");
        },
      },
      {
        label: t("common.total"),
        key: "total",
      },
      {
        label: t("dashboard.reservations.reserved"),
        key: "reserved",
      },
      {
        label: t("common.available"),
        key: "available_slots",
        render: (row) => {
          return (
            <b
              className={classNames({
                "text-success-600": row.available_slots > 0,
                "text-danger": !(row.available_slots > 0),
              })}
            >
              {row.available_slots || 0}
            </b>
          );
        },
      },
    ];
  }, []);

  const columnsDetail = useMemo(() => {
    // const handleEditReservation = (row) => async (e) => {
    //   const result = await AddEditReservationSlotDetailModal.show({
    //     reservation: row,
    //     slotId: selectedTableSlot,
    //     resource: selectedResource,
    //   });

    //   if (result) {
    //     await dispatch(getSlot(selectedTableSlot, selectedResource?.id, currentProject.id, true));
    //     // setIsModified(true);

    //     await dispatch(getResources(currentProject.id));
    //     tableDetailRef.current.refresh();
    //   }
    // };
    const handleEditReservation = (row) => async (e) => {
      if (selectedResource.allow_dynamic_reservation) {
        const result = await ListDynamicReservationsModal.show({
          slotActionsInfo: slotActionsInfo,
          resource: selectedResource,
          fetchEvents: fetchEvents,
          date: row.start_date,
          highlightReservationId: row.id,
        });

        if (result) {
          tableDetailRef.current.refresh();
        }
      } else {
        const mappedRelatedEvents = row.slot.map((slot) =>
          slotToEventMapper(slot, { reservedView, resource: selectedResource })
        );

        const result = await EditReservationSlotDateModal.show({
          // events,
          // reservation: reservation,
          // resource,
          editMode: true,
          slotActionsInfo: slotActionsInfo,
          reservation: row,
          resource: selectedResource,
          events: mappedRelatedEvents,
          fetchEvents: fetchEvents,
          date: DateHelper.getDateTimeLocal(row.slot[0].start_date).startOf("day").toDate(),
          reservedMode: reservedView,
        });

        if (result) {
          // await dispatch(getSlot(selectedTableSlot, selectedResource?.id, currentProject.id, true));
          // setIsModified(true);

          // await dispatch(getResources(currentProject.id));
          tableDetailRef.current.refresh();
        }
      }
    };
    // const handleDeleteReservation = (row) => async (e) => {
    //   if (!(await DialogHelper.showQuestionDelete())) return;

    //   dispatch(deleteReservation(row.id, selectedResource?.id, currentProject.id)).then(() => {
    //     // dispatch(getReservations(resourceId, currentProject.id));
    //     dispatch(getSlot(row.slot, selectedResource?.id, currentProject.id, true)).then(() => {
    //       tableDetailRef.current.refresh();
    //     });
    //   });
    // }
    const publicColumns = [];
    if (selectedResource?.allow_dynamic_reservation) {
      for (const field of publicMemberDataFields) {
        publicColumns.push({
          label: field.display_name,
          key: `reserver.safe_information.${field.name}`,
        });
      }
    }

    return [
      {
        label: t("common.date"),
        key: "start_date",
        render: (row) => {
          if (selectedResource?.allow_dynamic_reservation) {
            return DateHelper.getDateTimeLocal(row?.start_date).format("L");
          }
          const sortedSlots = [...(row?.slot || [])]?.sort((a, b) => {
            return DateHelper.getDateTimeLocal(a.start_date).diff(DateHelper.getDateTimeLocal(b.start_date));
          });

          const startDate = DateHelper.getDateTimeLocal(sortedSlots?.[0]?.start_date).format("L");
          return startDate;
        },
      },
      {
        label: t("common.time"),
        key: "time_range",
        render: (row) => {
          if (selectedResource?.allow_dynamic_reservation) {
            const startDate = DateHelper.getDateTimeLocal(row?.start_date).format("LT");
            const endDate = DateHelper.getDateTimeLocal(row?.end_date).format("LT");
            return `${startDate} - ${endDate}`;
          }

          const sortedSlots = [...(row?.slot || [])]?.sort((a, b) => {
            return DateHelper.getDateTimeLocal(a.start_date).diff(DateHelper.getDateTimeLocal(b.start_date));
          });

          const startTime = DateHelper.getDateTimeLocal(sortedSlots?.[0]?.start_date).format("LT");
          const endTime = DateHelper.getDateTimeLocal(sortedSlots?.[sortedSlots.length - 1]?.end_date).format("LT");

          return `${startTime} - ${endTime}`;
        },
      },
      !!selectedResource?.allow_dynamic_reservation && {
        label: t("dashboard.reservations.isAttended"),
        key: "is_attended",
        render: (row) => {
          return organizationResourceAttendanceStatusMap[row.is_attended] || row.is_attended;
        },
      },
      ...publicColumns,
      // {
      //   label: "Full Name",
      //   key: "reserver.safe_information.full_name",
      // },
      // {
      //   label: "Email",
      //   key: "reserver.safe_information.email",
      // },
      {
        label: t("common.note"),
        key: "note",
      },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        render: (row) => {
          // const handleClickDelete = async () => {
          //   if (!(await DialogHelper.showQuestionDelete())) return;
          //   dispatch(deleteIntentTag(currentProject.id, currentBot.id, row.id)).then(() => {
          //     tableRef.current.refresh();
          //   });
          // };

          return (
            <div className="d-flex justify-content-center">
              <PRButton
                outline
                color="primary"
                icon={MdEdit}
                size="sm"
                tooltipText={t("common.edit")}
                onClick={handleEditReservation(row)}
              />
              {/* <PRButton
                outline
                className="ms-1"
                color="danger"
                icon={MdDelete}
                size="sm"
                tooltipText={t("common.delete")}
                onClick={handleDeleteReservation(row)}
              /> */}
            </div>
          );
        },
      },
    ].filter(Boolean);
  }, [selectedResource, publicMemberDataFields, tableDetailRef, slotActionsInfo, reservedView, fetchEvents, t]);

  const handleClickTab = (tabId) => {
    setTab(tabId);
  };

  const handleClickOpenGenerator = async () => {
    const result = await LowCodeEditorModal.show({
      moduleType: lowCodeModuleType.RESERVATION_MODULES,
      reservationActionGeneratorId: selectedResource.reservation_action_generator,
      hideContext: true,
      defaultSelectedProcedureName: "main",
    });
    if (result) {
      await dispatch(getResources(currentProject.id));
      dispatch(getReservationActions(currentProject.id, selectedResourceId)).then((response) => {
        setSlotActionsInfo(response);
      });
    }
  };

  return (
    <PRContainer
      className="pr-resource"
      description={t("dashboard.reservations.description")}
      name={t("common.organization")}
      parentName={t("dashboard.reservations")}
    >
      <PRPage>
        <Row className="mt-2">
          <Col lg="auto">
            <Label className="" size="md">
              {t("common.calendar")}:
            </Label>
          </Col>

          <Col lg className="d-flex align-items-center">
            <PRSelect
              fullWidth
              isPrimitiveValue
              isClearable={false}
              isLoading={loadingResources}
              options={resourceOptions}
              placeholder={t("dashboard.reservations.selectCalendarPlaceholder")}
              value={selectedResourceId}
              onChange={setSelectedResourceId}
            />

            {hasFullAccess && (
              <PRButton
                outline
                className="ms-2"
                color="danger"
                disabled={!selectedResourceId}
                icon={MdDelete}
                tooltipText={t("dashboard.reservations.deleteCalendar")}
                onClick={handleClickDeleteResource}
              />
            )}
            {hasFullAccess && !!selectedResource?.reservation_action_generator && (
              <PRButton
                className="ms-2"
                color="primary"
                disabled={!selectedResourceId}
                icon={MdCode}
                tooltipText={t("dashboard.reservations.calendarLowCode")}
                onClick={handleClickOpenGenerator}
              />
            )}
            <PRButton
              className=" ms-2"
              color="warning"
              icon={MdBlock}
              tooltipText={t("dashboard.reservations.blacklist")}
              onClick={handleClickOpenBlacklist}
            />
            {hasFullAccess && (
              <>
                <PRButton
                  className=" ms-2"
                  disabled={!selectedResourceId}
                  icon={MdEdit}
                  tooltipText={t("dashboard.reservations.editCalendar")}
                  onClick={handleClickEditResource}
                />
                <PRButton
                  className=" ms-2"
                  color="success"
                  icon={MdAdd}
                  tooltipText={t("dashboard.reservations.addCalendar")}
                  onClick={handleClickAddResource}
                />
              </>
            )}
          </Col>
        </Row>
        <PRTab
          className="mb-2 mt-4"
          tab={tab}
          tabList={[
            {
              id: "calendar",
              label: t("common.calendar"),
            },
            {
              id: "list",
              label: t("dashboard.reservations"),
            },
          ]}
          onChange={handleClickTab}
        />

        {tab === "calendar" && (
          <>
            {!selectedResource?.allow_dynamic_reservation && (
              <Box alignItems="center" className="mb-2" display="flex">
                <Switch checked={reservedView} onChange={handleToggleSlotView} />
                <Label className="font-size-16" size="md" tag="h6">
                  {reservedView ? t("dashboard.reservations.reservedView") : t("dashboard.reservations.slotView")}
                </Label>
              </Box>
            )}
            <Row className="g-2 mb-2 align-items-center justify-content-between">
              {!selectedResource?.allow_dynamic_reservation && (
                <>
                  <Col className="d-flex align-items-center " xs="auto">
                    <Label className="font-size-16" tag="h6">
                      {t("dashboard.reservations.totalSlots")}:
                    </Label>
                    <Label className="font-size-16 ms-1 fw-semibold" tag="h6">
                      {selectedResource?.reservation_status?.all?.count || 0}
                    </Label>
                  </Col>
                  <Col className="d-flex align-items-center" xs="auto">
                    <Label className="font-size-16" tag="h6">
                      {t("dashboard.reservations.availableSlots")}:
                    </Label>
                    <Label className={"font-size-16 ms-1 fw-semibold d-flex align-items-center lh-1"} tag="h6">
                      <span
                        className={classNames({
                          "text-success-600": (selectedResource?.reservation_status?.all?.available_count || 0) > 0,
                          "text-danger": (selectedResource?.reservation_status?.all?.available_count || 0) === 0,
                        })}
                      >
                        {selectedResource?.reservation_status?.future?.available_count || 0}
                      </span>
                      <span className="ms-1 fs-6 d-flex align-items-center lh-1">
                        ({selectedResource?.reservation_status?.all?.available_count || 0}
                        <PRTooltip title={t("dashboard.reservations.totalAvailableSlotsTooltip")}>
                          <span>
                            <MdHelp className="fs-6 ms-1" />
                          </span>
                        </PRTooltip>
                        )
                      </span>
                    </Label>
                  </Col>
                  <Col className="d-flex align-items-center" xs="auto">
                    <Label className="font-size-16" tag="h6">
                      {t("dashboard.reservations.reservedSlots")}:Reserved slots
                    </Label>
                    <Label className={"font-size-16 ms-1 fw-semibold d-flex align-items-center"} tag="h6">
                      <span
                        className={classNames({
                          "text-primary": selectedResource?.reservation_status?.future?.reservation_count || 0 > 0,
                        })}
                      >
                        {selectedResource?.reservation_status?.all?.reservation_count || 0}
                      </span>
                      <span className="ms-1 fs-6 d-flex align-items-center lh-1">
                        ({selectedResource?.reservation_status?.future?.reservation_count || 0}
                        <PRTooltip title={t("dashboard.reservations.totalReservedSlotsTooltip")}>
                          <span>
                            <MdHelp className="fs-6 ms-1" />
                          </span>
                        </PRTooltip>
                        )
                      </span>
                    </Label>
                  </Col>
                </>
              )}

              {selectedResource?.allow_dynamic_reservation && <Col xs className="d-flex align-items-center"></Col>}
              <Col xs="auto">
                <PRButton
                  className="fw-medium ms-2"
                  color="success"
                  disabled={!selectedResourceId}
                  icon={MdAdd}
                  onClick={handleClickAddResourceSlots}
                >
                  {t("dashboard.reservations.addSlots")}
                </PRButton>
              </Col>
            </Row>

            <PRCalendar
              ref={handleCalendarRef}
              backgroundEvents={backgroundEvents}
              components={calendarComponents}
              dayLayoutAlgorithm="no-overlap"
              events={calendarEvents}
              showAll={showAll}
              step={15}
              tooltipAccessor="tooltip"
              view={calendarView}
              onClickDownload={handleClickDownload}
              onDoubleClickEvent={handleDoubleClickCalendar}
              onRangeChange={handleRangeChanged}
              onView={setCalendarView}
            />
          </>
        )}

        {tab === "list" && (
          <div>
            <PRTable
              ref={tableDetailRef}
              inline
              {...(selectedResourceId && {
                url: selectedResource?.allow_dynamic_reservation
                  ? apiUrlOrganization.getDynamicReservation.format(currentProject.id, selectedResourceId)
                  : apiUrlOrganization.getReservation.format(currentProject.id, selectedResourceId),
              })}
              columns={columnsDetail}
            />
          </div>
        )}
      </PRPage>
    </PRContainer>
  );
}
