import React, { Fragment, memo, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  Md123,
  MdAbc,
  MdCalendarMonth,
  MdCheckCircle,
  MdDescription,
  MdImage,
  MdNewspaper,
  MdNoteAdd,
  MdOutlineLocationOn,
  MdOutlineQuestionMark,
  MdRadioButtonChecked,
} from "react-icons/md";
import { useSelector } from "react-redux";

import styled from "@emotion/styled";
import { Avatar, Badge, Box, Grid } from "@mui/material";

import { ReactComponent as NotificationIcon } from "~assets/images/icons/notification.svg";
import ChatTyping from "~components/ChatTyping";
import { PRTextAreaFormat } from "~components/Generic/PRInput";
import PRPhoneNumber from "~components/Generic/PRPhoneNumber";
import PRTooltip from "~components/Generic/PRTooltip";
import {
  chatbotInputRequestType,
  chatbotSenderType,
  chatbotSessionPlatform,
  chatbotSessionStatus,
  chatbotTypingThrottleMs,
} from "~constants";
import DateHelper from "~helpers/DateHelper";
import RenderHelper from "~helpers/RenderHelper";
import SanitizeHelper from "~helpers/SanitizeHelper";
import { selectProjectSettings } from "~store/socket/livechat/selectors";
import { selectProjects } from "~store/user/selectors";

import { convertSocketMsgToChatMessage, getStatusText, parseMetadata } from "../SessionUtils";

import "./style.scss";

// function SkeletonWithTimeout({ timeoutMs, children }) {
//   const [visible, setVisible] = useState(false);
//   useEffect(() => {
//     const timeout = setTimeout(() => {
//       setVisible(true);
//     }, timeoutMs);
//     return () => clearTimeout(timeout);
//   }, [timeoutMs]);
//   if (visible) return children;
//   return <Skeleton variant="rectangular" width={"100%"} height={15} />;
// }

const SessionToPreviewText = ({ DisplayIcon, displayText, isTranslated }) => {
  const { t } = useTranslation();

  const finalText = typeof displayText === "function" ? displayText() : displayText;

  return (
    <Grid container alignItems={"end"} display={"flex"} flexWrap={"nowrap"} gap={"3px"}>
      {DisplayIcon && (
        <Grid item zeroMinWidth display={"flex"} sx={{ svg: { width: "auto", height: 15 } }} xs="auto">
          <DisplayIcon />
        </Grid>
      )}
      <Grid item className="text-truncate">
        <>{isTranslated ? t(finalText) : finalText}</>
      </Grid>
    </Grid>
  );
};
const anonymousEmail = "anonymous@palmate.ai";
function MessageTime({ isQueueWait, value }) {
  const [time, setTime] = useState("");
  useEffect(() => {
    const updateTime = () => {
      setTime(DateHelper.getDateTimeLocal(value).fromNow(true));
    };
    const interval = setInterval(updateTime, 1000);
    updateTime();
    return () => clearInterval(interval);
  }, [value]);
  return (
    <>
      <PRTooltip
        title={
          <Box textAlign={"center"}>
            <Box marginBottom={1}>
              {isQueueWait && <span className="text-warning">Waiting for agent</span>}
              {!isQueueWait && <span>Last message sent/received at</span>}
            </Box>
            {!!value && (
              <div>
                {DateHelper.getDateTimeLocal(value).fromNow()} - {isQueueWait && "Customer joined queue at"}{" "}
                {DateHelper.getDateTimeLocal(value).format("LLT")}
              </div>
            )}
          </Box>
        }
      >
        <span className="font-size-13">
          <Box component={"span"}>{time || <>&nbsp;</>}</Box>
        </span>
      </PRTooltip>
    </>
  );
}
export function renderSessionToPreviewText(session) {
  const rawMessages = session?.messages || [];
  const messages = rawMessages.filter((item) => item?.type !== "log");
  let displayText;
  let DisplayIcon;
  let isTranslated = false;
  if (messages.length > 0) {
    let lastMessage = messages[messages.length - 1];
    let previousLastMessage;
    if (messages.length > 1) {
      previousLastMessage = messages[messages.length - 2];
    }
    if (["submit_input_request", "button_select"].includes(lastMessage.type) && messages?.length > 1) {
      if (messages.length > 2) {
        previousLastMessage = messages[messages.length - 3];
      }
      lastMessage = messages[messages.length - 2];
    }

    const msg = convertSocketMsgToChatMessage(lastMessage, previousLastMessage, session);
    // console.log("lastMessage, previousLastMessage", lastMessage, previousLastMessage);
    if (msg) {
      const text = msg.unformattedText || msg.text;
      if (msg.type === "text") {
        displayText = text;
        if (msg.format === PRTextAreaFormat.markdown) {
          displayText = RenderHelper.renderMd(text, {
            renderAsPlain: true,
          });
          displayText = SanitizeHelper.plainText(displayText || "");
        } else if (msg.format === PRTextAreaFormat.html) {
          const sanitizedPlainText = SanitizeHelper.plainText(text || "");
          displayText = sanitizedPlainText;
          const lines = displayText.split(/\r?\n/);
          displayText = lines?.[0] || ""; // first line of html
        }
        if (msg.isChoiceText) {
          DisplayIcon = MdRadioButtonChecked;
        }
      } else if (msg.type === "choice") {
        displayText = text;
        DisplayIcon = MdOutlineQuestionMark;
      } else if (msg.type === "file") {
        displayText = msg.fileName;
        DisplayIcon = MdDescription;
      } else if (msg.type === "bot_file") {
        displayText = "component.sessionListItem.botFile";
        isTranslated = true;
        DisplayIcon = MdNoteAdd;
      } else if (msg.type === "image") {
        if (msg.fileName) displayText = msg.fileName;
        else {
          displayText = "common.image";
          isTranslated = true;
        }
        DisplayIcon = MdImage;
      } else if (msg.type === "location") {
        const tempText = [msg.locationInfo?.name, msg.locationInfo?.address].filter(Boolean).join(" - ");
        if (tempText) displayText = tempText;
        else {
          displayText = "common.location";
          isTranslated = true;
        }
        DisplayIcon = MdOutlineLocationOn;
      } else if (msg.type === "inputRequest") {
        if (msg.inputFormat.type === chatbotInputRequestType.form) {
          displayText = "component.sessionListItem.formInputRequest";
          isTranslated = true;
          DisplayIcon = MdNewspaper;
        } else if ([chatbotInputRequestType.custom, chatbotInputRequestType.select].includes(msg.inputFormat.type)) {
          displayText = "component.sessionListItem.choiceRequest";
          isTranslated = true;
          DisplayIcon = MdCheckCircle;
        } else if ([chatbotInputRequestType.date, chatbotInputRequestType.date_range].includes(msg.inputFormat.type)) {
          displayText = "component.sessionListItem.dateRequest";
          isTranslated = true;
          DisplayIcon = MdCalendarMonth;
        } else if (msg.inputFormat.type === chatbotInputRequestType.file) {
          displayText = "component.sessionListItem.fileRequest";
          isTranslated = true;
          DisplayIcon = MdDescription;
        } else if (
          [chatbotInputRequestType.only_numbers, chatbotInputRequestType.range].includes(msg.inputFormat.type)
        ) {
          displayText = "component.sessionListItem.numberRequest";
          isTranslated = true;
          DisplayIcon = Md123;
        } else {
          displayText = "component.sessionListItem.textInputRequest";
          isTranslated = true;
          DisplayIcon = MdAbc;
        }
      } else if (msg.type === "notification") {
        displayText = text;
        DisplayIcon = NotificationIcon;
      }
    }
  }
  const isReactComponent = typeof displayText === "object" && displayText !== null && displayText.$$typeof;
  if (typeof displayText === "string" || typeof displayText === "function" || isReactComponent) {
    return <SessionToPreviewText DisplayIcon={DisplayIcon} displayText={displayText} isTranslated={isTranslated} />;
  }
  return "";
}

const StyledDiv = styled.div`
  padding: 15px;
  margin: 0px 15px;
  border-radius: 8px;
  border: solid 1px ${(props) => (props.isCurrentAgentChatting ? "#28aae1" : "#ddd")};
  position: relative;

  background-color: #fff;
  &:hover {
    /* box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2); */
    /* background-color: hsl(198deg 76% 95%); */
    background-color: #f4f4f4;
    &.selected {
      background-color: hsl(194, 89%, 94%);
    }
  }
  &.selected {
    /* background-color: hsl(198deg 76% 90%); */
    /* box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2); */
    background-color: rgb(237, 250, 254);
  }
  &.fade {
    //add gloving effect to turn normal gradually with animation but not infinite

    position: absolute;
    z-index: 1;
    top: 0;
    left: 10px;
    bottom: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    &--active {
      opacity: 1;
      animation: glow 20s ease-in-out;
      animation-iteration-count: 1;
    }
  }
  .user-title {
    font-size: 15px;
    font-weight: 500;
    /* line-height: 34px; */
    color: rgba(0, 0, 0, 0.85);
    margin-top: 4px;
    margin-bottom: 3px;
    line-height: 1;
    ${({ isAnonymousUser }) =>
      isAnonymousUser &&
      `
    font-weight: 100;
`}
  }
  .preview-text {
    margin-bottom: 7px;
    font-size: 13px;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.85);
  }
  .platform-icon {
    height: 15px;
  }
  .detail-text {
    font-size: 11px;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 300;
    &-brand {
      font-weight: 500;
    }
    svg {
      font-size: 13px;
      margin-right: 2px;
    }
  }
`;

export const TypingIndicatorWrapper = memo(function TypingIndicatorWrapper({ children, session }) {
  const [interactionInfo, setInteractionInfo] = useState({
    online: true,
    typing: false,
    focused: false,
    location: "",
  });
  useEffect(() => {
    const interactions = session?.interactions || [];
    const connections = session?.connections || [];

    const loopCount = Math.max(interactions.length, connections.length);
    const mergedInteractionsWithConnections = [];
    for (let i = 0; i < loopCount; i++) {
      const interaction = interactions[i];
      const connection = connections[i];
      if (interaction) {
        const relatedConnection = connections.find((i) => i.connection_id === interaction.connection_id);
        mergedInteractionsWithConnections.push({ ...relatedConnection, ...interaction });
      } else if (connection) {
        mergedInteractionsWithConnections.push({ ...connection });
      }
    }

    if (!mergedInteractionsWithConnections.length) return;
    const onlineList = mergedInteractionsWithConnections.filter((interaction) => interaction?.online);
    const typingInteraction = onlineList.find((interaction) => interaction.typing);
    const isTyping = !!typingInteraction;
    const isTypingStop = onlineList.some((interaction) => interaction.typing === false);
    const isFocused = onlineList.some((interaction) => interaction.focused);
    const locationFocused = onlineList.find((interaction) => interaction.location);
    const location = onlineList.find((interaction) => interaction.location);
    const locationOfflineLatest = mergedInteractionsWithConnections.find((interaction) => interaction.location);
    const isTypingState = isTyping !== true && isTypingStop ? false : interactionInfo.typing || isTyping;

    const timeoutMs = typingInteraction?.time > 0 ? typingInteraction.time * 1000 : chatbotTypingThrottleMs;

    setInteractionInfo((prev) => {
      const newStatus = {
        ...prev,
        typing: isTypingState,
        focused: isFocused,
        location:
          locationFocused?.location || location?.location || locationOfflineLatest?.location || prev.location || "",
        locationList: mergedInteractionsWithConnections.filter((interaction) => interaction.location),
        count: onlineList.length,
        online: onlineList.length > 0,
      };
      return newStatus;
    });
    let typingTimeout;
    if (isTypingState) {
      typingTimeout = setTimeout(() => {
        setInteractionInfo((prev) => ({ ...prev, typing: false }));
      }, timeoutMs);
    }
    return () => clearTimeout(typingTimeout);
  }, [session?.interactions, interactionInfo.typing, session?.connections]);

  const [typingStatus, setTypingStatus] = useState(false);

  useEffect(() => {
    if (interactionInfo.typing === true) {
      setTypingStatus(true);
    } else if (interactionInfo.typing === false) {
      setTypingStatus(false);
    } else {
      const timeout = setTimeout(() => {
        setTypingStatus(false);
      }, 10);
      return () => clearTimeout(timeout);
    }
  }, [interactionInfo]);

  if (!typingStatus) return children;
  return (
    <Box
      sx={{
        ml: "2px",
      }}
    >
      <ChatTyping height={13} />
    </Box>
  );
});
export default function SessionListItem({
  order,
  session,
  selectedSession,
  onClick,
  hideHighlight,
  isCurrentAgentChatting,
}) {
  const { t } = useTranslation();
  const project = useSelector(selectProjects);
  const currentProject = project?.find((item) => item?.id === session.project);
  // const [highlightVisible, setHighlightVisible] = useState(false);
  // const livechatOptions = useSelector(selectLivechatOptions);
  // const [messageChangedCounter, setMessageChangedCounter] = useState(0);
  const [lastReadMessageCount, setLastReadMessageCount] = useState(null);
  // const [previousMessageChangedCounter, setPreviousMessageChangedCounter] = useState(-1);

  const isSelected = useMemo(() => selectedSession?.id === session?.id, [selectedSession?.id, session?.id]);

  const projectSettings = useSelector(selectProjectSettings);

  const matchedWelcomeSettings = useMemo(() => {
    if (!session?.project) return null;
    return projectSettings.find((project) => project.project === session.project);
  }, [projectSettings, session?.project]);

  const isInQueue = useMemo(() => {
    return [chatbotSessionStatus.BOT_CHAT_AGENT_WAIT, chatbotSessionStatus.AGENT_WAIT].includes(
      session?.session_status
    );
  }, [session?.session_status]);

  const customerMessages = useMemo(() => {
    return session?.messages?.filter((item) =>
      [chatbotSenderType.customer, "CUS"].includes(item?.payload?.sender_type)
    );
  }, [session?.messages]);

  useEffect(() => {
    if (isSelected || (lastReadMessageCount === null && session?.messages?.length)) {
      setLastReadMessageCount(customerMessages?.length);
    }
  }, [isSelected, customerMessages, session?.messages, session?.id, selectedSession?.id, lastReadMessageCount]);

  const handleClickSelect = () => {
    onClick?.(session);
  };

  const lastMessageReadableMessageItemDate = useMemo(() => {
    if (!session.messages?.length) {
      return session.created || session.queue_join_time;
    }

    let lastMessageText;
    let lastMessage;

    for (let i = session.messages.length - 1; i >= 0; i--) {
      const item = session?.messages?.[i];
      const date = item?.time;

      if (date) {
        if (item.type === "message") {
          lastMessageText = date;
          break;
        } else if (!lastMessage) {
          lastMessage = date;
        }
      }
    }
    const result = lastMessageText || lastMessage;
    return result;
  }, [session?.messages, session?.queue_join_time, session?.created]);

  const queueWaitStartTime = useMemo(() => {
    if (!session.messages?.length) return null;
    if ([chatbotSessionStatus.BOT_CHAT_AGENT_WAIT, chatbotSessionStatus.AGENT_WAIT].includes(session?.session_status)) {
      for (let i = session.messages.length - 1; i >= 0; i--) {
        const item = session?.messages?.[i];
        const date = item?.time;
        if (date && item?.payload?.type === "direct_to_queue") {
          return date;
        }
      }
    }
    return null;
  }, [session?.session_status, session?.messages]);

  const chatLastMessagePreview = useMemo(() => {
    return renderSessionToPreviewText(session);
  }, [session, t]);

  const { metadata, parsedMetadata } = useMemo(() => {
    const connections = (session?.connections || []).map((item) => ({
      ...item,
      lastAccess: DateHelper.getDateTime(item.last_access),
    }));

    const orderedConnections = _.orderBy(connections, ["lastAccess"], ["desc"]);
    const activeConnection = orderedConnections.find((item) => item.online) || orderedConnections[0];
    const metadata = activeConnection?.metadata || {};

    return {
      metadata,
      parsedMetadata: parseMetadata(metadata, session),
    };
  }, [session]);

  const isAuthUser = session?.customer?.auth_status > 0;
  const isAnonymousUser = anonymousEmail === session?.customer?.email;

  const itemFooterComponents = [];
  if (project.length > 1) {
    itemFooterComponents.push(
      <Grid item className="text-truncate detail-text-brand" flex={"0 1 auto"}>
        <b>{currentProject?.name}</b>
      </Grid>
    );
  }
  const isMultiBotSupport = currentProject?.chatbot_list?.length > 1;
  if (session.bot_name && isMultiBotSupport) {
    itemFooterComponents.push(
      <Grid item className="text-truncate" flex={"0 1 auto"}>
        {session.bot_name}
      </Grid>
    );
  }

  if (session.platform === chatbotSessionPlatform.WHAT || session?.customer?.phone_number) {
    const whatsappPhoneNumber =
      session?.customer?.phone_number || metadata?.whatsapp_phone_number || metadata?.phone_number?.[0];
    if (whatsappPhoneNumber) {
      itemFooterComponents.push(
        <Grid
          item
          className={classNames("text-truncate fw-semibold", {
            "text-primary": true,
          })}
          flex={"0 100 auto"}
        >
          <PRPhoneNumber render value={whatsappPhoneNumber} />
        </Grid>
      );
    }
  } else if (session.customer?.email) {
    itemFooterComponents.push(
      <Grid
        item
        className={classNames("text-truncate fw-semibold", {
          "text-secondary": isAuthUser,
          "text-primary": !isAuthUser,
        })}
        flex={"0 100 auto"}
      >
        {session.customer?.email}
      </Grid>
    );
  }
  //|| session.last_agent?.name
  if (session.agent?.name) {
    itemFooterComponents.push(
      <Grid item alignItems={"center"} className="text-truncate" display={"flex"} flex={"0 1 auto"}>
        <span
          className={classNames("fw-semibold", {
            "text-primary": true,
          })}
        >
          {session.agent?.name}
        </span>
      </Grid>
    );
  }

  const mergedFooterComponent = (
    <Grid container className="detail-text" gap={"5px"} wrap="nowrap">
      {itemFooterComponents.map((item, index) => (
        <Fragment key={index}>
          {index > 0 && (
            <Grid item flex={"0 0 auto"}>
              |
            </Grid>
          )}
          {item}
        </Fragment>
      ))}
    </Grid>
  );

  const unreadMessageCount = customerMessages?.length - lastReadMessageCount;
  return (
    <StyledDiv
      className={classNames({
        selected: isSelected,
      })}
      isAnonymousUser={isAnonymousUser}
      isCurrentAgentChatting={isCurrentAgentChatting}
      onClick={handleClickSelect}
    >
      {!isSelected && session?.messages?.length > 0 && lastReadMessageCount !== null && (
        <Badge
          badgeContent={unreadMessageCount > 0 ? unreadMessageCount : 0}
          color="error"
          sx={{
            position: "absolute",
            top: 0,
            right: "0px",
          }}
        >
          <span></span>
        </Badge>
      )}
      <Grid container gap={"10px"} wrap="nowrap">
        <Grid item xs="auto">
          <PRTooltip title={currentProject?.name}>
            <Avatar
              alt={currentProject?.name}
              src={matchedWelcomeSettings?.popup_header_icon_path}
              sx={{
                borderRadius: 8,
                border: "solid 1px #ddd",
                width: 46,
                height: 46,
              }}
            />
          </PRTooltip>
        </Grid>
        <Grid item xs zeroMinWidth>
          <Grid container gap={"0px"}>
            <Grid item xs className="user-title text-truncate">
              <PRTooltip title={session.customer?.full_name}>
                <span>{session.customer?.full_name}</span>
              </PRTooltip>
            </Grid>
            <Grid item alignItems={"center"} display={"flex"} xs="auto">
              <PRTooltip title={session.customer?.full_name}>
                <span>{getStatusText(session.session_status)}</span>
              </PRTooltip>
            </Grid>
          </Grid>
          <Grid container alignItems={"center"} className="preview-text" gap={"0px"} lineHeight={"15px"} wrap="nowrap">
            <Grid item flex={"0 1 auto"} overflow="hidden">
              <PRTooltip title={chatLastMessagePreview}>
                <span>
                  <TypingIndicatorWrapper session={session}>{chatLastMessagePreview}</TypingIndicatorWrapper>
                </span>
              </PRTooltip>
            </Grid>
            <Grid item flex={"0 0 auto"} marginLeft="auto" paddingLeft={1}>
              {((isInQueue && queueWaitStartTime) || lastMessageReadableMessageItemDate) && (
                <>
                  <MessageTime
                    isQueueWait={!!queueWaitStartTime && isInQueue}
                    value={(isInQueue && queueWaitStartTime) || lastMessageReadableMessageItemDate}
                  />
                </>
              )}
            </Grid>
          </Grid>
          <Grid container alignItems={"center"} display={"flex"} gap={"5px"} wrap="nowrap">
            <Grid item xs className="text-truncate">
              <PRTooltip title={mergedFooterComponent}>
                <div className="lh-1">{mergedFooterComponent}</div>
              </PRTooltip>
            </Grid>
            <Grid item className="platform-icon d-flex" xs="auto">
              {parsedMetadata.platformIcon}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledDiv>
  );
}
