import { useEffect, useState } from "react";

import { MdOutlineAddReaction } from "react-icons/md";

import styled from "@emotion/styled";
import { Fade } from "@mui/material";

import { useDebouncedEffect } from "~common/hooks/useDebounceEffect";
import PRPopover from "~components/Generic/PRPopover";
import PalIconButton from "~components/mui/PalIconButton";
import "./index.scss";

const StyledPalIconButton = styled(PalIconButton)`
  &.MuiIconButton-sizeMedium {
    width: 32px;
    height: 32px;
  }
  padding: 0;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 0px #0000000a;
  border: 1px solid #e8e8e8;

  svg {
    width: 24px;
    height: 24px;
  }
`;
export default function EmoteWheel({ onToggleEmote, disabled, position, dateTime, visible }) {
  const [isVisible, setIsVisible] = useState(false);

  useDebouncedEffect(
    () => {
      if (!visible) return;
      setIsVisible(visible);
    },
    [visible],
    250
  );

  useEffect(() => {
    if (visible) return;
    setIsVisible(visible);
  }, [visible]);

  if (!["left", "right"].includes(position)) {
    return null;
  }
  return (
    <Fade in={isVisible}>
      <div>
        <PRPopover>
          <div>
            <StyledPalIconButton disabled={disabled} onClick={onToggleEmote}>
              <MdOutlineAddReaction />
            </StyledPalIconButton>
          </div>
        </PRPopover>
      </div>
    </Fade>
  );
}
