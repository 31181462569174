import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import styled from "@emotion/styled";
import { Alert, Grid } from "@mui/material";

import { ReactComponent as Logo } from "~assets/images/palmate-logo.svg";
import useQueryParams from "~common/hooks/useQueryParams";
import Footer from "~components/Layout/Footer";
import PalButton from "~components/mui/PalButton";
import PalLink from "~components/mui/PalLink";
import DateHelper from "~helpers/DateHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import TokenHelper from "~helpers/TokenHelper";
import { getInvitationInfo, joinProject } from "~store/actions";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: absolute;
  width: 100%;
  height: calc(100% - 80px);
  padding: 30px 50px;
  @media (max-width: 600px) {
    padding: 30px 20px;
  }

  overflow-y: auto;
  svg {
    flex: 0 0 auto;
  }
  .input-box {
    margin: 100px auto auto auto;
    @media (max-width: 600px) {
      max-width: 100%;
      margin: 30px auto auto;
    }

    max-width: 555px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .title {
      color: ${(props) => props.theme.palette.primary.dark};
      //
      font: normal normal 600 36px/34px MuseoModerno;
      height: 57px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .desc {
      font: normal normal normal 22px/28px Museo Sans;
      color: ${(props) => props.theme.palette.primary.dark};
      letter-spacing: 0;
      margin-bottom: 20px;
      // text-align: center;
    }
    .company {
      font-size: 30px;
      font-weight: 700;

      color: ${(props) => props.theme.palette.primary.dark};
      margin-bottom: 10px;
    }
  }

  .MuiLink-root {
    color: #535353;
    text-decoration: underline !important;
  }
  .register-btn {
    font-weight: bold;
    background-color: ${(props) => props.theme.palette.primary.light};
    border-color: ${(props) => props.theme.palette.primary.light};
    max-width: 365px;
  }
  .login {
    font-family: Museo Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #838383;
    a {
      margin-left: 5px;
      color: #50c8f4;
      text-decoration: none !important;
    }
  }
`;

const JoinProject = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [alertInfo, setAlertInfo] = React.useState({
    msg: "",
    color: "",
  });

  const [invitationInfo, setInvitationInfo] = React.useState({
    project_name: "",
    email: "",
    expires_in: "",
    isValid: false,
    invalid: false,
  });
  const { invite_token } = useQueryParams();

  useEffect(() => {
    document.title = t("public.joinProject.pageTitle").format(
      invitationInfo?.project_name ? ` (${invitationInfo?.project_name})` : ""
    );
  }, [invitationInfo, t]);
  useEffect(() => {
    if (!invite_token) {
      TokenHelper.getJwtIfValid().then((jwt) => {
        if (jwt) {
          HistoryHelper.push("/dashboard");
        }
      });
    } else {
      dispatch(getInvitationInfo(invite_token))
        .then((res) => {
          const expiresIn = DateHelper.getDateTime(res.expires_in);
          const now = DateHelper.getDateTime();
          const isValid = expiresIn > now;
          setInvitationInfo((prev) => ({ ...prev, ...res, isValid }));

          if (!isValid) {
            setAlertInfo({
              msg: t("public.joinProject.invitationExpiredAlert"),
              color: "error",
            });
          }
        })
        .catch(() => {
          setInvitationInfo({
            invalid: true,
          });
          setAlertInfo({
            msg: t("public.joinProject.invalidInvitationAlert"),
            color: "error",
          });
        });
    }
  }, [dispatch, invite_token, t]);

  const disabled = (invite_token && !invitationInfo.isValid) || alertInfo.color === "success";

  const handleClickJoinProject = async () => {
    let alertInfo = {};

    setLoading(true);
    try {
      await dispatch(joinProject(invite_token));
      alertInfo = {
        msg: t("public.joinProject.successAlert"),
        color: "success",
      };
    } catch (error) {
      alertInfo = {
        msg: error.response?.data?.error || error?.message,
        color: "danger",
      };
    }

    setLoading(false);
    setAlertInfo(alertInfo);
  };

  useEffect(() => {
    let timer;
    if (invite_token && alertInfo.color === "success") {
      timer = setTimeout(() => {
        HistoryHelper.push("/login");
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [invite_token, alertInfo.color]);

  return (
    <StyledDiv>
      <div>
        <PalLink noPrefix to="/">
          <Logo />
        </PalLink>
      </div>
      <div className="input-box">
        <Grid container columnSpacing={"15px"} rowSpacing={"25px"}>
          <Grid item xs={12}>
            <div className="title">{t("public.joinProject.title")}</div>
            <div className="desc">{t("public.joinProject.description")}</div>
            <div className="company">{!!invitationInfo?.project_name && `${invitationInfo?.project_name}`} </div>
          </Grid>
          {alertInfo?.msg && (
            <Grid item xs={12}>
              <Alert fullWidth severity={alertInfo.color}>
                {alertInfo.msg}
              </Alert>
            </Grid>
          )}

          <Grid item xs={12}>
            <PalButton
              fullWidth
              className="register-btn"
              disabled={disabled}
              loading={loading}
              variant="contained"
              onClick={handleClickJoinProject}
            >
              {t("public.joinProject.joinButton")}
            </PalButton>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </StyledDiv>
  );
};

export default JoinProject;
