import PRSwitch from "~components/Generic/PRSwitch";

import Module from "./Module";
import ModuleIntegration from "./ModuleIntegration";

function Integration() {
  const routes = [
    { path: "/integration/module/", component: Module },
    { path: "/integration/module-integration/", component: ModuleIntegration },
    { path: "/integration", to: "/integration/module", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}

export default Integration;
