import React, { useEffect, useRef, useState } from "react";

import { PalButton as LibPalButton } from "@palamar/fe-library";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import styled from "@emotion/styled";
import { Box } from "@mui/material";

import { ReactComponent as Logo } from "~assets/images/palmate-logo.svg";
import Footer from "~components/Layout/Footer";
import PalLink from "~components/mui/PalLink";
import AlertHelper from "~helpers/AlertHelper";
import DateHelper from "~helpers/DateHelper";
import { DynamicReportContent } from "~pages/Statistics/StatisticGenerator/StatisticGeneratorReportList/DynamicReportChartModal";
import { getPublicStatistic } from "~store/statistics/actions";

const Title = styled.div`
  color: ${(props) => props.theme.palette.primary.dark};
  font-weight: 600;
  font-size: 36px;
  font-family: Museo Sans;
  line-height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const PrintButton = styled(LibPalButton)`
  font-size: 18px;
  border-radius: 26px;
  font-weight: bold;
  background-color: ${(props) => props.theme.palette.primary.light};
  border-color: ${(props) => props.theme.palette.primary.light};
  max-width: 150px;
  height: 31px;
  margin-top: -31px; //height of the button
  ${(props) => props.theme.breakpoints.down("md")} {
    max-width: 100%;
    margin-top: 10px;
  }
`;

const StyledDiv = styled.div`
  /* font-family: Museo Sans; */
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: absolute;
  width: 100%;
  height: calc(100% - 80px);
  padding: 30px 50px;

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 30px 20px;
  }

  overflow-y: auto;
  svg {
    flex: 0 0 auto;
  }
  .report-box {
    margin: 5px auto auto auto;
    ${(props) => props.theme.breakpoints.down("md")} {
      max-width: 100%;
      margin: 30px auto auto;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .report-detail {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    background-color: #f7f7f7;
    padding: 35px 35px 30px;
    max-width: ${(props) => props.theme.breakpoints.values.lg}px;

    ${(props) => props.theme.breakpoints.down("md")} {
      padding: 35px 15px 30px;
    }

    .report-card {
      border-radius: 8px;
      background-color: #fff;
      margin-bottom: 30px;
      padding: 20px;
      font-size: 16px;
      font-weight: 300;
      line-height: 19px;
      color: #000;
      white-space: pre-wrap;
      word-wrap: break-word;
      &.chat-box {
        overflow: hidden;
        max-height: min(420px, 100vh - 420px);
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        .chat-area {
        }
      }
    }
    .report-card-title {
      padding: 0 20px;
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 22px;
      color: #000;
    }
  }
`;

export default function CustomerReport() {
  const { key } = useParams();
  const [reportData, setReportData] = useState();
  const dispatch = useDispatch();
  const printRef = useRef();

  const { t } = useTranslation();

  useEffect(() => {
    if (reportData?.project_name) {
      document.title = `${t("public.customerReport.pageTitle")}: ${reportData?.project_name} - ${reportData?.generator_name} - ${t(reportData?.report_name)}`;
    } else {
      document.title = t("public.customerReport.pageTitle");
    }
  }, [t, reportData]);
  useEffect(() => {
    const browserLang = navigator?.language || navigator?.userLanguage || "en";
    i18next.changeLanguage(browserLang);
  }, []);

  useEffect(() => {
    if (!key) {
      AlertHelper.showError(t("public.customerReport.reportNotFound"));
      return;
    }
    dispatch(getPublicStatistic(key))
      .then((result) => {
        setReportData(result);
      })
      .catch(() => {
        setReportData({});
      });
  }, [key, t, dispatch]);

  const handlePrint = () => {
    const printContent = printRef.current;
    const WinPrint = window.open("", "", "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0");

    WinPrint.document.write(printContent.innerHTML);
    // Copy <link>
    document.querySelectorAll("link[rel='stylesheet']").forEach((link) => {
      const newLink = WinPrint.document.createElement("link");
      newLink.rel = "stylesheet";
      newLink.href = link.href;
      WinPrint.document.head.appendChild(newLink);
    });

    // Copy <style>
    document.querySelectorAll("style").forEach((style) => {
      const newStyle = WinPrint.document.createElement("style");
      newStyle.textContent = style.textContent;
      WinPrint.document.head.appendChild(newStyle);
    });

    const customStyle = WinPrint.document.createElement("style");
    customStyle.textContent = `
    body, html {
      overflow: auto !important;
      height: auto !important;
      display: block !important;
      position: static !important; 
     
    }
    .echarts-for-react{
      transform: scale(0.65) !important;
      transform-origin: 0 0 !important;
    }
    .markdown-body{
      padding-right: 10px;
    }
  `;
    WinPrint.document.head.appendChild(customStyle);

    const viewportMeta = WinPrint.document.createElement("meta");
    viewportMeta.name = "viewport";
    viewportMeta.content = "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0";
    WinPrint.document.head.appendChild(viewportMeta);

    WinPrint.document.body.classList.add("markdown-wrapper");

    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
  };

  if (!key) return null;
  return (
    <StyledDiv>
      <div>
        <PalLink noPrefix to="/">
          <Logo />
        </PalLink>
      </div>
      <div className="report-box">
        <Title>
          {reportData?.project_name} - {t(reportData?.generator_name)}
        </Title>
        <Box alignItems={"center"} display="flex" justifyContent={"flex-end"} width="100%">
          <PrintButton fullWidth loading={false} variant="contained" onClick={handlePrint}>
            {t("public.customerReport.print")}
          </PrintButton>
        </Box>
        <div className="report-detail">
          <div className="report-card-title">
            <Box alignItems={"center"} display="flex" justifyContent={"space-between"} width="100%">
              <Box component="span" fontWeight={600}>
                {reportData?.report_name}
              </Box>
              <Box component="span" fontWeight={300} marginLeft={2}>
                {DateHelper.getDateTimeLocal(reportData?.created).format("LLT")}
              </Box>
            </Box>
          </div>
          <div className="report-card">
            <DynamicReportContent ref={printRef} reportJson={reportData?.data || []} />
          </div>
        </div>
      </div>
      <Footer />
    </StyledDiv>
  );
}
