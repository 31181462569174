import { useEffect, useMemo, useState } from "react";

import { isEqual, cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Col, FormGroup, Label, Row } from "reactstrap";

import { Grid } from "@mui/material";

import PRContainer from "~components/Generic/PRContainer";
import PRInput from "~components/Generic/PRInput";
import { chatbotSessionStatus, livechatCallcenterType } from "~constants";
import {
  selectAgentInfo,
  selectCallcenterSettings,
  selectLivechatLoadingStatus,
} from "~store/socket/livechat/selectors";

import SessionListItem from "./SessionListItem";

import "./style.scss";

export default function SessionList({
  sessions,
  selectedSession: selectedSessionProp,
  onClick,
  hideHighlight,
  footerComponent,
  reverseOrder,
  visibleStatusList,
  nonVisibleStatusList,
  showAssignedToMeOption,
}) {
  const { t } = useTranslation();
  const [selectedSession, setSelectedSession] = useState(selectedSessionProp);
  const [showOnlyAssignedToMe, setShowOnlyAssignedToMe] = useState(false);

  const loading = useSelector(selectLivechatLoadingStatus);
  const agentInfo = useSelector(selectAgentInfo);
  const callcenterSettings = useSelector(selectCallcenterSettings);

  useEffect(() => {
    if (!isEqual(selectedSession, selectedSessionProp)) {
      setSelectedSession(selectedSessionProp);
    }
  }, [selectedSessionProp, selectedSession]);

  const visibleSessions = useMemo(() => {
    let filteredSessions = cloneDeep(sessions);

    //TODO. Investigate why there are duplicates if there are any
    const duplicatedSessions = [];
    //remove possible duplicates
    filteredSessions = filteredSessions.filter((session, index, self) => {
      if (self.findIndex((s) => s.id === session.id) === index) {
        return true;
      } else {
        duplicatedSessions.push(session);
        return false;
      }
    });
    if (duplicatedSessions.length) {
      console.warn("[LIVECHAT] Duplicated sessions found and removed", duplicatedSessions);
    }

    if (Array.isArray(visibleStatusList)) {
      filteredSessions = filteredSessions.filter((session) => visibleStatusList.includes(session.session_status));
    }
    if (Array.isArray(nonVisibleStatusList)) {
      filteredSessions = filteredSessions.filter((session) => !nonVisibleStatusList.includes(session.session_status));
    }

    if (callcenterSettings?.callcenter_type === livechatCallcenterType.FIFO) {
      return filteredSessions;
    }
    let currentActiveChattingSessions = [];
    let otherActiveChattingSessions = [];
    for (const session of filteredSessions) {
      if (session.session_status === chatbotSessionStatus.AGENT_CHAT && session?.agent?.id === agentInfo?.id) {
        currentActiveChattingSessions.push(session);
      } else {
        otherActiveChattingSessions.push(session);
      }
    }

    if (reverseOrder) {
      otherActiveChattingSessions = otherActiveChattingSessions.reverse();
    }

    let mergedSessions = [...currentActiveChattingSessions];

    if (!showOnlyAssignedToMe) {
      mergedSessions.push(...otherActiveChattingSessions);
    }
    return mergedSessions;
  }, [
    callcenterSettings?.callcenter_type,
    showOnlyAssignedToMe,
    sessions,
    reverseOrder,
    visibleStatusList,
    nonVisibleStatusList,
    agentInfo?.id,
  ]);

  const handleCheckedShowAssignedToMe = (e) => {
    setShowOnlyAssignedToMe(e.target.checked);
  };
  return (
    <div className="pr-livechat-sessionlist">
      <Row className="scrollbar-container g-0 flex-nowrap flex-column overflow-hidden">
        <Col className="scrollbar-item flex-shrink-1">
          {showAssignedToMeOption && !!visibleSessions.length && (
            <div className="w-100 d-flex align-items-center justify-content-end">
              <FormGroup switch className="d-flex align-items-center me-3">
                <PRInput checked={showOnlyAssignedToMe} type="switch" onChange={handleCheckedShowAssignedToMe} />
                <Label size="md">{t("component.sessionList.showAssignedToMe")}</Label>
              </FormGroup>
            </div>
          )}
          <div className="session-group">
            <PRContainer bare loading={loading} style={{ minHeight: 80 }}>
              <Grid container gap={"10px"} marginBottom={"15px"} marginTop={"15px"}>
                {!!visibleSessions.length &&
                  visibleSessions.map((session, index) => (
                    <Grid key={session.id} item xs={12}>
                      <SessionListItem
                        hideHighlight={hideHighlight}
                        isCurrentAgentChatting={
                          session?.agent?.id === agentInfo?.id &&
                          session.session_status === chatbotSessionStatus.AGENT_CHAT
                        }
                        order={index}
                        selectedSession={selectedSession}
                        session={session}
                        onClick={onClick}
                      />
                    </Grid>
                  ))}
                {!visibleSessions.length && (
                  <Grid item xs={12}>
                    <div className="text-center text-muted">{t("component.sessionList.noActiveSession")}</div>
                  </Grid>
                )}
              </Grid>
            </PRContainer>
          </div>
        </Col>
        <Col xs="auto">{typeof footerComponent === "function" ? footerComponent() : footerComponent}</Col>
      </Row>
    </div>
  );
}
