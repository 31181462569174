import { apiUrlChannel } from "~constants";
import Network from "~helpers/Network";

import * as at from "./actionTypes";

export const setChannel = (payload) => ({
  type: at.SET_CHANNEL,
  payload,
});

export const setChannelList = (payload) => ({
  type: at.SET_CHANNEL_LIST,
  payload,
});

// ------------- API -------------

export const getChannel = (projectId, channelId) => (_dispatch) => {
  return Network.request(apiUrlChannel.get.format(projectId, channelId), {
    loading: true,
    onSuccess: setChannel,
  });
};

export const createOrUpdateChannel = (projectId, data) => (_dispatch, _getState) => {
  let url = apiUrlChannel.getList.format(projectId);
  if (data.id) {
    url += data.id + "/";
  }

  return Network.request(url, {
    method: data.id ? "PATCH" : "POST",
    data,
    successMsg: true,
    loading: true,
  });
};

export const getChannelList = (projectId) => (_dispatch) => {
  return Network.request(apiUrlChannel.getList.format(projectId), {
    loading: true,
    onSuccess: ({ results = [] }) => setChannelList(results),
  });
};
