import { useTranslation } from "react-i18next";
import { Badge } from "reactstrap";

import ConstantHelper from "~helpers/ConstantHelper";

export const ticketStatus = {
  WAITING: "WAI",
  PROGRESS: "PRG",
  RESOLVED: "RES",
  APPROVED: "APR",
};
export const ticketStatusMap = {
  [ticketStatus.WAITING]: "constants.ticket.waiting",
  [ticketStatus.PROGRESS]: "constants.ticket.inProgress",
  [ticketStatus.RESOLVED]: "constants.ticket.resolved",
  [ticketStatus.APPROVED]: "constants.ticket.approved",
};

export const ticketStatusOptions = [
  {
    label: function Label() {
      const { t } = useTranslation();
      return (
        <Badge className="font-size-12" color="danger">
          {t("constants.ticket.waiting")}
        </Badge>
      );
    },
    value: ticketStatus.WAITING,
  },
  {
    label: function Label() {
      const { t } = useTranslation();
      return (
        <Badge className="font-size-12" color="warning">
          <span className="text-dark">{t("constants.ticket.inProgress")}</span>
        </Badge>
      );
    },
    value: ticketStatus.PROGRESS,
  },
  {
    label: function Label() {
      const { t } = useTranslation();
      return (
        <Badge className="font-size-12" color="primary">
          {t("constants.ticket.resolved")}
        </Badge>
      );
    },
    value: ticketStatus.RESOLVED,
  },
  {
    label: function Label() {
      const { t } = useTranslation();
      return (
        <Badge className="font-size-12" color="success-600">
          {t("constants.ticket.approved")}
        </Badge>
      );
    },
    value: ticketStatus.APPROVED,
  },
];

// export const ticketStatusOptions = [
//   { value: ticketStatus.WAITING, label: ticketStatusMap[ticketStatus.WAITING] },
//   { value: ticketStatus.PROGRESS, label: ticketStatusMap[ticketStatus.PROGRESS] },
//   { value: ticketStatus.RESOLVED, label: ticketStatusMap[ticketStatus.RESOLVED] },
//   { value: ticketStatus.APPROVED, label: ticketStatusMap[ticketStatus.APPROVED] },
// ];

export const ticketStatusColorMap = {
  [ticketStatus.WAITING]: "danger",
  [ticketStatus.PROGRESS]: "warning",
  [ticketStatus.RESOLVED]: "primary",
  [ticketStatus.APPROVED]: "success-600",
};
export const ticketStatusColorTextMap = {
  [ticketStatus.WAITING]: "",
  [ticketStatus.PROGRESS]: "text-dark",
  [ticketStatus.RESOLVED]: "",
  [ticketStatus.APPROVED]: "",
};

export const [ticketWaitingAction, ticketWaitingActionOptions, ticketWaitingActionMap] = ConstantHelper.typify({
  AGENT_ACTION_WAITING: "constants.ticket.agentActionWaiting",
  CUSTOMER_ACTION_WAITING: "constants.ticket.customerActionWaiting",
});
