import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import { cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";
import { MdCheck } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { Box } from "@mui/material";

import ChatbotResponse from "~components/ChatbotResponse";
import { PRAccordionItem } from "~components/Generic/PRAccordion";
import PRAccordion from "~components/Generic/PRAccordion/PRAccordionBody";
import PRButton from "~components/Generic/PRButton";
import PRFormFeedback from "~components/Generic/PRFormFeedback";
import PRInput, { PRTextArea, PRTextAreaFormat } from "~components/Generic/PRInput";
import PRSelect from "~components/Generic/PRSelect";
import {
  chatbotGupshupTemplateStatus,
  chatbotGupshupTemplateType,
  chatbotGupshupTemplateTypeMap,
  organizationActionTypes,
  organizationActionTypesOptions,
} from "~constants";
import Utils from "~helpers/Utils";
import useMonacoScenarioEditorVariables from "~pages/ChatBot/DialogComponents/ScenarioManager/ScenarioManagerEdit/useMonacoScenarioEditorVariables";
import { WhatsappMapSelector, locateToUser } from "~pages/ContactCenter/LiveChat/StartNewSessionModal";
import { getActionList, getFilterList } from "~store/organization/actions";
import { selectActionList } from "~store/organization/selectors";
import { getWhatsappTemplateList } from "~store/platform/actions";
import { selectWhatsappTemplateList } from "~store/platform/selectors";
import { getChannelList } from "~store/settings/channels/actions";
import { selectChannelList } from "~store/settings/channels/selectors";
import { selectCurrentProject } from "~store/user/selectors";
import "./style.scss";
import { getIntellisenseData } from "~store/lowcode/actions";

const AccordionWrapper = memo(function ActionWrapper({ show, name, children }) {
  const { t } = useTranslation();
  if (!show) return children;
  return (
    <PRAccordion noHeaderBackground noSpacing separated>
      <PRAccordionItem defaultCollapsed={false} title={t("common.action")}>
        {children}
      </PRAccordionItem>
    </PRAccordion>
  );
});

function HighlightTemplateText({ text, params }) {
  const parts = text.split(/(\{\{\d+\}\})/g);

  let paramOrder = 0;
  return (
    <>
      {parts.map((part, index) => {
        const match = part.match(/\{\{(\d+)\}\}/);
        if (!match) return part;
        // const paramIndex = match[1] - 1;
        const paramIndex = paramOrder++;
        return (
          <span key={index} className="fw-bold text-primary">
            {params?.[paramIndex] || part}
          </span>
        );
      })}
    </>
  );
}

const defaultZoom = 15;
const defaultLocation = { lat: 37.870906, lng: 32.504787 };
export default forwardRef(
  /**
   * @param {object} props
   * @param {boolean} props.fromActionPage
   * @param {boolean} props.showAccordion
   * @param {Function} props.onClickSaveTemplate
   * @param {Function} props.onSubmit
   * @param {object[]} props.action
   * @param {any} ref
   */
  function ActionRule({ fromActionPage, showAccordion, onClickSaveTemplate, action, onSubmit }, ref) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const currentProject = useSelector(selectCurrentProject);
    const actionList = useSelector(selectActionList);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [codeEditor, setCodeEditor] = useState(null);
    const googleMapsRef = useRef(null);
    const googleInputRef = useRef(null);

    const channels = useSelector(selectChannelList);
    const [hasLocationPermission, setHasLocationPermission] = useState(false);

    const whatsappTemplateList = useSelector(selectWhatsappTemplateList);
    const whatsappTemplateOptions = useMemo(() => {
      return whatsappTemplateList.map((template) => ({
        ...template,
        disabled: template?.response_data?.status !== chatbotGupshupTemplateStatus.APPROVED,
      }));
    }, [whatsappTemplateList]);

    useEffect(() => {
      dispatch(getChannelList(currentProject?.id));
      dispatch(getIntellisenseData(currentProject?.id));
    }, [dispatch, currentProject?.id]);

    const isWhatsappChannelAvailable = useMemo(() => {
      const channel = channels.find(
        (item) => item.integration_type === "whatsapp" && currentProject.id === item.project
      );
      const isValid = !!channel?.name && !!channel?.number;
      return isValid;
    }, [channels, currentProject.id]);

    const validTypeOptions = organizationActionTypesOptions.map((item) => {
      return {
        ...item,
        disabled: item.value === organizationActionTypes.WHATSAPP && !isWhatsappChannelAvailable,
      };
    });
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        ...action,
        action_type: action?.action_type || organizationActionTypes.EMAIL,
        name: action?.name || "",
        title: action?.title || "",
        // announcement_name: action?.announcement_name || "",
        // whatsapp_template_type: action?.whatsapp_template_type || chatbotGupshupTemplateType.TEXT,
        whatsapp_multimedia_data: {
          //for image, document, video
          link: action?.whatsapp_multimedia_data?.link,

          //for document
          filename: action?.whatsapp_multimedia_data?.filename,

          //for location
          latitude: action?.whatsapp_multimedia_data?.latitude,
          longitude: action?.whatsapp_multimedia_data?.longitude,
          address: action?.whatsapp_multimedia_data?.address,
          name: action?.whatsapp_multimedia_data?.name,
        },
        whatsapp_parameters: action?.whatsapp_parameters || [],
        body: action?.body || "",
        active: true, // active: action?.active || true,
        is_template: (action?.is_template ?? fromActionPage) ? true : false,
        callback_response: action?.callback_response || {
          suggested_intents: [],
          ask_feedback: false,
          message_items: [],
        },
        multimedia_response: action?.multimedia_response || {
          suggested_intents: [],
          ask_feedback: false,
          message_items: [],
        },
        whatsapp_template: action?.whatsapp_template || null,
        // non stateful fields
        selectedWhatsappTemplate: null,
        defaultLocation: { ...defaultLocation },
        location: null,
        center: null,
        zoom: null,
        // addressInfo: {
        //   formattedAddress: null,
        //   addressParts: null,
        // },
      },
      validationSchema: Yup.object({
        name: Yup.string().when({
          is: () => !onClickSaveTemplate,
          then: Yup.string().required(t("component.formik.required").format(t("common.name"))),
        }),
        action_type: Yup.string().required(t("component.formik.required").format(t("common.actionType"))),

        title: Yup.mixed().when("action_type", {
          is: (action_type) => [organizationActionTypes.EMAIL].includes(action_type),
          then: Yup.string().required(t("component.formik.required").format(t("common.title"))),
        }),
        // announcement_name: Yup.mixed().when("action_type", {
        //   is: (action_type) => [organizationActionTypes.WHATSAPP].includes(action_type),
        //   then: Yup.string().required("Required"),
        // }),
        whatsapp_template: Yup.mixed().when("action_type", {
          is: (action_type) => [organizationActionTypes.WHATSAPP].includes(action_type),
          then: Yup.number().required(t("component.formik.required").format(t("common.whatsappTemplate"))),
        }),

        // whatsapp_template_type: Yup.mixed().when("action_type", {
        //   is: (action_type) => [organizationActionTypes.WHATSAPP].includes(action_type),
        //   then: Yup.string().required("Required"),
        // }),

        whatsapp_parameters: Yup.mixed().when("action_type", {
          is: (action_type) => [organizationActionTypes.WHATSAPP].includes(action_type),
          then: Yup.array().of(
            Yup.string()
              .nullable()
              .required(t("component.formik.required").format(t("common.parameter")))
          ),
        }),

        whatsapp_multimedia_data: Yup.object({
          link: Yup.string().test(
            "is-valid-link",
            t("component.formik.required").format(t("common.link")),
            (val, ctx) => {
              const selectedWhatsappTemplate = ctx.options.context.selectedWhatsappTemplate;
              if (
                ctx.options.context.action_type === organizationActionTypes.WHATSAPP &&
                [
                  chatbotGupshupTemplateType.IMAGE,
                  chatbotGupshupTemplateType.VIDEO,
                  chatbotGupshupTemplateType.DOCUMENT,
                ].includes(selectedWhatsappTemplate?.template_type)
              ) {
                if (!val) {
                  return ctx.createError({ message: t("component.formik.required").format(t("common.link")) });
                }
              }
              return true;
            }
          ),

          latitude: Yup.number().test(
            "is-valid-lat",
            t("component.formik.required").format(t("component.actionRule.latitude")),
            (val, ctx) => {
              const selectedWhatsappTemplate = ctx.options.context.selectedWhatsappTemplate;
              if (
                ctx.options.context.action_type === organizationActionTypes.WHATSAPP &&
                [chatbotGupshupTemplateType.LOCATION].includes(selectedWhatsappTemplate?.template_type)
              ) {
                if (isNaN(val) || val < -180 || val > 180) {
                  return ctx.createError({
                    message: t("component.formik.required").format(t("component.actionRule.latitude")),
                  });
                }
              }
              return true;
            }
          ),
          longitude: Yup.number().test(
            "is-valid-lng",
            t("component.formik.required").format(t("component.actionRule.longitude")),
            (val, ctx) => {
              const selectedWhatsappTemplate = ctx.options.context.selectedWhatsappTemplate;
              if (
                ctx.options.context.action_type === organizationActionTypes.WHATSAPP &&
                [chatbotGupshupTemplateType.LOCATION].includes(selectedWhatsappTemplate?.template_type)
              ) {
                if (isNaN(val) || val < -180 || val > 180) {
                  return ctx.createError({
                    message: t("component.formik.required").format(t("component.actionRule.longitude")),
                  });
                }
              }
              return true;
            }
          ),
        }),

        body: Yup.mixed().when("action_type", {
          is: (action_type) =>
            [
              organizationActionTypes.EMAIL,
              organizationActionTypes.SMS,
              organizationActionTypes.TELEGRAM,
              // organizationActionTypes.WHATSAPP,
            ].includes(action_type),
          then: Yup.string().required(t("component.formik.required").format(t("common.body"))),
        }),
        // multimedia_response: Yup.mixed().when("action_type", {
        //   is: (action_type) => [organizationActionTypes.TELEGRAM, organizationActionTypes.WHATSAPP].includes(action_type),
        //   then: Yup.object().test("must-be-valid", "At least one message item is required", (value) => {
        //     return value?.message_items?.length > 0;
        //   }),
        // }),
        callback_response: Yup.mixed().when("action_type", {
          is: (action_type) =>
            [organizationActionTypes.TELEGRAM, organizationActionTypes.WHATSAPP].includes(action_type),
          then: Yup.object().test("must-be-valid", t("component.actionRule.atLeastOneItem"), (value) => {
            return value?.message_items?.length > 0;
          }),
        }),
        // ...(showFilterSelection && {
        //   action_filter: Yup.object({
        //     id: Yup.number().required("Required"),
        //   }),
        // }),
      }),
      onSubmit: async (values) => {
        // delete values.selectedWhatsappTemplate;
        onSubmit?.(values);
      },
    });

    useImperativeHandle(ref, () => ({
      handleSubmit: async () => {
        let errors = await formik.validateForm();
        formik.handleSubmit();
        if (Object.keys(errors).length > 0) {
          return false;
        }
        return formik.values;
      },
    }));

    useEffect(() => {
      //set initial selectedWhatsappTemplate obj
      if (formik.values?.action_type !== organizationActionTypes.WHATSAPP) return;

      const selectedWhatsappTemplate = whatsappTemplateOptions.find(
        (item) => item.id === formik.values.whatsapp_template
      );
      if (selectedWhatsappTemplate) {
        formik.setFieldValue("selectedWhatsappTemplate", selectedWhatsappTemplate);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      dispatch,
      formik.values.whatsapp_template,
      formik.values.action_type,
      whatsappTemplateOptions,
      formik.values.id,
    ]);

    useEffect(() => {
      if (!googleInputRef.current || formik.values.action_type !== organizationActionTypes.WHATSAPP) return;
      const selectedWhatsappTemplate = whatsappTemplateOptions.find(
        (item) => item.id === formik.values.whatsapp_template
      );
      if (selectedWhatsappTemplate.template_type === chatbotGupshupTemplateType.LOCATION) {
        googleInputRef.current.value = formik.values.whatsapp_multimedia_data?.address ?? "";

        const lat = formik.values.whatsapp_multimedia_data?.latitude;
        const lng = formik.values.whatsapp_multimedia_data?.longitude;
        formik.setFieldValue("whatsapp_multimedia_data.latitude", lat);
        formik.setFieldValue("whatsapp_multimedia_data.longitude", lng);
        if (!isNaN(lat) && !isNaN(lng)) {
          googleMapsRef.current?.panTo({ lat, lng });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      googleInputRef.current,
      dispatch,
      formik.values.whatsapp_template,
      formik.values.action_type,
      whatsappTemplateOptions,
    ]);

    useEffect(() => {
      dispatch(getFilterList(currentProject.id));
      dispatch(getActionList(currentProject.id)).then(({ results = [] }) => {
        if (results.length > 0) {
          setSelectedTemplate(results[0]);
        }
      });
    }, [dispatch, currentProject.id]);

    const onChangeSelect = (key) => (obj) => {
      let value = obj;
      if (key === "action_type") {
        value = obj?.value;
      }
      formik.setFieldValue(key, value);
    };

    const actionTypeValue = useMemo(() => {
      return validTypeOptions.find((item) => item.value === formik.values.action_type);
    }, [formik.values.action_type, validTypeOptions]);

    const handleChangeFormik = (key) => (e) => {
      let value = e.target ? e.target.value : e;
      formik.setFieldValue(key, value);
    };

    const handleChangeTemplate = (value) => {
      let template = Utils.getWithNonAllowedKeys(cloneDeep(value), ["id"]);
      template.is_template = false;
      setSelectedTemplate(template);
    };

    const handleChangeWhatsappTemplate = (obj) => {
      formik.setFieldValue("announcement_name", obj?.response_data?.elementName);
      formik.setFieldValue("whatsapp_template", obj?.id);

      if (formik.values.action_type === organizationActionTypes.WHATSAPP) {
        formik.setFieldValue("selectedWhatsappTemplate", obj);
      }
    };

    const handleApplyTemplate = () => {
      formik.setValues?.(selectedTemplate);
    };

    // const handleChangeLatLng = (key) => (data) => {
    //   formik.setFieldValue(`whatsapp_multimedia_data.${key}`, data.floatValue);
    // };
    const handleChangeWhatsappParameters = (index) => (e) => {
      let value = e.target.value;
      formik.setFieldValue(`whatsapp_parameters.${index}`, value);
    };

    const { templateLength, formattedTemplate } = useMemo(() => {
      //detect {{0}} fields as rexex;
      const regex = /{{+\d?}}/g;
      const templateFields = formik.values.selectedWhatsappTemplate?.response_data?.data?.match(regex);
      // const uniqueTemplateFields = [...new Set(templateFields)];
      // const matchLength = uniqueTemplateFields?.length || 0;
      const matchLength = templateFields?.length || 0; // All param fields must be filled even same args
      const formattedTemplateByParams =
        templateFields?.reduce((acc, field, index) => {
          const param = formik.values?.whatsapp_parameters?.[index];
          const paramValue = param || field;

          const replaceRegExp = new RegExp(Utils.escapeRegExp(field), "g");
          return acc.replace(replaceRegExp, paramValue);
        }, formik.values.selectedWhatsappTemplate?.response_data?.data) || "";

      return {
        templateLength: matchLength,
        formattedTemplate: formattedTemplateByParams,
      };
    }, [formik.values.selectedWhatsappTemplate, formik.values.whatsapp_parameters]);

    useEffect(() => {
      if (action?.whatsapp_parameters?.length) {
        formik.setFieldValue("whatsapp_parameters", action?.whatsapp_parameters);
      } else {
        formik.setFieldValue("whatsapp_parameters", new Array(templateLength).fill(""));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateLength, action?.whatsapp_parameters]);

    useEffect(() => {
      dispatch(getWhatsappTemplateList(currentProject.id));
    }, [dispatch, currentProject.id]);

    const autoPanMap = useCallback(() => {
      locateToUser().then((position) => {
        const focusCoords = { ...defaultLocation };
        if (position) {
          const { latitude, longitude } = position.coords;
          formik.setFieldValue("defaultLocation", { lat: latitude, lng: longitude });
          focusCoords.lat = latitude;
          focusCoords.lng = longitude;
          setHasLocationPermission(true);
        } else {
          setHasLocationPermission(false);
        }

        googleMapsRef.current?.panTo({ lat: focusCoords.lat, lng: focusCoords.lng });
        googleMapsRef.current?.setZoom(15);
      });
    }, []);

    useEffect(() => {
      if (formik.values.template?.template_type === chatbotGupshupTemplateType.LOCATION && googleMapsRef.current) {
        autoPanMap();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateLength, googleMapsRef, formik.values.template, autoPanMap]);

    const handleChangeLocation = (lat, lng) => {
      // formik.setFieldValue("location", { lat: lat, lng: lng });
      formik.setFieldValue("whatsapp_multimedia_data.latitude", lat);
      formik.setFieldValue("whatsapp_multimedia_data.longitude", lng);
    };

    const handleResetLocation = () => {
      formik.setFieldValue("location", null);
      googleMapsRef.current?.panTo(formik.values.defaultLocation);
      googleMapsRef.current?.setZoom(defaultZoom);
    };
    const handleOnMyLocation = () => {
      locateToUser().then((position) => {
        if (position) {
          const { latitude, longitude } = position.coords;
          googleMapsRef.current?.panTo({ lat: latitude, lng: longitude });
          googleMapsRef.current?.setZoom(defaultZoom);
          setHasLocationPermission(true);
        } else {
          setHasLocationPermission(false);
        }
      });
    };

    const handlePlaceSelected = (place) => {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      handleChangeLocation(lat, lng);
      // formik.setFieldValue("center", { lat, lng });
      googleMapsRef.current.panTo({ lat, lng });
    };
    const onMapLoad = useCallback(
      (map) => {
        googleMapsRef.current = map;
        autoPanMap();
      },
      [autoPanMap]
    );
    const handleClickMap = (e) => {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      handleChangeLocation(lat, lng);
      if (googleInputRef.current) {
        const geocoder = new window.google.maps.Geocoder();

        const latLng = new window.google.maps.LatLng(lat, lng);
        geocoder.geocode(
          {
            bounds: new window.google.maps.LatLngBounds(latLng),
            location: latLng,
          },
          (results, status) => {
            if (status === "OK") {
              const nonPlusCodeResult = results.find((result) => !result.plus_code);
              if (nonPlusCodeResult) {
                googleInputRef.current.value = nonPlusCodeResult?.formatted_address ?? "";
                // formik.setFieldValue("addressInfo", {
                //   addressParts: results,
                //   formattedAddress: nonPlusCodeResult?.formatted_address,
                // });
                formik.setFieldValue("whatsapp_multimedia_data.address", nonPlusCodeResult?.formatted_address);
              } else {
                console.log("No results found");
              }
            } else {
              console.log("Geocoder failed due to: " + status);
            }
          }
        );
      }
    };

    const handleChangeLocationName = (e) => {
      formik.setFieldValue("whatsapp_multimedia_data.name", e.target.value);
    };

    useMonacoScenarioEditorVariables(codeEditor?.editor);
    return (
      <>
        <Row className="mb-2 g-2 justify-content-end">
          <Col lg className="d-flex align-items-center " md="auto">
            <Label className=" w-100 text-lg-end" size="md">
              {t("component.actionRule.applyTemplate")}:
            </Label>
          </Col>
          <Col md className="d-flex align-items-center" lg="4">
            <PRSelect
              fullWidth
              labelSelector="name"
              options={actionList}
              value={selectedTemplate}
              valueSelector="id"
              onChange={handleChangeTemplate}
            />
            <PRButton
              className="ms-2"
              color="primary"
              disabled={!selectedTemplate}
              icon={MdCheck}
              tooltipText={t("component.actionRule.applyTemplate")}
              onClick={handleApplyTemplate}
            />
          </Col>
        </Row>
        <AccordionWrapper name={formik.values.name} show={showAccordion}>
          <Row className="mt-2 g-2">
            {!onClickSaveTemplate && (
              <Col lg="6" md="12">
                <Label className="" size="md">
                  {t("component.actionRule.templateName")}:
                </Label>
                <PRInput
                  invalid={formik.touched.name && formik.errors.name}
                  name="name"
                  type="text"
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Col>
            )}
            <Col lg={onClickSaveTemplate ? "12" : "6"} md="12">
              <Label className="" size="md">
                {t("common.type")}:
              </Label>
              <div className="d-flex align-items-center flex-nowrap gap-2">
                <PRSelect
                  fullWidth
                  invalid={formik.touched.action_type && formik.errors.action_type}
                  name="action_type"
                  options={validTypeOptions}
                  value={actionTypeValue}
                  onBlur={formik.handleBlur}
                  onChange={onChangeSelect("action_type")}
                />
                {[organizationActionTypes.WHATSAPP].includes(formik.values.action_type) && (
                  <div className="w-lg">
                    <PRButton link="/organization/action-templates/whatsapp-template-messages/">
                      {t("component.actionRule.manageTemplates")}
                    </PRButton>
                  </div>
                )}
              </div>
            </Col>
            {[organizationActionTypes.WHATSAPP].includes(formik.values.action_type) && (
              <>
                <Col md="12">
                  {/* <PRInput
                  invalid={formik.touched.announcement_name && formik.errors.announcement_name}
                  name="announcement_name"
                  type="text"
                  value={formik.values.announcement_name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                /> */}
                  <Label size="md">{t("common.template")}:</Label>
                  <PRSelect
                    invalid={formik.touched.whatsapp_template && formik.errors.whatsapp_template}
                    labelRenderer={(option) =>
                      `${
                        chatbotGupshupTemplateTypeMap[option?.template_type]
                          ? t(chatbotGupshupTemplateTypeMap[option?.template_type])
                          : option?.template_type
                      } - ${option?.response_data?.elementName}`
                    }
                    labelSelector="response_data.data"
                    name="whatsapp_template"
                    options={whatsappTemplateOptions}
                    value={formik.values.selectedWhatsappTemplate}
                    valueSelector="response_data.elementName"
                    onChange={handleChangeWhatsappTemplate}
                  />
                </Col>
                {/* <Col md="12">
                <Label className="" size="md">
                  Template Type
                </Label>
                <PRSelect
                  isPrimitiveValue
                  invalid={formik.touched.whatsapp_template_type && formik.errors.whatsapp_template_type}
                  isClearable={false}
                  name="whatsapp_template_type"
                  options={whatsappTemplateTypeOptions}
                  value={selectedWhatsappTemplate?.template_type}
                  onBlur={formik.handleBlur}
                  onChange={handleChangeWhatsappTemplateType}
                />
              </Col> */}
                {[
                  chatbotGupshupTemplateType.IMAGE,
                  chatbotGupshupTemplateType.VIDEO,
                  chatbotGupshupTemplateType.DOCUMENT,
                ].includes(formik.values?.selectedWhatsappTemplate?.template_type) && (
                  <Col md="12">
                    <Label className="" size="md">
                      {t("common.link")}:
                    </Label>
                    <PRInput
                      invalid={
                        formik.touched.whatsapp_multimedia_data?.link && formik.errors.whatsapp_multimedia_data?.link
                      }
                      name="whatsapp_multimedia_data.link"
                      type="text"
                      value={formik.values.whatsapp_multimedia_data?.link}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </Col>
                )}
                {[chatbotGupshupTemplateType.LOCATION].includes(
                  formik.values?.selectedWhatsappTemplate?.template_type
                ) && (
                  <>
                    <Col xs={12}>
                      <WhatsappMapSelector
                        defaultLocation={formik.values.defaultLocation}
                        disableMyLocation={!hasLocationPermission}
                        inputRef={googleInputRef}
                        locationName={formik.values.whatsapp_multimedia_data?.name}
                        mapContainerStyle={{
                          width: "100%",
                          height: "300px",
                        }}
                        markerProps={{
                          ...(!isNaN(formik.values.whatsapp_multimedia_data?.latitude) &&
                          !isNaN(formik.values.whatsapp_multimedia_data?.longitude)
                            ? {
                                position: {
                                  lat: formik.values.whatsapp_multimedia_data?.latitude,
                                  lng: formik.values.whatsapp_multimedia_data?.longitude,
                                },
                              }
                            : {}),
                        }}
                        options={{
                          disableDefaultUI: true,
                          zoomControl: true,
                        }}
                        onChangeName={handleChangeLocationName}
                        onClick={handleClickMap}
                        onLoad={onMapLoad}
                        onMyLocation={handleOnMyLocation}
                        onPlaceSelected={handlePlaceSelected}
                        onReset={handleResetLocation}
                      />
                      <PRFormFeedback
                        invalid={
                          (formik.touched.whatsapp_multimedia_data?.latitude ||
                            formik.touched.whatsapp_multimedia_data?.longitude) &&
                          (formik.errors.whatsapp_multimedia_data?.latitude ||
                            formik.errors.whatsapp_multimedia_data?.longitude) &&
                          t("component.formik.required").format(t("common.location"))
                        }
                      />
                    </Col>
                    {/* <Col md="6">
                    <Label className="" size="md">
                      Latitude
                    </Label>
                    <PRNumber
                      allowNegative
                      decimalScale={6}
                      onChange={handleChangeLatLng("latitude")}
                      // allowNegative={false}
                      // isNumericString
                      min={-90}
                      name={`whatsapp_multimedia_data.latitude`}
                      value={formik.values.whatsapp_multimedia_data?.latitude}
                      max={90}
                      // borderless
                    />
                  </Col>
                  <Col md="6">
                    <Label className="" size="md">
                      Longitude
                    </Label>
                    <PRNumber
                      allowNegative
                      decimalScale={6}
                      onChange={handleChangeLatLng("longitude")}
                      // allowNegative={false}
                      // isNumericString
                      min={-90}
                      name={`whatsapp_multimedia_data.longitude`}
                      value={formik.values.whatsapp_multimedia_data?.longitude}
                      max={90}
                      // borderless
                    />
                  </Col> */}
                  </>
                )}
                <Col xs={12}>
                  <Label>{t("common.body")}:</Label>
                  <Box
                    sx={{
                      minHeight: 50,
                      maxHeight: 250,
                      overflowY: "auto",
                      border: "1px dashed #ced4da",
                      borderRadius: "0.25rem",
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      padding: "0.375rem 0.75rem",
                    }}
                  >
                    <HighlightTemplateText
                      params={formik.values.whatsapp_parameters}
                      text={formik.values.selectedWhatsappTemplate?.response_data?.data || ""}
                    />
                  </Box>
                </Col>
                <Col md="12">
                  <div className="d-flex align-items-center justify-content-between">
                    <Label size="md">
                      {t("component.actionRule.templateParameters").format(templateLength.toString())}
                    </Label>
                  </div>

                  <div className="d-flex flex-column gap-2">
                    {templateLength > 0 ? (
                      formik.values?.whatsapp_parameters.map((param, index) => {
                        return (
                          <div key={index} className="d-flex align-items-center w-100">
                            <PRInput
                              className="w-100"
                              invalid={
                                formik.touched.whatsapp_parameters?.[index] &&
                                formik.errors.whatsapp_parameters?.[index]
                              }
                              value={param}
                              onChange={handleChangeWhatsappParameters(index)}
                            />
                            {/* <PRButton icon={MdDelete} onClick={handleRemoveParam(index)} color="danger" /> */}
                          </div>
                        );
                      })
                    ) : (
                      <div className="text-center"> - {t("component.actionRule.noParameters")} - </div>
                    )}
                  </div>
                </Col>
              </>
            )}
            {[
              organizationActionTypes.EMAIL,
              organizationActionTypes.PUSH_NOTIFICATION,
              // organizationActionTypes.WHATSAPP,
              organizationActionTypes.TELEGRAM,
            ].includes(formik.values.action_type) && (
              <>
                <Col md="12">
                  <Label className="" size="md">
                    {t("common.title")}
                  </Label>
                  <PRInput
                    invalid={formik.touched.title && formik.errors.title}
                    name="title"
                    type="text"
                    value={formik.values.title}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </Col>
              </>
            )}
            {[organizationActionTypes.EMAIL, organizationActionTypes.SMS, organizationActionTypes.TELEGRAM].includes(
              formik.values.action_type
            ) && (
              <>
                <Col md="12">
                  <Label className="" size="md">
                    {t("common.body")}
                  </Label>
                  <PRTextArea
                    editorMode
                    editorProps={{
                      defaultHeight: 400,
                      onMount: setCodeEditor,
                    }}
                    excludeFormatList={
                      organizationActionTypes.EMAIL === formik.values.action_type
                        ? [PRTextAreaFormat.plain, PRTextAreaFormat.markdown, PRTextAreaFormat.css]
                        : [PRTextAreaFormat.markdown, PRTextAreaFormat.html, PRTextAreaFormat.css]
                    }
                    format={
                      organizationActionTypes.EMAIL === formik.values.action_type
                        ? PRTextAreaFormat.html
                        : PRTextAreaFormat.plain
                    }
                    invalid={formik.touched.body && formik.errors.body}
                    name="body"
                    value={formik.values.body}
                    onBlur={formik.handleBlur}
                    onChange={handleChangeFormik("body")}
                  />
                </Col>
              </>
            )}
            <Col md="12">
              <div className="mt-2">
                <ChatbotResponse
                  label={t("component.actionRule.callbackResponse")}
                  messageItems={formik.values.callback_response?.message_items}
                  onChange={handleChangeFormik("callback_response.message_items")}
                />
                <PRFormFeedback invalid={formik.touched.callback_response && formik.errors.callback_response} />
              </div>
            </Col>
          </Row>
          {onClickSaveTemplate && (
            <>
              {/* <PRDivider className={"mt-1 mb-2"} color={"secondary"}></PRDivider> */}
              <Row className="mt-2 mb-2 g-2 justify-content-start">
                <Col className="gap-2 d-flex align-items-center" xs="auto">
                  <PRButton className="" color="secondary" onClick={onClickSaveTemplate}>
                    {t("component.actionRule.createTemplate")}
                  </PRButton>
                </Col>
              </Row>
            </>
          )}
        </AccordionWrapper>
      </>
    );
  }
);
