import { useCallback, useState } from "react";

import { withCardon } from "cardon";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";

import PRInput from "~components/Generic/PRInput";
import PRModal from "~components/Generic/PRModal";

function CreateAsTemplateModalContent({ get }) {
  const { t } = useTranslation();
  const [value, setValue] = useState("");

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  return (
    <PRModal
      submitText={t("common.create")}
      title={t("component.createAsTemplateModal.title")}
      onClick={get(value)}
      onClose={get(false)}
    >
      <Label htmlFor="slot-validation-text">{t("common.name")}:</Label>
      <PRInput placeholder={t("common.namePlaceholder")} type="text" value={value} onChange={handleChange} />
    </PRModal>
  );
}
const CreateAsTemplateModal = withCardon(CreateAsTemplateModalContent, { destroyOnHide: true });
export default CreateAsTemplateModal;
