import { PalIconButton, PalTooltip } from "@palamar/fe-library";
import { useTranslation } from "react-i18next";
import { MdMoreVert } from "react-icons/md";

const MoreButton = ({ onClick, id }) => {
  const { t } = useTranslation();
  return (
    <PalTooltip title={t("components.moreButton.tooltip")}>
      <PalIconButton color="primary" id="more-button" onClick={onClick}>
        <MdMoreVert />
      </PalIconButton>
    </PalTooltip>
  );
};

export default MoreButton;
