import { call, select, takeEvery } from "redux-saga/effects";

import { popupSettingsMapper } from "~constants";

import { SET_POPUP_SETTING_STATE } from "./actionTypes";
import { selectPopupSettingsState } from "./selectors";
const asyncReader = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      let data = e.target.result;
      resolve(data);
    };
    reader.onerror = (e) => {
      reject(e);
    };
    reader.readAsText(file);
  });
};
function* setChangesToPopupPreview() {
  const settingState = yield select(selectPopupSettingsState);

  const botName = (settingState.chatbot_list || Object.values(settingState.chatbot_status || {}))[0]?.name;

  const previewIframe = document.getElementById("_palamar_chat_iframe");
  const framePopup = previewIframe?.contentWindow?.popup;
  if (framePopup) {
    yield call([framePopup, framePopup.enablePreviewMessages], {
      botName: botName,
    });

    const welcomeFormBodyForPopup = {
      form_items: settingState.welcome_form?.form_items,
      popup_button_icon_path: settingState.popup_button_icon_path,
      popup_button_type: settingState.popup_button_type,
      popup_header_icon_path: settingState.popup_header_icon_path,
      popup_header_icon_type: settingState.popup_header_icon_type,
      popup_bot_icon_type: settingState.popup_bot_icon_type,
      popup_bot_icon_path: settingState.popup_bot_icon_path,
      popup_primary_color: settingState.popup_primary_color,
      popup_title: settingState.popup_title,
      popup_welcome_text: settingState.popup_welcome_text,
      start_anonymous_chat: settingState.welcome_form?.optional,
      welcome_msg: settingState.welcome_msg,
      popup_style_info: settingState.popup_style_info,
      chatbot_info: settingState.chatbot_info,
      system_chatbot_info: settingState.system_chatbot_info,
    };

    if (welcomeFormBodyForPopup.popup_button_icon_path instanceof File) {
      const file = welcomeFormBodyForPopup.popup_button_icon_path;
      const result = yield call(asyncReader, file);
      welcomeFormBodyForPopup.popup_button_icon_path = result;
    }

    const mappedData = popupSettingsMapper(welcomeFormBodyForPopup);
    framePopup.IFrameHelper.setPropertyToRoot([
      ["--webchat-icon-margin-x", `${mappedData.popup_style_info.popup_margin_x}px`],
      ["--webchat-icon-margin-y", `${mappedData.popup_style_info.popup_margin_y}px`],
      ["--webchat-icon-size", `${mappedData.popup_style_info.popup_icon_size}px`],
      ["--webchat-height", `${mappedData.popup_style_info.chat_height}px`],
    ]);
    framePopup.setWelcomeOptions(mappedData);
    framePopup.setViewModeDelayed(settingState.welcome_form?.optional ? "chat" : "welcome");
    framePopup.setWelcomeMsgShowedStatus(false);
    framePopup.setPopupSizeModePosition(mappedData?.popup_style_info?.popup_position);
  }
}

function* chatbotSaga() {
  yield takeEvery(SET_POPUP_SETTING_STATE, setChangesToPopupPreview);
}

export default chatbotSaga;
