import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

/**
 * @typedef {object} AnnouncementItem
 * @property {number} id
 * @property {string} title
 * @property {string} body
 * @property {string} start
 * @property {string} end
 * @property {string} announcement_type
 * @property {boolean} custom - Custom announcement is not fetched from the server
 */

export const initState = {
  /** @type {AnnouncementItem[]} */
  announcementList: [],
  /** @type {number[]} */
  acknowledgedAnnouncementIds: [],
};

const announcement = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_ANNOUNCEMENT_LIST:
        const filterNonCustomAnnouncement = action.payload.filter((announcement) => !announcement.custom);
        draft.announcementList = [...filterNonCustomAnnouncement, ...draft.announcementList];
        break;
      case at.READ_ANNOUNCEMENT:
        const availableIdList = draft.acknowledgedAnnouncementIds.filter((itemId) =>
          draft.announcementList.find((announcement) => announcement.id === itemId)
        );
        availableIdList.push(action.payload);
        draft.acknowledgedAnnouncementIds = availableIdList;

        break;

      case at.SET_ACKNOWLEDGED_ANNOUNCEMENT_IDS:
        draft.acknowledgedAnnouncementIds = action.payload;
        break;
      case at.APPEND_ANNOUNCEMENT:
        if (!draft.announcementList.some((announcement) => announcement?.id === action.payload?.id)) {
          draft.announcementList.push(action.payload);
        }
        break;
    }
  });

export default announcement;
