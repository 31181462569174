import React, { useCallback, useMemo, useRef, useState, useEffect, memo } from "react";

import i18next from "i18next";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { MdCheckBox, MdCheckBoxOutlineBlank, MdClose, MdExpandMore } from "react-icons/md";

import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";

import { useDebouncedEffect } from "~common/hooks/useDebounceEffect";
import Utils from "~helpers/Utils";

import PalCheckbox from "./PalCheckbox";
import PalTextField from "./PalTextField";

const StyledAutocomplete = styled(Autocomplete)`
  // border-radius: ${(props) => props.borderRadius}px;
  ${(props) => (props.disabled && "pointer-events: none;") || ""}
  .MuiInputBase-root {
    padding: 25px;
    padding-top: 7px;
    padding-bottom: 7px;

    background-color: #fcfcfc !important;
  }

  .MuiAutocomplete-tag {
    margin: 0px 0px;
  }
`;

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: "5px",
  borderRadius: 26,
  // background: "#FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: "2px 2px 4px #00000014",
  border: "1px solid #DDDDDD",
  font: "normal normal normal 16px/20px Museo Sans",
  letterSpacing: 0,
  color: "#535353",
  li: {
    borderRadius: "4px",
    margin: "6px 10px",
    minHeight: "42px",
    height: "auto",
    maxHeight: "100px",
  },
}));

const StyledPalTextField = styled(PalTextField)(({ theme }) => ({
  ".MuiInputBase-root": {
    padding: "0px 26px",
  },
}));

const isPrimitive = (value) => {
  return value !== Object(value);
};

class PalTimeoutError extends Error {
  constructor(message) {
    super(message || "Request timeout");
    this.name = "PalTimeoutError";
  }
}

/** @param {import("@mui/material/Autocomplete").AutocompleteProps} props */
const PalAutocomplete = ({
  invalid,
  labelSelector = "label",
  valueSelector = "value",
  labelRenderer,
  lazy,
  onChange,
  name,
  id,
  // noWrap,
  // menuPortal,
  value: valueProp,
  isPrimitiveValue,
  options: optionsProp,
  // isOptionReadonly,
  // fullWidth,
  multiple,
  loading: loadingProp,
  placeholder = i18next.t("select.placeholder"),
  disabled: disabledProp,
  loadOptions,
  // noBorder,
  // size = "md",
  // cacheOptions,
  defaultOptions,
  // classNamePortal,
  lazySearchDebounce = 500,
  isClearable = true,

  lazyLoadingTimeout = 5000,
  initialFetch = true,
  helperText,
  label,
  smallSpacing,
  borderRadius = 26,
  InputProps,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState(null);
  const [remoteOptions, setRemoteOptions] = useState([]);
  const abortControllerRef = useRef();
  const [key, setKey] = useState(Utils.getId());
  const previousValueRef = useRef(null);
  const [remoteLoading, setRemoteLoading] = useState(false);
  const [initialCachedOptions, setInitialCachedOptions] = useState(null);
  const [open, setOpen] = useState(false);
  const [textValue, setTextValue] = useState("");

  const { t } = useTranslation();

  const loading = loadingProp || remoteLoading;
  // const getOptionValue = useCallback((option) => get(option, valueSelector), [valueSelector]);
  const handleChange = useCallback(
    (e, item, reason, detail) => {
      // const primitiveValue = item?.[valueSelector];
      const primitiveValue = multiple ? item?.map((i) => get(i, valueSelector)) : get(item, valueSelector);
      const value = isPrimitiveValue ? primitiveValue : item;
      return onChange?.(e, value, reason, detail);
    },
    [valueSelector, onChange, isPrimitiveValue, multiple]
  );
  const { options, flattenOptions } = useMemo(() => {
    let options = optionsProp;

    if (!options?.length) {
      options = remoteOptions;
    }

    if (!options?.length) {
      options = defaultOptions;
    }

    if (lazy && !inputValue?.length && !valueProp) {
      options = defaultOptions?.length ? defaultOptions : initialCachedOptions || [];
    }

    const flattenOptions = options?.flatMap((option) => {
      if (option?.options) {
        return option?.options;
      }
      return option;
    });
    return {
      options: options ?? [],
      flattenOptions: flattenOptions,
    };
  }, [optionsProp, remoteOptions, inputValue, lazy, defaultOptions, initialCachedOptions, valueProp]);

  const disabled = loadingProp || disabledProp;
  const getDisabled = useCallback((option) => option?.disabled, []);

  const value = useMemo(() => {
    if (!isPrimitiveValue) {
      return valueProp;
    }

    if (multiple) {
      const multipleData = flattenOptions?.filter((option) => valueProp?.includes(get(option, valueSelector))) || [];
      return multipleData;
    }
    const opt = lazy
      ? remoteOptions?.length
        ? remoteOptions
        : Array.isArray(defaultOptions)
          ? defaultOptions
          : []
      : flattenOptions;

    let data = opt?.find((option) => get(option, valueSelector) === valueProp);
    if (!data && Array.isArray(defaultOptions)) {
      data = defaultOptions?.find((option) => get(option, valueSelector) === valueProp) || {};
    }
    const dataValue = get(data || {}, valueSelector, "");
    return dataValue;
  }, [defaultOptions, valueProp, valueSelector, isPrimitiveValue, flattenOptions, multiple, lazy, remoteOptions]);

  useEffect(() => {
    // Re-render when initial value is set for Lazy Select to trigger loadOptions function again
    if (!previousValueRef.current && valueProp && lazy) {
      previousValueRef.current = valueProp;
      setKey(Utils.getId());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazy]);

  const handleLoadOptions = useCallback(
    async (inputValue, callback) => {
      if (loadOptions) {
        try {
          abortControllerRef.current?.abort();
          const controller = new AbortController();
          abortControllerRef.current = controller;
          // await Utils.wait(lazySearchDebounce);
          if (controller.signal.aborted) return;
          let options = [];

          const abortPromise = new Promise((resolve, reject) => {
            setTimeout(() => {
              reject(new PalTimeoutError());
            }, lazyLoadingTimeout);
          });

          try {
            const optionsPromise = loadOptions(inputValue, callback, abortControllerRef.current.signal);

            const firstPromise = await Promise.race([abortPromise, optionsPromise]);
            if (!(firstPromise instanceof Error)) {
              options = await optionsPromise;
            }
          } catch (error) {
            if (error.name !== "PalTimeoutError") {
              console.error(error);
            }
          }
          setRemoteOptions(options);
          return options;
        } catch (error) {
          abortControllerRef.current?.abort();
          abortControllerRef.current = null;
        }
      }
      return [];
    },
    [loadOptions, lazyLoadingTimeout]
  );

  useEffect(() => {
    if (lazy && initialCachedOptions === null && initialFetch) {
      handleLoadOptions("").then((options) => {
        setInitialCachedOptions(options || []);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazy, initialFetch, initialCachedOptions]);

  // useImperativeHandle(ref, () => ({
  //   refresh: () => {
  //     setKey(Utils.getId());
  //   },
  // }));

  const handleOpenClose = (status) => (e) => {
    if (status && typeof rest.onOpen === "function") {
      rest.onOpen(e);
    } else if (!status && typeof rest.onClose === "function") {
      rest.onClose(e);
    }
    // if (!status) {
    //   onBlur?.({ target: { name, id, value }, type: "blur" }); // TODO: Fix this with real event
    // }
    setOpen(status);
  };
  useDebouncedEffect(
    () => {
      if (!lazy) return;
      if (!textValue?.length) return;
      setRemoteLoading(true);
      handleLoadOptions(textValue).then(() => {
        setRemoteLoading(false);
      });
    },
    [textValue],
    lazySearchDebounce
  );

  const RenderInput = useCallback(
    (props) => {
      const debouncedLazySearch = (e) => {
        if (!lazy) return;

        const value = e.target.value;
        setTextValue(value);
      };
      return (
        <StyledPalTextField
          {...props}
          borderRadius={borderRadius}
          helperText={helperText}
          InputProps={{
            ...props.InputProps,
            ...InputProps,
            endAdornment: (
              <>
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {props.InputProps.endAdornment}
                </React.Fragment>
              </>
            ),
          }}
          invalid={invalid}
          label={label}
          placeholder={multiple ? "" : loadingProp ? i18next.t("select.loading") + "..." : placeholder}
          smallSpacing={smallSpacing}
          onChange={debouncedLazySearch}
        />
      );
    },
    [
      label,
      lazy,
      invalid,
      smallSpacing,
      borderRadius,
      loadingProp,
      placeholder,
      multiple,
      helperText,
      loading,
      InputProps,
    ]
  );

  const getOptionLabel = useCallback(
    (optionValue) => {
      if (!optionValue) return "";
      let option = optionValue;
      if (isPrimitive(optionValue)) {
        option = options?.find((option) => get(option, valueSelector) === optionValue);
      }
      if (labelRenderer) {
        return labelRenderer(option) || "";
      }
      return get(option, labelSelector) || "";
    },
    [labelSelector, labelRenderer, options, valueSelector]
  );

  return (
    <StyledAutocomplete
      {...rest}
      key={key}
      clearIcon={<MdClose />}
      clearText={t("select.clear")}
      closeText={t("select.close")}
      disableClearable={!isClearable}
      disabled={disabled}
      getOptionDisabled={getDisabled}
      id={id}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => {
        let optionData = get(option, valueSelector);
        let valueData = isPrimitiveValue ? value : get(value, valueSelector);
        if (multiple && isPrimitiveValue && typeof value === "object") {
          valueData = get(value, valueSelector);
        }

        const isEqual = optionData === valueData;
        return isEqual;
      }}
      loading={loading}
      loadingText={`${t("select.loading")}...`}
      multiple={multiple}
      name={name}
      noOptionsText={t("select.noOptions")}
      options={options}
      PaperComponent={StyledPaper}
      popupIcon={<MdExpandMore />}
      renderInput={RenderInput}
      renderOption={(props, option, state, ownerState) => {
        const { selected } = state;
        return (
          <li {...props}>
            {multiple && (
              <PalCheckbox
                icon={<MdCheckBoxOutlineBlank />}
                checkedIcon={<MdCheckBox />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
            )}
            {get(option, labelSelector)}
          </li>
        );
      }}
      renderTags={(arr, getTagProps, ownerState) => {
        const valueObjArr = arr.map((option) => {
          if (isPrimitive(option)) {
            return options?.find((o) => get(o, valueSelector) === option);
          }
          return option;
        });

        return (
          <div>
            {valueObjArr.map((option, index, arr) => {
              const valueData = get(option, valueSelector);
              const labelData = get(option, labelSelector);
              return (
                <span key={valueData || index} {...getTagProps({ index })}>
                  {labelData}
                  {index !== arr.length - 1 ? ", " : ""}
                </span>
              );
            })}
          </div>
        );
      }}
      slotProps={{
        popupIndicator: {
          style: {
            height: 30,
            width: 30,
          },
        },
        clearIndicator: {
          style: {
            height: 30,
            width: 30,
          },
        },
        ...rest.slotProps,
      }}
      value={value}
      onClose={handleOpenClose(false)}
      onInputChange={(event, newInputValue, reason) => {
        setInputValue(newInputValue);
      }}
      onOpen={handleOpenClose(true)}
      getOptionLabel={getOptionLabel}
      // getOptionValue={getOptionValue}
      onChange={handleChange}
    />
  );
};

export default memo(PalAutocomplete);
