import { createRef, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { MdAdd, MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import { Badge, Col, Row } from "reactstrap";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRDivider from "~components/Generic/PRDivider";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import { apiUrlOrganization, organizationAnnouncementStatus, organizationAnnouncementStatusMap } from "~constants";
import DateHelper from "~helpers/DateHelper";
import { selectCurrentProject } from "~store/user/selectors";

export default function AnnouncementList() {
  const { t } = useTranslation();
  const currentProject = useSelector(selectCurrentProject);
  const tableRef = createRef();

  const columns = useMemo(() => {
    return [
      {
        label: t("common.name"),
        key: "name",
        render: (row) => <span>{row.name || "-"}</span>,
      },
      {
        label: t("dashboard.announcement.versions"),
        key: "versions",
        render: (row) => {
          const versions = row.versions || [];
          versions.sort((a, b) => a.version_id - b.version_id);
          return versions?.map((version) => (
            <PRTooltip
              key={version.id}
              title={
                <div className="text-nowrap font-size-12  ">
                  {/*TODO: Ask about Layout */}
                  <span className="text-center font-size-14">
                    {version.is_periodic ? "Periodic" : "One-time"} v{version.version_id}
                  </span>
                  <PRDivider />
                  <Row className="gx-2 align-items-center">
                    <Col className="text-end" xs="4">
                      {t("common.status")}:
                    </Col>
                    <Col className="text-start" xs="8">
                      <span className="fw-medium" size="md">
                        {organizationAnnouncementStatusMap[version.status] || version.status}
                      </span>
                    </Col>
                  </Row>
                  <Row className="gx-2 align-items-center">
                    <Col className="text-end" xs="4">
                      {version.is_periodic ? t("common.startDate") : t("dashboard.announcement.sendAt")}:
                    </Col>
                    <Col className="text-start" xs="8">
                      <span className="fw-medium" size="md">
                        {version.send_at ? DateHelper.getDateTimeLocal(version.send_at).format("LLT") : "-"}
                      </span>
                    </Col>
                  </Row>
                  {version.is_periodic && (
                    <>
                      <Row className="gx-2 align-items-center">
                        <Col className="text-end" xs="4">
                          {t("common.endDate")}:
                        </Col>
                        <Col className="text-start" xs="8">
                          <span className="fw-medium" size="md">
                            {DateHelper.getDateTimeLocal(version.valid_until).format("LLT")}
                          </span>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              }
            >
              <span>
                <Badge
                  className="me-1"
                  color={
                    organizationAnnouncementStatus.DONE === version.status
                      ? "success-600"
                      : organizationAnnouncementStatus.DRAFT === version.status
                        ? "secondary"
                        : organizationAnnouncementStatus.WAITING === version.status
                          ? "orange"
                          : organizationAnnouncementStatus.SCHEDULED === version.status
                            ? "primary"
                            : organizationAnnouncementStatus.PROCESSING === version.status
                              ? "purple"
                              : "secondary"
                  }
                >
                  v{version.version_id}
                </Badge>
              </span>
            </PRTooltip>
          ));
        },
      },
      {
        label: t("dashboard.announcement.announcementCount"),
        key: "histories",
        render: (row) => <span>{row.histories?.length || 0}</span>,
      },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        render: (row) => (
          <div className="d-flex justify-content-center gap-1">
            <PRButton
              outline
              color="primary"
              icon={MdEdit}
              link={`/organization/announcement/form/${row.id}`}
              size="sm"
              tooltipText={t("common.edit")}
            />
          </div>
        ),
      },
    ];
  }, [t]);

  const actions = useMemo(() => {
    return [
      {
        label: t("common.createNew"),
        icon: MdAdd,
        color: "success",
        link: "/organization/announcement/form",
      },
    ];
  }, [t]);

  const filters = useMemo(() => {
    return [];
  }, []);
  return (
    <PRContainer
      actions={actions}
      className=""
      description={t("dashboard.announcement.Description")}
      name={t("common.organization")}
      parentName={t("dashboard.announcement")}
    >
      <PRTable
        ref={tableRef}
        columns={columns}
        filters={filters}
        url={apiUrlOrganization.getAnnouncement.format(currentProject.id)}
      />
    </PRContainer>
  );
}
