import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";
import { isEqual } from "lodash";

import store from "~store";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

import CryptoHelper from "./CryptoHelper";
const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

const historyData = { order: -1, list: [] };
const previousReplaceOrPush = {
  push: null,
  replace: null,
};
const recursiveTriggerLimitCount = 10;
let isSentryLogged = false;
let recursiveHistoryCountMap = {};

setInterval(
  () => {
    isSentryLogged = false;
    recursiveHistoryCountMap = {};
  },
  1000 * 60 * 60 * 2
); // Clear the flags after 2hrs

window.addEventListener("popstate", function () {
  const currentPathname = window.location.pathname;

  for (let i = historyData.list.length - 1; i >= 0; i--) {
    const item = historyData.list[i];
    if (item.location?.pathname === currentPathname) {
      historyData.order = i;
      break;
    }
  }
});

export default class HistoryHelper {
  static getHistory = () => {
    return history;
  };

  /** @param {"current" | "back" | "forward"} type */
  static getHistoryUrl = (type) => {
    const order = historyData.order;
    if (type === "current") {
      return historyData.list?.[order]?.location?.pathname;
    } else if (type === "back") {
      return historyData.list?.[order - 1]?.location?.pathname;
    } else if (type === "forward") {
      return historyData.list?.[order + 1]?.location?.pathname;
    }
  };
  /**
   * @param {string | null} fallbackUrl Automatically go to this URL if there is no history
   * @param {object} options - Options
   */
  static goBack = (fallbackUrl, options) => {
    if (historyData.order === -1) {
      if (fallbackUrl) {
        HistoryHelper.push(fallbackUrl, options);
      }
      return;
    }

    history.goBack();
    historyData.order = Math.max(-1, historyData.order - 1);
  };

  static goForward = () => {
    history.goForward();
    historyData.order = Math.min(historyData.list.length - 1, historyData.order + 1);
  };

  static _getDashboardPrefix() {
    const state = store.getState();
    const currentProject = selectCurrentProject(state);
    const currentBot = selectCurrentBot(state);
    const urlPrefix = `/dashboard/project/${currentProject?.id || 0}/bot/${currentBot?.id || 0}`;
    return urlPrefix;
  }

  /** @type {typeof createBrowserHistory().push} */
  static push(location, { state, scope } = {}) {
    const locationItem = typeof location === "string" ? { pathname: location } : location;
    if (scope === "dashboard") {
      const urlPrefix = HistoryHelper._getDashboardPrefix();
      locationItem.pathname = `${urlPrefix}${locationItem.pathname}`;
    }

    if (
      !locationItem.pathname.endsWith("/") &&
      !locationItem.pathname.includes("?") &&
      !locationItem.pathname.includes("#")
    ) {
      locationItem.pathname = `${locationItem.pathname}/`;
    }

    if (locationItem.pathname.startsWith("#")) {
      const hashValue = locationItem.pathname;
      locationItem.hash = hashValue;
      locationItem.pathname = history.location.pathname;
      locationItem.search = history.location.search;
    }
    const isExactSameLocation = isEqual(previousReplaceOrPush.push, locationItem);
    const locationHash = CryptoHelper.calculateHash(locationItem);
    if (!isExactSameLocation || !(recursiveHistoryCountMap[locationHash] > recursiveTriggerLimitCount)) {
      if (isExactSameLocation) {
        recursiveHistoryCountMap[locationHash] = recursiveHistoryCountMap[locationHash] || 0;
        recursiveHistoryCountMap[locationHash]++;
      }
      history.push(locationItem, state);
      previousReplaceOrPush.push = locationItem;

      historyData.list = historyData.list.slice(0, Math.max(historyData.order, 0) + 1);
      historyData.list.push({ location: locationItem, state });
      historyData.order = historyData.list.length - 1;
    } else {
      if (!isSentryLogged) {
        Sentry.captureMessage(
          `Possible infinite loop detected in HistoryHelper.push() limit:${recursiveTriggerLimitCount}`,
          {
            level: "warning",
            extra: {
              locationItem,
              history: JSON.stringify(historyData),
              historyObj: JSON.parse(JSON.stringify(historyData)),
            },
          }
        );
        isSentryLogged = true;
      }
    }
  }

  /** @type {typeof createBrowserHistory().replace} */
  static replace(location, { state, scope } = {}) {
    const locationItem = typeof location === "string" ? { pathname: location } : location;

    if (scope === "dashboard") {
      const urlPrefix = HistoryHelper._getDashboardPrefix();
      locationItem.pathname = `${urlPrefix}${locationItem.pathname}`;
    }

    if (
      !locationItem.pathname.endsWith("/") &&
      !locationItem.pathname.includes("?") &&
      !locationItem.pathname.includes("#")
    ) {
      locationItem.pathname = `${locationItem.pathname}/`;
    }

    if (locationItem.pathname.startsWith("#")) {
      const hashValue = locationItem.pathname;
      locationItem.hash = hashValue;
      locationItem.pathname = history.location.pathname;
      locationItem.search = history.location.search;
    }
    const isExactSameLocation = isEqual(previousReplaceOrPush.replace, locationItem);
    const locationHash = CryptoHelper.calculateHash(locationItem);
    if (!isExactSameLocation || !(recursiveHistoryCountMap[locationHash] > recursiveTriggerLimitCount)) {
      if (isExactSameLocation) {
        recursiveHistoryCountMap[locationHash] = recursiveHistoryCountMap[locationHash] || 0;
        recursiveHistoryCountMap[locationHash]++;
      }
      history.replace(locationItem, state);
      previousReplaceOrPush.replace = locationItem;

      historyData.list = historyData.list.slice(0, Math.max(historyData.order, 0) + 1);
      historyData.list[historyData.order] = { location: locationItem, state };
      historyData.order = historyData.list.length - 1;
    } else {
      if (!isSentryLogged) {
        // Sentry.captureMessage(
        //   `Possible infinite loop detected in HistoryHelper.replace() limit:${recursiveTriggerLimitCount}`,
        //   {
        //     level: "warning",
        //     extra: {
        //       locationItem,
        //       history: historyData.list,
        //     },
        //   }
        // );
        isSentryLogged = true;
      }
    }
  }

  /**
   * @param {string} to Path to navigate to
   * @param {"dashboard"} scope Scope of the navigation
   * @returns {string} Generated URL
   */
  static generateUrl(to, scope = "dashboard") {
    let urlPrefix = "";
    if (scope === "dashboard") {
      urlPrefix = HistoryHelper._getDashboardPrefix();
    }
    const navigateUrl = `${urlPrefix}${to}`;
    return navigateUrl;
  }
}

// history.listen((location, action) => {
//   if (process.env.NODE_ENV === "development") {
//     console.log("%cHistory changed:", "color: gray", location, action);
//   }
// });
