import { useState } from "react";

import { withCardon } from "cardon";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Col, Label, Row } from "reactstrap";

import PRButton from "~components/Generic/PRButton";
import PRModal from "~components/Generic/PRModal";
import PRSelect from "~components/Generic/PRSelect";
import { getChatLabelList } from "~store/chatLabels/actions";
import { createOrUpdateChatLabel } from "~store/socket/livechat/actions";

function SetSessionLabelModalContent({ get, projectId, botId, sessionId, labelId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [label, setLabel] = useState(labelId);

  const handleSubmit = async () => {
    await dispatch(createOrUpdateChatLabel(projectId, sessionId, label));
    get({
      id: label,
    })();
  };

  const handleSearch = async (searchText, callback, signal, valueProp) => {
    const response = await dispatch(
      getChatLabelList(projectId, {
        params: {
          //   key__icontains: searchText,
          limit: 999, //TODO: support for key__icontains filter and reduce limit to 20
        },
        signal,
      })
    );

    return response?.results || [];
  };

  const handleClickDelete = async () => {
    await dispatch(createOrUpdateChatLabel(projectId, sessionId, null));
    get({
      id: 0,
    })();
  };

  //   const handleClickGoToLabels = () => {
  //     const newTabUrl = `/project/${projectId}/bot/${botId}/contactcenter/chat-labels`;

  //     window.open(newTabUrl, "_blank");
  //   };

  return (
    <PRModal
      size="lg"
      title={t("component.setSessionLabelModal.title")}
      onClick={handleSubmit}
      onClose={get(false)}
      onDelete={handleClickDelete}
    >
      <Row className="g-2">
        {/* <Col xs="12">
          <Label> Add text question to intent</Label>
        </Col> */}
        <Col className="d-flex justify-content-between align-items-center" xs="12">
          <Label>{t("common.label")}</Label>
          <PRButton
            link={`/dashboard/project/${projectId}/bot/${botId}/contactcenter/chat-labels`}
            linkProps={{
              newTab: true,
              scope: "",
              noPrefix: true,
            }}
          >
            {t("component.setSessionLabelModal.manageLabels")}
          </PRButton>
        </Col>

        <Col xs="12">
          <PRSelect
            isPrimitiveValue
            lazy
            menuPortal
            className="w-100"
            isClearable={false}
            labelSelector="key"
            loadOptions={handleSearch}
            name="label"
            placeholder={t("component.setSessionLabelModal.selectLabelPlaceholder")}
            value={label}
            valueSelector="id"
            onChange={setLabel}
          />
        </Col>

        {/* <Col xs="12">
          <PRButton
            icon={MdAdd}
            linkProps={{
              newTab: true,
            }}
            onClick={handleSubmit}
            // {...(addAs === "new"
            //   ? {
            //       link: `/chatbot/intent/form/?question=${textData}`,
            //       disabled: !textData,
            //     }
            //   : {
            //       link: `/chatbot/intent/form/${intent?.id}/?sample=${textData}`,
            //       disabled: !textData || !intent?.id,
            //     })}
          >
            Add{addAs === "new" ? " as new intent" : ""}
          </PRButton>
        </Col> */}
      </Row>
    </PRModal>
  );
}
const SetSessionLabelModal = withCardon(SetSessionLabelModalContent, { destroyOnHide: true });
export default SetSessionLabelModal;
