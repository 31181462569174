import { useEffect, useState } from "react";

import { withCardon } from "cardon";
import { ProgressBar } from "react-bootstrap";
import { useSelector } from "react-redux";

import { getBotSettings } from "~apiRequests/bot";
import PRModal from "~components/Generic/PRModal";
import PRTable from "~components/Generic/PRTable";
import DateHelper from "~helpers/DateHelper";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";
const DisplayLogs = ({ get }) => {
  const [reverseLogs, setReverseLogs] = useState({ logs: [], percent: 0 });

  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);

  useEffect(() => {
    function handleRefresh() {
      getBotSettings(currentProject.id, currentBot.id).then((response) => {
        if (response.training_procedure.logs) {
          let reverseLogs = {};
          reverseLogs.logs = response.training_procedure.logs.reverse();
          reverseLogs.percent = response.training_procedure.percent;
          setReverseLogs(reverseLogs);
        }
      });
    }

    const interval = setInterval(() => {
      handleRefresh();
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [currentBot.id, currentProject.id]);

  useEffect(() => {
    if (currentBot.id?.data === "no_data") return;
  }, [currentBot.id]);

  const columns = [
    {
      label: "Status",
      key: "text",
    },
    {
      label: "Time",
      key: "time",
      render: (value) => {
        return DateHelper.getDateTimeLocal(value.time).format("LLT");
      },
    },
  ];
  return (
    <PRModal size="lg" submitText={null} title={"Training Logs"} onClose={get(false)}>
      <div className="px-1" style={{ maxHeight: 250, minHeight: 100, overflowY: "auto" }}>
        {!!reverseLogs.logs.length && (
          <div className="mb-1">
            <ProgressBar striped now={reverseLogs.percent} variant="primary" />
          </div>
        )}

        <PRTable noPagination columns={columns} data={reverseLogs.logs} />
      </div>
    </PRModal>
  );
};

const DisplayLogsModal = withCardon(DisplayLogs, { destroyOnHide: true });
export default DisplayLogsModal;
