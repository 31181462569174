import { useCallback, useEffect, useState } from "react";

/**
 * @template T
 * @param {() => Promise<T>} asyncFunction
 * @param {any} immediate
 * @returns {{
 *   execute: () => void;
 *   status: "idle" | "pending" | "success" | "error";
 *   value: T;
 *   error: any;
 *   loading: boolean;
 * }}
 */
export default function useAsync(asyncFunction, immediate = true) {
  const [status, setStatus] = useState("idle");
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null); // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.

  const execute = useCallback(() => {
    setStatus("pending");
    setValue(null);
    setError(null);
    return asyncFunction()
      .then((response) => {
        setValue(response);
        setStatus("success");
      })
      .catch((error) => {
        setError(error);
        setStatus("error");
      });
  }, [asyncFunction]); // Call execute if we want to fire it right away.

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);
  return {
    execute,
    status,
    value,
    error,
    loading: status === "pending" || (status === "idle" && immediate),
  };
}
