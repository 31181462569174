import { useMemo, useRef } from "react";

import { useTranslation } from "react-i18next";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Label } from "reactstrap";

import useProjectChange from "~common/hooks/useProjectChange";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import { apiUrlChatbot, dialogComponentsEntityTypeFamilyMap, tableFilterStorageKey } from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import { deleteEntityType } from "~store/dialogComponents/entityTypes/actions";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

export default function EntityTypesList() {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const dispatch = useDispatch();

  useProjectChange(() => {
    tableRef.current.resetPagination();
  }, []);

  const columns = useMemo(() => {
    return [
      {
        label: t("common.name"),
        key: "name",
      },

      {
        label: t("common.family"),
        key: "family",
        render: (row) => {
          return t(dialogComponentsEntityTypeFamilyMap[row.family]);
        },
      },
      {
        label: t("common.entities"),
        key: "entities",
        render: (row) => {
          return (
            <>
              {row.entities?.map((entity) => {
                const entityList = (
                  <div
                    className="overflow-hidden"
                    style={{
                      maxHeight: 250,
                    }}
                  >
                    <Label className="font-size-12">{t("common.dictionaries")}</Label>
                    <ul>
                      {entity.dictionary?.map((item) => {
                        return <li key={item.id}>{item}</li>;
                      })}
                    </ul>
                  </div>
                );
                return (
                  <Badge key={entity.id} className="badge-soft-secondary me-1">
                    <PRTooltip disabled={!entity.dictionary?.length} title={entityList}>
                      <span className="font-size-12">
                        {entity.name}
                        {!!entity.dictionary?.length && <span className="ms-1">({entity.dictionary?.length}) </span>}
                      </span>
                    </PRTooltip>
                  </Badge>
                );
              })}
            </>
          );
        },
      },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        render: (row) => {
          const handleClickDelete = async () => {
            if (!(await DialogHelper.showQuestionDelete())) return;
            dispatch(deleteEntityType(currentProject.id, currentBot.id, row.id)).then(() => {
              tableRef.current.refresh();
            });
          };

          return (
            <div className="d-flex justify-content-center">
              <PRButton
                outline
                color="primary"
                icon={MdEdit}
                link={`/chatbot/entity-types/form/${row.id}`}
                size="sm"
                tooltipText={t("common.edit")}
              />
              <PRButton
                outline
                className="ms-1"
                color="danger"
                icon={MdDelete}
                size="sm"
                tooltipText={t("common.delete")}
                onClick={handleClickDelete}
              />
            </div>
          );
        },
      },
    ];
  }, [dispatch, currentProject, currentBot, t]);

  const actions = useMemo(() => {
    return [
      {
        label: t("common.createNew"),
        icon: MdAdd,
        color: "success",
        link: `/chatbot/entity-types/form/`,
      },
    ];
  }, [t]);

  const filters = useMemo(() => {
    return [
      {
        key: "entities__name",
        label: t("common.entities"),
      },
    ];
  }, [t]);

  const parentName = [
    {
      label: t("common.entityTypes"),
      url: "/chatbot/entity-types",
    },
  ];
  return (
    <PRContainer
      smalltalkSelector
      actions={actions}
      description={t("dashboard.entityTypeList.description")}
      name={t("common.chatbot")}
      parentName={parentName}
    >
      <PRTable
        ref={tableRef}
        columns={columns}
        filters={filters}
        storageKey={tableFilterStorageKey.entityTypesList}
        url={apiUrlChatbot.getEntityType.format(currentProject.id, currentBot.id)}
      />
    </PRContainer>
  );
}
