import { useState } from "react";

import { withCardon } from "cardon";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Col, Label, Row } from "reactstrap";

import PRButton, { PRButtonGroup } from "~components/Generic/PRButton";
import PRInput from "~components/Generic/PRInput";
import PRModal from "~components/Generic/PRModal";
import PRSelect from "~components/Generic/PRSelect";
import { getIntentList } from "~store/dialogComponents/scenarioManager/actions";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

const addAsOptions = [
  {
    value: "new",
    label: "New ",
  },
  {
    value: "existing",
    label: "Existing intent",
  },
];
function AddAsIntentModalContent({ get, text = "" }) {
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);

  const [intent, setIntent] = useState(null);
  const [addAs, setAddAs] = useState("new");
  const [textData, setTextData] = useState(text);

  const handleSubmit = () => {
    get()();
  };

  const handleSearch = async (searchText, callback, signal, valueProp) => {
    const response = await dispatch(
      getIntentList(currentProject.id, currentBot.id, {
        params: {
          text__icontains: searchText,
          limit: 20,
        },
        signal,
      })
    );

    return response?.results || [];
  };

  const handleTextChange = (e) => {
    setTextData(e.target.value);
  };
  return (
    <PRModal
      submitText={""}
      title="Create or Add New Intent Question"
      onClose={get(false)}
      //   onClick={handleSubmit}
      size="lg"
    >
      <Row className="g-2">
        <Col xs="12">
          <Label> Add text question to intent</Label>
          <div>
            <PRButtonGroup options={addAsOptions} value={addAs} onChange={setAddAs} />
          </div>
        </Col>
        <Col xs="12">
          <Label>Intent Sample</Label>
          <PRInput value={textData} onChange={handleTextChange}></PRInput>
        </Col>

        {addAs === "existing" && (
          <Col xs="12">
            <Label>Target Intent </Label>
            <PRSelect
              lazy
              menuPortal
              className="w-100"
              id="intent"
              isClearable={false}
              labelSelector="text"
              loadOptions={handleSearch}
              name="intent"
              placeholder="Search intent..."
              value={intent}
              valueSelector="id"
              onChange={setIntent}
            />
          </Col>
        )}

        <Col xs="12">
          <PRButton
            icon={MdAdd}
            linkProps={{
              newTab: true,
            }}
            onClick={handleSubmit}
            {...(addAs === "new"
              ? {
                  link: `/chatbot/intent/form/?question=${textData}`,
                  disabled: !textData,
                }
              : {
                  link: `/chatbot/intent/form/${intent?.id}/?sample=${textData}`,
                  disabled: !textData || !intent?.id,
                })}
          >
            Add{addAs === "new" ? " as new intent" : ""}
          </PRButton>
        </Col>
      </Row>
    </PRModal>
  );
}
const AddAsIntentModal = withCardon(AddAsIntentModalContent, { destroyOnHide: true });
export default AddAsIntentModal;
