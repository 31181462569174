import PRSwitch from "~components/Generic/PRSwitch";

import TicketDetail from "./TicketDetail";
import TicketList from "./TicketList";

function Ticket() {
  const routes = [
    { path: "/helpdesk/ticket/form/:uuid?", component: TicketDetail },
    { path: "/helpdesk/ticket/", component: TicketList },
  ];
  return <PRSwitch routes={routes} />;
}

export default Ticket;
