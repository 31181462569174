import classNames from "classnames";
import "./style.scss";
import chroma from "chroma-js";

function getRandomColorByIndex(index) {
  const color = "#28aae1";
  const colorHue = chroma(color).alpha(0.6);

  const colorHue50 = chroma(colorHue).set("hsl.h", -180).set("hsl.s", 0.4);
  const colorHue100 = chroma(colorHue).set("hsl.h", -120).set("hsl.s", 0.4);
  const colorHue150 = chroma(colorHue).set("hsl.h", -60).set("hsl.s", 0.4);
  const colorHueScale = chroma.scale([colorHue, colorHue50, colorHue100, colorHue150]).colors(4);

  return colorHueScale[index % colorHueScale.length];
}

const CodeBracket = ({ children, depth }) => {
  const randomColor = getRandomColorByIndex(depth);
  return (
    <span className={classNames("pr-code-bracket")}>
      <span
        className={"left"}
        style={{
          borderLeft: `2px solid ${randomColor}`,
        }}
      ></span>
      <span
        className={"underline-wrapper"}
        style={{
          borderBottom: `1px solid ${randomColor}`,
        }}
      >
        {children}
      </span>
      <span
        className={"right"}
        style={{
          borderRight: `2px solid ${randomColor}`,
        }}
      ></span>
    </span>
  );
};

export default CodeBracket;
