import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import classnames from "classnames";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  MdAssignmentInd,
  MdClose,
  MdInfoOutline,
  MdLabel,
  MdOutlineSchedule,
  MdOutlineWarningAmber,
  MdPlaylistAdd,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Col, Row } from "reactstrap";

import styled from "@emotion/styled";
import { Box, Paper, useScrollTrigger } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { ReactComponent as DirectToTicketIcon } from "~assets/images/icons/direct-to-ticket-white.svg";
import { ReactComponent as EyeOffIcon } from "~assets/images/icons/livechat-eye-off.svg";
import { ReactComponent as EyeIcon } from "~assets/images/icons/livechat-eye.svg";
import { ReactComponent as LivechatIcon } from "~assets/images/icons/livechat-white.svg";
import { ReactComponent as RobotIcon } from "~assets/images/icons/robot-white.svg";
import { ReactComponent as TakeCustomerIcon } from "~assets/images/icons/take-customer-white.svg";
import { ReactComponent as HistoryIcon } from "~assets/images/icons/webchat-history.svg";
import ProfileImg from "~assets/images/profile-img.png";
import useBootstrapBreakpoint from "~common/hooks/useBootstrapBreakpoint";
import LivechatPersonalDetailModal from "~common/modals/LivechatPersonalDetailModal";
import RedirectAgentModalModal from "~common/modals/RedirectAgentModal";
import PRButton from "~components/Generic/PRButton";
import PRChatMessage from "~components/Generic/PRChatMessage";
import { getChatDom } from "~components/Generic/PRChatMessage/ChatUtils";
import { domChatScrollContainerId } from "~components/Generic/PRChatMessage/constants";
import { usePRRefContext } from "~components/Generic/PRChatMessage/context";
import { PRDividerLabel } from "~components/Generic/PRDivider";
import PRLink from "~components/Generic/PRLink";
import PRPhoneNumber from "~components/Generic/PRPhoneNumber";
import PRPopover from "~components/Generic/PRPopover";
import PRTooltip from "~components/Generic/PRTooltip";
import PalButton from "~components/mui/PalButton";
import { chatbotSessionPlatform, chatbotSessionStatus } from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import { getCompletedSession, setIsGetterSetterDrawerOpen } from "~store/chathistory/actions";
import { selectIsGetterSetter } from "~store/chathistory/selectors";
import { getChatLabel, setChatLabel } from "~store/chatLabels/actions";
import { selectChatLabel } from "~store/chatLabels/selectors";
import { uploadFile } from "~store/socket/livechat/actions";
import { selectAgentInfo } from "~store/socket/livechat/selectors";
import { selectMuiTheme } from "~store/theme/selectors";
import { selectUserInfo } from "~store/user/selectors";

import AddAsIntentModal from "./AddAsIntentModal";
import LiveChatButton from "./LiveChatButton";
import SessionMetadataMessage from "./SessionMetadataMessage";
import TemplatePopup from "./TemplatePopup";
import { convertSocketMsgToChatMessage, getPlatformIcon, getStatusText, parseMetadata } from "../SessionUtils";

function SessionTime({ created, last }) {
  const { t } = useTranslation();
  const [renderer, setRerender] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setRerender((prev) => !prev);
    }, 1000);
    return () => clearInterval(interval);
  }, [created, last]);

  const visibleDate = useMemo(() => {
    if (!last && !created) return null;
    return DateHelper.getDateTimeLocal(last || created);
  }, [last, created, renderer]);

  return (
    <span>
      <PRTooltip
        title={
          <Box
            sx={{
              "tr > td": {
                padding: "0px 2px",
              },
            }}
          >
            <table>
              <tbody>
                <tr>
                  <td className="text-center" colSpan={3}>
                    <Box marginBottom={1}>
                      {t("component.sessionTime.tooltip1")}
                      <strong>{!!visibleDate && visibleDate.fromNow()} </strong>
                      {t("component.sessionTime.tooltip2")}
                    </Box>
                  </td>
                </tr>
                <tr>
                  <td>{t("component.sessionTime.firstConnection")}</td>
                  <td>:</td>
                  <td>{DateHelper.getDateTimeLocal(created).format("LLT")}</td>
                </tr>
                <tr>
                  <td>{t("component.sessionTime.lastMessage")}</td>
                  <td>:</td>
                  <td>{last ? DateHelper.getDateTimeLocal(last).format("LLT") : "N/A"}</td>
                </tr>
                <tr>
                  <td>{t("component.sessionTime.duration")}</td>
                  <td>:</td>
                  <td>
                    {last && created
                      ? DateHelper.formatMoment(
                          DateHelper.getDateTimeLocal(last).diff(DateHelper.getDateTimeLocal(created)),
                          "ms"
                        )
                      : "N/A"}
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
        }
      >
        <span className="d-flex align-items-center">
          <MdOutlineSchedule className="fs-5 me-1 " />
          <span>{visibleDate && visibleDate.fromNow(true)}</span>
        </span>
      </PRTooltip>
    </span>
  );
}
function GoToScenarioDefinitionButton({ text }) {
  const { t } = useTranslation();
  const handleClick = () => {
    AddAsIntentModal.show({ text });
  };
  return (
    <div className="go-to-scenario-button">
      <PRLink onClick={handleClick}>
        <PRTooltip title={t("component.goToScenarioDefinitionButton.tooltip")}>
          <div>
            <MdPlaylistAdd />
          </div>
        </PRTooltip>
      </PRLink>
    </div>
  );
}

function ShowHistoryPopoverContent({ session, onClick }) {
  const { t } = useTranslation();
  const allPreviousSession = useMemo(() => {
    let sessions = [...session.previous_sessions];
    if (session?.id) {
      sessions.push({
        ...session,
        bot_name: t("component.showHistoryPopoverContent.currentSession"),
        last_agent: session.agent,
        queue_join_time: session.queue_join_time,
      });
    }
    sessions = sessions.map((item) => ({ ...item, queue_join_time: DateHelper.getDateTime(item.queue_join_time) }));
    const orderedSessions = sessions.sort((a, b) => {
      return b.queue_join_time.isBefore(a.queue_join_time) ? -1 : 1;
    });

    return orderedSessions;
  }, [session]);

  const handleClickItem = (item) => () => {
    onClick?.(item);
  };
  return (
    <Paper
      elevation={3}
      sx={{
        maxHeight: 400,
        width: 350,
        overflowX: "hidden",
        overflowY: "auto",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#fff",
        borderRadius: 1,
        // border: "1px solid #e3e3e3",
        padding: 1,
        gap: 1,
      }}
    >
      {allPreviousSession?.map((item, index) => (
        <Box
          key={index}
          sx={{
            backgroundColor: "var(--bs-secondary-300)",
            "&:hover": {
              backgroundColor: "var(--bs-secondary-400)",
            },
            transition: "all 0.03s ease-in-out",
            borderRadius: "8px",
            width: "100%",
            cursor: "pointer",
            ...(session.id === item.id && {
              border: "1px solid var(--bs-primary)",
            }),
          }}
          onClick={handleClickItem(item)}
        >
          <Row className="p-1 gx-2 w-100 align-items-center flex-nowrap lh-1 ">
            <Col xs="auto"> {getPlatformIcon(item.platform)}</Col>
            {/* <Col xs="auto">{index + 1}.</Col> */}
            <Col xs="auto">
              <Badge color="primary">{item.last_agent?.name}</Badge>
            </Col>
            <Col xs className="text-truncate">
              <PRTooltip title={`${item.id} - ${item.bot_name}`}>{item.bot_name}</PRTooltip>
            </Col>
            <Col xs="auto">
              <PRTooltip title={DateHelper.getDateTimeLocal(item.queue_join_time).format("LLT")}>
                <span className="text-truncate">{DateHelper.getDateTimeLocal(item.queue_join_time).fromNow()}</span>
              </PRTooltip>
            </Col>
          </Row>
        </Box>
      ))}
    </Paper>
  );
}

const IDDivider = styled.div`
  background-color: rgba(0, 0, 0, 0.12);
  width: 2px;
  height: 17px;
`;
const FullHistoryButton = styled(PRButton)`
  background-color: #fff;
  font-family: Museo Sans;
  font-size: 12px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.85);
  border: none;
  padding-top: 2px;
  padding-bottom: 2px;
  &:hover,
  &:focus,
  &:active {
    background-color: #ffffffaa;
    color: rgba(0, 0, 0, 0.85);
  }
  svg {
    margin-left: 5px;
  }
  @media (max-width: 768px) {
    margin-right: -5px;
  }
`;
const MdLabelWithStroke = styled(MdLabel)`
  path:nth-of-type(2) {
    stroke-width: 2px;
    fill: transparent;
  }
`;

const MdInfoOutlineWithFill = styled(MdInfoOutline)`
  path:nth-of-type(2) {
    /* stroke-width: 1px; */
    fill: white;
  }
`;

const StyledDiv = styled.div`
  border-radius: 14px;
  background-color: ${({ noSession }) => (noSession ? "#fff" : "#f7f7f7")};
  padding: 10px;

  line-height: 1;
  .chat-form {
    display: flex;
    flex-direction: column;
    /* & > div:first-of-type {
      flex: 0 0 auto;
    }
    & > div:last-of-type {
      flex: 1 1 auto;
    } */
    /* border-radius: 5px;  */
    /* background-color: #fff; */
    position: relative;
    // min-height: 50vh;
    /* min-height: calc(100vh - 470px); */
    display: flex;
    .chat-wrapper {
      .chat-status-box {
        display: flex;
        margin: 0px 15px;
        align-items: center;
        gap: 6px;
        /* height: 28px; */
        background-color: #fff;
        margin: 15px;
        .status-text {
          flex: 0 0 auto;
          font-size: 12px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.55);
          line-height: 14px;
        }
        .placeholder-line {
          flex: 1 1 auto;
          border-radius: 6px;
          height: 12px;
          background-color: #f8f8f8;
          width: 100%;
        }
      }
      .chat-area {
        &::-webkit-scrollbar-track {
          background-color: #fefefe;
        }
      }
      border-radius: 14px;
      overflow: hidden;
      /* .chat-area {
        border-radius: 14px;
      } */

      background-color: #fff;
      width: 100%;
      /* padding-top: 142px; */
      .chat-toolbar {
        margin-bottom: 20px;
      }
      .chat {
        height: auto;
        .chat-area {
          height: calc(100vh - 570px);
          overflow-y: scroll;
        }
      }
    }
    @media (max-width: 992px) {
      min-height: calc(60vh - 100px);
      .chat-wrapper {
        .chat {
          .chat-area {
            height: calc(100vh - 390px);
          }
        }
      }
    }

    .header {
      /* box-shadow: 0px 2px 10px -3px #afafafa2; */
      // filter: drop-shadow(0px 5px 10px -3px #e3e3e3);
      /* margin-bottom: 20px; */
      /* position: absolute; */

      z-index: 20;
      .title-action {
        gap: 10px;
        padding-left: 15px;
        padding-right: 6px;
        align-items: flex-start;
        margin-top: 7px;
        & > div:first-of-type {
          margin-top: 3px;
        }
        .label-action {
          display: flex;
          margin-right: 5px;
          align-items: center;
          gap: 5px;
          .pr-button {
            width: 32px;
            height: 32px;
          }
        }
        .session-actions {
          display: flex;
          gap: 5px;
          .pr-button {
            width: 32px;
            height: 32px;
          }
          .btn-white {
            background-color: #fff;
          }
        }
        .customer-name {
          /* font-family: MuseoSans; */
          font-size: 16px;
          line-height: 19px;
          font-weight: 500;
          /* line-height: 1; */
          color: #000;
        }
        .customer-email {
          font-size: 13px;
          font-weight: 300;
          line-height: 15px;
        }
      }
      .label-box {
        padding-left: 15px;
        display: flex;
        align-items: center;
        height: 20px;
        .label-key {
          color: var(--bs-gray-600);
        }
      }
      .id-box {
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 6px;

        margin-top: 5px; //25px;
        display: flex;
        align-items: center;

        font-size: 13px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);

        line-height: 12px;
        gap: 14px;
      }
      .meta-box {
        padding-left: 15px;
        padding-right: 11px;
        margin-bottom: 15px;
        display: flex;
        @media (max-width: 768px) {
          flex-wrap: wrap;
          .meta-info {
            margin-top: 10px;
            margin-bottom: 15px;
          }
        }
        .row {
          gap: 14px;
        }
        .meta-info {
          gap: 10px;
        }
        .box-divider {
          background-color: rgba(0, 0, 0, 0.12);
          width: 2px;
          height: 20px;
        }
      }
    }
  }
  .no-chat {
    max-width: 100%;
    width: max-content;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .inner-markdown {
    & > div {
      //TODO: change auto width by "chat-container" container via ref observer
      width: 370px;
      overflow-x: auto;
    }
  }

  .go-to-scenario-button {
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 10;
    width: 18px;
    height: 18px;
    line-height: 22px;
    text-align: center;
    font-size: 12px;

    border-radius: 50%;
    opacity: 0.4;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    &:hover {
      opacity: 1;
      transform: scale(1.2);
    }
  }
`;

function SessionDateMessage({ date, isCurrentSession }) {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <Box
        className="d-flex align-items-center"
        sx={{
          font: "normal normal normal 18px/20px Museo Sans",
          letterSpacing: 0,
          color: "#838383",
          gap: "10px",
          textAlign: "center",
          width: "400px",
          mt: "20px",
          "& > div": {
            gap: "5px",
          },
          filter: "drop-shadow(0px 5px 10px -3px #e3e3e3)",

          ".text-area": {
            borderRadius: "20px",
            // padding: "1px 0px",
            backgroundColor: "#fff",
            minWidth: 250,
            "&-inner": {
              borderRadius: "20px",
              padding: "1px",
              border: !isCurrentSession ? "1px dashed var(--bs-secondary-400)" : `1px dashed var(--bs-primary-400)`,

              // textDecoration: "underline",
            },
          },
        }}
      >
        <Row className="w-100 g-0 align-items-center justify-content-center  flex-nowrap">
          {/* <Col xs>
            <SessionSeparator />
          </Col> */}
          <Col xs="auto">
            <div className={classnames("mx-2 text-area", { "text-primary": isCurrentSession })}>
              {/* Session - */}
              <div className={classnames("text-area-inner")}>
                {/* Session - */}
                {DateHelper.getDateTimeLocal(date).format("ll")}
              </div>
            </div>
          </Col>
          {/* <Col xs>
            <SessionSeparator />
          </Col> */}
        </Row>
      </Box>
    </div>
  );
}
function LoadMoreComponent({ previousSessions, setRelatedPreviousSessionList, chatRef }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const themeJson = useSelector(selectMuiTheme);

  const [lastScrollDirection, setLastScrollDirection] = useState("down");

  const distributedRef = usePRRefContext();
  const rootDom = document.getElementById(domChatScrollContainerId);
  const [loading, setLoading] = useState();
  const trigger = useScrollTrigger({
    threshold: 0,
    target: rootDom,
    disableHysteresis: true,
  });

  useEffect(() => {
    //handle mouse wheel direction
    const handleScroll = (e) => {
      if (e.deltaY > 0) {
        setLastScrollDirection("down");
      } else {
        setLastScrollDirection("up");
      }
    };
    rootDom.addEventListener("wheel", handleScroll);
    return () => {
      rootDom.removeEventListener("wheel", handleScroll);
    };
  }, [rootDom]);

  const theme = useMemo(() => {
    return createTheme(themeJson);
  }, [themeJson]);

  const firstPreviousSession = useMemo(() => {
    return previousSessions?.[0];
  }, [previousSessions]);

  const handleClickLoadSession = async (e) => {
    setLoading(true);
    if (distributedRef.current) {
      distributedRef.current.disableScrollOnMessage = true;
    }
    const promiseList = [];

    const sliceCount = e?.shiftKey ? 10 : 1;
    const slicedSessions = previousSessions.slice(0, sliceCount);
    for (const session of slicedSessions) {
      promiseList.push(dispatch(getCompletedSession(session.id, { disableState: true })));
    }

    const chatDom = getChatDom();
    chatDom.scrollTop = chatDom.scrollTop + 50;
    let results = (await Promise.all(promiseList)).map((result) => ({
      ...result,
      messages: [...result.messages].map((m) => ({
        ...m,
        created: DateHelper.getDateTime(m.message_time || m.created || m.time),
      })),
    }));

    results.reverse();
    if (results?.length) {
      setRelatedPreviousSessionList((prev) => {
        return [...results, ...prev];
      });

      //Ensure to be previous state update finished to start timeout
      setRelatedPreviousSessionList((prev) => {
        setLoading(false);
        setTimeout(() => {
          if (distributedRef.current) {
            distributedRef.current.disableScrollOnMessage = false;
          }
        }, 3000);
        return prev;
      });
    }
  };
  useEffect(() => {
    if (!trigger && !loading && lastScrollDirection === "up") {
      handleClickLoadSession();
    }
  }, [trigger, loading, lastScrollDirection]);

  return (
    // <Slide appear={false} direction="down" in={!trigger}>
    <ThemeProvider theme={theme}>
      <Box
        sx={(theme) => ({
          transform: trigger ? "scale(0.75)" : "scale(0.85)",
          // opacity: trigger ? 0.75 : 1,
          // paddingTop: trigger ? "0px" : "-10px",
          transition: "all 0.15s ease-in-out",
          ".MuiButton-root": {
            boxShadow: trigger ? theme.shadows[3] : "none",
          },
        })}
      >
        <Box
          sx={{
            // backgroundColor: "var(--bs-secondary-300)",
            // borderRadius: "10px",
            // width: "200px",
            // height: 30,
            padding: 1,
            textAlign: "center",
            marginTop: "-20px",
            width: 425,
            ".MuiButton-root": {
              backgroundColor: "#fff !important",
            },
          }}
        >
          <PalButton
            fullWidth
            color={trigger ? "secondary" : "primary"}
            curved={false}
            disabled={loading}
            loading={loading}
            size="small"
            variant="outlined"
            onClick={handleClickLoadSession}
          >
            {t("component.loadMoreComponent.loadPreviousSessions")}({previousSessions?.length}) -{" "}
            {DateHelper.getDateTime(firstPreviousSession?.queue_join_time).fromNow(true)} ago
          </PalButton>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

const SessionSeparator = styled.div`
  width: 100%;
  height: 0px;

  border: 1px solid #e8e8e8;
  opacity: 1;
`;

const StickyBox = styled(Box)`
  position: sticky;
  top: 0;
  /* background-color: #fff; */
  & > div {
    justify-content: center;
    width: 100%;
  }
  width: 100%;
  z-index: ${({ zIndex }) => zIndex || 400};
  ${(props) => props.hide && ` visibility: hidden;`}
`;

const StickySessionDateComponent = ({ children }) => {
  // const ref = useRef(null);
  // const rootDom = document.getElementById(domChatScrollContainerId);
  // const trigger = useScrollTrigger({
  //   threshold: 0,
  //   disableHysteresis: true,
  //   target: rootDom,
  // });

  return (
    <StickyBox //
      zIndex={300}
      // ref={ref}
      // hide={isInViewRange}
    >
      {children}
    </StickyBox>
  );
};
function SessionChat({
  onClickTake,
  onClickSend,
  onClickDirectToBot,
  onClickSendTicket,
  onClickCloseSession,
  onClickHistorySelect,
  onClickSetLabel,
  // onClickDirectToTicket,
  selectedSession: session,
  chatMessageProps = {},
  // disableActions,
  disableTextInput,
  hideActions,
  hideSendDirectToBot,
  hideSendTicket,
  hideOnline,
  // hideOutline,
  interactionInfo,
  onMessageChange,
  disableMetadataView,
  onInteraction,
  liveMode,
  hideLoadMore,
  scrollOnMessage,
}) {
  const dispatch = useDispatch();

  const [fileUploadStatuses, setFileUploadStatuses] = useState([]);
  const [chatMessagePropsState, setChatbotPropsState] = useState(chatMessageProps);
  const agentInfo = useSelector(selectAgentInfo);
  const userInfo = useSelector(selectUserInfo);
  const [interactionInfoState, setInteractionInfoState] = useState(interactionInfo);
  const [isChatbotReady, setIsChatbotReady] = useState(false);
  const chatRef = useRef(null);
  const [relatedPreviousSessionList, setRelatedPreviousSessionList] = useState([]);
  const { t } = useTranslation();
  const [focusOnRenderDisabled, setFocusOnRenderDisabled] = useState(false);
  const chatLabel = useSelector(selectChatLabel);

  const interactionOptions = useMemo(() => ({ listen: ["typing"] }), []);
  const isMd = useBootstrapBreakpoint("md");
  // const isLg = useBootstrapBreakpoint("lg");
  const isXxl = useBootstrapBreakpoint("xxl");
  const isGetterSetter = useSelector(selectIsGetterSetter);

  const { isCurrentAgentAvailable, isAgentChatting, isAnotherAgentChatting, isCurrentAgentChatting } = useMemo(() => {
    const isAgentChatting = session?.session_status === chatbotSessionStatus.AGENT_CHAT;
    return {
      isCurrentAgentAvailable: agentInfo?.status === "AV",
      isAgentChatting,
      isAnotherAgentChatting: isAgentChatting && session?.agent?.id && session?.agent?.id !== agentInfo?.id,
      isCurrentAgentChatting: isAgentChatting && session?.agent?.id && session?.agent?.id === agentInfo?.id,
    };
  }, [session, agentInfo]);

  const isSessionCompleted = [
    chatbotSessionStatus.CLOSED,
    chatbotSessionStatus.COMPLETED,
    chatbotSessionStatus.TIMEOUT,
    chatbotSessionStatus.FAILED,
    chatbotSessionStatus.CUSTOMER_DISCONNECT,
  ].includes(session?.session_status);

  const isSessionHasTerminateMessageAfterFeedback = useMemo(() => {
    const directToFeedbackMsg = session?.messages?.findIndex(
      (m) => m?.type === "action" && m.payload?.type === "direct_to_feedback"
    );
    const terminateMsg = session?.messages?.findIndex(
      (m) => m?.type === "action" && m.payload?.type === "terminate_session"
    );
    if (directToFeedbackMsg > -1 && terminateMsg > directToFeedbackMsg) {
      // If terminate message received after direct to feedback message, then the session has been terminated already.
      return true;
    }
  }, [session?.messages]);

  const disabledTextStatus =
    isSessionHasTerminateMessageAfterFeedback ||
    !isCurrentAgentChatting ||
    isSessionCompleted ||
    disableTextInput ||
    fileUploadStatuses.length > 0;
  const isActionDisabledCommon = !isCurrentAgentAvailable || isSessionCompleted;

  useEffect(() => {
    if (!session?.project || !session?.label) return;
    dispatch(
      getChatLabel(session?.project, session?.label, {
        loading: false,
      })
    );
    return () => {
      dispatch(setChatLabel(null));
    };
  }, [dispatch, session?.project, session?.label]);

  useEffect(() => {
    if (!_.isEqual(interactionInfo, interactionInfoState)) {
      setInteractionInfoState(interactionInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interactionInfo]);

  useEffect(() => {
    if (!_.isEqual(chatMessageProps, chatMessagePropsState)) {
      setChatbotPropsState(chatMessageProps);
    }
  }, [chatMessageProps, chatMessagePropsState]);

  const relatedPreviousSessions = useMemo(() => {
    const sessions = [...(session?.previous_sessions || [])];
    const nonFetchedSessions = sessions.filter((item) => !relatedPreviousSessionList.find((i) => i.id === item.id));

    const mappedSession = nonFetchedSessions.map((item) => ({
      ...item,
      queue_join_time: DateHelper.getDateTime(item.queue_join_time),
    }));
    const orderedSessions = mappedSession.sort((a, b) => {
      return b.queue_join_time.isBefore(a.queue_join_time) ? -1 : 1;
    });
    const currentSessionQueueJoinTime = DateHelper.getDateTime(session?.queue_join_time);
    const relatedSessions = orderedSessions.filter((item) => {
      return item.queue_join_time.isBefore(currentSessionQueueJoinTime);
    });
    return relatedSessions;
  }, [session?.previous_sessions, session?.queue_join_time, relatedPreviousSessionList]);

  useEffect(() => {
    setRelatedPreviousSessionList([]);
  }, [session?.id]);

  const mappedMessages = useMemo(() => {
    let oldSessionMessages = [];
    if (relatedPreviousSessionList?.length) {
      for (const prevSession of relatedPreviousSessionList) {
        const earliestTime = DateHelper.getDateTime(
          prevSession?.messages?.[0]?.created || prevSession?.queue_join_time
        );
        const latestTime = DateHelper.getDateTime(
          prevSession?.messages?.[prevSession?.messages?.length - 1]?.created || prevSession?.queue_join_time
        );

        const sessionDateMessageDate = earliestTime.clone().subtract(1, "milliseconds");

        const isAlreadySameDayExist = oldSessionMessages.find((item) => {
          const itemTime = DateHelper.getDateTime(item.created);
          return itemTime.isSame(sessionDateMessageDate, "day");
        });

        //Skip same day labels
        if (!isAlreadySameDayExist) {
          oldSessionMessages.push({
            type: "custom",
            position: "center",
            created: sessionDateMessageDate,
            isSingleGroup: true,
            component: StickySessionDateComponent,
            render: ({ distributedRef }) => {
              return <SessionDateMessage date={prevSession?.queue_join_time} />;
            },
          });
        }
        oldSessionMessages.push(...prevSession.messages);
        if (!prevSession.messages.length) {
          oldSessionMessages.push({
            type: "custom",
            position: "center",
            isSingleGroup: true,
            created: latestTime.clone().add(1, "milliseconds"),
            render: ({ distributedRef }) => {
              return (
                <div className="text-center">
                  <h5 className="text-center m-0 text-secondary opacity-50">{t("component.sessionChat.noMessages")}</h5>
                </div>
              );
            },
          });
        }
      }
    }
    if (oldSessionMessages.length) {
      oldSessionMessages = oldSessionMessages.map((m) => ({
        ...m,
        skipMessageScrollCounter: true,
      }));
      oldSessionMessages.push({
        type: "custom",
        position: "center",
        created: oldSessionMessages[oldSessionMessages.length - 1]?.created?.clone().add(1, "milliseconds"),

        isSingleGroup: true,
        skipMessageScrollCounter: true,
        component: StickySessionDateComponent,
        render: ({ distributedRef }) => {
          return <SessionDateMessage isCurrentSession date={session?.queue_join_time} />;
        },
      });
    }
    const messages = [
      ...(session?.messages || []).map((m) => ({
        ...m,
        created: DateHelper.getDateTime(m.created || m.message_time || m.time),
      })),
    ];
    const sessionMessages = [...oldSessionMessages, ...messages];
    sessionMessages.sort((a, b) => a.created - b.created);

    // const sortedSessionMessages = [...sessionMessages].sort((a, b) => {
    //   const aTime = DateHelper.getDateTime(a.messageTime || a.message_time || a.time);
    //   const bTime = DateHelper.getDateTime(b.messageTime || b.message_time || b.time);
    //   if (aTime.isBefore(bTime)) {
    //     return -1;
    //   } else if (aTime.isAfter(bTime)) {
    //     return 1;
    //   } else {
    //     return 0;
    //   }
    // });
    const firstMessageTime = DateHelper.getDateTime(sessionMessages[0]?.created || session?.created);
    if (!hideLoadMore && relatedPreviousSessions.length) {
      sessionMessages.unshift({
        type: "custom",
        position: "custom",
        created: firstMessageTime?.clone().subtract(3, "milliseconds"),
        isSingleGroup: true,
        component: StickyBox,
        render: ({ distributedRef }) => {
          return (
            <LoadMoreComponent
              chatRef={chatRef}
              distributedRef={distributedRef}
              previousSessions={relatedPreviousSessions}
              setRelatedPreviousSessionList={setRelatedPreviousSessionList}
            />
          );
        },
      });
    }

    if (!disableMetadataView) {
      sessionMessages.unshift({
        type: "custom",
        position: "center",
        created: firstMessageTime?.clone().subtract(1, "milliseconds"),
        render: ({ distributedRef }) => {
          return (
            <SessionMetadataMessage
              connections={session?.connections}
              customer={session.customer}
              distributedRef={distributedRef}
            />
          );
        },
      });
    }

    sessionMessages.sort((a, b) => a.created - b.created);
    const newSession = { ...session, messages: sessionMessages };
    const mappedMessageList = [];

    let previousMessage = null;
    for (const m of newSession.messages) {
      const convertedMsg = convertSocketMsgToChatMessage(m, previousMessage, newSession);
      if (!convertedMsg) continue;
      if (convertedMsg.position === "left" && convertedMsg.format === "plain" && !liveMode) {
        convertedMsg.footerComponent = (
          <>
            <GoToScenarioDefinitionButton text={convertedMsg.text} />
          </>
        );
      }

      mappedMessageList.push(convertedMsg);
      if (convertedMsg?.inputFormat?.cancelled) {
        mappedMessageList.push({
          type: "notification",
          position: "center",
          text: t("component.sessionChat.inputCancelled"),
          messageTime: convertedMsg.messageTime.clone().add(1, "ms"),
        });
      }
      previousMessage = m;
    }

    if (
      session?.session_status === chatbotSessionStatus.AGENT_WAIT ||
      session?.session_status === chatbotSessionStatus.BOT_CHAT_AGENT_WAIT
    ) {
      const handleClick = () => {
        onClickTake?.(session);
      };
      mappedMessageList.push({
        type: "custom",
        messageTime: DateHelper.getDateTime(),
        position: "center",
        render: () => (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <PRDividerLabel center className="text-muted my-2 font-size-12" color="secondary" style={{ width: 500 }}>
              <div className="d-flex align-items-center">
                {t("component.sessionChat.agentWaitMessage")}
                <MdOutlineWarningAmber className="ms-1" />
              </div>
            </PRDividerLabel>
            <div>
              <PRButton
                outline
                color="danger"
                disabled={!isCurrentAgentAvailable}
                icon={MdAssignmentInd}
                onClick={handleClick}
              >
                {t("component.sessionChat.takeSession")}
              </PRButton>
            </div>
          </div>
        ),
      });
    }

    if (fileUploadStatuses.length) {
      mappedMessageList.push({
        isSent: false,
        position: "right",
        type: "file",
        loading: true,
        volatile: true,
        files: fileUploadStatuses,
      });
    }

    return mappedMessageList;
  }, [
    fileUploadStatuses,
    session,
    onClickTake,
    disableMetadataView,
    liveMode,
    isCurrentAgentAvailable,
    hideLoadMore,
    relatedPreviousSessions,
    relatedPreviousSessionList,
    t,
  ]);

  // const { whatsappNumber, formattedWhatsappNumber } = useMemo(() => {
  //   const connections = session?.connections || [];
  //   const whatsappConnection = connections.find((item) => item.metadata?.whatsapp_phone_number);
  //   const number = whatsappConnection?.metadata?.whatsapp_phone_number || "";
  //   const parsedNumber = parse(number);
  //   return {
  //     whatsappNumber: number,
  //     formattedWhatsappNumber: format(parsedNumber, "INTERNATIONAL"),
  //   };
  // }, [session]);
  const onClientMessageSend = useCallback(
    (msgPayload) => {
      onClickSend?.(session, msgPayload.text);
    },
    [onClickSend, session]
  );
  const handleMessageChange = useCallback(
    (msg) => {
      onMessageChange?.(msg);
    },
    [onMessageChange]
  );

  const lastMessageReadableMessageItemDate = useMemo(() => {
    if (!session?.messages?.length) return null;

    let lastMessageText;
    let lastMessage;

    for (let i = session.messages.length - 1; i >= 0; i--) {
      const item = session?.messages?.[i];
      const date = item?.time;

      if (date) {
        if (item.type === "message") {
          lastMessageText = date;
          break;
        } else if (!lastMessage) {
          lastMessage = date;
        }
      }
    }
    return lastMessageText || lastMessage;
  }, [session?.messages]);

  const lastMessage = useMemo(() => {
    const messages = [...(mappedMessages || [])];
    return messages[messages.length - 1];
  }, [mappedMessages]);

  useEffect(() => {
    if (lastMessage?.isCustomer) {
      setInteractionInfoState((p) => ({ ...p, typing: false }));
    }
  }, [lastMessage]);

  const handleClickDirectToBot = () => {
    onClickDirectToBot?.(session);
  };

  const handleClickSendTicket = () => {
    onClickSendTicket?.(session);
  };
  const handleClickCloseSession = async (e) => {
    const result = await DialogHelper.showQuestion(
      t("component.sessionChat.endChat"),
      t("component.sessionChat.detailMessage"),
      {
        label: t("component.sessionChat.terminate"),
        color: "error",
      }
    );
    if (!result) return;
    onClickCloseSession?.(session, e);
  };

  const handleClickTake = useCallback(
    (e) => {
      e.stopPropagation();
      onClickTake?.(session);
    },
    [onClickTake, session]
  );

  const handleClickShowDetails = useCallback(() => {
    LivechatPersonalDetailModal.show({
      dataObj: session?.customer?.public_fields,

      dataProject: session?.project,
    });
  }, [session?.customer?.public_fields, session?.project]);

  const handleClickDirectToAgent = useCallback(async () => {
    setFocusOnRenderDisabled(true);
    await RedirectAgentModalModal.show({ session });
    setFocusOnRenderDisabled(false);
  }, [session]);

  const handleMount = useCallback(() => {
    setIsChatbotReady(true);
  }, []);

  const handleSubmitTemplate = useCallback((e, text) => {
    if (e?.shiftKey) {
      chatRef.current?.send(text);
    } else {
      chatRef.current?.setText(text);
    }
  }, []);
  const handleDropFile = async (files) => {
    setFileUploadStatuses(
      files.map((file) => ({
        ...file,
        name: file?.path || "",
        loading: true,
      }))
    );
    const resolveList = await Promise.all(
      files.map(async (file) => {
        const response = await dispatch(uploadFile(session.id, file)).catch((e) => {
          if (e.response?.status === 413) {
            AlertHelper.show(t("chatbot.fileToLarge"), "error");
          } else if (e.response?.status === 415) {
            AlertHelper.show(t("chatbot.fileTypeNotSupported"), "error");
          } else {
            AlertHelper.show(t("chatbot.fileTypeNotSupported"), "error");
          }
        });

        setFileUploadStatuses((prev) =>
          prev.map((status, index) => (index === files.indexOf(file) ? { ...status, loading: false } : status))
        );
        return response;
      })
    );

    const fileData = resolveList.map((response) => ({
      file_name: response.file_name,
      url: response.file_url,
      file_type: response.file_type,
      machine_data: response?.uuid,
    }));
    let isImage = true;
    for (let i = 0; i < fileData.length; i++) {
      const url = fileData[i].url;
      isImage = url.match(/\.(jpeg|jpg|gif|png|svg|webp)$/) != null;
      if (!isImage) {
        break;
      }
    }
    setFileUploadStatuses([]);
    onClickSend?.(session, "", isImage ? "IMAGE" : "FILE", fileData);

    // dispatch(uploadFile(session.id, files[0])).then((res) => {
    // });
  };

  const handleClickSetSessionLabel = async () => {
    onClickSetLabel?.(session);
  };

  const handleClickGetterSetter = () => {
    dispatch(setIsGetterSetterDrawerOpen(true));
  };

  const { parsedMetadata, isWebSession, isInstagramSession } = useMemo(() => {
    const connections = (session?.connections || []).map((item) => ({
      ...item,
      lastAccess: DateHelper.getDateTime(item.last_access),
    }));

    const orderedConnections = _.orderBy(connections, ["lastAccess"], ["desc"]);
    const activeConnection = orderedConnections.find((item) => item.online) || orderedConnections[0];
    const metadata = activeConnection?.metadata || {};
    const isWebSession = session?.platform === chatbotSessionPlatform.WEB;
    const isInstagramSession = session?.platform === chatbotSessionPlatform.INST;

    return {
      metadata,
      parsedMetadata: parseMetadata(metadata, session),
      isWebSession,
      isInstagramSession,
    };
  }, [session]);

  const currentProtocol = window.location.protocol;
  const baseUrl =
    process.env.NODE_ENV !== "production"
      ? `${currentProtocol}//${process.env.REACT_APP_PROXY_URL}`
      : window.location.origin;

  const subText = useMemo(() => {
    const chunks = [];
    if (session?.customer?.email) {
      chunks.push(session.customer.email);
    }

    if (
      (session?.platform === chatbotSessionPlatform.WHAT && session.connections?.length) ||
      session?.customer?.phone_number
    ) {
      const ActivePhoneMetadata = session.connections.find(
        (item) => session?.customer?.phone_number || item.metadata?.whatsapp_phone_number || item.metadata?.phone_number
      );
      const phoneNumber =
        session?.customer?.phone_number ||
        ActivePhoneMetadata?.metadata?.whatsapp_phone_number ||
        ActivePhoneMetadata?.metadata?.phone_number?.[0];

      if (phoneNumber) {
        chunks.push(<PRPhoneNumber key={phoneNumber} render value={phoneNumber} />);
      }
    }

    if (!chunks.length) return "";
    else if (chunks?.length === 1) return chunks[0];

    return chunks.reduce((prev, curr) => [prev, " | ", curr]);
  }, [session]);

  const instagramId = useMemo(() => {
    const connections = (session?.connections || []).map((item) => item.metadata?.identifier);
    return connections.find((item) => item);
  }, [session?.connections]);

  const firstCreatedDate = useMemo(() => {
    if (session?.created) return DateHelper.getDateTime(session.created);
    const connections = (session?.connections || []).map((item) => DateHelper.getDateTime(item.started));
    let farthestDate;
    for (const date of connections) {
      if (!farthestDate || farthestDate?.isAfter(date)) {
        farthestDate = date;
      }
    }
    return farthestDate;
  }, [session?.created, session?.connections]);

  const variableMap = useMemo(() => {
    return {
      ...session?.customer,
      agent_name: agentInfo?.name,
    };
  }, [session?.customer, agentInfo?.name]);
  return (
    <StyledDiv noSession={!session}>
      {isChatbotReady && !disabledTextStatus && (
        <TemplatePopup projectId={session?.project} variableMap={variableMap} onSubmit={handleSubmitTemplate} />
      )}
      {session && (
        <div
          className="chat-form"
          // style={{
          //   ...(chatMessageProps.colorPrimary && !hideOutline
          //     ? {
          //         boxShadow: `0px 0px 12px -2px ${chroma(chatMessageProps.colorPrimary).alpha(0.9).css()}`,
          //       }
          //     : {}),
          // }}
        >
          <div className="header">
            <div className="">
              <Row className="g-0 flex-nowrap overflow-hidden title-action">
                <Col xs className="overflow-hidden">
                  <div className="text-nowrap">
                    <div className="customer-name text-truncate">
                      <span>{session.customer?.full_name}</span>
                    </div>
                    <div className="customer-email text-truncate"> {subText}</div>
                  </div>
                </Col>
                <Col className="d-flex justify-content-end" xs="auto">
                  <div className="label-action d-flex align-items-center">
                    {isXxl && isGetterSetter && (
                      <LiveChatButton
                        icon={() => <MdInfoOutlineWithFill />}
                        tooltipText={t("component.sessionChat.getterSetterTooltip")}
                        onClick={handleClickGetterSetter}
                      />
                    )}
                    <LiveChatButton
                      // disabled={isActionDisabledCommon || !isCurrentAgentChatting}
                      icon={() => <MdLabelWithStroke />}
                      tooltipText={t("component.sessionChat.setLabelTooltip")}
                      onClick={handleClickSetSessionLabel}
                    />
                  </div>
                  {!hideActions && (
                    <>
                      <div className="session-actions d-flex align-items-center">
                        <LiveChatButton
                          disabled={isActionDisabledCommon || !isCurrentAgentChatting}
                          icon={() => <LivechatIcon />}
                          tooltipText={t("component.sessionChat.directToAgentTooltip")}
                          onClick={handleClickDirectToAgent}
                        />
                        <LiveChatButton
                          disabled={isActionDisabledCommon || isAgentChatting}
                          icon={() => <TakeCustomerIcon />}
                          tooltipText={t("component.sessionChat.takeSession")}
                          onClick={handleClickTake}
                        />
                        {!hideSendTicket && (
                          <LiveChatButton
                            disabled={isActionDisabledCommon || !isCurrentAgentChatting}
                            icon={() => <DirectToTicketIcon />}
                            tooltipText={t("component.sessionChat.sendTicketTooltip")}
                            onClick={handleClickSendTicket}
                          />
                        )}
                        {!hideSendDirectToBot && (
                          <LiveChatButton
                            disabled={isActionDisabledCommon || !isCurrentAgentChatting}
                            icon={() => <RobotIcon />}
                            tooltipText={t("component.sessionChat.directToBotTooltip")}
                            onClick={handleClickDirectToBot}
                          />
                        )}
                        <LiveChatButton
                          closeButton
                          disabled={isSessionCompleted || isAnotherAgentChatting}
                          icon={MdClose}
                          tooltipText={t("component.sessionChat.closeSessionTooltip")}
                          onClick={handleClickCloseSession}
                        />
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </div>

            <div className="label-box">
              {session?.label && chatLabel?.key && (
                <Box backgroundColor={chatLabel?.background_color} className="text-truncate" color={chatLabel?.color}>
                  <Box
                    alignItems="center"
                    display="flex"
                    sx={{
                      svg: {
                        ml: "-2px",
                        mr: "2px",
                        width: 20,
                        height: 20,
                        color: "var(--bs-gray-500)",
                      },
                    }}
                  >
                    <MdLabel />
                    <span className="label-key">{chatLabel?.key}</span>
                  </Box>
                </Box>
              )}
            </div>
            <div className="id-box">
              <span>
                ID:&nbsp;
                {userInfo.is_superuser ? (
                  <PRLink newTab noPrefix to={`${baseUrl}/api/admin/customer/chatsessionv2/${session.id}/change/`}>
                    <PRTooltip title={t("component.sessionChat.openRawSessionTooltip")}>
                      <span>{session.id}</span>
                    </PRTooltip>
                  </PRLink>
                ) : (
                  session.id
                )}
              </span>

              {!hideOnline && (
                <>
                  <IDDivider />
                  <div className="d-flex align-items-center">
                    <Box
                      sx={{
                        backgroundColor: interactionInfoState?.online ? "var(--bs-primary)" : "#6e6e6e",
                        width: 12,
                        height: 12,
                        borderRadius: "50%",
                        marginRight: "5px",
                      }}
                    />
                    <span>{interactionInfoState?.online ? t("common.online") : t("common.offline")}</span>
                    {interactionInfoState?.count > 1 && (
                      <PRTooltip title={t("component.sessionChat.connectionCountTooltip")}>
                        <span className="ms-1">({interactionInfoState?.count})</span>
                      </PRTooltip>
                    )}
                  </div>
                </>
              )}
              {(true || (!!session.previous_sessions?.length && !isMd)) && (
                <div className="ms-auto">
                  <PRPopover
                    content={<ShowHistoryPopoverContent session={session} onClick={onClickHistorySelect} />}
                    portal="root"
                    zIndex={1400}
                  >
                    <span>
                      <FullHistoryButton size="sm">
                        {t("component.sessionChat.showHistory")}
                        <HistoryIcon />
                      </FullHistoryButton>
                    </span>
                  </PRPopover>
                </div>
              )}
            </div>

            {interactionInfoState ? (
              <div className="meta-box">
                {isMd && (
                  <Col className="d-flex align-items-center meta-info" xs={"12"}>
                    <SessionTime created={firstCreatedDate} last={lastMessageReadableMessageItemDate} />
                    <IDDivider />

                    {parsedMetadata.osIcon && <div>{parsedMetadata.osIcon}</div>}
                    {parsedMetadata.platformIcon && <div>{parsedMetadata.platformIcon}</div>}
                    {parsedMetadata.browserIcon && <div>{parsedMetadata.browserIcon}</div>}

                    {!!session.previous_sessions?.length && (
                      <div className="ms-auto">
                        <PRPopover
                          content={<ShowHistoryPopoverContent session={session} onClick={onClickHistorySelect} />}
                          portal="root"
                          zIndex={1400}
                        >
                          <span>
                            <FullHistoryButton size="sm">
                              {t("component.sessionChat.showHistory")}
                              <HistoryIcon />
                            </FullHistoryButton>
                          </span>
                        </PRPopover>
                      </div>
                    )}
                  </Col>
                )}
                <Row className="g-0 align-items-center justify-content-between w-100">
                  <Col xs className="text-truncate d-flex overflow-hidden">
                    {isWebSession && (
                      <>
                        URL:&nbsp;
                        <PRTooltip
                          placement="top"
                          title={
                            <Box textAlign="center">
                              <Box>
                                {interactionInfoState?.locationList?.length > 1 && (
                                  <Box fontSize={14}>{t("component.sessionChat.latest")}:</Box>
                                )}

                                {interactionInfoState?.location}
                              </Box>
                              {interactionInfoState?.locationList?.length > 1 && (
                                <Box
                                  sx={{
                                    "td,th": {
                                      padding: "0px 4px",
                                    },
                                  }}
                                >
                                  <br />
                                  <Box fontSize={14}>{t("component.sessionChat.otherTabs")}: </Box>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>{t("common.location")}</th>
                                        <th>{t("component.sessionChat.referrer")}</th>
                                        <th>{t("component.sessionChat.lastAccess")}</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {interactionInfoState?.locationList?.map((item, index) => (
                                        <tr key={index}>
                                          <td>{item.location}</td>
                                          <td>{item.metadata?.referrer}</td>
                                          <td>{DateHelper.getDateTimeLocal(item.last_access).format("LLT")}</td>
                                          <td>
                                            <Box
                                              sx={{
                                                backgroundColor: item.online ? "var(--bs-success)" : "var(--bs-danger)",
                                                width: 12,
                                                height: 12,
                                                borderRadius: "50%",
                                                marginRight: "5px",
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </Box>
                              )}
                            </Box>
                          }
                        >
                          <div className="text-truncate"> {interactionInfoState?.location}</div>
                        </PRTooltip>
                      </>
                    )}
                    {isInstagramSession && <>User ID:&nbsp;{instagramId}</>}
                  </Col>

                  {!isMd && (
                    <>
                      <Col xs={"auto"}>
                        <SessionTime created={firstCreatedDate} last={lastMessageReadableMessageItemDate} />
                      </Col>
                      <Col xs={"auto"}>
                        <div className="box-divider"></div>
                      </Col>
                      <Col className="d-flex align-items-center meta-info" xs={"auto"}>
                        {parsedMetadata.osIcon && <div>{parsedMetadata.osIcon}</div>}
                        {parsedMetadata.platformIcon && <div>{parsedMetadata.platformIcon}</div>}
                        {parsedMetadata.browserIcon && <div>{parsedMetadata.browserIcon}</div>}
                      </Col>
                      {liveMode && isWebSession && (
                        <Col xs={"auto"}>
                          <div className="box-divider"></div>
                        </Col>
                      )}
                    </>
                  )}
                  {liveMode && isWebSession && (
                    <Col xs={"auto"}>
                      <PRTooltip
                        title={
                          interactionInfoState?.focused
                            ? t("component.sessionChat.focused")
                            : t("component.sessionChat.notFocused")
                        }
                      >
                        {interactionInfoState?.focused ? <EyeIcon /> : <EyeOffIcon />}
                      </PRTooltip>
                    </Col>
                  )}
                </Row>
              </div>
            ) : (
              <div className={"pt-1"} />
            )}
          </div>
          <div className="chat-wrapper">
            <div className="chat-status-box">
              <span className="status-text">
                {getStatusText(
                  lastMessage?.disconnected ? chatbotSessionStatus.CUSTOMER_DISCONNECT : session.session_status,
                  null,
                  session.session_status === chatbotSessionStatus.AGENT_CHAT && session.agent?.name
                )}
              </span>
              <span className="placeholder-line"></span>
              <Box
                sx={{
                  fontSize: 12,
                  color: "rgba(0, 0, 0, 0.85)",
                }}
                whiteSpace={"nowrap"}
              >
                {/* Assigned To  {session.agent?.name || "N/A"} */}
                {!!Object.keys(session?.customer?.public_fields || {})?.length && (
                  <PalButton
                    size="small"
                    sx={{
                      fontSize: 12,
                      height: "20px",
                    }}
                    variant="outlined"
                    onClick={handleClickShowDetails}
                  >
                    {t("component.sessionChat.customerDetails")}
                  </PalButton>
                )}
              </Box>
            </div>
            <PRChatMessage
              noMic
              colorPrimary={"#50c8f4"}
              {...chatMessagePropsState}
              ref={chatRef}
              disableInputRequest
              dropDisabled
              multiline
              readonlyLikeDislike
              className="chat"
              disableDislike={liveMode}
              disableLike={liveMode}
              disableMic={disabledTextStatus}
              disableTextInput={disabledTextStatus}
              focusOnRender={!focusOnRenderDisabled}
              id={session.id}
              interactionOptions={interactionOptions}
              maxRows={4}
              messages={mappedMessages}
              reverseChat={true}
              scrollOnMessage={scrollOnMessage}
              senderType={session.session_type === chatbotSessionStatus.AGENT_CHAT ? "AGT" : "BOT"}
              showAttachment={isCurrentAgentChatting && fileUploadStatuses.length === 0}
              showTyping={interactionInfoState?.typing}
              onDrop={handleDropFile}
              onInteraction={onInteraction}
              onMessage={onClientMessageSend}
              onMessageChange={handleMessageChange}
              onMount={handleMount}
            />
          </div>
        </div>
      )}
      {!session && (
        <div className="  no-chat d-flex justify-content-center align-items-center flex-column">
          <img alt="No chat selected" className="no-chat img-fluid align-self-center px-5" src={ProfileImg} />
          <h4 className="text-center mt-4 text-muted fw-light">{t("component.sessionChat.noChatSelected")}</h4>
        </div>
      )}
    </StyledDiv>
  );
}

export default SessionChat;
