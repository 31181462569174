import { useState } from "react";

import { get, map } from "lodash";
import { useTranslation } from "react-i18next";
import { MdExpandMore } from "react-icons/md";

import styled from "@emotion/styled";
import { Menu, MenuItem } from "@mui/material";

import { ReactComponent as LanguageIcon } from "~assets/images/icons/language.svg";
import useBootstrapBreakpoint from "~common/hooks/useBootstrapBreakpoint";
import languages from "~common/languages";

import PalButton from "./mui/PalButton";
import PalIconButton from "./mui/PalIconButton";
const StyledLanguageDropdownChevron = styled.div`
  transition: all 0.3s ease;
  ${(props) => (props.isOpen ? "transform: rotate(-90deg);" : "")}
  svg {
    color: ${(props) => props.theme.palette.secondary.main} !important;
    width: 20px;
    margin-left: -2px;
  }
`;
const StyledLabel = styled.span`
  /* width: 46px; */
  font-family: "Museo Sans";
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #000;
`;

const ExpandCollapseIcon = ({ isOpen }) => (
  <StyledLanguageDropdownChevron className="d-none d-md-inline-block" isOpen={isOpen}>
    <MdExpandMore />
  </StyledLanguageDropdownChevron>
);

const LanguageDropdown = () => {
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.up("md"));
  const isMd = useBootstrapBreakpoint("md");
  const [anchorEl, setAnchorEl] = useState(null);
  const { i18n } = useTranslation();

  const changeLanguageAction = (lang) => () => {
    i18n.changeLanguage(lang);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <>
      {isMd ? (
        <PalIconButton
          size="sm"
          sx={{
            width: "20px !important",
            height: "20px !important",
            padding: 0,
          }}
          onClick={handleClickOpen}
        >
          <LanguageIcon />
        </PalIconButton>
      ) : (
        <PalButton
          endIcon={<ExpandCollapseIcon isOpen={Boolean(anchorEl)} />}
          startIcon={<LanguageIcon />}
          sx={{
            padding: "0px !important",
          }}
          onClick={handleClickOpen}
        >
          <StyledLabel className="d-none d-md-block">{get(languages, `${i18n.language}.label`)}</StyledLabel>
        </PalButton>
      )}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        id="account-menu"
        open={Boolean(anchorEl)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        onClose={handleClose}
      >
        {map(Object.keys(languages), (key) => (
          <MenuItem
            key={key}
            className={`notify-item ${i18n.language === key ? "active" : "none"}`}
            onClick={changeLanguageAction(key)}
          >
            <img alt="Skote" className="me-1" height="12" src={get(languages, `${key}.flag`)} />
            <span className="align-middle">{get(languages, `${key}.label`)}</span>
          </MenuItem>
        ))}
      </Menu>
      {/* <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block"> */}
      {/* <DropdownToggle tag="button" className="px-0 btn header-item d-flex align-items-center"> */}

      {/* </DropdownToggle> */}
      {/* <DropdownMenu className="language-switch dropdown-menu-end"> */}

      {/* </DropdownMenu> */}
      {/* </Dropdown> */}
    </>
  );
};

export default LanguageDropdown;
