import { useEffect, useState } from "react";

import { Redirect, Route, Router, Switch } from "react-router-dom";

import HistoryHelper from "~helpers/HistoryHelper";
import Offline from "~pages/public/Offline";
import RouteMiddlewareProtected, { RouteMiddleware } from "~routes/route";

import { publicRoutes } from "./routes";

import "./app.scss";

const RedirectComponent = () => {
  return <Redirect to="/login" />;
};
function App() {
  const [online, setOnline] = useState(navigator.onLine);
  useEffect(() => {
    function updateOnlineStatus() {
      setOnline(navigator.onLine);
    }

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  return (
    <Router history={HistoryHelper.getHistory()}>
      <Switch>
        <Route component={() => __PR_RELEASE__} path="/version" />
        <Route component={Offline} path="/offline" />
        {publicRoutes.map((route) => (
          <RouteMiddleware
            key={"public|" + route.path}
            component={route.component}
            isProtected={false}
            path={route.path}
          />
        ))}

        <Route component={RouteMiddlewareProtected} path="/dashboard" />
        <Route component={RedirectComponent} path="/" />
        {!online && <Redirect to="/offline" />}
      </Switch>
    </Router>
  );
}

export default App;
