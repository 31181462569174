import { combineReducers } from "redux";

import dictionaries from "./dictionaries/reducer";
import entityTypes from "./entityTypes/reducer";
import intentRecords from "./intentRecords/reducer";
import intentTags from "./intentTags/reducer";
import scenarioManager from "./scenarioManager/reducer";

const dialogComponentsReducer = combineReducers({
  dictionaries,
  entityTypes,
  intentTags,
  scenarioManager,
  intentRecords,
});

export default dialogComponentsReducer;
