import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

export const initState = {
  statisticGenerator: null,
};

const statisticGenerator = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_STATISTICS_GENERATOR:
        draft.statisticGenerator = action.payload;
        break;
    }
  });

export default statisticGenerator;
