import { MdOutlineDescription } from "react-icons/md";
import { Col } from "reactstrap";

import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";

import PRLink from "~components/Generic/PRLink";
import PRTooltip from "~components/Generic/PRTooltip";
import IFrameHelper from "~helpers/IFrameHelper";
import Utils from "~helpers/Utils";

import SimpleMessage from "../SimpleMessage";

const StyledDiv = styled.div`
  /* min-height: 58px; */
  width: auto;
  white-space: normal;
  word-wrap: break-word;
  text-align: left;
  font: normal normal normal 18px/20px Museo Sans;
  letter-spacing: 0px;
  color: #000000b3;
  overflow-wrap: anywhere;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  .logo-item {
    display: flex;
    align-items: center;
  }
  ${(props) => {
    if (props.position === "right") {
      return `
        //background-color: ${props.theme.palette.primary.main};
        `;
    } else {
      return `
        // border: 1px solid #ccc;
        `;
    }
  }}
  svg {
    margin: 8px;
    width: auto;
    height: 20px;
    transform: scale(1.2);
  }
  .file-name {
    margin: 0px 22px 0px 0px;
    max-width: ${(props) => (props.isInIFrame ? "250px;" : "100%")};
  }
`;
export default function MessageFile({
  position,
  messageTime,
  format,
  sender,
  isSent,
  // fileName,
  // fileUrl,
  files,
  messageId,
  historyId,
  likeStatus,
  plainView,
  isLast,
  isRag,
}) {
  const fileBytes = 0;
  const Wrapper = plainView ? ({ children }) => children : SimpleMessage;
  return (
    <>
      <Wrapper
        className={"gap-2"}
        format={format}
        historyId={historyId}
        isLast={isLast}
        isRag={isRag}
        isSent={isSent}
        likeStatus={likeStatus}
        messageId={messageId}
        messageTime={messageTime}
        position={position}
      >
        {(files || []).map?.((file, index) => (
          <StyledDiv
            key={`files-${index}`}
            className="g-0 row d-flex align-items-center"
            isInIFrame={IFrameHelper.isInIFrame() || IFrameHelper.isTryChatbot()}
            position={position}
          >
            <Col className="logo-item" xs="auto">
              {isSent || !file.loading ? (
                <MdOutlineDescription height={"auto"} width={"auto"} />
              ) : (
                <CircularProgress size={"auto"} />
              )}
            </Col>

            <Col xs>
              <PRTooltip
                placement="top"
                title={
                  <div>
                    <div>{file.name || file.file_name}</div>
                    <div>
                      {!!fileBytes && (
                        <p className="mt-2 ">
                          <strong>{Utils.formatBytes(fileBytes)}</strong>
                        </p>
                      )}
                    </div>
                  </div>
                }
              >
                <div className="file-name ">
                  {!file.url && !file.file_url ? (
                    file.name || file.file_name
                  ) : (
                    <PRLink
                      newTab
                      noPrefix
                      className="text-decoration-none text-reset"
                      target="_blank"
                      to={file.url || file.file_url}
                    >
                      {file.name || file.file_name}
                    </PRLink>
                  )}
                </div>
              </PRTooltip>
            </Col>
          </StyledDiv>
        ))}
      </Wrapper>
    </>
  );
}
