import PRSwitch from "~components/Generic/PRSwitch";

import ModuleEdit from "./ModuleEdit";
import ModuleVersion from "./ModuleEdit/ModuleVersions";
import ModuleList from "./ModuleList";
function Module() {
  const routes = [
    { path: "/integration/module/form/:id?", component: ModuleEdit },
    { path: "/integration/module/version/:id", component: ModuleVersion },
    { path: "/integration/module/", component: ModuleList },
    { path: "/integration", to: "/integration/module", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}

export default Module;
