import PRSwitch from "~components/Generic/PRSwitch";

import WhatsappTemplateMessageEdit from "./WhatsappTemplateMessageEdit";
import WhatsappTemplateMessageList from "./WhatsappTemplateMessageList";

function WhatsappTemplateMessages() {
  const routes = [
    { path: "/contactcenter/livechat/whatsapp-template-messages/form/:id?", component: WhatsappTemplateMessageEdit },
    { path: "/contactcenter/livechat/whatsapp-template-messages/", component: WhatsappTemplateMessageList },
    { path: "/contactcenter/", to: "/contactcenter/livechat/whatsapp-template-messages/", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}

export default WhatsappTemplateMessages;
