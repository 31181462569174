import { useEffect } from "react";

import * as Sentry from "@sentry/react";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import useQueryParams from "~common/hooks/useQueryParams";
import { CK_TOKEN, LS_TOKEN } from "~constants";
import StorageHelper from "~helpers/StorageHelper";
import { logoutUser, setCurrentUser } from "~store/actions";
import { clearPushNotification } from "~store/notification/actions";
import { resetPopupSettingsState } from "~store/settings/popupSettings/actions";
import { wsDisconnect } from "~store/socket/livechat/actions";

const Logout = () => {
  const dispatch = useDispatch();
  const { return: returnUrl } = useQueryParams();

  useEffect(() => {
    dispatch(wsDisconnect());
    dispatch(logoutUser({ returnUrl: returnUrl ? returnUrl : undefined }));
    dispatch(setCurrentUser({}));
    dispatch(resetPopupSettingsState());
    dispatch(clearPushNotification());
    Sentry.setUser(null);
    setTimeout(() => {
      StorageHelper.remove(LS_TOKEN);
      Cookies.remove(CK_TOKEN);
    }, 1000);
  }, [dispatch, returnUrl]);

  return null;
};

export default withRouter(Logout);
