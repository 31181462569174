import accent from "remove-accents";
import { v4 as uuidv4 } from "uuid";

export default class Utils {
  /**
   * @param {string} colorCode
   * @param {number} amount
   * @returns
   */
  static changeColorBrightness(colorCode, amount) {
    let usePound = true;

    if (colorCode[0] == "#") {
      colorCode = colorCode.slice(1);
      usePound = true;
    }
    const num = parseInt(colorCode, 16);
    let r = (num >> 16) + amount;

    if (r > 255) {
      r = 255;
    } else if (r < 0) {
      r = 0;
    }

    let g = ((num >> 8) & 0x00ff) + amount;

    if (g > 255) {
      g = 255;
    } else if (g < 0) {
      g = 0;
    }

    let b = (num & 0x0000ff) + amount;

    if (b > 255) {
      b = 255;
    } else if (b < 0) {
      b = 0;
    }
    let color = (b | (g << 8) | (r << 16)).toString(16);
    while (color.length < 6) {
      color = 0 + color;
    }
    return (usePound ? "#" : "") + color;
  }

  /**
   * @param {string} hex
   * @returns {"black" | "white"}
   */
  static determineTextColor(hex) {
    const hexCode = hex.charAt(0) === "#" ? hex.substr(1, 6) : hex;

    const hexR = parseInt(hexCode.substr(0, 2), 16);
    const hexG = parseInt(hexCode.substr(2, 2), 16);
    const hexB = parseInt(hexCode.substr(4, 2), 16);
    // Gets the average value of the colors
    const contrastRatio = (hexR + hexG + hexB) / (255 * 3);

    return contrastRatio >= 0.7 ? "black" : "white";
  }
  static getContrast(hexColor1, hexColor2 = "#ffffff") {
    const color1 = hexColor1.charAt(0) === "#" ? hexColor1.substr(1, 8) : hexColor1;
    const color2 = hexColor2.charAt(0) === "#" ? hexColor2.substr(1, 8) : hexColor2;

    const r1 = parseInt(color1.substr(0, 2), 16);
    const g1 = parseInt(color1.substr(2, 2), 16);
    const b1 = parseInt(color1.substr(4, 2), 16);
    const a1 = parseInt(color1.substr(6, 2), 16) / 255 || 1;

    const r2 = parseInt(color2.substr(0, 2), 16);
    const g2 = parseInt(color2.substr(2, 2), 16);
    const b2 = parseInt(color2.substr(4, 2), 16);
    const a2 = parseInt(color2.substr(6, 2), 16) / 255 || 1;

    const blended1 = [(1 - a1) * r1 + a1 * r2, (1 - a1) * g1 + a1 * g2, (1 - a1) * b1 + a1 * b2];

    const blended2 = [(1 - a2) * r2 + a2 * r1, (1 - a2) * g2 + a2 * g1, (1 - a2) * b2 + a2 * b1];

    const l1 =
      0.2126 * Math.pow(blended1[0] / 255, 2.2) +
      0.7152 * Math.pow(blended1[1] / 255, 2.2) +
      0.0722 * Math.pow(blended1[2] / 255, 2.2);

    const l2 =
      0.2126 * Math.pow(blended2[0] / 255, 2.2) +
      0.7152 * Math.pow(blended2[1] / 255, 2.2) +
      0.0722 * Math.pow(blended2[2] / 255, 2.2);

    if (l1 > l2) {
      return (l1 + 0.05) / (l2 + 0.05);
    }
    return (l2 + 0.05) / (l1 + 0.05);
  }
  static getColorAsContrastOptimized(hexColor1, backgroundColor = "#ffffff") {
    let newColor = hexColor1;
    let contrastRatio = Utils.getContrast(hexColor1, backgroundColor);
    let tempNewColor = hexColor1;
    let tempContrastRatio = contrastRatio;
    let brightnessAmount = 10;
    let iteration = 0;

    while (contrastRatio < 4.5 && iteration < 100) {
      newColor = Utils.changeColorBrightness(newColor, brightnessAmount);
      contrastRatio = Utils.getContrast(newColor, backgroundColor);

      if (contrastRatio < tempContrastRatio) {
        brightnessAmount = -Math.floor(Math.abs(brightnessAmount) / 2);
      }

      if (tempNewColor === newColor || Math.abs(brightnessAmount) < 1) {
        break;
      }

      tempNewColor = newColor;
      tempContrastRatio = contrastRatio;
      iteration++;
    }
    return newColor;
  }

  static setBySelector(obj, selector, value) {
    const keys = selector.split(".");
    let lastKey = keys.pop();
    let lastObj = keys.reduce((obj, key) => obj[key], obj);
    lastObj[lastKey] = value;
  }
  static getBySelector(obj, selector) {
    const keys = selector.split(".");
    return keys.reduce((obj, key) => obj[key], obj);
  }

  static detectDeviceType() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
    ) {
      return "mobile";
    }
    return "desktop";
  }

  static isIOSDevice() {
    return /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent) && !window.MSStream;
  }

  static validateEmail = (email) => {
    if (!email || typeof email !== "string") return false;
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  static validateUrl = (url) => {
    const urlRegex = new RegExp(
      // eslint-disable-next-line no-control-regex
      "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})",
      "gi"
    );
    return urlRegex.test(url);
  };

  static validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{10,}$/;
    return regex.test(password);
  };

  static getVariablesFromStr = (str = "") => {
    const regex = /<<(.*?)>>/g;
    const matches = str.match(regex);
    return matches ? matches.map((match) => match.replace(/<<|>>/g, "")) : [];
  };

  /**
   * Convert a object to a query string for url
   *
   * @param {object} obj - The object to convert to a query string
   * @param {string} url - Append the query string to this url if provided (optional)
   * @returns {string} - The query string or url with query string
   */
  static qs = (obj, url = null) => {
    const str = [];
    for (const p in obj) {
      if (!obj[p]) continue;
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }

    const currentQs = url ? url.split("?")[1] : null;
    let qs = "?" + str.join("&");
    if (currentQs) {
      qs = "?" + currentQs + "&" + str.join("&");
    }

    if (url) {
      return url.split("?")[0] + qs;
    }
    return qs;
  };

  static getQs = (url) => {
    const qs = url.split("?")?.[1] || "";
    const query = new URLSearchParams(qs);
    const qsObj = {};
    for (const [key, value] of query.entries()) {
      qsObj[key] = value;
    }
    return qsObj;
  };

  static isAsync = (func) => {
    if (func?.constructor?.name === "AsyncFunction" || (typeof func === "object" && typeof func.then === "function")) {
      return true;
    } else {
      return false;
    }
  };
  /**
   * Remove all null, undefined and empty string from an object recursively
   *
   * @param {any} obj
   * @param objItem
   * @param createNewObj
   * @returns
   */
  static removeNulls = (objItem, createNewObj = false) => {
    let obj = objItem;
    if (!obj) return obj;
    if (createNewObj && typeof obj === "object") {
      obj = JSON.parse(JSON.stringify(objItem));
    }

    Object.keys(obj).forEach((key) => {
      if (Array.isArray(obj[key])) {
        obj[key] = obj[key]
          .filter((item) => item)
          .map((item) => Utils.removeNulls(item, createNewObj))
          .filter((item) => item);
      } else if (obj[key] && typeof obj[key] === "object") Utils.removeNulls(obj[key], createNewObj);
      else if (obj[key] == null) delete obj[key];
      else if (obj[key] === "") delete obj[key];
    });
    return obj;
  };
  static arrayMove = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };
  static formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  static getFileType = (name = "") => {
    const ext = name.split(".").pop();
    if (ext === "pdf") {
      return "pdf";
    } else if (["png", "jpg", "jpeg", "gif", "webp", "bmp", "tif", "tiff"].includes(ext)) {
      return "image";
    } else if (["mp4", "mov", "avi", "webm"].includes(ext)) {
      return "video";
    } else if (["mp3", "wav", "ogg"].includes(ext)) {
      return "audio";
    } else {
      return "file";
    }
  };

  static keyToCapital = (str) => {
    if (!str) return "";
    const words = str.split("_").map((word) => word.toLowerCase());
    return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
  };

  static getPaging = (page, limit) => {
    const offset = (page - 1) * limit;
    return { offset, limit };
    // return { limit, page, offset };
  };

  static normalizeUrl = (url = "") => {
    if (typeof url !== "string") return url;
    let newUrl = url;
    if (url.includes("https://localhost/")) {
      newUrl = url.replace("https://localhost/", "http://localhost:3500/");
    }
    return newUrl;
  };

  static ellipsisUrl = (url = "", trimStart = 100) => {
    if (url?.length <= trimStart || !url) {
      return url;
    }

    const parts = url.split("/");
    if (parts.length > 3) {
      return `${parts[0]}//${parts[2]}/.../${parts[parts.length - 1]}`;
    }
    return url;
  };

  static insertAtCursor = (dom, insertValue) => {
    //IE support
    if (document.selection) {
      dom.focus();
      const sel = document.selection.createRange();
      sel.text = insertValue;
    }
    //MOZILLA and others
    else if (dom.selectionStart || dom.selectionStart == "0") {
      const startPos = dom.selectionStart;
      const endPos = dom.selectionEnd;
      dom.value = dom.value.substring(0, startPos) + insertValue + dom.value.substring(endPos, dom.value.length);
    } else {
      dom.value += insertValue;
    }
  };
  static createObjectURLfromBase64 = (base64, mimeType = "image/png") => {
    const blobData = new Blob([base64], { type: mimeType });
    return URL.createObjectURL(blobData);
  };

  static log = (name, obj, color = "#00bfff") => {
    const log = window.prLog || console.log;
    try {
      const status = localStorage.getItem("log_save");
      if (status == 1) {
        window.global_log = window.global_log || [];
        window.global_log.push(name);
      }
    } catch {}

    log(`%c${name}`, `color: ${color}; font-weight: bold`, obj);
  };

  static safeStringify = (obj) => {
    const cache = new Set();
    const replacer = (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (cache.has(value)) return;
        cache.add(value);
      }
      return value;
    };

    return JSON.stringify(obj, replacer);
  };
  static cleanPrototypes = (input) => {
    if (Array.isArray(input)) {
      return input.map((item) => Utils.cleanPrototypes(item));
    } else if (typeof input === "object" && input !== null) {
      const cleanedObj = {};

      for (const key in input) {
        if (key && Object.prototype.hasOwnProperty.call(input, key)) {
          cleanedObj[key] = Utils.cleanPrototypes(input[key]);
        }
      }

      return cleanedObj;
    } else {
      return input;
    }
  };
  static isCyclic(obj) {
    const seen = new WeakMap(); // WeakMap, garbage collector'a yardımcı olur

    function detectCycles(obj, stack) {
      if (obj && typeof obj === "object") {
        // obj nesne veya dizi olmalı
        // Eğer bu obje daha önce görüldüyse, döngü vardır
        if (seen.has(obj)) {
          return true;
        }

        seen.set(obj, true); // Bu objeyi "görüldü" olarak işaretle

        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            stack.push(key);
            if (detectCycles(obj[key], stack)) {
              // Rekürsif çağrı
              console.log("Loop found through: " + stack.join(" -> "));
              return true;
            }
            stack.pop();
          }
        }
      }

      return false;
    }

    return detectCycles(obj, []);
  }
  static escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  };

  static getWithOnlyAllowedKeys = (obj, allowedKeys = []) => {
    if (typeof obj === "object" && obj !== null) {
      if (Array.isArray(obj)) {
        return obj.map((item) => Utils.getWithOnlyAllowedKeys(item, allowedKeys));
      } else {
        const newObj = {};
        for (let key in obj) {
          if (allowedKeys.includes(key)) {
            newObj[key] = Utils.getWithOnlyAllowedKeys(obj[key], allowedKeys);
          }
        }
        return newObj;
      }
    }
    return obj;
  };
  static getWithNonAllowedKeys = (obj, nonAllowedKeys = [], skipObjectKeys = []) => {
    if (typeof obj === "object" && obj !== null) {
      if (Array.isArray(obj)) {
        return obj.map((item) => Utils.getWithNonAllowedKeys(item, nonAllowedKeys, skipObjectKeys));
      } else {
        const newObj = {};
        for (let key in obj) {
          if (!nonAllowedKeys.includes(key)) {
            if (skipObjectKeys.includes(key)) {
              newObj[key] = obj[key];
            } else {
              newObj[key] = Utils.getWithNonAllowedKeys(obj[key], nonAllowedKeys, skipObjectKeys);
            }
          }
        }
        return newObj;
      }
    }
    return obj;
  };
  static wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  static getId = () => {
    return uuidv4();
  };
  static isValidPythonVariableName(str = "") {
    const validChars = /^[a-zA-Z_][a-zA-Z0-9_]*$/;

    const keywords = [
      "False",
      "None",
      "True",
      "and",
      "as",
      "assert",
      "async",
      "await",
      "break",
      "class",
      "continue",
      "def",
      "del",
      "elif",
      "else",
      "except",
      "finally",
      "for",
      "from",
      "global",
      "if",
      "import",
      "in",
      "is",
      "lambda",
      "nonlocal",
      "not",
      "or",
      "pass",
      "raise",
      "return",
      "try",
      "while",
      "with",
      "yield",
    ];

    const isValidChars = validChars.test(str);
    const isNotKeyword = !keywords.includes(str);
    const isValid = isValidChars && isNotKeyword;
    return {
      valid: isValid,
      reason: !isValidChars
        ? "Field can only contain alphanumeric characters and underscores, and must not start with a number"
        : !isNotKeyword
          ? "Field cannot be a Python keyword"
          : "",
    };
  }
  static convertTextToPythonVariableName(str = "") {
    const cleanedStr = accent.remove(str);
    let newStr = cleanedStr.replace(/[^a-zA-Z0-9_]/g, "_").replace(/^[^a-zA-Z_]+/, "");

    //convert CamelCase to snake_case
    newStr = newStr.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();
    //remove multiple underscores
    newStr = newStr.replace(/_+/g, "_");
    //remove trailing underscores
    newStr = newStr.replace(/_+$/, "");
    //remove leading underscores
    newStr = newStr.replace(/^_+/, "");
    return newStr;
  }

  static slugify = (str = "") => {
    const cleanedStr = accent.remove(str);
    return cleanedStr
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-|-$/g, "");
  };

  static replaceOrigin = (url, newOrigin) => {
    const origin = newOrigin || window.location.origin;
    const urlObj = new URL(url);
    const newUrl = `${origin}${urlObj.pathname}${urlObj.search}`;
    return newUrl;
  };

  static isValidMimeType = (mimeList, file) => {
    if (!mimeList?.length) return true;

    for (const mime of mimeList) {
      const [typeMain, typeSub] = mime.split("/");
      const [fileMain, fileSub] = file.type.split("/");

      if (typeMain === "*" || typeMain === fileMain) {
        if (typeSub === "*" || typeSub === fileSub) {
          return true;
        }
      }
    }

    return false;
  };
  static urlToFile = async (url, filename, mimeType) => {
    if (url.startsWith("data:")) {
      let arr = url.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const file = new File([u8arr], filename, { type: mime || mimeType });
      return Promise.resolve(file);
    }
    return fetch(url)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], filename, { type: mimeType }));
  };

  static getFlagUrlByIsoCode = (isoCode = "") => {
    let code = isoCode?.toLowerCase();

    if (code === "en") code = "gb";
    return `/static/flags/webp/${code}.webp`;
  };
  static isValidUsername = (username) => {
    return /^[a-zA-Z0-9-]+$/.test(username);
  };
  static promiseRun = async (tasks, maxParallel) => {
    const results = [];
    const executing = [];

    for (const task of tasks) {
      const promise = task().then((result) => {
        results.push(result);
        executing.splice(executing.indexOf(promise), 1);
      });

      executing.push(promise);

      if (executing.length >= maxParallel) {
        await Promise.race(executing);
      }
    }

    await Promise.all(executing);
    return results;
  };
}
