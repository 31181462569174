import PRSwitch from "~components/Generic/PRSwitch";

import IntentRecordsList from "./IntentRecordList";

function IntentRecords() {
  const routes = [
    { path: "/chatbot/intent-records/", component: IntentRecordsList },
    { path: "/chatbot/", to: "chatbot/intent-records/", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}

export default IntentRecords;
