import { createRef, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { MdReplay } from "react-icons/md";
import { useSelector } from "react-redux";

import { postInviteUser } from "~apiRequests/permissions";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import PalTooltip from "~components/mui/PalTooltip";
import { apiUrlInvitation } from "~constants";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import { selectCurrentProject, selectUserInfo } from "~store/user/selectors";

function InvitedUser() {
  const userInfo = useSelector(selectUserInfo);
  const { t } = useTranslation();

  const currentProject = useSelector(selectCurrentProject);
  const tableRef = createRef();

  const handleClickSendResetPassword = async (data) => {
    if (
      !(await DialogHelper.showQuestionYesNo(null, t("dashboard.invitedUser.resendConfirmation").format(data.email)))
    ) {
      return;
    }
    let payload = { email: data.email, roles: data.roles };
    postInviteUser(currentProject.id, payload).then(() => {
      tableRef.current?.refresh();
    });
  };

  const columns = useMemo(() => {
    return [
      {
        label: t("common.email"),
        key: "email",
      },
      {
        label: t("dashboard.invitedUser.inviteDate"),
        key: "invite_date",
        render: (row) => {
          return (
            <PalTooltip title={DateHelper.getDateTimeLocal(row.invite_date).format("LLT")}>
              <span>{DateHelper.getDateTimeLocal(row.invite_date).fromNow()}</span>
            </PalTooltip>
          );
        },
      },
      {
        label: t("dashboard.invitedUser.expiresIn"),
        key: "expires_in",
        render: (row) => {
          return (
            <PalTooltip title={DateHelper.getDateTimeLocal(row.expires_in).format("LLT")}>
              <span>
                {(() => {
                  if (row.status === false) {
                    return <span className="text-success-600">{t("dashboard.invitedUser.accepted")}</span>;
                  }
                  const now = DateHelper.getDateTimeLocal();
                  if (DateHelper.getDateTimeLocal(row.expires_in).isBefore(now)) {
                    return <span className="text-danger">{t("dashboard.invitedUser.expired")}</span>;
                  }

                  const diff = DateHelper.getDateTimeLocal(row.expires_in).diff(
                    DateHelper.getDateTimeLocal(),
                    "seconds"
                  );
                  let duration = DateHelper.formatMoment(diff, "s", "auto");
                  return `${duration} left`;
                })()}
              </span>
            </PalTooltip>
          );
        },
      },
      {
        label: t("common.fullName"),
        key: "full_name",
        render: (row) => {
          if (!row.target_user) return "";
          return `${row.target_user?.firstname} ${row.target_user?.lastname}`;
        },
      },
      {
        label: t("dashboard.invitedUser.roles"),
        key: "roles",
        render: (obj, _index, _value) => {
          return (
            <ul className="m-0">
              {obj.roles.map((role, i) => (
                <li key={i} className=" ">
                  {role}
                </li>
              ))}
            </ul>
          );
        },
      },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        render: (row) => {
          const handleClickReset = () => {
            handleClickSendResetPassword(row);
          };
          const now = DateHelper.getDateTimeLocal();
          let expired = false;
          if (DateHelper.getDateTimeLocal(row.expires_in).isBefore(now)) {
            expired = true;
          }

          return (
            <div className="gap-2 d-flex justify-content-center">
              {userInfo.is_superuser && (
                <PRButton
                  outline
                  color="primary"
                  disabled={!expired}
                  icon={MdReplay}
                  size="sm"
                  tooltipText={t("dashboard.invitedUser.resend")}
                  onClick={handleClickReset}
                />
              )}
            </div>
          );
        },
      },
    ];
  }, [t]);

  const parentName = [
    {
      label: t("common.user"),
      url: "/settings/user",
    },
    {
      label: t("dashboard.invitedUser"),
    },
  ];
  return (
    <PRContainer
      description={t("dashboard.invitedUser.description")}
      name={t("common.settings")}
      parentName={parentName}
    >
      <div className="mt-2">
        <PRTable ref={tableRef} columns={columns} url={apiUrlInvitation.get.format(currentProject.id)} />
      </div>
    </PRContainer>
  );
}

export default InvitedUser;
