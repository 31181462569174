import React, { forwardRef } from "react";

import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";

const Styled = styled(IconButton)`
  // background: #f8f8f8;

  &.MuiIconButton-sizeMedium {
    height: 52px;
    width: 52px;
  }
  &.MuiIconButton-sizeSmall {
    height: 24px;
    width: 24px;
  }
`;

/**
 * @param {import("@mui/material/IconButton").IconButtonProps} props
 * @param {React.Ref<any>} ref
 */
const PalIconButton = ({ ...rest }, ref) => {
  return <Styled {...rest} ref={ref} />;
};

export default forwardRef(PalIconButton);
