import React from "react";

// @svgr/webpack import syntax
import { useTranslation } from "react-i18next";

import { ReactComponent as LogoFilledSvg } from "~assets/images/logo-filled-notext.svg";

import "./style.scss";

export function LoadingOverlayBase() {
  const { t } = useTranslation();
  return (
    <div className="pr-loading-overlay">
      <div>
        <LogoFilledSvg />
      </div>
      <span className="text-white text-center fs-5 fw-bold">{t("component.loadingOverlayBase.loading")}</span>
    </div>
  );
}

const _loadingKey = "generic-loading-dialog-component";
// export default function LoadingOverlay() {
//   useDebouncedEffect(
//     () => {
//       // const minimized = selectMinimized(store.getState());
//       // const viewMode = selectViewMode(store.getState());
//       // if (IFrameHelper.isWebChatMode() && ((IFrameHelper.isInIFrame() && minimized) || viewMode === "chat")) return;
//       DialogHelper.show("", <LoadingOverlayBase />, [], {
//         bare: true,
//         transparent: true,
//         disableBackdropClick: true,
//         key: _loadingKey,
//       });
//       return () => {
//         DialogHelper.close(_loadingKey);
//       };
//     },
//     [],
//     100
//   );
// }
