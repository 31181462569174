import { dialogComponentsAuthType, dialogComponentsIntentType } from "~constants";
import { safeProduce as produce } from "~helpers/immer";
import Utils from "~helpers/Utils";

import * as actionTypes from "./actionTypes";
/**
 * // MessageItem type definition
 *
 * @typedef {keyof typeof import("~constants").dialogComponentsResponseMessageTypes} MessageItemTypeKeys
 *
 * @typedef {(typeof import("~constants").dialogComponentsResponseMessageTypes)[MessageItemTypeKeys]} MessageItemTypes
 *
 * @typedef {{
 *   id?: number;
 *   order?: number;
 *   text: string;
 *   button_type: "Go Message Item" | "Go Intent";
 *   project: number;
 *   bot: number;
 *   go_message_item?: MessageItem;
 *   go_intent?: number;
 * }} MessageButtonType
 *
 *
 * @typedef {{
 *   id?: number;
 *   order?: number;
 *   message_type: MessageItemTypes;
 *   text?: string;
 *   file_url?: string;
 *   image_url?: string;
 *   message_buttons: MessageButtonType[];
 *   project?: number;
 *   bot?: number;
 *   resource?: number;
 * }} MessageItem
 */

/**
 * // Form type definition
 *
 * @typedef {{
 *   id?: number;
 *   order?: number;
 *   question: string; // dynamic
 *   display_key: string;
 *   key: string;
 *   entity_type: null;
 *   project: number;
 *   bot: number;
 * }} FormItemType
 *
 *
 * @typedef {{
 *   id?: number;
 *   action_type: "Save Ticket" | "Send Email";
 *   email_title?: string;
 *   email_format?: string;
 *   email_to_sent?: string;
 *   ticket_body?: string;
 *   project: number;
 *   bot: number;
 * }} FormAction
 *
 *
 * @typedef {{
 *   id?: number;
 *   validation?: string;
 *   form_items: FormItemType[];
 *   actions: FormAction[];
 *   project: number;
 *   bot: number;
 * }} FormType
 */

/**
 * //Slots Type definition
 *
 * @typedef {{
 *   entity_type: { id: number };
 *   name: string; //AKA key
 *   question: string;
 *   display_name: string;
 * }} SlotType
 */

/** @typedef {{ procedure: number; parameters: any[] }} Procedure */

/**
 * @typedef {keyof typeof import("~constants").dialogComponentsAuthType} AuthTypeKeys
 *
 * @typedef {(typeof import("~constants").dialogComponentsAuthType)[AuthTypeKeys]} AuthType
 */

/**
 * @typedef {object} RagFactGroup
 * @property {number} id
 * @property {string} content
 * @property {string} summary
 * @property {string[]} related_questions
 * @property {string} status
 * @property {number} project
 */

//seal the object with lodash

export const initState = Object.freeze({
  intent: {
    id: null,
    /** @type {number} */
    intent_tag: null,
    //read only
    intent_tag_text: null,
    slot_validation_text: "",
    text: "",
    display_text: "",
    authentication_required: false,
    use_in_prediction: true,
    // post_procedure: null,
    response: {
      id: null,
      /** @type {number[]} */
      suggested_intents: [],
      ask_feedback: false,
      /** @type {MessageItem[]} */
      message_items: [],

      /** @type {number} */
      project: null,

      /** @type {number} */
      bot: null,
    },
    /** @type {SlotType[]} */
    slots: [],
    /** @type {FormType[]} */
    forms: [],
    /** @type {{ id?: number; intent_text: string; saved_to_data: boolean; project?: number; bot?: number }[]} */
    samples: [],
    saved_to_data: false,
    follows_from: null,
    translations: {
      TR: 0,
    },
    /** @type {number} */
    project: null,
    /** @type {number} */
    bot: null,
    followup_intents: [],
    /** @type {AuthType} */
    auth_type: dialogComponentsAuthType.ANONYMOUS,
    preprocess_low_code_script: "",
    /** @type {keyof typeof dialogComponentsIntentType} */
    intent_type: dialogComponentsIntentType.STANDARD,
    /** @type {RagFactGroup} */
    rag_fact_group: {
      id: null,
      content: "",
      summary: "",
      related_questions: [],
      status: "",
      project: null,
    },
  },
  intentLoading: false,
  intentSaving: false,
  procedureList: [],
  sessionSamples: [],
  intentSamples: [],
  intentResponses: [],
  variableList: [],
  mediaList: [],
  databaseList: [],
  insight: {
    intents: [],
    samples: [],
  },
  insightData: null,
});

const scenarioManager = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.SET_SCENARIO_FORM_STATE:
        const { key, value } = action.payload;
        if (key && typeof value === "function") {
          draft.intent[key] = value(draft[key], draft);
        } else if (!key && typeof value === "object") {
          draft.intent = value;
        } else {
          Utils.setBySelector(draft.intent, key, value);
        }
        break;
      case actionTypes.RESET_FORM:
        draft.intent = initState.intent;
        break;
      case actionTypes.SET_FORM_LOADING:
        draft.intentLoading = action.payload;
        break;
      case actionTypes.SET_FORM_SAVING:
        draft.intentSaving = action.payload;
        break;
      case actionTypes.SET_PROCEDURE_LIST:
        draft.procedureList = action.payload;
        break;
      case actionTypes.SET_SESSION_SAMPLE_PREDICTION:
        draft.sessionSamples = action.payload;
        break;
      case actionTypes.SET_INTENT_SAMPLE_PREDICTION:
        draft.intentSamples = action.payload;
        break;
      case actionTypes.SET_INTENT_RESPONSE_PREDICTION:
        draft.intentResponses = action.payload;
        break;

      case actionTypes.SET_MEDIA_LIST:
        draft.mediaList = action.payload;
        break;
      case actionTypes.SET_DATABASE_LIST:
        draft.databaseList = action.payload;
        break;
      case actionTypes.SET_VARIABLE_LIST:
        draft.variableList = action.payload;
        break;
      case actionTypes.SET_INSIGHT:
        draft.insight = action.payload;
        break;
      case actionTypes.SET_INSIGHT_DATA:
        draft.insightData = action.payload;
        break;
    }
  });
export default scenarioManager;
