import { createRef, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { MdAdd, MdBarChart, MdCampaign, MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

// import ShowSurveyAnnouncesModal from "~common/modals/ShowSurveyAnnouncesModal";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import { apiUrlOrganization, organizationSurveyTypeMap, organizationSurveyTypeOptions, userRole } from "~constants";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import { deleteSurvey } from "~store/organization/actions";
import { selectCurrentProject } from "~store/user/selectors";

import "./style.scss";

export default function SurveyList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const tableRef = createRef();

  const columns = useMemo(() => {
    const handleDelete = (row) => async () => {
      if (!(await DialogHelper.showQuestionDelete())) return;
      dispatch(deleteSurvey(row.id));
      tableRef.current.refresh();
    };

    // const handleClickHistory = (row) => () => {
    //   // ShowSurveyAnnouncesModal.show({ surveyId: row.id });
    // };
    return [
      {
        label: t("common.name"),
        key: "name",
      },
      {
        label: t("dashboard.surveys.entryMessage"),
        key: "entry_message",
      },
      {
        label: t("dashboard.surveys.exitMessage"),
        key: "exit_message",
      },
      {
        label: t("dashboard.surveys.dueTime"),
        key: "due_time",
        render: (row) => <span>{DateHelper.getDateTimeLocal(row.due_time).format("L")}</span>,
      },
      {
        label: t("dashboard.surveys.surveyType"),
        key: "survey_type",
        render: (row) => t(organizationSurveyTypeMap[row.survey_type]) || row.survey_type,
      },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        render: (row) => (
          <div className="d-flex justify-content-center gap-1">
            <PRButton
              outline
              color={"success-600"}
              icon={MdCampaign}
              link={`/organization/announcement/form/?survey=${row.id}&survey_name=${row.name}`}
              size="sm"
              tooltipText={t("dashboard.surveys.announcement")}
            />
            {currentProject.permissions?.some((p) => [userRole.admin].includes(p)) && (
              <>
                <PRButton
                  outline
                  color="primary"
                  icon={MdBarChart}
                  link={`/organization/surveys/results/${row.id}`}
                  size="sm"
                  tooltipText="Show Results"
                />
                {/* <PRButton
                  outline
                  color="primary"
                  icon={MdBarChart}
                  link={`/stats/survey/${row.id}`}
                  size="sm"
                  tooltipText="Go to Statistics"
                /> */}
              </>
            )}
            <PRButton
              outline
              color="primary"
              icon={MdEdit}
              link={`/organization/surveys/form/${row.id}`}
              size="sm"
              tooltipText={t("common.edit")}
            />
            <PRButton
              outline
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText={t("common.delete")}
              onClick={handleDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [dispatch, currentProject.permissions, tableRef, t]);

  const actions = useMemo(() => {
    return [
      {
        label: t("common.createNew"),
        icon: MdAdd,
        color: "success",
        link: "/organization/surveys/form",
      },
    ];
  }, []);

  const filters = useMemo(() => {
    return [
      {
        key: "survey_type",
        options: organizationSurveyTypeOptions,
      },
    ];
  }, []);
  return (
    <PRContainer
      actions={actions}
      className=""
      description={t("dashboard.surveys.description")}
      name={t("common.organization")}
      parentName={t("dashboard.surveys")}
    >
      <PRTable
        ref={tableRef}
        columns={columns}
        filters={filters}
        url={apiUrlOrganization.getSurvey.format(currentProject.id)}
      />
    </PRContainer>
  );
}
