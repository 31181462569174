import chroma from "chroma-js";

const { default: styled } = require("@emotion/styled");

const HtmlContent = styled.div`
  font-size: 16px;
  line-height: 18px;
  a {
    color: ${(props) => {
      let linkColor = props.theme.palette.primary.main;
      const defaultLeftBackgroundColor = "#f8f8f8";
      const primaryColorChroma = props.theme.palette.primary.main;
      const contrastDifference = chroma.contrast(primaryColorChroma, defaultLeftBackgroundColor);
      if (4.5 > contrastDifference) {
        linkColor = chroma(primaryColorChroma)
          .darken(4.5 - contrastDifference)
          .hex();
      }

      return linkColor;
    }};
  }
  ${(props) =>
    props.isRightBalloon &&
    `
    color: #ebebeb;    
    a {
        color: #ebebeb;
    }
    `}
  a {
    text-decoration: underline;
  }
  ol,
  ul,
  dl {
    margin-bottom: 1rem;
  }
  white-space: normal;

  ol > li,
  ul > li {
    margin: 5px 0;
    padding: 0;
  }
`;

export default HtmlContent;
