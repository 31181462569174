import React from "react";

import classNames from "classnames";
import { Collapse } from "reactstrap";

import "./style.scss";
export default function PRAccordionItem({
  title,
  children,
  noSpacing,
  secondary,
  collapsed,
  onClick,
  className,
  classNameButton,
  defaultCollapsed,
  noHeaderBackground,
}) {
  const [isOpen, setIsOpen] = React.useState(defaultCollapsed ?? false);
  const isUncontrolled = collapsed === undefined && onClick === undefined;

  const handleClick = (e) => {
    if (isUncontrolled) {
      setIsOpen((p) => !p);
    }
    onClick?.(e, !collapsed);
  };

  return (
    <div
      className={classNames("accordion-item", "pr-accordion-item", className, {
        "secondary-color": secondary,
        "no-spacing": noSpacing,
      })}
    >
      <h2 className="accordion-header">
        <button
          className={classNames("accordion-button", "fw-bold", classNameButton, {
            collapsed: isUncontrolled ? !isOpen : !collapsed,
            "disable-button-color": noHeaderBackground,
          })}
          style={{ cursor: "pointer" }}
          type="button"
          onClick={handleClick}
        >
          <div className="position-relative d-flex align-items-center w-100">
            {typeof title === "function" ? title() : title}
          </div>
        </button>
      </h2>

      <Collapse className={classNames("accordion-collapse")} isOpen={isUncontrolled ? isOpen : collapsed}>
        <div className="accordion-body">
          <div className="text-muted">{children}</div>
        </div>
      </Collapse>
    </div>
  );
}
