import { useState, useEffect, useMemo } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { MdAdd, MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

import PRButton from "~components/Generic/PRButton";
import PRDivider from "~components/Generic/PRDivider";
import PRDropdown from "~components/Generic/PRDropdown";
import PRInput from "~components/Generic/PRInput";
import PRSelect from "~components/Generic/PRSelect";
import {
  organizationComparisonTypesOptions,
  organizationComparisonTypes,
  organizationFilterType,
  organizationFilterTypeOptions,
} from "~constants";
import { selectMemberFieldFormat } from "~store/organization/selectors";

import "./style.scss";
import { useHoverContext } from "../FilterRule";

const emptyFilterItem = {
  filter_type: organizationFilterType.COMPARISON,
  member_data_field: null,
  comparison_type: organizationComparisonTypesOptions[0].value,
  value: "",
};

function FilterItem({ filter: filterProp, depth, onChange, onDelete, showDelete }) {
  const { t } = useTranslation();
  const [globalHover, setGlobalHover] = useHoverContext();
  const [isActionHover, setIsActionHover] = useState(false);
  const memberDataFormat = useSelector(selectMemberFieldFormat);
  const fields = memberDataFormat?.member_data_fields;

  const filter = useMemo(() => {
    if (!filterProp?.filter_type) {
      return { ...filterProp, ...emptyFilterItem };
    }
    return filterProp;
  }, [filterProp]);

  const finalOrganizationFilterTypeOptions = useMemo(() => {
    return organizationFilterTypeOptions.map((option) => ({ ...option, label: t(option.label) }));
  }, [t]);

  const fieldOptions = useMemo(() => {
    return fields?.map((field) => ({
      label: field.display_name,
      value: field.id,
    }));
  }, [fields]);

  const selectedField = useMemo(() => {
    return fieldOptions?.find((field) => field.value === filter?.member_data_field?.id);
  }, [fieldOptions, filter]);

  const selectedComparison = useMemo(() => {
    return organizationComparisonTypesOptions?.find((option) => option.value === filter?.comparison_type);
  }, [filter?.comparison_type]);

  const selectedFilterType = useMemo(() => {
    return organizationFilterTypeOptions?.find((option) => option.value === filter?.filter_type);
  }, [filter?.filter_type]);

  const childFilters = filter?.children || [];

  const handleChange = (key) => (e) => {
    let value = e.target ? e.target.value : e;
    if (["comparison_type"].includes(key)) {
      value = e.value;
    } else if (["member_data_field"].includes(key)) {
      value = { id: e.value };
    } else if (key === "filter_type" && value !== organizationFilterType.COMPARISON) {
      const childrenItems = [...childFilters];
      while (childrenItems.length < 2) {
        const newChild = { ...emptyFilterItem };
        if (childrenItems?.length === 0) {
          if (filterProp?.member_data_field?.id) {
            newChild.member_data_field = { id: filterProp?.member_data_field?.id };
          }
          newChild.value = filterProp?.value;
          newChild.comparison_type = filterProp?.comparison_type;
        }
        childrenItems.push(newChild);
      }
      onChange({
        ...filter,
        comparison_type: organizationComparisonTypes.EXACT,
        filter_type: value,
        children: childrenItems,
      });
      return;
    }

    onChange({ ...filter, [key]: value });
  };

  const handleChangeChild = (currFilter) => (newFilter) => {
    const newChildFilters = childFilters.map((filter) => {
      if (filter === currFilter) {
        return newFilter;
      }
      return filter;
    });
    onChange({ ...filter, children: newChildFilters });
  };

  const handleDeleteChild = (currFilter) => () => {
    const newChildFilters = childFilters.filter((filter) => filter !== currFilter);
    onChange({ ...filter, children: newChildFilters });
  };

  const handleHoverAction = (isHover) => () => {
    setIsActionHover(isHover);
    setGlobalHover(isHover);
  };

  const handleClickAddChild = () => {
    const newChildFilters = [...childFilters, { ...emptyFilterItem }];
    onChange({ ...filter, children: newChildFilters });
  };

  useEffect(() => {
    if (globalHover !== isActionHover) {
      setIsActionHover(false);
    }
  }, [globalHover, isActionHover]);
  return (
    <div
      className={classNames("pr-filter-item", {
        "add-hover": isActionHover,
      })}
    >
      {filter?.filter_type === organizationFilterType.COMPARISON ? (
        <Row className="gx-2 align-items-center justify-content: center filter-border">
          <Col lg="3" md="3">
            <PRSelect options={fieldOptions} value={selectedField} onChange={handleChange("member_data_field")} />
          </Col>
          <Col xs="auto">
            <span className="text-secondary ">{t("component.filterItem.between1")}</span>
          </Col>
          <Col lg="3" md="3">
            <PRSelect
              options={organizationComparisonTypesOptions}
              value={selectedComparison}
              onChange={handleChange("comparison_type")}
            />
          </Col>
          <Col xs="auto">
            <span className="text-secondary ">{t("component.filterItem.between2")}</span>
          </Col>
          <Col lg="3" md="2">
            <PRInput type="text" value={filter?.value} onChange={handleChange("value")} />
          </Col>
          <Col xs></Col>
          <Col xs="auto">
            <div className="d-flex align-items-center">
              {/* <span className="text-secondary me-2">Comparison Type:</span> */}
              <PRDropdown
                label={t(selectedFilterType?.label)}
                labelSelector="label"
                options={finalOrganizationFilterTypeOptions}
                valueSelector="value"
                onChange={handleChange("filter_type")}
                // secondaryButtonProps={{
                //   position: "right",
                //   label: "Add",
                // }}
              />
            </div>
          </Col>
          {showDelete && (
            <Col xs>
              <PRButton
                outline
                color="danger"
                icon={MdDelete}
                onClick={onDelete}
                onMouseEnter={handleHoverAction(true)}
                onMouseLeave={handleHoverAction(false)}
              />
            </Col>
          )}
        </Row>
      ) : (
        <>
          {/* <Row className="gx-2 align-items-center justify-content: center filter-border">
            <Col xs>
              <span className="text-secondary me-2 font-size-18">Filter Group</span>
            </Col>
            <Col xs="auto">
              <PRDropdown
                labelSelector="label"
                valueSelector="value"
                options={filterTypeOptions}
                label={selectedFilterType?.label}
                onChange={handleChange("filter_type")}
                // size="sm"
              />
            </Col>
          </Row> */}
        </>
      )}
      {!!childFilters?.length && filter?.filter_type !== organizationFilterType.COMPARISON && (
        <Row className="gx-2  mt-2 align-items-stretch">
          <Col className="filter-type me-2" xs={"auto"}>
            {/* {selectedFilterType?.label} */}
            <PRDropdown
              label={t(selectedFilterType?.label)}
              labelSelector="label"
              options={finalOrganizationFilterTypeOptions}
              size="sm"
              valueSelector="value"
              onChange={handleChange("filter_type")}
              className={classNames("h-100", {
                and: selectedFilterType?.value === organizationFilterType.AND,
                or: selectedFilterType?.value === organizationFilterType.OR,
              })}
              // color={selectedFilterType?.value === organizationFilterType.AND ? "pink" : "secondary"}
            />
          </Col>
          <Col xs>
            <Row className="g-2">
              {childFilters?.map((child, index) => {
                return (
                  <Col key={child.id || index} xs={12}>
                    <FilterItem
                      depth={depth + 1}
                      filter={child}
                      order={index}
                      showDelete={childFilters.length > 1}
                      onChange={handleChangeChild(child)}
                      onDelete={handleDeleteChild(child)}
                    />
                  </Col>
                );
              })}
            </Row>
            <Row key={childFilters.length + 1} className="g-1 flex-column align-items-stretch">
              <Col xs={"auto"}></Col>
              <Col className="filter-action" xs={"auto"}>
                <PRButton
                  className="me-2"
                  color="success"
                  icon={MdAdd}
                  size="sm"
                  onClick={handleClickAddChild}
                  onMouseOut={handleHoverAction(false)}
                  onMouseOver={handleHoverAction(true)}
                />
              </Col>
              <Col xs={"auto"}>
                <PRDivider size={0} />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
}
export default FilterItem;
