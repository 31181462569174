import { Fragment, useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { MdHelp } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import useFormikEx from "~common/hooks/useFormikEx";
import useLoading from "~common/hooks/useLoading";
import useProjectChange from "~common/hooks/useProjectChange";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import { PRDividerLabel } from "~components/Generic/PRDivider";
import PRInput from "~components/Generic/PRInput";
import PRPage from "~components/Generic/PRPage";
import PRTooltip from "~components/Generic/PRTooltip";
import HistoryHelper from "~helpers/HistoryHelper";
import { getProcedureList } from "~store/dialogComponents/scenarioManager/actions";
import {
  createOrUpdateModuleIntegration,
  getModuleIntegration,
  getModuleVersion,
  setModuleIntegration,
  setModuleVersion,
} from "~store/integration/module/actions";
import { selectModuleIntegration, selectModuleVersion } from "~store/integration/module/selectors";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

const ModuleIntegrationEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const moduleIntegration = useSelector(selectModuleIntegration);
  const moduleVersion = useSelector(selectModuleVersion);
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const [loading, q] = useLoading();

  useEffect(() => {
    if (!id) return;
    q(dispatch(getModuleIntegration(currentProject.id, id))).then((response) => {
      if (response?.version) {
        q(dispatch(getModuleVersion(currentProject.id, response.version)));
      }
    });

    return () => {
      dispatch(setModuleIntegration(null));
      dispatch(setModuleVersion(null));
    };
  }, [currentProject.id, dispatch, id, q]);

  useEffect(() => {
    q(dispatch(getProcedureList(currentProject.id, currentBot.id)));
  }, [currentProject.id, dispatch, currentBot.id, q]);

  const formik = useFormikEx({
    enableReinitialize: true,
    initialValues: {
      ...moduleIntegration,
      enabled: moduleIntegration?.enabled || false,
      parameters: moduleVersion?.parameters?.reduce((acc, cur) => {
        acc[cur.name] = moduleIntegration?.parameters[cur.name] || cur.default_value;
        return acc;
      }, {}),
      version: moduleVersion?.id || "",
    },
    validationSchema: Yup.object({
      parameters: Yup.object().shape({
        ...moduleVersion?.parameters?.reduce((acc, cur) => {
          acc[cur.name] = cur.required
            ? Yup.string().required(t("component.formik.required").format(cur.name))
            : Yup.string();
          return acc;
        }, {}),
      }),
    }),
    onSubmit: async (values, f, e) => {
      await dispatch(createOrUpdateModuleIntegration(currentProject.id, id, values));
      if (!e?.shiftKey) {
        HistoryHelper.goBack("/integration/module-integration/", { scope: "dashboard" });
      }
    },
  });
  useProjectChange(() => {
    HistoryHelper.push("/integration/module-integration/", { scope: "dashboard" });
  }, []);

  const parentName = useMemo(
    () => [
      {
        label: t("common.integration"),
        url: "/integration/module",
      },
      {
        label: id ? t("dashboard.moduleIntegration.editModule") : t("dashboard.moduleIntegration.createModule"),
      },
    ],
    [id, t]
  );

  const handleClickCancel = () => {
    HistoryHelper.goBack("/integration/module-integration/", { scope: "dashboard" });
  };
  return (
    <PRContainer loading={loading} name="Integration" parentName={parentName}>
      <PRPage title={id ? t("dashboard.moduleIntegration.editModule") : t("dashboard.moduleIntegration.createModule")}>
        <Row className="g-0 gy-2">
          <Col xs="4">
            <Label className="">{t("common.enable")}</Label>
          </Col>
          <Col xs="8">
            <PRInput
              checked={formik.values.enabled}
              invalid={formik.touched.enabled && formik.errors.enabled}
              name="enabled"
              type="checkbox"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
          <Col xs="12">
            {/* <Label className="">:</Label> */}
            <PRDividerLabel pageSeparator>{t("common.parameters")}</PRDividerLabel>
          </Col>
          <Col xs="12">
            <Row className="g-0 gy-1">
              {moduleVersion?.parameters?.map((item) => (
                <Fragment key={item.name}>
                  <Col xs="4">
                    <Label className="lh-1 d-flex align-items-center">
                      {item.name}
                      <PRTooltip title={item.description}>
                        <MdHelp className="ms-1 fs-6" />
                      </PRTooltip>
                      {item.required && <span className="ms-1 text-danger">*</span>}
                    </Label>
                  </Col>
                  <Col xs="8">
                    <PRInput
                      invalid={formik.touched?.parameters?.[item.name] && formik.errors?.parameters?.[item.name]}
                      name={`parameters.${item.name}`}
                      type="text"
                      value={formik.values?.parameters?.[item.name]}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Fragment>
              ))}
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-end mt-2">
          <Col xs="auto">
            <PRButton outline className="me-2" onClick={handleClickCancel}>
              {t("common.cancel")}
            </PRButton>
            <PRButton onClick={formik.handleSubmit}>{id ? t("common.update") : t("common.create")}</PRButton>
          </Col>
        </Row>
      </PRPage>
    </PRContainer>
  );
};

export default ModuleIntegrationEdit;
