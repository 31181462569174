import { createTheme } from "@mui/material/styles";

const primaryColor = "#28AAE1";
// const primaryLight = "#50C8F4";

const textMain = "#838383";
const textLight = "#DDDDDD";
const textDark = "#535353"; //"rgba(0, 0, 0, 0.7)"

export const defaultTheme = {
  typography: {
    fontFamily: '"Museo Sans", "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  palette: {
    primary: {
      // light: "#50C8F4",
      main: primaryColor,
      // dark: "#04133A",
      contrastText: "#fff",
    },
    secondary: {
      light: textLight,
      main: textMain,
      dark: textDark,
    },
    error: {
      main: "#D32A5E",
    },
  },

  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            color: "#838383",
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 14,
          boxShadow: "2px 2px 24px 0 rgba(0, 0, 0, 0.24)",
          border: "solid 1px #f8f8f8",
        },
      },
    },
  },
  // overrides: {
  // MuiIconButton: {
  //   root: {
  //     padding: 5,
  //     borderRadius: "5px !important",
  //     "&:hover": {
  //       borderRadius: 5,
  //       backgroundColor: "rgba(0, 0, 0, 0.3)",
  //     },
  //   },
  // },
  // },
};

export default class ThemeMapper {
  static initializeStyle = (style = {}) => {
    return {
      ...style,
      primary_color: style.primary_color || defaultTheme.palette.primary.main,
      icon_color: style.icon_color || "#6d6d6d",
      header_color: style.header_color || defaultTheme.palette.primary.main,
      header_background_color: style.header_background_color || "#f1dadf",
      header_icon_color: style.header_icon_color || style.icon_color || "#6d6d6d",
      chat_background_color: style.chat_background_color || "#fefefe",
      chat_text_color: style.chat_text_color || textDark,
      chat_ballon_shadow: style.chat_ballon_shadow || "2px 2px 0px #0000000a",
      chat_choice_border: style.chat_choice_border || "2px solid #bcbcbc",
      chat_choice_disabled_opacity: style.chat_choice_disabled_opacity || "0.5",
      chat_balloon_time_opacity: style.chat_balloon_time_opacity || "0.35",
      chat_balloon_customer_text_color: style.chat_balloon_customer_text_color || "#ffffff",
      chat_balloon_customer_background_color: style.chat_balloon_customer_background_color || "#000faa88",
      chat_balloon_customer_border: style.chat_balloon_customer_border || "1px solid #e8e8e8",
      chat_balloon_left_text_color: style.chat_balloon_left_text_color || textDark,
      chat_balloon_left_background_color: style.chat_balloon_left_background_color || "#f8f8f8",
      chat_balloon_left_border: style.chat_balloon_left_border || "1px solid #e8e8e8",
      chat_balloon_bot_text_color: style.chat_balloon_bot_text_color || style.chat_balloon_left_text_color || textDark,
      chat_balloon_bot_background_color:
        style.chat_balloon_bot_background_color || style.chat_balloon_left_background_color || "#f8f8f8",
      chat_balloon_bot_border: style.chat_balloon_bot_border || style.chat_balloon_left_border || "1px solid #e8e8e8",
      chat_balloon_agent_text_color:
        style.chat_balloon_agent_text_color || style.chat_balloon_left_text_color || textDark,
      chat_balloon_agent_background_color:
        style.chat_balloon_agent_background_color || style.chat_balloon_left_background_color || "#f8f8f8",
      chat_balloon_agent_border:
        style.chat_balloon_agent_border || style.chat_balloon_left_border || "1px solid #e8e8e8",
      chat_balloon_filebox_background_color: style.chat_balloon_filebox_background_color || "#ffffff",
      chat_balloon_filebox_text_color: style.chat_balloon_filebox_text_color || textDark,
      input_background_color: style.input_background_color || "#f8f8f8",
      input_text_color: style.input_text_color || textDark,
      input_border: style.input_border || "none",

      custom_css: style.custom_css || "",
    };
  };

  static createTheme = (style = {}) => {
    const initializedStyle = ThemeMapper.initializeStyle(style);

    const baseTheme = createTheme(defaultTheme);

    const themeJson = {
      palette: {
        primary: baseTheme.palette.augmentColor({
          color: {
            main: initializedStyle.primary_color,
          },
          name: "primary",
        }),
        chat: {
          icon: {
            color: initializedStyle.icon_color,
          },
          header: {
            color: initializedStyle.header_color,
            backgroundColor: initializedStyle.header_background_color,
            iconColor: initializedStyle.header_icon_color,
          },
          body: {
            color: initializedStyle.chat_text_color,
            backgroundColor: initializedStyle.chat_background_color,
          },
          balloon: {
            shadow: initializedStyle.chat_ballon_shadow,
            choiceBorder: initializedStyle.chat_choice_border,
            choiceDisabledOpacity: initializedStyle.chat_choice_disabled_opacity,
            timeOpacity: initializedStyle.chat_balloon_time_opacity,
            customer: {
              color: initializedStyle.chat_balloon_customer_text_color,
              backgroundColor: initializedStyle.chat_balloon_customer_background_color,
              border: initializedStyle.chat_balloon_customer_border,
            },
            agent: {
              color: initializedStyle.chat_balloon_agent_text_color,
              backgroundColor: initializedStyle.chat_balloon_agent_background_color,
              border: initializedStyle.chat_balloon_agent_border,
            },
            bot: {
              color: initializedStyle.chat_balloon_bot_text_color,
              backgroundColor: initializedStyle.chat_balloon_bot_background_color,
              border: initializedStyle.chat_balloon_bot_border,
            },
            fileBox: {
              color: initializedStyle.chat_balloon_filebox_text_color,
              backgroundColor: initializedStyle.chat_balloon_filebox_background_color,
            },
          },
          input: {
            color: initializedStyle.input_text_color,
            backgroundColor: initializedStyle.input_background_color,
            border: initializedStyle.input_border,
          },
        },
      },
    };

    const createdTheme = createTheme(baseTheme, themeJson);
    return createdTheme;
  };
}
