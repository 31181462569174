import { useEffect } from "react";

import { withCardon } from "cardon";
import { useDispatch, useSelector } from "react-redux";

import styled from "@emotion/styled";
import { Grid } from "@mui/material";

import PRModal from "~components/Generic/PRModal";
import RenderHelper from "~helpers/RenderHelper";
import { getAllAppVersions } from "~store/actions";
import { selectAllAppVersions } from "~store/layout/selectors";

const Divider = styled.hr`
  margin: 0.5rem 0;
  border: 0;
  border-top: 1px dashed var(--bs-gray);
`;
const Container = styled.div`
  max-height: 70vh;
  overflow-y: auto;
  padding: 1rem;
`;
function AppVersionModalContent({ get }) {
  const dispatch = useDispatch();
  const allAppVersions = useSelector(selectAllAppVersions);

  useEffect(() => {
    if (!allAppVersions?.length) {
      dispatch(getAllAppVersions());
    }
  }, [allAppVersions, dispatch]);

  return (
    <PRModal size="lg" submitText={""} title="Version Changelog" onClose={get(false)}>
      <Container>
        <Grid container spacing={2}>
          {allAppVersions.map((appVersion, index, arr) => (
            <>
              <Grid key={appVersion.id} item xs={12}>
                <h3 className="text-primary">
                  {[appVersion.app_version, appVersion.title].filter(Boolean).join(" - ")}
                </h3>
                {appVersion.change_logs.map((changeLog) => (
                  <div key={changeLog.id}>
                    <h4>{changeLog.title}</h4>
                    <p className="text-muted">
                      <div dangerouslySetInnerHTML={{ __html: RenderHelper.renderMd(changeLog.body) }} />
                    </p>
                  </div>
                ))}
              </Grid>
              {index !== arr.length - 1 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </>
          ))}
        </Grid>
      </Container>
    </PRModal>
  );
}
const AppVersionModal = withCardon(AppVersionModalContent, { destroyOnHide: true });
export default AppVersionModal;
