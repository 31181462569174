import PRSwitch from "~components/Generic/PRSwitch";

import AdminWallboardEdit from "./AdminWallboardEdit";
import AdminWallboardList from "./AdminWallboardList";

export default function AdminWallboard() {
  const routes = [
    { path: "/contactcenter/wallboard/form/:id?", component: AdminWallboardEdit },
    { path: "/contactcenter/wallboard", component: AdminWallboardList },
  ];
  return <PRSwitch routes={routes} />;
}
