import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { MdHistory, MdLiveHelp, MdPeople } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import styled from "@emotion/styled";
import { FormControlLabel, Grid, Switch, Tab, Tabs } from "@mui/material";

import useLoading from "~common/hooks/useLoading";
import PRChart from "~components/Generic/PRChart";
import chartTheme from "~components/Generic/PRChart/theme.json";
import PalContainer from "~components/mui/PalContainer";
import PalPage from "~components/mui/PalPage";
import AggregateCard from "~components/Statistics/AggregateCard";
import ExcelButton from "~components/Statistics/ExcelButton";
import FilterCard from "~components/Statistics/FilterCard";
import AlertHelper from "~helpers/AlertHelper";
import FileHelper from "~helpers/FileHelper";
import { getSessionCountPieChartStatistics, getSessionCountSankeyStatistics } from "~store/statistics/actions";
import { selectStatistics, selectStatisticsFilter } from "~store/statistics/selectors";

const sankeyDataMap = {
  app: "constants.sessionCountSankey.app",
  desktop: "constants.sessionCountSankey.desktop",
  mobile: "constants.sessionCountSankey.mobile",
  bot: "constants.sessionCountSankey.bot", // Bot Only
  agent: "constants.sessionCountSankey.agent", //Agent Joined
  ticket: "constants.sessionCountSankey.ticket", // Ticket Opened
  successful: "constants.sessionCountSankey.successful", // Graded Successful
  unsuccessful: "constants.sessionCountSankey.unsuccessful", // Graded Unsuccessful
  notGraded: "constants.sessionCountSankey.notGraded", // Not Graded
};
const SessionCountSankeyStatistics = ({ hideBlanks }) => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectStatistics);
  const { t } = useTranslation();
  useEffect(() => {
    q(dispatch(getSessionCountSankeyStatistics(projectId, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate]);

  const chartStyle = {
    height: "50vh",
  };

  const links = [
    {
      source: t(sankeyDataMap.app),
      target: t(sankeyDataMap.ticket),
      value: statistics?.SESSION_COUNT_SANKEY_APP_TICKET,
    },
    {
      source: t(sankeyDataMap.app),
      target: t(sankeyDataMap.agent),
      value: statistics?.SESSION_COUNT_SANKEY_APP_AGENT,
    },
    {
      source: t(sankeyDataMap.app),
      target: t(sankeyDataMap.bot),
      value: statistics?.SESSION_COUNT_SANKEY_APP_BOT,
    },
    {
      source: t(sankeyDataMap.desktop),
      target: t(sankeyDataMap.ticket),
      value: statistics?.SESSION_COUNT_SANKEY_DESKTOP_TICKET,
    },
    {
      source: t(sankeyDataMap.desktop),
      target: t(sankeyDataMap.agent),
      value: statistics?.SESSION_COUNT_SANKEY_DESKTOP_AGENT,
    },
    {
      source: t(sankeyDataMap.desktop),
      target: t(sankeyDataMap.bot),
      value: statistics?.SESSION_COUNT_SANKEY_DESKTOP_BOT,
    },
    {
      source: t(sankeyDataMap.mobile),
      target: t(sankeyDataMap.ticket),
      value: statistics?.SESSION_COUNT_SANKEY_MOBILE_TICKET,
    },
    {
      source: t(sankeyDataMap.mobile),
      target: t(sankeyDataMap.agent),
      value: statistics?.SESSION_COUNT_SANKEY_MOBILE_AGENT,
    },
    {
      source: t(sankeyDataMap.mobile),
      target: t(sankeyDataMap.bot),
      value: statistics?.SESSION_COUNT_SANKEY_MOBILE_BOT,
    },
    {
      source: t(sankeyDataMap.agent),
      target: t(sankeyDataMap.successful),
      value:
        (statistics?.SESSION_COUNT_SANKEY_APP_AGENT_SUCCESSFUL || 0) +
        (statistics?.SESSION_COUNT_SANKEY_DESKTOP_AGENT_SUCCESSFUL || 0) +
        (statistics?.SESSION_COUNT_SANKEY_MOBILE_AGENT_SUCCESSFUL || 0),
    },
    {
      source: t(sankeyDataMap.agent),
      target: t(sankeyDataMap.unsuccessful),
      value:
        (statistics?.SESSION_COUNT_SANKEY_APP_AGENT_UNSUCCESSFUL || 0) +
        (statistics?.SESSION_COUNT_SANKEY_DESKTOP_AGENT_UNSUCCESSFUL || 0) +
        (statistics?.SESSION_COUNT_SANKEY_MOBILE_AGENT_UNSUCCESSFUL || 0),
    },
    {
      source: t(sankeyDataMap.agent),
      target: t(sankeyDataMap.notGraded),
      value:
        (statistics?.SESSION_COUNT_SANKEY_APP_AGENT_NOT_GRADED || 0) +
        (statistics?.SESSION_COUNT_SANKEY_DESKTOP_AGENT_NOT_GRADED || 0) +
        (statistics?.SESSION_COUNT_SANKEY_MOBILE_AGENT_NOT_GRADED || 0),
    },
    {
      source: t(sankeyDataMap.bot),
      target: t(sankeyDataMap.successful),
      value:
        (statistics?.SESSION_COUNT_SANKEY_APP_BOT_SUCCESSFUL || 0) +
        (statistics?.SESSION_COUNT_SANKEY_DESKTOP_BOT_SUCCESSFUL || 0) +
        (statistics?.SESSION_COUNT_SANKEY_MOBILE_BOT_SUCCESSFUL || 0),
    },
    {
      source: t(sankeyDataMap.bot),
      target: t(sankeyDataMap.unsuccessful),
      value:
        (statistics?.SESSION_COUNT_SANKEY_APP_BOT_UNSUCCESSFUL || 0) +
        (statistics?.SESSION_COUNT_SANKEY_DESKTOP_BOT_UNSUCCESSFUL || 0) +
        (statistics?.SESSION_COUNT_SANKEY_MOBILE_BOT_UNSUCCESSFUL || 0),
    },
    {
      source: t(sankeyDataMap.bot),
      target: t(sankeyDataMap.notGraded),
      value:
        (statistics?.SESSION_COUNT_SANKEY_APP_BOT_NOT_GRADED || 0) +
        (statistics?.SESSION_COUNT_SANKEY_DESKTOP_BOT_NOT_GRADED || 0) +
        (statistics?.SESSION_COUNT_SANKEY_MOBILE_BOT_NOT_GRADED || 0),
    },
  ];

  const data = [
    {
      name: t(sankeyDataMap.app),
    },
    {
      name: t(sankeyDataMap.desktop),
    },
    {
      name: t(sankeyDataMap.mobile),
    },
    {
      name: t(sankeyDataMap.bot),
      itemStyle: {
        color: chartTheme.theme.color[0],
      },
    },
    {
      name: t(sankeyDataMap.agent),
      itemStyle: {
        color: chartTheme.theme.color[6],
      },
    },
    {
      name: t(sankeyDataMap.ticket),
      itemStyle: {
        color: chartTheme.theme.color[2],
      },
    },
    {
      name: t(sankeyDataMap.successful),
      itemStyle: {
        color: chartTheme.theme.color[5],
      },
    },
    {
      name: t(sankeyDataMap.unsuccessful),
      itemStyle: {
        color: chartTheme.theme.color[3],
      },
    },
    {
      name: t(sankeyDataMap.notGraded),
    },
  ];

  const filteredLinks = hideBlanks ? links.filter((link) => link.value > 0) : links;
  const filteredData = hideBlanks
    ? data.filter((item) => filteredLinks.some((link) => link.source === item.name || link.target === item.name))
    : data;
  const options = {
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        if (params.dataType === "node") {
          return `${params.name} (${params.value})`;
        } else {
          return `${params.data.source} (${params.data.value}) → ${params.data.target}`;
        }
      },
    },
    label: {
      show: true,
      position: "inside",
      formatter: "{b} ({c})",
    },
    series: {
      type: "sankey",
      layout: "none",
      draggable: false,
      emphasis: {
        focus: "adjacency",
      },
      data: filteredData,
      links: filteredLinks,
    },
  };
  return (
    <PRChart
      loading={loading}
      option={options}
      style={chartStyle}
      title={t("dashboard.sessionStatistics.sessionCountSankey")}
    />
  );
};

const SessionCountPieChartStatistics = ({ hideBlanks }) => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectStatistics);
  const { t } = useTranslation();
  useEffect(() => {
    q(dispatch(getSessionCountPieChartStatistics(projectId, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate]);

  const chartStyle = {
    height: "70vh",
  };

  const filteredData = [
    { value: statistics?.SESSION_COUNT_APP, name: t("dashboard.sessionStatistics.app") },
    { value: statistics?.SESSION_COUNT_MOBILE, name: t("dashboard.sessionStatistics.mobile") },
    { value: statistics?.SESSION_COUNT_DESKTOP, name: t("dashboard.sessionStatistics.desktop") },
  ].filter((item) => (hideBlanks ? !!item.value : true));

  const filteredData2 = [
    { value: statistics?.SESSION_COUNT_APP_WHATSAPP, name: t("dashboard.sessionStatistics.appWhatsapp") },
    { value: statistics?.SESSION_COUNT_APP_TELEGRAM, name: t("dashboard.sessionStatistics.appTelegram") },
    { value: statistics?.SESSION_COUNT_APP_TEAMS, name: t("dashboard.sessionStatistics.appTeams") },
    { value: statistics?.SESSION_COUNT_MOBILE_ANDROID, name: t("dashboard.sessionStatistics.mobileAndroid") },
    { value: statistics?.SESSION_COUNT_MOBILE_IOS, name: t("dashboard.sessionStatistics.mobileIos") },
    { value: statistics?.SESSION_COUNT_MOBILE_OTHER, name: t("dashboard.sessionStatistics.mobileOther") },
    { value: statistics?.SESSION_COUNT_DESKTOP_WINDOWS, name: t("dashboard.sessionStatistics.desktopWindows") },
    { value: statistics?.SESSION_COUNT_DESKTOP_MAC, name: t("dashboard.sessionStatistics.desktopMac") },
    { value: statistics?.SESSION_COUNT_DESKTOP_OTHER, name: t("dashboard.sessionStatistics.desktopOther") },
  ].filter((item) => (hideBlanks ? !!item.value : true));

  const options = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      data: [...filteredData.map((item) => item.name), ...filteredData2.map((item) => item.name)],
    },
    series: [
      {
        name: t("dashboard.sessionStatistics.platforms"),
        type: "pie",
        selectedMode: "single",
        radius: [0, "30%"],
        label: {
          position: "inner",
          fontSize: 14,
        },
        labelLine: {
          show: false,
        },
        data: filteredData,
      },
      {
        name: t("dashboard.sessionStatistics.platforms"),
        type: "pie",
        radius: ["45%", "60%"],
        labelLine: {
          length: 30,
        },
        label: {
          position: "outside",
          alignTo: "edge",
          margin: 10,
          formatter: (params) => {
            return params.value === 0 ? "" : params.name;
          },
        },
        data: filteredData2,
      },
    ],
  };
  return (
    <PRChart
      loading={loading}
      option={options}
      style={chartStyle}
      title={t("dashboard.sessionStatistics.sessionCountPieChart")}
    />
  );
};
const StyledTab = styled(Tab)`
  font-weight: 600;
`;

const StyledGrid = styled(Grid)`
  align-items: center;
  display: flex;
  justify-content: center;
  align-self: center;
`;

const SessionStatistics = () => {
  const [tab, setTab] = useState(0);
  const [hideBlanks, setHideBlanks] = useState(true);
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const { t } = useTranslation();

  const handleHideBlanks = () => {
    setHideBlanks(!hideBlanks);
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleExcelExport = async () => {
    let fn;
    switch (tab) {
      case 0:
        fn = getSessionCountSankeyStatistics;
        break;
      case 1:
        fn = getSessionCountPieChartStatistics;
        break;
      default:
        break;
    }
    if (!fn) {
      AlertHelper.show(t("dashboard.sessionStatistics.noDataError"), "error");
      return;
    }

    // get the buffer from fn(), and then save it as a file
    const buffer = await dispatch(fn(projectId, filter.beginDate, filter.endDate, true));
    FileHelper.saveAs(buffer, "statistics", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
  };

  return (
    <PalContainer
      description={t("dashboard.sessionStatistics.description")}
      name={t("common.statistics")}
      parentName={t("dashboard.sessionStatistics")}
    >
      <Grid container>
        <Grid item xs={12}>
          <FilterCard />
        </Grid>
        <Grid item md={4} xs={12}>
          <AggregateCard
            icon={MdHistory}
            statisticKey="SESSION_COUNT"
            title={t("dashboard.sessionStatistics.totalSessions")}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <AggregateCard
            icon={MdLiveHelp}
            statisticKey="SESSION_QUESTION_COUNT"
            title={t("dashboard.sessionStatistics.totalQuestions")}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <AggregateCard
            icon={MdPeople}
            statisticKey="SESSION_AGENT_JOINED_COUNT"
            title={t("dashboard.sessionStatistics.totalAgentJoined")}
          />
        </Grid>
      </Grid>
      <PalPage collapse>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item md xs={10}>
                <Tabs value={tab} variant="scrollable" onChange={handleChangeTab}>
                  <StyledTab label={t("dashboard.sessionStatistics.sessionCountSankey")} />
                  <StyledTab label={t("dashboard.sessionStatistics.sessionCountPieChart")} />
                </Tabs>
              </Grid>
              <Grid
                item
                sx={{
                  pr: 1,
                  display: "flex",
                  alignItems: "center",
                  label: {
                    margin: 0,
                    padding: 0,
                  },
                }}
                xs="auto"
              >
                <FormControlLabel
                  checked={hideBlanks}
                  control={<Switch />}
                  label={t("dashboard.sessionStatistics.hideBlanks")}
                  onChange={handleHideBlanks}
                />
              </Grid>
              <StyledGrid item md={"auto"} xs={2}>
                <ExcelButton onClick={handleExcelExport} />
              </StyledGrid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {tab === 0 && <SessionCountSankeyStatistics hideBlanks={hideBlanks} />}
            {tab === 1 && <SessionCountPieChartStatistics hideBlanks={hideBlanks} />}
          </Grid>
        </Grid>
      </PalPage>
    </PalContainer>
  );
};

export default SessionStatistics;
