import { createRef, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { useDispatch } from "react-redux";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import { apiUrlGuide, guideTypeMap, tableFilterStorageKey } from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import { deleteGuide } from "~store/settings/guideDefinition/actions";

import "./style.scss";

export default function GuideDefinitionList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const tableRef = createRef();

  const columns = useMemo(() => {
    const handleDelete = (row) => async () => {
      if (!(await DialogHelper.showQuestionDelete())) return;
      await dispatch(deleteGuide(row.id));
      tableRef.current?.refresh();
    };
    return [
      {
        label: t("common.title"),
        key: "title",
      },
      {
        label: t("dashboard.guideDefinition.regex"),
        key: "test_regex",
      },
      {
        label: t("common.type"),
        key: "type",
        render: (row) => {
          return t(guideTypeMap[row.type]) || row.type;
        },
      },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        render: (row) => (
          <div className="d-flex justify-content-center">
            <PRButton
              outline
              color="primary"
              icon={MdEdit}
              link={`/settings/guide-definition/form/${row.id}`}
              size="sm"
              tooltipText={t("common.edit")}
            />
            <PRButton
              outline
              className="ms-1"
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText={t("common.delete")}
              onClick={handleDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [dispatch, tableRef, t]);

  const actions = useMemo(() => {
    return [
      {
        label: t("common.createNew"),
        icon: MdAdd,
        color: "success",
        link: "/settings/guide-definition/form",
      },
    ];
  }, [t]);

  const filters = useMemo(() => [], []);

  return (
    <PRContainer
      actions={actions}
      className=""
      description={t("dashboard.guideDefinition.description")}
      name={t("common.settings")}
      parentName={t("dashboard.guideDefinition")}
    >
      <PRTable
        ref={tableRef}
        columns={columns}
        filters={filters}
        storageKey={tableFilterStorageKey.guideDefinitionList}
        url={apiUrlGuide.get}
      />
    </PRContainer>
  );
}
