import ConstantHelper from "~helpers/ConstantHelper";

export const [intentRecordTriggerType, intentRecordTriggerTypeOptions, intentRecordTriggerTypeMap] =
  ConstantHelper.typify({
    PREDICTED: "Predicted",
    USER_SELECTED: "User Selected",
    BOT_TRIGGERED: "Bot Triggered",
    AGENT_TRIGGERED: "Agent Triggered",
    SUGGESTED_INTENT: "Suggested Intent",
    NONE_SELECTED: "None Selected",
    CHOICE_PROVIDED: "Choice Provided",
    CONFUSION: "Confusion",
    SUGGESTION_PROVIDED: "Suggestion Provided",
  });

export const [intentRecordActionType, intentRecordActionTypeOptions, intentRecordActionTypeMap] = ConstantHelper.typify(
  {
    NONE: "None",
    IGNORE: "Ignored",
    CHANGE_INTENT: "Added Sample Question to different Intent",
    CREATE_INTENT: "New Intent Created",
    ADD_SAMPLE_QUESTION: "Added Sample Question",
  }
);
