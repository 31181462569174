export default class SpeechHelper {
  _recognition = null;
  _listenerCallback = null;
  _autoStopTimeout = null;
  _autoStopMs = 1250;
  _lastResults = null;
  _isRunning = false;
  constructor() {
    try {
      const SpeechRecognition = window.SpeechRecognition || webkitSpeechRecognition;
      if (SpeechRecognition) {
        this._recognition = new SpeechRecognition();
        this._recognition.continuous = true;
        this._recognition.lang = "tr-TR";
        this._recognition.interimResults = true;
        this._recognition.maxAlternatives = 1;
        this._recognition.addEventListener("audioend", (e) => {
          this._onChangeListener("audioend", e);
        });

        this._recognition.addEventListener("audiostart", (e) => {
          this._onChangeListener("audiostart", e);
        });

        this._recognition.addEventListener("end", (e) => {
          this._onChangeListener("end", e);
          if (this._autoStopTimeout) {
            clearTimeout(this._autoStopTimeout);
          }
        });

        this._recognition.addEventListener("nomatch", (e) => {
          this._onChangeListener("nomatch", e);
        });

        this._recognition.addEventListener("soundend", (e) => {
          this._onChangeListener("soundend", e);
        });

        this._recognition.addEventListener("soundend", (e) => {
          this._onChangeListener("soundend", e);
        });

        this._recognition.addEventListener("soundstart", (e) => {
          this._onChangeListener("soundstart", e);
        });
        this._recognition.addEventListener("speechend", (e) => {
          this._onChangeListener("speechend", e);
        });

        this._recognition.addEventListener("speechstart", (e) => {
          this._onChangeListener("speechstart", e);
        });

        this._recognition.addEventListener("start", (e) => {
          this._onChangeListener("start", e);
        });

        this._recognition.addEventListener("error", (e) => {
          this._onChangeListener("error", e);
          console.log(e);
        });

        this._recognition.addEventListener("result", (e) => {
          this._onChangeListener("result", e);
          this._lastResults = e.results;
          this._createAutoStopTimeout();
        });
      }
    } catch (e) {
      console.log(e);
      this._recognition = null;
    }
  }

  _createAutoStopTimeout = () => {
    if (this._autoStopTimeout) {
      clearTimeout(this._autoStopTimeout);
    }
    this._autoStopTimeout = setTimeout(() => {
      this.stop();
      this._onChangeListener("end-result", this._lastResults);
    }, this._autoStopMs);
  };

  _onChangeListener = (type, e) => {
    if (typeof this._listenerCallback === "function") {
      this._listenerCallback(type, e);
    }
    if (type === "start") {
      this._isRunning = true;
    } else if (type === "end") {
      this._isRunning = false;
    }
  };
  /** @param {(type: "audioend"|"audiostart"|"end"|"nomatch"|"soundend"|"soundend"|"soundstart"|"speechend"|"speechstart"|"start"|"error"|"result"|"end-result", event:any)} callback */
  setListener = (callback) => {
    this._listenerCallback = callback;
  };

  start = () => {
    if (this._isRunning) return;
    this._recognition.start();
  };

  stop = () => {
    if (!this._isRunning) return;
    this._recognition.stop();
  };

  static isSpeechSupport = () => {
    try {
      const SpeechRecognition = window.SpeechRecognition || webkitSpeechRecognition;
      const instance = new SpeechRecognition();
      return !!instance;
    } catch {
      return false;
    }
  };
}
