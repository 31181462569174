export const SET_CHATBOT_STATISTICS = "@STATISTICS/SET_CHATBOT_STATISTICS";
export const SET_WEEKLY_SESSIONS = "@STATISTICS/SET_WEEKLY_SESSIONS";
export const SET_WEEKLY_TICKETS = "@STATISTICS/SET_WEEKLY_TICKETS";
export const SET_AGENT_STATISTICS = "@STATISTICS/SET_AGENT_STATISTICS";
export const SET_AGENT_TICKET_STATISTICS = "@STATISTICS/SET_AGENT_TICKET_STATISTICS";
export const SET_TICKET_STATISTICS = "@STATISTICS/SET_TICKET_STATISTICS";
export const SET_GENERIC_STATISTICS = "@STATISTICS/SET_GENERIC_STATISTICS";
export const SET_STATISTICS = "@STATISTICS/SET_STATISTICS";
export const SET_STATISTICS_FILTER = "@STATISTICS/SET_STATISTICS_FILTER";
export const SET_AGENT_SPESIFIC_STATISTICS = "@STATISTICS/SET_AGENT_SPESIFIC_STATISTICS";
export const SET_AGENT_FILTER_IDS = "@STATISTICS/SET_AGENT_FILTER_IDS";
