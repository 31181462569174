import TokenHelper from "~helpers/TokenHelper.js";

import SocketWorker from "../socket.worker.js";

const webSocketStatusCodes = {
  1000: "NORMAL_CLOSURE",
  1001: "GOING_AWAY",
  1002: "PROTOCOL_ERROR",
  1003: "UNSUPPORTED_DATA",
  1004: "RESERVED",
  1005: "NO_STATUS_RCVD",
  1006: "ABNORMAL_CLOSURE",
  1007: "INVALID_FRAME_PAYLOAD_DATA",
  1008: "POLICY_VIOLATION",
  1009: "MESSAGE_TOO_BIG",
  1010: "MANDATORY_EXT",
  1011: "INTERNAL_ERROR",
  1012: "SERVICE_RESTART",
  1013: "TRY_AGAIN_LATER",
  1014: "BAD_GATEWAY",
  1015: "TLS_HANDSHAKE",
};

class WorkerSocket {
  /** @param {"customer" | "agent"} type */
  constructor(type) {
    this.readyState = null;

    this.worker = new SocketWorker();
    window.pr_config._workers = window.pr_config._workers || [];
    window.pr_config._workers.push(this.worker);

    this.nextMessageId = 1;
    this.messagePromises = {};

    this.worker.addEventListener("message", (event) => {
      const { type, data, readyState, id } = event.data;
      const safeType = typeof type === "string" ? type.replace(/[^a-zA-Z0-9_-]/g, "").replace(/\n|\r/g, "") : "";

      switch (safeType) {
        case "OPEN":
          if (this.onopen) {
            this.onopen();
          }
          break;

        case "MESSAGE":
          if (this.onmessage) {
            this.onmessage({ data });
          }
          break;

        case "ERROR":
          if (this.onerror) {
            this.onerror({ data });
          }
          break;
        case "WS-CLOSE":
        case "CLOSE":
          const errorKey = webSocketStatusCodes[data] || "UNKNOWN";
          console.log(`SocketWorker closed done(${safeType}):`, data, errorKey);
          if (this.onclose) {
            this.onclose({ code: data, codeKey: errorKey });
          }
          break;
        case "READY_STATE":
          this.readyState = readyState;
          break;
        case "READY_STATE_RESPONSE":
          if (this.messagePromises[id]) {
            this.messagePromises[id]?.(readyState);
            delete this.messagePromises[id];
          }
          break;
        default:
          console.error(`Unknown event type: ${safeType}`);
      }
    });

    const alias = TokenHelper.getChatbotAlias();
    this.worker.postMessage({ type: "CONNECT", urlType: type, alias });
  }

  send(data) {
    this.worker.postMessage({ type: "SEND", payload: data });
  }

  close() {
    this.worker.postMessage({ type: "CLOSE" });
    window.pr_config._workers = window.pr_config._workers.filter((w) => w !== this.worker);
  }
  getReadyState() {
    const messageId = this.nextMessageId++;
    this.worker.postMessage({ type: "GET_READY_STATE", id: messageId });
    return new Promise((resolve, reject) => {
      const timeoutDuration = 5000;

      const timeoutId = setTimeout(() => {
        reject(new Error("Webworker readyState timeout exceeded"));
        this.messagePromises[messageId] = null;
      }, timeoutDuration);

      this.messagePromises[messageId] = (response) => {
        clearTimeout(timeoutId);
        resolve(response);
      };
    });
  }

  onopen() {}
  onmessage() {}
  onerror() {}
  onclose() {}
  terminate() {
    // this.worker?.terminate();
    this.worker.postMessage({ type: "CLOSE" });
    window.pr_config._workers = window.pr_config._workers.filter((w) => w !== this.worker);
  }
}

export default WorkerSocket;
