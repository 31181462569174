import PRSwitch from "~components/Generic/PRSwitch";

import GuideDefinitionEdit from "./GuideDefinitionEdit";
import GuideDefinitionList from "./GuideDefinitionList";

export default function GuideDefinition() {
  const routes = [
    { path: "/settings/guide-definition/form/:id?", component: GuideDefinitionEdit },
    { path: "/settings/guide-definition", component: GuideDefinitionList },
  ];
  return <PRSwitch routes={routes} />;
}
