import ConstantHelper from "~helpers/ConstantHelper";

export const [moduleVersionStatusType, moduleVersionStatusTypeOptions, moduleVersionStatusTypeMap] =
  ConstantHelper.typify({
    0: "constants.moduleVersionStatusType.draft",
    1: "constants.moduleVersionStatusType.checking",
    2: "constants.moduleVersionStatusType.ready",
    3: "constants.moduleVersionStatusType.failed",
  });

export const [moduleVersionErrorPolicyType, moduleVersionErrorPolicyTypeOptions, moduleVersionErrorPolicyTypeMap] =
  ConstantHelper.typify({
    /** @type {"Report Error"} */
    0: "constants.moduleVersionErrorPolicyType.reportError",
    /** @type {"Ignore"} */
    1: "constants.moduleVersionErrorPolicyType.ignore",
    /** @type {"Stop"} */
    2: "constants.moduleVersionErrorPolicyType.stop",
  });
