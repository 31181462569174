import PRSwitch from "~components/Generic/PRSwitch";
import WhatsappTemplateMessageEdit from "~pages/ContactCenter/LiveChat/WhatsappTemplateMessages/WhatsappTemplateMessageEdit";
import WhatsappTemplateMessageList from "~pages/ContactCenter/LiveChat/WhatsappTemplateMessages/WhatsappTemplateMessageList";

import ActionsEdit from "./ActionsEdit";
import ActionsList from "./ActionsList";

function WhatsappTemplateMessagesAction() {
  const routes = [
    {
      path: "/organization/action-templates/whatsapp-template-messages/form/:id?",
      component: WhatsappTemplateMessageEdit,
      componentProps: {
        organization: true,
      },
    },
    {
      path: "/organization/action-templates/whatsapp-template-messages/",
      component: WhatsappTemplateMessageList,
      componentProps: {
        organization: true,
      },
    },
  ];
  return <PRSwitch routes={routes} />;
}

export default function Actions() {
  const routes = [
    {
      path: "/organization/action-templates/whatsapp-template-messages/",
      component: WhatsappTemplateMessagesAction,
    },
    { path: "/organization/action-templates/form/:id?", component: ActionsEdit },
    { path: "/organization/action-templates", component: ActionsList },
  ];

  return <PRSwitch routes={routes} />;
}
