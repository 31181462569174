import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

import {
  CK_TOKEN,
  LS_TOKEN,
  LS_TOKEN_REFRESH,
  LS_WEBCHAT_TOKEN,
  LS_WEBCHAT_TOKEN_EXPIRE,
  LS_WEBCHAT_UUID,
} from "~constants";

import DateHelper from "./DateHelper";
import StorageHelper from "./StorageHelper";
import Utils from "./Utils";

export default class TokenHelper {
  static getChatbotAlias = () => {
    const aliasPathPrefix = `${window.location.origin}/webchat/`;
    const isAliasMode = window.location.href.startsWith(aliasPathPrefix);
    if (!isAliasMode) return null;

    let aliasKey;
    const url = new URL(window.location.href);
    const urlChunks = url.pathname.split("/");
    const webchatChunkIndex = urlChunks.findIndex((chunk) => chunk === "webchat");
    aliasKey = urlChunks[webchatChunkIndex + 1];

    return aliasKey;
  };
  static getChatbotSessionIfAvailable = async () => {
    const palmateTkn = TokenHelper.getWebchatProjectToken();

    const alias = TokenHelper.getChatbotAlias();
    const token = await StorageHelper.get(LS_WEBCHAT_TOKEN.format(palmateTkn || alias));
    const tokenExpire = await StorageHelper.get(LS_WEBCHAT_TOKEN_EXPIRE.format(palmateTkn || alias));
    let uuid = await StorageHelper.get(LS_WEBCHAT_UUID.format(palmateTkn || alias));
    if (!uuid) {
      //generate guid
      uuid = Utils.getId();
      await StorageHelper.set(LS_WEBCHAT_UUID.format(palmateTkn || alias), uuid);
    }
    if (token && tokenExpire && DateHelper.getDateTime(tokenExpire).isAfter(DateHelper.getDateTime())) {
      return { token, tokenExpire, uuid };
    } else {
      await StorageHelper.remove(LS_WEBCHAT_TOKEN.format(palmateTkn || alias));
      await StorageHelper.remove(LS_WEBCHAT_TOKEN_EXPIRE.format(palmateTkn || alias));
    }
    return null;
  };
  static getJwtIfValid = async () => {
    const jwtToken = await StorageHelper.get(LS_TOKEN);
    const jwtRefreshToken = await StorageHelper.get(LS_TOKEN_REFRESH);
    try {
      const decoded = jwtDecode(jwtRefreshToken || jwtToken);
      if (decoded.exp && decoded.exp > Date.now() / 1000) {
        return jwtToken;
      }
    } catch {}
    await StorageHelper.remove(LS_TOKEN);
    Cookies.remove(CK_TOKEN);
    return null;
  };
  static getWebchatProjectToken = () => {
    const params = new URLSearchParams(window?.location?.search || {});
    const token = params.get("token");
    const alias = TokenHelper.getChatbotAlias();
    return token || alias;
  };
  static removeJwt = async () => {
    await StorageHelper.remove(LS_TOKEN);
    Cookies.remove(CK_TOKEN);
  };
}
