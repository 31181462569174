// eslint-disable-next-line unused-imports/no-unused-imports
import { useFormik, FormikHelpers } from "formik";

//To prevent missing onSubmit warning
const dummyOnSubmit = () => {};
/**
 * UseFormik with Extended features
 *
 * @template T
 * @param {{ onSubmit: (values: T, formik: FormikHelpers<T>, e: Event) => void; initialValues: T }} props
 * @returns {import("formik").FormikProps<T> & { handleSubmit: (e: Event) => void }}
 */
export default function useFormikEx({ onSubmit, initialValues, ...props }) {
  const formik = useFormik({ initialValues, onSubmit: dummyOnSubmit, ...props });

  const handleSubmit = (e) => {
    formik.submitForm();
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        onSubmit?.(formik.values, formik, e);
      }
    });
  };

  return { ...formik, handleSubmit };
}
