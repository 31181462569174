import { createContext, useContext } from "react";

export const PROnClickChoiceContext = createContext();
export const PRMessages = createContext();
export const PRDropzone = createContext();
export const PRTyping = createContext();
export const PRChatOptions = createContext();
export const PRRef = createContext();
export const PRAnimation = createContext();
export const PRPreviewMode = createContext();
export const PRScrollState = createContext();
export const PRRecognition = createContext();

export function PRContextProvider({
  children,
  valueOnClickChoice,
  valueMessages,
  valueDropzone,
  valueTyping,
  valueChatOptions,
  valueRef,
  valueAnimation,
  valuePreviewMode,
  valueScrollState,
  valueRecognition,
}) {
  return (
    <PROnClickChoiceContext.Provider value={valueOnClickChoice}>
      <PRMessages.Provider value={valueMessages}>
        <PRDropzone.Provider value={valueDropzone}>
          <PRTyping.Provider value={valueTyping}>
            <PRChatOptions.Provider value={valueChatOptions}>
              <PRRef.Provider value={valueRef}>
                <PRAnimation.Provider value={valueAnimation}>
                  <PRPreviewMode.Provider value={valuePreviewMode}>
                    <PRScrollState.Provider value={valueScrollState}>
                      <PRRecognition.Provider value={valueRecognition}>
                        {/* */}
                        {children}
                        {/* */}
                      </PRRecognition.Provider>
                    </PRScrollState.Provider>
                  </PRPreviewMode.Provider>
                </PRAnimation.Provider>
              </PRRef.Provider>
            </PRChatOptions.Provider>
          </PRTyping.Provider>
        </PRDropzone.Provider>
      </PRMessages.Provider>
    </PROnClickChoiceContext.Provider>
  );
}

export const usePROnClickChoiceContext = () => useContext(PROnClickChoiceContext);
export const usePRMessagesContext = () => useContext(PRMessages);
export const usePRDropzoneContext = () => useContext(PRDropzone);
export const usePRTypingContext = () => useContext(PRTyping);
export const usePRChatOptionsContext = () => useContext(PRChatOptions);
export const usePRRefContext = () => useContext(PRRef);
export const usePRAnimationContext = () => useContext(PRAnimation);
export const usePRPreviewModeContext = () => useContext(PRPreviewMode);
export const usePRScrollStateContext = () => useContext(PRScrollState);
export const usePRRecognitionContext = () => useContext(PRRecognition);
