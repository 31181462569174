import { useEffect, useMemo, useState } from "react";

import { useMonaco } from "@monaco-editor/react";
import { cloneDeep, isEqual } from "lodash";

function createCompletionItems(structure) {
  let completionItems = [];

  function processNode(node, parent) {
    let item = {
      ...node,
    };

    if (parent) {
      item = {
        ...item,
        detail: `${parent.label}.${node.label}`,
        insertText: `${parent.label}.${node.insertText}`,
      };
    }

    completionItems.push(item);

    if (node.children && node.children.length) {
      node.children.forEach((childNode) => processNode(childNode, node));
    }
  }

  structure.forEach((node) => processNode(node, null));

  return completionItems;
}

// const structure = [
//   {
//     label: "session",
//     insertText: "session",
//     detail: "session",
//     documentation: "Manage chatbot session context and state with `session`.",
//     kind: 5,
//     children: [
//       {
//         label: "copy",
//         insertText: "copy()",
//         detail: "def read(self: list) -> list",
//         documentation: "Return a shallow read of the list.",
//         kind: 1,
//       },
//       {
//         label: "copy",
//         insertText: "copy(${1:key}, ${2|'CUSTOMER','AGENT','BOT'|})",
//         detail: "def read(self: set, key: str) -> set",
//         documentation: "Return a shallow read of the set.",
//         kind: 1,
//         parameters: [
//           {
//             label: "self",
//             documentation: "The instance to copy.",
//             detail: "self: set",
//           },
//           {
//             label: "key",
//             documentation: "The key to be copied.",
//             detail: "key: str",
//           },
//         ],
//       },
//       {
//         label: "copy",
//         insertText: "copy(${1:start}, ${2:stop}, ${3:step})",
//         detail: "def read(self: set, key: str, value: any) -> set",
//         documentation: "Return a shallow read of the set.",
//         kind: 1,
//         parameters: [
//           {
//             label: "self",
//             documentation: "The instance to copy.",
//             detail: "self: set",
//           },
//           {
//             label: "key",
//             documentation: "The key to be copied.",
//             detail: "key: str",
//           },
//           {
//             label: "value",
//             documentation: "The value to be copied.",
//             detail: "value: any",
//           },
//         ],
//       },
//     ],
//   },
//   {
//     label: "utils",
//     insertText: "utils",
//     detail: "def read(self: set) -> set",
//     documentation: "Main utils",
//     kind: 5,
//     children: [
//       {
//         label: "modifier",
//         insertText: "modifier",
//         detail: "modifier",
//         documentation: "modifier utils",
//         kind: 5,
//         children: [
//           {
//             label: "run",
//             insertText: "run()",
//             detail: "def run(self: set) -> set",
//             documentation: "Return a run",
//             kind: 1,
//           },
//         ],
//       },
//     ],
//   },
// ];

// const completionItemMap = completionItems.reduce((map, item) => {
//   map[item.label] = item;
//   return map;
// }, {});

function getWordAtPositionCustom(lineStr, column) {
  const regExp = /[^\s"]+/g;
  let match;

  while ((match = regExp.exec(lineStr))) {
    const start = match.index;
    const end = start + match[0].length;

    if (column >= start && column <= end) {
      return match[0];
    }
  }

  return "";
}
let globalDispose = [];

/**
 * @param {import("@monaco-editor/react").Monaco} editorBase
 * @param {{
 *   list: any[];
 * }} options
 */
export default function useMonacoCompletion(editorBase, options = {}) {
  const editor = editorBase?.getEditorType() === "vs.editor.IDiffEditor" ? editorBase.getModifiedEditor() : editorBase;
  const monaco = useMonaco();
  const [completionItems, setCompletionItems] = useState([]);

  const editorLanguage = editor?.getModel()?.getLanguageId();

  const completionItemDefinition = useMemo(() => options?.list || {}, [options.list]);

  useEffect(() => {
    const items = options?.list?.length ? createCompletionItems(options.list) : [];
    if (isEqual(items, completionItems)) return;
    setCompletionItems(items);
  }, [options.list, completionItems]);

  useEffect(() => {
    if (!editor || !completionItems.length) return;
    const c = [];
    if (globalDispose.length) {
      globalDispose.forEach((d) => d.dispose());
      globalDispose = [];
    }
    c.push(
      monaco.languages.registerHoverProvider(editorLanguage, {
        provideHover: async function (model, position, token) {
          const lineContent = model.getLineContent(position.lineNumber);
          let word = model.getWordAtPosition(position);

          // #region detect double quote and get value
          const lineTextBeforePosition = lineContent.substring(0, position.column - 1);
          const lineTextAfterPosition = lineContent.substring(position.column - 1);

          const regex = /("[^"]*")/;
          const matchBefore = lineTextBeforePosition.match(regex);
          const matchAfter = lineTextAfterPosition.match(regex);

          let doubleQuoteValue;
          if (matchBefore && matchAfter) {
            const startOffset = position.column - matchBefore[1].length;
            const endOffset = position.column + matchAfter[1].length;
            doubleQuoteValue = model.getValueInRange({
              startLineNumber: position.lineNumber,
              startColumn: startOffset,
              endLineNumber: position.lineNumber,
              endColumn: endOffset,
            });
          }
          // #endregion

          if (editorLanguage === "plaintext") {
            word = {
              word: getWordAtPositionCustom(lineContent, position.column),
            };
          }

          const item = completionItems.find((item) => item.label === word?.word);
          let documentation = item?.documentation;
          let detail = item?.detail;
          let targetItem = item;
          if (word) {
            const wordStart = lineContent?.indexOf(word?.word);
            const wordEnd = lineContent?.length;
            const fullWord = lineContent.slice(wordStart, wordEnd);
            const isIndexer = lineContent?.includes(`["${word?.word}"]`);
            if (isIndexer) {
              const arrayObjMatch = new RegExp(`(?<array>[^\\s]+?)(?=\\["${word?.word}"\\])`);
              const arrayObj = lineContent.match(arrayObjMatch)?.groups?.array;
              const arrayObjItem = completionItems.find((item) => item.label === arrayObj);
              const filteredChildren = arrayObjItem?.children?.filter((item) => item.label === word?.word);
              if (filteredChildren?.length) {
                documentation = filteredChildren[0]?.documentation;
                detail = filteredChildren[0]?.detail;
                targetItem = filteredChildren[0];
              }
            } else if (fullWord) {
              const parameterCount = fullWord.split(",").length;
              for (const item of completionItems) {
                const paramCount = item.parameters?.length || 1;
                if (item.label === word?.word) {
                  if (item.children) {
                    documentation = item.documentation;
                    detail = item.detail;
                    targetItem = item;
                    break;
                  } else if (paramCount === parameterCount) {
                    documentation = item.documentation;
                    detail = item.detail + `(${item.parameters?.map((param) => param?.label).join(", ") || ""})`;
                    targetItem = item;
                    break;
                  }
                }
              }
            }
          }

          if (documentation && detail) {
            if (targetItem?.onHover) {
              const hover = await targetItem?.onHover(targetItem);
              if (hover) {
                documentation = hover.documentation;
                detail = hover.detail;
              }
            }
            return {
              contents: [
                {
                  value: `\`\`\`${editorLanguage}\n${detail}\n\`\`\`\n---\n\n${
                    documentation?.value ?? documentation
                  }\n`,
                  supportHtml: true,
                  isTrusted: true,
                  uris: targetItem?.documentation?.uris,
                },
              ],
            };
          }
        },
      })
    );

    c.push(
      monaco.languages.registerSignatureHelpProvider(editorLanguage, {
        signatureHelpTriggerCharacters: ["(", ","],
        provideSignatureHelp: function (model, position, token, context) {
          let lineContent = model.getLineContent(position.lineNumber);
          let functionMatch = lineContent.slice(0, position.column).match(/\b(\w+)\s*\([^)]*\)$/);
          if (functionMatch) {
            let functionName = functionMatch[1];
            let matchingItems = [];
            completionItems.forEach((item) => {
              // if (item.label === functionName) {
              //   matchingItems.push(item);
              // }
              if (item.children) {
                item.children.forEach((child) => {
                  if (child.label === functionName) {
                    matchingItems.push(child);
                  }
                });
              }
            });

            if (matchingItems.length > 0) {
              let signatures = matchingItems.map((matchingItem) => ({
                label: matchingItem.detail,
                documentation: matchingItem.documentation,
                parameters: matchingItem.parameters
                  ? matchingItem.parameters.map((param) => ({ label: param.label, documentation: param.documentation }))
                  : [],
              }));

              // Get the count of commas before the current position to estimate the index of the active parameter
              let parametersInFunctionCall = lineContent.slice(0, position.column).match(/\(([^)]*)\)/);
              let parameterCount = parametersInFunctionCall ? parametersInFunctionCall[1].split(",").length : 0;

              // Find the matching signature by comparing the parameter count
              let activeSignature = signatures.findIndex((signature) => signature.parameters.length === parameterCount);
              activeSignature = activeSignature === -1 ? 0 : activeSignature;

              // Since the cursor is after a comma or opening bracket, the next parameter should be active
              let activeParameter = Math.min(parameterCount, signatures[activeSignature].parameters.length - 1);

              return {
                value: { signatures: signatures, activeSignature: activeSignature, activeParameter: activeParameter },
                dispose: () => {},
              };
            }
          }

          return null;
        },
      })
    );

    c.push(
      monaco.languages.registerCompletionItemProvider(editorLanguage, {
        triggerCharacters: ["$"],
        provideCompletionItems: function (model, position, context, token) {
          const cloneCompletionItemDefinition = cloneDeep(completionItemDefinition);

          const currentLine = model.getLineContent(position.lineNumber);

          const textUntilPosition = model.getValueInRange({
            startLineNumber: 1,
            startColumn: 1,
            endLineNumber: position.lineNumber,
            endColumn: position.column,
          });
          const textFromPosition = model.getValueInRange({
            startLineNumber: position.lineNumber,
            startColumn: position.column,
            endLineNumber: position.lineNumber,
            endColumn: model.getLineMaxColumn(position.lineNumber),
          });

          const previousCharacter = currentLine.slice(-1);
          const isTriggerContinueAvailable =
            ![".", "[", '"'].includes(previousCharacter) &&
            (textFromPosition.trim() === "" || textFromPosition.match(/^(\s|\.)/));
          const isWhitespace = previousCharacter.match(/\s/);
          const lastCharGroup = currentLine.match(/(?<word>[^\s]+)$/);
          const lastCharGroupMatch = lastCharGroup?.groups?.word;
          const isIndexer = lastCharGroupMatch?.includes(`[`);

          if (
            isTriggerContinueAvailable &&
            !isIndexer &&
            (textUntilPosition.trim() === "" || isWhitespace?.index > -1 || lastCharGroupMatch?.length)
          ) {
            let suggestions = cloneCompletionItemDefinition;
            if (lastCharGroupMatch) {
              const range = {
                startLineNumber: position.lineNumber,
                endLineNumber: position.lineNumber,
                startColumn: lastCharGroup.index + 1,
                endColumn: lastCharGroup.index + lastCharGroupMatch.length + 1,
              };
              suggestions = suggestions.map((item) => ({
                ...item,
                range,
              }));

              suggestions = suggestions.filter((item) =>
                (item.filterText || item.label).toLowerCase().includes(lastCharGroupMatch.toLowerCase())
              );
            }
            return { suggestions };
          }
          const match = textUntilPosition.match(/\b(\w+)\.$/);
          if (match) {
            const parentName = match[1];
            const parentItem = completionItems.find((item) => item.label == parentName);
            if (parentItem && parentItem.children) {
              return {
                suggestions: parentItem.children.map((child) => ({
                  ...child,
                  insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                })),
              };
            }
          }

          //normalize array indexers to dot notation
          const normalizedTextUntilPosition = textUntilPosition.replace(/\[/g, ".[");
          let textChunks = normalizedTextUntilPosition.split(".").map((chunk) => chunk.trim());

          const filterRecursive = (items, chunks) => {
            const [currentChunk, ...restChunks] = chunks;
            const cleanChunk = currentChunk.replace(/\[/g, "").replace(/\]/g, "").replace(/"/g, "");
            const currentItem = items.find((item) => item.label === cleanChunk);
            if (currentItem && currentItem.children && restChunks.length) {
              return filterRecursive(currentItem.children, restChunks);
            }
            return items.filter((item) => item.label.startsWith(cleanChunk));
          };
          const filteredStructure = filterRecursive(cloneCompletionItemDefinition, textChunks);

          if (filteredStructure?.length) {
            const lastChunkLength = textChunks?.[textChunks.length - 1]?.length;

            const newRange = {
              startLineNumber: position.lineNumber,
              endLineNumber: position.lineNumber,
              startColumn: position.column - lastChunkLength,
              endColumn: position.column,
            };
            let suggestions = filteredStructure.map((item) => ({
              ...item,
              insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              range: newRange,
            }));
            return {
              suggestions,
            };
          }
          return { suggestions: [] };
        },
        resolveCompletionItem: function (item) {
          return item;
        },
      })
    );

    globalDispose.push(...c);
    return () => {
      for (const instance of c) {
        instance?.dispose();
        globalDispose = globalDispose.filter((item) => item !== instance);
      }
    };
  }, [editor, monaco, completionItems, completionItemDefinition, editorLanguage]);
}
