import React, { useState } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "reactstrap";

import styled from "@emotion/styled";
import Logout from "@mui/icons-material/Logout";
import Settings from "@mui/icons-material/Settings";
import { Avatar, Box, Divider, ListItemIcon, Menu, MenuItem } from "@mui/material";

import user1 from "~assets/images/profile.png";
import useBootstrapBreakpoint from "~common/hooks/useBootstrapBreakpoint";
import PRFormGroup from "~components/Generic/PRFormGroup";
import PRInput from "~components/Generic/PRInput";
import PRLink from "~components/Generic/PRLink";
import PalIconButton from "~components/mui/PalIconButton";
import PalTooltip from "~components/mui/PalTooltip";
import { userRole, userRoleMap } from "~constants";
import ProfileModal from "~pages/Profile/profile.modal";
import { wsStatusUpdate } from "~store/socket/livechat/actions";
import { selectAgentInfo } from "~store/socket/livechat/selectors";
import { selectCurrentProject, selectUserInfo } from "~store/user/selectors";

import RippleBadge from "../RippleBadge";

const StyledMyProfile = styled.div`
  @media (max-width: 576px) {
    button {
      padding: 0 !important;
    }
  }
  .form-check-input {
    background-color: var(--bs-danger);
    border-color: var(--bs-danger);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e") !important;

    &:checked {
      background-color: var(--bs-success);
      border-color: var(--bs-success);
    }
  }

  .profile-item {
    border: solid 1px #ddd;
    background-color: #fff;
    border-radius: 50%;
    width: 54px;
    height: 54px;
    position: relative;
    .profile-inline {
      width: 32px;
      height: 32px;
      img {
        width: 32px;
        height: 32px;
      }
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &-wide {
      width: 54px;
      height: 54px;
    }
    &-sm {
      border: none;
      width: 32px;
      height: 32px;
    }
  }
`;

const MyProfile = () => {
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const userInfo = useSelector(selectUserInfo);
  const agentInfo = useSelector(selectAgentInfo);
  const isSm = useBootstrapBreakpoint("sm");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const hasLivechatAccess = [userRole.admin, userRole.callCenterAgent].some((role) =>
    currentProject?.permissions?.includes(role)
  );
  const handleAgentAvailable = (e) => {
    const value = e.target.checked;
    e?.stopPropagation?.();
    dispatch(wsStatusUpdate(value));
    // dispatch(
    //   updateAgentInfo({
    //     ...agentInfo,
    //     status: value ? "AV" : "UN",
    //   })
    // );
  };
  const isAgentAvailable = agentInfo?.status === "AV";

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (key) => () => {
    if (key === "livechat") {
      handleAgentAvailable({ target: { checked: !isAgentAvailable } });
      return;
    }
    if (key === "profile") {
      ProfileModal.show();
    }
    setAnchorEl(null);
  };
  return (
    <StyledMyProfile>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        {!isSm && <span className="me-2 fs-6"> {`${userInfo?.firstname} ${userInfo?.lastname}`}</span>}
        <PalTooltip title={t("layout.profile.name")}>
          <span>
            <div
              className={classNames("profile-item", {
                "profile-item-sm": isSm,
              })}
            >
              <PalIconButton aria-haspopup="true" className="profile-inline" size="small" onClick={handleClick}>
                <RippleBadge
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  color={isAgentAvailable ? "success" : "error"}
                  noAnimation={!isAgentAvailable}
                  overlap="circular"
                  variant="dot"
                >
                  <Avatar alt="Profile Image" src={user1} sx={{ width: 32, height: 32 }} />
                </RippleBadge>
              </PalIconButton>
            </div>
          </span>
        </PalTooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        open={open}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              "&.MuiPaper-root": {
                borderRadius: "14px",
                boxShadow: "2px 2px 24px 0 rgba(0, 0, 0, 0.24)",
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        onClose={handleClose()}
      >
        <Box alignItems="center" display="flex" flexDirection="column" padding={2}>
          <div>
            <Avatar
              alt="Header Avatar"
              src={user1}
              sx={{
                "&.MuiAvatar-root": {
                  margin: 0,
                  width: 54,
                  height: 54,
                },
              }}
            />
          </div>
          <div className="mt-1 fw-semibold font-size-16"> {userInfo?.username}</div>
          <div className="mt-1 font-size-12">{`${userInfo?.firstname} ${userInfo?.lastname}`}</div>
          <div className="my-2 font-size-10 text-muted">{userInfo?.email}</div>

          {currentProject &&
            currentProject?.permissions?.map((p) => {
              return (
                <Badge key={p} pill className="mb-1" color="primary">
                  <span className="font-size-10">{t(userRoleMap[p])}</span>
                </Badge>
              );
            })}
          {userInfo?.is_superuser && (
            <Badge pill className="mb-1" color="danger">
              <span className="font-size-10">Super Admin</span>
            </Badge>
          )}
        </Box>
        <Divider />
        {hasLivechatAccess && (
          <MenuItem
            sx={{
              ".form-switch": {
                paddingLeft: "36px",
                paddingTop: 0,
                paddingBottom: 0,
                margin: 0,
                addingBottom: 0,
                height: 24,
              },
            }}
            onClick={handleClose("livechat")}
          >
            <PRFormGroup switch>
              <PRInput checked={isAgentAvailable} type="switch" onChange={handleAgentAvailable} />
              {t("layout.profile.liveChat")}
            </PRFormGroup>
          </MenuItem>
        )}
        <span>{hasLivechatAccess && <Divider />}</span>
        <MenuItem onClick={handleClose("profile")}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          {t("layout.profile.settings")}
        </MenuItem>
        <MenuItem onClick={handleClose()}>
          <ListItemIcon>
            <Logout className="text-danger" fontSize="small" />
          </ListItemIcon>
          <PRLink noPrefix className="text-secondary-700" scope="" to="/logout">
            {t("layout.profile.logout")}
          </PRLink>
        </MenuItem>
      </Menu>
    </StyledMyProfile>
  );
};

export default MyProfile;
