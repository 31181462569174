import { useState } from "react";

import { PalButton, PalDatePicker, PalTypography } from "@palamar/fe-library";

import styled from "@emotion/styled";
import { Grid } from "@mui/material";

import DateHelper from "~helpers/DateHelper";

const StyledFilterCard = styled.div`
  /* padding: 20px; */
  /* margin: 10px; */
  background-color: transparent; //${({ theme }) => theme.palette.grey[100]};
  border-radius: ${({ theme }) => theme.spacing(1)};
`;

const StyledFilterButton = styled(PalButton)`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up("md")} {
    width: auto;
  }
`;

function FilterButton({ children, ...rest }) {
  return (
    <StyledFilterButton {...rest} size="medium">
      {children}
    </StyledFilterButton>
  );
}

const StyledPalDatePicker = styled(PalDatePicker)`
  border-top-left-radius: auto;
  ${({ theme }) => theme.breakpoints.down("md")} {
    &.MuiTextField-root {
      width: 100%;
    }
  }
`;

const LeaderboardFilterCard = ({
  intentFilter = false,
  onDateChange = ({ beginDate, endDate }) => {},
  onViewChange = () => {},
}) => {
  const [selected, setSelected] = useState("month");
  const [beginDate, setBeginDate] = useState(DateHelper.getDateTime().subtract(1, "month"));
  const [endDate, setEndDate] = useState(DateHelper.getDateTime());
  const [viewType, setViewType] = useState(1);

  const handleDayClick = () => {
    setSelected("day");
    onDateChange({
      beginDate: DateHelper.getDateTime().subtract(1, "day").format("YYYY-MM-DD"),
      endDate: DateHelper.getDateTime().format("YYYY-MM-DD"),
    });
  };
  const handleWeekClick = () => {
    setSelected("week");
    onDateChange({
      beginDate: DateHelper.getDateTime().subtract(1, "week").format("YYYY-MM-DD"),
      endDate: DateHelper.getDateTime().format("YYYY-MM-DD"),
    });
  };
  const handleMonthClick = () => {
    setSelected("month");
    onDateChange({
      beginDate: DateHelper.getDateTime().subtract(1, "month").format("YYYY-MM-DD"),
      endDate: DateHelper.getDateTime().format("YYYY-MM-DD"),
    });
  };
  const handleCustomClick = () => {
    setSelected("custom");
  };

  const handleApplyClick = () => {
    onDateChange({
      beginDate: beginDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
    });
  };

  // const handleGetOptionDisabled = (option) => {
  //   return agents.length >= 3 && !agents.includes(option);
  // };

  const handleBeginDateChange = (date) => {
    const beginOfMonth = DateHelper.getDateTime(date);
    setBeginDate(beginOfMonth);
  };

  const handleEndDateChange = (date) => {
    const endOfMonth = DateHelper.getDateTime(date);
    setEndDate(endOfMonth);
  };

  const handleTagClick = () => {
    setViewType(1);
    onViewChange(1);
  };

  const handleIntentClick = () => {
    setViewType(2);
    onViewChange(2);
  };

  return (
    <StyledFilterCard>
      {/* <Typography variant="h6">Filter</Typography> */}
      <Grid container spacing={2}>
        <Grid item md="auto" sm={6} xs={12}>
          <FilterButton fullWidth variant={selected === "day" ? "contained" : "outlined"} onClick={handleDayClick}>
            Yesterday
          </FilterButton>
        </Grid>
        <Grid item md="auto" sm={6} xs={12}>
          <FilterButton fullWidth variant={selected === "week" ? "contained" : "outlined"} onClick={handleWeekClick}>
            Last Week
          </FilterButton>
        </Grid>
        <Grid item md="auto" sm={6} xs={12}>
          <FilterButton fullWidth variant={selected === "month" ? "contained" : "outlined"} onClick={handleMonthClick}>
            Last Month
          </FilterButton>
        </Grid>
        <Grid item md="auto" sm={6} xs={12}>
          <FilterButton
            fullWidth
            variant={selected === "custom" ? "contained" : "outlined"}
            onClick={handleCustomClick}
          >
            Custom
          </FilterButton>
        </Grid>
      </Grid>
      {selected === "custom" && (
        <Grid container pt={2} spacing={2}>
          <Grid item sm md="auto" xs="12">
            <StyledPalDatePicker
              disabled={selected !== "custom"}
              label="Begin Date"
              openTo="month"
              value={beginDate}
              onChange={handleBeginDateChange}
            />
          </Grid>
          <Grid item sm md="auto" xs="12">
            <StyledPalDatePicker
              disabled={selected !== "custom"}
              label="End Date"
              openTo="month"
              value={endDate}
              onChange={handleEndDateChange}
            />
          </Grid>
          <Grid item md="auto" sm="auto" xs="12">
            <FilterButton sx={{ minWidth: 80, height: "100%" }} variant="text" onClick={handleApplyClick}>
              Apply
            </FilterButton>
          </Grid>
        </Grid>
      )}
      {intentFilter && (
        <>
          <PalTypography pt={2}>View Type</PalTypography>
          <Grid container pt={1} spacing={2}>
            <Grid item sm md="auto" xs="12">
              <FilterButton fullWidth variant={viewType === 1 ? "contained" : "outlined"} onClick={handleTagClick}>
                Tag
              </FilterButton>
            </Grid>
            <Grid item sm md="auto" xs="12">
              <FilterButton fullWidth variant={viewType === 2 ? "contained" : "outlined"} onClick={handleIntentClick}>
                Intent
              </FilterButton>
            </Grid>
          </Grid>
        </>
      )}
    </StyledFilterCard>
  );
};

export default LeaderboardFilterCard;
