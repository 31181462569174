import { useCallback, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import Pagination from "react-js-pagination";

import useQueryParams from "~common/hooks/useQueryParams";
import HistoryHelper from "~helpers/HistoryHelper";
import Utils from "~helpers/Utils";

import "./style.scss";

function PRPagination({ value: valuePropStr, onChange, pageSize, total, range = 8, memoizeAsQuery = true, className }) {
  const { t } = useTranslation();
  const { p: pageQueryStr, ...restQuery } = useQueryParams();
  const { pageQuery, pageProp } = useMemo(() => {
    return {
      pageQuery: Number(pageQueryStr),
      pageProp: Number(valuePropStr),
    };
  }, [pageQueryStr, valuePropStr]);

  const [initialized, setInitialized] = useState(false);

  const handleChange = useCallback(
    (page) => {
      onChange?.(page);
    },
    [onChange]
  );

  useEffect(() => {
    if (pageQuery > 0) {
      onChange?.(pageQuery);
    }
    setInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!initialized) return;
    if (!pageQueryStr || (pageProp && pageQuery !== pageProp)) {
      HistoryHelper.replace({ pathname: window.location.pathname, search: Utils.qs({ ...restQuery, p: pageProp }) });
    }
  }, [pageProp, pageQuery, initialized, pageQueryStr, restQuery]);

  const moreSpacing1 = useMemo(() => {
    return pageProp > 999 - range;
  }, [pageProp, range]);

  return (
    <div
      className={classNames("pr-pagination", className, {
        "with-more-spacing-1": moreSpacing1,
      })}
    >
      <Pagination
        activeClass="active"
        activeLinkClass="active-link"
        activePage={pageProp}
        disabledClass="disabled"
        firstPageText={<MdKeyboardDoubleArrowLeft size={24} title={t("component.prPagination.first")} />}
        innerClass="pagination"
        itemClass="pr-page-item"
        itemClassFirst="pr-page-item-first"
        itemClassLast="pr-page-item-last"
        itemClassNext="pr-page-item-next"
        itemClassPrev="pr-page-item-prev"
        itemsCountPerPage={pageSize}
        lastPageText={<MdKeyboardDoubleArrowRight size={24} title={t("component.prPagination.last")} />}
        linkClass="pr-page-link"
        linkClassFirst="pr-page-link-first"
        linkClassLast="pr-page-link-last"
        linkClassNext="pr-page-link-next"
        linkClassPrev="pr-page-link-prev"
        nextPageText={<MdKeyboardArrowRight size={24} title={t("component.prPagination.next")} />}
        pageRangeDisplayed={range}
        prevPageText={<MdKeyboardArrowLeft size={24} title={t("component.prPagination.prev")} />}
        totalItemsCount={total}
        onChange={handleChange}
      />
    </div>
  );
}

export default PRPagination;
