import { createRef, useCallback, useMemo } from "react";

import { PalDateHelper, PalTypography } from "@palamar/fe-library";
import { withCardon } from "cardon";
import { useTranslation } from "react-i18next";
import { MdAdd, MdCode, MdDelete, MdEdit, MdPreview, MdRemoveRedEye } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "reactstrap";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRModal from "~components/Generic/PRModal";
import PRTable from "~components/Generic/PRTable";
import LowCodeEditorModal from "~components/LowCodeEditor";
import PalTooltip from "~components/mui/PalTooltip";
import {
  popupSettingsMapper,
  apiUrlStatisticGenerator,
  lowCodeModuleType,
  statisticGeneratorStatusMap,
} from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import { deleteStatisticGenerator } from "~store/statisticGenerator/actions";
import { selectCurrentProject } from "~store/user/selectors";

export default function StatisticGeneratorList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableRef = createRef();

  const currentProject = useSelector(selectCurrentProject);

  const handleShowReason = (reason) => async () => {
    await FailReasonModal.show({ reason });
  };

  const columns = useMemo(() => {
    const handleClickDelete = (item) => async () => {
      if (!(await DialogHelper.showQuestionYesNo(t("common.delete"), t("dashboard.statisticGenerator.deleteQuestion"))))
        return;
      await dispatch(deleteStatisticGenerator(currentProject.id, item.id));

      tableRef.current?.refresh();
    };

    const handleClickEditGenerator = (item) => async () => {
      await LowCodeEditorModal.show({
        moduleType: lowCodeModuleType.STATISTICS_MODULES,
        statisticsReportGeneratorId: item.id,
        hideContext: true,
        defaultSelectedProcedureName: "main",
        showSidebarAction: true,
      });
      tableRef.current?.refresh();
    };
    return [
      {
        label: t("common.name"),
        key: "name",
      },
      {
        label: t("dashboard.statisticGenerator.reportCount"),
        key: "report_count",
      },
      {
        label: t("dashboard.statisticGenerator.modules"),
        key: "modules",
        render: (row) => {
          return (
            <>
              {row?.modules?.map((item) => {
                return (
                  <Badge key={item?.id} className="badge-soft-secondary me-1">
                    {item?.name}
                  </Badge>
                );
              })}
            </>
          );
        },
      },
      {
        label: t("dashboard.statisticGenerator.lastRunTime"),
        key: "last_run_time",
        render: (row) =>
          row.last_run_time ? (
            <PalTooltip title={PalDateHelper.getDateTimeLocal(row.last_run_time).fromNow()}>
              {PalDateHelper.getDateTimeLocal(row.last_run_time).format("LLL")}
            </PalTooltip>
          ) : (
            "-"
          ),
      },
      {
        label: t("common.status"),
        key: "status",
        render: (row) =>
          statisticGeneratorStatusMap[row.status] ? t(statisticGeneratorStatusMap[row.status]) : row.status || "-",
      },
      {
        label: t("dashboard.statisticGenerator.failReason"),
        key: "fail_reason",
        render: (row) =>
          row.fail_reason ? (
            <PRButton
              outline
              color="primary"
              icon={MdRemoveRedEye}
              size="sm"
              tooltipText={t("dashboard.statisticGenerator.showFailReasonTooltip")}
              onClick={handleShowReason(row.fail_reason)}
            />
          ) : (
            "-"
          ),
      },
      {
        label: t("common.actions"),
        key: "actions",
        fixed: "right",
        actions: true,
        render: (row) => (
          <div className="d-flex justify-content-center gap-1">
            <PRButton
              outline
              color="primary"
              icon={MdPreview}
              link={`/stats/statistic-generator/reports/${row.id}`}
              size="sm"
              tooltipText={t("dashboard.statisticGenerator.showReportsTooltip")}
            />
            <PRButton
              outline
              color="primary"
              icon={MdCode}
              size="sm"
              tooltipText={t("dashboard.statisticGenerator.editorTooltip")}
              onClick={handleClickEditGenerator(row)}
            />
            <PRButton
              outline
              color="primary"
              icon={MdEdit}
              link={`/stats/statistic-generator/form/${row.id}`}
              size="sm"
              tooltipText={t("dashboard.statisticGenerator.editGeneratorTooltip")}
            />
            <PRButton
              outline
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText={t("common.delete")}
              onClick={handleClickDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [dispatch, currentProject?.id, tableRef, t]);

  const actions = useMemo(
    () => [
      {
        label: t("common.create"),
        icon: MdAdd,
        color: "success",
        link: "/stats/statistic-generator/form",
      },
    ],
    [t]
  );
  const parentName = [
    {
      label: t("dashboard.statisticGenerator"),
    },
  ];
  const handleOnLoad = useCallback((results) => {
    return results.map((item) => {
      return {
        ...item,
        settings: {
          ...item.settings,
          ...popupSettingsMapper(item.settings),
        },
      };
    });
  }, []);
  return (
    <PRContainer
      actions={actions}
      description={t("dashboard.statisticGenerator.description")}
      name={t("common.statistics")}
      parentName={parentName}
    >
      <div className="mt-2">
        <PRTable
          ref={tableRef}
          columns={columns}
          url={apiUrlStatisticGenerator.get.format(currentProject?.id)}
          onLoad={handleOnLoad}
        />
      </div>
    </PRContainer>
  );
}

export const FailReasonModal = withCardon(
  function FailReasonModal({ get, reason }) {
    const { t } = useTranslation();
    return (
      <PRModal submitText="" title={t("dashboard.statisticGenerator.failReason")} onClose={get(false)}>
        <PalTypography>{reason}</PalTypography>
      </PRModal>
    );
  },
  {
    destroyOnHide: true,
  }
);
