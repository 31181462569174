import { PalIconButton, PalTooltip } from "@palamar/fe-library";
import { useTranslation } from "react-i18next";
import { SiMicrosoftexcel } from "react-icons/si";

const ExcelButton = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <PalTooltip title={t("common.exportToExcel")}>
      <PalIconButton color="primary" onClick={onClick}>
        <SiMicrosoftexcel />
      </PalIconButton>
    </PalTooltip>
  );
};

export default ExcelButton;
