import { useEffect, useMemo, useRef } from "react";

import { withCardon } from "cardon";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

import PRModal from "~components/Generic/PRModal";
import PRTable from "~components/Generic/PRTable";
import { apiUrlOrganization, organizationVisibilityType, tableFilterStorageKey } from "~constants";
import { CustomerListCellRenderer } from "~pages/Organization/Customer/CustomerList";
import { getMemberFieldFormatList } from "~store/organization/actions";
import { selectMemberFieldFormat } from "~store/organization/selectors";
import { selectCurrentProject } from "~store/user/selectors";

function AnnouncementVersionMembersModalContent({ get, announcementId, versionId }) {
  const dispatch = useDispatch();

  const tableRef = useRef(null);

  const currentProject = useSelector(selectCurrentProject);

  const memberFieldFormat = useSelector(selectMemberFieldFormat);
  const memberDataFields = memberFieldFormat?.member_data_fields;

  useEffect(() => {
    dispatch(getMemberFieldFormatList(currentProject.id));
  }, [dispatch, currentProject.id]);

  // const serverSavedMemberDataFields = useMemo(() => {
  //   return memberDataFields?.filter((item) => item.id);
  // }, [memberDataFields]);

  const columns = useMemo(() => {
    if (!memberDataFields) return [];

    const fields = [];
    const nonMemberOnlyFields = memberDataFields.filter(
      (item) => item.visibility_type !== organizationVisibilityType.MEMBER_ONLY
    );
    const memberOnlyField = nonMemberOnlyFields.filter(
      (item) => item.visibility_type === organizationVisibilityType.MEMBER_ONLY
    );
    const safeInformationFields = nonMemberOnlyFields.map((item) => {
      const label = (
        <span
          className={classNames({
            "fw-semibold": item.identifier,
          })}
        >
          {item.display_name || item.name}
        </span>
      );

      return {
        label: label,
        key: `safe_information.${item.name}`,
        order: item.order,
        render: (obj, index, value) => (
          <CustomerListCellRenderer cellKey={item.name} memberDataFields={memberDataFields} value={value} />
        ),
      };
    });
    fields.push(...safeInformationFields);

    if (memberOnlyField?.length) {
      const memberOnlyFieldRows = memberOnlyField.map((item) => {
        const label = (
          <span
            className={classNames({
              "fw-semibold": item.identifier,
            })}
          >
            {item.display_name || item.name}
          </span>
        );

        return {
          label: label,
          key: `member_only_information.${item.name}`,
          order: item.order,
          render: (obj, index, value) => (
            <CustomerListCellRenderer cellKey={item.name} memberDataFields={memberOnlyField} value={value} />
          ),
        };
      });
      fields.push(...memberOnlyFieldRows);
    }

    fields.unshift(
      ...[
        {
          label: "#",
          key: "id",
          order: -3,
        },
        {
          label: "Announcement Status",
          key: "announcement_details.announcement_status",
          noWrap: true,
          order: -2,
        },
        {
          label: "Fail Reason",
          key: "announcement_details.fail_reason",
          noWrap: true,
          order: -1,
        },
      ]
    );

    return fields;
  }, [memberDataFields]);

  return (
    <PRModal size="xl" submitText="" title="Announcement Members" onClose={get(false)}>
      <Row className="g-3">
        <Col xs={12}>
          <PRTable
            ref={tableRef}
            columns={columns}
            storageKey={tableFilterStorageKey.announcementVersionMembers}
            url={apiUrlOrganization.getAnnouncementVersionMembers.format(currentProject.id, announcementId, versionId)}
          />
        </Col>
      </Row>
    </PRModal>
  );
}
const AnnouncementVersionMembersModal = withCardon(AnnouncementVersionMembersModalContent, { destroyOnHide: true });
export default AnnouncementVersionMembersModal;
