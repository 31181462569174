import PRSwitch from "~components/Generic/PRSwitch";

import AnnouncementEdit from "./AnnouncementEdit";
import AnnouncementList from "./AnnouncementList";

export default function Announcement() {
  const routes = [
    { path: "/organization/announcement/form/:id?", component: AnnouncementEdit },
    { path: "/organization/announcement", component: AnnouncementList },
  ];
  return <PRSwitch routes={routes} />;
}
