import { useEffect, useState } from "react";

import { t } from "i18next";
import { cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Alert, Col, Row } from "reactstrap";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRPage from "~components/Generic/PRPage";
import { organizationAnnouncementFilterType, organizationFilterType } from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import { createOrUpdateProcedure, getProcedure } from "~store/dialogComponents/scenarioManager/actions";
import { createOrUpdateFilter, getFilter, getFilterList, setFilter } from "~store/organization/actions";
import { selectFilter } from "~store/organization/selectors";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

import FilterRule from "./FilterRule";

import "./style.scss";
import { getIntellisenseData } from "~store/lowcode/actions";

export const validateFilterObject = (
  filterData = {},
  lowCodeData,
  options = {
    skipNameValidation: false,
  }
) => {
  const errors = [];
  const rootName = filterData?.name;
  const rootNameNormalize = rootName?.normalizeAccent();
  const traverseInFilter = (filterItem, depth, index, filterType, isRoot) => {
    if (!isRoot) {
      // Set child filter name prevent name is empty error
      filterItem.name = `${rootNameNormalize}|${filterType}_${depth}_${index}`;
    }
    if (filterItem.filter_type === organizationFilterType.COMPARISON) {
      if (!filterItem.member_data_field?.id) {
        errors.push(t("utils.validateFilterObject.mustField"));
      }
      if (!filterItem.value) {
        errors.push(t("utils.validateFilterObject.mustValue"));
      }
    } else if (filterItem.children?.length) {
      filterItem.children.forEach((child, childIndex) => {
        traverseInFilter(child, depth + 1, childIndex, filterItem.filter_type, false);
      });
    }
  };

  if (!rootName && !options?.skipNameValidation) {
    errors.push(t("component.formik.required").format(t("utils.validateFilterObject.filterName")));
  }

  if (filterData.filter_type === organizationAnnouncementFilterType.ID_LIST && !filterData.member_id_list?.length) {
    errors.push(t("component.formik.required").format(t("utils.validateFilterObject.customerList")));
  } else if (
    filterData.filter_type === organizationAnnouncementFilterType.LOW_CODE &&
    !lowCodeData?.implementation?.length
  ) {
    errors.push(t("utils.validateFilterObject.lowCodeError"));
  } else {
    traverseInFilter(filterData, 0, 0, "", true);
  }

  if (errors.length) {
    DialogHelper.showValidate(errors);
    return false;
  }
  return true;
};

export default function FilterEdit() {
  const { t } = useTranslation();
  const { id } = useParams();
  const filter = useSelector(selectFilter);

  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);

  const [lowCodeData, setLowCodeData] = useState(null);
  const dispatch = useDispatch();
  const parentName = [
    {
      label: t("dashboard.filterTemplates"),
      url: "/organization/filter-templates",
    },
    {
      label: id ? t("dashboard.filterTemplates.editFilter") : t("dashboard.filterTemplates.createFilter"),
    },
  ];
  const handleChangeFilter = (value) => {
    dispatch(setFilter(value));
  };

  const handleClickCancelFilter = () => {
    HistoryHelper.goBack("/organization/filter-templates", { scope: "dashboard" });
  };
  const handleClickSaveFilter = async () => {
    const filterData = cloneDeep(filter);
    if (!validateFilterObject(filterData, lowCodeData)) {
      return;
    }

    if (filterData?.filter_type === organizationAnnouncementFilterType.LOW_CODE) {
      const response = await dispatch(createOrUpdateProcedure(currentProject.id, currentBot.id, lowCodeData));
      filterData.low_code_module = response.id;
    }

    filterData.is_template = true;
    await dispatch(createOrUpdateFilter(filterData));
    HistoryHelper.goBack("/organization/filter-templates", { scope: "dashboard" });
    dispatch(getFilterList(currentProject.id));
  };

  useEffect(() => {
    if (id) {
      dispatch(getFilter(id));
    }
    return () => {
      dispatch(setFilter(null));
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (!filter?.low_code_module) return;
    dispatch(getProcedure(currentProject.id, currentBot.id, filter?.low_code_module)).then((res) => {
      setLowCodeData(res);
    });
  }, [dispatch, currentProject.id, filter?.low_code_module, currentBot.id]);

  useEffect(() => {
    dispatch(getIntellisenseData(currentProject.id));
  }, [dispatch, currentProject.id]);

  return (
    <PRContainer className="pr-data-format" name={t("common.organization")} parentName={parentName}>
      <PRPage title={t("common.filter")}>
        {filter?.id && !filter?.is_template && (
          <Alert className="mb-3" color="info">
            {t("dashboard.filterTemplates.readModeAlert")}
          </Alert>
        )}
        <div disabled={filter?.id && !filter?.is_template}>
          <FilterRule
            filter={filter}
            lowCodeData={lowCodeData}
            onChange={handleChangeFilter}
            onChangeLowCode={setLowCodeData}
          />
        </div>

        <Row className="g-0 justify-content-end mt-2">
          <Col md="auto">
            <PRButton outline className="" onClick={handleClickCancelFilter}>
              {t("common.cancel")}
            </PRButton>
            <PRButton className="ms-2" disabled={filter?.id && !filter?.is_template} onClick={handleClickSaveFilter}>
              {t("common.save")}
            </PRButton>
          </Col>
        </Row>
      </PRPage>
    </PRContainer>
  );
}
