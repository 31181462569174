import { safeProduce as produce } from "~helpers/immer";

import * as actionTypes from "./actionTypes";

export const initState = Object.freeze({
  intellisenseData: [],
});

const lowcode = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.SET_INTELLISENSE_DATA:
        draft.intellisenseData = action.payload;
        break;
    }
  });
export default lowcode;
