export default class ConstantHelper {
  /**
   * Generates type, typeOptions and typeMap from an object
   *
   * @example
   *   ```ts
   *   const [type, typeOptions, typeMap] = ConstantHelper.typify({
   *    key1: "Key 1 Display Name",
   *    key2: "Key 2 Display Name"
   *   });
   *
   *
   *   console.log(type) // {key1: "key1", key2: "key2"}
   *   console.log(typeOptions) // [{label: "Key 1 Display Name", value: "key1"}, {label: "Key 2 Display Name", value: "key2"}]
   *   console.log(typeMap) // {key1: "Key 1 Display Name", key2: "Key 2 Display Name"}
   *   ```;
   *
   * @param obj Type of object to be converted to a type.\
   *   Values should be add like`{"key": "Key Display Name"}`
   * @returns
   */
  public static typify<T extends string, Z extends string, K extends { [key in T]: Z }>(obj: K) {
    const typeMap: { [key in keyof K]: K[key] } = {} as any;
    const type: { [key in keyof K]: key } = {} as any;
    const typeOptions: { label: K[keyof K]; value: keyof K }[] = [];

    for (const [key, value] of Object.entries(obj)) {
      typeMap[key] = value;
      type[key] = key;
      typeOptions.push({ label: value, value: key } as { label: K[keyof K]; value: keyof K });
    }
    return [type, typeOptions, typeMap] as const;
  }
}
