import { useEffect, useState } from "react";

import { PalTypography } from "@palamar/fe-library";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import styled from "@emotion/styled";
import { Info } from "@mui/icons-material";
import { Box, Grid, Tab, Tabs } from "@mui/material";

import useLoading from "~common/hooks/useLoading";
import PRChart from "~components/Generic/PRChart";
import chartTheme from "~components/Generic/PRChart/theme.json";
import PalContainer from "~components/mui/PalContainer";
import PalPage from "~components/mui/PalPage";
import ExcelButton from "~components/Statistics/ExcelButton";
import FilterCard from "~components/Statistics/FilterCard";
import AlertHelper from "~helpers/AlertHelper";
import FileHelper from "~helpers/FileHelper";
import {
  getChatbotIntentReviewStatistics,
  getChatbotLikeDislikeStatistics,
  getChatbotQuestionCountStatistics,
  getChatbotSessionCountStatistics,
  getChatbotSessionRateStatistics,
} from "~store/statistics/actions";
import { selectStatistics, selectStatisticsFilter } from "~store/statistics/selectors";

const chartStyle = {
  height: "600px",
  maxHeight: "50vh",
};

const chartTooltip = {
  trigger: "axis",
  formatter: function (params) {
    const date = new Date(params[0].data[0]);
    const formattedDate = date.toLocaleDateString();
    let tooltipText = `${formattedDate}<br/>`;
    params.forEach((item) => {
      tooltipText += `${item.marker} ${item.seriesName}: ${item.data[1]}<br/>`;
    });
    return tooltipText;
  },
};
const ChatbotQuestionCountStatistics = () => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectStatistics);
  useEffect(() => {
    q(dispatch(getChatbotQuestionCountStatistics(projectId, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate]);

  const options = {
    tooltip: chartTooltip,
    xAxis: {
      type: "time",
      min: filter.beginDate, // Start date
      max: filter.endDate, // End date
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: t("dashboard.chatbotStatistics.questionCount"),
        data: Object.entries(statistics?.CHATBOT_QUESTION_COUNT || {}),
        type: "line",
      },
    ],
  };
  return <PRChart notMerge loading={loading} option={options} style={chartStyle} />;
};
const ChatbotSessionCountStatistics = () => {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectStatistics);
  useEffect(() => {
    q(dispatch(getChatbotSessionCountStatistics(projectId, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate]);

  const options = {
    color: [chartTheme.theme.color[0], chartTheme.theme.color[6], chartTheme.theme.color[2]],
    tooltip: chartTooltip,
    xAxis: {
      type: "time",
      min: filter.beginDate, // Start date
      max: filter.endDate, // End date
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: Object.entries(statistics?.CHATBOT_SESSION_COUNT_BOT_ONLY || {}),
        type: "bar",
        stack: "total",
        name: t("dashboard.chatbotStatistics.botOnly"),
      },
      {
        data: Object.entries(statistics?.CHATBOT_SESSION_COUNT_AGENT || {}),
        type: "bar",
        stack: "total",
        name: t("dashboard.chatbotStatistics.agentJoined"),
      },
      {
        data: Object.entries(statistics?.CHATBOT_SESSION_COUNT_TICKET || {}),
        type: "bar",
        stack: "total",
        name: t("dashboard.chatbotStatistics.ticketOpened"),
      },
    ],
  };
  return <PRChart notMerge loading={loading} option={options} style={chartStyle} />;
};
const ChatbotSessionRateStatistics = () => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectStatistics);
  useEffect(() => {
    q(dispatch(getChatbotSessionRateStatistics(projectId, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate]);

  const options = {
    color: [
      chartTheme.theme.color[3],
      chartTheme.theme.color[6],
      chartTheme.theme.color[2],
      chartTheme.theme.color[9],
      chartTheme.theme.color[5],
    ],
    xAxis: {
      type: "time",
      min: filter.beginDate, // Start date
      max: filter.endDate, // End date
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: Object.entries(statistics?.CHATBOT_SESSION_RATE_1 || {}),
        type: "bar",
        stack: "total",
        name: "1",
      },
      {
        data: Object.entries(statistics?.CHATBOT_SESSION_RATE_2 || {}),
        type: "bar",
        stack: "total",
        name: "2",
      },
      {
        data: Object.entries(statistics?.CHATBOT_SESSION_RATE_3 || {}),
        type: "bar",
        stack: "total",
        name: "3",
      },
      {
        data: Object.entries(statistics?.CHATBOT_SESSION_RATE_4 || {}),
        type: "bar",
        stack: "total",
        name: "4",
      },
      {
        data: Object.entries(statistics?.CHATBOT_SESSION_RATE_5 || {}),
        type: "bar",
        stack: "total",
        name: "5",
      },
    ],
  };
  return <PRChart notMerge loading={loading} option={options} style={chartStyle} />;
};

const ChatbotLikeDislikeStatistics = () => {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectStatistics);
  useEffect(() => {
    q(dispatch(getChatbotLikeDislikeStatistics(projectId, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate]);

  const options = {
    color: [
      //
      chartTheme.theme.color[3],
      chartTheme.theme.color[5],
    ],
    tooltip: chartTooltip,
    xAxis: {
      type: "time",
      min: filter.beginDate, // Start date
      max: filter.endDate, // End date
    },
    yAxis: {
      type: "value",
    },
    legend: {
      show: true,
      data: ["Like", "Dislike"],
    },
    series: [
      {
        data: Object.entries(statistics?.CHATBOT_DISLIKE_COUNT || {}),
        type: "bar",
        stack: "total",
        name: t("common.dislike"),
      },
      {
        data: Object.entries(statistics?.CHATBOT_LIKE_COUNT || {}),
        type: "bar",
        stack: "total",
        name: t("common.like"),
      },
    ],
  };
  return <PRChart notMerge loading={loading} option={options} style={chartStyle} />;
};

const StyledGrid = styled(Grid)`
  align-items: center;
  display: flex;
  justify-content: center;
  align-self: center;
`;

const ChatbotIntentReviewStatistics = () => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectStatistics);

  useEffect(() => {
    q(dispatch(getChatbotIntentReviewStatistics(projectId, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate]);

  const options = {
    tooltip: chartTooltip,
    xAxis: {
      type: "time",
      min: filter.beginDate, // Start date
      max: filter.endDate, // End date
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: Object.entries(statistics?.CHATBOT_INTENT_REVIEW_REQUIRED || {}),
        type: "bar",
        stack: "total",
        name: t("dashboard.chatbotStatistics.intentReviewRequired"),
      },
      {
        data: Object.entries(statistics?.CHATBOT_INTENT_REVIEWED || {}),
        type: "bar",
        stack: "total",
        name: t("dashboard.chatbotStatistics.intentReviewed"),
      },
    ],
  };
  return <PRChart notMerge loading={loading} option={options} style={chartStyle} />;
};

const StyledTab = styled(Tab)`
  font-weight: 600;
`;

const ChatbotStatistics = () => {
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { projectId } = useParams();
  const filter = useSelector(selectStatisticsFilter);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleExcelExport = async () => {
    let fn;
    switch (tab) {
      case 0:
        fn = getChatbotQuestionCountStatistics;
        break;
      case 1:
        fn = getChatbotSessionCountStatistics;
        break;
      case 2:
        fn = getChatbotSessionRateStatistics;
        break;
      case 3:
        fn = getChatbotLikeDislikeStatistics;
        break;
      case 4:
        break;
    }
    if (!fn) {
      AlertHelper.show(t("dashboard.chatbotStatistics.noDataError"), "error");
      return;
    }

    // get the buffer from fn(), and then save it as a file
    const buffer = await dispatch(fn(projectId, filter.beginDate, filter.endDate, true));
    FileHelper.saveAs(buffer, "statistics", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
  };

  return (
    <PalContainer
      description={t("dashboard.chatbotStatistics.description")}
      name={t("common.statistics")}
      parentName={t("dashboard.chatbotStatistics")}
    >
      <Grid container>
        <Grid item xs={12}>
          <FilterCard />
        </Grid>
      </Grid>
      <PalPage collapse>
        <Grid container rowSpacing={2}>
          <Grid item md xs={10}>
            <Tabs value={tab} variant="scrollable" onChange={handleChangeTab}>
              <StyledTab label={t("dashboard.chatbotStatistics.questionCount")} />
              <StyledTab label={t("dashboard.chatbotStatistics.sessionCount")} />
              <StyledTab label={t("dashboard.chatbotStatistics.sessionRate")} />
              <StyledTab label={t("dashboard.chatbotStatistics.likeDislikeCount")} />
              <StyledTab label={t("dashboard.chatbotStatistics.intentReviewStatus")} />
            </Tabs>
          </Grid>
          <StyledGrid item md={"auto"} xs={2}>
            <ExcelButton onClick={handleExcelExport} />
          </StyledGrid>
          <Grid item xs={12}>
            {tab === 0 && <ChatbotQuestionCountStatistics />}
            {tab === 1 && <ChatbotSessionCountStatistics />}
            {tab === 2 && <ChatbotSessionRateStatistics />}
            {tab === 3 && <ChatbotLikeDislikeStatistics />}
            {tab === 4 && (
              <Box alignItems="center" display="flex" flexDirection="column" height="600px" justifyContent="center">
                <Box
                  sx={{
                    svg: {
                      fontSize: "60px",
                    },
                  }}
                >
                  <Info />
                </Box>
                <PalTypography variant="h6">{t("dashboard.chatbotStatistics.noPageInfo")}</PalTypography>
              </Box>
            )}
            {/* {tab === 4 && <ChatbotIntentReviewStatistics />} */}
          </Grid>
        </Grid>
      </PalPage>
    </PalContainer>
  );
};
export default ChatbotStatistics;
