import PRSwitch from "~components/Generic/PRSwitch";

import FilterEdit from "./FilterEdit";
import FilterList from "./FilterList";

export default function Filter() {
  const routes = [
    { path: "/organization/filter-templates/form/:id?", component: FilterEdit },
    { path: "/organization/filter-templates", component: FilterList },
  ];

  return <PRSwitch routes={routes} />;
}
