import { cloneDeep } from "lodash";

import { apiUrlOrganization } from "~constants";
import DateHelper from "~helpers/DateHelper";
import Network from "~helpers/Network";
import Utils from "~helpers/Utils";
import { selectCurrentProject } from "~store/user/selectors";

import * as at from "./actionTypes";

export const resetOrganizationForm = () => ({
  type: at.RESET_FORM,
});

export const setMemberFieldFormat = (payload) => ({
  type: at.SET_MEMBER_FIELD_FORMAT,
  payload,
});

export const setFilters = (payload) => ({
  type: at.SET_FILTERS,
  payload,
});

export const setFilter = (payload) => ({
  type: at.SET_FILTER,
  payload,
});

export const setResources = (payload) => ({
  type: at.SET_RESOURCES,
  payload,
});

export const setResource = (payload) => ({
  type: at.SET_RESOURCE,
  payload,
});

export const setMembers = (payload) => ({
  type: at.SET_MEMBERS,
  payload,
});

export const setMember = (payload) => ({
  type: at.SET_MEMBER,
  payload,
});
export const setSurveyList = (payload) => ({
  type: at.SET_SURVEY_LIST,
  payload,
});

export const setSlots = (payload) => ({
  type: at.SET_SLOT_LIST,
  payload,
});

export const setSlot = (payload) => ({
  type: at.SET_SLOT,
  payload,
});

export const setAction = (payload) => ({
  type: at.SET_ACTION,
  payload,
});
export const setActionList = (payload) => ({
  type: at.SET_ACTION_LIST,
  payload,
});
export const setReservations = (payload) => ({
  type: at.SET_RESERVATION_LIST,
  payload,
});

export const setDynamicReservationList = (payload) => ({
  type: at.SET_DYNAMIC_RESERVATIONS,
  payload,
});

export const setSurvey = (payload) => ({
  type: at.SET_SURVEY,
  payload,
});

export const setSurveyResult = (payload) => ({
  type: at.SET_SURVEY_RESULT,
  payload,
});

export const setAnnouncement = (payload) => ({
  type: at.SET_ANNOUNCEMENT,
  payload,
});

export const setAnnouncementVersion = (payload) => ({
  type: at.SET_ANNOUNCEMENT_VERSION,
  payload,
});

export const setSecretKey = (payload) => ({
  type: at.SET_SECRET_KEY,
  payload,
});

export const setSecretKeyList = (payload) => ({
  type: at.SET_SECRET_KEY_LIST,
  payload,
});

//---------- API ----------

export const updateMemberDataFormat = (data, projectId) => (_dispatch, getState) => {
  const currentProjectId = projectId || selectCurrentProject(getState())?.id;
  let url = apiUrlOrganization.getMemberDataFormat.format(currentProjectId);

  return Network.request(url, {
    method: "PUT",
    data,
    loading: true,
    successMsg: true,
  });
};

/**
 * @param projectId
 * @returns {Promise<{
 *   id: number;
 *   member_data_fields: [];
 *   secret_keys: string[];
 *   project: number;
 * }>}
 */
export const getMemberFieldFormatList =
  (projectId = null) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    const url = apiUrlOrganization.getMemberDataFormat.format(currentProjectId);
    return Network.request(url, {
      loading: true,
      onSuccess: setMemberFieldFormat,
    });
  };

export const getMemberDataFormat =
  (id, projectId = null) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    const url = `${apiUrlOrganization.getMemberDataFormat.format(currentProjectId)}${id}/`;
    return Network.request(url, {
      loading: true,
    });
  };

export const getFilter =
  (id, projectId = null, options = {}) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    const url = `${apiUrlOrganization.getFilter.format(currentProjectId)}${id}/`;
    return Network.request(url, {
      loading: true,
      onSuccess: setFilter,
      ...options,
    });
  };

export const getFilterList =
  (
    projectId = null,
    params = {
      is_template: true,
    }
  ) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    const url = `${apiUrlOrganization.getFilter.format(currentProjectId)}`;
    return Network.request(url, {
      loading: true,
      params,
      onSuccess: ({ results = [] }) => setFilters(results),
    });
  };

export const deleteFilter =
  (id, projectId = null) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    const url = `${apiUrlOrganization.getFilter.format(currentProjectId)}${id}/`;
    return Network.request(url, {
      method: "DELETE",
      loading: true,
      successMsg: true,
    });
  };

export const createOrUpdateFilter =
  (data, projectId = null, options = {}) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    let url = apiUrlOrganization.getFilter.format(currentProjectId);
    if (data?.id) {
      url += `${data.id}/`;
    }

    return Network.request(url, {
      method: data?.id ? "PUT" : "POST",
      data,
      loading: true,
      successMsg: true,
      ...options,
    });
  };

// Action
export const createOrUpdateAction =
  (data, projectId = null, options = {}) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    let url = apiUrlOrganization.getAction.format(currentProjectId);
    if (data?.id) {
      url += `${data.id}/`;
    }

    return Network.request(url, {
      method: data?.id ? "PUT" : "POST",
      data,
      loading: true,
      successMsg: true,
      ...options,
    });
  };

export const getActionList =
  (projectId = null, params = { is_template: true }) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    let url = apiUrlOrganization.getAction.format(currentProjectId);

    return Network.request(url, {
      loading: true,
      params,
      onSuccess: ({ results = [] }) => setActionList(results),
    });
  };

export const getAction =
  (id, projectId = null, options = {}) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    let url = `${apiUrlOrganization.getAction.format(currentProjectId)}${id}/`;

    return Network.request(url, {
      loading: true,
      onSuccess: setAction,
      ...options,
    });
  };

export const deleteAction = (id, projectId) => (_dispatch) => {
  let url = `${apiUrlOrganization.getAction.format(projectId)}${id}/`;
  return Network.request(url, {
    method: "DELETE",
    loading: true,
    successMsg: true,
  });
};

//Announce

export const getAnnouncement =
  (projectId, id, options = {}) =>
  (_dispatch, getState) => {
    let url = `${apiUrlOrganization.getAnnouncement.format(projectId)}${id}/`;

    return Network.request(url, {
      loading: true,
      onSuccess: setAnnouncement,
      ...options,
    });
  };

export const getAnnouncementList =
  (projectId, options = {}) =>
  (_dispatch, getState) => {
    let url = `${apiUrlOrganization.getAnnouncement.format(projectId)}`;

    return Network.request(url, {
      loading: true,
      ...options,
    });
  };

export const getReservationAnnouncementList =
  (projectId, options = {}) =>
  (_dispatch, getState) => {
    let url = `${apiUrlOrganization.getReservationAnnouncement.format(projectId)}`;

    return Network.request(url, {
      loading: true,
      ...options,
    });
  };

export const getAnnouncementVersion =
  (projectId, announcementId, id, options = {}) =>
  (_dispatch, getState) => {
    let url = `${apiUrlOrganization.getAnnouncementVersion.format(projectId, announcementId)}${id}/`;

    return Network.request(url, {
      loading: true,
      onSuccess: setAnnouncementVersion,
      ...options,
    });
  };
export const createOrUpdateAnnouncement =
  (projectId, data, options = {}) =>
  (_dispatch, getState) => {
    let url = apiUrlOrganization.getAnnouncement.format(projectId);
    if (data?.id) {
      url += `${data.id}/`;
    }

    return Network.request(url, {
      loading: true,
      method: data?.id ? "PUT" : "POST",
      data,
      successMsg: true,
      ...options,
    });
  };

export const createOrUpdateAnnouncementVersion =
  (projectId, announcementId, dataRaw, options = {}) =>
  async (dispatch, getState) => {
    const data = cloneDeep(dataRaw);

    let url = apiUrlOrganization.getAnnouncementVersion.format(projectId, announcementId);
    if (data?.id) {
      url += `${data.id}/`;
    }

    if (data?.action && typeof data.action === "object") {
      data.action.name = `(Action - v${data?.version_id})`;
      const actionResponse = await dispatch(createOrUpdateAction(data.action, projectId, options));
      data.action = actionResponse?.id;
    }
    if (data?.filter && typeof data.filter === "object") {
      data.filter.name = `(filter - v${data?.version_id})`;
      const actionResponse = await dispatch(createOrUpdateFilter(data.filter, projectId, options));
      data.filter = actionResponse?.id;
    }

    return await Network.request(url, {
      loading: true,
      method: data?.id ? "PUT" : "POST",
      data,
      successMsg: true,
      ...options,
    });
  };
export const sendAnnounce =
  (id, projectId = null) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    let url = apiUrlOrganization.getAnnounce.format(currentProjectId, id);

    return Network.request(url, {
      loading: true,
    });
  };

export const sendReAnnounce =
  (id, projectId = null) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    let url = apiUrlOrganization.getReAnnounce.format(currentProjectId, id);

    return Network.request(url, {
      loading: true,
    });
  };

//Survey
export const getSurveyList =
  (projectId, options = {}) =>
  (_dispatch, getState) => {
    let url = apiUrlOrganization.getSurvey.format(projectId);

    return Network.request(url, {
      loading: true,
      onSuccess: (response) => setSurveyList(response.results),
      ...options,
    });
  };

export const getSurvey =
  (id, projectId = null, options = {}) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    let url = `${apiUrlOrganization.getSurvey.format(currentProjectId)}${id}/`;

    return Network.request(url, {
      loading: true,
      onSuccess: (res) => {
        res.due_time = DateHelper.getDateTimeLocal(res.due_time);
        return setSurvey(res);
      },
      ...options,
    });
  };

export const getSurveyResult =
  (id, projectId = null) =>
  (_dispatch, getState) => {
    let url = apiUrlOrganization.getSurveyResult.format(projectId, id);

    return Network.request(url, {
      loading: true,
      onSuccess: setSurveyResult,
    });
  };

export const deleteSurvey = (id) => (_dispatch) => {
  let url = apiUrlOrganization.getSurvey.format(id);

  return Network.request(url, {
    method: "DELETE",
    loading: true,
    successMsg: true,
  });
};

export const createOrUpdateSurvey =
  (data, projectId = null) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    let url = apiUrlOrganization.getSurvey.format(currentProjectId);
    if (data?.id) {
      url += `${data.id}/`;
    }

    return Network.request(url, {
      method: data?.id ? "PUT" : "POST",
      data,
      loading: true,
      successMsg: true,
    });
  };

//announcement

export const sendAnnouncement =
  (surveyId, reannounce, data, projectId = null) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    const urlFormat = reannounce ? apiUrlOrganization.getReAnnounce : apiUrlOrganization.getAnnounce;
    const url = urlFormat.format(currentProjectId, surveyId);

    return Network.request(url, {
      method: "POST",
      data,
      loading: true,
      successMsg: true,
    });
  };
export const sendAnnouncementAction = (projectId, id) => (_dispatch, getState) => {
  const url = `${apiUrlOrganization.getMakeAnnouncement.format(projectId)}${id}/`;

  return Network.request(url, {
    method: "POST",
    loading: true,
    successMsg: true,
  });
};
export const sendAnnouncementCancelAction = (projectId, id) => (_dispatch, getState) => {
  const url = `${apiUrlOrganization.getCancelAnnouncement.format(projectId)}${id}/`;

  return Network.request(url, {
    method: "POST",
    loading: true,
    successMsg: true,
  });
};

//resource

export const getResources =
  (projectId = null) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    const url = `${apiUrlOrganization.getResource.format(currentProjectId)}`;

    return Network.request(
      Utils.qs(
        {
          limit: 9999,
        },
        url
      ),
      {
        loading: true,
        onSuccess: (response) => setResources(response.results),
      }
    );
  };

export const getReservationActions = (projectId, resourceId) => (_dispatch, getState) => {
  const url = `${apiUrlOrganization.getReservationActions.format(projectId, resourceId)}`;

  return Network.request(url, {
    loading: true,
  });
};

export const postReservationAction = (projectId, resourceId, data) => (_dispatch, getState) => {
  const url = `${apiUrlOrganization.getReservationActions.format(projectId, resourceId)}`;

  return Network.request(url, {
    method: "POST",
    data,
    loading: true,
    successMsg: true,
  });
};

export const getResource =
  (id, projectId = null) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    const url = `${apiUrlOrganization.getResource.format(currentProjectId)}${id}/`;

    return Network.request(url, {
      loading: true,
      onSuccess: setResource,
    });
  };

export const getDynamicReservationList =
  (projectId, resourceId, params, options = {}) =>
  async (_dispatch, getState) => {
    const url = `${apiUrlOrganization.getDynamicReservation.format(projectId, resourceId)}`;

    let response = await Network.request(url, {
      loading: true,
      params,
      onSuccess: (response) => {
        let list = Array.isArray(response?.results) ? response?.results : Array.isArray(response) ? response : [];
        list = list.map((item) => ({ ...item, dynamic: true }));
        return setDynamicReservationList(list);
      },
      ...options,
    });

    if (Array.isArray(response.results)) {
      response.results = response.results.map((item) => ({ ...item, dynamic: true }));
    } else if (Array.isArray(response)) {
      response = response.map((item) => ({ ...item, dynamic: true }));
    }
    return response;
  };

export const getSlots =
  (resourceId, projectId, params, options = {}) =>
  (_dispatch, getState) => {
    let url = `${apiUrlOrganization.getResourceAddSlot.format(projectId, resourceId)}`;

    return Network.request(url, {
      loading: true,
      params,
      onSuccess: (response) => setSlots(response.results),
      ...options,
    });
  };

export const getSlot =
  (slotId, resourceId, projectId, detailed = false) =>
  (_dispatch, getState) => {
    let url = `${apiUrlOrganization.getResourceAddSlot.format(projectId, resourceId)}${slotId}/`;
    if (detailed) {
      url += `detailed/`;
    }
    return Network.request(url, {
      loading: true,
      onSuccess: setSlot,
    });
  };

export const createOrUpdateResource =
  (data, projectId = null) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    let url = apiUrlOrganization.getResource.format(currentProjectId);
    if (data?.id) {
      url += `${data.id}/`;
    }

    return Network.request(url, {
      method: data?.id ? "PATCH" : "POST",
      data,
      loading: true,
      successMsg: true,
    });
  };

export const getResourceManagerUserList =
  (projectId, options = {}) =>
  () => {
    const url = apiUrlOrganization.getResourceManagerList.format(projectId);
    return Network.request(url, {
      ...options,
    });
  };

export const deleteResource =
  (resourceId, projectId = null) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    const url = `${apiUrlOrganization.getResource.format(currentProjectId)}${resourceId}/`;

    return Network.request(url, {
      method: "DELETE",
      loading: true,
      successMsg: true,
    });
  };

export const addBlacklistResourceUser = (projectId, resourceId, memberId) => (_dispatch, getState) => {
  const url = apiUrlOrganization.updateResourceBlacklist.format(projectId, resourceId);
  return Network.request(url, {
    method: "PUT",
    data: { member_id: memberId, action: "add" },
    loading: true,
    successMsg: true,
  });
};

export const removeBlacklistResourceUser = (projectId, resourceId, memberId) => (_dispatch, getState) => {
  const url = apiUrlOrganization.updateResourceBlacklist.format(projectId, resourceId);
  return Network.request(url, {
    method: "PUT",
    data: { member_id: memberId, action: "remove" },
    loading: true,
    successMsg: true,
  });
};

export const addResourceSlots =
  (data, resourceId, projectId = null) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    const url = apiUrlOrganization.getResourceAddBulkSlots.format(currentProjectId, resourceId);

    return Network.request(url, {
      method: "POST",
      data,
      loading: true,
      successMsg: true,
    });
  };

export const addOrUpdateResourceSlot =
  (data, resourceId, projectId = null) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    let url = apiUrlOrganization.getResourceAddSlot.format(currentProjectId, resourceId);
    if (data?.id) {
      url += `${data.id}/`;
    }

    return Network.request(url, {
      method: data?.id ? "PUT" : "POST",
      data,
      loading: true,
      successMsg: true,
    });
  };

export const deleteResourceSlot =
  (slotId, resourceId, projectId = null) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    const url = `${apiUrlOrganization.getResourceAddSlot.format(currentProjectId, resourceId)}${slotId}/`;

    return Network.request(url, {
      method: "DELETE",
      loading: true,
      successMsg: true,
    });
  };

//Member
export const getMembers =
  (projectId = null, params = {}, options = {}) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    const url = `${apiUrlOrganization.getMember.format(currentProjectId)}`;

    return Network.request(url, {
      loading: true,
      params: {
        limit: 99999,
        ...params,
      },
      onSuccess: (response) => setMembers(response.results),
      ...options,
    });
  };

//Member
export const getReservationMembers =
  (projectId = null, params = {}, options = {}) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    const url = `${apiUrlOrganization.getReservationMembers.format(currentProjectId)}`;

    return Network.request(url, {
      loading: true,
      params: {
        limit: 99999,
        ...params,
      },
      onSuccess: (response) => setMembers(response.results),
      ...options,
    });
  };

export const getMember =
  (projectId, id, options = {}) =>
  (_dispatch, getState) => {
    const url = `${apiUrlOrganization.getMember.format(projectId)}${id}/`;

    return Network.request(url, {
      loading: true,
      onSuccess: (responseRaw = {}) => {
        const response = cloneDeep(responseRaw);
        // TODO: fix on backend. replace true/false values with "True"/"False"
        for (const dataKey in response.safe_information) {
          if (["True", "False"].includes(response.safe_information?.[dataKey])) {
            response.safe_information[dataKey] = response.safe_information?.[dataKey] === "True";
          }
        }
        return setMember(response);
      },
      ...options,
    });
  };

export const getReservationMember =
  (projectId, id, options = {}) =>
  async (_dispatch, getState) => {
    const url = `${apiUrlOrganization.getReservationMembers.format(projectId)}${id}/`;

    const responseRaw = await Network.request(url, {
      loading: true,
      ...options,
    });
    const response = cloneDeep(responseRaw);
    // TODO: fix on backend. replace true/false values with "True"/"False"
    for (const dataKey in response.safe_information) {
      if (["True", "False"].includes(response.safe_information?.[dataKey])) {
        response.safe_information[dataKey] = response.safe_information?.[dataKey] === "True";
      }
    }

    return response;
  };

export const createOrUpdateMember = (projectId, data) => (_dispatch, getState) => {
  let url = apiUrlOrganization.getMember.format(projectId);
  if (data?.id) {
    url += `${data.id}/`;
  }

  return Network.request(url, {
    method: data?.id ? "PUT" : "POST",
    data,
    loading: true,
    successMsg: true,
  });
};

export const deleteMember = (projectId, memberId) => (_dispatch, getState) => {
  const url = `${apiUrlOrganization.getMember.format(projectId)}${memberId}/`;

  return Network.request(url, {
    method: "DELETE",
    loading: true,
    successMsg: true,
  });
};

//Reservations

export const createOrUpdateReservation =
  (data, resourceId, projectId = null) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    let url = apiUrlOrganization.getReservation.format(currentProjectId, resourceId);
    if (data?.id) {
      url += `${data.id}/`;
    }

    return Network.request(url, {
      method: data?.id ? "PUT" : "POST",
      data,
      loading: true,
      successMsg: true,
    });
  };

export const deleteReservation =
  (reservationId, resourceId, projectId = null) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    const url = `${apiUrlOrganization.getReservation.format(currentProjectId, resourceId)}${reservationId}/`;

    return Network.request(url, {
      method: "DELETE",
      loading: true,
      successMsg: true,
    });
  };

export const getReservation =
  (reservationId, resourceId, projectId = null) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    const url = `${apiUrlOrganization.getReservation.format(currentProjectId, resourceId)}${reservationId}/`;

    return Network.request(url, {
      loading: true,
    });
  };

export const getReservations =
  (resourceId, projectId = null, params, options = {}) =>
  (_dispatch, getState) => {
    const currentProjectId = projectId || selectCurrentProject(getState())?.id;
    const url = `${apiUrlOrganization.getReservation.format(currentProjectId, resourceId)}`;

    return Network.request(url, {
      loading: true,
      onSuccess: (response) => setReservations(response.results),
      params,
      ...options,
    });
  };

// export const generateSecretKey =
//   (projectId = null) =>
//   (_dispatch, getState) => {
//     const currentProjectId = projectId || selectCurrentProject(getState())?.id;
//     const url = `${apiUrlOrganization.getSecret.format(currentProjectId)}`;

//     return Network.request(url, {
//       method: "POST",
//       loading: true,
//       successMsg: true,
//     });
//   };

// export const deleteSecretKey =
//   (keyId, projectId = null) =>
//   (_dispatch, getState) => {
//     const currentProjectId = projectId || selectCurrentProject(getState())?.id;
//     const url = `${apiUrlOrganization.getSecret.format(currentProjectId)}${keyId}/`;

//     return Network.request(url, {
//       method: "DELETE",
//       loading: true,
//       successMsg: true,
//     });
//   };

// Load data

export const uploadMemberData =
  (projectId, data, options = {}) =>
  () => {
    const url = apiUrlOrganization.getLoadMemberData.format(projectId);
    let postData = new FormData();
    if (data.file) {
      postData.append("file", data.file);
    } else {
      postData = {
        member_data: data.member_data,
      };
    }
    return Network.request(url, {
      method: "POST",
      data: postData,
      loading: true,
      successMsg: true,
      ...options,
    });
  };

export const downloadMemberData =
  (projectId, options = {}) =>
  async () => {
    const url = apiUrlOrganization.getLoadMemberData.format(projectId);
    const blobData = await Network.request(url, {
      method: "GET",
      loading: true,
      responseType: "blob",
      ...options,
    });
    return blobData;
  };

export const getSecretKey = (projectId, id) => () => {
  const url = `${apiUrlOrganization.getSecretKey.format(projectId)}${id}/`;

  return Network.request(url, {
    loading: true,
    onSuccess: setSecretKey,
  });
};

export const getSecretKeyList = (projectId, id) => () => {
  const url = apiUrlOrganization.getSecretKey.format(projectId);

  return Network.request(url, {
    loading: true,
    onSuccess: ({ results = [] }) => setSecretKeyList(results),
  });
};

export const createOrUpdateSecretKey =
  (projectId, data, keepMissingMode, options = {}) =>
  () => {
    let url = apiUrlOrganization.getSecretKey.format(projectId);

    url += `?keep_missing=${keepMissingMode}`;

    return Network.request(url, {
      method: data?.id ? "PUT" : "POST",
      data: {
        keep_missing: keepMissingMode,
        ...data,
      },
      loading: true,
      successMsg: true,
      ...options,
    });
  };

export const deleteSecretKey = (projectId, id) => () => {
  const url = `${apiUrlOrganization.getSecretKey.format(projectId)}${id}/`;

  return Network.request(url, {
    method: "DELETE",
    loading: true,
    successMsg: true,
  });
};
