import React, { forwardRef } from "react";

import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import PalLink from "../PalLink";

const shouldForwardProp = (prop) => prop !== "curved";

const Styled = styled(Button, { shouldForwardProp })`
  // background: #f8f8f8;
  //  background: ${(props) => props.variant === "contained" && props.theme.palette[props.color || "primary"].main};

  text-transform: none;
  border: 1px solid
    ${(props) =>
      (props.variant || "text") === "text"
        ? "#ffffff00"
        : props.color === "secondary"
          ? "#DDDDDD"
          : props.theme.palette[props.color || "primary"].main};

  ${(props) => (props.curved ? "border-radius: 25px;" : "")}

  &:not(.MuiButton-sizeSmall, .MuiButton-sizeLarge) {
    height: 52px;
  }
  font-size: 18px;
  line-height: 22px;
`;

/**
 * @typedef {import("@mui/material/Button").ButtonProps & {
 *   link?: string;
 *   linkProps?: any;
 * }} PalButtonProps
 * @type {React.FC<PalButtonProps>}
 */

const PalButton = forwardRef(function PalButton(
  { curved = true, startIcon, loading, link, disabled, linkProps, children, ...rest },
  ref
) {
  const Wrapper = link
    ? (props) => <PalLink ref={ref} color={rest.color} to={link} underline="none" {...linkProps} {...props} />
    : React.Fragment;
  return (
    <Wrapper>
      <Styled
        curved={curved}
        disabled={disabled || loading}
        {...rest}
        // INFO: Always use display css property or similar to hide the startIcon element instead of conditional rendering like this: {loading && <CircularProgress />}
        // Because the startIcon element is causing crash on Google Translating the page to another language
        // Only replace this place when multi-language support is properly implemented
        // https://github.com/facebook/react/issues/11538#issuecomment-390386520
        startIcon={
          startIcon ?? (
            <CircularProgress
              color="inherit"
              size={20}
              sx={{
                display: loading ? "block" : "none",
              }}
            />
          )
        }
        {...(link ? {} : { ref })}
      >
        {children}
      </Styled>
    </Wrapper>
  );
});

export default PalButton;
