// import React from "react";

// import Select, { SelectProps } from "@mui/material/Select";
// import { makeStyles } from "@mui/styles";
// import styled from "@emotion/styled";

// const StyledSelect = styled(Select, {
//   shouldForwardProp: (prop) => !["smallSpacing", "borderRadius"].includes(prop),
// })`
//   border-radius: ${(props) => props.borderRadius}px;
//   .MuiInputBase-root {
//     min-height: 52px;
//     border-radius: ${(props) => props.borderRadius}px;
//     background: #f8f8f8;
//     padding-left: 0px;
//     // padding-right: 0px;
//   }
//   .MuiInputBase-input {
//     padding-left: ${(props) => (props.smallSpacing ? "15px" : "25px")};
//     padding-right: 0px;
//     // padding-right: ${(props) => (props.smallSpacing ? "15px" : "25px")};
//     padding-top: 14.5px;
//     padding-bottom: 14.5px;
//   }

//   // .MuiFormLabel-root {
//   //   padding: 0px ${(props) => (props.smallSpacing ? "0px" : "12px")};
//   // }
//   // .MuiOutlinedInput-notchedOutline {
//   //   padding: 0px ${(props) => (props.smallSpacing ? "12px" : "18px")};

//   //   span {
//   //     padding-right: ${(props) => (props.smallSpacing ? "10px" : "5px")};
//   //   }
//   // }
//   // .MuiFormHelperText-root {
//   //   margin-left: ${(props) => (props.smallSpacing ? "15px" : "25px")};
//   //   font: normal normal normal 14px/20px Museo Sans;
//   // }

//   .MuiPaper-root {
//     border-radius: ${(props) => props.borderRadius}px;
//     border: 10px solid #f8f8f8 !important;
//   }
// `;

// // const StyledPaper = styled(Paper)`
// //   border-radius: ${(props) => props.borderRadius || 26}px;
// //   border: 222px solid #00f8f8 !important;
// // `;

// const useStyles = makeStyles((theme) => ({
//   menu: {
//     "& .MuiPaper-root": {
//       borderRadius: 26,
//       marginTop: "5px",
//       "& .MuiList-root": {
//         marginLeft: "15px",
//         marginRight: "15px",
//       },
//       "& .MuiMenuItem-root": {
//         borderRadius: "4px",
//         padding: "12px 10px !important",
//       },
//     },
//   },
// }));

// /**
//  * @typedef {SelectProps} PalSelectProps
//  * @param {PalSelectProps} rest
//  */
// const PalSelect = ({ invalid, smallSpacing, borderRadius = 26, ...rest }) => {
//   const classes = useStyles();

//   const isInvalid = !!invalid;
//   return (
//     <StyledSelect
//       smallSpacing={smallSpacing}
//       borderRadius={borderRadius}
//       error={isInvalid}
//       helperText={isInvalid ? invalid : ""}
//       MenuProps={{
//         className: classes.menu,
//       }}
//       {...rest}
//     />
//   );
// };
import { default as PalSelect } from "./PalAutocomplete";

export default PalSelect;
