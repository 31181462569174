import PRSwitch from "~components/Generic/PRSwitch";

import DictionariesEdit from "./DictionariesEdit";
import DictionariesList from "./DictionariesList";

function Dictionaries() {
  const routes = [
    { path: "/chatbot/dictionaries/form/:id?", component: DictionariesEdit },
    { path: "/chatbot/dictionaries/", component: DictionariesList },
    { path: "/chatbot/", to: "chatbot/dictionaries/", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}

export default Dictionaries;
