import { enableMapSet, enablePatches, produce } from "immer";

enablePatches();
enableMapSet();

export function safeProduce(...args) {
  const [obj, ...params] = args;

  let nextState;
  try {
    nextState = produce(obj, ...params);
  } catch (error) {
    console.error("Error in safeProduce: ", args, error);
  }
  return nextState;
}
