import { useEffect, useState } from "react";

import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Badge } from "reactstrap";

import useProjectChange from "~common/hooks/useProjectChange";
import PRAccordion, { PRAccordionItem } from "~components/Generic/PRAccordion";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRPage from "~components/Generic/PRPage";
import { dialogComponentsIntentType } from "~constants";
import HistoryHelper from "~helpers/HistoryHelper";
import Utils from "~helpers/Utils";
import {
  createOrUpdateScenarioManager,
  fetchScenarioForm,
  getDatabaseList,
  getMediaList,
  getProcedureList,
  getVariableList,
  resetScenarioManagerForm,
  setScenarioFormState,
} from "~store/dialogComponents/scenarioManager/actions";
import { initState as scenarioManagerInitState } from "~store/dialogComponents/scenarioManager/reducer";
import {
  selectScenarioManagerAuthenticationRequired,
  selectScenarioManagerAuthType,
  selectScenarioManagerForms,
  selectScenarioManagerId,
  selectScenarioManagerIntentTag,
  selectScenarioManagerIntentType,
  selectScenarioManagerLoading,
  selectScenarioManagerRagFactGroup,
  selectScenarioManagerResponse,
  selectScenarioManagerSamples,
  selectScenarioManagerSaving,
  selectScenarioManagerSlots,
  selectScenarioManagerSlotValidationText,
  selectScenarioManagerText,
  selectScenarioManagerUseInPrediction,
  selectScenarioPreProcessLowCodeScript,
} from "~store/dialogComponents/scenarioManager/selectors";
import { getIntellisenseData } from "~store/lowcode/actions";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

import Preprocess from "./Preprocess";
import Question from "./Question";
import Rag from "./Rag";
import Response from "./Response";

import "./style.scss";

function useRenderTitleQuestionTextChanges() {
  const intentTag = useSelector(selectScenarioManagerIntentTag);
  const text = useSelector(selectScenarioManagerText);
  const useInPrediction = useSelector(selectScenarioManagerUseInPrediction);
  const authType = useSelector(selectScenarioManagerAuthType);

  const questionOpt = {
    text,
    intentTag,
    useInPrediction,
    authType,
  };
  const questionOptDefault = {
    text: scenarioManagerInitState.intent.text,
    intentTag: scenarioManagerInitState.intent.intent_tag,
    useInPrediction: scenarioManagerInitState.intent.use_in_prediction,
    authType: scenarioManagerInitState.intent.auth_type,
  };

  const hasChanges =
    Utils.safeStringify(Utils.removeNulls(questionOpt, true)) !==
    Utils.safeStringify(Utils.removeNulls(questionOptDefault, true));

  return hasChanges;
}
function RenderTitleQuestionText() {
  const samples = useSelector(selectScenarioManagerSamples);
  const hasChanges = useRenderTitleQuestionTextChanges();
  return (
    <div className="position-relative">
      {t("dashboard.intentEdit.intentInformation")}
      {hasChanges && !samples.length && (
        <div className="">
          <span className="ms-2 position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-1">
            <span className="visually-hidden"></span>
          </span>
        </div>
      )}
      <Badge className="ms-1" color={"primary"}>
        {!!samples.length && samples.length}
      </Badge>
    </div>
  );
}
function useRenderTitleResponseChanges() {
  const response = useSelector(selectScenarioManagerResponse);

  const hasChanges =
    Utils.safeStringify(Utils.removeNulls(response, true)) !==
    Utils.safeStringify(Utils.removeNulls(scenarioManagerInitState.intent.response, true));

  return hasChanges;
}

function useRenderTitleRagChanges() {
  const ragFactGroup = useSelector(selectScenarioManagerRagFactGroup);

  const hasChanges =
    ragFactGroup?.content !== scenarioManagerInitState.intent?.rag_fact_group?.content ||
    ragFactGroup?.summary !== scenarioManagerInitState.intent?.rag_fact_group?.summary ||
    (ragFactGroup?.related_questions?.length &&
      scenarioManagerInitState.intent?.rag_fact_group?.related_questions?.length &&
      [...(ragFactGroup?.related_questions || [])].every((v) =>
        scenarioManagerInitState.intent?.rag_fact_group?.related_questions.includes(v)
      )) ||
    ragFactGroup?.status !== scenarioManagerInitState.intent?.rag_fact_group?.status ||
    ragFactGroup?.project !== scenarioManagerInitState.intent?.rag_fact_group?.project;
  return hasChanges;
}
function RenderTitleResponse() {
  const hasChanges = useRenderTitleResponseChanges();
  return (
    <div className="position-relative">
      {t("dashboard.intentEdit.response")}
      {hasChanges && (
        <span className="ms-2 position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-1">
          <span className="visually-hidden"></span>
        </span>
      )}
    </div>
  );
}

function RenderTitleRag() {
  const hasChanges = useRenderTitleRagChanges();
  return (
    <div className="position-relative">
      RAG
      {hasChanges && (
        <span className="ms-2 position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-1">
          <span className="visually-hidden"></span>
        </span>
      )}
    </div>
  );
}
function useRenderTitlePreprocessChanges() {
  const slots = useSelector(selectScenarioManagerSlots);
  const forms = useSelector(selectScenarioManagerForms);
  const slotValidationText = useSelector(selectScenarioManagerSlotValidationText);
  const authenticationRequired = useSelector(selectScenarioManagerAuthenticationRequired);

  const preProcessLowCodeScript = useSelector(selectScenarioPreProcessLowCodeScript);

  const initState = scenarioManagerInitState.intent;
  const advancedState = {
    slots,
    forms,
    authenticationRequired,
    slotValidationText,
    preProcessLowCodeScript,
  };
  const advancedInitState = {
    slots: initState.slots,
    forms: initState.forms,
    authenticationRequired: initState.authentication_required,
    slotValidationText: initState.slot_validation_text,
    preProcessLowCodeScript: initState.preprocess_low_code_script,
  };

  const hasChanges =
    Utils.safeStringify(Utils.removeNulls(advancedState, true)) !==
    Utils.safeStringify(Utils.removeNulls(advancedInitState, true));
  return hasChanges;
}

function RenderTitlePreprocess() {
  const hasChanges = useRenderTitlePreprocessChanges();
  return (
    <div className="position-relative">
      {t("dashboard.intentEdit.preprocess")}
      {hasChanges && (
        <span className="ms-2 position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-1">
          <span className="visually-hidden"></span>
        </span>
      )}
    </div>
  );
}

function ScenarioManagerEdit() {
  const { t } = useTranslation();
  const hasChangesRenderTitleQuestionText = useRenderTitleQuestionTextChanges();
  const hasChangesRenderTitleResponse = useRenderTitleResponseChanges();
  const hasChangesRenderTitlePreprocess = useRenderTitlePreprocessChanges();
  const hasChangesRenderTitleRag = useRenderTitleRagChanges();

  const loading = useSelector(selectScenarioManagerLoading);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [accordionState, setAccordionState] = useState({
    1: true,
    2: null,
    3: null,
    4: true,
  });

  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const intentType = useSelector(selectScenarioManagerIntentType);

  useEffect(() => {
    if (id) {
      dispatch(fetchScenarioForm(id));
    } else {
      dispatch(setScenarioFormState("bot", currentBot.id));
      dispatch(setScenarioFormState("project", currentProject.id));
    }

    dispatch(getProcedureList(currentProject.id, currentBot.id));

    dispatch(getMediaList(currentProject.id, currentBot.id));
    dispatch(getVariableList(currentProject.id, currentBot.id));
    dispatch(getDatabaseList(currentProject.id, currentBot.id));

    return () => {
      dispatch(resetScenarioManagerForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const handleClickAccordion = (id) => (e, state) => {
    setAccordionState({
      ...accordionState,
      [id]: state,
    });
  };
  useEffect(() => {
    dispatch(getIntellisenseData(currentProject.id));
  }, [dispatch, currentProject.id]);

  const parentName = [
    {
      label: t("layout.sidebar.intent"),
      url: "/chatbot/intent",
    },
    {
      label: !id ? t("dashboard.intentEdit.createIntent") : t("dashboard.intentEdit.editIntent"),
    },
  ];
  return (
    <PRContainer
      className="pr-scenario-manager"
      description={t("dashboard.intentEdit.description")}
      name={t("layout.sidebar.chatbot")}
      parentName={parentName}
    >
      <PRPage title="">
        <PRAccordion separated loading={loading}>
          <PRAccordionItem
            collapsed={accordionState[1] ?? hasChangesRenderTitleQuestionText}
            title={RenderTitleQuestionText}
            onClick={handleClickAccordion(1)}
          >
            <Question />
          </PRAccordionItem>

          {intentType === dialogComponentsIntentType.STANDARD && (
            <PRAccordionItem
              noSpacing
              collapsed={accordionState[3] ?? hasChangesRenderTitlePreprocess}
              title={RenderTitlePreprocess}
              onClick={handleClickAccordion(3)}
            >
              <Preprocess />
            </PRAccordionItem>
          )}
          <PRAccordionItem
            noSpacing
            collapsed={accordionState[2] ?? hasChangesRenderTitleResponse}
            title={RenderTitleResponse}
            onClick={handleClickAccordion(2)}
          >
            <Response />
          </PRAccordionItem>

          {intentType === dialogComponentsIntentType.RAG && (
            <PRAccordionItem
              // noSpacing
              collapsed={accordionState[4] ?? hasChangesRenderTitleRag}
              title={RenderTitleRag}
              onClick={handleClickAccordion(4)}
            >
              <Rag />
            </PRAccordionItem>
          )}
        </PRAccordion>
        <ScenarioActions />
      </PRPage>
    </PRContainer>
  );
}

function ScenarioActions() {
  const dispatch = useDispatch();
  const saving = useSelector(selectScenarioManagerSaving);
  const id = useSelector(selectScenarioManagerId);
  const intentType = useSelector(selectScenarioManagerIntentType);
  // const ragFactGroup = useSelector(selectScenarioManagerRagFactGroup);

  const handleClickCancel = () => {
    HistoryHelper.goBack("/chatbot/intent", { scope: "dashboard" });
  };
  const isInitialRag = intentType === dialogComponentsIntentType.RAG && !id;
  const handleClickSave = (e) => {
    dispatch(createOrUpdateScenarioManager(e.shiftKey || isInitialRag));
  };

  useProjectChange(() => {
    HistoryHelper.push("/chatbot/intent", { scope: "dashboard" });
  }, []);

  useEffect(() => {
    const handleSave = (e) => {
      if (e.ctrlKey && e.key === "s" && id) {
        e.preventDefault();
        dispatch(createOrUpdateScenarioManager(true));
      }
    };
    document.addEventListener("keydown", handleSave);
    return () => {
      document.removeEventListener("keydown", handleSave);
    };
  }, [dispatch, id]);

  // const isRagIntentNotProcessed =
  //   intentType === dialogComponentsIntentType.RAG &&
  //   ragFactGroup?.status !== dialogComponentsRagFactGroupStatus.Processed;
  return (
    <div className="d-flex justify-content-end mt-4 gap-2 action-buttons">
      <PRButton outline className="mr-2" color="primary" onClick={handleClickCancel}>
        {t("common.cancel")}
      </PRButton>

      <PRButton
        color="primary"
        disabled={!!saving} // || isRagIntentNotProcessed
        loading={!!saving}
        onClick={handleClickSave}
      >
        {id ? t("common.update") : t("common.create")}
      </PRButton>
    </div>
  );
}

export default ScenarioManagerEdit;
