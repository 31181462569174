import { Fragment } from "react";

import { Badge } from "reactstrap";

import { organizationComparisonOptions, organizationFilterType } from "~constants";

import CodeBracket from "./CodeBracket";

let tempId = 1;
const getUniqueKey = () => {
  tempId += 1;
  return tempId;
};

export const filterGetReadableText = (filter, getAsComponent, depth = 0) => {
  const filterTextArr = [];

  if (!filter) return "";
  if (organizationFilterType.COMPARISON !== filter.filter_type) {
    filterTextArr.push(getChildrenFilter(filter, getAsComponent, depth + 1));
  } else {
    filterTextArr.push(getLeafFilter(filter, getAsComponent));
  }
  if (getAsComponent) {
    return <span className="text-secondary d-flex align-items-center">{filterTextArr}</span>;
  }
  return filterTextArr.join(" ");
};

const getChildrenFilter = (filter, getAsComponent, depth = 0) => {
  const children = filter.children || [];
  const childArr = [];
  //   const matchedFilterType = organizationFilterType.find((option) => option.value === filter.filter_type);
  //   filterTextArr.push(`${matchedFilterType.label}`);

  let concatenateItem = filter.filter_type === organizationFilterType.AND ? "AND" : "OR";
  if (getAsComponent) {
    // concatenateItem = <b className="text-dark mx-2">{concatenateItem}</b>;
    concatenateItem = (
      <Badge key={getUniqueKey()} pill color="primary mx-2 font-size-12">
        {concatenateItem}
      </Badge>
    );
  }

  //   filterTextArr.push(getLeafFilter(filter, getAsComponent));
  //   filterTextArr.push(filter.filter_type === organizationFilterType.AND ? "AND" : "OR");

  for (const childFilter of children) {
    if (childArr.length > 0) {
      childArr.push(<Fragment key={getUniqueKey()}>{concatenateItem}</Fragment>);
    }
    if (getAsComponent) {
      childArr.push(
        <span key={getUniqueKey()} className="text-secondary d-flex align-items-center flex-nowrap">
          {/* <b className="font-size-16 ">(</b> */}
          <CodeBracket depth={depth}>{filterGetReadableText(childFilter, getAsComponent, depth)}</CodeBracket>
          {/* <b className="font-size-16 ">)</b> */}
        </span>
      );
      continue;
    }
    childArr.push(
      <Fragment key={getUniqueKey()}>{filterGetReadableText(childFilter, getAsComponent, depth)}</Fragment>
    );
  }

  if (getAsComponent) {
    return (
      <span key={getUniqueKey()} className="d-flex align-items-center flex-wrap">
        {childArr}
      </span>
    );
  }
  //   return filterTextArr.join(" ") + " " + childArr.join(" ");
  return <Fragment key={getUniqueKey()}>`(${childArr.join(" ")})`</Fragment>;
};

const getLeafFilter = (filter, getAsComponent) => {
  let fieldText = filter.member_data_field?.name ?? "EMPTY";
  let matchedComparisonType = organizationComparisonOptions.find(
    (option) => option.value === filter.comparison_type
  )?.label;
  let value = filter.value;
  if (getAsComponent) {
    fieldText = (
      <span key={getUniqueKey()} className="text-secondary">
        {fieldText}
      </span>
    );
    // matchedComparisonType = <b className="text-dark mx-2">{matchedComparisonType}</b>;
    matchedComparisonType = (
      <Badge key={getUniqueKey()} color=" badge-soft-secondary mx-2">
        {matchedComparisonType}
      </Badge>
    );

    value = (
      <span key={getUniqueKey()} className="text-secondary">
        {value}
      </span>
    );
  }

  const filterTextArr = [];
  filterTextArr.push(fieldText);
  filterTextArr.push(matchedComparisonType);
  filterTextArr.push(value);
  if (getAsComponent) {
    return (
      <span key={getUniqueKey()} className="d-flex align-items-center  ">
        {filterTextArr}
      </span>
    );
  }
  return filterTextArr.join(" ");
};
