import { createRef, useEffect, useMemo, useState } from "react";

import { withCardon } from "cardon";
import { useTranslation } from "react-i18next";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import useFormikEx from "~common/hooks/useFormikEx";
import useLoading from "~common/hooks/useLoading";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRModal from "~components/Generic/PRModal";
import PRSelect from "~components/Generic/PRSelect";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import { apiUrlIntegrationModuleRegistry } from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import {
  createOrUpdateModuleIntegration,
  deleteModuleIntegration,
  getModuleIntegrationList,
  getModuleList,
  getModuleVersion,
} from "~store/integration/module/actions";
import { selectModuleList } from "~store/integration/module/selectors";
import { selectCurrentProject } from "~store/user/selectors";

const CreateIntegrationModal = withCardon(
  function CreateIntegrationModalContent({ get }) {
    const { t } = useTranslation();
    const currentProject = useSelector(selectCurrentProject);
    const [loading, q] = useLoading();
    const moduleList = useSelector(selectModuleList);
    // const moduleIntegrationList = useSelector(selectModuleIntegrationList);
    const dispatch = useDispatch();
    const [versionList, setVersionList] = useState([]);

    const formik = useFormikEx({
      enableReinitialize: true,
      initialValues: {
        module: null,
        version: null,
      },
      validationSchema: Yup.object().shape({
        module: Yup.mixed().required(t("component.formik.required").format(t("dashboard.module"))),
        version: Yup.mixed().required(t("component.formik.required").format(t("common.version"))),
      }),
      onSubmit: (values) => {
        dispatch(
          createOrUpdateModuleIntegration(currentProject.id, values.module.value, { version: values.version.value })
        ).then((res) => {
          get(res)();
        });
      },
    });
    useEffect(() => {
      dispatch(getModuleList(currentProject.id));
      dispatch(getModuleIntegrationList(currentProject.id));
    }, [currentProject.id, dispatch]);

    useEffect(() => {
      if (!formik.values.module?.value || !moduleList?.length) return;
      const m = moduleList.find((m) => m.id === formik.values.module?.value);
      if (!m?.versions?.length) return;
      q(
        Promise.all(m.versions.map((mId) => dispatch(getModuleVersion(currentProject.id, mId)))).then((res = []) => {
          setVersionList(res);
        })
      );
    }, [currentProject.id, moduleList, formik.values.module?.value, dispatch, q]);

    const handleChange = (key) => (value) => {
      if (key === "module" && !value) {
        setVersionList([]);
        formik.setFieldValue("version", null);
      }
      formik.setFieldValue(key, value);
    };

    const moduleListOptions = useMemo(() => {
      if (!moduleList?.length) return [];
      return moduleList.map((m) => ({ label: `${m.display_name} (${m.machine_name})`, value: m.id }));
    }, [moduleList]);

    const versionListOptions = useMemo(() => {
      if (!versionList?.length) return [];
      return versionList.map((m) => ({ label: `v${m.version}`, value: m.id }));
    }, [versionList]);
    return (
      <PRModal
        submitText={t("common.create")}
        title={t("dashboard.moduleIntegration.createModuleIntegration")}
        onClick={formik.handleSubmit}
        onClose={get(false)}
      >
        <Row className="g-2">
          <Col xs={12}>
            <Label>{t("dashboard.module")}</Label>
            <PRSelect
              invalid={formik.touched.module && formik.errors.module}
              options={moduleListOptions}
              placeholder={t("dashboard.moduleIntegration.selectModulePlaceholder")}
              value={formik.values.module}
              onChange={handleChange("module")}
            />
          </Col>
          <Col disabled={!module} xs={12}>
            <Label>{t("common.version")}</Label>
            <PRSelect
              invalid={formik.touched.version && formik.errors.version}
              isLoading={loading}
              options={versionListOptions}
              placeholder={t("dashboard.moduleIntegration.selectVersionPlaceholder")}
              value={formik.values.version}
              onChange={handleChange("version")}
            />
          </Col>
        </Row>
      </PRModal>
    );
  },
  { destroyOnHide: true }
);
const ModuleIntegrationList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableRef = createRef();
  const currentProject = useSelector(selectCurrentProject);

  const actions = useMemo(() => {
    const handleClick = async () => {
      const res = await CreateIntegrationModal.show();
      if (res) {
        HistoryHelper.push(`/integration/module-integration/form/${res.id}`, { scope: "dashboard" });
      }
    };
    return [
      {
        label: t("common.createNew"),
        icon: MdAdd,
        color: "success",
        // link: "/integration/module-integration/form",
        onClick: handleClick,
      },
    ];
  }, [t]);

  const columns = useMemo(() => {
    const handleClickDelete = (row) => async () => {
      if (await DialogHelper.showQuestionDelete()) {
        dispatch(deleteModuleIntegration(currentProject.id, row.id)).then(() => {
          tableRef.current.refresh();
        });
      }
    };
    return [
      {
        label: t("common.enabled"),
        key: "enabled",
        render: (row) => (row.enabled ? <span className="fw-bold">{t("common.yes")}</span> : t("common.no")),
      },
      {
        label: t("common.parameters"),
        key: "parameters",
        render: (row) => {
          return (
            <>
              {Object.entries(row.parameters)?.map(([key, value]) => {
                return (
                  <Badge key={key} className="badge-soft-secondary me-1">
                    <PRTooltip title={value}>
                      <span className="font-size-12">{key}</span>
                    </PRTooltip>
                  </Badge>
                );
              })}
            </>
          );
        },
      },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        render: (row) => (
          <div className="d-flex justify-content-center gap-1">
            {/* <PRButton
              size="sm"
              outline
              color="secondary"
              icon="?? fa-code-branch"
              tooltipText="Manage Versions"
              link={`/integration/module-integration/version/${row.id}`}
            /> */}
            <PRButton
              outline
              color="primary"
              icon={MdEdit}
              link={`/integration/module-integration/form/${row.id}`}
              size="sm"
              tooltipText={t("common.edit")}
            />
            <PRButton
              outline
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText={t("common.delete")}
              onClick={handleClickDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [currentProject.id, dispatch, tableRef, t]);
  return (
    <PRContainer
      actions={actions}
      description={t("dashboard.moduleIntegration.description")}
      name={t("common.integration")}
      parentName={t("dashboard.moduleIntegration")}
    >
      <PRTable
        ref={tableRef}
        columns={columns} //
        url={apiUrlIntegrationModuleRegistry.get.format(currentProject.id)}
      />
    </PRContainer>
  );
};

export default ModuleIntegrationList;
