import { LS_LOCAL_STORAGE_VERSION, LS_LOCAL_STORAGE_VERSION_CHATBOT } from "~constants";

import IFrameHelper from "./IFrameHelper";
export default class StorageHelper {
  static initialize(webchatToken = "") {
    if (!StorageHelper.isAvailable()) return;

    const localStorageVersion = 1;
    const isWebChatMode = IFrameHelper.isWebChatMode();
    const localStorageVersionKey = isWebChatMode
      ? LS_LOCAL_STORAGE_VERSION_CHATBOT.format(webchatToken)
      : LS_LOCAL_STORAGE_VERSION;

    StorageHelper.get(localStorageVersionKey).then(async (version) => {
      const currentVersion = Number(version);

      if (currentVersion !== localStorageVersion) {
        if (Number(currentVersion) > 0 && currentVersion !== localStorageVersion) {
          await StorageHelper.clear();
        }

        await StorageHelper.set(localStorageVersionKey, localStorageVersion);
      }
    });
  }
  static async isAvailable() {
    const isAvailable = await IFrameHelper.checkStorage();
    return !!isAvailable;
  }

  static get = async (key) => {
    try {
      let value = await IFrameHelper.getStorage(key);
      try {
        value = JSON.parse(value);
      } catch {}
      return value;
    } catch (e) {
      console.log("StorageHelper.get", e);
    }
  };

  static set = async (key, value) => {
    let _value = value;
    if (typeof value === "object") {
      _value = JSON.stringify(value);
    }
    try {
      await IFrameHelper.setStorage(key, _value);
    } catch (e) {
      console.log("StorageHelper.set", e);
    }
  };

  static move = async (oldKey, newKey) => {
    try {
      const value = await StorageHelper.get(oldKey);
      await StorageHelper.set(newKey, value);
      await StorageHelper.remove(oldKey);
    } catch (e) {
      console.log("StorageHelper.move", e);
    }
  };

  static remove = async (key) => {
    try {
      await IFrameHelper.removeStorage(key);
    } catch (e) {
      console.log("StorageHelper.remove", e);
    }
  };

  static clear = async () => {
    try {
      await IFrameHelper.clearStorage();
    } catch (e) {
      console.log("StorageHelper.clear", e);
    }
  };
}
