import ConstantHelper from "~helpers/ConstantHelper";

export const [projectBotStatus, projectBotStatusOptions, projectBotStatusMap] = ConstantHelper.typify({
  initializing: "constants.projectBotStatus.initializing",
  ready: "constants.projectBotStatus.ready",
  train: "constants.projectBotStatus.train",
  train_required: "constants.projectBotStatus.trainRequired",
  golive: "constants.projectBotStatus.goLive",
  golive_required: "constants.projectBotStatus.goLiveRequired",
  live: "constants.projectBotStatus.live",
});

export const [projectLanguage, projectLanguageOptions, projectLanguageMap] = ConstantHelper.typify({
  TR: "constants.languages.turkish",
  EN: "constants.languages.english",
  DE: "constants.languages.german",
  GR: "constants.languages.greek",
  AR: "constants.languages.arabic",
  UK: "constants.languages.ukrainian",
  RU: "constants.languages.russian",
  PE: "constants.languages.persian",
  FR: "constants.languages.french",
  NL: "constants.languages.dutch",
});

export const [emailTypes, emailTypesOptions, emailTypesMap] = ConstantHelper.typify({
  SMTP: "SMTP",
  SES: "SES",
});

export const [emailStatus, emailStatusOptions, emailStatusMap] = ConstantHelper.typify({
  NEW: "New",
  PEN: "Pending",
  VER: "Verified",
});

export const [projectTimeoutTimes, projectTimeoutTimesOptions, projectTimeoutTimesMap] = ConstantHelper.typify({
  /** @type {"web"} */
  web: "constants.projectTimeoutTimes.web",
  /** @type {"mobile"} */
  whatsapp: "constants.projectTimeoutTimes.whatsapp",
  /** @type {"telegram"} */
  teams: "constants.projectTimeoutTimes.teams",
  /** @type {"telegram"} */
  telegram: "constants.projectTimeoutTimes.telegram",
  /** @type {"facebook"} */
  discord: "constants.projectTimeoutTimes.discord",
  /** @type {"slack"} */
  slack: "constants.projectTimeoutTimes.slack",
  /** @type {"skype"} */
  ios: "constants.projectTimeoutTimes.ios",
  /** @type {"android"} */
  android: "constants.projectTimeoutTimes.android",
  /** @type {"web"} */
  other_mobile: "constants.projectTimeoutTimes.otherMobile",
});

export const [projectTranslateKey, projectTranslateKeyOptions, projectTranslateKeyMap] = ConstantHelper.typify({
  DIRECT_TO_QUEUE_FOR_AGENT: "constants.projectTranslateKey.directToQueueForAgent",
  DIRECT_TO_QUEUE_FOR_BOT: "constants.projectTranslateKey.directToQueueForBot",
  TERMINATE_SESSION: "constants.projectTranslateKey.terminateSession",
  AGENT_CONNECT: "constants.projectTranslateKey.agentConnect",
  AGENT_DISCONNECT: "constants.projectTranslateKey.agentDisconnect",
  BOT_CONNECT: "constants.projectTranslateKey.botConnect",
  OFFLINE_MESSAGE: "constants.projectTranslateKey.offlineMessage",
});
