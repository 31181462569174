import { memo } from "react";

import styled from "@emotion/styled";

const StyledDiv = styled.div`
  align-items: center;
  display: flex;
  min-height: ${(props) => props.height}px;
  height: ${(props) => props.height}px;

  .dot {
    border-radius: 50%;
    margin-right: 5px;
    background-color: grey;
    color: #04133a;
    height: 6px;
    width: 6px;
    margin-right: 4px;
    border-radius: 50%;
    vertical-align: middle;
    display: inline-block;

    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  .dot1 {
    animation-name: colorChange1;
  }

  .dot2 {
    animation-name: colorChange2;
    animation-delay: 0.6s;
  }

  .dot3 {
    animation-name: colorChange3;
    animation-delay: 1.2s;
  }

  @keyframes colorChange1 {
    0% {
      opacity: 0.2;
    }
    33.3% {
      opacity: 0.4;
    }
    66.6% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.2;
    }
  }

  @keyframes colorChange2 {
    0% {
      opacity: 0.4;
    }
    33.3% {
      opacity: 0.8;
    }
    66.6% {
      opacity: 0.2;
    }
    100% {
      opacity: 0.4;
    }
  }

  @keyframes colorChange3 {
    0% {
      opacity: 0.8;
    }
    33.3% {
      opacity: 0.2;
    }
    66.6% {
      opacity: 0.4;
    }
    100% {
      opacity: 0.8;
    }
  }
`;

const ChatTyping = memo(function ChatTyping({ height = 22 }) {
  return (
    <StyledDiv height={height}>
      <div className="dot dot1"></div>
      <div className="dot dot2"></div>
      <div className="dot dot3"></div>
    </StyledDiv>
  );
});
export default ChatTyping;
