import React, { forwardRef, useState } from "react";

import styled from "@emotion/styled";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, Grid } from "@mui/material";

import PalIconButton from "~components/mui/PalIconButton";

function PRTitle({ title = "" }) {
  return <h4 className="card-title fs-5">{title}</h4>;
}

const StyledCard = styled.div`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  border-radius: 14px;
`;
export const PalCard = forwardRef(function PalCard({ children, style, ...rest }, ref) {
  return (
    <StyledCard ref={ref} style={style} {...rest}>
      {children}
    </StyledCard>
  );
});

const StyledPage = styled.div`
  & > .pal-page-container,
  & > * > * > .pal-page-container {
    padding: 20px;
  }
  h6 {
    font-weight: 400;
  }
  .page-footer {
    height: 40px;
  }
  @media (max-width: 575.98px) {
    .card {
      margin-bottom: 0.75rem;
    }
  }
  margin: 10px;
  /* position: absolute; */
  /* flex */
`;

const TitleContainer = styled(Grid)`
  align-items: center;
  justify-content: center;
  cursor: ${({ collapse }) => (collapse ? "pointer" : "default")};
`;

const TitleItem = styled(Grid)`
  padding-inline-start: 0;
`;

const IconItem = styled(Grid)`
  padding-inline-end: 0;
`;

const ChildrenItem = styled(Grid)`
  padding-left: 0;
  padding-right: 0;
`;

const PalPage = ({
  collapse,
  collapseOptions,
  defaultOpen = true,
  title,
  titleClassName,
  children,
  noCard,
  className = "",
  style,
  styleCard,
  onClick,
}) => {
  const Wrapper = noCard ? React.Fragment : PalCard;
  const [open, setOpen] = useState(defaultOpen);

  const handleChangeToggle = () => {
    setOpen(!open);
  };
  return (
    <StyledPage className={`${className}`} style={style} onClick={onClick}>
      <Wrapper {...(!noCard && { style: styleCard })} className={"pal-page-container"}>
        {/* <Container fluid className="container ms-0"> */}
        {!!title && (
          <TitleContainer
            container
            className="page-footer"
            collapse={collapse}
            onClick={collapse && handleChangeToggle}
          >
            <TitleItem item xs className={titleClassName}>
              <PRTitle title={title} />
            </TitleItem>
            {collapse && (
              <IconItem item xs="auto">
                <PalIconButton size="small">{!open ? <ExpandMore /> : <ExpandLess />}</PalIconButton>
              </IconItem>
            )}
          </TitleContainer>
        )}
        <Grid container>
          <ChildrenItem item xs="12">
            {collapse ? (
              <Collapse in={open} {...collapseOptions} timeout="auto">
                {children}
              </Collapse>
            ) : (
              children
            )}
          </ChildrenItem>
        </Grid>
        {/* </Container> */}
      </Wrapper>
    </StyledPage>
  );
};

export default PalPage;
