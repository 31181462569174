import Utils from "./Utils";
import TimerWorker from "../timer.worker.js";

let isWebworkerSupported = typeof Worker !== "undefined";
if (!isWebworkerSupported) {
  console.warn("Webworker is not supported. Please use modern browser to get better performance");
}
export function setTimeoutW(callback, interval) {
  if (!isWebworkerSupported) {
    return setTimeout(callback, interval);
  }
  const randomKey = Utils.getId();
  const worker = new TimerWorker();
  window.pr_config._workers = window.pr_config._workers || [];
  window.pr_config?._workers?.push(worker);
  worker.postMessage({ key: randomKey, interval });

  worker.onmessage = function (e) {
    if (e.data === randomKey) {
      callback();
    }
  };
  return () => {
    worker.postMessage({ key: randomKey, type: "timeout_clear" });
    window.pr_config._workers = window.pr_config._workers.filter((w) => w !== worker);
  };
}

export function setIntervalW(callback, interval) {
  if (!isWebworkerSupported) {
    return setInterval(callback, interval);
  }
  const randomKey = Utils.getId();
  const worker = new TimerWorker();
  worker.postMessage({ key: randomKey, interval, type: "interval" });

  worker.onmessage = function (e) {
    if (e.data === randomKey) {
      callback();
    }
  };
  return () => {
    worker.postMessage({ key: randomKey, type: "interval_clear" });
    window.pr_config._workers = window.pr_config._workers.filter((w) => w !== worker);
  };
}
export function clearIntervalW(interval) {
  interval?.();
}
export function clearTimeoutW(interval) {
  interval?.();
}
