import { useCallback, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import useQueryParams from "~common/hooks/useQueryParams";
import ChatbotResponse from "~components/ChatbotResponse";
import store from "~store";
import { setScenarioFormState } from "~store/dialogComponents/scenarioManager/actions";
import {
  selectScenarioManager,
  selectScenarioManagerResponseMessageItems,
  selectScenarioManagerSamples,
  selectScenarioManagerText,
} from "~store/dialogComponents/scenarioManager/selectors";
import { getResources } from "~store/organization/actions";
import { selectCurrentProject } from "~store/user/selectors";

export default function Messages() {
  const responseMessageItems = useSelector(selectScenarioManagerResponseMessageItems);
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const { response } = useQueryParams();

  useEffect(() => {
    dispatch(getResources(currentProject.id));
  }, [dispatch, currentProject.id]);

  const handlePrediction = useCallback((_messageItem) => {
    const text = selectScenarioManagerText(store.getState());
    const samples = selectScenarioManagerSamples(store.getState());

    return {
      text,
      samples: samples.map((item) => item.intent_text),
    };
  }, []);

  useEffect(() => {
    if (response) {
      dispatch(
        setScenarioFormState("response.message_items", [
          {
            data_format: "plain",
            message_buttons: [],
            message_type: "Text",
            order: 1,
            text: decodeURI(response),
          },
        ])
      );
    }
  }, [dispatch, response]);

  const handleChange = useCallback(
    (messageItems) => {
      dispatch(setScenarioFormState("response.message_items", messageItems));
    },
    [dispatch]
  );

  const handleGetAiContext = useCallback(() => {
    const intentState = selectScenarioManager(store.getState());
    const context = `"You are currently on the Intent page used to create messages that the user will see in the Chatbot software. With low-code, you can send various messages to the user and receive input. The code editor window you are in is used for creating Responses. Additional information about the Intent is provided in the JSON below:

\`\`\`json
${JSON.stringify(intentState, null, 2)}
\`\`\``;

    return context;
  }, []);
  return (
    <ChatbotResponse
      getRelevantContext={handleGetAiContext}
      messageItems={responseMessageItems}
      onChange={handleChange}
      onPrediction={handlePrediction}
    />
  );
}
