import PRSwitch from "~components/Generic/PRSwitch";

import ModuleIntegrationEdit from "./ModuleIntegrationEdit";
import ModuleIntegrationList from "./ModuleIntegrationList";
function ModuleIntegration() {
  const routes = [
    { path: "/integration/module-integration/form/:id?", component: ModuleIntegrationEdit },
    { path: "/integration/module-integration/", component: ModuleIntegrationList },
    { path: "/integration", to: "/integration/module-integration", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}

export default ModuleIntegration;
