export const SET_SCENARIO_FORM_STATE = "@DIALOG_COMPONENTS/SCENARIO/SET_SCENARIO_FORM_STATE";
export const RESET_FORM = "@DIALOG_COMPONENTS/SCENARIO/RESET_FORM";
export const CREATE_OR_UPDATE_SCENARIO_MANAGER = "@DIALOG_COMPONENTS/SCENARIO/CREATE_OR_UPDATE_SCENARIO_MANAGER";

export const SET_FORM_LOADING = "@DIALOG_COMPONENTS/SCENARIO/SET_FORM_LOADING";
export const SET_FORM_SAVING = "@DIALOG_COMPONENTS/SCENARIO/SET_FORM_SAVING";
export const SET_PROCEDURE_LIST = "@DIALOG_COMPONENTS/SCENARIO/SET_PROCEDURE_LIST";
export const SET_SESSION_SAMPLE_PREDICTION = "@DIALOG_COMPONENTS/SCENARIO/SET_SESSION_SAMPLE_PREDICTION";
export const SET_INTENT_SAMPLE_PREDICTION = "@DIALOG_COMPONENTS/SCENARIO/SET_INTENT_SAMPLE_PREDICTION";
export const SET_INTENT_RESPONSE_PREDICTION = "@DIALOG_COMPONENTS/SCENARIO/SET_INTENT_RESPONSE_PREDICTION";
export const SET_VARIABLE_LIST = "@DIALOG_COMPONENTS/SCENARIO/SET_VARIABLE_LIST";
export const SET_DATABASE_LIST = "@DIALOG_COMPONENTS/SCENARIO/SET_DATABASE_LIST";
export const SET_MEDIA_LIST = "@DIALOG_COMPONENTS/SCENARIO/SET_MEDIA_LIST";
export const SET_INSIGHT = "@DIALOG_COMPONENTS/SCENARIO/SET_INSIGHT";
export const SET_INSIGHT_DATA = "@DIALOG_COMPONENTS/SCENARIO/SET_INSIGHT_DATA";

export const FETCH_SCENARIO_FORM = "@DIALOG_COMPONENTS/SCENARIO/FETCH_SCENARIO_FORM";
