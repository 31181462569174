import IFrameHelper from "~helpers/IFrameHelper";
import { safeProduce as produce } from "~helpers/immer";

import * as actionTypes from "./actionTypes";

const initState = {
  loadingPre: IFrameHelper.isWebChatMode() && !IFrameHelper.isInIFrame(),
};

const loading = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.LOADING_PRE_SHOW:
        draft.loadingPre = true;
        break;
      case actionTypes.LOADING_PRE_HIDE:
        draft.loadingPre = false;
        break;
    }
  });

export default loading;
