import { useCallback, useEffect } from "react";

import { withCardon } from "cardon";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import PRInput from "~components/Generic/PRInput";
import PRModal from "~components/Generic/PRModal";
import PRSelect from "~components/Generic/PRSelect";
import { projectLanguageOptions } from "~constants";
import {
  createOrUpdateProject,
  getProject,
  setProject,
  updateProjectSettings,
} from "~store/settings/projectSettings/actions";
import { selectProject } from "~store/settings/projectSettings/selectors";

export const CreateOrUpdateProjectModal = withCardon(
  function CreateOrUpdateProject({ get, projectId }) {
    const dispatch = useDispatch();
    const project = useSelector(selectProject);
    // const [botLanguages, setBotLanguages] = useState([]);

    useEffect(() => {
      if (projectId) {
        dispatch(getProject(projectId));
      }
      return () => {
        dispatch(setProject({}));
      };
    }, [dispatch, projectId]);

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        name: project?.name,
        chatbotLanguages: project?.settings?.chatbot_languages,
        // ...project,
      },
      validationSchema: Yup.object({
        name: Yup.string().required("Required").min(3, "Must be at least 3 characters"),
        chatbotLanguages: Yup.array().required("Required").min(1, "Must be at least 1 language"),
      }),

      onSubmit: async ({ name, chatbotLanguages }) => {
        let newProject;
        newProject = await dispatch(
          createOrUpdateProject({
            name: name,
            id: projectId,
          })
        );

        if (newProject?.id) {
          const langs = newProject.settings.chatbot_languages;
          if (
            chatbotLanguages?.length &&
            (langs?.length !== chatbotLanguages?.length ||
              !langs.every((v) => v === chatbotLanguages.find((v2) => v2 === v)))
          ) {
            dispatch(
              updateProjectSettings(newProject?.id, {
                chatbot_languages: chatbotLanguages,
              })
            );
          }
        }
        get({ name, chatbotLanguages })();
      },
    });

    const handleChangeSelect = (value) => {
      formik.setFieldValue("chatbotLanguages", value);
    };
    const handleOptionReadonly = useCallback(
      (option) => {
        return !!project?.settings?.chatbot_languages.find((item) => item === option.value);
      },
      [project?.settings?.chatbot_languages]
    );
    return (
      <PRModal
        submitText={projectId ? "Update" : "Create"}
        title={projectId ? "Edit Project" : "Create Project"}
        onClick={formik.handleSubmit}
        onClose={get(false)}
      >
        <Row className="g-2">
          <Col xs={12}>
            <Label>Name:</Label>
            <PRInput
              invalid={formik.touched.name && formik.errors.name}
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Col>

          <Col xs={12}>
            <Label>Bot Languages:</Label>
            <PRSelect
              isMulti
              isPrimitiveValue
              disabled={projectId}
              invalid={formik.touched.chatbotLanguages && formik.errors.chatbotLanguages}
              isClearable={false}
              isOptionReadonly={handleOptionReadonly}
              options={projectLanguageOptions}
              value={formik.values.chatbotLanguages}
              onChange={handleChangeSelect}
            />
          </Col>
        </Row>
      </PRModal>
    );
  },
  {
    destroyOnHide: true,
  }
);
