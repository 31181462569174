import ConstantHelper from "~helpers/ConstantHelper";

export const dialogComponentsResponseMessageTypes = {
  /** @type {"Text"} */
  text: "Text",
  /** @type {"File"} */
  file: "File",
  /** @type {"Image"} */
  image: "Image",
  /** @type {"Choice"} */
  choice: "Choice",
  /** @type {"Return to Agent"} */
  returnToAgent: "Return to Agent",
  /** @type {"Direct to Ticket"} */
  directToTicket: "Direct to Ticket",
  /** @type {"HandleReservation"} */
  handleReservation: "HandleReservation",
  /** @type {"GPTSmalltalk"} */
  gptSmalltalk: "GPTSmalltalk",
  /** @type {"GPTQuestion"} */
  gptQuestion: "GPTQuestion",
  /** @type {"WikipediaAnswer"} */
  wikipediaAnswer: "WikipediaAnswer",
  /** @type {"LowCode"} */
  lowCode: "LowCode",
  /** @type {"GoIntent"} */
  goIntent: "GoIntent",
  /** @type {"Inline"} */
  inline: "Inline",
};
export const dialogComponentsResponseMessageTypeOptions = [
  {
    label: "constants.dialogComponentsResponseMessageTypeOptions.text",
    value: dialogComponentsResponseMessageTypes.text,
  },
  {
    label: "constants.dialogComponentsResponseMessageTypeOptions.file",
    value: dialogComponentsResponseMessageTypes.file,
  },
  {
    label: "constants.dialogComponentsResponseMessageTypeOptions.image",
    value: dialogComponentsResponseMessageTypes.image,
  },
  {
    label: "constants.dialogComponentsResponseMessageTypeOptions.choice",
    value: dialogComponentsResponseMessageTypes.choice,
  },
  {
    label: "constants.dialogComponentsResponseMessageTypeOptions.returnToAgent",
    value: dialogComponentsResponseMessageTypes.returnToAgent,
  },
  {
    label: "constants.dialogComponentsResponseMessageTypeOptions.directToTicket",
    value: dialogComponentsResponseMessageTypes.directToTicket,
  },
  {
    label: "constants.dialogComponentsResponseMessageTypeOptions.handleReservation",
    value: dialogComponentsResponseMessageTypes.handleReservation,
  },
  {
    label: "constants.dialogComponentsResponseMessageTypeOptions.GPTSmalltalk",
    value: dialogComponentsResponseMessageTypes.gptSmalltalk,
  },
  {
    label: "constants.dialogComponentsResponseMessageTypeOptions.GPTQuestion",
    value: dialogComponentsResponseMessageTypes.gptQuestion,
  },
  {
    label: "constants.dialogComponentsResponseMessageTypeOptions.wikipediaAnswer",
    value: dialogComponentsResponseMessageTypes.wikipediaAnswer,
  },
  {
    label: "constants.dialogComponentsResponseMessageTypeOptions.lowCode",
    value: dialogComponentsResponseMessageTypes.lowCode,
  },
  {
    label: "constants.dialogComponentsResponseMessageTypeOptions.goIntent",
    value: dialogComponentsResponseMessageTypes.goIntent,
  },
];

export const dialogComponentsMessageChoiceTypes = {
  goMessage: "Go Message Item",
  goIntent: "Go Intent",
};

export const dialogComponentsVariableTypes = {
  STR: "STR",
  INT: "INT",
  FLOAT: "FLOAT",
  JSON: "JSON",
};

export const dialogComponentsVariableTypeOptions = [
  { label: "constants.dialogComponentsVariableTypeOptions.string", value: dialogComponentsVariableTypes.STR },
  { label: "constants.dialogComponentsVariableTypeOptions.integer", value: dialogComponentsVariableTypes.INT },
  { label: "constants.dialogComponentsVariableTypeOptions.float", value: dialogComponentsVariableTypes.FLOAT },
  { label: "constants.dialogComponentsVariableTypeOptions.JSON", value: dialogComponentsVariableTypes.JSON },
];

export const dialogComponentsAuthType = {
  /** @type {"ANONYMOUS"} */
  ANONYMOUS: "ANONYMOUS",
  /** @type {"FAMILIAR"} */
  FAMILIAR: "FAMILIAR",
  /** @type {"AUTHENTICATED"} */
  AUTHENTICATED: "AUTHENTICATED",
};

export const dialogComponentsAuthTypeOptions = [
  { label: "constants.dialogComponentsAuthTypeOptions.anonymous", value: dialogComponentsAuthType.ANONYMOUS },
  { label: "constants.dialogComponentsAuthTypeOptions.familiar", value: dialogComponentsAuthType.FAMILIAR },
  {
    label: "constants.dialogComponentsAuthTypeOptions.authenticated",
    value: dialogComponentsAuthType.AUTHENTICATED,
  },
];

export const [
  dialogComponentsEntityTypeFamily,
  dialogComponentsEntityTypeFamilyOptions,
  dialogComponentsEntityTypeFamilyMap,
] = ConstantHelper.typify({
  /** @type {"Text"} */
  Text: "constants.dialogComponentsEntityTypeFamily.text",
  /** @type {"Email"} */
  Email: "constants.dialogComponentsEntityTypeFamily.email",
  /** @type {"Date"} */
  Date: "constants.dialogComponentsEntityTypeFamily.date",
  /** @type {"DateRange"} */
  DateRange: "constants.dialogComponentsEntityTypeFamily.dateRange",
  /** @type {"File"} */
  File: "constants.dialogComponentsEntityTypeFamily.file",
  /** @type {"CustomType"} */
  CustomType: "constants.dialogComponentsEntityTypeFamily.customType",
  /** @type {"Select"} */
  Select: "constants.dialogComponentsEntityTypeFamily.select",
  /** @type {"Range"} */
  Range: "constants.dialogComponentsEntityTypeFamily.range",
  /** @type {"PhoneNumber"} */
  PhoneNumber: "constants.dialogComponentsEntityTypeFamily.phoneNumber",
  /** @type {"OnlyNumbers"} */
  OnlyNumbers: "constants.dialogComponentsEntityTypeFamily.onlyNumbers",
});

export const [
  dialogComponentsEntityTypePredictionType,
  dialogComponentsEntityTypePredictionTypeOptions,
  dialogComponentsEntityTypePredictionTypeMap,
] = ConstantHelper.typify({
  /** @type {"AI"} */
  AI: "constants.dialogComponentsEntityTypePredictionType.AI",
  /** @type {"SS"} */
  SS: "constants.dialogComponentsEntityTypePredictionType.SS",
});

export const [
  dialogComponentsEntityTypeDateFormatType,
  dialogComponentsEntityTypeDateFormatTypeOptions,
  dialogComponentsEntityTypeDateFormatTypeMap,
] = ConstantHelper.typify({
  /** @type {"date"} */
  date: "constants.dialogComponentsEntityTypeDateFormatTypeOptions.date",
  /** @type {"time"} */
  time: "constants.dialogComponentsEntityTypeDateFormatTypeOptions.time",
  /** @type {"datetime"} */
  datetime: "constants.dialogComponentsEntityTypeDateFormatTypeOptions.datetime",
});

export const [dialogComponentsIntentType, dialogComponentsIntentTypeOptions, dialogComponentsIntentTypeMap] =
  ConstantHelper.typify({
    /** @type {"STANDARD"} */
    STANDARD: "constants.dialogComponentsIntentTypeOptions.standard",
    /** @type {"RAG"} */
    RAG: "constants.dialogComponentsIntentTypeOptions.rag",
  });

export const [
  dialogComponentsRagFactGroupStatus,
  dialogComponentsRagFactGroupStatusOptions,
  dialogComponentsRagFactGroupStatusMap,
] = ConstantHelper.typify({
  /** @type {"Fresh"} */
  Fresh: "constants.dialogComponentsRagFactGroupStatusOptions.fresh",
  /** @type {"Processed"} */
  Processed: "constants.dialogComponentsRagFactGroupStatusOptions.processed",
  /** @type {"Processing"} */
  Processing: "constants.dialogComponentsRagFactGroupStatusOptions.processing",
  /** @type {"Modified"} */
  Modified: "constants.dialogComponentsRagFactGroupStatusOptions.modified",
  /** @type {"Error"} */
  Error: "constants.dialogComponentsRagFactGroupStatusOptions.error",
});
