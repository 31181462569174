import { get } from "lodash";

import SanitizeHelper from "./SanitizeHelper";
import Utils from "./Utils";
let latestPopupSizeMode = "icon";
export default class IFrameHelper {
  static getIFrameWindow(iframe) {
    return iframe.contentWindow || iframe.contentDocument.parentWindow;
  }

  static listenForMessage(type, payload, listenValidator) {
    return new Promise((resolve) => {
      const urlQueryObj = new URLSearchParams(window.location.search);
      const postOrigin = SanitizeHelper.safeUrl(urlQueryObj.get("origin") || window.location.origin);
      window.parent.postMessage({ type, payload }, postOrigin);

      const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
      const eventer = window[eventMethod];
      const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

      const callbackType = `${type}-done`;
      eventer(
        messageEvent,
        function (e) {
          const key = e.message ? "message" : "data";
          const data = e[key];
          if (data && typeof data === "object" && data.type === callbackType) {
            if (listenValidator && typeof listenValidator === "function") {
              const validateResult = listenValidator(data.payload);
              if (!validateResult) {
                return;
              }
            }
            resolve(data.payload);
          }
        },
        false
      );
    });
  }

  static events = {};
  static listenForEvents(id) {
    const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    const eventerFunc = function (e) {
      const key = e.message ? "message" : "data";
      const data = e[key];
      if (data && typeof data === "object" && data.type === id) {
        const { id, isEvent, values } = data.payload;
        if (isEvent && IFrameHelper.events[id]) {
          IFrameHelper.events[id]({ id, values });
        }
      }
    };
    eventer(messageEvent, eventerFunc, false);
  }

  /** @returns {"inline" | null} */
  static getMode() {
    const url = new URL(window.location.href);
    const modeQuery = url.searchParams.get("mode");
    return modeQuery;
  }
  static isInIFrame() {
    let inIframe = false;
    try {
      inIframe = window.self !== window.top;
    } catch (e) {
      inIframe = true;
    }

    const url = new URL(window.location.href);
    const modeQuery = url.searchParams.get("mode");
    return inIframe && modeQuery !== "inline";
  }
  static isTryChatbot() {
    const url = new URL(window.location.href);
    const modeQuery = url.searchParams.get("mode");
    return modeQuery === "inline";
  }
  static isInIFrameOrTryChatbotOrPreview() {
    const previewContainerId = "preview-chatbot-container";

    const dom = document.getElementById(previewContainerId);
    if (dom) return true;

    let inIframe = false;
    try {
      inIframe = window.self !== window.top;
    } catch (e) {
      inIframe = true;
    }

    const url = new URL(window.location.href);
    const modeQuery = url.searchParams.get("mode");
    return inIframe || modeQuery === "inline";
  }
  static isWebChatMode() {
    const url = new URL(window.location.href);
    const pathnameChunks = url.pathname.split("---"); //for handling dynamic manifest links
    const firstPathnameChunk = pathnameChunks?.[0] || url.pathname;

    const isWebchatMode =
      firstPathnameChunk === `${process.env.PUBLIC_URL}/webchat` ||
      window.location.href.startsWith(`${url.origin}/webchat/`);
    return isWebchatMode;
  }

  static classListAsync(method, classList = [], isPreviewMode = false) {
    if (!IFrameHelper.isInIFrame() && !isPreviewMode) return;
    const previewContainerId = "preview-chatbot-container";
    if (isPreviewMode) {
      const previewContainer = document.getElementById(previewContainerId);
      if (previewContainer?.classList) {
        previewContainer.classList[method](...classList);
      }
      return Promise.resolve();
    }

    return IFrameHelper.listenForMessage("pr-update-class", { method, classList });
  }

  static setPropertyToRoot(keyValueArray) {
    const setProperty = (key, value) => document.documentElement.style.setProperty(key, value);
    for (const [key, value] of keyValueArray) {
      setProperty(key, value);
    }

    if (!IFrameHelper.isInIFrame()) {
      return;
    }

    return IFrameHelper.listenForMessage("pr-set-property", { keyValueArray });
  }
  /**
   * @param {"icon" | "iconWelcome" | "chat" | "wide"} mode
   * @param {boolean} isPreviewMode
   * @param {"mobile" | "desktop"} displayMode
   * @param {(typeof popupPosition)[keyof typeof popupPosition]} position
   * @returns {Promise<void>}
   */

  static download(url, fileName) {
    if (!IFrameHelper.isInIFrame()) return;
    return IFrameHelper.listenForMessage("pr-download", { url, fileName });
  }

  static getTitle() {
    if (!IFrameHelper.isInIFrame()) {
      return document.title;
    }
    return IFrameHelper.listenForMessage("pr-get-title");
  }

  static setTitle(title) {
    if (!IFrameHelper.isInIFrame()) {
      document.title = title;
      return;
    }
    return IFrameHelper.listenForMessage("pr-set-title", title);
  }

  static async checkStorage() {
    window.__pp_storage_mode = "none";

    let isCurrentValid = false;

    const testKey = "test";
    try {
      const storage = window.localStorage;
      storage.setItem(testKey, "1");
      storage.removeItem(testKey);
      isCurrentValid = true;
    } catch (error) {
      isCurrentValid = false;
    }

    if (!IFrameHelper.isInIFrame()) {
      return isCurrentValid;
    }

    if (isCurrentValid) {
      window.__pp_storage_mode = "current";
      return true;
    }

    const result = await IFrameHelper.listenForMessage("pr-check-storage");
    if (result) {
      window.__pp_storage_mode = "parent";
    }
    return result;
  }

  static getStorage(key) {
    if (!IFrameHelper.isInIFrame() || window.__pp_storage_mode === "current") {
      return localStorage.getItem(key);
    }
    return IFrameHelper.listenForMessage("pr-get-storage", { key });
  }

  static setStorage(key, value) {
    if (!IFrameHelper.isInIFrame() || window.__pp_storage_mode === "current") {
      return localStorage.setItem(key, value);
    }

    return IFrameHelper.listenForMessage("pr-set-storage", { key, value });
  }

  static removeStorage(key) {
    if (!IFrameHelper.isInIFrame() || window.__pp_storage_mode === "current") {
      return localStorage.removeItem(key);
    }

    return IFrameHelper.listenForMessage("pr-remove-storage", { key });
  }

  static clearStorage() {
    if (!IFrameHelper.isInIFrame() || window.__pp_storage_mode === "current") {
      return localStorage.clear();
    }

    return IFrameHelper.listenForMessage("pr-clear-storage");
  }
  /**
   * @returns {Promise<{
   *   href: string;
   *   referrer: string;
   * }>} - Href
   */
  static getLocationInfo() {
    if (!IFrameHelper.isInIFrame()) {
      return {
        href: window.location.href,
        referrer: document.referrer,
      };
    }

    return IFrameHelper.listenForMessage("pr-get-location");
  }
  static getCookies() {
    if (!IFrameHelper.isInIFrame()) {
      return document.cookie;
    }
    return IFrameHelper.listenForMessage("pr-get-cookies");
  }

  static fetch(url, options) {
    if (!IFrameHelper.isInIFrame()) {
      return fetch(url, options);
    }

    return IFrameHelper.listenForMessage("pr-fetch", { url, options });
  }

  /**
   * @param {"onBeforeAuth" | "onBeforeLogin" | "onTerminated"} eventName - Event name
   * @param {...any} args - Arguments to pass
   * @returns {Promise<{
   *   executed: boolean;
   *   data: any;
   * }>} - Executed event response
   */
  static async execEvent(eventName, ...args) {
    if (!IFrameHelper.isInIFrame()) {
      return {
        executed: false,
        data: null,
      };
    }
    const validator = (payload) => {
      return payload?.event === eventName;
    };

    const response = await IFrameHelper.listenForMessage("pr-event-action", { event: eventName, args }, validator);
    if (response.data instanceof Error) {
      console.error(response.data);
    }
    return response;
  }
  static addEventListener(name, callback, selectors = [], options = {}) {
    if (!IFrameHelper.isInIFrame()) {
      return window.addEventListener(name, callback, options);
    }
    const eventId = Utils.getId();
    IFrameHelper.events[eventId] = callback;
    IFrameHelper.listenForEvents(eventId);
    return IFrameHelper.listenForMessage("pr-listen", {
      eventName: name,
      eventOptions: options,
      id: eventId,
      selectors,
    });
  }
  static removeEventListener(name, callback, options) {
    if (!IFrameHelper.isInIFrame()) {
      return window.removeEventListener(name, callback, options);
    }
    const eventId = Object.keys(IFrameHelper.events).find((e) => IFrameHelper.events[e] === callback);
    if (eventId) {
      delete IFrameHelper.events[eventId];
    }
    return IFrameHelper.listenForMessage("pr-stop-listen", { eventName: name, eventOptions: options, id: eventId });
  }
  static getFromWindow(selector) {
    if (!IFrameHelper.isInIFrame()) {
      return Promise.resolve(get(window, selector));
    }
    return IFrameHelper.listenForMessage("pr-window", { selector });
  }

  static listenWebchatDomEvents(callback) {
    if (!IFrameHelper.isInIFrame()) return;
    const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    /*
    
      _pp_iframe.contentWindow.postMessage(
            {
              type: "pr-dom-action",
              payload: {
                event: attrAction,
                args: [attrActionValue],
              },
            },
            window.location.origin
          );
          
          */

    const eventerFunc = function (e) {
      const key = e.message ? "message" : "data";
      const data = e[key];
      if (e.target?.origin !== window.location.origin) return;
      if (data && typeof data === "object" && data.type === "pr-dom-action") {
        callback(data.payload);
      }
    };
    eventer(messageEvent, eventerFunc, false);
    return () => {
      window.removeEventListener(messageEvent, eventerFunc);
    };
  }
}
