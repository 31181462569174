import { useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as ErrorSvg } from "~assets/images/alert-error.svg";
import { ReactComponent as InfoSvg } from "~assets/images/alert-info.svg";
import { ReactComponent as SuccessSvg } from "~assets/images/alert-success.svg";
import { ReactComponent as WarningSvg } from "~assets/images/alert-warning.svg";
import IFrameHelper from "~helpers/IFrameHelper";
import { alertRemove, alertSetList } from "~store/actions";
import { selectAlertDom, selectAlertList } from "~store/helpers/alert/selectors";
import { selectSidebar } from "~store/layout/selectors";

import "./style.scss";

// const StyledMaterialDesignContent = styled(MaterialDesignContent)`
//   width: 99.5%;

//   // "&.notistack-MuiContent-success": {
//   //   backgroundColor: "#2D7738",
//   //   border: "1122px solid #00ff00",
//   // },
//   // "&.notistack-MuiContent-error": {
//   //   backgroundColor: "#970C0C",
//   // },
// `;
// const MaterialDesignContentWrapper = (props) => {
//   console.log("MaterialDesignContentWrapper", props);
//   return (
//     <StyledMaterialDesignContent>
//       <MaterialDesignContent {...props} />
//     </StyledMaterialDesignContent>
//   );
// };

function AlertConsumer({ stopAlerts }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const alertList = useSelector(selectAlertList);

  useEffect(() => {
    if (!alertList?.length || stopAlerts) return;

    let newAlerts = [...alertList];
    let isModified = false;
    for (const alertItem of alertList) {
      if (alertItem.dispatched) continue;
      isModified = true;
      const { key, message, type, ...rest } = alertItem;
      if (type === "close") {
        newAlerts = newAlerts.filter((alert) => alert.key !== key);
        closeSnackbar(key);
      } else {
        newAlerts = newAlerts.map((alert) => (alert.key === key ? { ...alert, dispatched: true } : alert));
        enqueueSnackbar(message, {
          variant: type,
          key: key,
          autoHideDuration: 4500,
          ...(IFrameHelper.isWebChatMode()
            ? { anchorOrigin: { vertical: "top", horizontal: "center" } }
            : { anchorOrigin: { vertical: "bottom", horizontal: "left" } }),
          ...rest,
          onExited: () => {
            dispatch(alertRemove(key));
          },
        });
      }
    }
    if (isModified) {
      dispatch(alertSetList(newAlerts));
    }
  }, [alertList, dispatch, enqueueSnackbar, closeSnackbar, stopAlerts]);

  return null;
}

function PRAlertProvider({ ...rest }) {
  const alertDom = useSelector(selectAlertDom);
  const [renderKey, setRenderKey] = useState(1);

  const sidebar = useSelector(selectSidebar);

  const targetDom = useMemo(() => {
    setRenderKey((prev) => prev + 1);
    if (IFrameHelper.isWebChatMode()) {
      return alertDom;
    }
    return document.getElementById("root");
  }, [alertDom]);

  if (IFrameHelper.isInIFrame()) return null;

  const isChatMode = IFrameHelper.isWebChatMode();

  return (
    <SnackbarProvider
      key={renderKey}
      iconVariant={{
        success: <SuccessSvg />,
        error: <ErrorSvg />,
        warning: <WarningSvg />,
        info: <InfoSvg />,
      }}
      maxSnack={isChatMode ? 5 : 4}
      preventDuplicate={true}
      {...rest}
      classes={{
        containerRoot: classNames("pr-alert-provider", {
          "pr-alert-provider-webchat": isChatMode,
          "sidebar-open": !sidebar.mobile && !sidebar.minimized,
          "sidebar-closed": !sidebar.mobile && sidebar.minimized,
        }),
      }}
      {...(isChatMode && {
        domRoot: targetDom,
      })}
    >
      <AlertConsumer stopAlerts={!targetDom} />
    </SnackbarProvider>
  );
}

export default PRAlertProvider;
