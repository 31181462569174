import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

export const initState = {
  /**
   * @type {{
   *   id: number;
   *   firstname: string;
   *   lastname: string;
   *   role_title: string;
   *   email: string;
   *   company: number;
   *   username: string;
   *   roles: (keyof typeof import("~constants").userRole)[];
   * }[]}
   */
  permissionUserList: [],
};

const popupSettings = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_PERMISSION_USER_LIST:
        draft.permissionUserList = action.payload;
    }
  });

export default popupSettings;
