import { DIALOG_SHOW, DIALOG_CLOSE, DIALOG_UPDATE } from "./actionTypes";

export const dialogShow = (payload) => ({
  type: DIALOG_SHOW,
  payload,
});
export const dialogUpdate = (currentDialog, props) => ({
  type: DIALOG_UPDATE,
  payload: {
    old: currentDialog,
    props,
  },
});

export const dialogClose = (payload) => ({
  type: DIALOG_CLOSE,
  payload,
});
