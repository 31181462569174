import Utils from "~helpers/Utils";
import store from "~store";

import { broadcastActionTypes } from ".";
import { onBroadcastMessage } from "../actions";

// polyfill if BroadcastChannel is not supported
if (!window.BroadcastChannel) {
  window.BroadcastChannel = function BroadcastChannel() {
    return {
      postMessage: () => {},
      close: () => {},
      addEventListener: () => {},
      removeEventListener: () => {},
    };
  };
}

const initializeTabId = () => {
  if (!window.tabId) {
    window.tabId = Utils.getId();
  }
};
initializeTabId();

const getTabId = () => {
  return window.tabId;
};

export const chatbotBroadcastChannel = new BroadcastChannel("pr-livechat");
// Current initializing multiple iframe for chatbot, so we need to listen only from webchat iframe.

chatbotBroadcastChannel.addEventListener("message", (event) => {
  //check origin to be sure it's from the same domain
  if (event.origin !== window.location.origin || event.data?.id === getTabId()) {
    return;
  }

  store.dispatch(onBroadcastMessage(event.data));
});

export const syncLivechatOptions = (settingsPayload) => {
  const clonedObj = JSON.parse(JSON.stringify(settingsPayload));
  chatbotBroadcastChannel.postMessage({
    type: broadcastActionTypes.syncLivechatOptions,
    id: getTabId(),
    payload: clonedObj,
  });
};
