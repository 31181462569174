import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

/**
 * @typedef {{
 *   id?: number;
 *   data: string;
 *   title: string;
 *   project: number;
 *   owner: number;
 *   owner_name?: string;
 *   created?: string;
 * }} DefaultMessage
 */
export const initState = {
  /** @type {DefaultMessage} */
  defaultMessage: {},
};

const knowledgeBase = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_DEFAULT_MESSAGE:
        draft.defaultMessage = action.payload;
        break;
    }
  });

export default knowledgeBase;
