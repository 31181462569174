import PRSwitch from "~components/Generic/PRSwitch";

import TicketCategoryEdit from "./TicketCategoryEdit";
import TicketCategoryList from "./TicketCategoryList";

export default function TicketCategory() {
  const routes = [
    { path: "/helpdesk/ticket-category/form/:id?", component: TicketCategoryEdit },
    { path: "/helpdesk/ticket-category", component: TicketCategoryList },
  ];
  return <PRSwitch routes={routes} />;
}
