// import "../../webchat.assets/slick/slick-theme.css";

import styled from "@emotion/styled";

import PalTooltip from "~components/mui/PalTooltip";
import DateHelper from "~helpers/DateHelper";

const StyledDiv = styled.div`
  text-align: center;
  font: normal normal normal 18px/20px Museo Sans;
  letter-spacing: 0px;
  /* color: #838383; */
  color: ${({ theme }) => theme.palette.chat?.body?.color};

  .notification-message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Separator = styled.div`
  width: 220px;
  height: 0px;

  border: 1px solid #e8e8e8;
  opacity: 1;
`;

const Text = styled.div`
  margin: 25px 0px;
  height: 22px;
`;

export default function NotificationMessage({ text, messageTime, children }) {
  const isCustomRender = typeof text === "function";

  const isReactComponent = typeof text === "object" && text !== null && text.$$typeof;
  return (
    <StyledDiv>
      {!children && (isCustomRender || isReactComponent) ? (
        isCustomRender ? (
          text({ messageTime })
        ) : (
          text
        )
      ) : (
        <PalTooltip
          placement="bottom"
          title={messageTime ? DateHelper.getDateTimeLocal(messageTime).format("HH:mm") : ""}
        >
          <div className="notification-message-container">
            <Separator />
            <Text>{children || text}</Text>
            <Separator />
          </div>
        </PalTooltip>
      )}
    </StyledDiv>
  );
}
