import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

export const initState = Object.freeze({
  dictionary: {
    id: 0,
    key: "",
    values: [],
    project: 0,
    bot: 0,
  },
});

const dictionaries = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_DICTIONARY:
        draft.dictionary = action.payload;
        break;
    }
  });

export default dictionaries;
