export default class StyledHelper {
  static sfw = (propList) => {
    return {
      shouldForwardProp: (prop) => {
        const propListArr = Array.isArray(propList) ? propList : [propList];
        return !propListArr.includes(prop);
      },
    };
  };
}
