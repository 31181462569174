import { useEffect, useRef } from "react";

import { useMonaco } from "@monaco-editor/react";

const stylePrefix = "monacoCollapsibleDecorator__";
/** @param {import("@monaco-editor/react").Monaco} editorBase */
export default function useMonacoCollapsibleDecorator(editorBase) {
  const monaco = useMonaco();
  const editor = editorBase?.getEditorType() === "vs.editor.IDiffEditor" ? editorBase.getModifiedEditor() : editorBase;

  const decoratorRef = useRef(null);
  useEffect(() => {
    if (!editor || !monaco) return;

    const disposableList = [];
    const model = editor.getModel();

    const style = document.createElement("style");
    style.innerHTML = `
      .${stylePrefix}foldingBackground {
        background-color: rgba(0, 0, 180, 0.075) !important; /* Hafif mavi arka plan */
      }
    `;
    document.head.appendChild(style);
    disposableList.push({ dispose: () => document.head.removeChild(style) });

    let innerDisposableList = [];
    const updateCollapsibleRegions = () => {
      if (innerDisposableList.length > 0) {
        innerDisposableList.forEach((d) => d?.dispose?.());
        innerDisposableList = [];
      }
      if (!model) return;

      const text = model.getValue();
      const lines = text.split(/\r?\n/);
      let foldingRanges = [];
      let newDecorations = [];

      const separatePoint = [];
      let firstTextExistingPoint = null;
      let lastTextExistingPoint = null;
      for (let i = 0; i < lines.length; i++) {
        if (lines[i].trim() !== "" && firstTextExistingPoint === null) {
          firstTextExistingPoint = i + 1;
        }
        if (lines[i].trim() !== "") {
          lastTextExistingPoint = i + 1;
        }
        const isSeparator = lines[i] === "";
        // const isPreviousLineSeparator = i > 0 && lines[i - 1] === "";
        const nextLineHasText = i < lines.length - 1 && lines[i + 1].trim() !== "";

        if (isSeparator && nextLineHasText) {
          let isInitialLine = false;
          if (!firstTextExistingPoint) {
            const isPreviousAllLinesAreEmpty = lines.slice(0, i).every((line) => line.trim() === "");
            if (isPreviousAllLinesAreEmpty) {
              firstTextExistingPoint = i + 2;
              isInitialLine = true;
            }
          }
          if (!isInitialLine) {
            separatePoint.push(i + 1);
          }
        }
      }

      separatePoint.unshift(firstTextExistingPoint || 1);
      separatePoint.push(lastTextExistingPoint || lines.length);

      for (let i = 0; i < separatePoint.length; i += 1) {
        const additionOffset = i > 0 ? 1 : 0;
        if (i + 1 >= separatePoint.length) break;
        const startVal = separatePoint[i] + additionOffset;
        let endVal; // separatePoint[i + 1] - 1;
        //find following next two blank lines
        for (let j = startVal; j < lines.length; j += 1) {
          if (lines[j].trim() === "") {
            // if (lines?.[j + 1]?.trim() === "") {
            endVal = j;
            break;
            // }
          }
        }
        if (!endVal) {
          endVal = separatePoint[i + 1];
        }
        foldingRanges.push({
          start: startVal,
          end: Math.max(startVal, endVal),
          kind: monaco.languages.FoldingRangeKind.Region,
        });
        newDecorations.push({
          range: new monaco.Range(startVal, 1, Math.max(startVal, endVal), 1),
          options: {
            isWholeLine: true,
            className: `${stylePrefix}foldingBackground`,

            overviewRuler: {
              color: "rgba(0, 0, 180, 0.3)",
              position: monaco.editor.OverviewRulerLane.Right,
            },
          },
        });
      }

      if (foldingRanges.length > 0) {
        innerDisposableList.push(
          monaco.languages.registerFoldingRangeProvider(model.getLanguageId(), {
            provideFoldingRanges: () =>
              foldingRanges.map(({ start, end }) => ({
                start,
                end,
                kind: monaco.languages.FoldingRangeKind.Region,
              })),
          })
        );

        editor.updateOptions({
          folding: true,
        });

        decoratorRef.current?.clear();
        decoratorRef.current = editor.createDecorationsCollection(newDecorations);
        innerDisposableList.push({ dispose: () => decoratorRef.current?.clear() });
      }
    };

    updateCollapsibleRegions();
    disposableList.push(editor.onDidChangeModelContent(updateCollapsibleRegions));

    return () => {
      disposableList.forEach((d) => d.dispose());
      innerDisposableList.forEach((d) => d?.dispose?.());
    };
  }, [editor, monaco]);
}
