import { cloneDeep } from "lodash";

import FileHelper from "./FileHelper";
import TokenHelper from "./TokenHelper";

const baseManifest = {
  id: "com.palmate.app.chatbot.",
  scope: "/",
  start_url: "/",
  orientation: "any",
  categories: ["utility"],
  display_override: ["window-controls-overlay", "standalone", "browser"],
  display: "standalone",
  theme_color: "#28aae1",
  background_color: "#ffffff",
  icons: [],
  screenshots: [
    {
      src: "/static/webchat.jpg",
      sizes: "1080x1920",
      type: "image/jpeg",
    },
  ],
};

const manifestId = "manifest-placeholder";
export default class ManifestHelper {
  static ApplyDefaultManifest = () => {
    const manifestDom = document.getElementById(manifestId);
    if (manifestDom) {
      manifestDom.href = "/manifest.json";
    } else {
      const link = document.createElement("link");
      link.rel = "manifest";
      link.id = manifestId;
      link.href = "/manifest.json";
      document.head.appendChild(link);
    }
  };
  static ApplyDynamicManifest = async (token, welcomeFormObj) => {
    const alias = TokenHelper.getChatbotAlias();
    const scope = alias ? `/webchat/${alias}/` : `/webchat---${token}/`;
    const startUrl = alias ? `/webchat/${alias}/` : `/webchat---${token}?token=${token}`;

    try {
      //Change favicon icon dynamically
      const faviconIconPath = welcomeFormObj?.popup_header_icon_path;
      if (faviconIconPath) {
        const faviconDom = document.getElementById("favicon");
        if (faviconDom) {
          faviconDom.href = faviconIconPath;
        } else {
          const link = document.createElement("link");
          link.rel = "icon";
          link.id = "favicon";
          link.href = faviconIconPath;
          document.head.appendChild(link);
        }
      }

      const manifestJson = cloneDeep(baseManifest);
      manifestJson.id += token;
      manifestJson.scope = scope;
      manifestJson.start_url = startUrl;
      manifestJson.name = `${welcomeFormObj?.popup_title} Bot`.normalizeAccent(" ", true);
      manifestJson.short_name = `${welcomeFormObj?.popup_title}`.normalizeAccent(" ", true);
      manifestJson.description = `${welcomeFormObj?.popup_title} Bot`.normalizeAccent(" ", true);
      manifestJson.theme_color = welcomeFormObj?.popup_primary_color;

      const pwaIconPath = welcomeFormObj?.popup_button_icon_path || welcomeFormObj?.popup_header_icon_path;
      if (pwaIconPath) {
        const img = await FileHelper.getImage(pwaIconPath);
        manifestJson.icons.push({
          src: pwaIconPath,
          type: "image/png",
          sizes: `${img.width}x${img.height}`,
          purpose: "any",
        });

        const base64Str = btoa(JSON.stringify(manifestJson));
        const manifestUrl = `/api/metadata?json=${base64Str}`;
        const oldManifestDom = document.getElementById(manifestId);
        if (oldManifestDom) {
          oldManifestDom.href = manifestUrl;
        } else {
          const link = document.createElement("link");
          link.rel = "manifest";
          link.href = manifestUrl;
          document.head.appendChild(link);
        }
      } else {
        console.log("PWA feature is not enabled due to missing icon", welcomeFormObj);
      }
    } catch (error) {
      console.log("PWA Manifest Error", error);
    }
  };
}
