import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { parseExpression } from "cron-parser";
import cronstrue from "cronstrue";
import { cloneDeep } from "lodash";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { BiLoaderAlt } from "react-icons/bi";
import {
  MdCheck,
  MdCheckCircle,
  MdContentCopy,
  MdEdit,
  MdOutlinePersonSearch,
  MdOutlineTimer,
  MdOutlineTimerOff,
  MdSchedule,
  MdTimer,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Alert, Col, FormGroup, Label, Row } from "reactstrap";

import useProjectChange from "~common/hooks/useProjectChange";
import useQueryParams from "~common/hooks/useQueryParams";
import CreateAsTemplateModal from "~common/modals/CreateAsTemplateModal";
import CrontabEditorModal from "~common/modals/CrontabEditorModal";
import { PRAccordionItem } from "~components/Generic/PRAccordion";
import PRAccordion from "~components/Generic/PRAccordion/PRAccordionBody";
import PRButton from "~components/Generic/PRButton";
import PRChart from "~components/Generic/PRChart";
import PRContainer from "~components/Generic/PRContainer";
import PRDate from "~components/Generic/PRDate";
import { PRDividerLabel } from "~components/Generic/PRDivider";
import PRFormGroup from "~components/Generic/PRFormGroup";
import PRInput from "~components/Generic/PRInput";
import PRLink from "~components/Generic/PRLink";
import PRPage from "~components/Generic/PRPage";
import PRSelect from "~components/Generic/PRSelect";
import PRTable from "~components/Generic/PRTable";
import {
  organizationAnnouncementFilterType,
  organizationAnnouncementPeriodicStatus,
  organizationAnnouncementStatus,
  organizationAnnouncementStatusMap,
  organizationAnnouncementStatusOptions,
  tableFilterStorageKey,
} from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import Utils from "~helpers/Utils";
import ActionRule from "~pages/Organization/Actions/ActionsEdit/ActionRule";
import { validateFilterObject } from "~pages/Organization/Filter/FilterEdit";
import FilterRule from "~pages/Organization/Filter/FilterEdit/FilterRule";
import { createOrUpdateProcedure } from "~store/dialogComponents/scenarioManager/actions";
import {
  createOrUpdateAction,
  createOrUpdateAnnouncement,
  createOrUpdateAnnouncementVersion,
  createOrUpdateFilter,
  getAction,
  getActionList,
  getAnnouncement,
  getAnnouncementVersion,
  getFilter,
  getFilterList,
  getSurvey,
  getSurveyList,
  sendAnnouncementAction,
  sendAnnouncementCancelAction,
  setAction,
  setAnnouncement,
  setAnnouncementVersion,
  setFilter,
} from "~store/organization/actions";
import { selectAnnouncement, selectAnnouncementVersion } from "~store/organization/selectors";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

import AnnouncementVersionMembersModal from "./AnnouncementVersionMembersModal";

const announcementVersionInitial = {
  id: null,
  action: null,
  filter: null,
  version_id: 1,
  is_periodic: false,
  time_interval: null,
  valid_until: DateHelper.getDateTime(),
  send_at: null,
  status: organizationAnnouncementStatus.DRAFT,
  periodic_status: organizationAnnouncementPeriodicStatus.ACTIVE,
  cron_schedule: "",
};

const barColors = ["#5470C6", "#91CC75", "#FAC858", "#73C0DE", "#EE6666", "#FC8452", "#3BA272"];

const quartzToUnixCron = (quartzCron) => {
  const [seconds, minutes, hours, dayOfMonth, month, dayOfWeek] = quartzCron.split(" ");

  const daysOfWeekQuartzToUnix = {
    SUN: "0",
    MON: "1",
    TUE: "2",
    WED: "3",
    THU: "4",
    FRI: "5",
    SAT: "6",
    "?": "*",
    "*": "*",
  };

  const convertedDayOfWeek = dayOfWeek
    .split(",")
    .map((day) => daysOfWeekQuartzToUnix[day.toUpperCase()])
    .join(",");

  const monthsQuartzToUnix = {
    JAN: "1",
    FEB: "2",
    MAR: "3",
    APR: "4",
    MAY: "5",
    JUN: "6",
    JUL: "7",
    AUG: "8",
    SEP: "9",
    OCT: "10",
    NOV: "11",
    DEC: "12",
    "?": "*",
    "*": "*",
  };

  const convertedMonth = month
    .split(",")
    .map((month) => monthsQuartzToUnix[month.toUpperCase()])
    .join(",");

  return `${minutes} ${hours} ${dayOfMonth} ${convertedMonth} ${convertedDayOfWeek}`;
};

const announcementPeriodLimit = 100;
export default function AnnouncementEdit() {
  const { t } = useTranslation();
  const { survey: surveyQueryIdProp, survey_name: surveyQueryName } = useQueryParams();
  const { id } = useParams();

  const surveyQueryId = surveyQueryIdProp ? Number(surveyQueryIdProp) : null;
  const dispatch = useDispatch();
  const actionRef = useRef();

  const [lowCodeData, setLowCodeData] = useState(null);
  const [sendNow, setSendNow] = useState(false);
  const [showSelectedHistory, setShowSelectedHistory] = useState(false);
  const [cronjobDateList, setCronjobDateList] = useState([]);

  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const announcement = useSelector(selectAnnouncement);
  const announcementVersion = useSelector(selectAnnouncementVersion);
  const announcementVersionChartRef = useRef();

  useProjectChange(() => {
    HistoryHelper.push("/organization/announcement", { scope: "dashboard" });
  }, []);

  const getAnnouncementVersionWithActionAndFilter = async (announcementId, announcementVersionId, options) => {
    const response = await dispatch(
      getAnnouncementVersion(currentProject.id, announcementId, announcementVersionId, {
        onSuccess: () => {},
        ...options,
      })
    );

    const action = await dispatch(getAction(response.action?.id || response.action, currentProject.id, options));
    const filter = await dispatch(getFilter(response.filter?.id || response.filter, currentProject.id, options));

    dispatch(setAnnouncementVersion({ ...response, action, filter }));
  };

  useEffect(() => {
    if (!id) {
      let payload = null;
      if (surveyQueryId) {
        payload = { survey: surveyQueryId };
      }
      dispatch(setAnnouncement(payload));
      dispatch(
        setAnnouncementVersion({
          ...announcementVersionInitial,
          send_at: DateHelper.getDateTimeLocal().add(3, "minute"),
          valid_until: DateHelper.getDateTimeLocal().add(3, "minute").add(1, "hour"),
        })
      );
      return;
    }
    dispatch(getAnnouncement(currentProject.id, id)).then((res) => {
      if (res?.versions?.length) {
        const highestVersion = res.versions.reduce((a, b) => (a.version_id > b.version_id ? a : b));
        getAnnouncementVersionWithActionAndFilter(res.id, highestVersion?.id);
      }
    });
    return () => {
      dispatch(setAnnouncement(null));
      dispatch(setAnnouncementVersion(null));
      dispatch(setFilter({}));
      dispatch(setAction({}));

      // dispatch(resetOrganizationForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentProject.id, id, surveyQueryId]);

  const handleChangeAnnouncement = (key) => (e) => {
    let value = e?.target ? e.target.value : e;
    if (e?.target?.type === "checkbox") {
      value = e.target.checked;
    }
    const newAnnouncement = { ...announcement };
    dispatch(setAnnouncement({ ...newAnnouncement, [key]: value }));
  };

  const handleChangeAnnouncementVersion = (key) => (e) => {
    let value = e.target ? e.target?.value : e;
    if (e?.target?.type === "checkbox") {
      value = e.target.checked;
    }
    const newAnnouncementVersion = { ...announcementVersion };
    dispatch(setAnnouncementVersion({ ...newAnnouncementVersion, [key]: value }));
  };

  const handleSaveVersion = async (announcementVersionData) => {
    if (announcementVersionData.filter.filter_type === organizationAnnouncementFilterType.LOW_CODE) {
      const response = await dispatch(
        createOrUpdateProcedure(currentProject.id, currentBot.id, lowCodeData, {
          successMsg: false,
        })
      );
      announcementVersionData.filter.low_code_module = response.id;
    }

    const errors = [];
    if (!announcement?.name) {
      errors.push(t("component.formik.required").format(t("common.name")));
    }
    if (errors.length) {
      DialogHelper.showValidate(errors);
      return;
    }

    let announcementPayload = cloneDeep(announcement);
    if (announcementPayload?.id) {
      announcementPayload = Utils.getWithOnlyAllowedKeys(announcementPayload, ["id", "survey"]);
    }
    const newOrUpdatedAnnouncement = await dispatch(
      createOrUpdateAnnouncement(currentProject.id, announcementPayload, {
        successMsg: false,
      })
    );

    dispatch(setAnnouncement(newOrUpdatedAnnouncement));
    announcementVersionData.announcement = newOrUpdatedAnnouncement.id;

    const result = await dispatch(
      createOrUpdateAnnouncementVersion(currentProject.id, newOrUpdatedAnnouncement.id, announcementVersionData, {
        successMsg: false,
      })
    );
    return result;
  };

  const getAnnouncementWithVersion = async (announcementVersion, noLoading = false) => {
    const announcementId = announcementVersion?.announcement || id;
    await dispatch(
      getAnnouncement(currentProject.id, announcementId, {
        loading: !noLoading,
      })
    );

    if (announcementVersion?.id && announcementId) {
      try {
        await dispatch(
          getAnnouncementVersionWithActionAndFilter(announcementId, announcementVersion?.id, {
            loading: !noLoading,
          })
        );
      } catch (error) {
        console.error(t("dashboard.announcementEdit.announcementVersionError"), error);
      }
    }
    if (!id && announcementVersion?.announcement) {
      HistoryHelper.replace(`/organization/announcement/form/${announcementVersion?.announcement}`, {
        scope: "dashboard",
      });
    }
  };
  useEffect(() => {
    //auto-refresh when organizationAnnouncementStatus.PROCESSING
    let interval = null;

    if (
      announcementVersion?.id &&
      [
        organizationAnnouncementStatus.PROCESSING,
        organizationAnnouncementStatus.WAITING,
        organizationAnnouncementStatus.SCHEDULED,
      ].includes(announcementVersion?.status)
    ) {
      const fastIntervalTime = 10 * 1000;
      const slowIntervalTime = 60 * 1000;
      let intervalTime =
        announcementVersion?.status === organizationAnnouncementStatus.PROCESSING ? fastIntervalTime : slowIntervalTime;
      const sendAtDate = announcementVersion?.send_at && DateHelper.getDateTime(announcementVersion?.send_at);
      if (
        announcementVersion?.status === organizationAnnouncementStatus.SCHEDULED &&
        !announcementVersion?.is_periodic &&
        sendAtDate?.isBefore(DateHelper.getDateTime())
      ) {
        intervalTime = fastIntervalTime;
      }
      interval = setInterval(() => {
        getAnnouncementWithVersion(announcementVersion, true);
      }, intervalTime);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcementVersion?.id, announcementVersion?.status, announcementVersion?.version_id]);

  const handleSubmitVersion = async () => {
    let resultValues = await actionRef.current?.handleSubmit();
    const valuesData = cloneDeep(announcementVersion);
    valuesData.action = resultValues;
    valuesData.status = organizationAnnouncementStatus.DRAFT;
    const errors = [];
    if (!announcement?.name) {
      errors.push(t("component.formik.required").format(t("common.name")));
    }
    if (!resultValues) {
      errors.push(t("component.formik.required").format(t("common.action")));
    }
    if (!valuesData?.filter) {
      errors.push(t("component.formik.required").format(t("common.filter")));
    }

    if (valuesData?.is_periodic) {
      if (!valuesData?.cron_schedule) {
        errors.push(t("component.formik.required").format(t("dashboard.announcementEdit.cronSchedule")));
      }

      if (valuesData.valid_until && DateHelper.getDateTime(valuesData.valid_until).isBefore(DateHelper.getDateTime())) {
        errors.push(t("dashboard.announcementEdit.endDateMustBeFuture"));
      }
      if (
        valuesData?.send_at &&
        valuesData?.valid_until &&
        DateHelper.getDateTime(sendNow ? undefined : valuesData.send_at).isAfter(
          DateHelper.getDateTime(valuesData.valid_until)
        )
      ) {
        errors.push(t("dashboard.announcementEdit.startDateBeforeError"));
      }

      if (!cronjobDateList?.length) {
        errors.push(t("dashboard.announcementEdit.noPeriodAvailableError"));
      }
    } else {
      if (!valuesData?.send_at) {
        errors.push(t("component.formik.required").format(t("dashboard.announcementEdit.sendAt")));
      }
      // if (
      //   !sendNow &&
      //   valuesData?.send_at &&
      //   DateHelper.getDateTime(valuesData.send_at).isBefore(DateHelper.getDateTime())
      // ) {
      //   errors.push("Send at must be in the future");
      // }
    }

    if (errors.length) {
      DialogHelper.showValidate(errors);
      return false;
    }
    // formikVersion.handleSubmit();
    if (
      !validateFilterObject(valuesData.filter, lowCodeData, {
        skipNameValidation: true,
      })
    ) {
      return false;
    }
    const announcementVersionResponse = await handleSaveVersion(valuesData);
    await getAnnouncementWithVersion(announcementVersionResponse);
    AlertHelper.showSuccess();
    return true;
  };
  const handleSendNow = async () => {
    let resultValues = await actionRef.current?.handleSubmit();
    const valuesData = cloneDeep(announcementVersion);
    valuesData.action = resultValues;
    const errors = [];
    if (!announcement?.name) {
      errors.push(t("component.formik.required").format(t("common.name")));
    }
    if (!resultValues) {
      errors.push(t("component.formik.required").format(t("common.action")));
    }
    if (!valuesData?.filter) {
      errors.push(t("component.formik.required").format(t("common.filter")));
    }

    if (valuesData?.is_periodic) {
      if (!valuesData?.cron_schedule) {
        errors.push(t("component.formik.required").format(t("dashboard.announcementEdit.cronSchedule")));
      }
      if (valuesData.valid_until && DateHelper.getDateTime(valuesData.valid_until).isBefore(DateHelper.getDateTime())) {
        errors.push(t("dashboard.announcementEdit.endDateMustBeFuture"));
      }
      if (
        valuesData?.send_at &&
        valuesData?.valid_until &&
        DateHelper.getDateTime(sendNow ? undefined : valuesData.send_at).isAfter(
          DateHelper.getDateTime(valuesData.valid_until)
        )
      ) {
        errors.push(t("dashboard.announcementEdit.startDateBeforeError"));
      }
      if (!cronjobDateList?.length) {
        errors.push(t("dashboard.announcementEdit.noPeriodAvailableError"));
      }
    } else {
      if (!valuesData?.send_at) {
        errors.push(t("component.formik.required").format(t("dashboard.announcementEdit.sendAt")));
      }

      if (
        !sendNow &&
        valuesData?.send_at &&
        DateHelper.getDateTime(valuesData.send_at).isBefore(DateHelper.getDateTime())
      ) {
        errors.push(t("dashboard.announcementEdit.sendAtMustBeFuture"));
      }
    }

    if (errors.length) {
      DialogHelper.showValidate(errors);
      return false;
    }

    if (!validateFilterObject(valuesData.filter, lowCodeData, { skipNameValidation: true })) {
      return;
    }

    if (
      !(await DialogHelper.showQuestionYesNo(
        t("dashboard.announcementEdit.sendAnnouncement"),
        t("dashboard.announcementEdit.sendAnnouncementQuestion")
      ))
    ) {
      return;
    }

    if (sendNow) {
      valuesData.send_at = DateHelper.getDateTimeLocal().add(3, "minute");
      // formikVersion.setFieldValue("send_at", valuesData.send_at);
      handleChangeAnnouncementVersion("send_at")(valuesData.send_at);
    }
    const announcementVersionResponse = await handleSaveVersion(valuesData);
    if (!announcementVersionResponse) return;
    // await Network.request(`project/${currentProject.id}/organization/make-announcement/${result.id}/`, {
    //   method: "POST",
    //   loading: true,
    // });
    await dispatch(sendAnnouncementAction(currentProject.id, announcementVersionResponse.id));
    await getAnnouncementWithVersion(announcementVersionResponse);
    AlertHelper.showSuccess();
  };
  const versions = useMemo(() => {
    let versions = [...(announcement?.versions || [])];
    versions?.sort((a, b) => {
      return a.version_id - b.version_id;
    });
    const highestVersionId = versions
      .filter((item) => !!item.id)
      .reduce((acc, cur) => {
        return Math.max(acc, cur.version_id);
      }, 0);

    versions.push({
      ...announcementVersionInitial,
      version_id: highestVersionId + 1,
      send_at: DateHelper.getDateTimeLocal().add(3, "minute"),
      valid_until: DateHelper.getDateTimeLocal().add(3, "minute").add(1, "hour"),
    });
    return versions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcement?.versions, announcementVersion?.version_id]);

  const handleCancelVersion = async () => {
    await dispatch(sendAnnouncementCancelAction(currentProject.id, announcementVersion?.id));
    getAnnouncementWithVersion(announcementVersion);
  };

  const handleChangeSendAt = (data) => {
    let date = data;
    if (moment.isMoment(data) && date.isBefore(DateHelper.getDateTimeLocal())) {
      date = DateHelper.getDateTimeLocal();
    }
    handleChangeAnnouncementVersion("send_at")(date);
  };

  const handleChangeSwitchNow = (e) => {
    const checked = e.target.checked;
    if (checked) {
      handleChangeAnnouncementVersion("send_at")(DateHelper.getDateTimeLocal().add(1, "minute"));
    } else {
      handleChangeAnnouncementVersion("send_at")(DateHelper.getDateTimeLocal().add(1, "minute"));
    }
    setSendNow(checked);
  };

  const handleChangeSwitchSelectedHistory = (e) => {
    const checked = e.target.checked;
    setShowSelectedHistory(checked);
  };

  const handleChangeVersion = useCallback(
    (version) => {
      const versionItem = versions.find((item) => item.version_id === version);
      const clonedVersion = cloneDeep(versionItem);
      if (announcement?.id) {
        dispatch(getAnnouncement(currentProject.id, announcement?.id));
      }
      if (!versionItem?.id) {
        dispatch(setAnnouncementVersion(clonedVersion));
      } else {
        getAnnouncementVersionWithActionAndFilter(announcement?.id, versionItem?.id);
      }
    },
    [announcement?.id, currentProject.id, dispatch, versions, t]
  );

  const columns = useMemo(() => {
    return [
      {
        label: t("common.version"),
        key: "version.version_id",
        render: (row) => {
          return (
            <PRLink
              onClick={() => {
                handleChangeVersion(row?.version?.version_id);
              }}
            >
              v{row?.version?.version_id}
            </PRLink>
          );
        },
      },
      {
        label: t("dashboard.announcementEdit.announcementDate"),
        key: "start_date",
        render: (row) => {
          return DateHelper.getDateTimeLocal(row?.start_date).format("LLT");
        },
      },
      // {
      //   label: "Periodic",
      //   key: "is_periodic",
      //   render: (row) => {
      //     return row?.version?.is_periodic ? (
      //       <PRTooltip
      //         title={cronstrue.toString(row?.version?.cron_schedule, {
      //           use24HourTimeFormat: true,
      //           throwExceptionOnParseError: false,
      //         })}
      //       >
      //         <span className="fw-bold">Yes</span>
      //       </PRTooltip>
      //     ) : (
      //       <span className="">No</span>
      //     );
      //   },
      // },
      {
        label: t("common.endDate"),
        key: "end_date",
        render: (row) => {
          return DateHelper.getDateTimeLocal(row?.end_date).format("LLT");
        },
      },
      {
        label: t("common.status"),
        key: "status",
        render: (row) => {
          return t(organizationAnnouncementStatusMap?.[row?.status]) || row?.status;
        },
      },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        render: (row) => {
          const handleClickShowMembers = async () => {
            AnnouncementVersionMembersModal.show({
              announcementId: announcement?.id,
              versionId: row?.version?.id,
            });
            // CrontabEditorModal.show({});
          };

          return (
            <div className="d-flex justify-content-center">
              <PRButton
                outline
                className="ms-1"
                color="primary"
                icon={MdOutlinePersonSearch}
                size="sm"
                tooltipText={t("dashboard.announcementEdit.showMembersTooltip")}
                onClick={handleClickShowMembers}
              />
            </div>
          );
        },
      },
      // {
      //   label: "Action",
      //   key: "action",
      //   render: (row) => (
      //     <PRLink newTab to={`/organization/actions/form/${row?.version?.action?.id}`}>
      //       {row?.version?.action?.name}
      //     </PRLink>
      //   ),
      // },
      // {
      //   label: "Filter",
      //   key: "filter",
      //   render: (row) => (
      //     <PRLink newTab to={`/organization/filters/form/${row?.version?.filter?.id}`}>
      //       {row?.version?.filter?.name}
      //     </PRLink>
      //   ),
      // },
    ];
  }, [handleChangeVersion, t]);

  const filters = useMemo(
    () => [
      {
        type: "options",
        label: t("common.status"),
        key: "status",
        options: organizationAnnouncementStatusOptions,
        multiple: true,
        localFilter: true,
      },
    ],
    []
  );

  const handleIsValidDateAfterToday = (date) => {
    const momentToday = DateHelper.getDateTimeLocal().startOf("day").add(-1, "minute");
    return momentToday.isBefore(date);
  };
  const isFormDisabled = [
    organizationAnnouncementStatus.DONE,
    organizationAnnouncementStatus.PROCESSING,
    organizationAnnouncementStatus.WAITING,
  ].includes(announcementVersion?.status);

  const handleNewVersionFromExisting = () => {
    const highestVersionId = versions
      .filter((item) => !!item.id)
      .reduce((acc, cur) => {
        return Math.max(acc, cur.version_id);
      }, 0);

    const currentVersionClone = cloneDeep(announcementVersion);
    const currentVersionCloneCleaned = Utils.getWithNonAllowedKeys(
      currentVersionClone,
      ["id", "bot", "project"],
      ["children", "member_data_field"]
    );

    const versionItem = {
      ...announcementVersionInitial,
      ...currentVersionCloneCleaned,
      version_id: highestVersionId + 1,
      status: organizationAnnouncementStatus.DRAFT,
    };

    const momentDiff = DateHelper.getDateTime().diff(DateHelper.getDateTime(versionItem.send_at), "minute");
    if (momentDiff > 0) {
      versionItem.send_at = DateHelper.getDateTimeLocal(versionItem.send_at).add(momentDiff + 3, "minute");
      versionItem.valid_until = DateHelper.getDateTimeLocal(versionItem.valid_until).add(momentDiff + 3, "minute");
    }

    if (versionItem.action) {
      versionItem.action.is_template = false;
    }
    if (versionItem.filter) {
      versionItem.filter.is_template = false;
    }
    dispatch(setAnnouncementVersion(versionItem));
  };

  const handleClickSaveFilterAsTemplate = async () => {
    const filterData = cloneDeep(announcementVersion?.filter);
    if (!validateFilterObject(filterData, lowCodeData, { skipNameValidation: true })) {
      return;
    }

    if (filterData.filter_type === organizationAnnouncementFilterType.LOW_CODE) {
      const response = await dispatch(createOrUpdateProcedure(currentProject.id, currentBot.id, lowCodeData));
      filterData.low_code_module = response.id;
    }
    filterData.is_template = true;
    delete filterData.id;
    const nameResult = await CreateAsTemplateModal.show();
    if (!nameResult) return;
    filterData.name = nameResult;
    await dispatch(createOrUpdateFilter(filterData));
    dispatch(getFilterList(currentProject.id));
  };

  const handleClickSaveActionAsTemplate = async () => {
    const values = await actionRef.current?.handleSubmit();
    if (values) {
      const clone = cloneDeep(values);
      clone.is_template = true;
      const nameResult = await CreateAsTemplateModal.show();
      if (!nameResult) return;
      clone.name = nameResult;
      delete clone.id;
      await dispatch(createOrUpdateAction(clone, currentProject.id));
      dispatch(getActionList(currentProject.id));
    }
  };

  useEffect(() => {
    if (
      !announcementVersion?.is_periodic ||
      !announcementVersion?.cron_schedule ||
      !announcementVersion?.send_at ||
      !announcementVersion?.valid_until ||
      ![5, 6, 7].includes(announcementVersion?.cron_schedule?.split(" ").length)
    ) {
      return;
    }
    try {
      const timezone = DateHelper.getTimezone();
      let startDate = DateHelper.getDateTimeLocal(announcementVersion?.send_at)?.toDate();
      let endDate = DateHelper.getDateTimeLocal(announcementVersion?.valid_until)?.toDate();

      const interval = parseExpression(announcementVersion?.cron_schedule, {
        startDate,
        currentDate: startDate,
        endDate,
        iterator: true,
        tz: timezone,
      });
      const dates = [];

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { value, done } = interval.next();
        if (done) break;

        const dateObj = {};
        const jobDate = DateHelper.getDateTimeLocal(value.toISOString());
        dateObj.date = jobDate;
        dateObj.dateText = jobDate.format("LLT");
        dateObj.isPast = jobDate.isBefore(DateHelper.getDateTimeLocal());

        dates.push(dateObj);
        if (dates.length > announcementPeriodLimit) break;
      }

      setCronjobDateList(dates);
    } catch (error) {
      setCronjobDateList([]);
    }
  }, [
    announcementVersion?.is_periodic,
    announcementVersion?.cron_schedule,
    announcementVersion?.send_at,
    announcementVersion?.valid_until,
  ]);

  const handleChangeCrontab = (e) => {
    let value = e.target ? e.target.value : e;
    if (value?.length && value?.split?.(" ")?.length > 7) {
      value = value?.split(" ").slice(0, 7).join(" ");
    }
    handleChangeAnnouncementVersion("cron_schedule")(value);
  };

  const handleClickCancel = () => {
    HistoryHelper.goBack("/organization/announcement/", { scope: "dashboard" });
  };

  const getOptionAnnouncementHistory = () => {
    const data = announcement?.statistics;
    const capitalizedKeys = Object.keys(data).map((key) => key.charAt(0).toUpperCase() + key.slice(1));

    //TODO: Ask total user count from backend instead of calculating here
    const totalStatistic = Object.values(data).reduce((acc, cur) => acc + cur, 0);

    const option = {
      title: {
        text: t("dashboard.announcementEdit.generalStatistics").format(totalStatistic.toString()),
      },
      xAxis: {
        data: capitalizedKeys,
        axisLabel: {
          rotate: 45,
          interval: 0,
        },
      },
      yAxis: {
        minInterval: 1,
        name: t("common.count"),
      },
      series: [
        {
          name: t("dashboard.announcementEdit.announcement"),
          type: "bar",
          data: Object.values(data),
          itemStyle: {
            color: function (params) {
              return barColors[params.dataIndex];
            },
          },
          label: {
            show: true,
            position: "inside",
            formatter: function (params) {
              if (params.value > 0) {
                return params.value;
              }
              return "";
            },
          },
        },
      ],
    };

    return option;
  };
  const getOptionAnnouncementVersionHistory = () => {
    const data = announcementVersion?.statistics;
    const capitalizedKeys = Object.keys(data).map((key) => key.charAt(0).toUpperCase() + key.slice(1));

    //TODO: Ask total user count from backend instead of calculating here
    const totalStatistic = Object.values(announcementVersion?.statistics || {}).reduce((acc, cur) => acc + cur, 0);

    const option = {
      toolbox: {
        feature: {
          myTool: {
            show: true,
            title: t("dashboard.announcementEdit.reload"),
            icon: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHXSURBVFhH7VahcsJAEMVVImsYOgOZqaxEIpFIZCWfgMRVRkZWVlYikUgkEomsjG3fy7xkLtfb5I6qzuTNvAnsvt3L5fb2bjRgQArm8/kSzGez2RnPb5eyvYMLySvA/knfEyBTOrIse0GSoztgF/UyS8Y59kLp0oBkKwR/OYmiidiD8/uolPFA0AbBZZ3kj7wpbRww+DaQhLzAt/PXFPY17CdP2+JkMnmQvBvT6fQRAf7MSwzwKokJ6FioVyeuIWtCsm5AnHvBHHwltwnoPry4FpFjI6mN0OyjAgFob26cT+TZSWoDwtbsEXSWqxf8SuABtLbsWlIbEC34pmBVUHhu5boL+KLPyFP1BJniwaqNrtwBAwLgluaOYFGzuGXuBjrsGAHVccsnE+A3G8yeZFJJewG9v6VPctmAiAeQu3d9ljE7gxpo/ca0l9uGd4aHeOt7AfoxkeYoFkv2BElscAm8wJq8E7z1JdEShjphLkk/ILZ6Os+Iwj+KCRUci+3XSQj7OamhGTNokRqH5j0APt4Hx0odBwQWTpK7rmMkBj8kzbwGAvkp6yRb/U99keKuwQkWGhJwvcmqgThrfAFDA5L05aEaSQbXzUrEl8FAvHo1pE3uAf8Jo9EPUmGCQkJyvKwAAAAASUVORK5CYII=",
            onclick: async () => {
              if (!announcement?.id || !announcementVersion?.id) return;
              //enable loading
              announcementVersionChartRef.current?.getEchartsInstance()?.showLoading();

              dispatch(
                getAnnouncementVersionWithActionAndFilter(announcement?.id, announcementVersion?.id, {
                  loading: false,
                })
              ).finally(() => {
                announcementVersionChartRef.current?.getEchartsInstance()?.hideLoading();
              });
            },
          },
        },
      },
      title: {
        text: t("dashboard.announcementEdit.versionStatistic").format(
          announcementVersion?.version_id.toString(),
          totalStatistic.toString()
        ),
      },
      xAxis: {
        data: capitalizedKeys,
        axisLabel: {
          rotate: 45,
          interval: 0,
        },
      },
      yAxis: {
        minInterval: 1,
        name: t("common.count"),
      },
      series: [
        {
          name: t("dashboard.announcementEdit.announcement"),
          type: "bar",
          data: Object.values(data),
          itemStyle: {
            color: function (params) {
              return barColors[params.dataIndex % barColors.length];
            },
          },
          label: {
            show: true,
            position: "inside",
            formatter: function (params) {
              if (params.value > 0) {
                return params.value;
              }
              return "";
            },
          },
        },
      ],
    };

    return option;
  };

  const getOptionAnnouncementVersionListHistory = () => {
    const chartVersions = [...(versions.filter((item) => !!item.id) || [])];
    chartVersions.sort((a, b) => {
      return a.version_id - b.version_id;
    });

    const versionLabels = chartVersions.map((item) => `v${item.version_id}`);
    const legends = [
      t("dashboard.announcementEdit.legends.submitted"),
      t("dashboard.announcementEdit.legends.sent"),
      t("dashboard.announcementEdit.legends.delivered"),
      t("dashboard.announcementEdit.legends.seen"),
      t("dashboard.announcementEdit.legends.failed"),
      t("dashboard.announcementEdit.legends.opened"),
      t("dashboard.announcementEdit.legends.completed"),
    ];
    const series = [
      {
        name: t("dashboard.announcementEdit.legends.submitted"),
        type: "bar",
        stack: "total",
        data: [],
        itemStyle: {
          color: barColors[0],
        },

        label: {
          show: true,
          position: "inside",
          formatter: function (params) {
            if (params.value > 0) {
              return params.value;
            }
            return "";
          },
        },
      },
      {
        name: t("dashboard.announcementEdit.legends.sent"),
        type: "bar",
        stack: "total",
        data: [],
        itemStyle: {
          color: barColors[1],
        },
        label: {
          show: true,
          position: "inside",
          formatter: function (params) {
            if (params.value > 0) {
              return params.value;
            }
            return "";
          },
        },
      },
      {
        name: t("dashboard.announcementEdit.legends.delivered"),
        type: "bar",
        stack: "total",
        data: [],
        itemStyle: {
          color: barColors[2],
        },
        label: {
          show: true,
          position: "inside",
          formatter: function (params) {
            if (params.value > 0) {
              return params.value;
            }
            return "";
          },
        },
      },
      {
        name: t("dashboard.announcementEdit.legends.seen"),
        type: "bar",
        stack: "total",
        data: [],
        itemStyle: {
          color: barColors[3],
        },
        label: {
          show: true,
          position: "inside",
          formatter: function (params) {
            if (params.value > 0) {
              return params.value;
            }
            return "";
          },
        },
      },
      {
        name: t("dashboard.announcementEdit.legends.failed"),
        type: "bar",
        stack: "total",
        data: [],
        itemStyle: {
          color: barColors[4],
        },
        label: {
          show: true,
          position: "inside",
          formatter: function (params) {
            if (params.value > 0) {
              return params.value;
            }
            return "";
          },
        },
      },
      {
        name: t("dashboard.announcementEdit.legends.opened"),
        type: "bar",
        stack: "total",
        data: [],
        itemStyle: {
          color: barColors[5],
        },
        label: {
          show: true,
          position: "inside",
          formatter: function (params) {
            if (params.value > 0) {
              return params.value;
            }
            return "";
          },
        },
      },
      {
        name: t("dashboard.announcementEdit.legends.completed"),
        type: "bar",
        stack: "total",
        data: [],
        itemStyle: {
          color: barColors[6],
        },
        label: {
          show: true,
          position: "inside",
          formatter: function (params) {
            if (params.value > 0) {
              return params.value;
            }
            return "";
          },
        },
      },
    ];

    for (const v of chartVersions) {
      const stats = v.statistics;
      const completed = stats?.completed || 0;
      const failed = stats?.failed || 0;
      const opened = stats?.opened || 0;
      const seen = stats?.seen || 0;
      const delivered = stats?.delivered || 0;
      const sent = stats?.sent || 0;
      const submitted = stats?.submitted || 0;

      series[0].data.push(submitted);
      series[1].data.push(sent);
      series[2].data.push(delivered);
      series[3].data.push(seen);
      series[4].data.push(failed);
      series[5].data.push(opened);
      series[6].data.push(completed);
    }

    const option = {
      legend: {
        data: legends,
      },
      title: {
        text: t("dashboard.announcementEdit.versionStatistics").format(chartVersions.length.toString()),
      },
      xAxis: {
        data: versionLabels,
      },
      yAxis: {
        name: t("common.count"),
      },
      series: series,
    };

    return option;
  };

  const sendAtMoment = announcementVersion?.send_at && DateHelper.getDateTime(announcementVersion?.send_at);
  const isSendTimePassed = sendAtMoment && sendAtMoment.isBefore(DateHelper.getDateTimeLocal());

  const parentName = [
    {
      label: t("common.organization"),
      url: "/organization/announcement",
    },
    {
      label: t("dashboard.announcementEdit.announcementDetail"),
    },
  ];
  const nextPeriodText = cronjobDateList?.length > 1 && cronjobDateList.find((item) => !item?.isPast)?.dateText;

  const historyList = useMemo(() => {
    let list = [...(announcement?.histories || [])];
    if (showSelectedHistory) {
      list = list.filter((item) => item.version?.id === announcementVersion?.id);
    }
    list.sort((a, b) => {
      return a.version?.version_id - b.version?.version_id;
    });
    return list;
  }, [announcement, announcementVersion, showSelectedHistory]);

  const handleEditCrontab = async () => {
    const result = await CrontabEditorModal.show({ value: announcementVersion?.cron_schedule });
    if (result) {
      handleChangeCrontab(quartzToUnixCron(result));
    }
  };

  // const isInLast3Minutes =
  //   sendAtMoment &&
  //   sendAtMoment.isBefore(DateHelper.getDateTimeLocal().add(3, "minute")) &&
  //   sendAtMoment.isAfter(DateHelper.getDateTimeLocal());
  const isSurveyDisabled =
    announcement?.versions?.length > 1 || // duplicate
    (announcement?.versions?.length === 1 && !announcementVersion?.id) || // new version
    (announcementVersion?.id && ![organizationAnnouncementStatus.DRAFT].includes(announcementVersion?.status));
  return (
    <PRContainer noAction name={t("common.organization")} parentName={parentName}>
      <PRPage title="">
        {[organizationAnnouncementStatus.PROCESSING].includes(announcementVersion?.status) &&
          announcementVersion?.id && (
            <Alert className="mb-3 d-flex align-items-center" color="primary">
              <BiLoaderAlt className="fs-5 me-2 spin" />
              {t("dashboard.announcementEdit.processingAlert")}
            </Alert>
          )}
        {[organizationAnnouncementStatus.DONE].includes(announcementVersion?.status) && announcementVersion?.id && (
          <Alert className="mb-3 d-flex align-items-center" color="success">
            <MdCheck className="fs-5 me-2" />
            {t("dashboard.announcementEdit.doneAlert")}
          </Alert>
        )}
        {[organizationAnnouncementStatus.DRAFT].includes(announcementVersion?.status) && announcementVersion?.id && (
          <Alert className="mb-3 d-flex align-items-center" color="secondary">
            <MdEdit className="fs-5 me-2" />
            {t("dashboard.announcementEdit.editingDraftAlert")}
          </Alert>
        )}
        {[organizationAnnouncementStatus.SCHEDULED].includes(announcementVersion?.status) &&
          announcementVersion?.id && (
            <Alert className="mb-3 d-flex align-items-center" color="warning">
              <MdSchedule className="fs-5 me-2" />
              {t("dashboard.announcementEdit.editingScheduledAlert")}
            </Alert>
          )}
        {[organizationAnnouncementStatus.WAITING].includes(announcementVersion?.status) && announcementVersion?.id && (
          <Alert className="mb-3 d-flex align-items-center" color="dark">
            <MdTimer className="fs-5 me-2" />
            {t("dashboard.announcementEdit.waitingNextAlert").format(
              nextPeriodText ? t("dashboard.announcementEdit.nextPeriod").format(nextPeriodText) : ""
            )}
          </Alert>
        )}
        {/* <PRDividerLabel className="my-3" pageSeparator>
          Announcement
        </PRDividerLabel> */}
        <Row className="gx-2 justify-content-end">
          <Col className="align-items-center text-lg-end" lg="1" md="1">
            <Label size="md">{t("common.version")}:</Label>
          </Col>
          <Col className="d-flex align-items-center" lg="4" md="6">
            <PRSelect
              fullWidth
              isPrimitiveValue
              isClearable={false}
              labelRenderer={(item) => {
                if (item?.id) {
                  return `v${item.version_id} (${t(organizationAnnouncementStatusMap?.[item.status]) || item.status})`;
                }
                return t("common.new");
              }}
              options={versions}
              value={announcementVersion?.version_id}
              valueSelector="version_id"
              onChange={handleChangeVersion}
            />
            <PRButton
              className="ms-2"
              color="success"
              disabled={!announcementVersion?.id || !announcementVersion?.version_id}
              icon={MdContentCopy}
              tooltipText={t("dashboard.announcementEdit.copyTooltip")}
              onClick={handleNewVersionFromExisting}
            />
          </Col>
        </Row>
        <Row className="g-2 mt-2 w-md-50">
          <Col className="" lg="3" md="12">
            <Label className="me-2" size="md">
              {t("common.name")}:
            </Label>
          </Col>
          <Col className="" lg="9" md="12">
            <PRInput
              className="w-100"
              disabled={announcement?.id || isFormDisabled}
              name="name"
              value={announcement?.name}
              onChange={handleChangeAnnouncement("name")}
            />
          </Col>
          <Col className="" lg="3" md="12">
            <Label size="md">{t("common.survey")}:</Label>
          </Col>
          <Col className=" " lg="9" md="12">
            <PRSelect
              key={currentProject.id}
              fullWidth
              isPrimitiveValue
              lazy
              isDisabled={isSurveyDisabled}
              labelSelector="name"
              loadOptions={async (text) => {
                const result = await dispatch(
                  getSurveyList(currentProject.id, {
                    params: {
                      ...(text && { name__icontains: text }),
                      limit: 20,
                    },
                    loading: false,
                  })
                );
                const surveyList = cloneDeep(result?.results || []);
                if (announcement?.survey && !surveyList.find((item) => item.id === announcement?.survey)) {
                  const result = await dispatch(
                    getSurvey(announcement?.survey, currentProject.id, {
                      loading: false,
                      onSuccess: () => {},
                    })
                  );
                  if (result) {
                    surveyList.unshift(result);
                  }
                }
                if (surveyQueryId && !surveyList.find((item) => item.id === surveyQueryId)) {
                  surveyList.unshift({
                    id: surveyQueryId,
                    name: surveyQueryName,
                  });
                }
                return surveyList;
              }}
              value={announcement?.survey}
              valueSelector="id"
              onChange={handleChangeAnnouncement("survey")}
            />
          </Col>

          <Col className=" " lg="12" md="12">
            <div disabled={!announcement?.survey}>
              <PRFormGroup switch className="me-1">
                <PRInput
                  checked={announcementVersion?.resend_incomplete_surveys}
                  disabled={!announcement?.survey}
                  type="switch"
                  onChange={handleChangeAnnouncementVersion("resend_incomplete_surveys")}
                />
                <Label size="md">{t("dashboard.announcementEdit.resendIncompleteSurvey")}</Label>
              </PRFormGroup>
            </div>
          </Col>
          <Col
            className=""
            xs={{
              size: 10,
              offset: 2,
            }}
          >
            {!isSurveyDisabled && !(announcement?.versions?.length > 1) && announcement?.survey && (
              <span className="fs-6 text-muted">
                <span className="fw-semibold">{t("common.note")}: </span>
                {t("dashboard.announcementEdit.surveyNote")}
              </span>
            )}
          </Col>
        </Row>
        <PRDividerLabel pageSeparator className="my-3">
          {t("dashboard.announcementEdit.announcementVersion")}
        </PRDividerLabel>
        <div className="my-2">
          <FilterRule
            showAccordion
            filter={announcementVersion?.filter}
            lowCodeData={lowCodeData}
            readonly={isFormDisabled}
            onChange={handleChangeAnnouncementVersion("filter")}
            onChangeLowCode={setLowCodeData}
            onClickSaveTemplate={handleClickSaveFilterAsTemplate}
          />
        </div>

        <div className="my-2">
          <ActionRule
            // onSubmit={handleChangeFormikVersion("action")}
            ref={actionRef}
            showAccordion
            action={announcementVersion?.action}
            readonly={isFormDisabled}
            onClickSaveTemplate={handleClickSaveActionAsTemplate}
          />
        </div>
        <Row className="mt-2 g-2 w-md-50">
          <Col md="3">
            <Label className="" size="md">
              {t("common.periodic")}:
            </Label>
          </Col>
          <Col className="d-flex align-items-center" md="9">
            <FormGroup switch>
              <PRInput
                checked={announcementVersion?.is_periodic}
                disabled={isFormDisabled}
                name="is_periodic"
                type="switch"
                onChange={handleChangeAnnouncementVersion("is_periodic")}
              />
            </FormGroup>
          </Col>
          {announcementVersion?.is_periodic && (
            <>
              <Col lg="3" md="12">
                <Label className="" size="md">
                  {t("dashboard.announcementEdit.dateRange")}:
                </Label>
              </Col>
              <Col lg="9" md="12">
                <Row className="g-2">
                  <Col lg md="12">
                    <PRDate
                      disabled={sendNow || isFormDisabled}
                      isClearable={false}
                      isValidDate={handleIsValidDateAfterToday}
                      name="send_at"
                      value={announcementVersion?.send_at}
                      onChange={handleChangeSendAt}
                    />

                    <FormGroup switch className="mt-1 d-flex align-items-center">
                      <PRInput
                        disabled={isFormDisabled}
                        type="switch"
                        value={sendNow}
                        onChange={handleChangeSwitchNow}
                      />
                      <Label size="md">{t("common.now")}</Label>
                    </FormGroup>
                  </Col>
                  <Col className="d-flex" lg="auto" md="12">
                    <Label className="" size="md">
                      {t("dashboard.announcementEdit.between")}
                    </Label>
                  </Col>
                  <Col lg md="12">
                    <PRDate
                      disabled={isFormDisabled}
                      isClearable={false}
                      isValidDate={handleIsValidDateAfterToday}
                      name="valid_until"
                      value={announcementVersion?.valid_until}
                      onChange={handleChangeAnnouncementVersion("valid_until")}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg="3" md="12">
                <Label className="" size="md">
                  {t("dashboard.announcementEdit.crontab")}:
                </Label>
              </Col>
              <Col className="d-flex align-items-center" lg="9" md="12">
                <PRInput
                  className="w-100"
                  disabled={isFormDisabled}
                  name="cron_schedule"
                  placeholder="* * * * *"
                  value={announcementVersion?.cron_schedule}
                  onChange={handleChangeCrontab}
                />
                <PRButton
                  className="ms-2"
                  color="success"
                  disabled={isFormDisabled}
                  icon={MdSchedule}
                  tooltipText={t("dashboard.announcementEdit.editCrontabTooltip")}
                  onClick={handleEditCrontab}
                />
              </Col>
              <Col className="offset-lg-3" lg="9" md="12">
                {announcementVersion?.cron_schedule && (
                  <div className="text-muted text-center my-2 p-2 bg-light">
                    {cronstrue.toString(announcementVersion?.cron_schedule, {
                      use24HourTimeFormat: true,
                      throwExceptionOnParseError: false,
                    })}
                  </div>
                )}
                {!!cronjobDateList?.length && cronjobDateList.length <= announcementPeriodLimit && (
                  <PRAccordion noSpacing>
                    <PRAccordionItem
                      defaultCollapsed={true}
                      title={t("dashboard.announcementEdit.periodPreview").format(cronjobDateList?.length.toString())}
                    >
                      <div style={{ maxHeight: 450, overflowY: "auto" }}>
                        {cronjobDateList?.map((item, index) => {
                          // const executeCount = (announcement?.histories || []).filter(
                          //   (history) => history.version?.id === announcementVersion?.id
                          // );
                          // const isExecuted = executeCount?.length > index;
                          return (
                            <div key={index} className="font-size-14 d-flex align-items-center">
                              {!item.isPast ? (
                                <>
                                  <MdOutlineTimer className="me-1 text-warning-600" />
                                  {t("dashboard.announcementEdit.scheduledTo")}
                                </>
                              ) : [
                                  organizationAnnouncementStatus.PROCESSING,
                                  organizationAnnouncementStatus.DONE,
                                  organizationAnnouncementStatus.WAITING,
                                ].includes(announcementVersion?.status) ? (
                                <>
                                  <MdCheckCircle className="me-1 text-success" />
                                  {t("dashboard.announcementEdit.executedAt")}
                                </>
                              ) : (
                                <>
                                  <MdOutlineTimerOff className="me-1 text-danger" />
                                  {t("dashboard.announcementEdit.unavailableAt")}
                                </>
                              )}
                              <span className="ms-1 fw-medium">{item.dateText}</span>
                            </div>
                          );
                        })}
                      </div>
                    </PRAccordionItem>
                  </PRAccordion>
                )}
                {cronjobDateList?.length > announcementPeriodLimit && (
                  <Alert className="mb-0" color="warning">
                    {t("dashboard.announcementEdit.periodPreviewLimit").format(announcementPeriodLimit.toString())}
                  </Alert>
                )}
                {!cronjobDateList?.length && (
                  <div className="text-muted text-center">{t("dashboard.announcementEdit.noPeriod")}</div>
                )}
              </Col>
            </>
          )}
          {!announcementVersion?.is_periodic && (
            <>
              <Col lg="3" md="12">
                <Label className="" size="md">
                  {t("dashboard.announcementEdit.sendAt")}:
                </Label>
              </Col>
              <Col lg="9" md="12">
                <PRDate
                  disabled={sendNow || isFormDisabled}
                  isClearable={false}
                  isValidDate={handleIsValidDateAfterToday}
                  name="send_at"
                  value={announcementVersion?.send_at}
                  onChange={handleChangeSendAt}
                />
                <FormGroup switch className="mt-1 d-flex align-items-center">
                  <PRInput disabled={isFormDisabled} type="switch" value={sendNow} onChange={handleChangeSwitchNow} />
                  <Label size="md">{t("common.now")}</Label>
                </FormGroup>
              </Col>
            </>
          )}
        </Row>

        <div className="mt-3 d-flex justify-content-end align-items-center">
          {announcementVersion?.is_periodic && cronjobDateList?.[0] && (
            <Row className="g-0 me-auto">
              <Col className="font-size-11 text-muted" xs="12">
                <span>{t("dashboard.announcementEdit.firstAnnouncement1")}</span>
                <span className="fw-medium">
                  {cronjobDateList[0].dateText} ({cronjobDateList[0].date.fromNow()})
                </span>
                <span>{t("dashboard.announcementEdit.firstAnnouncement2")}</span>
              </Col>
            </Row>
          )}
          {![organizationAnnouncementStatus.DRAFT, organizationAnnouncementStatus.PROCESSING].includes(
            announcementVersion?.status
          ) &&
            !announcementVersion?.is_periodic &&
            (announcementVersion?.id ? true : !isSendTimePassed) &&
            sendAtMoment && (
              <Row className="g-0 me-auto">
                <Col className="font-size-11 text-muted" xs="12">
                  <span>
                    {isSendTimePassed
                      ? t("dashboard.announcementEdit.announcementWasSent1")
                      : t("dashboard.announcementEdit.announcementWillBeSent1")}{" "}
                  </span>
                  <span className="fw-medium">{sendAtMoment.fromNow()}</span>{" "}
                  <span>
                    {isSendTimePassed
                      ? t("dashboard.announcementEdit.announcementWasSent2")
                      : t("dashboard.announcementEdit.announcementWillBeSent2")}
                  </span>
                </Col>
              </Row>
            )}
          <div className="d-flex align-items-center">
            {[organizationAnnouncementStatus.SCHEDULED, organizationAnnouncementStatus.WAITING].includes(
              announcementVersion?.status
            ) && (
              <PRButton className="me-2" onClick={handleCancelVersion}>
                {t("dashboard.announcementEdit.cancelSchedule")}
              </PRButton>
            )}
            <PRButton
              className=""
              color="secondary"
              disabled={
                ![organizationAnnouncementStatus.DRAFT].includes(announcementVersion?.status) ||
                (announcementVersion?.is_periodic && cronjobDateList?.length > announcementPeriodLimit)
              }
              onClick={handleSubmitVersion}
            >
              {t("dashboard.announcementEdit.saveAsDraft")}
            </PRButton>
            <PRButton
              className="ms-2"
              disabled={
                ![organizationAnnouncementStatus.DRAFT].includes(announcementVersion?.status) ||
                (announcementVersion?.is_periodic && cronjobDateList?.length > announcementPeriodLimit)
              }
              onClick={handleSendNow}
            >
              {t("common.send")}
            </PRButton>
          </div>
        </div>
      </PRPage>

      <PRPage title={t("common.history")}>
        <div>
          {announcementVersion?.id && (
            <FormGroup switch className="  d-flex align-items-center">
              <PRInput type="switch" value={showSelectedHistory} onChange={handleChangeSwitchSelectedHistory} />
              <Label size="md">{t("dashboard.announcementEdit.showSelectedHistory")}</Label>
            </FormGroup>
          )}
          <PRTable
            inline
            columns={columns}
            data={historyList}
            filters={filters}
            memoizeAsQuery={false}
            storageKey={tableFilterStorageKey.announcementHistory}
          />
        </div>
      </PRPage>
      {announcement?.statistics && (
        <PRPage title={t("common.statistics")}>
          <PRChart option={getOptionAnnouncementHistory()} />
          {announcementVersion?.statistics && (
            <PRChart
              ref={announcementVersionChartRef}
              className="mt-5"
              option={getOptionAnnouncementVersionHistory()}
            />
          )}
          <PRChart className="mt-5" option={getOptionAnnouncementVersionListHistory()} />
        </PRPage>
      )}
      <Row className="justify-content-end mt-2">
        <Col md="auto">
          <PRButton outline className="" onClick={handleClickCancel}>
            {t("common.cancel")}
          </PRButton>
        </Col>
      </Row>
    </PRContainer>
  );
}
