export default class LockHelper {
  static async acquireLockWithinMs(key, ms = 1000) {
    const state = await navigator.locks.query();
    const isHeld = state.held.some((lock) => lock.name === key);
    if (isHeld) {
      return false;
    }

    let lockAcquired = false;
    try {
      await navigator.locks.request(key, { mode: "exclusive", signal: this._getTimeoutSignal(ms) }, async (lock) => {
        if (lock) {
          lockAcquired = true;
          await new Promise((resolve) => setTimeout(resolve, ms));
        }
      });
      return lockAcquired;
    } catch (error) {
      if (error.name === "AbortError") {
        console.info("Lock request was aborted");
      } else {
        console.error("An error occurred while attempting to acquire the lock:", error);
      }
      return false;
    }
  }

  static _getTimeoutSignal(ms) {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), ms);
    return controller.signal;
  }
}
