import { useEffect } from "react";

import { useMonaco } from "@monaco-editor/react";
import { useTranslation } from "react-i18next";

const stylePrefix = "monacoDoubleNewLineDecorator__";
/** @param {import("@monaco-editor/react").Monaco} editorBase */
export default function useMonacoDoubleNewLineDecorator(editorBase) {
  const monaco = useMonaco();
  const editor = editorBase?.getEditorType() === "vs.editor.IDiffEditor" ? editorBase.getModifiedEditor() : editorBase;
  const { t } = useTranslation();
  useEffect(() => {
    if (!editor || !monaco) return;

    const style = document.createElement("style");
    style.innerHTML = `
        .${stylePrefix}myLineDecoration {
            background-color: rgba(255, 0, 0, 0.1) !important;
            // border-left: 4px solid red !important;
        }
        .${stylePrefix}myGlyphMarginClass {
            // background-color: rgba(255, 165, 0, 0.5) !important;
        }
        .${stylePrefix}myInlineWarning:after {
            content: '${t("monaco.doubleNewLineDecorator.warningText")}';
            color: red;
            font-weight: bold;
            margin-left: 10px;
        }
`;
    document.head.appendChild(style);

    let decorations;

    const updateDecorations = () => {
      let newDecorations = [];
      //   const markers = [];

      if (!editor.hasModel()) return;

      const model = editor.getModel();
      const text = model.getValue();

      decorations?.clear();

      const pattern = /((\r?\n){1,}[ ]+\r?\n|\r?\n[ ]+(\r?\n){1,})/g;
      let match;

      while ((match = pattern.exec(text)) !== null) {
        let startPos = model.getPositionAt(match.index + 1);
        let endPos = model.getPositionAt(match.index + match[0].length - 1); //-1 for the last newline character

        const startEndLineArray = [];
        for (let i = startPos.lineNumber; i <= endPos.lineNumber; i++) {
          startEndLineArray.push(model.getLineContent(i));
        }

        let matchLine;
        for (let i = 0; i < startEndLineArray.length; i++) {
          const line = startEndLineArray[i];
          if (line.match(/^((\r?\n)| )+$/)) {
            matchLine = i + startPos.lineNumber;
          }
        }
        newDecorations.push({
          range: new monaco.Range(matchLine, 1, matchLine, 1),
          options: {
            overviewRuler: {
              color: "rgba(255, 0, 0, 0.5)",
              position: monaco.editor.OverviewRulerLane.Center,
            },
            isWholeLine: true,
            className: stylePrefix + "myLineDecoration",
            linesDecorationsClassName: stylePrefix + "myGlyphMarginClass",
            inlineClassName: stylePrefix + "myInlineWarning", // Uyarı metni buraya gelecek
          },
        });
      }

      //remove duplicate location decorations
      newDecorations = newDecorations.filter(
        (v, i, a) =>
          a.findIndex(
            (t) => t.range.startLineNumber === v.range.startLineNumber && t.range.startColumn === v.range.startColumn
          ) === i
      );
      decorations = editor.createDecorationsCollection(newDecorations);
    };

    updateDecorations();
    const disposables = [];
    disposables.push(editor.onDidChangeModelContent(updateDecorations));

    return () => {
      decorations?.clear();
      document.head.removeChild(style);
      disposables.forEach((d) => d.dispose());
    };
  }, [editor, monaco, t]);
}
