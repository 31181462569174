import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { MdAdd, MdCode, MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Badge, Col, Label, Row, Table } from "reactstrap";
import * as Yup from "yup";

import useFormikEx from "~common/hooks/useFormikEx";
import useLoading from "~common/hooks/useLoading";
import useProjectChange from "~common/hooks/useProjectChange";
import { PRAccordionItem } from "~components/Generic/PRAccordion";
import PRAccordion from "~components/Generic/PRAccordion/PRAccordionBody";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRInput from "~components/Generic/PRInput";
import PRPage from "~components/Generic/PRPage";
import PRSelect from "~components/Generic/PRSelect";
import LowCodeEditorModal from "~components/LowCodeEditor";
import {
  moduleVersionErrorPolicyType,
  moduleVersionErrorPolicyTypeOptions,
  moduleVersionStatusType,
  moduleVersionStatusTypeMap,
  moduleVersionStatusTypeOptions,
} from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import { getProcedureList } from "~store/dialogComponents/scenarioManager/actions";
import {
  createOrUpdateModuleVersion,
  getModule,
  getModuleVersion,
  getTriggerList,
  setModule,
} from "~store/integration/module/actions";
import { selectModule, selectTriggerList } from "~store/integration/module/selectors";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

// function ModuleVersionEditModalContext({ get, id }) {
//   const [procedure, setProcedure] = useState(null);
//   const currentProject = useSelector(selectCurrentProject);
//   const currentBot = useSelector(selectCurrentBot);
//   const [loading, q] = useLoading(false);
//   const dispatch = useDispatch();
//   useEffect(() => {
//     if (!id) return;
//     q(dispatch(getProcedure(currentProject.id, currentBot.id, id))).then((res) => {
//       setProcedure(res);
//     });
//   }, [id, currentProject.id, currentBot.id, dispatch, q]);
//   const handleChange = (value) => {
//     setProcedure((prev) => ({ ...prev, implementation: value }));
//   };

//   const handleSave = (e) => {
//     q(dispatch(createOrUpdateProcedure(currentProject.id, currentBot.id, procedure)));
//     if (!e.shiftKey) {
//       get(true)();
//     }
//   };

//   return (
//     <PRModal
//       title={"Edit Procedure"}
//       submitText={"Update"}
//       onClose={get(false)}
//       onClick={handleSave}
//       loading={loading}
//       size="xl"
//     >
//       <CodeEditor value={procedure?.implementation} onChange={handleChange} />
//     </PRModal>
//   );
// }

// const ModuleVersionEditModal = withCardon(ModuleVersionEditModalContext, { destroyOnHide: true });
const ModuleVersion = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const module = useSelector(selectModule);
  // const moduleVersion = useSelector(selectModuleVersion);
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const triggerList = useSelector(selectTriggerList);
  // const procedureList = useSelector(selectProcedureList);
  const [loading, q] = useLoading();

  const [selectedVersionId, setSelectedVersionId] = useState(null);
  // const [selectedLowCode, setSelectedLowCode] = useState(null);
  const [moduleVersionList, setModuleVersionList] = useState([]);
  const [accordionState, setAccordionState] = useState({
    1: true,
    2: false,
    3: false,
  });

  const handleClickCancel = () => {
    HistoryHelper.goBack("/integration/module/", { scope: "dashboard" });
  };

  const handleClickAccordion = (id) => (e) => {
    let newState = {
      ...accordionState,
      [id]: !accordionState[id],
    };
    if (e.shiftKey) {
      newState = {
        [id]: !accordionState[id],
      };
    }
    setAccordionState(newState);
  };

  useEffect(() => {
    if (!id) return;
    q(dispatch(getModule(currentProject.id, id)));

    return () => {
      dispatch(setModule(null));
    };
  }, [currentProject.id, dispatch, id, q]);

  useEffect(() => {
    q(dispatch(getTriggerList()));
    q(dispatch(getProcedureList(currentProject.id, currentBot.id)));
  }, [currentProject.id, dispatch, currentBot.id, q]);

  useEffect(() => {
    if (!module?.versions?.length) return;
    q(
      Promise.all(module.versions.map((mId) => dispatch(getModuleVersion(currentProject.id, mId)))).then((res) => {
        setModuleVersionList(res);
        if (res?.length) {
          setSelectedVersionId(res[res.length - 1]?.id);
        }
      })
    );
  }, [currentProject.id, dispatch, module?.versions, q]);

  const selectedModuleVersionItem = useMemo(() => {
    return moduleVersionList?.find((v) => v.id === selectedVersionId);
  }, [moduleVersionList, selectedVersionId]);

  const formikVersion = useFormikEx({
    enableReinitialize: true,
    initialValues: {
      ...selectedModuleVersionItem,
      parameters: selectedModuleVersionItem?.parameters || [],
      triggers: selectedModuleVersionItem?.triggers || [],
      lc_modules: selectedModuleVersionItem?.lc_modules || [],
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values, f, e) => {},
  });

  // const procedureListOptions = useMemo(() => {
  //   return procedureList.map((item) => ({
  //     label: item.name,
  //     value: item.id,
  //     disabled: formikVersion.values?.lc_modules?.some((m) => m === item.id),
  //   }));
  // }, [procedureList, formikVersion.values?.lc_modules]);

  useProjectChange(() => {
    HistoryHelper.push("/integration/module/", { scope: "dashboard" });
  }, []);

  const parentName = useMemo(
    () => [
      {
        label: t("common.integration"),
        url: "/integration/module",
      },
      {
        label: t("dashboard.moduleVersions"),
      },
    ],
    [t]
  );

  const createNewVersion = async () => {
    if (await DialogHelper.showQuestionYesNo("", t("dashboard.moduleVersions.createVersionQuestion"))) {
      await q(dispatch(createOrUpdateModuleVersion(currentProject.id, id)));
      q(dispatch(getModule(currentProject.id, id)));
    }
  };

  const moduleVersionOptions = useMemo(() => {
    if (!moduleVersionList) return;

    return moduleVersionList.map((v) => ({
      label: `v${v.version} - ${t(moduleVersionStatusTypeMap[v.status])}`,
      value: v.id,
    }));
  }, [moduleVersionList, t]);

  const handleChangeVersionParameter = (index, key, isCheckbox) => (e) => {
    const value = isCheckbox ? e.target.checked : e.target.value;
    formikVersion.setFieldValue("parameters", [
      ...formikVersion.values.parameters.map((p, i) => {
        if (i === index) {
          return {
            ...p,
            [key]: value,
          };
        }
        return p;
      }),
    ]);
  };

  const handleAddVersionParameter = () => {
    formikVersion.setFieldValue("parameters", [...formikVersion.values.parameters, { name: "", value: "" }]);
  };

  const handleDeleteVersionParameter = (index) => () => {
    formikVersion.setFieldValue(
      "parameters",
      formikVersion.values.parameters.filter((_, i) => i !== index)
    );
  };

  const handleChangeVersionTrigger = (index, key, isSelect) => (e) => {
    const value = isSelect ? e : e.target.value;
    formikVersion.setFieldValue("triggers", [
      ...formikVersion.values.triggers.map((p, i) => {
        if (i === index) {
          return {
            ...p,
            [key]: value,
          };
        }
        return p;
      }),
    ]);
  };

  const handleAddVersionTrigger = () => {
    formikVersion.setFieldValue("triggers", [
      ...formikVersion.values.triggers,
      { trigger: null, error_policy: moduleVersionErrorPolicyType[0] },
    ]);
  };

  const handleDeleteVersionTrigger = (index) => () => {
    formikVersion.setFieldValue(
      "triggers",
      formikVersion.values.triggers.filter((_, i) => i !== index)
    );
  };

  // const handleDeleteVersionLowCodeModule = (index) => () => {
  //   formikVersion.setFieldValue(
  //     "lc_modules",
  //     formikVersion.values.lc_modules.filter((_, i) => i !== index)
  //   );
  // };

  // const handleEditLowCodeModule = (id) => async () => {
  //   ModuleVersionEditModal.show({ id });
  // };

  // const handleAddVersionLowCodeModule = () => {
  //   if (!selectedLowCode) return;
  //   if (formikVersion.values?.lc_modules?.some((m) => m === selectedLowCode)) {
  //     setSelectedLowCode(null);
  //     return;
  //   }
  //   const newLowCodes = [...formikVersion.values.lc_modules, selectedLowCode];
  //   const firstNonSelectedLowCode = procedureListOptions.find((p) => !newLowCodes.includes(p.value));
  //   if (firstNonSelectedLowCode) {
  //     setSelectedLowCode(firstNonSelectedLowCode.value);
  //   }
  //   formikVersion.setFieldValue("lc_modules", newLowCodes);
  // };

  const handleSetVersionStatus = (status) => {
    formikVersion.setFieldValue("status", status);
  };

  const handleSubmit = async (e) => {
    const errors = [];

    if (formikVersion.values.parameters.some((p) => !p.name)) {
      errors.push(t("dashboard.moduleVersions.nameNotFilledError"));
    }
    if (formikVersion.values.parameters.some((p) => !p.description)) {
      errors.push(t("dashboard.moduleVersions.descriptionNotFilledError"));
    }
    if (formikVersion.values.parameters.some((p) => !p.default_value)) {
      errors.push(t("dashboard.moduleVersions.defaultValueNotFilledError"));
    }
    if (formikVersion.values.triggers.some((p) => !p.trigger)) {
      errors.push(t("dashboard.moduleVersions.triggerNotFilledError"));
    }
    if (errors.length > 0) {
      DialogHelper.showValidate(errors);
      return;
    }

    const payload = { ...formikVersion.values, triggers: [...formikVersion.values.triggers.map((a) => ({ ...a }))] };
    for (const trigger of payload.triggers) {
      if (trigger.error_policy) {
        trigger.error_policy = Number(trigger.error_policy);
      }
    }

    await dispatch(createOrUpdateModuleVersion(currentProject.id, id, payload));
    if (!e?.shiftKey) {
      HistoryHelper.goBack("/integration/module/", { scope: "dashboard" });
    }
  };

  const handleShowLowCodeEditor = async () => {
    // const originalLcModuleIdList = formikVersion.values.lc_modules || [];
    let lcModuleIdList = formikVersion.values.lc_modules || [];
    await LowCodeEditorModal.show({
      idList: lcModuleIdList,
      onSave: (response) => {
        if (!lcModuleIdList.includes(response.id)) {
          lcModuleIdList = [...lcModuleIdList, response.id];
        }
        const initItemOnLoad = { ...selectedModuleVersionItem, lc_modules: lcModuleIdList };
        dispatch(createOrUpdateModuleVersion(currentProject.id, id, initItemOnLoad, { successMsg: false }));
      },
      onDelete: (response) => {
        if (lcModuleIdList.includes(response.id)) {
          lcModuleIdList = lcModuleIdList.filter((id) => id !== response.id);
        }

        const initItemOnLoad = { ...selectedModuleVersionItem, lc_modules: lcModuleIdList };
        dispatch(createOrUpdateModuleVersion(currentProject.id, id, initItemOnLoad, { successMsg: false }));
      },
    });
    formikVersion.setFieldValue("lc_modules", lcModuleIdList);

    // function areIdsEqual(list1, list2) {
    //   if ((!list1 && !list2) || list1 === list2) return true;
    //   if (list1?.length !== list2?.length) {
    //     return false;
    //   }

    //   for (let i = 0; i < list1.length; i++) {
    //     if (list1[i].id !== list2[i].id) {
    //       return false;
    //     }
    //   }

    //   return true;
    // }
    // let isModified = areIdsEqual(originalLcModuleIdList, lcModuleIdList);
    // if (isModified) {
    //   const initItemOnLoad = { ...selectedModuleVersionItem, lc_modules: lcModuleIdList || [] };
    //   await dispatch(createOrUpdateModuleVersion(currentProject.id, id, initItemOnLoad));
    // }
  };
  const actions = [
    {
      label: t("common.createNew"),
      icon: MdAdd,
      color: "success",
      onClick: createNewVersion,
    },
  ];

  const moduleVersionStatusTypeOptionsColored = useMemo(() => {
    return moduleVersionStatusTypeOptions.map((o) => {
      let badgeColor = "secondary";
      if (o.value === moduleVersionStatusType[1]) {
        badgeColor = "primary";
      } else if (o.value === moduleVersionStatusType[2]) {
        badgeColor = "success-600";
      } else if (o.value === moduleVersionStatusType[3]) {
        badgeColor = "danger";
      }
      return {
        label: (
          <div className={"font-size-12 badge border border-light rounded-pill bg-" + badgeColor}>{t(o.label)}</div>
        ),
        value: o.value,
      };
    });
  }, [t]);
  const actions2 = [
    {
      icon: MdCode,
      color: "primary",
      onClick: handleShowLowCodeEditor,
      render: () => {
        return (
          <>
            {t("dashboard.moduleVersions.manageLowCode")}
            <Badge className="ms-1" color={"danger"}>
              {formikVersion.values.lc_modules?.length || 0}
            </Badge>
          </>
        );
      },
    },
  ];

  return (
    <PRContainer actions={actions} loading={loading} name={t("common.integration")} parentName={parentName}>
      <PRPage title={t("dashboard.moduleVersions.versions")}>
        <Row className="g-0 gy-2">
          <Col xs="12">
            <Row className="g-2">
              <Col xs="4">
                <PRSelect
                  fullWidth
                  isPrimitiveValue
                  isClearable={false}
                  options={moduleVersionOptions}
                  value={selectedVersionId}
                  onChange={setSelectedVersionId}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </PRPage>
      <PRPage actions={actions2} title={t("common.version")}>
        <div disabled={!selectedVersionId}>
          <Row className="g-0 gy-2">
            <Col xs="4">
              <Label className="text-muted">{t("common.status")}:</Label>
              <PRSelect
                fullWidth
                isPrimitiveValue
                isClearable={false}
                options={moduleVersionStatusTypeOptionsColored}
                value={formikVersion.values.status?.toString()}
                onChange={handleSetVersionStatus}
              />
            </Col>
            <Col xs="12">
              <PRAccordion flush underline>
                <PRAccordionItem
                  noSpacing
                  collapsed={accordionState[1]}
                  title={
                    <>
                      {t("common.parameters")}
                      <Badge className="ms-1" color={"primary"}>
                        {!!formikVersion.values?.parameters?.length && formikVersion.values?.parameters?.length}
                      </Badge>
                    </>
                  }
                  onClick={handleClickAccordion(1)}
                >
                  <Row className="g-0 justify-content-end">
                    <Col className="m-2 me-0" xs="auto">
                      <PRButton color="success" icon={MdAdd} onClick={handleAddVersionParameter}>
                        {t("common.add")}
                      </PRButton>
                    </Col>
                  </Row>
                  <div className="">
                    <Table className="align-middle" size="sm">
                      <thead>
                        <tr>
                          <th>{t("common.name")}</th>
                          <th>{t("common.description")}</th>
                          <th>{t("dashboard.moduleVersions.defaultValue")}</th>
                          <th>{t("common.required")}</th>
                          <th>{t("dashboard.moduleVersions.isSecret")}</th>
                          <th style={{ width: "1%" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {formikVersion.values?.parameters?.map((p, index) => (
                          <tr key={p.id || index}>
                            <td>
                              <PRInput
                                name="name"
                                placeholder={t("common.namePlaceholder")}
                                value={p.name}
                                onChange={handleChangeVersionParameter(index, "name")}
                              />
                            </td>
                            <td>
                              <PRInput
                                name="description"
                                placeholder={t("dashboard.moduleVersions.descriptionPlaceholder")}
                                value={p.description}
                                onChange={handleChangeVersionParameter(index, "description")}
                              />
                            </td>
                            <td>
                              <PRInput
                                name="default_value"
                                placeholder={t("dashboard.moduleVersions.defaultValuePlaceholder")}
                                value={p.default_value}
                                onChange={handleChangeVersionParameter(index, "default_value")}
                              />
                            </td>
                            <td>
                              <PRInput
                                checked={p.required}
                                type="checkbox"
                                onChange={handleChangeVersionParameter(index, "required", true)}
                              />
                            </td>
                            <td>
                              <PRInput
                                checked={p.is_secret}
                                type="checkbox"
                                onChange={handleChangeVersionParameter(index, "is_secret", true)}
                              />
                            </td>
                            <td className="p-0">
                              <PRButton
                                outline
                                color="danger"
                                icon={MdDelete}
                                onClick={handleDeleteVersionParameter(index)}
                              />
                            </td>
                          </tr>
                        ))}
                        {!formikVersion.values?.parameters?.length && (
                          <tr>
                            <td className="text-center text-muted" colSpan="6">
                              {t("dashboard.moduleVersions.noParameters")}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </PRAccordionItem>
                <PRAccordionItem
                  noSpacing
                  secondary
                  collapsed={accordionState[2]}
                  title={
                    <div className="position-relative">
                      {t("dashboard.moduleVersions.triggers")}
                      <Badge className="ms-1" color={"primary"}>
                        {!!formikVersion.values?.triggers?.length && formikVersion.values?.triggers?.length}
                      </Badge>
                      {/* {!!formikVersion.values?.triggers?.length && (
                        <span className="ms-2 position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-1">
                          <span className="visually-hidden"></span>
                        </span>
                      )} */}
                    </div>
                  }
                  onClick={handleClickAccordion(2)}
                >
                  <Row className="g-0">
                    <Col className="m-2 ms-0" xs="12">
                      <Row className="g-0 justify-content-end">
                        <Col className="" xs="auto">
                          <PRButton color="success" icon={MdAdd} onClick={handleAddVersionTrigger}>
                            {t("common.add")}
                          </PRButton>
                        </Col>
                      </Row>

                      <Table className="align-middle" size="sm">
                        <thead>
                          <tr>
                            <th style={{ width: "50%" }}>{t("dashboard.moduleVersions.trigger")}</th>
                            <th>{t("dashboard.moduleVersions.errorPolicy")}</th>
                            <th style={{ width: "1%" }}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {formikVersion.values?.triggers?.map((p, index) => (
                            <tr key={p.id || index}>
                              <td>
                                <PRSelect
                                  isPrimitiveValue
                                  isClearable={false}
                                  labelSelector="display_name"
                                  options={triggerList}
                                  value={p.trigger}
                                  valueSelector="id"
                                  onBlur={formikVersion.handleBlur}
                                  onChange={handleChangeVersionTrigger(index, "trigger", true)}
                                />
                              </td>
                              <td>
                                <PRSelect
                                  isPrimitiveValue
                                  isClearable={false}
                                  options={moduleVersionErrorPolicyTypeOptions}
                                  value={p.error_policy}
                                  onBlur={formikVersion.handleBlur}
                                  onChange={handleChangeVersionTrigger(index, "error_policy", true)}
                                />
                              </td>
                              <td className="p-0">
                                <PRButton
                                  outline
                                  color="danger"
                                  icon={MdDelete}
                                  onClick={handleDeleteVersionTrigger(index)}
                                />
                              </td>
                            </tr>
                          ))}
                          {!formikVersion.values?.triggers?.length && (
                            <tr>
                              <td className="text-center text-muted" colSpan="6">
                                {t("dashboard.moduleVersions.noTriggers")}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                    {/* <Col className="m-2 me-0" xs>
                      <Label className="mb-2 text-secondary">LowCode Modules</Label>
                      <Row className="g-2 ">
                        <Col xs="6" className="d-flex align-items-center gap-2 text-nowrap">
                          <PRSelect
                            options={procedureListOptions}
                            value={selectedLowCode}
                            onChange={setSelectedLowCode}
                            isPrimitiveValue
                            fullWidth
                          />
                        </Col>
                        <Col xs></Col>
                        <Col xs="auto" className="d-flex justify-content-end">
                          <PRButton icon={MdAdd} color="success" onClick={handleAddVersionLowCodeModule}>
                            Add
                          </PRButton>
                        </Col>
                      </Row>
                      <Table size="sm" className="align-middle">
                        <thead>
                          <tr>
                            <th>LowCode</th>
                            <th style={{ width: "1%" }}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {procedureList?.length &&
                            formikVersion.values?.lc_modules.map((id, index) => {
                              const lowCode = procedureList.find((p) => p.id == id);
                              return (
                                <tr key={id || index}>
                                  <td>
                                    <div className="me-2">{lowCode?.name}</div>
                                  </td>
                                  <td className=" pe-0 d-flex gap-1">
                                    <PRButton
                                      icon="??? fa-code"
                                      color="primary"
                                      tooltipText="Edit Low Code"
                                      outline
                                      size="sm"
                                      onClick={handleEditLowCodeModule(id)}
                                    />
                                    <PRButton
                                      icon={MdDelete}
                                      color="danger"
                                      outline
                                      size="sm"
                                      onClick={handleDeleteVersionLowCodeModule(index)}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          {!formikVersion.values?.lc_modules?.length && (
                            <tr>
                              <td colSpan="6" className="text-center text-muted">
                                No LowCode
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Col> */}
                  </Row>
                </PRAccordionItem>
              </PRAccordion>
            </Col>
          </Row>
          <Row className="justify-content-end mt-2">
            <Col xs="auto">
              <PRButton outline className="me-2" onClick={handleClickCancel}>
                {t("common.cancel")}
              </PRButton>
              <PRButton onClick={handleSubmit}>{t("common.update")}</PRButton>
            </Col>
          </Row>
        </div>
      </PRPage>
    </PRContainer>
  );
};

export default ModuleVersion;
