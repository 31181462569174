import { safeProduce as produce } from "~helpers/immer";

import * as at from "./user.types";

/**
 * @typedef {typeof import("~constants").userRole} UserRoleObj
 *
 * @typedef {keyof UserRoleObj} UserRole
 *
 * @typedef {{
 *   language: string;
 *   id: number;
 *   projectId: number;
 *   name: string;
 *   status: string;
 *   smalltalk?: {
 *     id: number;
 *     name: string;
 *   };
 *   isSmalltalk?: boolean;
 *   smalltalkName?: string;
 *   parentBotId?: number;
 *   parentBot?: Bot;
 * }} Bot
 *
 *
 * @typedef {{
 *   id: number;
 *   name: string;
 *   chatbot_list: Bot[];
 *   permissions: UserRole[];
 *   chatbot_status: {
 *     [key: string]: { id: number; name: string; status: string; smalltalk: { id: number; name: string } };
 *   };
 * }} Project
 */
export const initState = {
  userInfo: {
    id: 0,
    firstname: "",
    lastname: "",
    /** @type {"Standard" | "Admin" | "Moderator"} */
    role_title: "",
    email: "",
    profile_image: null,
    join_date: "",
    /**
     * @type {{
     *   project: number;
     *   project_name: string;
     *   role: string;
     * }[]}
     */
    project_roles: [],
    company: 0,
    username: "",
    is_superuser: false,
    two_factor_enabled: false,
    /** @type {keyof typeof import("~constants/user").userAuthenticationMethodMap} */
    preferred_2fa_method: null,
  },
  /** @type {Project[]} */
  projects: null,
  /** @type {Project} */
  selectedProject: {},
  /** @type {Bot[]} */
  bots: [],
  /** @type {Bot} */
  selectedBot: {},
};

// function initializeState(initState) {
//   const state = { ...initState };
// }

const userReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_CURRENT_USER:
        const { projects = [], ...rest } = action.payload;
        draft.userInfo = rest;
        draft.projects = projects;
        const allBots = projects.reduce((acc, project) => {
          let bots =
            project.chatbot_list ||
            Object.entries(project.chatbot_status).map(([key, value]) => ({
              language: key,
              ...value,
            }));

          const botsWithProjectId = bots.map((item) => ({
            projectId: project.id,
            ...item,
          }));
          const smalltalkBots = botsWithProjectId
            .filter((bot) => bot.smalltalk)
            .map((bot) => ({
              ...bot.smalltalk,
              isSmalltalk: true,
              smalltalkName: bot.smalltalk.name,
              name: bot.name,
              parentBotId: bot.id,
              parentBot: bot,
              projectId: bot.projectId,
              language: bot.language,
            }));

          return [...acc, ...botsWithProjectId, ...smalltalkBots];
        }, []);

        draft.bots = allBots;
        break;
      case at.SET_SELECTED_PROJECT:
        draft.selectedProject = action.payload;
        break;
      case at.SET_SELECTED_BOT:
        draft.selectedBot = action.payload;
        break;
    }
  });

export default userReducer;
