import ConstantHelper from "~helpers/ConstantHelper";

export const audioList = {
  noSound: "noSound",
  recognition: "recognition",
  recognitionError: "recognitionError",
  agentWaitingAlert: "agentWaitingAlert",
  agentNewMessage: "agentNewMessage",
  customerNewMessage: "customerNewMessage",
  customerAgentJoined: "customerAgentJoined",
  agentTransferred: "agentTransferred",
};

export const [lookupKeys, lookupKeysOptions, lookupKeysMap] = ConstantHelper.typify({
  contains: "Contains",
  icontains: "Contains (case insensitive)",
  startswith: "Starts with",
  istartswith: "Starts with (case insensitive)",
  endswith: "Ends with",
  iendswith: "Ends with (case insensitive)",
  exact: "Equals",
  gt: "Greater than",
  gte: "Greater than or equal",
  lt: "Less than",
  lte: "Less than or equal",
  in: "Includes",
  isnull: "Is null",
});
