import { useEffect, useState } from "react";

import { withCardon } from "cardon";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { BiLoaderAlt } from "react-icons/bi";
import { MdOutlineWarningAmber } from "react-icons/md";
import { useSelector } from "react-redux";
import { Alert as BSAlert, Col, ListGroup, ListGroupItem, Row } from "reactstrap";

import { Box, Switch } from "@mui/material";

import { getBotSettings, trainBot, updateBotSettings } from "~apiRequests/bot";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRInput, { PRTextArea } from "~components/Generic/PRInput";
import PRLink from "~components/Generic/PRLink";
import PRModal from "~components/Generic/PRModal";
import PRPage from "~components/Generic/PRPage";
import AlertHelper from "~helpers/AlertHelper";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import LoadingHelper from "~helpers/LoadingHelper";
import { selectCurrentBot, selectCurrentProject, selectUserInfo } from "~store/user/selectors";

import DisplayLogsModal from "./display.logs";

function UntrainedRagIntentModalContent({ get, untrainedIntents }) {
  const [trainIntentIds, setTrainIntentIds] = useState([]);
  const handleChangeSwitch = () => {
    if (!trainIntentIds?.length) {
      setTrainIntentIds(untrainedIntents.map((intent) => intent.id));
    } else {
      setTrainIntentIds([]);
    }
  };

  return (
    <PRModal
      submitText={t("dashboard.botSettings.continue")}
      title={t("dashboard.botSettings.untrainedRagIntents")}
      onClick={get(trainIntentIds)}
      onClose={get([])}
    >
      <p>{t("dashboard.botSettings.untrainedRagIntentsDescription")}:</p>
      <Box maxHeight={500} overflow="auto">
        <ListGroup>
          {untrainedIntents.map((intent) => (
            <ListGroupItem key={intent.id}>
              <PRLink newTab target="_blank" to={`/chatbot/intent/form/${intent.id}`}>
                {intent.display_text || intent.text}
              </PRLink>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Box>
      <p className="mt-3">{t("dashboard.botSettings.untrainedRagIntentsWarning")}</p>
      <Box>
        <Switch checked={trainIntentIds?.length} onChange={handleChangeSwitch} />
        {trainIntentIds?.length
          ? t("dashboard.botSettings.intentTrainingAll")
          : t("dashboard.botSettings.intentTrainingOnly")}
      </Box>
    </PRModal>
  );
}

const UntrainedRagIntentModal = withCardon(UntrainedRagIntentModalContent, { destroyOnHide: true });

function BotSettings() {
  const { t } = useTranslation();
  const [botSettings, setBotSettings] = useState(null);
  const [pageRenderHelper, setPageRenderHelper] = useState(false);
  const userInfo = useSelector(selectUserInfo);

  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  useEffect(() => {
    LoadingHelper.open();
    getBotSettings(currentProject.id, currentBot.id)
      .then((response) => {
        setBotSettings(response);
      })
      .finally(() => {
        LoadingHelper.close();
      });
  }, [currentBot, pageRenderHelper, currentProject]);

  function renderAlerts() {
    function renderAlertType() {
      if (botSettings?.alerts) {
        let waitIndex = botSettings?.alerts.findIndex((alert) => alert.resolve_type === "wait_go_live");
        if (waitIndex !== -1) {
          return (
            <BSAlert color="danger">
              <Row className="gx-2 align-items-center">
                <Col xs>{t("dashboard.botSettings.waitGoLive")}</Col>
              </Row>
            </BSAlert>
          );
        }

        let failIndex = botSettings?.alerts.findIndex((alert) => alert.resolve_type === "fail_train");
        if (failIndex !== -1) {
          return (
            <BSAlert color="danger">
              <Row className="gx-2 align-items-center">
                <Col xs>{t("dashboard.botSettings.failTrain")}</Col>
              </Row>
            </BSAlert>
          );
        }

        let waitTrainIndex = botSettings?.alerts.findIndex((alert) => alert.resolve_type === "wait_train");
        if (waitTrainIndex !== -1) {
          return (
            <BSAlert color="danger">
              <Row className="gx-2 align-items-center">
                <Col xs>
                  <BiLoaderAlt className="me-2 spin" />
                  {t("dashboard.botSettings.waitTrain")}
                </Col>
                <Col xs="auto">
                  <PRButton
                    color="secondary"
                    onClick={(e) => {
                      DisplayLogsModal.show();
                    }}
                  >
                    {t("dashboard.botSettings.trainLogsButton")}
                  </PRButton>
                </Col>
              </Row>
            </BSAlert>
          );
        } else {
          let trainIndex = botSettings?.alerts.findIndex((alert) => alert.resolve_type === "train");

          let goLiveIndex = botSettings?.alerts.findIndex((alert) => alert.resolve_type === "go_live");
          if (trainIndex !== -1) {
            return (
              <BSAlert color="danger">
                <Row className="gx-2 align-items-center">
                  <Col xs>
                    <MdOutlineWarningAmber className="me-2" />
                    {t("dashboard.botSettings.trainRestartRequired")}
                  </Col>
                  <Col xs="auto">
                    <PRButton
                      color="primary"
                      onClick={async (e) => {
                        let ragIntentIds = [];
                        if (botSettings?.rag_train_needed?.length > 0) {
                          const result = await UntrainedRagIntentModal.show({
                            untrainedIntents: botSettings?.rag_train_needed,
                          });
                          if (result?.length) {
                            ragIntentIds = result;
                          }
                        }
                        if (
                          !(await DialogHelper.showQuestionYesNo(
                            "",
                            <span
                              dangerouslySetInnerHTML={{
                                __html: t("dashboard.botSettings.trainConfirmationMessage").format(currentBot.name),
                              }}
                            />
                          ))
                        ) {
                          return;
                        }

                        trainBot(currentProject.id, currentBot.id, "train", {
                          ...(ragIntentIds?.length && { rag_intent_ids: ragIntentIds }),
                        })
                          .then((response) => {
                            AlertHelper.show(t("dashboard.botSettings.trainStartedAlert"));
                            setPageRenderHelper(!pageRenderHelper);
                          })
                          .catch((error) => {
                            AlertHelper.show(t("dashboard.botSettings.trainErrorAlert"), "error");
                          })
                          .finally(() => {});
                      }}
                    >
                      {t("dashboard.botSettings.trainButton")}
                    </PRButton>
                    {goLiveIndex > -1 && userInfo.is_superuser && (
                      <PRButton
                        className="ms-2"
                        color="primary"
                        onClick={async (e) => {
                          if (
                            !(await DialogHelper.showQuestionYesNo(
                              "",
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: t("dashboard.botSettings.goLiveConfirmationMessage").format(currentBot.name),
                                }}
                              />
                            ))
                          ) {
                            return;
                          }

                          trainBot(currentProject.id, currentBot.id, "go-live")
                            .then((response) => {
                              AlertHelper.show(t("dashboard.botSettings.goLiveStartedAlert"));
                              setPageRenderHelper(!pageRenderHelper);
                            })
                            .catch((error) => {
                              AlertHelper.show(t("dashboard.botSettings.goLiveErrorAlert"), "error");
                            });
                        }}
                      >
                        {t("dashboard.botSettings.goLiveButton")}
                      </PRButton>
                    )}
                  </Col>
                </Row>
              </BSAlert>
            );
          } else {
            let goLiveIndex = botSettings?.alerts.findIndex((alert) => alert.resolve_type === "go_live");

            if (goLiveIndex !== -1) {
              return (
                <BSAlert color="danger">
                  <Row className="gx-2 align-items-center">
                    <Col xs>{t("dashboard.botSettings.goLiveRequired")}</Col>
                    <Col xs="auto">
                      <PRButton
                        color="primary"
                        onClick={async (e) => {
                          if (
                            !(await DialogHelper.showQuestionYesNo(
                              "",
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: t("dashboard.botSettings.goLiveConfirmationMessage").format(currentBot.name),
                                }}
                              />
                            ))
                          ) {
                            return;
                          }

                          trainBot(currentProject.id, currentBot.id, "go-live")
                            .then((response) => {
                              AlertHelper.show(t("dashboard.botSettings.goLiveStartedAlert"));
                              setPageRenderHelper(!pageRenderHelper);
                            })
                            .catch((error) => {
                              AlertHelper.show(t("dashboard.botSettings.goLiveErrorAlert"), "error");
                            });
                        }}
                      >
                        {t("dashboard.botSettings.goLiveButton")}
                      </PRButton>
                    </Col>
                  </Row>
                </BSAlert>
              );
            } else {
              return (
                <BSAlert color="success">
                  <Row className="gx-2 align-items-center">
                    <Col xs>{t("dashboard.botSettings.alreadyGoLive")}</Col>
                  </Row>
                </BSAlert>
              );
            }
          }
        }
      }
    }

    return <>{renderAlertType()}</>;
  }

  const handleChange = (key, isCheckbox) => (e) => {
    const value = isCheckbox ? e.target.checked : e.target.value;
    setBotSettings((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <PRContainer
      smalltalkSelector
      description={t("dashboard.botSettings.description")}
      name={t("layout.sidebar.chatbot")}
      parentName={t("layout.sidebar.botSettings")}
    >
      {botSettings?.is_fake ? (
        <BSAlert color="warning">
          <Row className="gx-2 align-items-center">
            <Col xs>{t("dashboard.botSettings.fakeBotAlert")}</Col>
          </Row>
        </BSAlert>
      ) : (
        <div className="fw-semibold">{renderAlerts()}</div>
      )}
      <PRPage
        title={
          botSettings?.name
            ? t("dashboard.botSettings.title").format(botSettings?.name)
            : t("dashboard.botSettings.titleWithoutName")
        }
      >
        <Row className="d-flex align-items-center">
          <Col lg={4}>{t("dashboard.botSettings.botName")}</Col>
          <Col lg={8}>
            <PRInput
              style={{ width: "40%" }}
              type="text"
              value={botSettings?.name || ""}
              onChange={handleChange("name")}
            />
          </Col>
        </Row>
        {userInfo.is_superuser && (
          <Row className="mt-4 d-flex align-items-start">
            <Col lg={4}>{t("dashboard.botSettings.botDescription")}:</Col>
            <Col lg={8}>
              <PRTextArea
                style={{ width: "40%" }}
                type="text"
                value={botSettings?.awareness_prompt || ""}
                onChange={handleChange("awareness_prompt")}
              />
            </Col>
          </Row>
        )}
        <Row className="mt-4 d-flex align-items-center">
          <Col lg={4}>{t("dashboard.botSettings.botEnabled")}</Col>
          <Col lg={8}>
            <PRInput checked={botSettings?.enabled} type="checkbox" onChange={handleChange("enabled", true)} />
          </Col>
        </Row>
        {/* <Row className="mt-4 d-flex align-items-center">
          <Col lg={4}>Feedback Enabled:</Col>
          <Col lg={8}>
            <PRInput
              type="checkbox"
              checked={botSettings?.feedback_enabled}
              onChange={handleChange("feedback_enabled", true)}
            />
          </Col>
        </Row> */}
        {/* <Row className="mt-4 d-flex align-items-center">
          <Col lg={4}>Message Validation:</Col>
          <Col lg={8}>
            <PRInput
              type="checkbox"
              checked={botSettings?.message_validation}
              onChange={handleChange("message_validation", true)}
            />
          </Col>
        </Row> */}
        <Row className="mt-4 d-flex align-items-center">
          <Col lg={4}>{t("dashboard.botSettings.remainingTrain")}</Col>
          <Col lg={8}>
            <label>{botSettings?.train_info?.remaining}</label>
          </Col>
        </Row>
        <Row className="mt-4 d-flex align-items-center">
          <Col lg={4}>{t("dashboard.botSettings.trainTime")}</Col>
          <Col lg={8}>
            {botSettings?.train_info?.train_time ? (
              <label>
                {DateHelper.getDateTimeLocal(botSettings?.train_info?.train_time).format("LLT")} (
                {DateHelper.getDateTimeLocal(botSettings?.train_info?.train_time).fromNow()})
              </label>
            ) : (
              "-"
            )}
          </Col>
        </Row>
        <Row className="mt-4 d-flex align-items-center">
          <Col lg={4}>{t("dashboard.botSettings.goLiveTime")}</Col>
          <Col lg={8}>
            {botSettings?.train_info?.go_live_time ? (
              <label>
                {DateHelper.getDateTimeLocal(botSettings?.train_info?.go_live_time).format("LLT")} (
                {DateHelper.getDateTimeLocal(botSettings?.train_info?.go_live_time).fromNow()})
              </label>
            ) : (
              "-"
            )}
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end" lg={12}>
            <PRButton
              color="primary"
              onClick={(e) => {
                LoadingHelper.open();
                updateBotSettings(currentProject.id, currentBot.id, botSettings)
                  .then((response) => {
                    AlertHelper.show(t("dashboard.botSettings.saveSuccessAlert"));
                  })
                  .finally(() => {
                    LoadingHelper.close();
                  });
              }}
            >
              {t("dashboard.botSettings.saveButton")}
            </PRButton>
          </Col>
        </Row>
      </PRPage>
    </PRContainer>
  );
}

export default BotSettings;
