import { createSelector } from "reselect";

/** @type {() => typeof import("./reducer").initState} */
export const selectRoot = (state) => state.announcement;

export const selectAppAnnouncementList = createSelector(selectRoot, (root) => root.announcementList);
export const selectAppAcknowledgedAnnouncementIds = createSelector(
  selectRoot,
  (root) => root.acknowledgedAnnouncementIds
);

export const selectVisibleAppAnnouncementList = createSelector(
  selectAppAnnouncementList,
  selectAppAcknowledgedAnnouncementIds,
  (appAnnouncementList, appAcknowledgedAnnouncementIds) => {
    return appAnnouncementList.filter((announcement) => !appAcknowledgedAnnouncementIds.includes(announcement.id));
  }
);

export const selectExistAndInvisibleAppAnnouncementList = createSelector(
  selectAppAnnouncementList,
  selectAppAcknowledgedAnnouncementIds,
  (appAnnouncementList, appAcknowledgedAnnouncementIds) => {
    const existList = appAnnouncementList.filter((announcement) =>
      appAcknowledgedAnnouncementIds.includes(announcement.id)
    );
    return existList;
  }
);
