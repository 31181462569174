import { memo, useMemo, useState } from "react";

import classNames from "classnames";
import { get, isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { Nav, NavItem, NavLink } from "reactstrap";

import useBootstrapBreakpoint from "~common/hooks/useBootstrapBreakpoint";

import "./style.scss";

export default memo(function PRTab({
  isTranslate,
  tabList,
  className,
  labelSelector = "label",
  labelRenderer,
  valueSelector = "id",
  closeIconRenderer,
  itemClassName,
  linkClassName,
  tab,
  onChange,
  onClose,
  pill,
  border,
  noUnderline,
  fullWidth,
  disabled,
}) {
  const { t } = useTranslation();
  const [tabState, setTabState] = useState(tab);
  const [selectedHover, setSelectedHover] = useState(false);
  const handleChangeTab = (tab) => () => {
    const isNonClickable = !!tab.nonClickable;
    if (isNonClickable) return;
    const value = get(tab, valueSelector);
    setTabState(value);
    onChange?.(value, tab);
  };

  const isClosable = typeof onClose === "function";
  const isSm = useBootstrapBreakpoint("sm");
  const activeTab = tab || tabState;

  const handleCloseHover = useMemo(() => {
    return (isHover) => () => {
      setSelectedHover(isHover);
    };
  }, []);
  return (
    <Nav
      tabs
      className={classNames("pr-tab", className, "gap-1", {
        "nav-tabs-custom": !pill,
        "nav-pills": pill,
        "border-bottom-0": pill,
        "no-underline": noUnderline,
        "full-width": fullWidth,
        sm: isSm,
      })}
      role="tablist"
    >
      {tabList?.map((tab, index) => {
        let label = get(tab, labelSelector);
        if (typeof labelRenderer === "function") {
          label = labelRenderer(tab);
        }
        const isCustomRender = typeof tab.render === "function";
        const value = get(tab, valueSelector);
        let TabButtonComp = MdClose;
        if (typeof closeIconRenderer === "function") {
          const newCloseIcon = closeIconRenderer(tab);
          if (newCloseIcon) {
            TabButtonComp = newCloseIcon;
          }
        }

        if (value === selectedHover || TabButtonComp === MdClose) {
          TabButtonComp = MdClose;
        }
        return (
          <NavItem key={index} className={classNames(itemClassName)}>
            <NavLink
              className={classNames(linkClassName, "position-relative", {
                "border border-primary": border,
                active: value === activeTab,
                "opacity-75": disabled,
                "p-0": isClosable,
              })}
              disabled={disabled}
              onClick={handleChangeTab(tab)}
            >
              {isCustomRender ? (
                <> {tab.render(tab)}</>
              ) : (
                <div
                  className={classNames("close-item d-flex align-items-center", {
                    " py-2 ps-2 pe-4 position-relative": isClosable,
                    "justify-content-center": fullWidth,
                  })}
                >
                  {isTranslate ? t(label) : label}
                  {isClosable && typeof TabButtonComp === "function" && (
                    <TabButtonComp
                      className="svg-icon fs-5 me-1"
                      onClick={(e) => {
                        e.stopPropagation();
                        onClose?.(value, tab);
                      }}
                      onMouseEnter={handleCloseHover(value)}
                      onMouseLeave={handleCloseHover(null)}
                    />
                  )}
                </div>
              )}
            </NavLink>
          </NavItem>
        );
      })}
    </Nav>
  );
}, isEqual);
