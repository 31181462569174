import { safeProduce as produce } from "~helpers/immer";

import * as actionTypes from "./actionTypes";

/**
 * @typedef {{
 *   id: number;
 *   member_data_format: any;
 *   name: string;
 *   display_name: any;
 *   is_required: boolean;
 *   identifier: boolean;
 *   is_manager: boolean;
 *   field_type: string;
 *   project: number;
 * }} MemberField
 */

/**
 * @typedef {{
 *   id: number;
 *   member_data_fields: {
 *     id: number;
 *     name: string;
 *     display_name: string;
 *     is_required: boolean;
 *     identifier: boolean;
 *     is_manager: boolean;
 *     field_type: string;
 *     visibility_type: string;
 *     member_data_format: number;
 *     project: number;
 *   }[];
 *   secret_keys: {
 *     id: number;
 *     name: string;
 *     expires_in: string;
 *     project: number;
 *   }[];
 *   project: number;
 * }} MemberFieldFormat
 */

/**
 * @typedef {{
 *   id: number;
 *   survey: number;
 *   question: string;
 *   question_type: typeof organizationQuestionType.CHOICE;
 *   options: string[];
 *   project: number;
 *   result: {
 *     count: number;
 *     histogram: {
 *       [key: string]: number;
 *     };
 *     top_choice: string;
 *   };
 * }} ChoiceAnswer
 *
 *
 * @typedef {{
 *   id: number;
 *   survey: number;
 *   question: string;
 *   question_type: typeof organizationQuestionType.RANGE;
 *   interval: [number, number];
 *   project: number;
 *   result: {
 *     mean: number;
 *     median: number;
 *     count: string;
 *   };
 * }} RangeAnswer
 *
 *
 * @typedef {{
 *   id: number;
 *   survey: number;
 *   question: string;
 *   question_type: typeof organizationQuestionType.TEXT;
 *   project: number;
 *   result: {
 *     responses: string[];
 *   };
 * }} TextAnswer
 *
 *
 * @typedef {{
 *   status: string;
 *   answers: (ChoiceAnswer | RangeAnswer | TextAnswer)[];
 *   total_count: number;
 *   waiting_count: number;
 *   started_count: number;
 *   finished_count: number;
 * }} SurveyResult
 */

/**
 * @typedef {object} ResourceItem
 * @property {number} id
 * @property {string} name
 * @property {string} resource_type
 * @property {null} member_filter
 * @property {object} reservation_status
 * @property {object} reservation_status.past
 * @property {number} reservation_status.past.reservation_count
 * @property {number} reservation_status.past.available_count
 * @property {number} reservation_status.past.slot_count
 * @property {object} reservation_status.future
 * @property {number} reservation_status.future.reservation_count
 * @property {number} reservation_status.future.available_count
 * @property {number} reservation_status.future.slot_count
 * @property {object} reservation_status.all
 * @property {number} reservation_status.all.reservation_count
 * @property {number} reservation_status.all.available_count
 * @property {number} reservation_status.all.slot_count
 * @property {boolean} allow_multiple_reservation
 * @property {boolean} allow_dynamic_reservation
 * @property {boolean} remind_reservation
 * @property {number} remind_reservation_time
 * @property {boolean} send_survey
 * @property blacklist
 * @property {object} announcement
 * @property {number} announcement.id
 * @property {string} announcement.name
 * @property {number} announcement.project
 * @property {number} reservation_action_generator
 * @property {number} project
 * @property resource_managers
 */

/**
 * @typedef {object} DynamicReservation
 * @property {number} id
 * @property {object} reserver
 * @property {number} reserver.id
 * @property {object} reserver.identifier
 * @property {string} reserver.identifier.name
 * @property {string} reserver.identifier.display_name
 * @property {object} reserver.safe_information
 * @property {string} reserver.safe_information.email
 * @property {string} reserver.safe_information.full_name
 * @property {string} reserver.safe_information.test
 * @property {null} reserver.safe_information.group
 * @property {number} reserver.project
 * @property {string} note
 * @property {string} start_date
 * @property {string} end_date
 * @property {string} is_attended
 * @property {string} secret_note
 * @property {object} slot
 * @property {number} slot.id
 * @property {string} slot.start_date
 * @property {string} slot.end_date
 * @property {number} slot.available_slots
 * @property {number} slot.reserved
 * @property {number} slot.total
 * @property {number} slot.resource
 * @property {number} slot.project
 * @property {number} project
 */

export const initState = Object.freeze({
  organization: {
    // /** @type {MemberField[]} */
    // memberFields: [],
    /** @type {MemberFieldFormat} */
    memberFieldFormat: [],
    //TODO: Split filters and actions to individual reducers
    filters: [],
    filter: {},
    actionList: [],
    action: {},
    announcement: {
      name: null,
      survey: null,
      statistics: null,
      versions: null,
      histories: null,
    },
    announcementVersion: {},
    /** @type {ResourceItem[]} */
    resources: [],
    /** @type {DynamicReservation[]} */
    dynamicReservations: [],
    /** @type {ResourceItem} */
    resource: {},
    slots: [],
    slot: {},
    reservations: [],
    members: [],
    /**
     * @type {{
     *   id: number;
     *   project: number;
     *   identifier: {};
     *   safe_information: {};
     * }}
     */
    member: {},
    survey: {},
    /** @type {SurveyResult} */
    surveyResult: {},
    surveyList: [],
  },
  validate: {},
  loading: false,
  saving: false,
  secretKey: null,
  secretKeyList: [],
});

const scenarioManager = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.RESET_FORM:
        draft.organization = JSON.parse(JSON.stringify(initState.organization));
        break;
      case actionTypes.SET_FORM_LOADING:
        draft.loading = action.payload;
        break;
      case actionTypes.SET_FORM_SAVING:
        draft.saving = action.payload;
        break;
      case actionTypes.SET_MEMBER_FIELD_FORMAT:
        draft.organization.memberFieldFormat = action.payload;
        break;
      case actionTypes.SET_FILTERS:
        draft.organization.filters = action.payload;
        break;
      case actionTypes.SET_FILTER:
        draft.organization.filter = action.payload;
        break;
      case actionTypes.SET_ACTION:
        draft.organization.action = action.payload;
        break;
      case actionTypes.SET_ACTION_LIST:
        draft.organization.actionList = action.payload;
        break;
      case actionTypes.SET_RESOURCES:
        draft.organization.resources = action.payload;
        break;
      case actionTypes.SET_RESOURCE:
        draft.organization.resource = action.payload;
        break;
      case actionTypes.SET_MEMBERS:
        draft.organization.members = action.payload;
        break;
      case actionTypes.SET_MEMBER:
        draft.organization.member = action.payload;
        break;
      case actionTypes.SET_SURVEY:
        draft.organization.survey = action.payload;
        break;
      case actionTypes.SET_SLOT:
        draft.organization.slot = action.payload;
        break;
      case actionTypes.SET_SURVEY_LIST:
        draft.organization.surveyList = action.payload;
        break;
      case actionTypes.SET_SLOT_LIST:
        draft.organization.slots = action.payload;
        break;
      case actionTypes.SET_RESERVATION_LIST:
        draft.organization.reservations = action.payload;
        break;
      case actionTypes.SET_SURVEY_RESULT:
        draft.organization.surveyResult = action.payload;
        break;
      case actionTypes.SET_ANNOUNCEMENT:
        draft.organization.announcement = action.payload;
        break;
      case actionTypes.SET_ANNOUNCEMENT_VERSION:
        draft.organization.announcementVersion = action.payload;
        break;
      case actionTypes.SET_DYNAMIC_RESERVATIONS:
        draft.organization.dynamicReservations = action.payload;
        break;
      case actionTypes.SET_SECRET_KEY:
        draft.secretKey = action.payload;
        break;
      case actionTypes.SET_SECRET_KEY_LIST:
        draft.secretKeyList = action.payload;
        break;
    }
  });
export default scenarioManager;
