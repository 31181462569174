import React from "react";

import styled from "@emotion/styled";
import Checkbox from "@mui/material/Checkbox";

const Styled = styled(Checkbox)``;

/** @param {CheckboxProps} rest */
const PalCheckbox = ({ ...rest }) => {
  return <Styled {...rest} />;
};

export default PalCheckbox;
