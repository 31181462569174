import Cookies from "js-cookie";
import { call, put, takeEvery } from "redux-saga/effects";

import { apiUrl, CK_TOKEN, LS_TOKEN } from "~constants";
import HistoryHelper from "~helpers/HistoryHelper";
import Network from "~helpers/Network";
import StorageHelper from "~helpers/StorageHelper";
import Utils from "~helpers/Utils";

import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { postJwtLogin } from "../../../helpers/palmate_backend_helper";

function* loginUser({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        username: user.username,
        password: user.password,
      });
      yield put(loginSuccess(response));
    }

    HistoryHelper.push("/dashboard");
  } catch (error) {
    let errorMessage = "";
    if (error.request.status === 401) {
      errorMessage = "Login failed! Please check your credentials";
    }
    yield put(apiError(errorMessage));
  }
}

function* logoutUser({ payload: { returnUrl } }) {
  try {
    try {
      const response = yield call(Network.request, apiUrl.logout, {
        method: "POST",
        noAlert: true,
      });
      yield put(logoutUserSuccess(response));
    } catch (error) {}
  } catch (error) {
    yield put(apiError(error));
  } finally {
    StorageHelper.remove(LS_TOKEN);
    Cookies.remove(CK_TOKEN);
    if (returnUrl) {
      HistoryHelper.push({
        pathname: "/login",
        search: Utils.qs({ return: returnUrl }),
      });
    } else {
      HistoryHelper.push("/login");
    }
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
