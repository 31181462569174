import classNames from "classnames";
import PropTypes from "prop-types";
import "./style.scss";
export default function PRDivider({ vertical, pageSeparator, size, className, style, color, ...rest }) {
  return (
    <div
      {...rest}
      className={classNames(
        "pr-divider",
        {
          small: size === "sm",
          large: size === "lg",
          "x-large": size === "xl",
          zero: !size && size !== undefined,
          vertical,
          "page-separator": pageSeparator,
        },
        className
      )}
      style={{
        ...style,
        ...(vertical && !["sm", "md", "lg", "xl"].includes(size) ? { height: size } : {}),
        ...(vertical
          ? {
              borderLeft: `1px solid var(--bs-${color || "primary"})`,
            }
          : {
              borderTop: `1px solid var(--bs-${color || "primary"})`,
            }),
      }}
    />
  );
}

PRDivider.propTypes = {
  size: PropTypes.oneOfType([PropTypes.oneOf(["sm", "md", "lg", "xl"]), PropTypes.number, PropTypes.string]),
};

export function PRDividerLabel({
  children,
  pageSeparator,
  labelProps,
  size,
  className,
  center,
  color = "primary-600",
  ...rest
}) {
  return (
    <div
      className={classNames("pr-divider-label", className, {
        "page-separator": pageSeparator,
      })}
      {...rest}
    >
      <PRDivider
        className={classNames({
          "divider-1": true,
          "divider-1-center": center,
        })}
        color={color}
        size={size}
      />
      <span
        className={classNames("label", {
          [`text-${color}`]: color,
        })}
        {...labelProps}
      >
        {children}
      </span>
      <PRDivider
        className={classNames({
          "divider-2": true,
          "divider-2-center": center,
        })}
        color={color}
        size={size}
      />
    </div>
  );
}
