import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

export default function PRSwitch({ scope = "dashboard", routes = [] }) {
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);

  const projectPermissions = currentProject?.permissions || [];
  const filteredRoutes = routes.filter((route) => {
    if (route.permissions) {
      return route.permissions.some((permission) => projectPermissions.includes(permission));
    }
    return true;
  });

  const firstRoute = filteredRoutes?.[0];
  const isRedirectExist = filteredRoutes.some((route) => route.redirect);
  if (!isRedirectExist) {
    filteredRoutes.push({
      path: routes.find((route) => route.redirect)?.path,
      to: firstRoute?.path,
      redirect: true,
    });
  }

  return (
    <Switch>
      {filteredRoutes.map((route) => {
        const { path, component, componentProps, to, ...rest } = route;
        const Component = component;
        let navigateUrl = path;
        let redirectUrl = to || firstRoute?.path;
        if (scope === "dashboard") {
          const urlPrefix = `/dashboard/project/${currentProject?.id || 0}/bot/${currentBot?.id || 0}`;
          navigateUrl = `${urlPrefix}${path}`;
          redirectUrl = `${urlPrefix}${to}`;
        }
        if (route.redirect) {
          return (
            <Route key={path} exact component={() => <Redirect to={redirectUrl} />} path={navigateUrl} {...rest} />
          );
        }
        return (
          <Route
            key={path}
            path={navigateUrl}
            render={(props) => <Component {...componentProps} {...props} />}
            {...rest}
          />
        );
      })}
    </Switch>
  );
}
