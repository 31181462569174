import { memo } from "react";

import { useTranslation } from "react-i18next";

import PRLink from "~components/Generic/PRLink";

import NotificationBase from "./index";

const TicketCreatedMessage = memo(function TicketCreatedMessage({ uuid, project, bot }) {
  const { t } = useTranslation();
  return (
    <NotificationBase>
      <div className="text-center pr-notification-message">
        {t("chatbot.actionInfo.ticketSaved")}.{" "}
        {uuid && project && bot && (
          <PRLink
            newTab
            noPrefix
            scope={"other"}
            to={`/dashboard/project/${project}/bot/${bot}/helpdesk/ticket/form/${uuid}/`}
          >
            Click here to view ticket
          </PRLink>
        )}
      </div>
    </NotificationBase>
  );
});

export default TicketCreatedMessage;
