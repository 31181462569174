import { useEffect, useState } from "react";

export default function useScrollDirection(dom) {
  const [scrollDirection, setScrollDirection] = useState("down");
  const [lastScrollTop, setLastScrollTop] = useState(0);
  useEffect(() => {
    if (!dom) return;
    const handleScroll = () => {
      const currentScrollTop = dom.scrollTop;
      if (currentScrollTop > lastScrollTop) {
        setScrollDirection("down");
      } else {
        setScrollDirection("up");
      }
      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
    };
    dom.addEventListener("scroll", handleScroll);
    return () => dom.removeEventListener("scroll", handleScroll);
  }, [lastScrollTop, dom]);
  return scrollDirection;
}
