import { createRef, useCallback, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { MdCircle, MdEdit, MdRefresh } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "reactstrap";

import useLoading from "~common/hooks/useLoading";
import useVisibility from "~common/hooks/useVisibility";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRLink from "~components/Generic/PRLink";
import PRPage from "~components/Generic/PRPage";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import RippleBadge from "~components/VerticalLayout/Header/RippleBadge";
import { tableFilterStorageKey } from "~constants";
import { getListCallcenterAgent } from "~store/agents/actions";
import { selectCurrentProject } from "~store/user/selectors";

const RenderAgentStatus = ({ status }) => {
  const { t } = useTranslation();
  return status === "AV" ? (
    <PRTooltip placement="top" title={t("common.online")}>
      <span>
        <MdCircle className="text-success fs-5 ms-1" />
      </span>
    </PRTooltip>
  ) : (
    <PRTooltip placement="top" title={t("common.offline")}>
      <span>
        <MdCircle className="text-secondary fs-5 ms-1" />
      </span>
    </PRTooltip>
  );
};

export default function AgentList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // const [isReadyForReload, setIsReadyForReload] = useState(false);
  const isVisible = useVisibility();
  const [callcenterAgentList, setCallcenterAgentList] = useState([]);
  const [loading, q] = useLoading(false);

  const currentProject = useSelector(selectCurrentProject);

  const tableRef = createRef();

  const handleGetAgentLeaderboardData = useCallback(async () => {
    if (!isVisible) return;
    const response = await q(dispatch(getListCallcenterAgent(currentProject?.id)));
    //sort by agent status and after sort by active session count
    response?.results?.sort((a, b) => {
      if (a.status === "AV" && b.status !== "AV") return -1;
      if (a.status !== "AV" && b.status === "AV") return 1;
      return b.active_session_count - a.active_session_count;
    });

    setCallcenterAgentList(response?.results || []);
  }, [dispatch, currentProject?.id, q, isVisible]);

  useEffect(() => {
    if (!currentProject?.id) return;

    handleGetAgentLeaderboardData();
    let interval = setInterval(handleGetAgentLeaderboardData, 10000);

    return () => clearInterval(interval);
  }, [dispatch, currentProject.id, handleGetAgentLeaderboardData]);

  const columns = useMemo(() => {
    return [
      {
        label: t("common.status"),
        key: "status",
        render: (row) => {
          return <RenderAgentStatus status={row?.status} />;
        },
      },
      {
        label: t("common.name"),
        key: "name",
      },
      {
        label: t("dashboard.wallboardList.maxActiveChatCount"),
        key: "max_active_chat_count",
      },
      {
        label: t("dashboard.wallboardList.activeSessionCount"),
        key: "active_session_count",
      },
      {
        label: t("common.sessions"),
        key: "active_sessions",
        render: (row) => {
          return (
            <>
              {row?.active_sessions?.map((item, index) => {
                return (
                  <Badge key={item?.id} className="badge-soft-secondary me-1">
                    <PRLink newTab className="text-dark" to={`/contactcenter/livechat/${item?.id}/`}>
                      {t("common.session")} {index + 1}
                    </PRLink>
                  </Badge>
                );
              })}
            </>
          );
        },
      },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        render: (row) => (
          <div className="d-flex justify-content-center">
            <PRButton
              outline
              color="primary"
              icon={MdEdit}
              link={`/contactcenter/wallboard/form/${row.id}`}
              size="sm"
              tooltipText={t("common.edit")}
            />
            {/* <PRButton
              outline
              className="ms-1"
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText="Delete"
              onClick={handleDelete(row)}
            /> */}
          </div>
        ),
      },
    ];
  }, []);
  const actions = useMemo(() => {
    return [
      {
        label: t("dashboard.wallboardList.reload"),
        icon: () => (
          <MdRefresh
            className={classNames({
              "spin-fast": loading,
            })}
          />
        ),
        color: "success",
        onClick: handleGetAgentLeaderboardData,
      },
    ];
  }, [handleGetAgentLeaderboardData, loading, t]);

  return (
    <>
      <PRContainer
        // actions={actions}
        noAction
        className=""
        description={t("dashboard.wallboardList.description")}
        name={t("common.contactCenter")}
        parentName={t("dashboard.wallboard")}
      >
        <PRPage
          actions={actions}
          title={
            <>
              {t("common.agents")}&nbsp;
              <RippleBadge
                anchorOrigin={{ vertical: "50%", horizontal: "right" }}
                color={"success"}
                overlap="circular"
                sx={{}}
                variant="dot"
              >
                &nbsp;
              </RippleBadge>
            </>
          }
        >
          <PRTable
            ref={tableRef}
            inline
            noPagination
            columns={columns}
            data={callcenterAgentList}
            memoizeAsQuery={false}
            storageKey={tableFilterStorageKey.agentList}
          />
        </PRPage>
      </PRContainer>
    </>
  );
}
