import React, { forwardRef } from "react";

import classNames from "classnames";
import { FormGroup } from "reactstrap";

import "./style.scss";

const PRFormGroup = forwardRef(function PRFormGroup({ className, ...rest }, ref) {
  return <FormGroup ref={ref} {...rest} className={classNames("pr-form-group", className)} />;
});

export default PRFormGroup;
