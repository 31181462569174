import { apiUrlPermission } from "~constants";
import Network from "~helpers/Network";

import * as ta from "./actionTypes";

const setPermissionUserList = (payload) => ({
  type: ta.SET_PERMISSION_USER_LIST,
  payload,
});

export const getPermissionUserList =
  (projectId, options = {}) =>
  () => {
    const url = apiUrlPermission.get.format(projectId);

    return Network.request(url, {
      ...options,
      onSuccess: (response) => setPermissionUserList(response.permissions),
    });
  };
