import PRSwitch from "~components/Generic/PRSwitch";

import DefaultMessageEdit from "./DefaultMessageEdit";
import DefaultMessageList from "./DefaultMessageList";

function DefaultMessage() {
  const routes = [
    { path: "/contactcenter/chat-messages/form/:id?", component: DefaultMessageEdit },
    { path: "/contactcenter/chat-messages/", component: DefaultMessageList },
    { path: "/contactcenter", to: "/contactcenter/chat-messages", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}

export default DefaultMessage;
