import { apiUrlChatbot } from "~constants";
import DateHelper from "~helpers/DateHelper";
import Network from "~helpers/Network";

export const updateRecommendations =
  (projectId, chatbotId, fromDate, toDate, options = {}) =>
  async (_dispatch, getState) => {
    return await Network.request(apiUrlChatbot.updateRecommendations.format(projectId, chatbotId), {
      method: "POST",
      loading: true,
      successMsg: true,
      data: {
        from_date: DateHelper.getDateTimeLocal(fromDate).format("YYYY-MM-DD"),
        to_date: DateHelper.getDateTimeLocal(toDate).format("YYYY-MM-DD"),
      },
      ...options,
    });
  };

export const getRecommendationGenerator =
  (projectId, chatbotId, fromDate, toDate, options = {}) =>
  async (_dispatch, getState) => {
    const result = await Network.request(apiUrlChatbot.getRecommendationGenerator.format(projectId, chatbotId), {
      method: "GET",
      ...options,
    });

    return result.results[0] || {};
  };

export const updateClusters =
  (projectId, chatbotId, date, options = {}) =>
  async (_dispatch, getState) => {
    return await Network.request(apiUrlChatbot.updateClusters.format(projectId, chatbotId), {
      method: "POST",
      data: {
        start_date: DateHelper.getDateTimeLocal(date).format("YYYY-MM-DD"),
      },
      loading: true,
      successMsg: true,
      ...options,
    });
  };
