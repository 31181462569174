import { createSelector } from "reselect";

/** @type {() => typeof import("./reducer.js").initState} */
const selectRoot = (state) => state.integration.module;

export const selectModule = createSelector(selectRoot, (root) => root.module);
export const selectModuleList = createSelector(selectRoot, (root) => root.moduleList);
export const selectModuleVersion = createSelector(selectRoot, (root) => root.moduleVersion);
export const selectTriggerList = createSelector(selectRoot, (root) => root.triggerList);

export const selectModuleIntegration = createSelector(selectRoot, (root) => root.moduleIntegration);
export const selectModuleIntegrationList = createSelector(selectRoot, (root) => root.moduleIntegrationList);
