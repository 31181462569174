import { Fragment, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { cloneDeep, groupBy } from "lodash";
import { useTranslation } from "react-i18next";
import { MdAdd, MdCalendarMonth, MdSettings } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Col, Label, Row } from "reactstrap";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import { PRDividerLabel } from "~components/Generic/PRDivider";
import PRPage from "~components/Generic/PRPage";
import PRSelect from "~components/Generic/PRSelect";
import PRTooltip from "~components/Generic/PRTooltip";
import { getResources, getSurveyList } from "~store/organization/actions";
import { selectResources, selectSurveyList } from "~store/organization/selectors";
import { getMessageTemplateDefinitionList, getTemplateTypeList } from "~store/settings/messageTemplate/actions";
import { selectMessageTemplateDefinitionList, selectTemplateTypeList } from "~store/settings/messageTemplate/selectors";
import { selectPopupSettingsState } from "~store/settings/popupSettings/selectors";
import { selectCurrentProject } from "~store/user/selectors";

import MessageDefinitionPreviewItem from "../MessageDefinitionPreviewItem";

import "./style.scss";

export const messageTemplateTextDescriptionMap = {
  TICKET_ASSIGNED_USER_CHANGED_AGENT: {
    title: "constants.messageTemplates.ticketAssignedAgent.title",
    description: "constants.messageTemplates.ticketAssignedAgent.description",
  },
  TICKET_ASSIGNED_USER_CHANGED_CUSTOMER: {
    title: "constants.messageTemplates.ticketAssignedCustomer.title",
    description: "constants.messageTemplates.ticketAssignedCustomer.description",
  },
  TICKET_CREATE_AGENT: {
    title: "constants.messageTemplates.ticketCreateAgent.title",
    description: "constants.messageTemplates.ticketCreateAgent.description",
  },
  TICKET_CREATE_CUSTOMER: {
    title: "constants.messageTemplates.ticketCreateCustomer.title",
    description: "constants.messageTemplates.ticketCreateCustomer.description",
  },
  TICKET_COMMENT_CUSTOMER: {
    title: "constants.messageTemplates.ticketCommentCustomer.title",
    description: "constants.messageTemplates.ticketCommentCustomer.description",
  },
  TICKET_COMMENT_AGENTS: {
    title: "constants.messageTemplates.ticketCommentAgents.title",
    description: "constants.messageTemplates.ticketCommentAgents.description",
  },
  TICKET_CATEGORY_CHANGED_AGENTS: {
    title: "constants.messageTemplates.ticketCategoryChangedAgent.title",
    description: "constants.messageTemplates.ticketCategoryChangedAgent.description",
  },
  TICKET_CATEGORY_CHANGED_CUSTOMER: {
    title: "constants.messageTemplates.ticketCategoryChangedCustomer.title",
    description: "constants.messageTemplates.ticketCategoryChangedCustomer.description",
  },
  TICKET_UPDATED: {
    title: "constants.messageTemplates.ticketUpdated.title",
    description: "constants.messageTemplates.ticketUpdated.description",
  },
  TICKET_RESOLVED: {
    title: "constants.messageTemplates.ticketResolved.title",
    description: "constants.messageTemplates.ticketResolved.description",
  },
  TICKET_SHARE: {
    title: "constants.messageTemplates.ticketShare.title",
    description: "constants.messageTemplates.ticketShare.description",
  },
  RESERVATION_CREATE: {
    title: "constants.messageTemplates.reservationCreate.title",
    description: "constants.messageTemplates.reservationCreate.description",
  },
  RESERVATION_UPDATE: {
    title: "constants.messageTemplates.reservationUpdate.title",
    description: "constants.messageTemplates.reservationUpdate.description",
  },
  RESERVATION_DELETE: {
    title: "constants.messageTemplates.reservationDelete.title",
    description: "constants.messageTemplates.reservationDelete.description",
  },
  PALMATE_REGISTRATION: {
    title: "constants.messageTemplates.palmateRegistration.title",
    description: "constants.messageTemplates.palmateRegistration.description",
  },
  PALMATE_JOIN_INVITATION: {
    title: "constants.messageTemplates.palmateJoinInvitation.title",
    description: "constants.messageTemplates.palmateJoinInvitation.description",
  },
  PALMATE_PASSWORD_RESET: {
    title: "constants.messageTemplates.palmatePasswordReset.title",
    description: "constants.messageTemplates.palmatePasswordReset.description",
  },
  PALMATE_PASSWORD_CHANGE: {
    title: "constants.messageTemplates.palmatePasswordChange.title",
    description: "constants.messageTemplates.palmatePasswordChange.description",
  },

  AUTH_TOKEN_MESSAGE: {
    title: "constants.messageTemplates.authTokenMessage.title",
    description: "constants.messageTemplates.authTokenMessage.description",
  },
  CHAT_HISTORY_TEMPLATE: {
    title: "constants.messageTemplates.chatHistoryTemplate.title",
    description: "constants.messageTemplates.chatHistoryTemplate.description",
  },
  TRAIN_START_TEMPLATE: {
    title: "constants.messageTemplates.trainStartTemplate.title",
    description: "constants.messageTemplates.trainStartTemplate.description",
  },
  GO_LIVE_TEMPLATE: {
    title: "constants.messageTemplates.goLiveTemplate.title",
    description: "constants.messageTemplates.goLiveTemplate.description",
  },
  RESERVATION_REMIND: {
    title: "constants.messageTemplates.reservationRemind.title",
    description: "constants.messageTemplates.reservationRemind.description",
  },
};

const MessageTemplateDefinition = ({ defaultMode: defaultModeProp }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const popupSettings = useSelector(selectPopupSettingsState);
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [customGroupedResources, setCustomGroupedResources] = useState([]);

  const { defaultMode, currentPageUrl } = useMemo(() => {
    const defaultModeStatus = !!defaultModeProp;
    return {
      defaultMode: defaultModeStatus,
      currentPageUrl: defaultModeStatus ? "/settings/system-message-template/" : "/settings/message-template/",
    };
  }, [defaultModeProp]);

  const currentProject = useSelector(selectCurrentProject);
  // const userInfo = useSelector(selectUserInfo);

  const messageTemplateDefinitionListRaw = useSelector(selectMessageTemplateDefinitionList);
  const messageTemplateDefinitionList = useMemo(() => {
    const nonDuplicateList = [];
    for (const item of messageTemplateDefinitionListRaw) {
      const isExist = nonDuplicateList.find(
        (i) =>
          i.language === item.language &&
          i.platform === item.platform &&
          i.template_type === item.template_type &&
          i.default_for_projects === item.default_for_projects &&
          i.default_for_resources === item.default_for_resources &&
          i.default_for_surveys === item.default_for_surveys &&
          i.resource === item.resource &&
          i.survey === item.survey
      );
      if (!isExist) {
        nonDuplicateList.push(item);
      }
    }

    return nonDuplicateList;
  }, [messageTemplateDefinitionListRaw]);

  const templateTypeList = useSelector(selectTemplateTypeList);
  const resources = useSelector(selectResources);
  const surveyList = useSelector(selectSurveyList);

  // const cleanedTemplateData = useMemo(() => {
  //   const filtered = templateData.filter((item) =>
  //     messageTemplateList?.some((definition) => definition.id === item.message_template)
  //   );
  //   const grouped = groupBy(filtered, (item) => item.message_template);
  //   const filteredGroup = [];
  //   for (const key in grouped) {
  //     const item = grouped[key];
  //     //take first of each duplicate
  //     filteredGroup.push(item[0]);
  //   }
  //   return filteredGroup;
  // }, [templateData, messageTemplateList]);

  const filteredTemplateTypeList = useMemo(() => {
    let filtered = templateTypeList.filter((item) => defaultMode || item.type !== "palmate");
    if (!defaultMode && !popupSettings.ticket_enabled) {
      filtered = filtered.filter((item) => {
        if (
          [
            "TICKET_ASSIGNED_USER_CHANGED_AGENT",
            "TICKET_CREATE_AGENT",
            "TICKET_COMMENT_AGENTS",
            "TICKET_CATEGORY_CHANGED_AGENTS",
            "TICKET_UPDATED",
            "TICKET_RESOLVED",
            "TICKET_SHARE",
            "TICKET_ASSIGNED_USER_CHANGED_CUSTOMER",
            "TICKET_CREATE_CUSTOMER",
            "TICKET_COMMENT_CUSTOMER",
            "TICKET_CATEGORY_CHANGED_CUSTOMER",
          ].includes(item.key)
        ) {
          return false;
        }
        return true;
      });
    }
    return filtered;
  }, [defaultMode, templateTypeList, popupSettings.ticket_enabled]);

  useEffect(() => {
    dispatch(getMessageTemplateDefinitionList(currentProject.id, { params: { limit: 9999 } }));
    dispatch(getTemplateTypeList(currentProject.id, { params: { limit: 9999 } }));
    dispatch(getResources(currentProject.id));
    dispatch(getSurveyList(currentProject.id, { params: { limit: 9999 } }));
  }, [dispatch, currentProject.id]);

  const { palmateTypes, projectTypes, resourceTypes } = useMemo(() => {
    const palmateTypes = [];
    const projectTypes = [];
    const resourceTypes = [];
    for (const templateType of filteredTemplateTypeList) {
      if (templateType.type === "project") {
        projectTypes.push(templateType);
      } else if (templateType.type === "resource") {
        resourceTypes.push(templateType);
      } else if (templateType.type === "palmate") {
        palmateTypes.push(templateType);
      }
    }
    projectTypes.push(...resourceTypes);

    //sort palmate types by messageTemplateTextDescriptionMap key order
    palmateTypes.sort((a, b) => {
      const aKey = a.key;
      const bKey = b.key;
      const aIndex = Object.keys(messageTemplateTextDescriptionMap).indexOf(aKey);
      const bIndex = Object.keys(messageTemplateTextDescriptionMap).indexOf(bKey);
      return aIndex - bIndex;
    });

    projectTypes.sort((a, b) => {
      const aKey = a.key;
      const bKey = b.key;
      const aIndex = Object.keys(messageTemplateTextDescriptionMap).indexOf(aKey);
      const bIndex = Object.keys(messageTemplateTextDescriptionMap).indexOf(bKey);
      return aIndex - bIndex;
    });

    resourceTypes.sort((a, b) => {
      const aKey = a.key;
      const bKey = b.key;
      const aIndex = Object.keys(messageTemplateTextDescriptionMap).indexOf(aKey);
      const bIndex = Object.keys(messageTemplateTextDescriptionMap).indexOf(bKey);
      return aIndex - bIndex;
    });
    return { palmateTypes, projectTypes, resourceTypes };
  }, [filteredTemplateTypeList]);

  const { templateGroups } = useMemo(() => {
    const clonedMessageTemplateList1 = cloneDeep(messageTemplateDefinitionList) || [];
    const filteredMessageTemplateList = clonedMessageTemplateList1.filter(
      (item) => !!item.default_for_projects === defaultMode
    );
    if (!defaultMode) {
      const clonedMessageTemplateList2 = cloneDeep(messageTemplateDefinitionList) || [];
      const defaultMessageTemplateList = clonedMessageTemplateList2.filter((item) => !!item.default_for_projects);

      for (const messageTemplate of defaultMessageTemplateList) {
        const isExist = filteredMessageTemplateList.find(
          (item) =>
            item.language === messageTemplate.language &&
            item.platform === messageTemplate.platform &&
            item.template_type === messageTemplate.template_type &&
            // item.default_for_projects === messageTemplate.default_for_projects &&
            item.default_for_resources === messageTemplate.default_for_resources &&
            item.default_for_surveys === messageTemplate.default_for_surveys &&
            item.resource === messageTemplate.resource &&
            item.survey === messageTemplate.survey
        );
        if (!isExist) {
          // if (filteredMessageTemplateList?.length === 0) {
          //   messageTemplate.untouched = true;
          // }
          filteredMessageTemplateList.push(messageTemplate);
        }
      }
    }

    const grouped = groupBy(filteredMessageTemplateList, (item) => {
      return item.template_type;
    });

    const resourceTypeKeys = resourceTypes.map((item) => item.key);
    for (const type in grouped) {
      if (resourceTypeKeys.includes(type)) {
        const items = grouped[type];
        const groupedByResourceId = groupBy(items, (item) => item?.resource || "default");

        Object.entries(groupedByResourceId).forEach(([id, templates]) => {
          groupedByResourceId[id] = templates;
        });

        grouped[type] = groupedByResourceId;
      }
    }
    return { templateGroups: grouped };
  }, [resourceTypes, defaultMode, messageTemplateDefinitionList]);

  const { groupedResourceTypes } = useMemo(() => {
    const groupedResourceTypes = [];
    for (const resource of resources) {
      const newItem = {
        resourceId: resource.id,
        templateTypes: [...resourceTypes],
      };
      let isOneOfTemplateDefined = !!messageTemplateDefinitionList.find((td) => td.resource === resource.id);
      if (isOneOfTemplateDefined) {
        groupedResourceTypes.push(newItem);
      }
    }
    groupedResourceTypes.push(...customGroupedResources);
    return { groupedResourceTypes };
  }, [resources, messageTemplateDefinitionList, resourceTypes, customGroupedResources]);

  const handleClickAddCalendar = () => {
    if (!selectedCalendar?.id) return;
    setCustomGroupedResources((prev) => {
      const newGroupedResources = [...prev];
      newGroupedResources.push({
        resourceId: selectedCalendar.id,
        templateTypes: [...resourceTypes],
      });
      return newGroupedResources;
    });
  };

  const calendarOptions = useMemo(() => {
    const options = [...resources].map((item) => ({
      ...item,
      disabled: !!groupedResourceTypes.find((cg) => cg.resourceId === item.id),
    }));
    return options;
  }, [resources, groupedResourceTypes]);

  const parentName = useMemo(
    () => [
      {
        label: t("common.templates"),
        url: currentPageUrl,
      },
      {
        label: t("dashboard.templates.templateDefinition"),
      },
    ],
    [currentPageUrl, t]
  );

  const resourceTypeKeys = resourceTypes.map((item) => item.key);
  return (
    <PRContainer
      className={"pr-message-template-definition"}
      description={t("dashboard.templates.listDescription")}
      name={t("common.settings")}
      parentName={parentName}
    >
      {defaultMode && (
        <Alert color="warning">
          <MdSettings className="me-1 text-secondary" /> {t("dashboard.templates.defaultModeWarning")}
        </Alert>
      )}
      <PRPage>
        {!!palmateTypes?.length && (
          <>
            <PRDividerLabel pageSeparator className="my-2">
              {t("dashboard.templates.systemTemplates")}
            </PRDividerLabel>
            <Row className="g-2 mb-2">
              {palmateTypes.map((item) => (
                <Col
                  key={item.key}
                  className="d-flex flex-column justify-content-center align-items-center"
                  lg="4"
                  md="6"
                  sm="12"
                >
                  <Label className="fw-semibold fs-5" size="md">
                    <PRTooltip title={item.key}>
                      <div className="text-truncate text-center overflow-hidden" style={{ width: 350 }}>
                        {t(messageTemplateTextDescriptionMap[item.key]?.title) || item.key}
                      </div>
                    </PRTooltip>
                  </Label>
                  <MessageDefinitionPreviewItem
                    className={classNames("template-system", {
                      "default-highlight": !defaultMode && templateGroups[item.key]?.[0]?.default_for_projects,
                    })}
                    templateGroups={templateGroups[item.key]}
                    templateKey={item.key}
                  />
                  <PRButton
                    className="mt-1 w-100"
                    link={`${currentPageUrl}form/${item.key}::${defaultMode ? "default" : "palmate"}`}
                  >
                    {t("dashboard.templates.manage")}
                  </PRButton>
                </Col>
              ))}
            </Row>
          </>
        )}
        {!!projectTypes?.length && (
          <Row className="g-2 mb-2">
            {projectTypes.map((item) => {
              let templateGroup = templateGroups[item.key];
              const isResourceType = resourceTypeKeys.includes(item.key);
              if (isResourceType) {
                templateGroup = templateGroup?.default;
              }
              const showHalfTransparent = !defaultMode && templateGroup?.[0]?.default_for_projects;
              return (
                <Col
                  key={item.key}
                  className="d-flex flex-column justify-content-center align-items-center"
                  lg="4"
                  md="6"
                  sm="12"
                >
                  <Label className="fw-semibold fs-5" size="md">
                    <PRTooltip title={item.key}>
                      <div className="text-truncate text-center overflow-hidden" style={{ width: 350 }}>
                        {t(messageTemplateTextDescriptionMap[item.key]?.title) || item.key}
                      </div>
                    </PRTooltip>
                  </Label>
                  <MessageDefinitionPreviewItem
                    className={classNames("template-project", {
                      "default-highlight": showHalfTransparent,
                    })}
                    templateGroups={templateGroup}
                    templateKey={item.key}
                  />
                  <PRButton
                    className="mt-1 w-100"
                    link={`${currentPageUrl}form/${item.key}::${defaultMode ? "default" : "palmate"}`}
                  >
                    {t("dashboard.templates.manage")}
                  </PRButton>
                </Col>
              );
            })}
          </Row>
        )}
        {!defaultMode && (
          <>
            <PRDividerLabel pageSeparator className="my-2">
              {t("dashboard.templates.calendarTemplates")}
            </PRDividerLabel>
            <Row className="g-2 mb-2">
              <Col xs="12">
                <Row className="gx-2 justify-content-end align-items-center">
                  <Col xs md="auto">
                    {t("dashboard.templates.addCalendar")}:
                  </Col>
                  <Col xs lg="3" md="4">
                    <PRSelect
                      isClearable={false}
                      labelSelector="name"
                      options={calendarOptions}
                      value={selectedCalendar}
                      valueSelector="id"
                      onChange={setSelectedCalendar}
                    />
                  </Col>
                  <Col xs="auto">
                    <PRButton
                      color="success"
                      disabled={groupedResourceTypes?.find((g) => g.resourceId === selectedCalendar?.id)}
                      icon={MdAdd}
                      tooltipText={t("dashboard.templates.calendarTooltip")}
                      onClick={handleClickAddCalendar}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}

        {!defaultMode &&
          groupedResourceTypes.map(({ resourceId, templateTypes }) => {
            const resource = resources.find((r) => r.id === resourceId);
            return (
              <Fragment key={resourceId}>
                <div className="calendar-group">
                  <Row className="g-2 mb-2">
                    <Col xs>
                      <Label className="font-size-18 lh-1 d-flex align-items-center">
                        <MdCalendarMonth className="fs-4 me-1 text-secondary" />
                        {resource?.name}
                      </Label>
                    </Col>
                    {/* <Col xs="auto">
                    <PRButton icon={MdDelete} color="danger" />
                  </Col> */}
                  </Row>
                  <Row className="g-2 mb-2">
                    {templateTypes.map((item) => (
                      <Col
                        key={item.key}
                        className="d-flex flex-column justify-content-center align-items-center"
                        lg="4"
                        md="6"
                        sm="12"
                      >
                        <Label className="fw-semibold fs-5" size="md">
                          <PRTooltip title={item.key}>
                            <div className="text-truncate text-center overflow-hidden" style={{ width: 350 }}>
                              {t(messageTemplateTextDescriptionMap[item.key]?.title) || item.key}
                            </div>
                          </PRTooltip>
                        </Label>
                        <MessageDefinitionPreviewItem
                          className={classNames("template-resource", {
                            "default-highlight":
                              templateGroups[item.key]?.[resourceId]?.[0]?.default_for_projects && !defaultMode,
                          })}
                          templateGroups={templateGroups[item.key]?.[resourceId]}
                          templateKey={item.key}
                        />
                        <PRButton
                          className="mt-1 w-100"
                          link={`${currentPageUrl}form/${item.key}::${
                            defaultMode ? "default" : "palmate"
                          }::${resourceId}`}
                        >
                          {t("dashboard.templates.manage")}
                        </PRButton>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Fragment>
            );
          })}
        <Row className="justify-content-end g-2 mt-2">
          <Col xs="auto">
            <PRButton outline link={currentPageUrl}>
              {t("common.cancel")}
            </PRButton>
          </Col>
        </Row>
      </PRPage>
    </PRContainer>
  );
};

export default MessageTemplateDefinition;
