import { useState } from "react";

import { withCardon } from "cardon";
import { useDispatch, useSelector } from "react-redux";

import PRModal from "~components/Generic/PRModal";
import PRPhoneNumber from "~components/Generic/PRPhoneNumber";
import PRSelect from "~components/Generic/PRSelect";
import debounceAsync from "~helpers/debounceAsync";
import { getMembers } from "~store/organization/actions";
import { selectCurrentProject } from "~store/user/selectors";

function CustomerSelectModalContent({ get }) {
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const [selectedMember, setSelectedMember] = useState(null);
  const handleLoadOptions = debounceAsync(async (searchText, callback, signal) => {
    const memberList = await dispatch(
      getMembers(
        currentProject.id,
        {
          limit: 20,
          full_name__icontains: searchText,
        },
        {
          signal,
          loading: false,
        }
      )
    );
    const options = memberList.results.map((item) => {
      return {
        value: item.id,
        phone: item.safe_information?.phone_number,
        label: (
          <div>
            {item.safe_information?.full_name} (<PRPhoneNumber render value={item.safe_information?.phone_number} />)
          </div>
        ),
      };
    });
    return options;
  });

  return (
    <PRModal title="Select Customer" onClick={get(selectedMember?.phone)} onClose={get(false)}>
      <PRSelect lazy loadOptions={handleLoadOptions} value={selectedMember} onChange={setSelectedMember} />
    </PRModal>
  );
}
const CustomerSelectModal = withCardon(CustomerSelectModalContent, { destroyOnHide: true });
export default CustomerSelectModal;
