import { createSelector } from "reselect";

import { selectDialogComponents } from "../selector";

/** @type {() => typeof import("./reducer").initState} */
const rootSelector = createSelector(selectDialogComponents, (state) => state.scenarioManager);

export const selectScenarioManagerLoading = createSelector(rootSelector, (state) => state.intentLoading);
export const selectScenarioManagerSaving = createSelector(rootSelector, (state) => state.intentSaving);
export const selectProcedureList = createSelector(rootSelector, (state) => state.procedureList);
export const selectScenarioManager = createSelector(rootSelector, (state) => state.intent);

export const selectScenarioManagerId = createSelector(selectScenarioManager, (state) => state.id);
export const selectScenarioManagerIntentTag = createSelector(selectScenarioManager, (state) => state.intent_tag);
export const selectScenarioManagerIntentTagText = createSelector(
  selectScenarioManager,
  (state) => state.intent_tag_text
);
export const selectScenarioManagerSlotValidationText = createSelector(
  selectScenarioManager,
  (state) => state.slot_validation_text
);
export const selectScenarioManagerText = createSelector(selectScenarioManager, (state) => state.text);
export const selectScenarioManagerDisplayText = createSelector(selectScenarioManager, (state) => state.display_text);
export const selectScenarioManagerAuthenticationRequired = createSelector(
  selectScenarioManager,
  (state) => state.authentication_required
);
export const selectScenarioManagerUseInPrediction = createSelector(
  selectScenarioManager,
  (state) => state.use_in_prediction
);
export const selectScenarioManagerResponse = createSelector(selectScenarioManager, (state) => state.response);
export const selectScenarioManagerResponseFeedback = createSelector(
  selectScenarioManager,
  (state) => state.response.ask_feedback
);
export const selectScenarioManagerResponseMessageItems = createSelector(
  selectScenarioManager,
  (state) => state.response.message_items
);
export const selectScenarioManagerResponseSuggestedIntents = createSelector(
  selectScenarioManager,
  (state) => state.response.suggested_intents
);
export const selectScenarioManagerSlots = createSelector(selectScenarioManager, (state) => state.slots);
export const selectScenarioManagerForms = createSelector(selectScenarioManager, (state) => state.forms);
export const selectScenarioManagerSamples = createSelector(selectScenarioManager, (state) => state.samples);
export const selectScenarioManagerSavedToData = createSelector(selectScenarioManager, (state) => state.saved_to_data);
export const selectScenarioManagerFollowsFrom = createSelector(selectScenarioManager, (state) => state.follows_from);
export const selectScenarioManagerTranslations = createSelector(selectScenarioManager, (state) => state.translations);
export const selectScenarioManagerProject = createSelector(selectScenarioManager, (state) => state.project);
export const selectScenarioManagerBot = createSelector(selectScenarioManager, (state) => state.bot);
export const selectScenarioManagerFollowupIntents = createSelector(
  selectScenarioManager,
  (state) => state.followup_intents
);

export const selectScenarioManagerAuthType = createSelector(selectScenarioManager, (state) => state.auth_type);
export const selectScenarioPreProcessLowCodeScript = createSelector(
  selectScenarioManager,
  (state) => state.preprocess_low_code_script
);

export const selectScenarioManagerSessionSamplePrediction = createSelector(
  rootSelector,
  (state) => state.sessionSamples
);
export const selectScenarioManagerIntentSamplePrediction = createSelector(rootSelector, (state) => state.intentSamples);
export const selectScenarioManagerIntentResponsePrediction = createSelector(
  rootSelector,
  (state) => state.intentResponses
);

export const selectScenarioManagerMediaList = createSelector(rootSelector, (state) => state.mediaList);
export const selectScenarioManagerDatabaseList = createSelector(rootSelector, (state) => state.databaseList);
export const selectScenarioManagerVariableList = createSelector(rootSelector, (state) => state.variableList);
export const selectScenarioManagerInsight = createSelector(rootSelector, (state) => state.insight);
export const selectScenarioManagerInsightData = createSelector(rootSelector, (state) => state.insightData);

export const selectScenarioManagerIntentType = createSelector(selectScenarioManager, (state) => state.intent_type);
export const selectScenarioManagerRagFactGroup = createSelector(selectScenarioManager, (state) => state.rag_fact_group);
