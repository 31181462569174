import React from "react";

import styled from "@emotion/styled";

const StyledStatusItem = styled.div`
  background-color: ${(props) => `var(--bs-${props.color})`};
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 5px;
`;

export default function PalDot({ color = "primary", ...rest }) {
  return <StyledStatusItem color={color} {...rest} />;
}
