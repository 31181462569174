import { useEffect, useState } from "react";

import { withCardon } from "cardon";
import classNames from "classnames";
import { MdClose, MdDownload } from "react-icons/md";

import styled from "@emotion/styled";

import PRImg from "~components/Generic/PRImg";
import PalIconButton from "~components/mui/PalIconButton";
import IFrameHelper from "~helpers/IFrameHelper";
import Utils from "~helpers/Utils";

import "./style.scss";

function addPanAndZoom(imageElement) {
  let scale = 1;
  let pos = { x: 0, y: 0 };
  let mousePos = { x: 0, y: 0 };
  let touchStartPos = { x: 0, y: 0 };
  let isPanning = false;
  const dblClickScaleSteps = [1, 1.5, 2];
  let initialPinchDistance = null;
  const minZoom = 0.5;
  const maxZoom = 14;

  imageElement.style.transition = "ease-out 0.1s";

  const updateTransform = () => {
    scale = Math.min(Math.max(minZoom, scale), maxZoom);
    imageElement.style.transform = `translate3d(${pos.x}px, ${pos.y}px, 0px) scale(${scale})`;
  };
  const handleWheelScroll = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isNaN(e.deltaY)) {
      const delta = e.deltaY * -0.005;
      scale += delta;

      if (!isNaN(scale)) {
        scale = Math.min(Math.max(minZoom, scale), maxZoom);
        updateTransform();
      } else {
        scale = 1;
      }
    }
  };

  const fitToScreen = () => {
    const imgWidth = imageElement.naturalWidth;
    const imgHeight = imageElement.naturalHeight;

    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    const widthRatio = screenWidth / imgWidth;
    const heightRatio = screenHeight / imgHeight;

    scale = Math.min(widthRatio, heightRatio, maxZoom);
    pos = { x: 0, y: 0 }; // Optionally reset the position
    updateTransform();

    handleWheelScroll({ deltaY: 0, preventDefault: () => {}, stopPropagation: () => {} });
  };

  // For Mobile
  imageElement.addEventListener("touchstart", (e) => {
    e.preventDefault();
    const touchCount = e.touches.length;

    if (touchCount === 1) {
      isPanning = true;
      touchStartPos = { x: e.touches[0].clientX, y: e.touches[0].clientY };
      initialPinchDistance = null;
    } else if (touchCount === 2) {
      isPanning = false;
      initialPinchDistance = Math.hypot(
        e.touches[0].clientX - e.touches[1].clientX,
        e.touches[0].clientY - e.touches[1].clientY
      );
    }
  });

  imageElement.addEventListener("touchmove", (e) => {
    e.preventDefault();
    const touchCount = e.touches.length;

    if (isPanning && touchCount === 1) {
      const dx = e.touches[0].clientX - touchStartPos.x;
      const dy = e.touches[0].clientY - touchStartPos.y;
      pos.x += dx;
      pos.y += dy;
      touchStartPos = { x: e.touches[0].clientX, y: e.touches[0].clientY };
      updateTransform();
    } else if (touchCount === 2) {
      const currentPinchDistance = Math.hypot(
        e.touches[0].clientX - e.touches[1].clientX,
        e.touches[0].clientY - e.touches[1].clientY
      );

      const pinchRatio = currentPinchDistance / initialPinchDistance;
      scale *= pinchRatio;
      scale = Math.min(Math.max(minZoom, scale), maxZoom);

      initialPinchDistance = currentPinchDistance;
      updateTransform();
    }
  });

  imageElement.addEventListener("touchend", () => {
    initialPinchDistance = null;
    isPanning = false;
  });

  if (Utils.detectDeviceType() !== "mobile") {
    // Attach wheel listener for zoom on desktop
    imageElement.addEventListener("wheel", handleWheelScroll);
  }

  imageElement.addEventListener("mousedown", (e) => {
    e.preventDefault();
    isPanning = true;
    imageElement.style.cursor = "grabbing";
    imageElement.style.userSelect = "none";
    mousePos = { x: e.clientX, y: e.clientY };
  });

  window.addEventListener("mouseup", () => {
    isPanning = false;
    imageElement.style.cursor = "grab";
    imageElement.style.removeProperty("user-select");
    imageElement.style.transition = "ease-out 0.3s";
  });

  imageElement.addEventListener("dblclick", (e) => {
    e.preventDefault();
    const currentDblClickScale = dblClickScaleSteps.findIndex((s) => s === scale);
    if (currentDblClickScale > -1) {
      scale = dblClickScaleSteps[currentDblClickScale + 1] || 1;
    } else {
      scale = dblClickScaleSteps[0];
    }
    pos = { x: 0, y: 0 };
    updateTransform();
  });

  window.addEventListener("mousemove", (e) => {
    if (!isPanning) return;
    imageElement.style.transition = "none";
    const dx = e.clientX - mousePos.x;
    const dy = e.clientY - mousePos.y;
    pos.x += dx;
    pos.y += dy;
    mousePos = { x: e.clientX, y: e.clientY };
    updateTransform();
  });

  imageElement.addEventListener("load", fitToScreen);
  window.addEventListener("resize", fitToScreen);
}

const StyledToolbar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1202;
  height: 71px;
  background-color: #000000cc;
  display: flex;
  justify-content: end;
  align-items: center;
  opacity: 0.7;
  padding: 0 10px;
  .MuiButtonBase-root {
    color: white;
    width: 40px;
    height: 40px;
  }
  .MuiIconButton-root {
    &:hover {
      background-color: #fff3;
    }
  }
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: ${(props) => (props.isInIframe ? "0px 0px 0px 0px" : "0px 0px 0px 0px")};
`;

const ImagePreviewModal = withCardon(
  function ImagePreviewModalContent({ get, image, title, authenticated }) {
    const [imgContainer, setImgContainer] = useState(null);
    const [img, setImg] = useState(null);
    //Only helps the re-rendering of the image after the first load
    // const [imgLoaded, setImgLoaded] = useState(false);

    useEffect(() => {
      if (!imgContainer) return;
      addPanAndZoom(imgContainer);
    }, [imgContainer]);

    useEffect(() => {
      const handleEsc = (e) => {
        if (e.key === "Escape") {
          get(false)();
        }
      };
      window.addEventListener("keydown", handleEsc);
      return () => {
        window.removeEventListener("keydown", handleEsc);
      };
    }, [get]);

    const handleDownloadImage = async () => {
      const isBase64 = image.startsWith("data:image");
      const isHttpUrl = image.startsWith("http");

      let blob;
      let name = "image.jpg";

      if (isBase64) {
        const byteCharacters = atob(image.split(",")[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        blob = new Blob([byteArray], { type: "image/jpeg" });
      } else if (isHttpUrl) {
        const response = await fetch(image);
        blob = await response.blob();
        name = response.headers.get("content-disposition")?.split("filename=")?.[1];
        if (!name) {
          name = image.split("/").pop();
        }
      } else {
        console.error("Unsupported image type");
        return;
      }

      const type = name.split(".").pop();

      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = title + "." + type;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
    };

    return (
      <div
        className={classNames(
          "pr-image-preview-modal d-flex justify-content-center align-items-center overflow-hidden"
        )}
      >
        <div className="overlay" onClick={get(false)} />
        <StyledToolbar isInIframe={IFrameHelper.isInIFrame()}>
          <PalIconButton disabled={!image} onClick={handleDownloadImage}>
            <MdDownload />
          </PalIconButton>
          <PalIconButton onClick={get(false)}>
            <MdClose />
          </PalIconButton>
        </StyledToolbar>

        {!!title && <div className="title">{title}</div>}
        <div ref={setImgContainer} className="image-wrapper">
          <PRImg
            ref={setImg}
            showTransparentBackground
            alt={title || "Image preview"}
            src={image}
            authenticated={authenticated}
            // onLoad={setImgLoaded}
          />
          {!!img?.width && <div className="mt-2 text-secondary text-center">{`${img?.width}x${img?.height}`}</div>}
        </div>
      </div>
    );
  },
  {
    destroyOnHide: true,
  }
);

export default ImagePreviewModal;
