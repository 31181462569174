import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

export const initState = {
  reservationGenerator: null,
};

const reservationGenerator = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_RESERVATION_GENERATOR:
        draft.reservationGenerator = action.payload;
        break;
    }
  });

export default reservationGenerator;
