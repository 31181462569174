import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

export const initState = Object.freeze({
  intentTag: {
    id: 0,
    key: "",
    project: 0,
    bot: 0,
  },
});

const intentTags = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_INTENT_TAG:
        draft.intentTag = action.payload;
        break;
    }
  });

export default intentTags;
