import { createContext, forwardRef, useContext, useMemo, useState } from "react";

import classNames from "classnames";
import { Container, Spinner } from "reactstrap";

import { Fade } from "@mui/material";

import Breadcrumbs from "~components/Breadcrumb";

import "./style.scss";

const PRContainerContext = createContext({});
export const usePRContainerContext = () => useContext(PRContainerContext);

function PRBreadcrumbs({ name, parentName, description, smalltalkSelector }) {
  return (
    <Breadcrumbs
      breadcrumbItem={parentName}
      description={description}
      smalltalkSelector={smalltalkSelector}
      title={name}
    />
  );
}

function PRContainer(
  {
    name,
    parentName,
    description,
    className,
    children,
    loading,
    center,
    bare,
    smalltalkSelector,
    maxWidth,
    actions,
    noAction,
    ...rest
  },
  ref
) {
  const ContainerComp = bare ? "div" : Container;
  const [contextActions, setContextActions] = useState([]);

  const mergedActions = useMemo(() => {
    if (noAction) {
      return [];
    }
    return [...(actions || []), ...contextActions.map((b) => ({ ...b, fromContext: true }))];
  }, [actions, contextActions, noAction]);

  return (
    <PRContainerContext.Provider value={{ actions: mergedActions, setActions: setContextActions }}>
      <div
        ref={ref}
        className={classNames(
          "pr-container p-0",
          {
            "page-content": !bare,
            "container-loading": loading,
            "align-left": !center,
          },
          className
        )}
      >
        <ContainerComp
          {...rest}
          className={classNames({
            bare: bare,
            "container-spacing": !bare,
            "container-max-width": !!maxWidth,
          })}
        >
          <Fade mountOnEnter unmountOnExit className={classNames("pr-spinner")} in={loading}>
            <span>
              <Spinner className="ms-2" color="primary" />
            </span>
          </Fade>
          <div className="container-inner">
            {(!!name || !!parentName) && (
              <PRBreadcrumbs
                description={description}
                name={name}
                parentName={parentName}
                smalltalkSelector={smalltalkSelector}
              />
            )}
            {children}
          </div>
        </ContainerComp>
      </div>
    </PRContainerContext.Provider>
  );
}

export default forwardRef(PRContainer);
