import { safeProduce as produce } from "~helpers/immer";

import * as actionTypes from "./actionTypes";

const initState = {
  /** @type {{ title: ""; content: ""; actions: []; visible: false; options: {} }[]} */
  dialogList: [],
};

const dialog = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.DIALOG_SHOW:
        const key = action.payload?.key;
        let newDialogList = draft.dialogList;
        if (key) {
          newDialogList = draft.dialogList.filter((item) => item.key !== key);
        }

        const newPayload = { ...action.payload, visible: true };
        newDialogList.push(newPayload);
        draft.dialogList = newDialogList;
        break;
      case actionTypes.DIALOG_CLOSE:
        const closeKey = action.payload?.key;
        if (closeKey) {
          draft.dialogList = draft.dialogList.filter((item) => item.key !== closeKey);
        } else {
          //reverse for
          for (let i = draft.dialogList.length - 1; i >= 0; i--) {
            const item = draft.dialogList[i];
            if (item.visible) {
              const newDialogList = draft.dialogList.slice(0, i);
              draft.dialogList = newDialogList;
              break;
            }
          }
        }
        break;
      case actionTypes.DIALOG_UPDATE:
        const payload = action.payload;
        draft.dialogList = draft.dialogList.map((item) =>
          item === payload.old ? { ...payload.old, ...payload.props } : item
        );
        break;
    }
  });

export default dialog;
