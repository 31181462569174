import { useMemo } from "react";

import { useSelector } from "react-redux";

import useMonacoCompletion from "~common/hooks/useMonacoCompletion";
import useMonacoDecorator from "~common/hooks/useMonacoDecorator";
import { completionItemKind, dialogComponentsVariableTypeOptions } from "~constants";
import Network from "~helpers/Network";
import {
  selectScenarioManagerDatabaseList,
  selectScenarioManagerForms,
  selectScenarioManagerMediaList,
  selectScenarioManagerSlots,
  selectScenarioManagerVariableList,
} from "~store/dialogComponents/scenarioManager/selectors";

export default function useMonacoScenarioEditorVariables(editor) {
  const forms = useSelector(selectScenarioManagerForms);
  const slots = useSelector(selectScenarioManagerSlots);
  const mediaList = useSelector(selectScenarioManagerMediaList);
  const databaseList = useSelector(selectScenarioManagerDatabaseList);
  const variableList = useSelector(selectScenarioManagerVariableList);

  useMonacoDecorator(editor, {
    wrapperType: "curlyBrackets",
    variableResolver: (variableName) => {
      const hoverLabel = slots?.find((item) => item.name === variableName)?.question;

      return hoverLabel;
    },
    validator: (variableName) => {
      const removeList = ["media", "database", "variable", '["', '"]'];
      let pureString = variableName;
      const mergedFormItems = forms?.reduce((acc, cur) => [...acc, ...(cur.form_items || [])], []);
      for (const r of removeList) {
        pureString = pureString.replaceAll(r, "");
      }
      const isVariable = variableList?.find((item) => item.name === pureString);
      const isMedia = mediaList?.find((item) => item.name === pureString);
      const isDatabase = databaseList?.find((item) => item.name === pureString);
      const isSlot = slots?.find((item) => item.name === pureString);
      const isForm = mergedFormItems?.find((item) => item.key === pureString);
      return isVariable || isMedia || isDatabase || isSlot || isForm;
    },
  });

  const monacoIntelliSenseList = useMemo(() => {
    const list = [];

    for (const m of mediaList) {
      list.push({
        label: m.name,
        insertText: `\${media["${m.name}"]}`,
        detail: "Media",
        documentation: {
          value: [
            // MarkdownString
            "#### Image<br>",
            `[${m.name}](${m.media_url})<br>`,
            `<img src="${m.media_url}" width="200px" height="200px"/><br>`,
          ].join("\n"),
          supportHtml: true,
          isTrusted: true,
        },
        onHover: async () => {
          const response = await Network.request(m.media_url, {
            responseType: "blob",
            rawResponse: true,
          });
          const base64 = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(response.data);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });
          return {
            detail: "Media",
            documentation: {
              value: [
                // MarkdownString
                "#### Image<br/>",
                `[${m.name}](${m.media_url})<br/>`,
                `<img src="${base64}" width="160px" height="auto"/><br/>`,
              ].join("\n"),
            },
          };
        },
        filterText: `\${media["${m.name}"]}`,
        kind: completionItemKind.Struct,
      });
    }

    for (const m of databaseList) {
      list.push({
        label: m.name,
        insertText: `\${database["${m.name}"]}`,
        detail: "Database",
        documentation: {
          value: [
            // MarkdownString
            "#### File",
            `- [${m.name}](${m.data_url})`,
          ].join("\n"),
        },
        filterText: `\${database["${m.name}"]}`,
        kind: completionItemKind.Constant,
      });
    }
    for (const m of variableList) {
      const typeLabel =
        dialogComponentsVariableTypeOptions.find((item) => item.value === m.variable_type)?.label || m.variable_type;
      list.push({
        label: m.name,
        insertText: `\${${m.name}}`,
        detail: "Variable",
        documentation: {
          value: [
            // MarkdownString
            "#### Variable",
            `- Type: ${typeLabel}`,
            `- Value: \`${m.value}\``,
          ].join("\n"),
        },
        filterText: `\${${m.name}}`,
        kind: completionItemKind.Variable,
      });
    }

    for (const m of slots) {
      list.push({
        label: m.name,
        insertText: `\${${m.name}}`,
        detail: `Slot: ${m.name}`,
        documentation: {
          value: [
            // MarkdownString
            "#### Slot",
            `- Type: ${m.entity_type?.name}`,
            `- Question: ${m.question}`,
            `- Display Name: ${m.display_name}`,
            `- Key: ${m.name}`,
          ].join("\n"),
        },
        filterText: `\${${m.entity_type?.name}}\${${m.name}}\${${m.question}}\${${m.display_name}}`,
        kind: completionItemKind.Enum,
      });
    }

    let formOrder = 0;
    for (const m of forms) {
      formOrder++;
      const formItems = m?.form_items || [];
      for (const formItem of formItems) {
        list.push({
          label: formItem.key,
          insertText: `\${${formItem.key}}`,
          detail: `Form Item(${formOrder}): ${formItem.key}`,
          documentation: {
            value: [
              // MarkdownString
              `#### Form Item${formOrder}`,
              `- Type: ${formItem.entity_type?.name}`,
              `- Question: ${formItem.question}`,
              `- Display Name: ${formItem.display_key}`,
              `- Key: ${formItem.key}`,
            ].join("\n"),
          },
          filterText: `\${${formItem.entity_type?.name}}\${${formItem.key}}\${${formItem.question}}\${${formItem.display_key}}`,
          kind: completionItemKind.Variable,
        });
      }
    }
    return list;
  }, [mediaList, databaseList, variableList, slots, forms]);

  useMonacoCompletion(editor, {
    list: monacoIntelliSenseList,
  });
  return null;
}
