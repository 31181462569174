import { useEffect } from "react";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRInput, { PRTextArea, PRTextAreaFormat } from "~components/Generic/PRInput";
import PRPage from "~components/Generic/PRPage";
import PRSelect from "~components/Generic/PRSelect";
import { guideType, guideTypeOptions } from "~constants";
import HistoryHelper from "~helpers/HistoryHelper";
import { createOrUpdateGuide, getGuide, setGuide } from "~store/settings/guideDefinition/actions";
import { selectGuide } from "~store/settings/guideDefinition/selectors";
import "./style.scss";

export default function GuideDefinitionEdit() {
  const { t } = useTranslation();
  const { id } = useParams();
  const guide = useSelector(selectGuide);

  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...guide,
      title: guide?.title || "",
      test_regex: guide?.test_regex || "",
      body: guide?.body || "",
      type: guide?.type || guideType.Guide,
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t("component.formik.required").format(t("common.title"))),
      type: Yup.string().required(t("component.formik.required").format(t("common.type"))),
    }),
    onSubmit: async (values) => {
      await dispatch(createOrUpdateGuide(values));
      HistoryHelper.goBack("/settings/guide-definition", { scope: "dashboard" });
    },
  });

  useEffect(() => {
    if (!id) return;
    dispatch(getGuide(id));
    return () => {
      dispatch(setGuide(null));
    };
  }, [dispatch, id]);

  const onChangeSelect = (key) => (obj) => {
    formik.setFieldValue(key, obj);
  };
  const handleClickCancel = () => {
    HistoryHelper.goBack("/settings/guide-definition", { scope: "dashboard" });
  };

  const handleChangeBody = (e) => {
    const { value } = e.target;
    formik.setFieldValue("body", value);
  };
  const parentName = [
    {
      label: t("dashboard.guideDefinition"),
      url: "/settings/guide-definition",
    },
    {
      label: id ? t("dashboard.guideDefinition.definitionEdit") : t("dashboard.guideDefinition.definitionCreate"),
    },
  ];

  return (
    <PRContainer className="pr-data-format" name="Settings" parentName={parentName}>
      <PRPage className="" title={id ? t("dashboard.guideDefinition.edit") : t("dashboard.guideDefinition.create")}>
        <Row className="mt-2 g-2">
          <Col md="4">
            <Label size="md">{t("common.title")}</Label>
          </Col>
          <Col md="8">
            <PRInput
              invalid={formik.touched.title && formik.errors.title}
              name="title"
              type="text"
              value={formik.values.title}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
          <Col md="4">
            <Label size="md">{t("common.type")}</Label>
          </Col>
          <Col md="8">
            <PRSelect
              isPrimitiveValue
              invalid={formik.touched.type && formik.errors.type}
              isClearable={false}
              name="type"
              options={guideTypeOptions}
              value={formik.values.type}
              onBlur={formik.handleBlur}
              onChange={onChangeSelect("type")}
            />
          </Col>
          <Col md="4">
            <Label size="md">{t("dashboard.guideDefinition.regex")}</Label>
          </Col>
          <Col md="8">
            <PRInput
              invalid={formik.touched.test_regex && formik.errors.test_regex}
              name="test_regex"
              type="text"
              value={formik.values.test_regex}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>

          <Col md="4">
            <Label size="md">{t("common.body")}</Label>
          </Col>
          <Col md="8">
            <PRTextArea
              editorMode
              editorProps={{
                defaultHeight: 400,
              }}
              excludeFormatList={[PRTextAreaFormat.plain, PRTextAreaFormat.html, PRTextAreaFormat.css]}
              format={PRTextAreaFormat.markdown}
              invalid={formik.touched.body && formik.errors.body}
              name="body"
              value={formik.values.body}
              onBlur={formik.handleBlur}
              onChange={handleChangeBody}
            />
          </Col>
        </Row>
        <Row className="justify-content-end mt-2">
          <Col md="auto">
            <PRButton outline className="" onClick={handleClickCancel}>
              {t("common.cancel")}
            </PRButton>
            <PRButton className="ms-2" onClick={formik.handleSubmit}>
              {t("common.save")}
            </PRButton>
          </Col>
        </Row>
      </PRPage>
    </PRContainer>
  );
}
