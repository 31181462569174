import { useState } from "react";

import { withCardon } from "cardon";
import { useFormik } from "formik";
import { Alert } from "reactstrap";

import PRDropZone from "~components/Generic/PRDropZone";
import PRModal from "~components/Generic/PRModal";

const TemplateUploadModal = withCardon(
  function TemplateUploadModalContent({ get }) {
    const [isUploadVisible, setIsUploadVisible] = useState(false);

    const formik = useFormik({
      initialValues: {
        files: [],
      },
      onSubmit: async (values) => {
        const newFiles = [];
        for (const file of values.files) {
          newFiles.push({
            file,
          });
        }
        get(newFiles)();
      },
    });

    const handleUpload = async () => {
      if (!formik.values.files.length) {
        setIsUploadVisible(true);
        return;
      }
      setIsUploadVisible(false);
      formik.handleSubmit();
    };

    const handleFileChange = (files) => {
      setIsUploadVisible(false);
      formik.setFieldValue("files", files);
    };

    const toggle = () => setIsUploadVisible(!isUploadVisible);

    return (
      <PRModal title={"Upload File"} onClick={handleUpload} onClose={get(false)}>
        <Alert color="warning" isOpen={isUploadVisible} toggle={toggle}>
          Please select file to upload
        </Alert>

        <PRDropZone className="mt-4" maxFiles={10} onFileChange={handleFileChange} />
      </PRModal>
    );
  },
  { destroyOnHide: true }
);
export default TemplateUploadModal;
