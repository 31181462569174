import _ from "lodash";

import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

/**
 * @typedef {{
 *   key: string;
 *   variables: {
 *     label: string;
 *     value: string;
 *   }[];
 *   type: "resource" | "project" | "palmate";
 * }} TemplateType
 */

/**
 * @typedef {{
 *   id: number;
 *   message_template: number;
 *   resource: number;
 *   platform: string;
 *   default: boolean;
 *   type: string;
 *   language: string;
 *   palmate_type: string;
 *   project: number;
 * }} TemplateDefinition
 */
export const initState = {
  messageTemplate: {
    id: 0,
    title: "",
    content: "",
    language: "",
    template_format: "",
    registries: [],
    is_active: false,
    project: 0,
    resource: null,
    survey: null,
    default_for_projects: false,
    default_for_resources: false,
    default_for_surveys: false,
    platform: null,
    template_type: null,
    files: [],
  },
  messageTemplateList: [],
  /** @type {TemplateDefinition[]} */
  templateData: [],
  /** @type {TemplateType[]} */
  templateTypeList: [],
};

const popupSettings = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_MESSAGE_TEMPLATE:
        draft.messageTemplate = action.payload;
        break;
      case at.SET_MESSAGE_TEMPLATE_LIST:
        draft.messageTemplateList = action.payload;
        break;
      case at.RESET_MESSAGE_TEMPLATE:
        draft.messageTemplate = _.cloneDeep(initState.messageTemplate);
        break;
      case at.SET_TEMPLATE_DATA:
        draft.templateData = action.payload;
        break;
      case at.SET_TEMPLATE_TYPE_LIST:
        draft.templateTypeList = action.payload;
        break;
    }
  });

export default popupSettings;
