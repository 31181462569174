import React, { useEffect, useState } from "react";

import { PalTooltip } from "@palamar/fe-library";

import styled from "@emotion/styled";
import { Chip, Grid, Popper } from "@mui/material";

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    background-color: #f8f8f8;
    border-color: #f5f5f5;
  }
`;

export default function PRChatMessageFilePopper({ files, onChange }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [domWidth, setDomWidth] = useState(0);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const inputId = "chat-textbox";
    const input = document.getElementById(inputId);
    if (!input) return;

    const handleDomResize = () => {
      setDomWidth(document.getElementById(inputId)?.offsetWidth || 0);
    };
    window.addEventListener("resize", handleDomResize);
    setDomWidth(input?.offsetWidth || 0);
    setAnchorEl(input);

    return () => {
      window.removeEventListener("resize", handleDomResize);
    };
  }, [files]);

  const open = files?.length > 0;

  const handleClickBadge = (file) => () => {
    onChange?.(files?.filter((item) => item !== file));
    if (files?.length === 1) {
      setAnchorEl(null);
    }
  };
  return (
    <Popper
      anchorEl={anchorEl}
      open={open}
      placement="top-end"
      slotProps={{
        root: {
          style: {
            zIndex: 1300,
          },
        },
      }}
      style={{ width: domWidth }}
      onClose={handleClose}
    >
      <Grid container gap={0.5}>
        {files.map((file, index) => (
          <Grid key={index} item>
            <PalTooltip arrow placement="top" title={file.name}>
              <StyledChip
                label={file.name?.length > 20 ? file.name?.substring(0, 20) + "..." : file.name}
                onDelete={handleClickBadge(file)}
              />
            </PalTooltip>
          </Grid>
        ))}
      </Grid>
    </Popper>
  );
}
