import React from "react";

import { useTranslation } from "react-i18next";
import { MdEdit, MdPriorityHigh } from "react-icons/md";

import PRButton from "~components/Generic/PRButton";

import PRPage from "./Generic/PRPage";
import PRPopover from "./Generic/PRPopover";
import PRTable from "./Generic/PRTable";

const ConfusedIntentsPopup = ({ data, placement, iconClass }) => {
  const { t } = useTranslation();
  return (
    <PRPopover
      portal
      content={
        <PRPage title={t("component.confusedIntentsPopup.title")}>
          {t("component.confusedIntentsPopup.description")}
          <div>
            <PRTable
              inline
              noPagination
              columns={[
                {
                  label: t("component.confusedIntentsPopup.table.name"),
                  key: "display_text",
                  render: (row) => row.display_text || row.text,
                },
                {
                  label: t("component.confusedIntentsPopup.table.count"),
                  key: "count",
                },
                {
                  label: t("component.confusedIntentsPopup.table.actions"),
                  key: "actions",
                  actions: true,
                  fixed: "right",
                  render: (row) => {
                    return (
                      <div className="d-flex justify-content-center">
                        <PRButton
                          outline
                          color="primary"
                          icon={MdEdit}
                          link={`/chatbot/intent/form/${row.id}`}
                          linkProps={{
                            newTab: true,
                          }}
                          size="sm"
                          tooltipText="Edit"
                        />
                      </div>
                    );
                  },
                },
              ]}
              data={data}
            />
          </div>
        </PRPage>
      }
      placement={placement || "bottom"}
    >
      <div className={`text-danger ${iconClass}`} style={{ cursor: "pointer" }}>
        <MdPriorityHigh />
      </div>
    </PRPopover>
  );
};

export default ConfusedIntentsPopup;
