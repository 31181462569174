import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { authProtectedRoutes } from "~routes";
import { setSelectedBot, setSelectedProject } from "~store/actions";
import { selectBots, selectProjects } from "~store/user/selectors";

export default function AuthorizedRedirect({ to }) {
  const dispatch = useDispatch();
  const projects = useSelector(selectProjects);
  const bots = useSelector(selectBots);
  const firstProject = projects?.[0];

  const botsForProject = bots.filter((bot) => bot.projectId === firstProject?.id);
  const mainBotId = firstProject?.main_bot;
  const targetBotId = mainBotId ?? botsForProject?.[0]?.id;
  const firstBot = botsForProject.find((bot) => bot.id === targetBotId);
  // if (firstProject) {
  dispatch(setSelectedProject(firstProject));
  dispatch(setSelectedBot(firstBot));
  const permissions = firstProject?.permissions || [];
  const availableRoutes = authProtectedRoutes.filter((route) => {
    const routePermissions =
      route.permissions?.length && typeof route.permissions === "string" ? [route.permissions] : route.permissions;
    if (!firstProject?.id && route.noPermission) return true;
    if (routePermissions && Array.isArray(routePermissions)) {
      return routePermissions.some((permission) => permissions.includes(permission));
    }
    return false;
  });
  const firstAvailableRoute = availableRoutes?.[0];
  const redirectRoute = to || firstAvailableRoute?.path;

  return <Redirect to={`/dashboard/project/${firstProject?.id || 0}/bot/${firstBot?.id || 0}${redirectRoute}`} />;
  // } else {
  //   return <Redirect to={`/dashboard/project/0/bot/0/`} />;
  // }
}
