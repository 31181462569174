import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { MdHistory } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import styled from "@emotion/styled";
import { Grid, Tab, Tabs } from "@mui/material";

import useLoading from "~common/hooks/useLoading";
import PRChart from "~components/Generic/PRChart";
import chartTheme from "~components/Generic/PRChart/theme.json";
import PalContainer from "~components/mui/PalContainer";
import PalPage from "~components/mui/PalPage";
import AggregateCard from "~components/Statistics/AggregateCard";
import ExcelButton from "~components/Statistics/ExcelButton";
import FilterCard from "~components/Statistics/FilterCard";
import AlertHelper from "~helpers/AlertHelper";
import FileHelper from "~helpers/FileHelper";
import {
  getTicketResolveStatistics,
  getTicketStackBarStatistics,
  getTicketUnassignedStatistics,
} from "~store/statistics/actions";
import { selectStatistics, selectStatisticsFilter } from "~store/statistics/selectors";

const chartTooltip = {
  trigger: "axis",
  axisPointer: {
    type: "shadow", // Gölge tipi gösterim
  },
  formatter: function (params) {
    const date = new Date(params[0].data[0]);
    const formattedDate = date.toLocaleDateString();
    let tooltipText = `${formattedDate}<br/>`;
    if (params.value === 0) {
      return "";
    }
    const hasAnyValue = params.some((item) => item.value[1] !== 0);
    if (!hasAnyValue) {
      return "";
    }
    params.forEach((item) => {
      if (item.value[1] !== 0) {
        tooltipText += `${item.marker} ${item.seriesName}: ${item.data[1]}<br/>`;
      }
    });
    return tooltipText;
  },
};

const chartStyle = {
  height: "600px",
  maxHeight: "50vh",
};
const TicketResolveStatistics = () => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectStatistics);
  const { t } = useTranslation();
  useEffect(() => {
    q(dispatch(getTicketResolveStatistics(projectId, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate]);

  const filteredData = [
    {
      value: statistics?.TICKET_RESOLVE_15,
      name: t("dashboard.ticketStatistics.15min"),

      itemStyle: {
        color: chartTheme.theme.color[5],
      },
    },
    {
      value: statistics?.TICKET_RESOLVE_60,
      name: t("dashboard.ticketStatistics.1hr"),
      itemStyle: {
        color: chartTheme.theme.color[9],
      },
    },
    {
      value: statistics?.TICKET_RESOLVE_120,
      name: t("dashboard.ticketStatistics.2hrs"),
      itemStyle: {
        color: chartTheme.theme.color[2],
      },
    },
    {
      value: statistics?.TICKET_RESOLVE_360,
      name: t("dashboard.ticketStatistics.6hrs"),
      itemStyle: {
        color: chartTheme.theme.color[10],
      },
    },
    {
      value: statistics?.TICKET_RESOLVE_1440,
      name: t("dashboard.ticketStatistics.1day"),
      itemStyle: {
        color: chartTheme.theme.color[11],
      },
    },
    {
      value: statistics?.TICKET_RESOLVE_2880,
      name: t("dashboard.ticketStatistics.2day"),
      itemStyle: {
        color: chartTheme.theme.color[6],
      },
    },
    {
      value: statistics?.TICKET_RESOLVE_2880_PLUS,
      name: t("dashboard.ticketStatistics.2plusDay"),
      itemStyle: {
        color: chartTheme.theme.color[3],
      },
    },
  ].filter((item) => !!item.value);

  const options = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      data: [...filteredData.map((item) => item.name)],
    },
    series: [
      {
        name: t("dashboard.ticketStatistics.platforms"),
        type: "pie",
        selectedMode: "single",
        radius: [0, "70%"],
        label: {
          position: "inner",
          fontSize: 14,
        },
        labelLine: {
          //   show: false,
        },
        data: filteredData,
      },
    ],
  };
  return (
    <PRChart
      notMerge
      loading={loading}
      option={options}
      style={chartStyle}
      title={t("dashboard.ticketStatistics.ticketResolvePieChart")}
    />
  );
};
const TicketUnassignedStatistics = () => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectStatistics);
  const { t } = useTranslation();
  useEffect(() => {
    q(dispatch(getTicketUnassignedStatistics(projectId, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate]);

  const filteredData = [
    {
      value: statistics?.TICKET_UNASSIGNED_15,
      name: t("dashboard.ticketStatistics.15min"),
      itemStyle: { color: chartTheme.theme.color[5] },
    },
    {
      value: statistics?.TICKET_UNASSIGNED_60,
      name: t("dashboard.ticketStatistics.1hr"),
      itemStyle: {
        color: chartTheme.theme.color[9],
      },
    },
    {
      value: statistics?.TICKET_UNASSIGNED_120,
      name: t("dashboard.ticketStatistics.2hrs"),
      itemStyle: {
        color: chartTheme.theme.color[2],
      },
    },
    {
      value: statistics?.TICKET_UNASSIGNED_360,
      name: t("dashboard.ticketStatistics.6hrs"),
      itemStyle: {
        color: chartTheme.theme.color[10],
      },
    },
    {
      value: statistics?.TICKET_UNASSIGNED_1440,
      name: t("dashboard.ticketStatistics.1day"),
      itemStyle: {
        color: chartTheme.theme.color[11],
      },
    },
    {
      value: statistics?.TICKET_UNASSIGNED_2880,
      name: t("dashboard.ticketStatistics.2day"),
      itemStyle: {
        color: chartTheme.theme.color[6],
      },
    },
    {
      value: statistics?.TICKET_UNASSIGNED_2880_PLUS,
      name: t("dashboard.ticketStatistics.2plusDay"),
      itemStyle: {
        color: chartTheme.theme.color[3],
      },
    },
  ].filter((item) => !!item.value);

  const options = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      data: [...filteredData.map((item) => item.name)],
    },
    series: [
      {
        name: t("dashboard.ticketStatistics.platforms"),
        type: "pie",
        selectedMode: "single",
        radius: [0, "70%"],
        label: {
          position: "inner",
          fontSize: 14,
        },
        labelLine: {
          //   show: false,
        },
        data: filteredData,
      },
    ],
  };
  return (
    <PRChart
      notMerge
      loading={loading}
      option={options}
      style={chartStyle}
      title={t("dashboard.ticketStatistics.ticketUnassignedPieChart")}
    />
  );
};
const TicketStackBarStatistics = () => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectStatistics);
  const { t } = useTranslation();
  useEffect(() => {
    q(dispatch(getTicketStackBarStatistics(projectId, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate]);

  const barWidth = 80;

  const options = {
    tooltip: chartTooltip,
    xAxis: {
      type: "time",
      min: filter.beginDate, // Start date
      max: filter.endDate, // End date
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: Object.entries(statistics?.TICKET_OPEN_COUNT || {}),
        type: "bar",
        stack: "total",
        name: t("dashboard.ticketStatistics.openTicket"),
        itemStyle: { color: chartTheme.theme.color[6] },
      },
      {
        data: Object.entries(statistics?.TICKET_INPROGRESS_COUNT || {}),
        type: "bar",
        stack: "total",
        name: t("dashboard.ticketStatistics.inProgressTicket"),
        itemStyle: { color: chartTheme.theme.color[2] },
      },
      {
        data: Object.entries(statistics?.TICKET_CLOSED_COUNT || {}),
        type: "bar",
        stack: "total",
        name: t("dashboard.ticketStatistics.closedTicket"),
        itemStyle: { color: chartTheme.theme.color[5] },
      },
    ].map((item) => {
      return {
        ...item,
        barWidth: `${barWidth}%`,
      };
    }),
  };
  return (
    <PRChart
      notMerge
      loading={loading}
      option={options}
      style={chartStyle}
      title={t("dashboard.ticketStatistics.ticketStatusStackBar")}
    />
  );
};

const StyledTab = styled(Tab)`
  font-weight: 600;
`;

const StyledGrid = styled(Grid)`
  align-items: center;
  display: flex;
  justify-content: center;
  align-self: center;
`;
const TicketStatistics = () => {
  const [tab, setTab] = useState(0);
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const filter = useSelector(selectStatisticsFilter);
  const { t } = useTranslation();

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleExcelExport = async () => {
    let fn;
    switch (tab) {
      case 0:
        fn = getTicketResolveStatistics;
        break;
      case 1:
        fn = getTicketUnassignedStatistics;
        break;
      case 2:
        fn = getTicketStackBarStatistics;
        break;
      default:
        break;
    }
    if (!fn) {
      AlertHelper.show(t("dashboard.ticketStatistics.noDataError"), "error");
      return;
    }

    // get the buffer from fn(), and then save it as a file
    const buffer = await dispatch(fn(projectId, filter.beginDate, filter.endDate, true));
    FileHelper.saveAs(buffer, "statistics", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
  };

  return (
    <PalContainer
      description={t("dashboard.ticketStatistics.description")}
      name={t("common.statistics")}
      parentName={t("dashboard.ticketStatistics")}
    >
      <Grid container>
        <Grid item xs={12}>
          <FilterCard />
        </Grid>
        <Grid item md={4} xs={12}>
          <AggregateCard
            icon={MdHistory}
            statisticKey="TICKET_COUNT"
            title={t("dashboard.ticketStatistics.totalTicket")}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <AggregateCard
            icon={MdHistory}
            statisticKey="TICKET_CUSTOMER_REPLY_COUNT"
            title={t("dashboard.ticketStatistics.totalCustomerReply")}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <AggregateCard
            icon={MdHistory}
            statisticKey="TICKET_AGENT_REPLY_COUNT"
            title={t("dashboard.ticketStatistics.totalAgentReply")}
          />
        </Grid>
      </Grid>
      <PalPage collapse>
        <Grid container rowSpacing={2}>
          <Grid item md xs={10}>
            <Tabs value={tab} variant="scrollable" onChange={handleChangeTab}>
              <StyledTab label={t("dashboard.ticketStatistics.ticketResolve")} />
              <StyledTab label={t("dashboard.ticketStatistics.ticketUnassigned")} />
              <StyledTab label={t("dashboard.ticketStatistics.ticketStatus")} />
            </Tabs>
          </Grid>
          <StyledGrid item md={"auto"} xs={2}>
            <ExcelButton onClick={handleExcelExport} />
          </StyledGrid>
          <Grid item xs={12}>
            {tab === 0 && <TicketResolveStatistics />}
            {tab === 1 && <TicketUnassignedStatistics />}
            {tab === 2 && <TicketStackBarStatistics />}
          </Grid>
        </Grid>
      </PalPage>
    </PalContainer>
  );
};
export default TicketStatistics;
