import React, { useCallback } from "react";

import classNames from "classnames";
import { NumericFormat, PatternFormat } from "react-number-format";
import { FormFeedback, Input } from "reactstrap";

import "./style.scss";

/**
 * @typedef {Omit<import("react-number-format").PatternFormatProps, "onValueChange"> & {
 *   onChange: import("react-number-format").OnValueChange;
 *   invalid?: string;
 * }} Props
 * @param {Props} param0
 */
export function PRNumberFormat({ className, borderless, invalid, onChange, size = "md", ...rest }) {
  const handleValueChange = useCallback(
    (...args) => {
      onChange?.(...args);
    },
    [onChange]
  );

  return (
    <div
      className={classNames(
        "pr-number pr-number-format",
        {
          small: size === "sm",
          invalid: !!invalid,
          borderless: borderless,
        },
        className
      )}
    >
      <div className="pr-number-inline">
        <PatternFormat {...rest} onValueChange={handleValueChange} />
      </div>
      {typeof invalid === "string" && invalid?.length > 0 && (
        <>
          <Input className="d-none" invalid={!!invalid} />
          {invalid && <FormFeedback type="invalid">{invalid}</FormFeedback>}
        </>
      )}
    </div>
  );
}
//exclude onchange prop in type

/**
 * @typedef {Omit<import("react-number-format").NumericFormatProps, "onValueChange"> & {
 *   onChange: import("react-number-format").OnValueChange;
 *   invalid?: string;
 * }} Props
 * @param {Props} param0
 */
function PRNumber({ className, borderless, onChange, invalid, size = "md", ...rest }) {
  const handleValueChange = useCallback(
    (...args) => {
      onChange?.(...args);
    },
    [onChange]
  );
  return (
    <div
      className={classNames(
        "pr-number pr-number-numeric",
        {
          small: size === "sm",
          invalid: !!invalid,
          borderless: borderless,
        },
        className
      )}
    >
      <div className="pr-number-inline">
        <NumericFormat {...rest} onValueChange={handleValueChange} />
      </div>
      {typeof invalid === "string" && invalid?.length > 0 && (
        <>
          <Input className="d-none" invalid={!!invalid} />
          {invalid && <FormFeedback type="invalid">{invalid}</FormFeedback>}
        </>
      )}
    </div>
  );
}

export default PRNumber;
