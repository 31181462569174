import { all, select, takeLatest } from "redux-saga/effects";

import { LS_ANNOUNCEMENT_ACK_ID_LIST } from "~constants";
import StorageHelper from "~helpers/StorageHelper";

import * as at from "./actionTypes";
import { selectAppAcknowledgedAnnouncementIds } from "./selectors";
function* handleReadAppAnnouncement({ payload }) {
  const ackAppAnnouncementIdList = yield select(selectAppAcknowledgedAnnouncementIds);

  const newList = [...ackAppAnnouncementIdList, payload];
  StorageHelper.set(LS_ANNOUNCEMENT_ACK_ID_LIST, newList);
}

function* scenarioManagerSaga() {
  yield all([takeLatest(at.READ_ANNOUNCEMENT, handleReadAppAnnouncement)]);
}

export default scenarioManagerSaga;
