import { safeProduce as produce } from "~helpers/immer";

import * as actionTypes from "./actionTypes";

/**
 * @typedef {{
 *   key: string;
 *   message: string;
 *   type: "default" | "error" | "success" | "warning" | "info";
 *   dispatched: boolean;
 * } & import("notistack").OptionsObject} Alert
 */
export const initState = {
  /** @type {Alert[]} */
  alertList: [],
  alertDom: null,
};

const alert = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.ALERT_ADD: {
        draft.alertList.push(action.payload);
        break;
      }
      case actionTypes.ALERT_REMOVE: {
        const key = action.payload;
        draft.alertList = draft.alertList.filter((item) => item.key !== key);
        break;
      }
      case actionTypes.ALERT_UPDATE: {
        const { key, newAlert } = action.payload;
        draft.alertList = draft.alertList.map((item) => (item.key === key ? newAlert : item));
        break;
      }
      case actionTypes.ALERT_SET_LIST: {
        draft.alertList = action.payload;
        break;
      }
      case actionTypes.ALERT_SET_DOM: {
        draft.alertDom = action.payload;
        break;
      }
    }
  });

export default alert;
