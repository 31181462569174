import PRSwitch from "~components/Generic/PRSwitch";

import EntityTypesEdit from "./EntityTypesEdit";
import EntityTypesList from "./EntityTypesList";

function EntityTypes() {
  const routes = [
    { path: "/chatbot/entity-types/form/:id?", component: EntityTypesEdit },
    { path: "/chatbot/entity-types/", component: EntityTypesList },
    { path: "/chatbot/", to: "chatbot/entity-types/", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}

export default EntityTypes;
