import styled from "@emotion/styled";
import { Badge } from "@mui/material";

const RippleBadge = styled(Badge, {
  shouldForwardProp: (prop) => !["color", "noAnimation"].includes(prop),
})(({ theme, color = "success", noAnimation }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: `${theme.palette[color].light}`,
    color: `${theme.palette[color].light}`,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 4.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
      ...(noAnimation && {
        animation: "none",
      }),
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "28.57%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
    "100%": {
      transform: "scale(.8)",
      opacity: 0,
    },
  },
}));

export default RippleBadge;
