import React from "react";

import classNames from "classnames";
import { BiLoaderCircle } from "react-icons/bi";
import { Col, Label, Row } from "reactstrap";

import PRPage from "~components/Generic/PRPage";
import "./style.scss";
function formatNumber(number) {
  if (!number) return 0;
  const num = Number(number);
  if (Number.isNaN(num)) return number;
  // Nine Zeroes for Billions
  return Math.abs(num) >= 1.0e9
    ? `${(Math.abs(num) / 1.0e9).toFixed(2)}B`
    : // Six Zeroes for Millions
      Math.abs(num) >= 1.0e6
      ? `${(Math.abs(num) / 1.0e6).toFixed(2)}M`
      : // Three Zeroes for Thousands
        Math.abs(num) >= 1.0e3
        ? `${(Math.abs(num) / 1.0e3).toFixed(2)}K`
        : Math.abs(num).toFixed(0);
}

export function DataCardInline({
  value,
  valueSuffix,
  valuePrefix,
  label,
  infoText,
  icon: IconComp,
  color = "success",
  style,
  className,
  loading,
}) {
  return (
    <div className={classNames("pr-data-card-inline", className)} style={style}>
      <div className="avatar-out mini-stat-icon">
        <div
          className={classNames("avatar-in avatar-title rounded-circle text-white font-size-24", {
            [`bg-${color}-200`]: true,
          })}
          style={{
            border: `1px solid var(--bs-${color}-600)`,
          }}
        >
          {typeof IconComp === "string" ? (
            <i
              className={classNames(IconComp, {
                [`fs-4 text-${color}-600`]: true,
              })}
            />
          ) : (
            <IconComp className={`fs-2 text-${color}-600`} />
          )}
        </div>
      </div>
      <Row className="g-2 align-items-center">
        <Col xs>
          <div className="fs-1 lh-1">
            {loading ? (
              <BiLoaderCircle className="fs-1 spin" />
            ) : (
              <>
                {!!valuePrefix && <span className="fs-5 me-1">{valuePrefix}</span>}
                {formatNumber(value ?? 0)}
                {!!valueSuffix && <span className="fs-5 ms-1">{valueSuffix}</span>}
              </>
            )}
          </div>
        </Col>
      </Row>

      <Row className="mt-2 g-0 align-items-start">
        <Col xs>
          <Label className="lh-1 fs-5 fw-normal">{label}</Label>
        </Col>
        <Col xs="auto">{infoText}</Col>
      </Row>
    </div>
  );
}
export default function DataCard({
  value,
  valueSuffix,
  valuePrefix,
  label,
  icon,
  color = "success",
  loading,
  ...rest
}) {
  return (
    <PRPage className="pr-data-card mini-stats-wid">
      <DataCardInline
        color={color}
        icon={icon}
        label={label}
        loading={loading}
        value={value}
        valuePrefix={valuePrefix}
        valueSuffix={valueSuffix}
        {...rest}
      />
    </PRPage>
  );
}
