import { apiUrlGuide } from "~constants";
import Network from "~helpers/Network";

import * as actionTypes from "./actionTypes";

export const setGuide = (payload) => ({
  type: actionTypes.SET_GUIDE,
  payload,
});
export const setGuideList = (payload) => ({
  type: actionTypes.SET_GUIDE_LIST,
  payload,
});

export const getGuide = (id) => () => {
  const url = `${apiUrlGuide.get}${id}/`;
  return Network.request(url, {
    loading: true,
    onSuccess: setGuide,
  });
};

export const getGuideList = () => () => {
  const url = `${apiUrlGuide.get}`;
  return Network.request(url, {
    loading: true,
    params: {
      limit: 9999,
    },
    onSuccess: ({ results = [] }) => setGuideList(results),
  });
};
export const createOrUpdateGuide = (data) => () => {
  let url = `${apiUrlGuide.get}`;
  if (data?.id) {
    url = `${apiUrlGuide.get}${data.id}/`;
  }
  return Network.request(url, {
    method: data?.id ? "PUT" : "POST",
    loading: true,
    data,
    successMsg: true,
  });
};

export const deleteGuide = (id) => () => {
  const url = `${apiUrlGuide.get}${id}/`;
  return Network.request(url, {
    method: "DELETE",
    loading: true,
    successMsg: true,
  });
};
