import PRSwitch from "~components/Generic/PRSwitch";
import { userRole } from "~constants";

import Actions from "./Actions";
import Announcement from "./Announcement";
import Customer from "./Customer";
import Filter from "./Filter";
import MemberData from "./MemberData";
import Resources from "./Resources";
import Surveys from "./Survey";

export default function Organization() {
  const routes = [
    {
      path: "/organization/customers",
      component: Customer,
      permissions: [userRole.admin, userRole.organizationManager],
    },
    {
      path: "/organization/customer-data-format",
      component: MemberData,
      permissions: [userRole.admin, userRole.organizationManager],
    },
    {
      path: "/organization/filter-templates",
      component: Filter,
      permissions: [userRole.admin, userRole.organizationManager],
    },
    {
      path: "/organization/action-templates",
      component: Actions,
      permissions: [userRole.admin, userRole.organizationManager],
    },
    {
      path: "/organization/announcement",
      component: Announcement,
      permissions: [userRole.admin, userRole.organizationManager],
    },
    {
      path: "/organization/surveys",
      component: Surveys,
      permissions: [userRole.admin, userRole.organizationManager],
    },
    {
      path: "/organization/reservations",
      component: Resources,
      permissions: [userRole.admin, userRole.organizationManager, userRole.ResourceManager],
    },
    // {
    //   path: "/organization/reservation-generator",
    //   component: ReservationGenerator,
    //   permissions: [userRole.admin, userRole.organizationManager],
    // },
    {
      path: "/organization",
      to: "/organization/customers",
      redirect: true,
      permissions: [userRole.admin, userRole.organizationManager],
    },
  ];
  return <PRSwitch routes={routes} />;
}
