import { useCallback, useEffect, useMemo, useState } from "react";

import {
  PalCodeEditor,
  PalSelect,
  PalPage,
  PalTypography,
  PalButton,
  PalContainer,
  PalTextField,
} from "@palamar/fe-library";
import chroma from "chroma-js";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import styled from "@emotion/styled";
import { Alert, Box, Grid } from "@mui/material";

import useMonacoDecorator from "~common/hooks/useMonacoDecorator";
import useProjectChange from "~common/hooks/useProjectChange";
import { messageTemplateVariableFormat, userRole } from "~constants";
import { chatMessageTypeOptions, chatMessageTypes } from "~constants/contactCenter";
import AlertHelper from "~helpers/AlertHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import { createOrUpdateDefaultMessage, getDefaultMessage, setDefaultMessage } from "~store/knowledgeBase/actions";
import { selectDefaultMessage } from "~store/knowledgeBase/selectors";
import { selectPopupSettingsState } from "~store/settings/popupSettings/selectors";
import { selectCurrentProject, selectUserInfo } from "~store/user/selectors";

const TitleGridItem = styled(Grid)`
  flex-direction: column;
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
`;

const ButtonContainer = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

const VariablesDiv = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing(2)};
`;

const CodeEditorTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const VariableButton = styled(PalButton)`
  margin-left: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

const DefaultMessageEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const defaultMessage = useSelector(selectDefaultMessage);
  const currentProject = useSelector(selectCurrentProject);
  const userInfo = useSelector(selectUserInfo);
  const [codeEditor, setCodeEditor] = useState(null);
  const dispatch = useDispatch();
  const handleClickCancel = () => {
    HistoryHelper.goBack("/contactcenter/chat-messages/", { scope: "dashboard" });
  };
  const popupSettings = useSelector(selectPopupSettingsState);

  const isAdminOrAgentManager = useMemo(
    () =>
      currentProject?.permissions?.includes(userRole.admin) ||
      currentProject?.permissions?.includes(userRole.AgentManager),
    [currentProject]
  );
  const isOwner = useMemo(() => userInfo?.id === defaultMessage?.owner, [defaultMessage, userInfo]);

  useEffect(() => {
    if (!id) return;
    dispatch(getDefaultMessage(currentProject.id, id));

    return () => {
      dispatch(setDefaultMessage({}));
    };
    // Page should be redirected to list if selected project is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...defaultMessage,
      title: defaultMessage.title || "",
      data: defaultMessage.data || "",
      message_type: defaultMessage.message_type || chatMessageTypes.PRIVATE,
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t("component.formik.required").format(t("common.title"))),
      data: Yup.string().required(t("component.formik.required").format(t("common.message"))),
      message_type: Yup.string().required(
        t("component.formik.required").format(t("dashboard.chatMessagesEdit.messageType"))
      ),
    }),
    onSubmit: (values) => {
      if (values.data.length > 750) {
        AlertHelper.showError(
          t("component.formik.maxLength").format(t("dashboard.chatMessagesEdit.messageBody"), "750")
        );
        return;
      }
      dispatch(
        createOrUpdateDefaultMessage(currentProject.id, {
          ...values,
          project: currentProject.id,
          owner: userInfo.id,
        })
      ).then(() => {
        handleClickCancel();
      });
    },
  });

  useProjectChange(() => {
    HistoryHelper.push("/contactcenter/chat-messages/", { scope: "dashboard" });
  }, []);

  const variableStatus = useMemo(() => {
    const formItems = popupSettings.welcome_form?.form_items || [];
    const variables = {};
    formItems.forEach((item) => {
      variables[item.form_key] = formik.values.data.includes(`<<${item.form_key}>>`);
    });
    return variables;
  }, [formik.values.data, popupSettings.welcome_form?.form_items]);

  const parentName = useMemo(
    () => [
      {
        label: t("dashboard.chatMessages"),
        url: "/contactcenter/chat-messages",
      },
      {
        label: id ? t("dashboard.chatMessagesEdit.editMessage") : t("dashboard.chatMessagesEdit.createMessage"),
      },
    ],
    [id, t]
  );

  const handleChangeEditor = (value) => {
    formik.setFieldValue("data", value);
  };

  const handleClickVariable = (variable) => () => {
    if (codeEditor.editor) {
      //  && formik.values.template_format !== settingsTemplateFormat.PLAIN
      //insert text at cursor position in monaco editor
      const position = codeEditor.editor.getPosition();
      codeEditor.editor.executeEdits("", [
        {
          range: new codeEditor.monaco.Range(
            position.lineNumber,
            position.column,
            position.lineNumber,
            position.column
          ),
          text: messageTemplateVariableFormat.format(variable),
        },
      ]);
    }
  };

  useMonacoDecorator(codeEditor?.editor);
  const getContentLimitColor = useCallback((value) => {
    return chroma
      .scale(["#66CC00", "#FFD700", "#FF0000"])(value / 100)
      .hex();
  }, []);

  const variableList = useMemo(() => {
    return [
      ...(popupSettings.welcome_form?.form_items || []),
      {
        form_key: "agent_name",
        form_label: t("dashboard.chatMessagesEdit.agentName"),
      },
    ];
  }, [popupSettings.welcome_form?.form_items, t]);

  const handleMessageTypeChange = (e, value) => {
    formik.setFieldValue("message_type", value);
  };

  return (
    <PalContainer
      description={t("dashboard.chatMessagesEdit.description")}
      name={t("common.contactCenter")}
      parentName={parentName}
    >
      <PalPage>
        <Grid container spacing={2}>
          {isAdminOrAgentManager && !isOwner && (
            <Grid item xs="12">
              <Alert severity="info">
                {t("dashboard.chatMessagesEdit.editingInfo1")}
                <b>{defaultMessage.owner_name}</b>
                {t("dashboard.chatMessagesEdit.editingInfo2")}
              </Alert>
            </Grid>
          )}
          <TitleGridItem item xs={isAdminOrAgentManager ? "6" : "12"}>
            <PalTypography>{t("common.title")}</PalTypography>
            <PalTextField
              error={formik.touched.title && formik.errors.title}
              name="title"
              placeholder={t("dashboard.chatMessagesEdit.titlePlaceholder")}
              value={formik.values.title}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </TitleGridItem>
          {isAdminOrAgentManager && (
            <TitleGridItem item xs="6">
              <PalTypography>{t("dashboard.chatMessagesEdit.messageType")}</PalTypography>
              <PalSelect
                isPrimitiveValue
                disabled={!isOwner && defaultMessage?.id}
                invalid={formik.touched.message_type && formik.errors.message_type}
                name="message_type"
                options={chatMessageTypeOptions}
                value={formik.values.message_type}
                onChange={handleMessageTypeChange}
              />
            </TitleGridItem>
          )}
          <Grid item xs="12">
            <CodeEditorTopBar>
              <PalTypography>{t("dashboard.chatMessagesEdit.messageBody")}</PalTypography>
              <VariablesDiv>
                {variableList?.length > 0 && (
                  <>
                    <PalTypography>{t("common.variables")}:</PalTypography>
                    <div>
                      {variableList?.map((item) => {
                        return (
                          <VariableButton
                            key={item.form_key}
                            color={variableStatus[item.form_key] ? "success" : "warning"}
                            size="small"
                            tooltipText={item.form_label}
                            variant="contained"
                            onClick={handleClickVariable(item.form_key)}
                          >
                            {item.form_key}
                          </VariableButton>
                        );
                      })}
                    </div>
                  </>
                )}
              </VariablesDiv>
            </CodeEditorTopBar>
            <PalCodeEditor
              customCodeToolbar={
                <Box
                  sx={{
                    color: getContentLimitColor(Math.min((formik.values.data.length / 750) * 100, 100)),
                    mr: "auto",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  {formik.values.data.length > 750 && <>{t("dashboard.chatMessagesEdit.exceedLimit")} -&nbsp;</>}
                  {formik.values.data.length}/750
                </Box>
              }
              defaultLanguage="plaintext"
              invalid={formik.touched.data && formik.errors.data}
              minimap={false}
              value={formik.values.data}
              onChange={handleChangeEditor}
              onMount={setCodeEditor}
            />
          </Grid>
        </Grid>

        <ButtonContainer container>
          <Grid container item spacing={1} xs="auto">
            <Grid item xs={6}>
              <PalButton variant="outlined" onClick={handleClickCancel}>
                {t("common.cancel")}
              </PalButton>
            </Grid>
            <Grid item xs={6}>
              <PalButton variant="contained" onClick={formik.handleSubmit}>
                {id ? t("common.update") : t("common.create")}
              </PalButton>
            </Grid>
          </Grid>
        </ButtonContainer>
      </PalPage>
    </PalContainer>
  );
};

export default DefaultMessageEdit;
