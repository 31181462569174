import { forwardRef, useCallback, useEffect, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Box } from "@mui/material";

import PRPagination from "~components/Generic/PRPagination";
import SessionList from "~components/Session/SessionList";
import { chatbotLivechatPageSize } from "~constants";
import HistoryHelper from "~helpers/HistoryHelper";
import {
  setLivechatLoadingStatus,
  setOtherSessionsInfo,
  setSelectedSession,
  wsAskSessionInfo,
} from "~store/socket/livechat/actions";
import { selectOtherSessionsInfo, selectSelectedSession, selectSessions } from "~store/socket/livechat/selectors";

const LiveSessionList = forwardRef(function LiveSessionList(
  { showAssignedToMeOption, visibleStatusList, nonVisibleStatusList },
  ref
) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const selectedSession = useSelector(selectSelectedSession);
  const sessions = useSelector(selectSessions);
  const { count, page } = useSelector(selectOtherSessionsInfo);

  useEffect(() => {
    if (!id || !sessions?.length || selectedSession?.id === Number(id)) return;
    const session = sessions.find((session) => session.id === Number(id));
    if (session) {
      dispatch(setSelectedSession(session));
    }
  }, [id, sessions, dispatch, selectedSession?.id]);

  const handleClick = useCallback(
    (session) => {
      dispatch(setSelectedSession(session));
      HistoryHelper.replace(`/contactcenter/livechat/${session.id}/`, { scope: "dashboard" });
    },
    [dispatch]
  );

  const handleChangePage = useCallback(
    (page) => {
      const offset = (page - 1) * chatbotLivechatPageSize;
      dispatch(setLivechatLoadingStatus(true));
      dispatch(setOtherSessionsInfo({ page }));
      dispatch(wsAskSessionInfo(chatbotLivechatPageSize, offset));
    },
    [dispatch]
  );

  const footerComponent = useMemo(() => {
    const safePage = Math.min(page, Math.ceil(count / chatbotLivechatPageSize));
    return (
      <Box>
        <PRPagination
          className="my-1"
          memoizeAsQuery={false}
          pageSize={chatbotLivechatPageSize}
          total={count}
          value={safePage}
          onChange={handleChangePage}
        />
      </Box>
    );
  }, [handleChangePage, page, count]);

  return (
    <SessionList
      footerComponent={!!nonVisibleStatusList?.length && footerComponent}
      nonVisibleStatusList={nonVisibleStatusList}
      selectedSession={selectedSession}
      sessions={sessions}
      showAssignedToMeOption={showAssignedToMeOption}
      visibleStatusList={visibleStatusList}
      onClick={handleClick}
    />
  );
});

export default LiveSessionList;
