import { apiUrlLowcode } from "~constants";
import Network from "~helpers/Network";

import * as at from "./actionTypes";

export const setIntellisenseData = (payload) => ({
  type: at.SET_INTELLISENSE_DATA,
  payload,
});

//---------- API ----------

export const getIntellisenseData = (projectId) => (_dispatch, getState) => {
  const url = apiUrlLowcode.getDocumentation.format(projectId);

  return Network.request(url, {
    loading: false,
    onSuccess: setIntellisenseData,
  });
};
