import { apiUrlChatbot } from "~constants";
import Network from "~helpers/Network";

import * as at from "./actionTypes";

export const setIntentTag = (payload) => ({
  type: at.SET_INTENT_TAG,
  payload,
});

// -----------  API ------------

export const getIntentTag =
  (projectId, chatbotId, id, options = {}) =>
  (_dispatch, _getState) => {
    const url = `${apiUrlChatbot.getIntentTags.format(projectId, chatbotId)}${id}/`;

    return Network.request(url, {
      loading: true,
      onSuccess: setIntentTag,
      ...options,
    });
  };

export const getIntentTags =
  (projectId, chatbotId, options = {}) =>
  (_dispatch, _getState) => {
    const url = apiUrlChatbot.getIntentTags.format(projectId, chatbotId);
    return Network.request(url, {
      ...options,
    });
  };

export const createOrUpdateIntentTag = (projectId, chatbotId, data) => (_dispatch, _getState) => {
  const url = data?.id
    ? `${apiUrlChatbot.getIntentTags.format(projectId, chatbotId)}${data?.id}/`
    : apiUrlChatbot.getIntentTags.format(projectId, chatbotId);

  return Network.request(url, {
    method: data?.id ? "PUT" : "POST",
    data,
    loading: true,
  });
};

export const deleteIntentTag = (projectId, chatbotId, id) => (_dispatch, _getState) => {
  const url = `${apiUrlChatbot.getIntentTags.format(projectId, chatbotId)}${id}/`;

  return Network.request(url, {
    method: "DELETE",
    successMsg: true,
    loading: true,
  });
};
