import PRSwitch from "~components/Generic/PRSwitch";
import { userRole } from "~constants";
import AgentStats from "~pages/Statistics/AgentStats";

import AdminWallboard from "./AdminWallboard";
import AgentLeaderboard from "./AgentLeaderboard";
import ChatHistory from "./ChatHistory";
import ChatLabels from "./ChatLabels";
import DefaultMessage from "./DefaultMesssage";
import LiveChat from "./LiveChat";
import WhatsappTemplateMessages from "./LiveChat/WhatsappTemplateMessages";

function ContactCenter() {
  const routes = [
    {
      path: "/contactcenter/livechat/whatsapp-template-messages/",
      component: WhatsappTemplateMessages,
      permissions: [userRole.admin, userRole.callCenterAgent],
    },
    {
      path: "/contactcenter/livechat/:id?/",
      component: LiveChat,
      permissions: [userRole.admin, userRole.callCenterAgent],
    },
    {
      path: "/contactcenter/chat-messages/",
      component: DefaultMessage,
      permissions: [userRole.admin, userRole.callCenterAgent, userRole.ticketAgent],
    },
    {
      path: "/contactcenter/chat-labels/",
      component: ChatLabels,
      permissions: [userRole.admin, userRole.callCenterAgent, userRole.ticketAgent],
    },
    {
      path: "/contactcenter/history/:id?/",
      component: ChatHistory,
      permissions: [userRole.admin, userRole.callCenterAgent, userRole.ticketAgent],
    },
    {
      path: "/contactcenter/wallboard/",
      component: AdminWallboard,
      permissions: [userRole.admin, userRole.callCenterAgent, userRole.AgentManager],
    },
    {
      path: "/contactcenter/agent-leaderboard/",
      component: AgentLeaderboard,
      permissions: [userRole.admin, userRole.callCenterAgent, userRole.AgentManager],
    },
    {
      path: "/contactcenter/statistics/",
      component: AgentStats,
      componentProps: { scope: "contactcenter" },
      permissions: [userRole.admin, userRole.callCenterAgent, userRole.AgentManager],
    },
    {
      path: "/contactcenter/",
      to: "/contactcenter/livechat/",
      redirect: true,
      permissions: [userRole.admin, userRole.callCenterAgent],
    },
    {
      path: "/contactcenter/",
      to: "/contactcenter/history/",
      redirect: true,
      permissions: [userRole.ticketAgent],
    },
  ];
  return <PRSwitch routes={routes} />;
}

export default ContactCenter;
