import React, { useEffect } from "react";

import * as Sentry from "@sentry/react"; // Sentry'yi import edin
import classNames from "classnames";
import { ErrorBoundary as EB } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import { mapStackTrace } from "sourcemapped-stacktrace";

import { Button } from "@mui/material";

import errorImage from "../../../assets/images/error-img.png";

import "./style.scss";

const errorUrlRegex = /(.*\()(.*)(\).*)/;
let localStorageEnableLog;
try {
  localStorageEnableLog = localStorage.getItem("log");
} catch {}
function ErrorFallback({ error, resetErrorBoundary }) {
  const { t } = useTranslation();
  const [mappedStackTrace, setMappedStackTrace] = React.useState([]);
  const [eventId, setEventId] = React.useState(null);

  useEffect(() => {
    const eventId = Sentry.captureException(error);
    setEventId(eventId?.toUpperCase());

    if (!error.stack || process.env.NODE_ENV === "production") return;

    mapStackTrace(error.stack, function (mappedStack) {
      setMappedStackTrace(mappedStack);
    });
  }, [error]);

  return (
    <React.Fragment>
      <div className="my-5 pt-5">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h1 className="display-3 font-weight-medium">{t("component.errorFallback.oops")}</h1>
                <h4 className="text-uppercase">{t("component.errorFallback.error")}</h4>
                {eventId && (
                  <h4 className="pt-2 text-secondary fw-light">
                    {t("common.id")}: {eventId}
                  </h4>
                )}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="12" xl="9">
              <div className="mt-5 text-center">
                {process.env.NODE_ENV !== "production" || localStorageEnableLog ? (
                  <>
                    <h6 className="">{error.message}</h6>
                    <br />
                    <pre>
                      {mappedStackTrace.map((line, index) => {
                        const match = line.match(errorUrlRegex);
                        const isPrimaryFile = !line?.includes("node_modules") && line?.includes("/src/");
                        if (match?.[2]) {
                          return (
                            <div
                              key={index}
                              className={classNames({
                                "fw-bold": isPrimaryFile,
                                "text-muted": !isPrimaryFile,
                              })}
                            >
                              {match[1]}
                              <a href={`vscode://file/${match[2]}`}>{match?.[2]}</a>
                              {match[3]}
                            </div>
                          );
                        }
                        return (
                          <div
                            key={index}
                            className={classNames({
                              // "text-dark": true,
                              "fw-bold": isPrimaryFile,
                              "text-muted": !isPrimaryFile,
                            })}
                          >
                            {line}
                          </div>
                        );
                      })}
                    </pre>
                    <Button variant="outlined" onClick={resetErrorBoundary}>
                      {t("common.reload")}
                    </Button>
                  </>
                ) : (
                  <div>
                    <img alt="" className="img-fluid" src={errorImage} />
                    <Button onClick={() => window.location.reload(true)}>Reload</Button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

const ErrorBoundary = ({ children }) => <EB FallbackComponent={ErrorFallback}>{children}</EB>;

export default ErrorBoundary;
