import { safeProduce as produce } from "~helpers/immer";

import * as actionTypes from "./actionTypes";

export const initState = {
  /** @type {import("@mui/styles").ThemeProviderProps} */
  muiTheme: {
    typography: {
      fontFamily: '"Museo Sans", "Open Sans","Roboto","Helvetica","Arial",sans-serif',
    },
    palette: {
      primary: {
        light: "#50C8F4",
        main: "#28AAE1",
        dark: "#04133A",
        contrastText: "#fff",
      },
      secondary: {
        light: "#DDDDDD",
        main: "#838383",
        dark: "#535353",
      },
      error: {
        main: "#D32A5E",
      },
      teal: {
        main: "#20c997",
      },
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color: "#535353",
          },
          notchedOutline: {
            borderColor: "#DDDDDD",
            borderWidth: "1px !important",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "#838383",
          },
          // shrink: {
          //   color: "#53F353",
          // },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow: "2px 2px 12px #00000014",
            border: "1px solid #F8F8F8",
            borderRadius: "8px",
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#DDDDDD",
            borderRadius: 4,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            backgroundColor: "#FCFCFC",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
          outlined: {
            backgroundColor: "#fff",
          },
        },
      },
    },
  },
  muiThemeWebchat: {
    typography: {
      fontFamily: '"Museo Sans", "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    palette: {
      primary: {
        // light: "#50C8F4",
        main: "#28AAE1",
        // dark: "#04133A",
        contrastText: "#fff",
      },
      secondary: {
        light: "#DDDDDD",
        main: "#838383",
        dark: "#535353",
      },
      error: {
        main: "#D32A5E",
      },
    },

    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            "&::placeholder": {
              color: "#838383",
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            borderRadius: 14,
            boxShadow: "2px 2px 24px 0 rgba(0, 0, 0, 0.24)",
            border: "solid 1px #f8f8f8",
          },
        },
      },
    },
    // overrides: {
    // MuiIconButton: {
    //   root: {
    //     padding: 5,
    //     borderRadius: "5px !important",
    //     "&:hover": {
    //       borderRadius: 5,
    //       backgroundColor: "rgba(0, 0, 0, 0.3)",
    //     },
    //   },
    // },
    // },
  },
};

const theme = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.SET_THEME:
        draft.muiTheme = action.payload;
        break;
      case actionTypes.SET_WEBCHAT_THEME:
        draft.muiThemeWebchat = action.payload;
        break;
    }
  });

export default theme;
