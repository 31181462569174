import { apiUrlNotification } from "~constants";
import Network from "~helpers/Network";

import * as at from "./actionTypes";

export const requestPushNotification = () => ({
  type: at.REQUEST_PUSH_NOTIFICATION,
});

export const clearPushNotification = () => ({
  type: at.CLEAR_PUSH_NOTIFICATION,
});

export const setNotifications = (payload) => ({
  type: at.SET_NOTIFICATIONS,
  payload,
});

// ------------- API -------------

export const getNotifications = (params) => (_dispatch) => {
  return Network.request(apiUrlNotification.get, {
    // loading: true,
    params,
    onSuccess: setNotifications,
  });
};

export const markAsReadNotifications =
  (idList = []) =>
  (_dispatch) => {
    return Network.request(apiUrlNotification.get, {
      method: "POST",
      loading: true,
      params: {
        seen: idList.join(","),
      },
    });
  };
