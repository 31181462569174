import { useEffect, useMemo, useState } from "react";

import { useFormik } from "formik";
import { isValidNumber } from "libphonenumber-js";
import { cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { ReactComponent as TelegramIcon } from "~assets/images/icons/telegram.svg";
import { ReactComponent as WhatsAppIcon } from "~assets/images/icons/whatsapp.svg";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRInput from "~components/Generic/PRInput";
import PRPage from "~components/Generic/PRPage";
import PRTab from "~components/Generic/PRTab";
import PalPhoneNumber from "~components/mui/PalPhoneNumber";
import { createOrUpdateChannel, getChannelList } from "~store/settings/channels/actions";
import { selectChannelList } from "~store/settings/channels/selectors";
import { selectCurrentProject } from "~store/user/selectors";

import "./style.scss";

const addPlusPrefix = (value) => (value ? (value.startsWith("+") ? value : `+${value}`) : value);

export default function Channels() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const [tab, setTab] = useState("whatsapp");
  const [whatsappCountry, setWhatsappCountry] = useState("TR");
  const [whatsappAccessTokenFirstClick, setWhatsappAccessTokenFirstClick] = useState(false);
  const [whatsappSecretFirstClick, setWhatsappSecretFirstClick] = useState(false);
  const [telegramAccessTokenFirstClick, setTelegramAccessTokenFirstClick] = useState(false);
  const [telegramSecretFirstClick, setTelegramSecretFirstClick] = useState(false);
  const channelList = useSelector(selectChannelList);

  useEffect(() => {
    dispatch(getChannelList(currentProject.id));
  }, [dispatch, currentProject]);

  const { whatsappData, telegramData } = useMemo(() => {
    const whatsappData = channelList.find((item) => item.integration_type === "whatsapp");
    const telegramData = channelList.find((item) => item.integration_type === "telegram");
    return { whatsappData, telegramData };
  }, [channelList]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      whatsapp: {
        ...whatsappData,
        // secret: whatsappData?.secret || "",
        name: whatsappData?.name || "",
        integration_type: "whatsapp",
        number: addPlusPrefix(whatsappData?.number) || "",
      },
      telegram: {
        ...telegramData,
        // access_token: telegramData?.access_token || "",
        name: telegramData?.name || "",
        integration_type: "telegram",
      },
    },
    validationSchema: Yup.object()
      .when({
        is: () => tab === "whatsapp",
        then: Yup.object()
          .nullable()
          .shape({
            whatsapp: Yup.object()
              .nullable()
              .shape({
                // secret: Yup.string().required("Required"),
                name: Yup.string()
                  .nullable()
                  .required(t("component.formik.required").format(t("common.name"))),
                number: Yup.string()
                  .nullable()
                  .required(t("component.formik.required").format(t("common.phoneNumber")))
                  .test("phone", t("chatbot.getInformations.invalidPhoneNumber"), (value) => {
                    if (!value) return true;
                    let isValid = false;
                    try {
                      isValid = isValidNumber(value, whatsappCountry);
                    } catch (e) {}
                    return isValid;
                  }),
              }),
          }),
      })
      .when({
        is: () => tab === "telegram",
        then: Yup.object()
          .nullable()
          .shape({
            telegram: Yup.object()
              .nullable()
              .shape({
                // access_token: Yup.string().required("Required"),
                name: Yup.string()
                  .nullable()
                  .required(t("component.formik.required").format(t("common.name"))),
              }),
          }),
      }),
    onSubmit: (values) => {
      const payload = cloneDeep(values);
      const targetData = payload[tab];
      if (tab === "whatsapp" && targetData.access_token === whatsappData.access_token) {
        delete targetData.access_token;
      }
      if (tab === "whatsapp" && targetData.secret === whatsappData.secret) {
        delete targetData.secret;
      }
      if (tab === "telegram" && targetData.access_token === telegramData.access_token) {
        delete targetData.access_token;
      }
      if (tab === "telegram" && targetData.secret === telegramData.secret) {
        delete targetData.secret;
      }

      if (targetData.number && targetData.number.startsWith("+")) {
        targetData.number = targetData.number.slice(1);
      }
      dispatch(createOrUpdateChannel(currentProject.id, targetData));
    },
  });

  const handleChangeWhatsappNumber = (data, { numberValue, countryCode }) => {
    formik.setFieldValue("whatsapp.number", numberValue);

    setWhatsappCountry(countryCode);
  };

  const handleWhatsappAccessTokenClick = () => {
    if (!whatsappAccessTokenFirstClick) {
      formik.setFieldValue("whatsapp.access_token", "");
      setWhatsappAccessTokenFirstClick(true);
    }
  };

  const handleWhatsappSecretClick = () => {
    if (!whatsappSecretFirstClick) {
      formik.setFieldValue("whatsapp.secret", "");
      setWhatsappSecretFirstClick(true);
    }
  };

  const handleTelegramAccessTokenClick = () => {
    if (!telegramAccessTokenFirstClick) {
      formik.setFieldValue("telegram.access_token", "");
      setTelegramAccessTokenFirstClick(true);
    }
  };

  const handleTelegramSecretClick = () => {
    if (!telegramSecretFirstClick) {
      formik.setFieldValue("telegram.secret", "");
      setTelegramSecretFirstClick(true);
    }
  };

  return (
    <PRContainer
      className="pr-channels"
      description={t("dashboard.channels.description")}
      name={t("common.settings")}
      parentName={t("dashboard.channels")}
    >
      <PRPage>
        <PRTab
          tab={tab}
          tabList={[
            {
              id: "whatsapp",
              label: (
                <div className="d-flex align-items-center lh-1">
                  <span className="me-1 fs-2">
                    <WhatsAppIcon height="1em" width="1em" />
                  </span>
                  {t("common.whatsapp")}
                </div>
              ),
            },
            {
              id: "telegram",
              label: (
                <div className="d-flex align-items-center lh-1">
                  <span className="me-1 fs-3">
                    <TelegramIcon height="1em" width="1em" />
                  </span>
                  {t("common.telegram")}
                </div>
              ),
            },
          ]}
          onChange={setTab}
        />
        <Row className="g-2 mt-2 w-100">
          {tab === "whatsapp" && (
            <>
              <Col lg={6} md={12}>
                <Label>{t("common.name")}</Label>
                <PRInput
                  invalid={formik.touched.whatsapp?.name && formik.errors.whatsapp?.name}
                  name="whatsapp.name"
                  value={formik.values.whatsapp.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col lg={6} md={12}>
                <Label>{t("dashboard.channels.token")}</Label>
                <PRInput
                  invalid={formik.touched.whatsapp?.access_token && formik.errors.whatsapp?.access_token}
                  name="whatsapp.access_token"
                  placeholder="Access Token"
                  value={formik.values.whatsapp.access_token}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  onClick={handleWhatsappAccessTokenClick}
                />
              </Col>
              <Col lg={6} md={12}>
                <Label>{t("dashboard.channels.appId")}</Label>
                <PRInput
                  invalid={formik.touched.whatsapp?.app_id && formik.errors.whatsapp?.app_id}
                  name="whatsapp.app_id"
                  placeholder={t("dashboard.channels.appId")}
                  value={formik.values.whatsapp.app_id}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col lg={6} md={12}>
                <Label>{t("dashboard.channels.secret")}</Label>
                <PRInput
                  invalid={formik.touched.whatsapp?.secret && formik.errors.whatsapp?.secret}
                  name="whatsapp.secret"
                  placeholder={t("dashboard.channels.secret")}
                  value={formik.values.whatsapp.secret}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  onClick={handleWhatsappSecretClick}
                />
              </Col>
              <Col lg={6} md={12}>
                <Label>{t("common.phoneNumber")}</Label>
                <PalPhoneNumber
                  fullWidth
                  reactstrapMode
                  invalid={formik.touched.whatsapp?.number && formik.errors.whatsapp?.number}
                  name="whatsapp.number"
                  value={formik.values.whatsapp.number}
                  onBlur={formik.handleBlur}
                  onChange={handleChangeWhatsappNumber}
                />
              </Col>
            </>
          )}
          {tab === "telegram" && (
            <>
              <Col lg={6} md={12}>
                <Label>{t("common.name")}</Label>
                <PRInput
                  invalid={formik.touched.telegram?.name && formik.errors.telegram?.name}
                  name="telegram.name"
                  value={formik.values.telegram.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col lg={6} md={12}>
                <Label>{t("dashboard.channels.token")}</Label>
                <PRInput
                  invalid={formik.touched.telegram?.access_token && formik.errors.telegram?.access_token}
                  name="telegram.access_token"
                  placeholder="Access Token"
                  value={formik.values.telegram.access_token}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  onClick={handleTelegramAccessTokenClick}
                />
              </Col>
              <Col lg={6} md={12}>
                <Label>{t("dashboard.channels.appId")}</Label>
                <PRInput
                  invalid={formik.touched.telegram?.app_id && formik.errors.telegram?.app_id}
                  name="telegram.app_id"
                  placeholder={t("dashboard.channels.appId")}
                  value={formik.values.telegram.app_id}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col lg={6} md={12}>
                <Label>{t("dashboard.channels.secret")}</Label>
                <PRInput
                  invalid={formik.touched.telegram?.secret && formik.errors.telegram?.secret}
                  name="telegram.secret"
                  placeholder={t("dashboard.channels.secret")}
                  value={formik.values.telegram.secret}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  onClick={handleTelegramSecretClick}
                />
              </Col>
            </>
          )}
        </Row>

        <Row>
          <Col className="d-flex justify-content-end pt-2" xs={12}>
            <PRButton className="w-md" onClick={formik.handleSubmit}>
              {t("common.save")}
            </PRButton>
          </Col>
        </Row>
      </PRPage>
    </PRContainer>
  );
}
