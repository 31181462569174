import { safeProduce as produce } from "~helpers/immer";

export const initState = Object.freeze({});

const intentRecords = (state = initState, action) =>
  produce(state, (draft) => {
    switch (
      action.type
      // case at.SET_INTENT_TAG:
      //   draft.intentTag = action.payload;
      //   break;
    ) {
    }
  });

export default intentRecords;
