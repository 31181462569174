import { useEffect, useRef } from "react";

export const useDebouncedEffect = (effect, deps, delay) => {
  const disposeRef = useRef(null);
  useEffect(() => {
    const handler = setTimeout(() => {
      if (disposeRef.current) {
        disposeRef.current();
      }
      disposeRef.current = effect();
    }, delay);

    return () => {
      if (disposeRef.current) {
        disposeRef.current();
      }
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay]);
};
