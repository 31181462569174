import PRSwitch from "~components/Generic/PRSwitch";

import Ticket from "./Ticket";
import TicketCategory from "./TicketCategory";

function HelpDesk() {
  const routes = [
    { path: "/helpdesk/ticket-category/", component: TicketCategory },
    { path: "/helpdesk/ticket/", component: Ticket },
    { path: "/helpdesk/", to: "/helpdesk/ticket/", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}

export default HelpDesk;
