import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import SanitizeHelper from "~helpers/SanitizeHelper";
const getProxy = (search) => {
  const params = new URLSearchParams(search);
  return new Proxy(params, {
    get(target, prop) {
      if (typeof prop === "symbol") {
        return undefined;
      }
      const data = target.get(prop);

      return SanitizeHelper.safeText(data);
    },
  });
};
export default function useQueryParams() {
  let search;
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const location = useLocation();
    search = location.search;
  } catch (error) {
    search = window.location.search;
  }
  const [proxy, setProxy] = useState(getProxy(search));

  useEffect(() => {
    setProxy(getProxy(search));
  }, [search]);
  return proxy;
}
