import Network from "~helpers/Network";

async function updateMessage(projectId, chatbot, body, messageId) {
  return await Network.request(`project/${projectId}/chatbot/${chatbot}/bot-message/${messageId}/`, {
    method: "PATCH",
    data: body,
  });
}

export { updateMessage };
