import i18next from "i18next";

import { alertShow } from "~store/helpers/alert/actions";
import { selectAlertList } from "~store/helpers/alert/selectors";

import store from "../store";
export default class AlertHelper {
  static keyCounter = 1;
  static showAlert(message, type, options = {}) {
    const key = `alert-${this.keyCounter++}`;
    store.dispatch(alertShow({ type, message, key, ...options, dispatched: false }));
    return key;
  }
  /**
   * @param {"default" | "error" | "success" | "warning" | "info"} type Alert type
   * @param {string} message The message to show
   * @param {import("notistack").OptionsObject} options Optional. See notistack documentation for more details
   * @returns {Promise}
   */
  static show(message, type = "success", options = {}) {
    const defaultColor = "#28aae1dd";
    const color =
      {
        default: "black",
        error: "red",
        success: "green",
        warning: "orange",
        info: "blue",
      }?.[type] || defaultColor;

    console.log(`[AlertHelper] %c[${type}] %c${message}`, `color:${color}`, `color:${defaultColor}`);
    const key = options?.key;
    const alertList = (selectAlertList(store.getState()) || [])
      .filter((alert) => alert?.type !== "close")
      .filter((alert) => alert?.key === key);
    if (key && alertList.length) {
      return Promise.resolve();
    }
    return new Promise((resolve) => {
      AlertHelper.showAlert(message, type, {
        ...options,
        onClose: () => {
          options?.onClose?.();
          resolve();
        },
      });
    });
  }
  /**
   * @param {any} message
   * @param {import("notistack").OptionsObject} options
   */
  static showError(message = null, options = {}) {
    AlertHelper.show(message || i18next.t("network.serverError"), "error", options);
  }
  /**
   * @param {any} message
   * @param {import("notistack").OptionsObject} options
   */
  static showSuccess(message = null, options = {}) {
    AlertHelper.show(message || i18next.t("network.success"), "success", options);
  }
  /**
   * @param {any} message
   * @param {import("notistack").OptionsObject} options
   */
  static showWarning(message = null, options = {}) {
    AlertHelper.show(message || i18next.t("network.serverError"), "warning", options);
  }
  /**
   * @param {string} key The key of the alert to close
   * @returns True if alert is closed, false if alert is not found
   */
  static close(key = null) {
    let result = false;
    if (key) {
      const alertList = selectAlertList(store.getState()) || [];
      const alert = alertList.find((alert) => alert?.key === key);
      if (alert) {
        result = true;
      }
    }
    store.dispatch(alertShow({ type: "close", key, dispatched: false }));
    return result;
  }
}
