import { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { MdSettings } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import useFormikEx from "~common/hooks/useFormikEx";
import useLoading from "~common/hooks/useLoading";
import useProjectChange from "~common/hooks/useProjectChange";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRInput from "~components/Generic/PRInput";
import PRPage from "~components/Generic/PRPage";
import HistoryHelper from "~helpers/HistoryHelper";
import { getProcedureList } from "~store/dialogComponents/scenarioManager/actions";
import { createOrUpdateModule, getModule, setModule } from "~store/integration/module/actions";
import { selectModule } from "~store/integration/module/selectors";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

const ModuleEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const module = useSelector(selectModule);
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const [loading, q] = useLoading();

  useEffect(() => {
    if (!id) return;
    q(dispatch(getModule(currentProject.id, id)));

    return () => {
      dispatch(setModule(null));
    };
  }, [currentProject.id, dispatch, id, q]);

  useEffect(() => {
    q(dispatch(getProcedureList(currentProject.id, currentBot.id)));
  }, [currentProject.id, dispatch, currentBot.id, q]);

  const formik = useFormikEx({
    enableReinitialize: true,
    initialValues: {
      ...module,
      machine_name: module?.machine_name || "",
      display_name: module?.display_name || "",
      description: module?.description || "",
      versions: module?.versions || [],
    },
    validationSchema: Yup.object({
      machine_name: Yup.string().required(t("component.formik.required").format(t("common.name"))),
      display_name: Yup.string().required(t("component.formik.required").format(t("common.displayName"))),
      description: Yup.string().required(t("component.formik.required").format(t("common.description"))),
    }),
    onSubmit: async (values, f, e) => {
      await dispatch(createOrUpdateModule(currentProject.id, values));
      if (!e?.shiftKey) {
        HistoryHelper.goBack("/integration/module/", { scope: "dashboard" });
      }
    },
  });
  useProjectChange(() => {
    HistoryHelper.push("/integration/module/", { scope: "dashboard" });
  }, []);

  const parentName = useMemo(
    () => [
      {
        label: t("common.integration"),
        url: "/integration/module",
      },
      {
        label: id ? t("dashboard.moduleEdit.editModule") : t("dashboard.moduleEdit.createModule"),
      },
    ],
    [id, t]
  );

  const handleClickCancel = () => {
    HistoryHelper.goBack("/integration/module/", { scope: "dashboard" });
  };
  return (
    <PRContainer loading={loading} name={t("common.integration")} parentName={parentName}>
      <PRPage
        actions={[
          id && {
            label: t("dashboard.module.manageVersions"),
            icon: MdSettings,
            onClick: () => {
              HistoryHelper.push(`/integration/module/version/${id}/`, { scope: "dashboard" });
            },
          },
        ].filter(Boolean)}
      >
        <Row className="g-0 gy-2">
          <Col xs="12">
            <Label className="">{t("common.name")}</Label>
            <PRInput
              invalid={formik.touched.machine_name && formik.errors.machine_name}
              name="machine_name"
              placeholder={t("common.namePlaceholder")}
              value={formik.values.machine_name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
          <Col xs="12">
            <Label className="">{t("common.displayName")}</Label>
            <PRInput
              invalid={formik.touched.display_name && formik.errors.display_name}
              name="display_name"
              placeholder={t("dashboard.moduleEdit.displayNamePlaceholder")}
              value={formik.values.display_name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
          <Col xs="12">
            <Label className="">{t("common.description")}</Label>
            <PRInput
              invalid={formik.touched.description && formik.errors.description}
              name="description"
              placeholder={t("dashboard.moduleVersions.descriptionPlaceholder")}
              rows="5"
              type="textarea"
              value={formik.values.description}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
        </Row>
        <Row className="justify-content-end mt-2">
          <Col xs="auto">
            <PRButton outline className="me-2" onClick={handleClickCancel}>
              {t("common.cancel")}
            </PRButton>
            {/* <PRButton onClick={formik.handleSubmit} className="me-2">
              Manage Versions
            </PRButton> */}
            <PRButton onClick={formik.handleSubmit}>{id ? t("common.update") : t("common.create")}</PRButton>
          </Col>
        </Row>
      </PRPage>
    </PRContainer>
  );
};

export default ModuleEdit;
