import { useEffect, useMemo } from "react";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { MdAdd, MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRDate from "~components/Generic/PRDate";
import PRDivider from "~components/Generic/PRDivider";
import PRDragHandle from "~components/Generic/PRDragHandle";
import PRInput from "~components/Generic/PRInput";
import PRPage from "~components/Generic/PRPage";
import PRSelect from "~components/Generic/PRSelect";
import { organizationQuestionType, organizationQuestionTypeOptions, organizationSurveyTypeOptions } from "~constants";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import Utils from "~helpers/Utils";
import { createOrUpdateSurvey, getFilterList, getSurvey, setSurvey } from "~store/organization/actions";
import { selectSurvey } from "~store/organization/selectors";
import { selectCurrentProject } from "~store/user/selectors";

import "./style.scss";

function SurveyItem({ order, survey, onChange, onDelete }) {
  const { t } = useTranslation();
  const handleChangeSelect = (key) => (obj) => {
    const data = { ...survey, [key]: obj.value };
    if (key === "question_type" && obj.value === organizationQuestionType.CHOICE && !survey.options?.length) {
      data.options = ["", ""];
    } else {
      data.options = [];
    }
    onChange(data);
  };
  const handleChange = (key) => (e) => {
    let value = e.target.value;
    onChange({
      ...survey,
      [key]: value,
    });
  };
  const handleChangeInterval2 = (e) => {
    let value = e.target.value;
    onChange({
      ...survey,
      interval: [survey.interval?.[0] ?? 0, value],
    });
  };
  const handleChangeInterval1 = (e) => {
    let value = e.target.value;
    onChange({
      ...survey,
      interval: [value, survey.interval?.[1] ?? 5],
    });
  };
  const selectValue = useMemo(
    () => organizationQuestionTypeOptions.find((option) => option.value === survey.question_type),
    [survey.question_type]
  );

  const handleAddSurveyChoice = () => {
    onChange({
      ...survey,
      options: [...(survey?.options || []), ""],
    });
  };

  const handleChangeSurveyChoice = (index) => (e) => {
    const value = e.target.value;
    onChange({
      ...survey,
      options: survey.options.map((option, i) => (i === index ? value : option)),
    });
  };

  const handleDeleteSurveyChoice = (index) => () => {
    onChange({
      ...survey,
      options: survey.options.filter((_, i) => i !== index),
    });
  };

  const handleDeleteSurveyItem = () => {
    onDelete();
  };

  return (
    <div className="survey-item">
      <PRDragHandle />
      <Row className="g-2 align-items-end">
        <Col md>
          <Label> {t("common.question")} </Label>
          <PRInput
            name={`question`}
            placeholder={t("dashboard.surveyEdit.questionPlaceholder")}
            value={survey.question}
            onChange={handleChange("question")}
          />
        </Col>
        <Col md>
          <Label> {t("dashboard.surveyEdit.questionType")} </Label>
          <PRSelect
            isClearable={false}
            name={`survey_items[${order}].question_type`}
            options={organizationQuestionTypeOptions}
            placeholder={t("dashboard.surveyEdit.questionTypePlaceholder")}
            value={selectValue}
            onChange={handleChangeSelect("question_type")}
          />
        </Col>
        <Col md="auto">
          <PRButton outline color="danger" icon={MdDelete} onClick={handleDeleteSurveyItem} />
        </Col>
      </Row>

      <Row className="g-0 ">
        {survey.question_type === organizationQuestionType.CHOICE && (
          <>
            <PRDivider className={"my-2"} />
            <span className="text-secondary mb-1">{t("common.options")}</span>
            <Col lg={"12"} md="12">
              <Row className="gx-2 gy-1">
                {survey.options?.map((choice, index) => (
                  <Row key={index} className="gx-2 gy-1">
                    <Col key={index} xs>
                      <PRInput
                        placeholder={t("common.choice")}
                        size="sm"
                        value={choice}
                        onChange={handleChangeSurveyChoice(index)}
                      />
                    </Col>
                    <Col xs="auto">
                      <PRButton
                        outline
                        color="danger"
                        icon={MdDelete}
                        size="sm"
                        onClick={handleDeleteSurveyChoice(index)}
                      />
                    </Col>
                  </Row>
                ))}
                <Col className="d-flex justify-content-end" xs="12">
                  <PRButton className="me-2" color="success" icon={MdAdd} size="sm" onClick={handleAddSurveyChoice} />
                </Col>
              </Row>
            </Col>
          </>
        )}
        {survey.question_type === organizationQuestionType.RANGE && (
          <>
            <PRDivider className={"my-2"} />
            <Row className="g-0 gap-2 align-items-center">
              <Col xs>
                <Label> {t("component.surveyItem.rangeStart")} </Label>
                <PRInput
                  placeholder="Start"
                  type="number"
                  value={survey.interval?.[0]}
                  onChange={handleChangeInterval1}
                />
              </Col>
              <Col xs>
                <Label> {t("component.surveyItem.rangeEnd")} </Label>
                <PRInput
                  placeholder="End"
                  type="number"
                  value={survey.interval?.[1]}
                  onChange={handleChangeInterval2}
                />
              </Col>

              <Col md="auto">
                <div className="opacity-0" style={{ pointerEvents: "none" }}>
                  <PRButton outline color="danger" icon={MdDelete} />
                </div>
              </Col>
            </Row>
          </>
        )}
      </Row>
    </div>
  );
}

const SortableSurveyItem = SortableElement(SurveyItem);
const SortableSurveyItemList = SortableContainer(({ items, handleChangeSurvey, handleDeleteSurvey }) => {
  return (
    <Row className="g-0">
      {items.map((item, index) => {
        return (
          <Col key={index} className="mb-2" xs="12">
            <SortableSurveyItem
              key={index}
              index={index}
              order={index}
              survey={item}
              onChange={handleChangeSurvey(item)}
              onDelete={handleDeleteSurvey(item)}
            />
          </Col>
        );
      })}
    </Row>
  );
});

const initialValues = {
  name: "",
  entry_message: "",
  exit_message: "",
  due_time: "",
  survey_type: "",
  survey_items: [
    {
      question: "",
      question_type: organizationQuestionType.TEXT,
    },
  ],
};

export default function SurveyEdit() {
  const { t } = useTranslation();
  const { id } = useParams();
  // const [data, setData] = useState();
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const survey = useSelector(selectSurvey);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      ...survey,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("component.formik.required").format(t("common.name"))),
      entry_message: Yup.string().required(t("component.formik.required").format(t("dashboard.surveys.entryMessage"))),
      exit_message: Yup.string().required(t("component.formik.required").format(t("dashboard.surveys.exitMessage"))),
      due_time: Yup.date()
        .required(t("component.formik.required").format(t("dashboard.surveys.dueTime")))
        .test("must-be-future", t("dashboard.surveys.mutBeFutureError"), (value) => {
          return DateHelper.getDateTime(value).isAfter(DateHelper.getDateTime());
        }),

      survey_type: Yup.string().required(t("component.formik.required").format(t("dashboard.surveys.surveyType"))),
    }),
    onSubmit: async (values) => {
      const postData = {
        ...values,
        due_time: DateHelper.format(values.due_time),
      };

      let errors = [];

      for (const surveyItem of values.survey_items) {
        if (!surveyItem.question) {
          errors.push(t("dashboard.surveys.errors.fillQuestion"));
        }

        if (surveyItem.question_type === organizationQuestionType.CHOICE) {
          if (!surveyItem.options?.length || surveyItem.options?.length < 2) {
            errors.push(t("dashboard.surveys.errors.minChoices"));
          } else if (surveyItem.options.filter(Boolean)?.length < 2) {
            errors.push(t("dashboard.surveys.errors.fillChoices"));
          }
        } else if (surveyItem.question_type === organizationQuestionType.RANGE) {
          if (!surveyItem.interval || surveyItem.interval.length !== 2) {
            errors.push(t("dashboard.surveys.errors.fillRange"));
          } else if (surveyItem.interval[0] === surveyItem.interval[1]) {
            errors.push(t("dashboard.surveys.errors.rangeSame"));
          } else if (Number(surveyItem.interval[0]) > Number(surveyItem.interval[1])) {
            errors.push(t("dashboard.surveys.errors.rangeInvalid"));
          } else if (!(surveyItem.interval[0] > -1)) {
            errors.push(t("dashboard.surveys.errors.startRangeZero"));
          } else if (!(surveyItem.interval[1] > -1)) {
            errors.push(t("dashboard.surveys.errors.endRangeZero"));
          }
        }
      }
      if (errors.length) {
        DialogHelper.showValidate(errors);
        return;
      }

      await dispatch(createOrUpdateSurvey(postData, currentProject.id));
      HistoryHelper.goBack("/organization/surveys", { scope: "dashboard" });
    },
  });

  useEffect(() => {
    dispatch(getFilterList(currentProject.id));
  }, [dispatch, currentProject.id]);

  useEffect(() => {
    if (id) {
      dispatch(getSurvey(id, currentProject.id));
    }
    return () => {
      dispatch(setSurvey({ ...initialValues }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, currentProject.id]);

  const onChangeSelect = (key) => (obj) => {
    let value = obj;
    if (key === "action_type") {
      value = obj?.value;
    } else if (key === "action_filter") {
      value = { id: obj?.value };
    } else if (key === "survey_type") {
      value = obj?.value;
    }
    formik.setFieldValue(key, value);
  };

  const handleClickCancel = () => {
    HistoryHelper.goBack("/organization/surveys", { scope: "dashboard" });
  };
  const handleChangeDate = (date) => {
    formik.setFieldValue("due_time", DateHelper.format(date));
  };

  const handleAddQuestion = () => {
    formik.setFieldValue("survey_items", [
      ...formik.values.survey_items,
      {
        question: "",
        question_type: organizationQuestionType.TEXT,
        options: organizationQuestionType.TEXT === formik.values.survey_type ? ["", ""] : [],
        interval: [0, 0],
      },
    ]);
  };

  const surveyTypeValue = useMemo(() => {
    return organizationSurveyTypeOptions.find((item) => item.value === formik.values.survey_type);
  }, [formik.values.survey_type]);

  const handleChangeSurvey = (curr) => (newObj) => {
    let newSurveyItems = [...formik.values.survey_items];
    newSurveyItems = newSurveyItems.map((item) => {
      if (item === curr) {
        return newObj;
      }
      return item;
    });

    formik.setFieldValue("survey_items", newSurveyItems);
  };

  const handleDeleteSurvey = (curr) => () => {
    let newSurveyItems = [...formik.values.survey_items];
    newSurveyItems = newSurveyItems.filter((item) => item !== curr);
    formik.setFieldValue("survey_items", newSurveyItems);
  };

  const handleOnSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = Utils.arrayMove([...formik.values.survey_items], oldIndex, newIndex);

    formik.setFieldValue(
      "survey_items",
      newItems.map((item, index) => ({ ...item, order: index + 1 }))
    );
  };

  const parentName = [
    {
      label: t("dashboard.surveys"),
      url: "/organization/surveys",
    },
    {
      label: id ? t("dashboard.surveys.editSurvey") : t("dashboard.surveys.createSurvey"),
    },
  ];
  return (
    <PRContainer className="pr-survey-edit" name={t("common.organization")} parentName={parentName}>
      <PRPage className="" title={id ? t("dashboard.surveys.editSurvey") : t("dashboard.surveys.createSurvey")}>
        <Row className="mt-2 g-2">
          <Col lg="12" md="12">
            <Label className="mb-2">{t("common.name")}</Label>
            <PRInput
              invalid={formik.touched.name && formik.errors.name}
              name="name"
              type="text"
              value={formik.values.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>

          <Col lg="6" md="12">
            <Label className="mb-2">{t("dashboard.surveys.entryMessage")}</Label>
            <PRInput
              invalid={formik.touched.entry_message && formik.errors.entry_message}
              name="entry_message"
              type="text"
              value={formik.values.entry_message}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>

          <Col lg="6" md="12">
            <Label className="mb-2">{t("dashboard.surveys.exitMessage")}</Label>
            <PRInput
              invalid={formik.touched.exit_message && formik.errors.exit_message}
              name="exit_message"
              type="text"
              value={formik.values.exit_message}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>

          <Col lg="6" md="12">
            <Label className="mb-2">{t("dashboard.surveys.dueTime")}</Label>
            <PRDate
              invalid={formik.touched.due_time && formik.errors.due_time}
              placeholder={t("dashboard.surveys.dueTime")}
              timeFormat={false}
              value={formik.values.due_time}
              onChange={handleChangeDate}
            />
          </Col>
          <Col lg="6" md="12">
            <Label className="mb-2">{t("dashboard.surveys.surveyType")}</Label>
            <PRSelect
              invalid={formik.touched.survey_type && formik.errors.survey_type}
              options={organizationSurveyTypeOptions}
              placeholder={t("dashboard.surveys.surveyTypePlaceholder")}
              value={surveyTypeValue}
              onChange={onChangeSelect("survey_type")}
            />
          </Col>

          <Col lg="12" md="12">
            <Label className="mb-2">
              {t("dashboard.surveys.surveyItems").format(formik.values.survey_items.length.toString())}
            </Label>
            <SortableSurveyItemList
              useDragHandle
              handleChangeSurvey={handleChangeSurvey}
              handleDeleteSurvey={handleDeleteSurvey}
              helperClass="sortable-helper"
              items={formik.values.survey_items}
              onSortEnd={handleOnSortEnd}
            />
            <Row className="justify-content-end ">
              <Col md="auto">
                <PRButton className="ms-2" color="success" icon={MdAdd} size="sm" onClick={handleAddQuestion}>
                  {t("dashboard.surveys.addSurveyItem")}
                </PRButton>
              </Col>
            </Row>
          </Col>
        </Row>
        <PRDivider size="lg" />
        <Row className="justify-content-end mt-2">
          <Col md="auto">
            <PRButton outline className="" onClick={handleClickCancel}>
              {t("common.cancel")}
            </PRButton>
            <PRButton className="ms-2" onClick={formik.handleSubmit}>
              {t("common.save")}
            </PRButton>
          </Col>
        </Row>
      </PRPage>
    </PRContainer>
  );
}
