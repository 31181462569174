import { useCallback, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";
import { FaFileExcel } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Col, Label, Row } from "reactstrap";

import { Box } from "@mui/material";

import useLoading from "~common/hooks/useLoading";
import useScrollDirection from "~common/hooks/useScrollDirection";
import { PRButtonGroup } from "~components/Generic/PRButton";
import PRChart from "~components/Generic/PRChart";
import PRContainer from "~components/Generic/PRContainer";
import PRDate from "~components/Generic/PRDate";
import PRPage from "~components/Generic/PRPage";
import PRSelect from "~components/Generic/PRSelect";
import PRTab from "~components/Generic/PRTab";
import PalIconButton from "~components/mui/PalIconButton";
import PalTooltip from "~components/mui/PalTooltip";
import { statisticType, userRole } from "~constants";
import DateHelper from "~helpers/DateHelper";
import FileHelper from "~helpers/FileHelper";
import { getPermissionUserList } from "~store/settings/permission/actions";
import { selectPermissionUserList } from "~store/settings/permission/selectors";
import { downloadStatistics, getGenericStatistics } from "~store/statistics/actions";
import { selectGenericStatisticsByTypeList } from "~store/statistics/selectors";
import { selectCurrentProject } from "~store/user/selectors";

import "../style.scss";

const statisticTypeList = [
  statisticType.AGENT_SESSION_RATE_AVERAGE,
  statisticType.AGENT_SESSION_RATE_MEDIAN,
  statisticType.AGENT_SESSION_RATE_MAX,
  statisticType.AGENT_SESSION_RATE_MIN,

  // statisticType.AGENT_CHAT_TIME_AVERAGE,
  // statisticType.AGENT_CHAT_TIME_MEDIAN,
  // statisticType.AGENT_CHAT_TIME_MAX,
  // statisticType.AGENT_CHAT_TIME_MIN,

  // statisticType.AGENT_RESPONSE_TIME_AVERAGE,
  // statisticType.AGENT_RESPONSE_TIME_MEDIAN,
  // statisticType.AGENT_RESPONSE_TIME_MAX,
  // statisticType.AGENT_RESPONSE_TIME_MIN,

  statisticType.CALLCENTER_QUEUE_TIME_AVERAGE,
  statisticType.CALLCENTER_QUEUE_TIME_MEDIAN,
  statisticType.CALLCENTER_QUEUE_TIME_MAX,
  statisticType.CALLCENTER_QUEUE_TIME_MIN,
  statisticType.AGENT_SESSION_COUNT,

  statisticType.AGENT_TOTAL_CHAT_TIME,
  statisticType.TOTAL_AGENT_MESSAGE_COUNT,
  statisticType.TOTAL_AGENT_LIKE_COUNT,
  statisticType.TOTAL_AGENT_DISLIKE_COUNT,
  statisticType.TOTAL_AGENT_SESSION_RATE_AVERAGE,

  // statisticType.AGENT_TICKET_RESOLVE_TIME_AVERAGE,
  // statisticType.AGENT_TICKET_RESOLVE_TIME_MEDIAN,
  // statisticType.AGENT_TICKET_RESOLVE_TIME_MAX,
  // statisticType.AGENT_TICKET_RESOLVE_TIME_MIN,

  statisticType.AGENT_MESSAGE_COUNT,
  statisticType.AGENT_LIKE_COUNT,
  statisticType.AGENT_DISLIKE_COUNT,
  statisticType.AGENT_AVAILABLE_TIME,
];

export default function AgentStats({ scope }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentProject = useSelector(selectCurrentProject);
  const genericStatistics = useSelector((state) => selectGenericStatisticsByTypeList(state, statisticTypeList));
  const permissionUserList = useSelector(selectPermissionUserList);
  const [loading1, q1] = useLoading();
  const [loading2, q2] = useLoading();
  const [loading3, q3] = useLoading();
  const [loading4, q4] = useLoading();
  const [loading5, q5] = useLoading();
  const [filterSticked, setFilterSticked] = useState(false);

  const [legendsState, setLegendsState] = useState({
    sessionRate: {
      Average: true,
      Median: false,
      Max: false,
      Min: false,
    },
    chattingTime: {
      Average: true,
      Median: false,
      Max: false,
      Min: false,
    },
    responseTime: {
      Average: true,
      Median: false,
      Max: false,
      Min: false,
    },
    queueTime: {
      Average: true,
      Median: false,
      Max: false,
      Min: false,
    },
    ticketResolve: {
      Average: true,
      Median: false,
      Max: false,
      Min: false,
    },

    likeDislike: {
      Like: true,
      Dislike: true,
    },
  });

  const [genericQueryFilter, setGenericQueryFilter] = useState({
    // default: {
    start_date: DateHelper.getDateTimeLocal().startOf("day").add(-30, "day"),
    end_date: DateHelper.getDateTimeLocal().startOf("day"), //.add(1, "day"),
    time_interval: 24,
    agent_list: [],
    statistic_type: "average",
  });

  const [tabState, setTabState] = useState(1);
  const scrollDirection = useScrollDirection(document.getElementById("main-scroll"));

  const handleLegendChange = (type) => (state) => {
    const newLegendsState = {
      ...legendsState,
      [type]: {
        ...state.selected,
      },
    };
    setLegendsState(newLegendsState);

    if (!agentList?.length) return;
    if (tabState === 2) return;

    const statisticRequestList = [];

    const startDate = genericQueryFilter?.start_date;
    const endDate = genericQueryFilter?.end_date;
    const timeInterval = genericQueryFilter?.time_interval;

    const relatedActiveKeys = getKeysByEnabledLegends(type, newLegendsState);

    const requestBase = {
      ...genericQueryFilter,
      start_date: DateHelper.format(startDate),
      end_date: DateHelper.format(endDate),
      agent_list: [],
      time_interval: timeInterval,
    };
    for (const key of relatedActiveKeys) {
      const requestStatistic = cloneDeep(requestBase);
      requestStatistic.key = key;
      statisticRequestList.push(requestStatistic);
    }

    let queuePool;
    if (type === "queueTime") {
      queuePool = q4;
    } else if (type === "responseTime") {
      queuePool = q3;
    } else if (type === "chattingTime") {
      queuePool = q2;
    } else if (type === "sessionRate") {
      queuePool = q1;
    } else if (type === "ticketResolve") {
      queuePool = q5;
    }

    queuePool(dispatch(getGenericStatistics(currentProject.id, statisticRequestList)));
  };
  const agentList = useMemo(() => {
    return permissionUserList.filter((item) =>
      item.roles.some((r) => [userRole.admin, userRole.ticketAgent].includes(r))
    );
  }, [permissionUserList]);

  const agentListOptions = useMemo(() => {
    return agentList.map((item) => ({
      label: `${item.firstname} ${item.lastname} (${item.email})`,
      value: item.id,
    }));
  }, [agentList]);

  const buttonGroupOption = useMemo(() => {
    return [
      {
        label: t("dashboard.agentStatistics.optionsForAgent.average"),
        value: "average",
      },
      {
        label: t("dashboard.agentStatistics.optionsForAgent.median"),
        value: "median",
      },
      {
        label: t("dashboard.agentStatistics.optionsForAgent.max"),
        value: "max",
      },
      {
        label: t("dashboard.agentStatistics.optionsForAgent.min"),
        value: "min",
      },
    ];
  }, [t]);

  const getRelatedKeysByStatisticType = useCallback(
    (chartKey = "") => {
      let result = [];
      switch (genericQueryFilter.statistic_type) {
        case "average":
          result = [
            statisticType.AGENT_LIKE_COUNT,
            statisticType.AGENT_DISLIKE_COUNT,
            statisticType.AGENT_AVAILABLE_TIME,
            statisticType.AGENT_MESSAGE_COUNT,
            statisticType.AGENT_SESSION_RATE_AVERAGE,
            // statisticType.AGENT_CHAT_TIME_AVERAGE,
            // statisticType.AGENT_RESPONSE_TIME_AVERAGE,
            statisticType.CALLCENTER_QUEUE_TIME_AVERAGE,
            // statisticType.AGENT_TICKET_RESOLVE_TIME_AVERAGE,
            statisticType.AGENT_SESSION_COUNT,
            statisticType.AGENT_TOTAL_CHAT_TIME,
          ];
          break;
        case "median":
          result = [
            statisticType.AGENT_LIKE_COUNT,
            statisticType.AGENT_DISLIKE_COUNT,
            statisticType.AGENT_AVAILABLE_TIME,
            statisticType.AGENT_MESSAGE_COUNT,
            statisticType.AGENT_SESSION_RATE_MEDIAN,
            // statisticType.AGENT_CHAT_TIME_MEDIAN,
            // statisticType.AGENT_RESPONSE_TIME_MEDIAN,
            statisticType.CALLCENTER_QUEUE_TIME_MEDIAN,
            // statisticType.AGENT_TICKET_RESOLVE_TIME_MEDIAN,
            statisticType.AGENT_SESSION_COUNT,
            statisticType.AGENT_TOTAL_CHAT_TIME,
          ];
          break;
        case "max":
          result = [
            statisticType.AGENT_LIKE_COUNT,
            statisticType.AGENT_DISLIKE_COUNT,
            statisticType.AGENT_AVAILABLE_TIME,
            statisticType.AGENT_MESSAGE_COUNT,
            statisticType.AGENT_SESSION_RATE_MAX,
            // statisticType.AGENT_CHAT_TIME_MAX,
            // statisticType.AGENT_RESPONSE_TIME_MAX,
            statisticType.CALLCENTER_QUEUE_TIME_MAX,
            // statisticType.AGENT_TICKET_RESOLVE_TIME_MAX,
            statisticType.AGENT_SESSION_COUNT,
            statisticType.AGENT_TOTAL_CHAT_TIME,
          ];
          break;
        case "min":
          result = [
            statisticType.AGENT_LIKE_COUNT,
            statisticType.AGENT_DISLIKE_COUNT,
            statisticType.AGENT_AVAILABLE_TIME,
            statisticType.AGENT_MESSAGE_COUNT,
            statisticType.AGENT_SESSION_RATE_MIN,
            statisticType.AGENT_CHAT_TIME_MIN,
            statisticType.AGENT_RESPONSE_TIME_MIN,
            statisticType.CALLCENTER_QUEUE_TIME_MIN,
            statisticType.AGENT_TICKET_RESOLVE_TIME_MIN,
            statisticType.AGENT_SESSION_COUNT,
            statisticType.AGENT_TOTAL_CHAT_TIME,
          ];
          break;
      }
      //Remove unrelated chartKeys order-based(Be careful when editing result's key order of each case)
      if (chartKey === "sessionRate") {
        result = result.filter((item) => item.includes("SESSION_RATE"));
      } else if (chartKey === "chattingTime") {
        result = result.filter((item) => item.includes("CHAT_TIME"));
      } else if (chartKey === "responseTime") {
        result = result.filter((item) => item.includes("RESPONSE_TIME"));
      } else if (chartKey === "queueTime") {
        result = result.filter((item) => item.includes("QUEUE_TIME"));
      } else if (chartKey === "ticketResolve") {
        result = result.filter((item) => item.includes("TICKET_RESOLVE_TIME"));
      } else if (chartKey === "like" || chartKey === "dislike" || chartKey === "likeDislike") {
        result = result.filter((item) => item.includes("LIKE") || item.includes("DISLIKE"));
      } else if (chartKey === "availableTime") {
        result = result.filter((item) => item.includes("AVAILABLE_TIME"));
      }

      return result;
    },
    [genericQueryFilter.statistic_type]
  );

  const getKeysByEnabledLegends = useCallback(
    (chartKey = "", customLegendsState) => {
      const activeKeys = [];

      const state = customLegendsState || legendsState;
      const predicate1 = chartKey === "" || chartKey === "sessionRate";
      if (predicate1 && state.sessionRate.Average) activeKeys.push(statisticType.AGENT_SESSION_RATE_AVERAGE);
      if (predicate1 && state.sessionRate.Median) activeKeys.push(statisticType.AGENT_SESSION_RATE_MEDIAN);
      if (predicate1 && state.sessionRate.Max) activeKeys.push(statisticType.AGENT_SESSION_RATE_MAX);
      if (predicate1 && state.sessionRate.Min) activeKeys.push(statisticType.AGENT_SESSION_RATE_MIN);

      // const predicate2 = chartKey === "" || chartKey === "chattingTime";
      // if (predicate2 && state.chattingTime.Average) activeKeys.push(statisticType.AGENT_CHAT_TIME_AVERAGE);
      // if (predicate2 && state.chattingTime.Median) activeKeys.push(statisticType.AGENT_CHAT_TIME_MEDIAN);
      // if (predicate2 && state.chattingTime.Max) activeKeys.push(statisticType.AGENT_CHAT_TIME_MAX);
      // if (predicate2 && state.chattingTime.Min) activeKeys.push(statisticType.AGENT_CHAT_TIME_MIN);

      // const predicate3 = chartKey === "" || chartKey === "responseTime";
      // if (predicate3 && state.responseTime.Average) activeKeys.push(statisticType.AGENT_RESPONSE_TIME_AVERAGE);
      // if (predicate3 && state.responseTime.Median) activeKeys.push(statisticType.AGENT_RESPONSE_TIME_MEDIAN);
      // if (predicate3 && state.responseTime.Max) activeKeys.push(statisticType.AGENT_RESPONSE_TIME_MAX);
      // if (predicate3 && state.responseTime.Min) activeKeys.push(statisticType.AGENT_RESPONSE_TIME_MIN);

      const predicate4 = chartKey === "" || chartKey === "queueTime";
      if (predicate4 && state.queueTime.Average) activeKeys.push(statisticType.CALLCENTER_QUEUE_TIME_AVERAGE);
      if (predicate4 && state.queueTime.Median) activeKeys.push(statisticType.CALLCENTER_QUEUE_TIME_MEDIAN);
      if (predicate4 && state.queueTime.Max) activeKeys.push(statisticType.CALLCENTER_QUEUE_TIME_MAX);
      if (predicate4 && state.queueTime.Min) activeKeys.push(statisticType.CALLCENTER_QUEUE_TIME_MIN);

      // const predicate5 = chartKey === "" || chartKey === "ticketResolve";
      // if (predicate5 && state.ticketResolve.Average) activeKeys.push(statisticType.AGENT_TICKET_RESOLVE_TIME_AVERAGE);
      // if (predicate5 && state.ticketResolve.Median) activeKeys.push(statisticType.AGENT_TICKET_RESOLVE_TIME_MEDIAN);
      // if (predicate5 && state.ticketResolve.Max) activeKeys.push(statisticType.AGENT_TICKET_RESOLVE_TIME_MAX);
      // if (predicate5 && state.ticketResolve.Min) activeKeys.push(statisticType.AGENT_TICKET_RESOLVE_TIME_MIN);

      activeKeys.push(statisticType.TOTAL_AGENT_MESSAGE_COUNT);
      activeKeys.push(statisticType.TOTAL_AGENT_LIKE_COUNT);
      activeKeys.push(statisticType.TOTAL_AGENT_DISLIKE_COUNT);
      activeKeys.push(statisticType.TOTAL_AGENT_SESSION_RATE_AVERAGE);
      activeKeys.push(statisticType.AGENT_SESSION_COUNT);
      activeKeys.push(statisticType.AGENT_TOTAL_CHAT_TIME);

      return activeKeys;
    },
    [legendsState]
  );

  useEffect(() => {
    const positionHandler = () => {
      const filterDom = document.getElementById("filter-col");
      if (!filterDom) return;

      const { top } = filterDom.getBoundingClientRect();
      const limit = 110; // header + padding-top
      // const limit = 70 + 24; // header + padding-top
      if (top <= limit) {
        setFilterSticked(true);
      } else if (scrollDirection === "up") {
        setFilterSticked(false);
      }
    };

    const filterDom = document.getElementById("filter-col");
    if (!filterDom) return;

    const mainDom = document.getElementById("main-scroll");
    mainDom.addEventListener("scroll", positionHandler);

    return () => {
      mainDom.removeEventListener("scroll", positionHandler);
    };
  }, [scrollDirection]);

  useEffect(() => {
    if (!currentProject.id) return;
    // dispatch(getTicketCategoryList(currentProject.id));
    dispatch(getPermissionUserList(currentProject.id));
  }, [dispatch, currentProject.id]);

  useEffect(() => {
    if (!agentList?.length) return;

    const statisticRequestList = [];
    const allAgentsIdList = agentList.map((item) => item.id);
    const queryFilter = genericQueryFilter;
    const startDate = genericQueryFilter?.start_date;
    const endDate = genericQueryFilter?.end_date;
    const timeInterval = genericQueryFilter?.time_interval;

    if (tabState === 2) {
      const requestBase = {
        ...queryFilter,
        // key: queryFilter.key,
        start_date: DateHelper.format(startDate),
        end_date: DateHelper.format(endDate),
        agent_list: queryFilter.agent_list?.length ? queryFilter.agent_list : allAgentsIdList,
        time_interval: timeInterval,
      };

      const activeKeys = getRelatedKeysByStatisticType();
      for (const key of activeKeys) {
        const requestStatistic = cloneDeep(requestBase);
        requestStatistic.key = key;
        statisticRequestList.push(requestStatistic);
      }
    } else {
      const activeKeys = getKeysByEnabledLegends();

      const requestBase = {
        ...queryFilter,
        // key: type,
        start_date: DateHelper.format(startDate),
        end_date: DateHelper.format(endDate),
        agent_list: [],
        time_interval: timeInterval,
      };
      // statisticRequestList.push(request);
      for (const key of activeKeys) {
        const requestStatistic = cloneDeep(requestBase);
        requestStatistic.key = key;
        statisticRequestList.push(requestStatistic);
      }
    }

    q5(q4(q3(q2(q1(dispatch(getGenericStatistics(currentProject.id, statisticRequestList)))))));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getRelatedKeysByStatisticType,
    // getKeysByEnabledLegends,
    q1,
    q2,
    q3,
    q4,
    q5,
    agentList,
    genericQueryFilter,
    dispatch,
    currentProject.id,
    tabState,
  ]);

  const getOptionForAgentSession = () => {
    const agentModeKey = getRelatedKeysByStatisticType("sessionRate")[0];
    const option = {
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => value.toFixedSoft(2),
        hideBlank: true,
      },
      legend: {
        data:
          tabState === 1
            ? [
                t("dashboard.agentStatistics.optionsForAgent.average"),
                t("dashboard.agentStatistics.optionsForAgent.median"),
                t("dashboard.agentStatistics.optionsForAgent.max"),
                t("dashboard.agentStatistics.optionsForAgent.min"),
              ]
            : [
                ...(genericQueryFilter?.agent_list || [])
                  .map((item) => agentList.find((agent) => agent.id === item)?.email)
                  .filter(Boolean),
              ],
        selected: {
          ...legendsState.sessionRate,
        },
      },
      xAxis: {
        type: "time",
      },
      yAxis: {
        type: "value",
      },
      series:
        tabState === 1
          ? [
              {
                name: t("dashboard.agentStatistics.optionsForAgent.average"),
                type: "line",
                data: genericStatistics[statisticType.TOTAL_AGENT_SESSION_RATE_AVERAGE]?.data?.map((item) => [
                  new Date(item.timestamp),
                  item.value,
                ]),
                lineStyle: {
                  type: [15, 5],
                },
              },
              {
                name: t("dashboard.agentStatistics.optionsForAgent.median"),
                type: "line",
                data: genericStatistics[statisticType.AGENT_SESSION_RATE_MEDIAN]?.data?.total?.map((item) => [
                  new Date(item.timestamp),
                  item.value,
                ]),
                lineStyle: {
                  type: [5, 5],
                },
              },
              {
                name: t("dashboard.agentStatistics.optionsForAgent.max"),
                type: "line",
                data: genericStatistics[statisticType.AGENT_SESSION_RATE_MAX]?.data?.total?.map((item) => [
                  new Date(item.timestamp),
                  item.value,
                ]),
                lineStyle: {
                  type: [4, 8],
                },
              },
              {
                name: t("dashboard.agentStatistics.optionsForAgent.min"),
                type: "line",
                data: genericStatistics[statisticType.AGENT_SESSION_RATE_MIN]?.data?.total?.map((item) => [
                  new Date(item.timestamp),
                  item.value,
                ]),
                lineStyle: {
                  type: [4, 8],
                },
              },
            ]
          : [
              ...Object.entries(genericStatistics[agentModeKey]?.data || {})
                .filter(([key, value]) => key !== "total")
                .map(([key, value]) => ({
                  name: agentList.find((item) => item.id === Number(key))?.email,
                  type: "line",
                  data: value.map((item) => [new Date(item.timestamp), item.value]),
                }))
                .filter((item) => item.name),
            ],
    };
    return option;
  };

  // const getOptionForAgentChatTime = () => {
  //   const agentModeKey = getRelatedKeysByStatisticType("chattingTime")[0];
  //   return {
  //     tooltip: {
  //       trigger: "axis",
  //       valueFormatter: (value) => DateHelper.formatMoment(value, "seconds", "d [day] h [hour] m [min] s [sec]"),
  //       hideBlank: true,
  //     },
  //     legend: {
  //       data:
  //         tabState === 1
  //           ? ["Average", "Median", "Max", "Min"]
  //           : [
  //               ...(genericQueryFilter?.agent_list || [])
  //                 .map((item) => agentList.find((agent) => agent.id === item)?.email)
  //                 .filter(Boolean),
  //             ],
  //       selected: {
  //         ...legendsState.chattingTime,
  //       },
  //     },
  //     xAxis: {
  //       type: "time",
  //     },
  //     yAxis: {
  //       type: "value",
  //     },
  //     series:
  //       tabState === 1
  //         ? [
  //             {
  //               name: "Average",
  //               type: "line",
  //               data: genericStatistics[statisticType.AGENT_CHAT_TIME_AVERAGE]?.data?.total.map((item) => [
  //                 new Date(item.timestamp),
  //                 item.value,
  //               ]),
  //               lineStyle: {
  //                 type: [15, 5],
  //               },
  //             },
  //             {
  //               name: "Median",
  //               type: "line",
  //               data: genericStatistics[statisticType.AGENT_CHAT_TIME_MEDIAN]?.data?.total.map((item) => [
  //                 new Date(item.timestamp),
  //                 item.value,
  //               ]),
  //               lineStyle: {
  //                 type: [5, 5],
  //               },
  //             },
  //             {
  //               name: "Max",
  //               type: "line",
  //               data: genericStatistics[statisticType.AGENT_CHAT_TIME_MAX]?.data?.total.map((item) => [
  //                 new Date(item.timestamp),
  //                 item.value,
  //               ]),
  //               lineStyle: {
  //                 type: [4, 8],
  //               },
  //             },
  //             {
  //               name: "Min",
  //               type: "line",

  //               data: genericStatistics[statisticType.AGENT_CHAT_TIME_MIN]?.data?.total.map((item) => [
  //                 new Date(item.timestamp),
  //                 item.value,
  //               ]),
  //               lineStyle: {
  //                 type: [4, 8],
  //               },
  //             },
  //           ]
  //         : [
  //             ...Object.entries(genericStatistics[agentModeKey]?.data || {})
  //               .filter(([key, value]) => key !== "total")
  //               .map(([key, value]) => ({
  //                 name: agentList.find((item) => item.id === Number(key))?.email,
  //                 type: "line",
  //                 data: value.map((item) => [new Date(item.timestamp), item.value]),
  //               }))
  //               .filter((item) => item.name),
  //           ],
  //   };
  // };

  // const getOptionForAgentResponseTime = () => {
  //   const agentModeKey = getRelatedKeysByStatisticType("responseTime")[0];
  //   return {
  //     tooltip: {
  //       trigger: "axis",
  //       valueFormatter: (value) => DateHelper.formatMoment(value, "seconds", "d [day] h [hour] m [min] s [sec]"),
  //       hideBlank: true,
  //     },
  //     legend: {
  //       data:
  //         tabState === 1
  //           ? ["Average", "Median", "Max", "Min"]
  //           : [
  //               ...(genericQueryFilter.agent_list || [])
  //                 .map((item) => agentList.find((agent) => agent.id === item)?.email)
  //                 .filter(Boolean),
  //             ],
  //       selected: {
  //         ...legendsState.responseTime,
  //       },
  //     },
  //     xAxis: {
  //       type: "time",
  //     },
  //     yAxis: {
  //       type: "value",
  //     },
  //     series:
  //       tabState === 1
  //         ? [
  //             {
  //               name: "Average",
  //               type: "line",
  //               data: genericStatistics[statisticType.AGENT_RESPONSE_TIME_AVERAGE]?.data?.total.map((item) => [
  //                 new Date(item.timestamp),
  //                 item.value,
  //               ]),
  //               lineStyle: {
  //                 type: [15, 5],
  //               },
  //             },
  //             {
  //               name: "Median",
  //               type: "line",
  //               data: genericStatistics[statisticType.AGENT_RESPONSE_TIME_MEDIAN]?.data?.total.map((item) => [
  //                 new Date(item.timestamp),
  //                 item.value,
  //               ]),
  //               lineStyle: {
  //                 type: [5, 5],
  //               },
  //             },
  //             {
  //               name: "Max",
  //               type: "line",
  //               data: genericStatistics[statisticType.AGENT_RESPONSE_TIME_MAX]?.data?.total.map((item) => [
  //                 new Date(item.timestamp),
  //                 item.value,
  //               ]),
  //               lineStyle: {
  //                 type: [4, 8],
  //               },
  //             },
  //             {
  //               name: "Min",
  //               type: "line",

  //               data: genericStatistics[statisticType.AGENT_RESPONSE_TIME_MIN]?.data?.total.map((item) => [
  //                 new Date(item.timestamp),
  //                 item.value,
  //               ]),
  //               lineStyle: {
  //                 type: [4, 8],
  //               },
  //             },
  //           ]
  //         : [
  //             ...Object.entries(genericStatistics[agentModeKey]?.data || {})
  //               .filter(([key, value]) => key !== "total")
  //               .map(([key, value]) => ({
  //                 name: agentList.find((item) => item.id === Number(key))?.email,
  //                 type: "line",
  //                 data: value.map((item) => [new Date(item.timestamp), item.value]),
  //               }))
  //               .filter((item) => item.name),
  //           ],
  //   };
  // };

  // const getOptionForAgentTicketResolve = () => {
  //   const agentModeKey = getRelatedKeysByStatisticType("ticketResolve")[0];
  //   return {
  //     tooltip: {
  //       trigger: "axis",
  //       valueFormatter: (value) => DateHelper.formatMoment(value, "seconds", "d [day] h [hour] m [min] s [sec]"),
  //       hideBlank: true,
  //     },
  //     legend: {
  //       data:
  //         tabState === 1
  //           ? ["Average", "Median", "Max", "Min"]
  //           : [
  //               ...(genericQueryFilter.agent_list || [])
  //                 .map((item) => agentList.find((agent) => agent.id === item)?.email)
  //                 .filter(Boolean),
  //             ],
  //       selected: {
  //         ...legendsState.responseTime,
  //       },
  //     },
  //     xAxis: {
  //       type: "time",
  //     },
  //     yAxis: {
  //       type: "value",
  //     },
  //     series:
  //       tabState === 1
  //         ? [
  //             {
  //               name: "Average",
  //               type: "line",
  //               data: genericStatistics[statisticType.AGENT_TICKET_RESOLVE_TIME_AVERAGE]?.data?.total.map((item) => [
  //                 new Date(item.timestamp),
  //                 item.value,
  //               ]),
  //               lineStyle: {
  //                 type: [15, 5],
  //               },
  //             },
  //             {
  //               name: "Median",
  //               type: "line",
  //               data: genericStatistics[statisticType.AGENT_TICKET_RESOLVE_TIME_MEDIAN]?.data?.total.map((item) => [
  //                 new Date(item.timestamp),
  //                 item.value,
  //               ]),
  //               lineStyle: {
  //                 type: [5, 5],
  //               },
  //             },
  //             {
  //               name: "Max",
  //               type: "line",
  //               data: genericStatistics[statisticType.AGENT_TICKET_RESOLVE_TIME_MAX]?.data?.total.map((item) => [
  //                 new Date(item.timestamp),
  //                 item.value,
  //               ]),
  //               lineStyle: {
  //                 type: [4, 8],
  //               },
  //             },
  //             {
  //               name: "Min",
  //               type: "line",

  //               data: genericStatistics[statisticType.AGENT_TICKET_RESOLVE_TIME_MIN]?.data?.total.map((item) => [
  //                 new Date(item.timestamp),
  //                 item.value,
  //               ]),
  //               lineStyle: {
  //                 type: [4, 8],
  //               },
  //             },
  //           ]
  //         : [
  //             ...Object.entries(genericStatistics[agentModeKey]?.data || {})
  //               .filter(([key, value]) => key !== "total")
  //               .map(([key, value]) => ({
  //                 name: agentList.find((item) => item.id === Number(key))?.email,
  //                 type: "line",
  //                 data: value.map((item) => [new Date(item.timestamp), item.value]),
  //               }))
  //               .filter((item) => item.name),
  //           ],
  //   };
  // };

  const getOptionForAgentAvailableTime = () => {
    // const agentModeKey = getRelatedKeysByStatisticType("availableTime")[0];
    return {
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => DateHelper.formatMoment(value, "seconds", "d [day] h [hour] m [min] s [sec]"),
        hideBlank: true,
      },
      xAxis: {
        type: "time",
      },
      yAxis: {
        type: "value",
      },
      series:
        tabState === 1
          ? [
              {
                name: t("common.total"),
                type: "line",
                data: genericStatistics[statisticType.AGENT_AVAILABLE_TIME]?.data?.total.map((item) => [
                  new Date(item.timestamp),
                  item.value,
                ]),
                lineStyle: {
                  type: [15, 5],
                },
              },
            ]
          : [
              ...Object.entries(genericStatistics[statisticType.AGENT_AVAILABLE_TIME]?.data || {})
                .filter(([key, value]) => key !== "total")
                .map(([key, value]) => ({
                  name: agentList.find((item) => item.id === Number(key))?.email,
                  type: "line",
                  data: value.map((item) => [new Date(item.timestamp), item.value]),
                }))
                .filter((item) => item.name),
            ],
    };
  };

  const getOptionQueueTime = () => {
    return {
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => DateHelper.formatMoment(value, "seconds", "d [day] h [hour] m [min] s [sec]"),
        hideBlank: true,
      },
      legend: {
        data: [
          t("dashboard.agentStatistics.optionsForAgent.average"),
          t("dashboard.agentStatistics.optionsForAgent.median"),
          t("dashboard.agentStatistics.optionsForAgent.max"),
          t("dashboard.agentStatistics.optionsForAgent.min"),
        ].filter(Boolean),
        selected: {
          ...legendsState.queueTime,
        },
      },
      xAxis: {
        type: "time",
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: t("dashboard.agentStatistics.optionsForAgent.average"),
          type: "line",
          data: genericStatistics[statisticType["CALLCENTER_QUEUE_TIME_AVERAGE"]]?.data.map((item) => [
            new Date(item.timestamp),
            item.value,
          ]),
          lineStyle: {
            type: [15, 5],
          },
        },
        {
          name: t("dashboard.agentStatistics.optionsForAgent.median"),
          type: "line",
          data: genericStatistics[statisticType["CALLCENTER_QUEUE_TIME_MEDIAN"]]?.data.map((item) => [
            new Date(item.timestamp),
            item.value,
          ]),
          lineStyle: {
            type: [5, 5],
          },
        },
        {
          name: t("dashboard.agentStatistics.optionsForAgent.max"),
          type: "line",
          data: genericStatistics[statisticType["CALLCENTER_QUEUE_TIME_MAX"]]?.data.map((item) => [
            new Date(item.timestamp),
            item.value,
          ]),
          lineStyle: {
            type: [4, 8],
          },
        },
        {
          name: t("dashboard.agentStatistics.optionsForAgent.min"),
          type: "line",

          data: genericStatistics[statisticType["CALLCENTER_QUEUE_TIME_MIN"]]?.data.map((item) => [
            new Date(item.timestamp),
            item.value,
          ]),
          lineStyle: {
            type: [4, 8],
          },
        },
      ].filter(Boolean),
    };
  };

  const getOptionLike = () => {
    const option = {
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => value.toFixedSoft(0),
        hideBlank: true,
      },
      xAxis: {
        type: "time",
      },
      yAxis: {
        type: "value",
      },
      series:
        tabState === 1
          ? [
              {
                name: t("dashboard.agentStatistics.totalLike"),
                type: "line",
                data: genericStatistics[statisticType.TOTAL_AGENT_LIKE_COUNT]?.data?.map((item) => [
                  new Date(item.timestamp),
                  item.value,
                ]),
                lineStyle: {
                  type: [15, 5],
                },
              },
            ]
          : [
              ...Object.entries(genericStatistics[statisticType.AGENT_LIKE_COUNT]?.data || {})
                .filter(([key, value]) => key !== "total")
                .map(([key, value]) => ({
                  name: agentList.find((item) => item.id === Number(key))?.email,
                  type: "line",
                  data: value.map((item) => [new Date(item.timestamp), item.value]),
                }))
                .filter((item) => item.name),
            ],
    };
    return option;
  };
  const getOptionDislike = () => {
    const option = {
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => value.toFixedSoft(0),
        hideBlank: true,
      },
      xAxis: {
        type: "time",
      },
      yAxis: {
        type: "value",
      },
      series:
        tabState === 1
          ? [
              {
                name: t("dashboard.agentStatistics.totalDislike"),
                type: "line",
                data: genericStatistics[statisticType.TOTAL_AGENT_DISLIKE_COUNT]?.data?.map((item) => [
                  new Date(item.timestamp),
                  item.value,
                ]),
                lineStyle: {
                  type: [15, 5],
                },
              },
            ]
          : [
              ...Object.entries(genericStatistics[statisticType.AGENT_DISLIKE_COUNT]?.data || {})
                .filter(([key, value]) => key !== "total")
                .map(([key, value]) => ({
                  name: agentList.find((item) => item.id === Number(key))?.email,
                  type: "line",
                  data: value.map((item) => [new Date(item.timestamp), item.value]),
                }))
                .filter((item) => item.name),
            ],
    };
    return option;
  };

  const getOptionMessageCount = () => {
    const option = {
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => value.toFixedSoft(0),
        hideBlank: true,
      },
      xAxis: {
        type: "time",
      },
      yAxis: {
        type: "value",
      },
      series:
        tabState === 1
          ? [
              {
                name: t("dashboard.agentStatistics.totalMessageCount"),
                type: "line",
                data: genericStatistics[statisticType.TOTAL_AGENT_MESSAGE_COUNT]?.data?.map((item) => [
                  new Date(item.timestamp),
                  item.value,
                ]),
                lineStyle: {
                  type: [15, 5],
                },
              },
            ]
          : [
              ...Object.entries(genericStatistics[statisticType.AGENT_MESSAGE_COUNT]?.data || {})
                .filter(([key, value]) => key !== "total")
                .map(([key, value]) => ({
                  name: agentList.find((item) => item.id === Number(key))?.email,
                  type: "line",
                  data: value.map((item) => [new Date(item.timestamp), item.value]),
                }))
                .filter((item) => item.name),
            ],
    };
    return option;
  };

  const getOptionAgentSessionCount = () => {
    const option = {
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => value.toFixedSoft(2),
        hideBlank: true,
      },
      xAxis: {
        type: "time",
      },
      yAxis: {
        type: "value",
      },
      series:
        tabState === 1
          ? [
              {
                name: t("dashboard.agentStatistics.sessionCount"),
                type: "line",
                data: genericStatistics[statisticType.AGENT_SESSION_COUNT]?.data?.total?.map((item) => [
                  new Date(item.timestamp),
                  item.value,
                ]),
                lineStyle: {
                  type: [15, 5],
                },
              },
            ]
          : [
              ...Object.entries(genericStatistics[statisticType.AGENT_SESSION_COUNT]?.data || {})
                .filter(([key, value]) => key !== "total")
                .map(([key, value]) => ({
                  name: agentList.find((item) => item.id === Number(key))?.email,
                  type: "line",
                  data: value.map((item) => [new Date(item.timestamp), item.value]),
                }))
                .filter((item) => item.name),
            ],
    };
    return option;
  };

  const handleChangeGenericFilter = (key) => (value) => {
    let newFilter = {};

    if (key === "time_interval" || key === "start_date") {
      const startDateValue = key === "start_date" ? value : genericQueryFilter?.start_date;
      const intervalValue = key === "time_interval" ? value : genericQueryFilter?.time_interval;

      if (genericQueryFilter?.end_date) {
        const startDate = DateHelper.getDateTimeLocal(startDateValue).startOf("day");
        const endDate = DateHelper.getDateTimeLocal(genericQueryFilter?.end_date).startOf("day");

        const diffHour = endDate.diff(startDate, "hour");
        const dayDiff = intervalValue === 1 ? 3 : intervalValue === 24 ? 30 : 30 * 12;
        const maxHoursDiff = dayDiff * 24;

        if (diffHour < 0 || diffHour > maxHoursDiff) {
          newFilter = {
            end_date: startDate.clone().add(maxHoursDiff, "hour"),
          };
        }
      }
    }

    const newState = {
      ...genericQueryFilter,
      [key]: value,
      ...newFilter,
    };
    setGenericQueryFilter(newState);
  };

  const handleStartDateIsValid = (current) => {
    const isFuture = current.isAfter(DateHelper.getDateTimeLocal().startOf("day"));
    if (isFuture) return false;

    return true;
  };
  const handleEndDateIsValid = (current) => {
    const isFuture = current.isAfter(DateHelper.getDateTimeLocal().startOf("day"));
    const isBeforeStartDate = current.isBefore(genericQueryFilter.start_date.clone().startOf("day"));
    if (isFuture || isBeforeStartDate) return false;

    if (genericQueryFilter.time_interval === 1) {
      return current.startOf("day").isSameOrBefore(genericQueryFilter.start_date.clone().startOf("day").add(3, "day"));
    } else if (genericQueryFilter.time_interval === 1 * 24) {
      return current.startOf("day").isSameOrBefore(genericQueryFilter.start_date.clone().startOf("day").add(30, "day"));
    } else if (genericQueryFilter.time_interval === 1 * 24 * 7) {
      return current.startOf("day").isSameOrBefore(
        genericQueryFilter.start_date
          .clone()
          .startOf("day")
          .add(30 * 12, "day")
      );
    }

    return true;
  };

  const handleExcelDownload = async () => {
    if (!agentList?.length) return;

    const statisticRequestList = [];
    const allAgentsIdList = agentList.map((item) => item.id);
    const queryFilter = genericQueryFilter;
    const startDate = genericQueryFilter?.start_date;
    const endDate = genericQueryFilter?.end_date;
    const timeInterval = genericQueryFilter?.time_interval;

    if (tabState === 2) {
      const requestBase = {
        ...queryFilter,
        // key: queryFilter.key,
        start_date: DateHelper.format(startDate),
        end_date: DateHelper.format(endDate),
        agent_list: queryFilter.agent_list?.length ? queryFilter.agent_list : allAgentsIdList,
        time_interval: timeInterval,
      };

      const activeKeys = getRelatedKeysByStatisticType();
      for (const key of activeKeys) {
        const requestStatistic = cloneDeep(requestBase);
        requestStatistic.key = key;
        statisticRequestList.push(requestStatistic);
      }
    } else {
      const activeKeys = getKeysByEnabledLegends();

      const requestBase = {
        ...queryFilter,
        // key: type,
        start_date: DateHelper.format(startDate),
        end_date: DateHelper.format(endDate),
        agent_list: [],
        time_interval: timeInterval,
      };
      // statisticRequestList.push(request);
      for (const key of activeKeys) {
        const requestStatistic = cloneDeep(requestBase);
        requestStatistic.key = key;
        statisticRequestList.push(requestStatistic);
      }
    }

    const blobResponse = await dispatch(downloadStatistics(currentProject.id, statisticRequestList));

    const fileName = `agent_statistics_[${tabState === 2 ? "A" : "G"}]_${DateHelper.format(
      DateHelper.getDateTimeLocal(),
      "YYYYMMDDHHmmss"
    )}.xlsx`;
    FileHelper.saveAs(blobResponse, fileName);
  };

  const getOptionAgentTotalChatTime = () => {
    const option = {
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => DateHelper.formatMoment(value, "seconds", "d [day] h [hour] m [min] s [sec]"),
        hideBlank: true,
      },
      xAxis: {
        type: "time",
      },
      yAxis: {
        type: "value",
      },
      series:
        tabState === 1
          ? [
              {
                name: t("dashboard.agentStatistics.chatTime"),
                type: "line",
                data: genericStatistics[statisticType.AGENT_TOTAL_CHAT_TIME]?.data?.total?.map((item) => [
                  new Date(item.timestamp),
                  item.value,
                ]),
                lineStyle: {
                  type: [15, 5],
                },
              },
            ]
          : [
              ...Object.entries(genericStatistics[statisticType.AGENT_TOTAL_CHAT_TIME]?.data || {})
                .filter(([key, value]) => key !== "total")
                .map(([key, value]) => ({
                  name: agentList.find((item) => item.id === Number(key))?.email,
                  type: "line",
                  data: value.map((item) => [new Date(item.timestamp), item.value]),
                }))
                .filter((item) => item.name),
            ],
    };
    return option;
  };

  return (
    <PRContainer
      className={"pr-agent-stats"}
      description={t("dashboard.agentStatistics.description")}
      name={scope === "contactcenter" ? t("common.contactCenter") : t("common.statistics")}
      parentName={scope === "contactcenter" ? t("common.statistics") : t("dashboard.agentStatistics")}
    >
      <Row>
        <Col
          className={classNames("pr-stats-filter-col", {
            "pr-stats-filter-col-sticked": filterSticked,
          })}
          id="filter-col"
          sm={12}
        >
          <PRPage
            headerColor="primary"
            title={
              !filterSticked && (
                <Box alignItems="center" display="flex" justifyContent="between" width={1}>
                  {t("common.filter")}
                  <Box ml="auto">
                    <PalTooltip title={t("common.exportToExcel")}>
                      <PalIconButton color="teal" size="small" onClick={handleExcelDownload}>
                        <FaFileExcel />
                      </PalIconButton>
                    </PalTooltip>
                  </Box>
                </Box>
              )
            }
            titleClassName="pe-0"
          >
            <Row className="d-flex align-items-center">
              <Col xs>
                <PRTab
                  className="my-2"
                  tab={tabState}
                  tabList={[
                    { id: 1, label: t("dashboard.agentStatistics.general") },
                    { id: 2, label: t("common.agent") },
                  ]}
                  onChange={setTabState}
                />
              </Col>
              {filterSticked && (
                <Col xs="auto">
                  <Box ml="auto">
                    <PalTooltip title={t("common.exportToExcel")}>
                      <PalIconButton color="teal" size="small" onClick={handleExcelDownload}>
                        <FaFileExcel />
                      </PalIconButton>
                    </PalTooltip>
                  </Box>
                </Col>
              )}
            </Row>
            <Row className="g-2">
              <Col md={3} sm={6}>
                <Label className=" ">{t("common.startDate")}</Label>
                <PRDate
                  isClearable={false}
                  isValidDate={handleStartDateIsValid}
                  timeFormat={false}
                  value={genericQueryFilter?.start_date}
                  onChange={handleChangeGenericFilter("start_date")}
                />
              </Col>
              <Col md={3} sm={6}>
                <Label className=" ">{t("common.endDate")}</Label>
                <PRDate
                  isClearable={false}
                  isValidDate={handleEndDateIsValid}
                  timeFormat={false}
                  value={genericQueryFilter?.end_date}
                  onChange={handleChangeGenericFilter("end_date")}
                />
              </Col>
              <Col md={6} sm={12}>
                <Label>{t("common.period")}</Label>
                <PRTab
                  border
                  fullWidth
                  pill
                  tab={genericQueryFilter?.time_interval}
                  tabList={[
                    // { id: 1, label: "Hourly" },
                    { id: 24, label: t("common.daily") },
                    { id: 24 * 7, label: t("common.weekly") },
                    { id: 24 * 7 * 30, label: t("common.monthly") },
                  ]}
                  onChange={handleChangeGenericFilter("time_interval")}
                />
              </Col>
            </Row>

            {tabState === 2 && (
              <Row className="g-2 mt-2">
                <Col md={6} sm={12}>
                  <Label className="">{t("dashboard.agentStatistics.statisticType")}</Label>
                  <div>
                    <PRButtonGroup
                      options={buttonGroupOption}
                      value={genericQueryFilter.statistic_type}
                      onChange={handleChangeGenericFilter("statistic_type")}
                    />
                  </div>
                </Col>
                <Col md={6} sm={12}>
                  <Label className="">{t("common.agent")}</Label>
                  <PRSelect
                    isMulti
                    isPrimitiveValue
                    options={agentListOptions}
                    placeholder={t("dashboard.agentStatistics.selectAgentPlaceholder")}
                    value={genericQueryFilter.agent_list}
                    onChange={handleChangeGenericFilter("agent_list")}
                  />
                </Col>
              </Row>
            )}
          </PRPage>
        </Col>
        <Col xs={12}>
          <PRPage title={t("dashboard.agentStatistics.sessionCount")}>
            <PRChart
              notMerge
              loading={loading3}
              option={getOptionAgentSessionCount()}
              onEvents={{ legendselectchanged: handleLegendChange("sessionCount") }}
            />
          </PRPage>
        </Col>
        <Col xs={12}>
          <PRPage title={t("dashboard.agentStatistics.messageCount")}>
            <PRChart
              notMerge
              loading={loading3}
              option={getOptionMessageCount()}
              onEvents={{ legendselectchanged: handleLegendChange("messageCount") }}
            />
          </PRPage>
        </Col>
        <Col xs={12}>
          <PRPage title={t("dashboard.agentStatistics.sessionRate")}>
            <PRChart
              notMerge
              loading={loading1}
              option={getOptionForAgentSession()}
              onEvents={{ legendselectchanged: handleLegendChange("sessionRate") }}
            />
          </PRPage>
        </Col>
        <Col xs={12}>
          <PRPage title={t("dashboard.agentStatistics.chatTime")}>
            <PRChart
              notMerge
              loading={loading3}
              option={getOptionAgentTotalChatTime()}
              // onEvents={{ legendselectchanged: handleLegendChange("chatTime") }}
            />
          </PRPage>
        </Col>
        {tabState === 1 && (
          <Col xs={12}>
            <PRPage title={t("dashboard.agentStatistics.queueTime")}>
              <PRChart
                notMerge
                loading={loading4}
                option={getOptionQueueTime()}
                onEvents={{ legendselectchanged: handleLegendChange("queueTime") }}
              />
            </PRPage>
          </Col>
        )}
        {tabState === 2 && (
          <Col xs={12}>
            <PRPage title={t("dashboard.agentStatistics.availableTime")}>
              <PRChart
                notMerge
                loading={loading4}
                option={getOptionForAgentAvailableTime()}
                onEvents={{ legendselectchanged: handleLegendChange("dislike") }}
              />
            </PRPage>
          </Col>
        )}
        <Col xs={12}>
          <PRPage title={t("dashboard.agentStatistics.like")}>
            <PRChart
              notMerge
              loading={loading4}
              option={getOptionLike()}
              onEvents={{ legendselectchanged: handleLegendChange("like") }}
            />
          </PRPage>
        </Col>
        <Col xs={12}>
          <PRPage title={t("dashboard.agentStatistics.dislike")}>
            <PRChart
              notMerge
              loading={loading4}
              option={getOptionDislike()}
              onEvents={{ legendselectchanged: handleLegendChange("dislike") }}
            />
          </PRPage>
        </Col>
      </Row>
    </PRContainer>
  );
}
