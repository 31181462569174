import React from "react";

import { FormFeedback, Input } from "reactstrap";

export default function PRFormFeedback({ invalid }) {
  return (
    <>
      {invalid && (
        <>
          <Input className="d-none" invalid={true} />
          <FormFeedback type="invalid">{invalid}</FormFeedback>
        </>
      )}
    </>
  );
}
