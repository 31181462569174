import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import Utils from "~helpers/Utils";

/**
 * Returns `loading` status and `enqueue(Promise)` method that `isLoading` status will only be set to true after all
 * enqueued promise fulfilled
 *
 * Example usage:
 *
 * ```js
 * const [isLoading, enqueue] = useLoading(true);
 *
 * console.log(isLoading); // `true` because initial value is true
 *
 * enqueue(getProductList(11)); // Add promise to loading queue and set `isLoading` to true if it is not already true
 * enqueue(getProductList(22));
 * enqueue(getProductList(33));
 *
 * // Also loading status can be kept true until all loading dependencies are truly.
 * const [id, setId] = useState(0);
 * const [isLoading, enqueue] = useLoading(true, [id]);
 * useEffect(() => {
 *   enqueue(getProduct(id)).then(({ pk }) => {
 *     setId(pk);
 *   });
 * }, []);
 *
 * console.log(isLoading); // Output is `false` when all promises fulfilled and loading dependencies are truly
 * ```
 *
 * @template T Type of promise
 * @param {boolean | null} defaultValue
 * @param {any[]?} loadingDependencies
 * @returns {[boolean, (promise: Promise<T>) => Promise<T>]}
 */

export default function useLoading(defaultValue = false, loadingDependencies = []) {
  const [isLoading, setIsLoading] = useState(defaultValue);
  const [promises, setPromises] = useState([]);
  const keyRef = useRef(Utils.getId());

  const enqueue = useCallback((promise) => {
    if (promise && typeof promise.then === "function") {
      keyRef.current = Utils.getId();
      setPromises((prev) => {
        return [...prev, promise];
      });
    }
    return promise;
  }, []);

  useEffect(() => {
    if (promises.length === 0) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);

    const promise = Promise.all(promises);
    const currentPromiseKey = keyRef.current;
    const promiseFulfilled = () => {
      if (currentPromiseKey === keyRef.current) {
        setIsLoading(false);
        setPromises([]);
      }
    };
    promise.finally(promiseFulfilled);
  }, [promises]);

  const isLoadingResult = !loadingDependencies.every(Boolean) || isLoading;
  return [isLoadingResult, enqueue];
}

/**
 * Returns wrapped function and that automatically set `isLoading` status to true after calling wrapped function and
 * then turn it back to false after wrapped function(s) fulfilled
 *
 * Should be used with `useLoading`
 *
 * Example:
 *
 * ```js
 * const [isLoading, enqueue] = useLoading(true);
 * const getProductListWL = useWithLoading(getProductList, enqueue); // Wrap getProductList with `useWithLoading`
 * getProductListWL(11);
 * getProductListWL(22);
 * getProductListWL(33);
 *
 * console.log(isLoading); // after all enqueued promise fulfilled, it will be false
 * ```
 *
 * @template T,K
 * @param {T} func
 * @param {(promise: Promise<K>) => Promise<K>} enqueue
 * @returns {T}
 */
export const useWithLoading = (func, enqueue) => {
  return useMemo(() => {
    return (...args) => {
      if (!func) return;
      return enqueue(func(...args));
    };
  }, [func, enqueue]);
};
