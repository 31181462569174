import { apiUrlChatbot } from "~constants";
import Network from "~helpers/Network";

import * as at from "./actionTypes";

export const setEntityType = (payload) => ({
  type: at.SET_ENTITY_TYPE,
  payload,
});

// -----------  API ------------

export const getEntityType = (projectId, chatbotId, id) => (_dispatch, _getState) => {
  const url = `${apiUrlChatbot.getEntityType.format(projectId, chatbotId)}${id}/`;

  return Network.request(url, {
    loading: true,
    onSuccess: setEntityType,
  });
};

export const getEntityTypeList =
  (projectId, chatbotId, options = {}) =>
  (_dispatch, _getState) => {
    const url = `${apiUrlChatbot.getEntityType.format(projectId, chatbotId)}/`;

    return Network.request(url, {
      ...options,
      params: {
        ...options.params,
        limit: options.params?.limit || 9999,
      },
    });
  };

export const createOrUpdateEntityType = (projectId, chatbotId, data) => (_dispatch, _getState) => {
  const url = data?.id
    ? `${apiUrlChatbot.getEntityType.format(projectId, chatbotId)}${data?.id}/`
    : apiUrlChatbot.getEntityType.format(projectId, chatbotId);

  return Network.request(url, {
    method: data?.id ? "PUT" : "POST",
    data,
    loading: true,
  });
};

export const deleteEntityType = (projectId, chatbotId, id) => (_dispatch, _getState) => {
  const url = `${apiUrlChatbot.getEntityType.format(projectId, chatbotId)}${id}/`;

  return Network.request(url, {
    method: "DELETE",
    successMsg: true,
    loading: true,
  });
};

export const downloadEntityType = (projectId, chatbotId, id) => (_dispatch, _getState) => {
  const url = apiUrlChatbot.getEntityTypeImportExport.format(projectId, chatbotId, id);

  return Network.request(url, {
    loading: true,
    responseType: "blob",
  });
};

export const uploadEntityType = (projectId, chatbotId, id, file) => (_dispatch, _getState) => {
  const url = apiUrlChatbot.getEntityTypeImportExport.format(projectId, chatbotId, id);

  let postData = new FormData();

  postData.append("file", file);

  return Network.request(url, {
    method: "POST",
    data: postData,
    loading: true,
    successMsg: true,
  });
};
