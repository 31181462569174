import { useState, useEffect } from "react";

/**
 * @param {string} keyName
 * @returns
 */
export default function useKeyPressed(keyName = "Enter") {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    function downHandler({ key }) {
      if (key === keyName) {
        setKeyPressed(true);
      }
    }

    const upHandler = ({ key }) => {
      if (key === keyName) {
        setKeyPressed(false);
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [keyName]);

  return keyPressed;
}
