import { useMemo, useState } from "react";

import { withCardon } from "cardon";
import classNames from "classnames";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import useLoading from "~common/hooks/useLoading";
import PRDropZone from "~components/Generic/PRDropZone";
import { PRTextArea } from "~components/Generic/PRInput";
import PRModal from "~components/Generic/PRModal";
import PRTab from "~components/Generic/PRTab";
import DialogHelper from "~helpers/DialogHelper";
import { uploadMemberData } from "~store/organization/actions";
import { selectCurrentProject } from "~store/user/selectors";
import "./style.scss";

function UploadMemberData({ get, fields }) {
  const currentProject = useSelector(selectCurrentProject);
  const dispatch = useDispatch();
  const [loading, enqueue] = useLoading();
  const [tab, setTab] = useState(1);
  const [keepMissingMode, setKeepMissingMode] = useState(true);

  const jsonPlaceholder = useMemo(() => {
    const idExistFields = fields.filter((item) => item.id);
    const columnKeyList = idExistFields.map((item) => item.name);
    return JSON.stringify(
      [
        {
          ...columnKeyList.reduce((acc, cur, index) => {
            let valData = "Value " + (index + 1);
            if (cur.includes("email")) {
              valData = `email${index + 1}@test.com`;
            }
            return {
              ...acc,
              [cur]: valData,
            };
          }, {}),
        },
      ],
      null,
      2
    );
  }, [fields]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      jsonText: jsonPlaceholder,
      files: [],
    },
    validationSchema: Yup.object().shape({
      ...(tab === 1 && {
        files: Yup.array()
          .required("Required")
          .test("min-one-file", "At least one file is required", (value) => value?.length > 0),
      }),
      ...(tab === 2 && {
        jsonText: Yup.string()
          .required("Required")
          .test("json", "Invalid JSON", (value) => {
            try {
              JSON.parse(value);
              return true;
            } catch {
              return false;
            }
          }),
      }),
    }),
    onSubmit: async (values) => {
      if (
        !(await DialogHelper.showQuestionYesNo(
          "",
          <>
            Are you sure you want to proceed for <b>{currentProject?.name}</b> ?
          </>
        ))
      ) {
        return;
      }

      const payload = {
        ...(tab === 1 && { file: values.files?.[0] }),
        ...(tab === 2 && { member_data: JSON.parse(values.jsonText) }),
      };
      await enqueue(
        dispatch(
          uploadMemberData(currentProject.id, payload, {
            params: {
              keep_missing: keepMissingMode,
            },
          })
        )
      );
      formik.setFieldValue("files", []);
      formik.setFieldTouched("files", false);
    },
  });

  const handleFileChange = (files) => {
    formik.setFieldValue("files", files);
  };

  const handleClickUploadMode = (mode) => () => {
    setKeepMissingMode(mode);
  };

  return (
    <PRModal
      centered
      className="pr-member-data-modal"
      isOpen={true}
      size="lg"
      submitText="Upload"
      title={"Upload Member Data"}
      onClick={formik.handleSubmit}
      onClose={get(false)}
    >
      <p className="w-md-75">
        Select the upload mode and upload the file containing the member data. The upload mode determines how the
        members in the file will be handled.
      </p>
      <div className=" text-center">
        <Label className="fs-5">Upload mode</Label>
      </div>
      <Row className="g-2">
        <Col lg={6} md={12}>
          <div
            className={classNames("upload-type-card", {
              active: keepMissingMode,
            })}
            onClick={handleClickUploadMode(true)}
          >
            <span className="fw-semibold"> Load Members with Update and Creation</span>
            Import members from the file, update existing members with the new data, and create new members if they
            don't already exist. Existing members will not be deleted.
          </div>
        </Col>
        <Col lg={6} md={12}>
          <div
            className={classNames("upload-type-card", {
              active: !keepMissingMode,
            })}
            onClick={handleClickUploadMode(false)}
          >
            <span className="fw-semibold">Load Members with Exact Replacement</span> Import members from the file and
            completely replace existing members with the new data. Any existing members that are not in the file will be
            removed
          </div>
        </Col>
      </Row>

      <PRTab
        className="mb-1 mt-2"
        tab={tab}
        tabList={[
          { id: 1, label: "File" },
          { id: 2, label: "JSON" },
        ]}
        onChange={setTab}
      />
      {tab === 1 && (
        <PRDropZone
          accept={[".xls", ".xlsx"]}
          className=""
          files={formik.values.files}
          invalid={formik.touched.files && formik.errors.files}
          onFileChange={handleFileChange}
        />
      )}
      {tab === 2 && (
        <PRTextArea
          editorMode
          editorProps={{
            defaultLanguage: "json",
            defaultHeight: 300,
          }}
          invalid={formik.touched.jsonText && formik.errors.jsonText}
          name="jsonText"
          placeholder={jsonPlaceholder}
          value={formik.values.jsonText}
          onChange={formik.handleChange}
        />
      )}
    </PRModal>
  );
}
const UploadMemberDataModal = withCardon(UploadMemberData, { destroyOnHide: true });
export default UploadMemberDataModal;
