import { createRef, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { FaCodeBranch } from "react-icons/fa";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import { apiUrlIntegrationModule, tableFilterStorageKey } from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import { deleteModule } from "~store/integration/module/actions";
import { selectCurrentProject } from "~store/user/selectors";

const ModuleList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableRef = createRef();
  const currentProject = useSelector(selectCurrentProject);

  const actions = useMemo(() => {
    return [
      {
        label: t("common.createNew"),
        icon: MdAdd,
        color: "success",
        link: "/integration/module/form",
      },
    ];
  }, []);

  const columns = useMemo(() => {
    const handleClickDelete = (row) => async () => {
      if (await DialogHelper.showQuestionDelete()) {
        dispatch(deleteModule(currentProject.id, row.id)).then(() => {
          tableRef.current.refresh();
        });
      }
    };
    return [
      {
        label: t("common.name"),
        key: "machine_name",
      },
      {
        label: t("common.displayName"),
        key: "display_name",
      },
      {
        label: t("common.description"),
        key: "description",
      },
      {
        label: t("dashboard.module.versionCount"),
        key: "versions",
        render: (row) => row.versions?.length || 0,
      },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        render: (row) => (
          <div className="d-flex justify-content-center gap-1">
            <PRButton
              outline
              color="secondary"
              icon={FaCodeBranch}
              link={`/integration/module/version/${row.id}`}
              size="sm"
              tooltipText={t("dashboard.module.manageVersions")}
            />
            <PRButton
              outline
              color="primary"
              icon={MdEdit}
              link={`/integration/module/form/${row.id}`}
              size="sm"
              tooltipText={t("common.edit")}
            />
            <PRButton
              outline
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText={t("common.delete")}
              onClick={handleClickDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [currentProject.id, dispatch, tableRef, t]);
  return (
    <PRContainer
      actions={actions}
      description={t("dashboard.module.description")}
      name={t("common.integration")}
      parentName={t("dashboard.module")}
    >
      <PRTable
        ref={tableRef}
        columns={columns} //
        storageKey={tableFilterStorageKey.moduleList}
        url={apiUrlIntegrationModule.get.format(currentProject.id)}
      />
    </PRContainer>
  );
};

export default ModuleList;
