import { safeProduce as produce } from "~helpers/immer";

import * as actionTypes from "./actionTypes";

export const initState = {
  guide: null,
  guideList: null,
};

const guideDefinition = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.SET_GUIDE:
        draft.guide = action.payload;
        break;
      case actionTypes.SET_GUIDE_LIST:
        draft.guideList = action.payload;
        break;
    }
  });

export default guideDefinition;
