import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

export const initState = {
  project: {},
};

const popupSettings = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_PROJECT:
        draft.project = action.payload;
    }
  });

export default popupSettings;
