import WorkerSocket from "~components/WorkerSocket";
import { apiUrlWebchat } from "~constants";
import TokenHelper from "~helpers/TokenHelper";

let _idCounter = 0;
const getId = () => _idCounter++;
export default class ChatSocket {
  static instances = new Map();
  socket = null;
  /**
   * @param {"agent" | "customer"} type Type of socket
   * @param {Function} onMessage Message handler
   * @param {Function} onClose Callback when socket close
   * @param {Function} onError Callback when socket error
   * @param {number} connectionTimeout Timeout for connection
   */
  constructor(type = "customer", onMessage, onClose, onError, connectionTimeout = 120_000) {
    const existingInstance = ChatSocket.instances.get(type);
    if (existingInstance) {
      //force close existing instance if same type is created
      existingInstance.forceClose();
    }

    const chatbotAlias = TokenHelper.getChatbotAlias();

    const socketProtocol = window.location.protocol === "https:" ? "wss://" : "ws://";
    const socketPathAgent = `${socketProtocol}${window.location.host}/ws/agent/`;
    let socketPathCustomer = `${socketProtocol}${window.location.host}/${apiUrlWebchat.websocket}`;

    if (chatbotAlias) {
      socketPathCustomer = `${socketProtocol}${window.location.host}/${apiUrlWebchat.websocketAlias.format(
        chatbotAlias
      )}`;
    }

    /** @type {WebSocket} */
    this.socket = null;
    this.type = type;
    this.id = getId();
    this.path = type === "agent" ? socketPathAgent : socketPathCustomer;
    this.forceClosed = false;
    this.connectionTimeout = connectionTimeout;
    this.onMessage = (event) => {
      if (this.forceClosed) return;
      const data = JSON.parse(event.data);
      try {
        onMessage?.(data);
      } catch (error) {
        console.error(`Error when handling socket message: ${this.id}.`, data, error);
      }
    };
    this.onClose = (event) => {
      onClose?.(event);
    };
    this.onError = (event) => {
      onError?.(event);
    };
    ChatSocket.instances.set(type, this);
  }

  connect = async () => {
    if (this.forceClosed) {
      console.log("Socket is force closed. Skipping connect:", this.id);
      return;
    }

    const isWebworkerSupported = typeof Worker !== "undefined";
    if (!isWebworkerSupported) {
      console.warn("Webworker is not supported. Please use modern browser to get better performance: ", this.id);
    }
    try {
      // this.socket = isWebworkerSupported ? new WorkerSocket(this.path) : new WebSocket(this.path);
      this.socket = isWebworkerSupported ? new WorkerSocket(this.type) : new WebSocket(this.path);

      this.socket.onmessage = this.onMessage;
    } catch (e) {
      console.error(`Error when creating socket: ${this.id}`, e);
      this.onError(e);
      return Promise.reject(e.message);
    }

    let currentSocket = this.socket;
    return new Promise((resolve, reject) => {
      let timeoutId;
      let isErrorReceived = false;

      const clearSocketTimeout = () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
          timeoutId = null;
        }
      };
      const socketTimeout = () => {
        if (this.forceClosed) return;
        console.log(`Socket connection timed out: ${this.id}`);
        reject("websocket.init.timeout.error");
        isErrorReceived = true;
        currentSocket.close();
        this.onError("websocket.init.timeout.error");
      };
      currentSocket.onopen = () => {
        if (this.forceClosed) return;
        console.log(`Socket connected: ${this.id}`);
        clearSocketTimeout();
        resolve();
      };
      currentSocket.onerror = (error) => {
        isErrorReceived = true;
        if (this.forceClosed) return;
        console.log(`Socket connection error, ${this.id}. Type: ` + this.type, error);
        reject("websocket.network.error");
        clearSocketTimeout();
        this.onError(error);
      };
      currentSocket.onclose = (e, r) => {
        if (isErrorReceived) return;
        if (this.forceClosed) return;
        console.log(`Socket closed: ${this.id}`, e, r);
        clearSocketTimeout();
        this.onClose();
      };

      timeoutId = setTimeout(socketTimeout, this.connectionTimeout);
    });
  };

  send = (data) => {
    if (WebSocket.CLOSED === this.socket?.readyState) {
      console.log(`Socket is already closed. Skipping send: ${this.id}`);
      return;
    } else if (WebSocket.CLOSING === this.socket?.readyState) {
      console.log(`Socket is already closing stage. Skipping send: ${this.id}`);
      return;
    } else if (WebSocket.CONNECTING === this.socket?.readyState) {
      console.log(`Socket is still trying to connect... Skipping send: ${this.id}`);
      return;
    } else if (this.forceClosed) {
      console.log(`Socket is force closed. Skipping send: ${this.id}`);
      return;
    }
    const dataStr = typeof data === "string" ? data : JSON.stringify(data);
    this.socket?.send(dataStr);
  };

  close = () => {
    if (!this.forceClosed && this.socket?.readyState === WebSocket.OPEN) {
      this.socket?.close();
    }
  };

  forceClose = () => {
    console.log(`Force closing socket: ${this.id}`, this.socket?.readyState);
    this.socket?.close();
    this.socket?.terminate();
    if (this.socket) {
      this.socket.onmessage = null;
    }
    this.forceClosed = true;
    this.socket = null;
  };

  isAvailable = async () => {
    if (this.forceClosed) return false;
    let readyState;
    if (this.socket instanceof WebSocket) {
      readyState = this.socket.readyState;
    } else {
      try {
        readyState = await this.socket.getReadyState();
      } catch (error) {
        return false;
      }
    }
    return readyState === WebSocket.OPEN;
  };
}
