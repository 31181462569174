import { LS_ANNOUNCEMENT_ACK_ID_LIST, apiUrlAppAnnouncement } from "~constants";
import Network from "~helpers/Network";
import StorageHelper from "~helpers/StorageHelper";

import * as at from "./actionTypes";

export const setAppAnnouncementList = (payload) => ({
  type: at.SET_ANNOUNCEMENT_LIST,
  payload,
});

export const readAppAnnouncement = (payload) => {
  return {
    type: at.READ_ANNOUNCEMENT,
    payload,
  };
};

export const setAcknowledgedAppAnnouncementIds = (payload) => ({
  type: at.SET_ACKNOWLEDGED_ANNOUNCEMENT_IDS,
  payload,
});

export const appendAppAnnouncement = (payload) => {
  return {
    type: at.APPEND_ANNOUNCEMENT,
    payload,
  };
};

export const getAppAnnouncementList =
  (projectId, id, options = {}) =>
  async (_dispatch, _getState) => {
    const ackIdList = await StorageHelper.get(LS_ANNOUNCEMENT_ACK_ID_LIST);
    if (ackIdList) {
      _dispatch(setAcknowledgedAppAnnouncementIds(ackIdList));
    }
    return Network.request(apiUrlAppAnnouncement.get, {
      onSuccess: (response) => {
        return setAppAnnouncementList(response?.results);
      },
      loading: true,
      params: {
        limit: 9999,
      },
      ...options,
    });
  };
