import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

export const initState = {
  module: null,
  moduleList: null,
  moduleVersion: null,
  triggerList: [],

  moduleIntegration: null,
  moduleIntegrationList: null,
};

const popupSettings = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_MODULE:
        draft.module = action.payload;
        break;
      case at.SET_MODULE_LIST:
        draft.moduleList = action.payload;
        break;
      case at.SET_MODULE_VERSION:
        draft.moduleVersion = action.payload;
        break;
      case at.SET_TRIGGER_LIST:
        draft.triggerList = action.payload;
        break;
      case at.SET_MODULE_INTEGRATION:
        draft.moduleIntegration = action.payload;
        break;
      case at.SET_MODULE_INTEGRATION_LIST:
        draft.moduleIntegrationList = action.payload;
        break;
    }
  });

export default popupSettings;
