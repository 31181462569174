import { createRef, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import {
  apiUrlOrganization,
  organizationActionTypesMap,
  organizationActionTypesOptions,
  tableFilterStorageKey,
} from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import { filterGetReadableText } from "~pages/Organization/Filter/FilterUtils";
import { deleteAction } from "~store/organization/actions";
import { selectCurrentProject } from "~store/user/selectors";

import "./style.scss";

export default function ActionsList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const tableRef = createRef();

  const columns = useMemo(() => {
    const handleDelete = (row) => async () => {
      if (!(await DialogHelper.showQuestionDelete())) return;
      await dispatch(deleteAction(row.id, currentProject.id));
      tableRef.current.refresh();
    };
    return [
      {
        label: t("common.name"),
        key: "name",
      },
      {
        label: t("common.title"),
        key: "title",
      },
      {
        label: t("common.active"),
        key: "active",
        render: (row) => <span>{row.active ? t("common.yes") : t("common.no")}</span>,
      },
      {
        label: t("common.periodic"),
        key: "is_periodic",
        render: (row) => <span>{row.is_periodic ? t("common.yes") : t("common.no")}</span>,
      },
      {
        label: t("common.actionType"),
        key: "action_type",
        render: (row) => {
          return t(organizationActionTypesMap[row.action_type]) || row.action_type;
        },
      },
      {
        label: t("dashboard.actionTemplates.filterName"),
        key: "filter_name",
        render: (row) => (
          // <PRTooltip title={filterGetReadableText(row.action_filter, true)}> {/* </PRTooltip> */}
          <span>{row.action_filter?.name}</span>
        ),
      },
      {
        label: t("dashboard.actionTemplates.filterRule"),
        key: "filter_Rule",
        render: (row) => <span>{filterGetReadableText(row.action_filter, true)}</span>,
      },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        render: (row) => (
          <div className="d-flex justify-content-center">
            <PRButton
              outline
              color="primary"
              icon={MdEdit}
              link={`/organization/action-templates/form/${row.id}`}
              size="sm"
              tooltipText={t("common.edit")}
            />
            <PRButton
              outline
              className="ms-1"
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText={t("common.delete")}
              onClick={handleDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [dispatch, currentProject, tableRef, t]);

  const actions = useMemo(() => {
    return [
      {
        label: t("common.createNew"),
        icon: MdAdd,
        color: "success",
        link: "/organization/action-templates/form",
      },
    ];
  }, [t]);

  const filters = useMemo(() => {
    return [
      {
        key: "action_type",
        label: t("dashboard.actionTemplates.actionType"),
        options: organizationActionTypesOptions,
      },
      {
        key: "action_filter__name",
        label: t("dashboard.actionTemplates.filterName"),
      },
      {
        key: "is_periodic",
        label: t("common.periodic"),
      },
    ];
  }, [t]);

  return (
    <PRContainer
      actions={actions}
      className="pr-data-format"
      description={t("dashboard.actionTemplates.description")}
      name={t("common.organization")}
      parentName={t("dashboard.actionTemplates")}
    >
      <PRTable
        ref={tableRef}
        columns={columns}
        filters={filters}
        storageKey={tableFilterStorageKey.actionList}
        url={apiUrlOrganization.getAction.format(currentProject.id)}
        urlQuery={{ is_template: true }}
      />
    </PRContainer>
  );
}
