import { useEffect, useMemo } from "react";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import useProjectChange from "~common/hooks/useProjectChange";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRInput from "~components/Generic/PRInput";
import PRPage from "~components/Generic/PRPage";
import HistoryHelper from "~helpers/HistoryHelper";
import { createOrUpdateChatLabel, getChatLabel, setChatLabel } from "~store/chatLabels/actions";
import { selectChatLabel } from "~store/chatLabels/selectors";
import { selectCurrentProject } from "~store/user/selectors";

const ChatLabelEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const dispatch = useDispatch();

  const currentProject = useSelector(selectCurrentProject);
  const chatLabel = useSelector(selectChatLabel);

  const handleClickCancel = () => {
    HistoryHelper.goBack("/contactcenter/chat-labels/", { scope: "dashboard" });
  };

  useEffect(() => {
    if (!id) return;
    dispatch(getChatLabel(currentProject.id, id));

    return () => {
      dispatch(setChatLabel(null));
    };
    // Page should be redirected to list if selected project is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...chatLabel,
      key: chatLabel?.key || "",
    },
    validationSchema: Yup.object({
      key: Yup.string().required(t("component.formik.required").format(t("common.label"))),
    }),
    onSubmit: async (values) => {
      await dispatch(
        createOrUpdateChatLabel(currentProject.id, {
          ...values,
        })
      );
      handleClickCancel();
    },
  });

  useProjectChange(() => {
    HistoryHelper.push("/contactcenter/chat-labels/", { scope: "dashboard" });
  }, []);

  const parentName = useMemo(
    () => [
      {
        label: t("dashboard.chatLabels"),
        url: "/contactcenter/chat-labels",
      },
      {
        label: id ? t("dashboard.chatLabelsEdit.editLabel") : t("dashboard.chatLabelsEdit.createLabel"),
      },
    ],
    [id, t]
  );
  return (
    <PRContainer
      description={t("dashboard.chatLabelsEdit.description")}
      name={t("common.contactCenter")}
      parentName={parentName}
    >
      <PRPage>
        <Row>
          <Col lg="6" xs="12">
            <Label className="col-form-label col-lg-2">{t("common.label")}</Label>
            <PRInput
              invalid={formik.touched.key && formik.errors.key}
              name="key"
              placeholder={t("dashboard.chatLabelsEdit.labelPlaceholder")}
              value={formik.values.key}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
        </Row>

        <Row className="justify-content-end mt-2">
          <Col xs="auto">
            <PRButton outline className="me-2" onClick={handleClickCancel}>
              {t("common.cancel")}
            </PRButton>
            <PRButton onClick={formik.handleSubmit}>{id ? t("common.update") : t("common.create")}</PRButton>
          </Col>
        </Row>
      </PRPage>
    </PRContainer>
  );
};

export default ChatLabelEdit;
