import { useEffect, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";

import useLoading from "~common/hooks/useLoading";
import { getStatistics } from "~store/statistics/actions";
import { selectStatisticsByKey, selectStatisticsFilter } from "~store/statistics/selectors";

const StyledAggregateCard = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  border-radius: 20px;
  padding: 20px;
  margin: 10px;
`;

const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

const AggregateCard = ({ title, statisticKey, icon }) => {
  const { projectId } = useParams();
  const { beginDate, endDate } = useSelector(selectStatisticsFilter);
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const value = useSelector(selectStatisticsByKey(statisticKey));
  const IconComp = useMemo(() => icon, [icon]);
  useEffect(() => {
    q(dispatch(getStatistics(projectId, [statisticKey], beginDate, endDate, true)));
  }, [dispatch, projectId, q, statisticKey, beginDate, endDate]);
  return (
    <StyledAggregateCard>
      <TitleDiv>
        <h5>{title}</h5>
        <h4>
          <IconComp />
        </h4>
      </TitleDiv>
      <h1>{loading ? <CircularProgress /> : value}</h1>
    </StyledAggregateCard>
  );
};

export default AggregateCard;
