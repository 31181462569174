import { useMemo } from "react";

import { useSelector } from "react-redux";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import IFrameHelper from "~helpers/IFrameHelper";
import { selectMuiTheme, selectMuiThemeWebchat } from "~store/theme/selectors";

function PRThemeProvider({ webchat, children, ...rest }) {
  const muiTheme = useSelector(selectMuiTheme);
  const muiThemeWebchat = useSelector(selectMuiThemeWebchat);
  const isWebTheme = IFrameHelper.isWebChatMode() || webchat;

  const theme = useMemo(() => {
    const activeTheme = isWebTheme ? muiThemeWebchat : muiTheme;
    const theme = createTheme(activeTheme);
    return theme;
  }, [isWebTheme, muiTheme, muiThemeWebchat]);

  if (!theme) return null;
  return (
    <ThemeProvider {...rest} theme={theme}>
      {children}
    </ThemeProvider>
  );
}

export default PRThemeProvider;
