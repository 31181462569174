import { apiUrlAppVersion } from "~constants";
import Network from "~helpers/Network";

import * as at from "./actionTypes";

export const setSidebar = (payload) => ({
  type: at.SET_SIDEBAR,
  payload,
});

export const setLatestAppVersion = (payload) => ({
  type: at.SET_LATEST_APP_VERSION,
  payload,
});

export const setAllAppVersions = (payload) => ({
  type: at.SET_ALL_APP_VERSIONS,
  payload,
});

export const setAppAnnouncements = (payload) => ({
  type: at.SET_APP_ANNOUNCEMENTS,
  payload,
});
export const getLatestAppVersion = () => async (_dispatch, _getState) => {
  return Network.request(apiUrlAppVersion.get, {
    loading: true,
    params: {
      limit: 1,
    },
    onSuccess: (response) => {
      return setLatestAppVersion(response?.results?.[0]);
    },
  });
};

export const getAllAppVersions = () => async (_dispatch, _getState) => {
  return Network.request(apiUrlAppVersion.get, {
    loading: true,
    params: {
      limit: 9999,
    },
    onSuccess: (response) => {
      _dispatch(setLatestAppVersion(response?.results?.[0]));
      return setAllAppVersions(response?.results);
    },
  });
};
